import React from "react";
import editIcon from "assets/images/view.svg";

const ViewButton = (props) => {
  return (
    <button {...props} type="button" className="icons edit-btn">
      <img src={editIcon} alt="edit" height={props.height} width={props.width} />
    </button>
  );
};

export default ViewButton;
