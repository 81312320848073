import React, { Component, useEffect, useContext, useState } from "react";
import ReactDOM from "react-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { getVirtualBrandsByKitchen } from "services/api/brand";
import makeAnimated from "react-select/animated";
import MultiSelectFilter from "../../pages/order/listing/MultiSelectFilter";

const MultiVBFilter = (props) => {
    const kitchenId = props.kitchenId;
    let initialValue = props.value;
    const onSelect = props.onSelect;

    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(initialValue);
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        getBrandsForDD();
        return () => {
          setIsMounted(false);
        };
    }, [kitchenId]);

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    const animatedComponents = makeAnimated();

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: "white",
            /* border: state.isSelected ? "rgba(236, 56, 11, 1)" : "none", */
        }),
        control: base => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #c0c0c8",
            minHeight: 48,
            "&:focus": {
                borderColor: "none",
                boxShadow: "none",
                outline: "none",
                border: 0
            }
          }),
     /*    placeholder: defaultStyles => ({
            ...defaultStyles,
            color: "#6c757d",
            opacity: 1
        }) */
    }


    const getBrandsForDD = async () => {
      /*   setBrands([{ value: 0, label: "All" }]); */
        setSelectedBrand(initialValue || 0);
        await getVirtualBrandsByKitchen(kitchenId).then((res) => {
            let data = res.data;
            if (isMounted && data && data.length) {
                let brands = data.map((res) => ({
                    value: res.id,
                    label: res.name,
                }));
               /*  brands.unshift({ value: 0, label: "All" }); */
                setBrands(brands);
            } /* else setBrands([{ value: 0, label: "All" }]); */
        });
    };

    const onKitchenValueSelected = (val) => {
        setSelectedBrand(val.value);
        onSelect(val);
    };

    const handleChange = (selected) => {
        setSelectedBrand(selected);
        onSelect(selected, brands?.length);
    };

    return (
        <MultiSelectFilter
            options={brands}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleChange}
            allowSelectAll={true}
            value={selectedBrand || {value: 0, label: "All"}}
            styles={customStyles}
        />
    );
};
export default MultiVBFilter;
