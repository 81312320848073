import React, { useContext, useEffect, useState, useRef } from "react";
import "./AddNewCategoeris.scss";
import MenuCategoryCard from "../category-card/MenuCategoryCard";
import VirtualBrandContext from "store/virtual-brand-context";
import { getMenuCategories } from "services/api/menu";
import NoMenuCategoryCard from "../category-card/NoMenuCategoryCard";
import AddButton from "UI/Button/AddButton";
import CategoryModal from "../category-modal/CategoryModal";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import { VirtualBrandMode } from "constants/virtualBrandMode";

const CategorySection = () => {
  const brandCtx = useContext(VirtualBrandContext);
  const menuId = brandCtx.virtualBrandMenuId;
  const modalStatus = brandCtx.menuCategoryModalStatus;
  const brandMode = brandCtx.virtualBrandMode;

  const [categories, setcategories] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
     isMounted.current = true;
    if (menuId) {
      getMenuRelatedCategory();
    }
     return () => {
       isMounted.current = false;
     };
  }, [menuId]);

  useEffect(() => {
     isMounted.current = true;
    if (modalStatus === false) {
      getMenuRelatedCategory();
    }
     return () => {
       isMounted.current = false;
     };
  }, [modalStatus]);

  const showModal = () => {
    brandCtx.menuCategoryModalStatusChange(true);
  };

  const closeModal = () => {
    brandCtx.menuCategoryModalStatusChange(false);
  };

  const getMenuRelatedCategory = async () => {
    setisLoading(true);
    await getMenuCategories(menuId)
      .then((res) => {
        if (isMounted.current) {
          setisLoading(false);
          setcategories(res.data);
        }
      })
      .catch(() => {
        setisLoading(false);
        setcategories(null);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="addnewCat__wrapper">
                <div className="row justify-content-between align-items-md-center">
                  <div className="col-12 col-md-6">
                    <div className="main__heading">
                      <h1>Menu Categories & Items</h1>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-none d-md-block">
                    <div className="CreateCatagoryModal__wrapper">
                      {brandMode === VirtualBrandMode.SHARED ||
                      brandMode === VirtualBrandMode.VIEW ? null : (
                        <div className="oulined-button-wrapper">
                          <AddButton
                            title="Add Menu Category"
                            openModal={showModal}
                          />
                        </div>
                      )}
                      <CategoryModal
                        show={modalStatus}
                        className="right"
                        onHide={closeModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {isLoading ? (
              <>
                <div className="col-12">
                  <ShimmerLoader />
                </div>
              </>
            ) : categories && categories.length ? (
              categories.map((category, idx) => (
                <MenuCategoryCard
                  {...category}
                  key={idx}
                  onReload={getMenuRelatedCategory}
                />
              ))
            ) : (
              <NoMenuCategoryCard />
            )}

            {brandMode === VirtualBrandMode.SHARED ||
            brandMode === VirtualBrandMode.VIEW ? null : (
              <div className="col-12 col-md-6 d-md-none pb-3">
                <div className="oulined-button-wrapper">
                  <AddButton title="Add Menu Category" openModal={showModal} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySection;
