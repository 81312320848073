import { RoleType } from "constants/roleType";
import React, { useState, useEffect, useContext } from "react";
import { getActiveKitchens } from "services/api/kitchen";
import UserContext from "store/user-context";
import { SelectDropdownSearch } from "UI/Select-Dropdown/SelectDropdownSearch";

const KitchenFilter = (props) => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;

  const intialValue = props.value;
  const onSelect = props.onSelect;

  const [kitchens, setKitchens] = useState([]);
  const [selectedKitchen, setselectedKitchen] = useState(intialValue);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    getKitchensForDD();
    return () => {
      setIsMounted(false);
    };
  }, []);

  const getKitchensForDD = async () => {
    await getActiveKitchens().then((res) => {
      let data = res.data;
      if (isMounted) {
        if (data && data.length) {
          let kitchens = data.map((res) => ({
            value: res.id,
            label: res.name,
          }));
          if (kitchens.length === 1) {
            setKitchens(kitchens);
            onKitchenValueSelected(kitchens[0]);
          }
          else {
            kitchens.unshift({ value: 0, label: "All Restaurants" });
            setKitchens(kitchens);
          }
        } else setKitchens([{ value: 0, label: "All Restaurants" }]);
      }
    });
  };

  const onKitchenValueSelected = (val) => {
    setselectedKitchen(val.value);
    onSelect(val);
  };

  return (
    <>
      {kitchens && (
        <SelectDropdownSearch
          options={kitchens}
          placeholder="Search Restaurant"
          selectedValue={onKitchenValueSelected}
          value={selectedKitchen}
          searchPlaceholder="Search Restaurant"
          type="truncate"
        />
      )}
    </>
  );
};

export default KitchenFilter;
