import { privateApi, publicApi } from "../utilities/Provider";

const basicUrl = "/User/";

const getStaffs = ({ pageSize, pageNum, type, searchTerm }) => {
  const params = new URLSearchParams();
  params.append("pageNum", pageNum);
  params.append("pageSize", pageSize);
  if (type) params.append("roleId", type);
  if (searchTerm) params.append("name", searchTerm);
  return privateApi.get(basicUrl + "userlist", { params });
};

const getUsersListForPayout = (type) => {
  const params = new URLSearchParams();
  if (type) params.append("roleId", type);
  return privateApi.get(basicUrl + "userlist", { params });
};

const getStaff = (userId) => {
  return privateApi.get(basicUrl + "userbyid", {
    params: {
      id: userId,
    },
  });
};

const addStaff = (model) => {
  let body = {
    contactName: model.fullName,
    phoneNumber: "" + model.phone,
    emailAddress: model.email,
    addressOne: model.addressOne,
    addressTwo: model.addressTwo,
    city: model.city,
    zip: model.zip ? "" + model.zip : null,
    stateId: model.stateId,
    roleId: model.roleId,
    kitchenId: model.kitchenId,
  };

  return privateApi.post(basicUrl + "add", body);
};

const editStaff = (model) => {
  let body = {
    id: model.id,
    contactName: model.fullName,
    phoneNumber: "" + model.phone,
    emailAddress: model.email,
    addressOne: model.addressOne,
    addressTwo: model.addressTwo,
    city: model.city,
    zip: model.zip ? "" + model.zip : null,
    stateId: model.stateId,
    roleId: model.roleId,
    kitchenId: model.kitchenId,
  };

  return privateApi.put(basicUrl + "update", body);
};

const changeStaffStatus = (userId, status) => {
  let body = {
    userId: userId,
    isActive: status,
  };

  return privateApi.put(basicUrl + "userstatus", body);
};

const getUserAccount = () => {
  return privateApi.get(basicUrl + "myAccount");
};

const editUserAccount = (model) => {
  let body = {
    contactName: model.fullName,
    phoneNumber: "" + model.phone,
    addressOne: model.addressOne,
    addressTwo: model.addressTwo,
    city: model.city,
    zip: model.zip ? "" + model.zip : null,
  };
  if (model.stateId > 0) body["stateId"] = model.stateId;

  return privateApi.put(basicUrl + "myAccount", body);
};

const getStates = () => {
  return privateApi.get(basicUrl + "states");
};

const sendContactUsDetails = (model) => {
  let body = {
    name: model.name,
    phoneNumber: "" + model.phone,
    email: model.email,
    businessName: model.businessName,
    message: model.message
  };

  return publicApi.post(basicUrl + "contactus", body);
};

export {
  getStaffs,
  getStaff,
  addStaff,
  editStaff,
  changeStaffStatus,
  getUserAccount,
  editUserAccount,
  getStates,
  sendContactUsDetails,
  getUsersListForPayout
};
