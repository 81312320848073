import React, { useEffect, useState }  from "react";
import BrandRow from "./BrandRow";
import { Global } from "constants/global";
import { getVBListByKitchenId } from "services/api/kitchen";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import fileIcon from "assets/images/file-icon.svg";
import noresultsIcon from "assets/images/noresult.svg";
import { getUsersListForPayout } from "services/api/staff";
import { RoleTypeId } from "constants/roleType";
import { getServices } from "services/api/connection";

const BrandList = (props) => {
  const kitchen = props.kitchen;
  const kitchenId = kitchen?.id;
  const pageSize = Global.pageSize;

  const [brands, setBrands] =  useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setsearchTerm] = useState(null);
  const [brandOwnerList, setBrandOwnerList] = useState([]);
  const [affiliateUserList, setAffiliateUserList] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    if(kitchenId){
      getAllList();
    }
  }, [kitchenId, pageNumber]);

  useEffect(() => {
    let isMounted = true;
    const getDeliveryServices = async () => {
      await getServices().then((res) => {
        let data = res.data;
        if (data && data?.length) {
          let filtered = data.map((res) => ({
            label: res.name,
            value: res.id,
          }));
          if (isMounted) {
            setServices(filtered);
          }
        }
      });
    };
    getDeliveryServices();
    return () => {
      isMounted = false;
    };
  }, []);

  const getAllList = async () => {
    const model = {
      kitchenId: kitchenId,
      pageNum: pageNumber,
      pageSize: pageSize
    }
    setisLoading(true);
    await getVBListByKitchenId(model)
      .then((res) => {
        let data = res.data;
        setTotalRecords(data?.pagingParams?.totalRecords);
        setBrands(data?.kitchenVirtualBrandAssociations);
        setisLoading(false);
      })
      .catch((_) => {
        setBrands(null);
        setTotalRecords(0);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getBrandOwnerListing();
    getAffiliateUserListing();
  }, []);

  const getAffiliateUserListing = async () => {
    setisLoading(true);
    await getUsersListForPayout(RoleTypeId.AFFILIATE_USER)
      .then((res) => {
        let data = res?.data?.users || [];
        if (data && data.length) {
          let affiliateUserList = data.map((res) => ({
            value: res.userId,
            label: res.name,
          }));
          affiliateUserList.splice(0,0,{ value: null, label: 'Select User' });
          setAffiliateUserList(affiliateUserList);
        }
        setisLoading(false);
      })
      .catch(() => {
        setAffiliateUserList(null);
        setisLoading(false);
      });
  };

  const getBrandOwnerListing = async () => {
    setisLoading(true);
    await getUsersListForPayout(RoleTypeId.BRAND_OWNER)
      .then((res) => {
        let data = res?.data?.users || [];
        if (data && data.length) {
           let brandOwnerList = data.map((res) => ({
            value: res.userId,
            label: res.name,
          }));
          brandOwnerList.splice(0,0,{ value: null, label: 'Select User' });
          setBrandOwnerList(brandOwnerList);
        }
        setisLoading(false);
      })
      .catch(() => {
        setBrandOwnerList(null);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    setPageNumber(page);
  };

  return (
    <>
      <div className="managermodifier__wrapper">
        <div className="row">
          <div className="col-12">
            <div className="shared__vb__listing__wrapper">
              {/*  <h2>Virtual Brands</h2> */}
              {brands && totalRecords === 0 && !isLoading ? (
                <div className="restaurantdetails__noListing">
                  <div className="managermodifier__noListing__innerwrapper">
                    <div className="content">
                      <div className="img-box">
                        <img
                          className="img-fluid"
                          src={
                            searchTerm && searchTerm !== ""
                              ? noresultsIcon
                              : fileIcon
                          }
                          alt="no list icon"
                          width="185"
                        />
                      </div>
                      <div className="content-desc">
                        <h3>
                          {searchTerm && searchTerm !== ""
                            ? "No virtual brand(s) found"
                            : "No virtual brands are added"}
                        </h3>
                        {/* <p>
                        Please click on “Add New Virtual Brand” to create new
                        Recipes.
                      </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="shared__vbs__wrapper">
                  <div className="accordion__header">
                    <span>Name</span>
                    <span>Actions</span>
                  </div>
                  <div className="marketplace__accordion__wrapper">
                    {isLoading ? (
                      <LoaderBlock />
                    ) : (
                      brands?.map((brand, idx) => (
                        <BrandRow
                          key={idx}
                          brand={brand}
                          kitchen={kitchen}
                          affiliateUserList={affiliateUserList}
                          brandOwnerList={brandOwnerList}
                          services={services}
                        />
                      ))
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Paginator
                        totalCount={totalRecords}
                        currentPage={pageNumber}
                        pageSize={pageSize}
                        pageClick={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandList;