export const Global = {
  otpLength: 6,
  otpMask: "*",
  pageSize: 15,
  dashboardPageSize: 36,
  resendCodeTimer: 60000, // 1 minute
  fiveMB: 5242880,
  tenMB: 10485760,
  twoHunMB: 209715200,
  homeUrl: "https://backhouse.io",
  landingPageDomain: "order.backhouse.io",
  webHookUrl: "http://webhook.backhouse.io/api/chowly/",
  loginErrorForKitchenStaff: "The tablet user account does not have access to the web. Please try a different login",
};
