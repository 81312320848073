import React, { useState, useEffect } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import calendarIcon from "assets/images/calender.svg";
import clearIcon from "assets/images/ico-calendar-reset.svg";
import moment from "moment";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

const DateFilter = (props) => {
  const dateTo = props.dateToValue;
  const dateFrom = props.dateFromValue;
  const sendInfo = props.onSelect;
  const [value, onChange] = useState([dateFrom, dateTo]);

  useEffect(() => {
    onChange([dateFrom, dateTo]);
  }, [dateTo, dateFrom]);

  const isDateRangeMatching = (fromStr, toStr, daysAgo) => {
    const from = moment(fromStr);
    const to = moment(toStr);
    const startDate = moment().subtract(daysAgo, "days");
    return startDate.isSame(from, 'day') && moment().isSameOrAfter(to, 'day');
  };

  const dateRangeConfigArr = [
    {
      label: "Today",
      value: 1,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 0),
    },
    {
      label: "Yesterday",
      value: 2,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 1),
    },
    {
      label: "Last Week",
      value: 3,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 7),
    },
    {
      label: "Last 30 Days",
      value: 4,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 30),
    },
  ];

  const onDateSelect = (data) => {
    onChange(data);
    if (data) {
      const matchingObj = dateRangeConfigArr.find((config) =>
        config.match(data[0], data[1])
      );
      sendInfo({
        dateFrom: data[0],
        dateTo: data[1],
        selectedValue: matchingObj ? matchingObj.value : 5
      });
    } else {
      sendInfo({
        dateFrom: null,
        dateTo: null,
        selectedValue: 0
      });
    }
  };

  return (
    <>
      <div className="date__filter__inner">
        <DateRangePicker
          className="custom-datepicker"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          format="MM/dd/yyyy"
          rangeDivider="  to  "
          onChange={onDateSelect}
          value={value}
          calendarIcon={<img src={calendarIcon} alt="calender" />}
          clearIcon={<img src={clearIcon} alt="clear" />}
        />
      </div>
    </>
  );
};

export default DateFilter;
