import React from "react";
import { Link } from "react-router-dom";
import "../ManageResturants/ManageResturantsDetails.scss";
import "../MyAccount/MyAccount.scss"


const MyAccount = () => {
  return (
    <div className="myaccount__wrapper">
      <div className="modifierDetails__wrapper restaurantDetails__wrapper user__details devices__details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main_heading">
                <h1>My Account</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                <div className="details__wrapper__inner">
                  <div className="left_content">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Full Name</h5>
                          <p>Drew Rice</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>User Type</h5>
                          <p>Normal User</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Contact No</h5>
                          <p>234 678 0987</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Account Email</h5>
                          <p>drew.rice@gmail.com</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Address Line 1</h5>
                          <p>3045 Eagandale Place</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Address Line 2</h5>
                          <p>3045 Eagandale Place</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>City</h5>
                          <p>Los Angeles</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>State</h5>
                          <p>California</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Country</h5>
                          <p>USA</p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Zip</h5>
                          <p>123456</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right_content">
                    <div className="btn-group dropleft threeDotsBropdown">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="dropdown-button-drop-left"
                      ></button>
                      <div className="dropdown-menu">
                        <div className="dropdown__innerWrapper">
                          <Link to="/change-password" className="dropdown-item active">
                            <span>Change Password</span>
                          </Link>
                          <Link to="/account-edit" className="dropdown-item">
                            <span>Edit</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
