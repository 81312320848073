import React from "react";
import Shimmer from "react-shimmer-effect";

const ShimmerLoader = () => {
  return (
    <>
      <div className="row m-4">
        <div className="col-12">
          <Shimmer>
            <div
              style={{
                height: "60px",
                width: "100%",
                borderRadius: "4px",
              }}
            />
            <div
              style={{
                height: "40px",
                width: "100%",
                borderRadius: "4px",
              }}
            />
            <div
              style={{
                height: "20px",
                width: "100%",
                borderRadius: "4px",
              }}
            />
          </Shimmer>
        </div>
      </div>
    </>
  );
};

export default ShimmerLoader;
