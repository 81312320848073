import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import "../../modifier/modifier-form/ModifierForm.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import plusIconRed from "assets/images/add-redIcon.svg";
import deleteIocn from "assets/images/delete-icon.svg";
import { addRecipe,editRecipe,getRecipeByRecipeId } from "services/api/recipeguide";
import LoaderInline from "shared/loader/LoaderInline";
import toastify from "shared/toastr";
import LoaderFull from "shared/loader/LoaderFull";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import RecipeDetailsForm from "pages/recipeguide/recipeguide-form/RecipeDetailsForm";
import IngredientsForm from "pages/recipeguide/recipeguide-form/IngredientsForm";
import { Tab, Nav, Col, Row } from "react-bootstrap";

const RecipeFormGlobal = (props) => {
  const { recipeId } = useParams("recipeId");
  const [isLoading, setisLoading] = useState(false);
  const [isDetailLoading, setisDetailLoading] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [basicData, setbasicData] = useState(null);
  const [availData, setAvailData] = useState(null);
  const [activeStep, setactiveStep] = useState(1);
  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
  let availabilityData = null;

  useEffect(() => {
    if (recipeId) {
      if (parseInt(recipeId) > 0) {
        getDetail(+recipeId);
        setisEditMode(true);
      }
    }
  }, [recipeId]);

  const getDetail = async (id) => {
    setisDetailLoading(true);
    await getRecipeByRecipeId(id)
      .then((res) => {
        setisDetailLoading(false);
        const data = res?.data;
        setbasicData(data);
        setAvailData(data);
      })
      .catch(() => {
        setisDetailLoading(false);
        onBack();
      });
  };

  const saveInfo = async (data) => {
    setisLoading(true);
    let model = {
      ...basicData,
      ...availabilityData,
    };
    if (isEditMode) {
      await editRecipe(recipeId, model)
        .then((res) => {
          setisLoading(false);
          toastify("success", res?.message);
          onBack();
        })
        .catch(() => {
          setisLoading(false);
        });
      return;
    } else {
      await addRecipe(model)
        .then((res) => {
          setisLoading(false);
          toastify("success", res?.message);
          onBack();
        })
        .catch(() => {
          setisLoading(false);
        });
      return;
    }
  };

  const onMenuBasicInfoComplete = (data) => {
    setbasicData({ ...data });
    setactiveStep(2);
  };

  const onMenuAvailabilityComplete = (data) => {
    availabilityData = data;
    saveInfo();
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
    if (step === 2) {
      if (basicData) setactiveStep(2);
    }
  };

  const saveRecipeDetails = (data) => {
    setbasicData(data);
  };

  const saveAvailTimings = (data) => {
    setAvailData(data);
  };

  const onBack = () => {
    props.history.goBack();
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="modifierDetails__wrapper modifierEdit__wrapper">
        {isDetailLoading && <LoaderFull />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button
                  type="button"
                  onClick={onBack}
                  className=" back-btn-red"
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>{isEditMode ? "Edit" : "Add"} New Recipe</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper editModifier__wrapper">
                <div className="details__wrapper__inner">
                  <div className="basic__info__content">
                    <div className="form-row global-recipe-width no-gutters">
                      <div className="createMenustabs mt-0">
                        <Tab.Container
                          id="addMenu__item__modal__Tabs"
                          defaultActiveKey="first"
                          mountOnEnter
                          unmountOnExit
                        >
                          <Row className="no-gutters">
                            <Col sm={12}>
                              <Nav variant="tabs" className="addMenu__item__modal__Tabs justify-content-around">
                                <Nav.Item className="w-50 text-center mr-0">
                                  <Nav.Link
                                    eventKey="first"
                                    active={activeStep === 1}
                                    onClick={() => onTabChange(1)}
                                  >
                                    {isEditMode && "Edit"}
                                    {/* !isViewMode && */ !isEditMode
                                      ? "Add"
                                      : null}{" "}
                                    Recipe Details
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="w-50 text-center">
                                  <Nav.Link
                                    eventKey="second"
                                    active={activeStep === 2}
                                    onClick={() => onTabChange(2)}
                                  >
                                    Ingredients
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                          </Row>
                          <Row className="no-gutters">
                            <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane
                                  eventKey="first"
                                  active={activeStep === 1}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <div className="tab__mainHeading">
                                    <RecipeDetailsForm
                                      onSave={onMenuBasicInfoComplete}
                                      initialData={basicData}
                                      disabled={isFieldsDisabled}
                                      onChangeTab={saveRecipeDetails}
                                      onHide={onBack}
                                    >
                                    </RecipeDetailsForm>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane
                                  eventKey="second"
                                  active={activeStep === 2}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <div className="tab__mainHeading body-overflow">
                                    <IngredientsForm
                                      onSave={onMenuAvailabilityComplete}
                                      loader={isLoading}
                                      initialData={availData}
                                      onChangeTab={saveAvailTimings}
                                      disabled={isFieldsDisabled}
                                      recipeTitle={basicData?.title}
                                      onHide={onBack}
                                    >
                                    </IngredientsForm>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(RecipeFormGlobal);
