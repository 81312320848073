import { privateApi } from "../utilities/Provider";
import dateFormat from "dateformat";

const basicUrl = "/report/";

const getReportData = ({ kitchenId, virtualBrandId, dateTo, dateFrom, orderStatus, marketPlaceStatus }) => {
  const params = new URLSearchParams();
  if (marketPlaceStatus) params.append("marketPlaceStatus", marketPlaceStatus);
  if (orderStatus) params.append("orderStatus", orderStatus);
  // if (dateFrom)
  //   params.append("from", dateFormat(new Date(dateFrom), "yyyy-mm-dd"));
  // if (dateTo) params.append("to", dateFormat(new Date(dateTo), "yyyy-mm-dd"));

  if (dateFrom) {
    //let frmtDate = new Date(new Date(dateFrom)/* .toUTCString() */).toISOString();
    let frmtDate = dateFormat(new Date(dateFrom), "yyyy-mm-dd");
    params.append("from", frmtDate);
  }
  if (dateTo) {
    //let frmtDate = new Date(new Date(dateTo)/* .toUTCString() */).toISOString();
    let frmtDate = dateFormat(new Date(dateTo), "yyyy-mm-dd");
    params.append("to", frmtDate);
  }

  if (kitchenId) params.append("kitchenId", kitchenId);
  if (virtualBrandId) params.append("virtualBrandId", virtualBrandId);

  return privateApi.get(basicUrl + "data", { params });
};

export { getReportData };
