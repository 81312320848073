import React, { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./ModifierForm.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import plusIconRed from "assets/images/add-redIcon.svg";
import deleteIocn from "assets/images/delete-icon.svg";
import { ModifierTypeDropdown } from "constants/modifierType";
import {
  addModifierGroup,
  getModifierGroup,
  editModifierGroup,
} from "services/api/modifier";
import LoaderInline from "shared/loader/LoaderInline";
import toastify from "shared/toastr";
import LoaderFull from "shared/loader/LoaderFull";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { numberFormat } from "constants/numberFormat";

const ModifierForm = (props) => {
  const { type, modGroupId } = useParams();
  const types = ModifierTypeDropdown;
  const [isLoading, setisLoading] = useState(false);
  const [isDetailLoading, setisDetailLoading] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);

  let params = useParams();
  console.log({params})

  useEffect(() => {
    if (modGroupId) {
      if (parseInt(modGroupId) > 0) {
        getDetail(+modGroupId);
        setisEditMode(true);
      }
    }
  }, [modGroupId]);

  const optionsSchema = Yup.object().shape({
    id: Yup.number().default(0).required(),
    name: Yup.string()
      .trim()
      .max(120, ({ max }) => `Name not more than ${max} characters`)
      .required("Please enter option name"),
    price: Yup.number()
      .typeError("Only digits allowed")
      .moreThan(-1, ({ more }) => `Price cannot be negative`)
      .max(10000, ({ max }) => `Price not more than ${max}`)
      .test("validDollarType","Price must have 2 digits after decimal or less",
      value => /^\d*.?\d{1,2}$/.test(value))
      .required("Please enter price")
  });

  const validationSchema = Yup.object().shape({
    id: Yup.number().default(0).required(),
    name: Yup.string()
      .trim()
      .max(120, ({ max }) => `Name not more than ${max} characters`)
      .required("Please enter modifier group name"),
    modifierType: Yup.number().required(),
    options: Yup.array()
      .of(optionsSchema)
      .min(1, "Minimum one option is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
    keyName: "optId",
  });

  const getDetail = async (id) => {
    setisDetailLoading(true);
    await getModifierGroup(id)
      .then((res) => {
        setisDetailLoading(false);
        let data = res.data;
        reset({
          id: data?.id,
          name: data?.name + (type === 'clone' ? ' (copy)' : ''),
          modifierType: data?.modifierType,
          options: data?.menuItemModOptions,
        });
      })
      .catch(() => {
        setisDetailLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.goBack();
  };

  const addOption = () => {
    append({ id: 0, name: "", price: 0 });
  };

  const onSubmit = async (data) => {
    setisLoading(true);
    if (isEditMode && type !== 'clone') {
      await editModifierGroup(data)
        .then((res) => {
          setisLoading(false);
          toastify("success", res.message);
          onBack();
        })
        .catch(() => {
          setisLoading(false);
        });
    } else {
      await addModifierGroup(data)
        .then((res) => {
          setisLoading(false);
          toastify("success", res.message);
          onBack();
        })
        .catch(() => {
          setisLoading(false);
        });
    }
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="modifierDetails__wrapper modifierEdit__wrapper">
        {isDetailLoading && <LoaderFull />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button
                  type="button"
                  onClick={onBack}
                  className=" back-btn-red"
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>{isEditMode ? type : "Add"} Modifiers</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper editModifier__wrapper">
                <div className="details__wrapper__inner">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic__info__content">
                      <div className="form-row no-gutters">
                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="modifiergroupName">
                            Modifier Group Name
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="modifiergroupName"
                            placeholder="e.g. Toppings"
                            className={`form-control ${errors?.name ? "is-invalid" : ""
                              }`}
                            {...register("name")}
                            maxLength="120"
                          />
                          {errors?.name?.message && (
                            <div className="invalid-feedback d-block">
                              {errors?.name?.message}
                            </div>
                          )}
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                          <label>
                            Modifier Type<span className="mendatory">*</span>
                          </label>
                          <select
                            className={`form-control ${errors?.modifierType ? "is-invalid" : ""
                              }`}
                            {...register("modifierType")}
                          >
                            {types &&
                              types.map((opt, idx) => (
                                <option key={"opt" + idx} value={opt.value}>
                                  {opt.text}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col-md-12">
                          <div className="modifieroptions__wrap">
                            <div className="modifieroptions">
                              <label htmlFor="modifieroptions">
                                Modifier Options
                                <span className="mendatory">*</span>
                              </label>
                            </div>
                            <div className="modifieroptions__prices">
                              <label htmlFor="modifierprice">Price</label>
                            </div>
                            <div className="modifieroptions__prices">
                              <label htmlFor="modifierprice">Plu</label>
                            </div>
                          </div>
                          {fields &&
                            fields.map((field, idx) => (
                              <div className="modifieroptions__wrap pb-2">
                                <div className="modifieroptions">
                                  <input
                                    key={field.optId}
                                    type="text"
                                    placeholder="e.g. Extra Beef"
                                    className={`form-control mb-0 ${errors?.options?.[idx]?.name
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    {...register(`options.${idx}.name`)}
                                  />
                                  {errors?.options?.[idx]?.name?.message && (
                                    <div className="invalid-feedback d-block">
                                      {errors?.options[idx].name?.message}
                                    </div>
                                  )}
                                </div>     
                                <div className="modifieroptions__prices">
                                  <div
                                    key={field.optId}
                                    className="delete_modifier_wrapper"
                                  >
                                    <input
                                      type="text"
                                      placeholder="e.g. $5"
                                      className={`form-control mb-0 ${errors?.options?.[idx]?.price
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                      {...register(`options.${idx}.price`, field?.price &&{value: numberFormat(field?.price).replace(/[^0-9\.-]+/g,"")})}
                                    />
                                  </div>
                                  {errors?.options?.[idx]?.price?.message && (
                                    <div className="invalid-feedback d-block">
                                      {errors?.options[idx].price?.message}
                                    </div>
                                  )}
                                </div>
                                <div className="modifieroptions__prices">
                                  <div
                                    key={field.optId}
                                    className="delete_modifier_wrapper"
                                  >
                                    <input
                                      type="text"
                                      placeholder="Plu"
                                      className={`form-control mb-0 `}
                                      {...register(`options.${idx}.plu`)}
                                      disabled
                                    />
                                    <button
                                      type="button"
                                      onClick={() => remove(idx)}
                                      className="delete_modifier"
                                    >
                                      <span>
                                        <img
                                          src={deleteIocn}
                                          className="icons"
                                          alt="remove"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <div className="addmodifiers__options mt-3 pt-2">
                            <button
                              type="button"
                              onClick={addOption}
                              className="addModifiers addModifiers-btn"
                            >
                              <span className="icons">
                                <img src={plusIconRed} alt="add" />
                              </span>
                              Add Modifer Options
                            </button>
                          </div>
                          {errors?.options?.message && (
                            <div className="invalid-feedback d-block">
                              {errors?.options?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 px-0">
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn red-btn-ghost btn"
                            onClick={onBack}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="red-btn next-btn btn"
                            disabled={isLoading || !isValid}
                          >
                            {isLoading ? <LoaderInline /> : type === 'clone' ? "Clone Modifier" : "Save Modifier"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ModifierForm);
