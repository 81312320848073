import React, {useContext} from "react";
import PropTypes from "prop-types";
import deleteIcon from "assets/images/delete-icon.svg";
import downloadIcon from "assets/images/download-icon.svg";
import pdfIcon from "assets/images/pdf-icon.svg";
import docxIcon from "assets/images/doc-icon.svg";
import retryIcon from "assets/images/retry-icon.svg";
import RecipeGuideContext from "store/recipe-guide-context";
import { VirtualBrandMode } from "constants/virtualBrandMode";

const ListingRow = (props) => {
  const file = props.file;
  const filepath = file?.filePath || file?.resourceUrl;
  const isDownload = props.isDownload;
  const recipeguideCtx = useContext(RecipeGuideContext);
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <tr role="row" className="mat-row justify-content-between" style={{ border: "1px solid #C0C0C8" }}>
      <td className="col-md-10">
        {/*  <Link to={file?.filePath} className="modifierDeatil__link d-inline-block w-100"> */}
        <a href={filepath} target="_self">
          {/*  {file?.name || "NA"} */}
          <div className="customer-data mt-1 mt-lg-0">
            <div className="img-box">
              <img
                src={file?.type === "application/pdf" ? pdfIcon : docxIcon}
                alt="icon"
                className="img-fluid"
              />
            </div>
            <div className="content-box">
              <p className="customer-name">{file?.name || "NA"}</p>
              <p className={filepath ? "order-no" : "failed-text"}>{filepath ? formatBytes(file?.size) : "Upload Failed"}</p>
            </div>
          </div>
          {/*   </Link> */}
        </a>
      </td>
      <td className="col-md-2 d-flex justify-content-end">
        {filepath ? (<button
          type="button"
          className="btn_new edit-btn m-0"
          style={{display: !isDownload ? "none" : "block"}}
          onClick={() => window.open(filepath, '_self')}
        >
          <span>
            <img
              src={downloadIcon}
              alt="download"
            />
          </span>
        </button>
        ) :
          (<button
            type="button"
            className="btn_new edit-btn"
            onClick={() => props.retry(file)}
          >
            <span>
              <img
                src={retryIcon}
                alt="download"
              />
            </span>
          </button>)}
        {![VirtualBrandMode.VIEW].includes(recipeGuideMode) && <button
          type="button"
          className="btn_new edit-btn"
          onClick={() => props.delete(props?.idx, file)}
        >
          <span>
            <img
              src={deleteIcon}
              alt="remove"
            />
          </span>
        </button>}
      </td>
    </tr>
  );
};

ListingRow.propTypes = {
  file: PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
    contact: PropTypes.string,
    roleName: PropTypes.string,
    isActive: PropTypes.number,
  }).isRequired,
};

export default ListingRow;
