import axios from "axios";
import { getBasicToken, getUserToken } from "services/local-storage";
import toastify from "shared/toastr";
import { getRefreshToken, logoutUser } from "../auth";

// we will use publicApi for not logged in screens
const publicApi = axios.create();
publicApi.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
publicApi.interceptors.request.use((req) => {
  const token = getBasicToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

publicApi.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    const errorData = err?.response?.data;
    const errorStatus = err?.response?.status;
    if (err && errorData) {
      toastify("error", errorData.message);
      switch (errorStatus) {
        case 400:
          return Promise.reject(errorData);
        case 404:
          return Promise.reject(errorData);
        default:
          return Promise.reject(errorData);
      }
    } else {
      return Promise.reject(err?.response);
    }
  }
);

// we will use privateApi for logged in screens
const privateApi = axios.create();
privateApi.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
privateApi.interceptors.request.use((req) => {
  const token = getUserToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});
privateApi.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    const errRes = err?.response;
    const originalConfig = errRes?.config;
    const errorData = errRes?.data;
    const errorStatus = errRes?.status;

    if (errRes) {
      if (errorData) toastify("error", errorData?.message);

      if (errorStatus === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        logoutUser();
        return Promise.reject(errorData);
        // await getRefreshToken()
        //   .then((res) => {
        //     return privateApi(originalConfig);
        //   })
        //   .catch((error) => {
        //     logoutUser();
        //     return Promise.reject(error);
        //   });
      }

      switch (errorStatus) {
        case 400:
          return Promise.reject(errorData);
        case 404:
          return Promise.reject(errorData);
        default:
          return Promise.reject(errorData);
      }
    } else {
      return Promise.reject(errRes);
    }
  }
);

export { publicApi, privateApi };
