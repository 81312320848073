import React, { useContext, useEffect, useState } from "react";
import { Tab, Col, Row } from "react-bootstrap";
import { getRecipeCategories } from "services/api/recipeguide";
import RecipeGuideContext from "store/recipe-guide-context";
import nextArrrowIcon from "assets/images/next-arrow.svg";
import prevArrrowIcon from "assets/images/prev-arrow.svg"
import LoaderBlock from "shared/loader/LoaderBlock";
import NoPrepStepCard from "../layout-wrapper/NoPrepStepCard";
import "./PrepStepSlideShow.scss";
import noImage from "../../../assets/images/menuitem-noimage.svg";

const PrepStepSlideShow = (props) => {
    const recipeguideCtx = useContext(RecipeGuideContext);
    const [isLoading, setisLoading] = useState(false);
    const [activeStep, setactiveStep] = useState(1);
    const modalStatus = recipeguideCtx.prepStepSlideShow;
    const modalExtras = recipeguideCtx.modalExtras;
    const [prepStepData, setprepStepData] = useState(false);
    const [resourceUrl, setResourceUrl] = useState(false);
    const [description, setDescription] = useState(false);
    const [prepStepIdx, setPrrepStepIdx] = useState(0);
    const [resourceType, setResourceType] = useState(null);
    const recipeId = recipeguideCtx.recipeId;

    useEffect(() => {
        if (modalStatus === true && recipeId) {
            getRecipeRelatedCategory();
        }
    }, [modalStatus]);

    const getRecipeRelatedCategory = async () => {
        setisLoading(true);
        await getRecipeCategories(recipeId)
            .then((res) => {
                setisLoading(false);
                const data = res?.data;
                let prepStepArray = [];
                if (data && data.length) {
                    data.map((ele) => {
                        prepStepArray.push(...ele?.recipePrepSteps)
                    })
                }
                setprepStepData(prepStepArray);
                setResourceUrl(prepStepArray[0].resourceUrl);
                setResourceType(prepStepArray[0].resourceType);
                setDescription(prepStepArray[0].description);
            })
            .catch(() => {
                setisLoading(false);
            });
    };

    const handleNextPrepStep = () => {
        var arr = prepStepData?.length;
        var idx = prepStepIdx + 1;
        var idx = idx % arr;
        setResourceUrl(prepStepData[idx].resourceUrl);
        setResourceType(prepStepData[idx].resourceType);
        setDescription(prepStepData[idx].description);
        setPrrepStepIdx(idx);
    }

    const handlePrevPrepStep = () => {
        var arr = prepStepData?.length;
        var idx = prepStepIdx;
        if (idx === 0) {
            var idx = arr - 1;
        } else {
            var idx = idx - 1;
        }
        setResourceUrl(prepStepData[idx].resourceUrl);
        setResourceType(prepStepData[idx].resourceType);
        setDescription(prepStepData[idx].description);
        setPrrepStepIdx(idx);
    }


    const closeModal = () => {
        recipeguideCtx.prepStepSlideShowChange(false);
    };

    return (
        <>
            {isLoading ? (
                <LoaderBlock />
            ) : (
                <div className="prepStepSlideShow__wrapper">
                    {prepStepData && prepStepData?.length ?
                        (<div className="tab__mainHeading">
                            <div className="form-row no-gutters">
                                <div className="img-video-wrapper mb-3 pb-3">
                                        {resourceType === "video" ?
                                            <video width="620" height="430" key={resourceUrl} className="imgStyle img-fluid" controls>
                                                <source src={resourceUrl+'#t=0.1'} />
                                            </video> :
                                            <img className="imgStyle img-fluid" src={resourceUrl ? resourceUrl : noImage} />
                                        }
                                </div>
                                <div className="col-md-12 mb-3 pb-3">
                                    <div className="prepstep-step">
                                        {'Step - ' + (prepStepIdx + 1) || "N/A"}
                                    </div>
                                    <div className="prepstep-desc">
                                        {description || "N/A"}
                                    </div>
                                </div>
                            </div>
                            <div className="prepstep-slideshow-footer col-12">
                                <button
                                    type="button"
                                    onClick={handlePrevPrepStep}
                                    className="prepstep-btn-prev"
                                    disabled={prepStepIdx === 0 ? true : false}
                                >
                                    <>
                                        <img src={prevArrrowIcon} alt="arrow icon" />
                                        <span className="ml-3">
                                            Previous
                                        </span>
                                    </>
                                </button>

                                    <div className="prepstep-pages">
                                        {(prepStepIdx + 1) + "/" +prepStepData?.length}
                                    </div>

                                <button
                                    type="button"
                                    onClick={handleNextPrepStep}
                                    className="prepstep-btn-next"
                                    disabled={prepStepIdx === (prepStepData?.length - 1) ? true : false}
                                >
                                    <>
                                        <span className="mr-3">
                                            Next
                                        </span>
                                        <img src={nextArrrowIcon} alt="arrow icon" />
                                    </>
                                </button>
                                {/*  </div> */}
                            </div>
                        </div>) : <NoPrepStepCard />}
                </div>
            )}
        </>
    );
};

export default PrepStepSlideShow;
