import React from "react";
import { Modal } from "react-bootstrap";
import "./OrderTracking.scss";

const OrderTrackingModal = (props) => {
  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            src={props?.trackingUrl || `https://portal.usenash.com/tracking/${props?.deliveryJobId}`}
            title="TrackingPortal"
            className="tracking__iframe"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderTrackingModal;
