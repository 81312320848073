import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./BasicInfo.scss";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import Ranger from "UI/Ranger/ProgressBar";
import MenuAvailability from "shared/menu-availability/MenuAvailability";
import {
  addVirtualBrandBasicInfo,
  editVirtualBrandBasicInfo,
  getVirtualBrand,
} from "services/api/brand";
import LoaderInline from "shared/loader/LoaderInline";
import VirtualBrandContext from "store/virtual-brand-context";
import LoaderFull from "shared/loader/LoaderFull";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import UserContext from "store/user-context";

const BasicInfo = (props) => {
  const minDeliveryDistance = 5;
  const maxDeliveryDistance = 50;
  const minPrepTime = 5;
  const maxPrepTime = 90;
  const minTipAmt = 2;

  const [isLoading, setisLoading] = useState(false);
  const [isLoadingOnGet, setisLoadingOnGet] = useState(false);
  const [radiusValue, setRadiusValue] = useState(minDeliveryDistance);
  const [prepTimeValue, setprepTimeValue] = useState(minPrepTime);

  const brandCtx = useContext(VirtualBrandContext);
  const brandId = brandCtx.virtualBrandId;
  const brandMode = brandCtx.virtualBrandMode;

  const userCtx = useContext(UserContext);
  const userInfo = userCtx.userInfo;

  let brandDetail = null;
  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);

  useEffect(() => {
    if (brandId) {
      getBrandDetails();
    }
  }, [brandId]);

  useEffect(() => {
    if (brandMode) {
      if (
        brandMode === VirtualBrandMode.SHARED ||
        brandMode === VirtualBrandMode.VIEW
      ) {
        setisFieldsDisabled(true);
      } else {
        setisFieldsDisabled(false);
      }
    }
  }, [brandMode]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(30, "Brand name not more than 30 characters")
      .required("Please enter brand name"),
    tagline: Yup.string()
      .trim()
      .max(120, ({ max }) => `Brand tagline not more than ${max} characters`)
      .required("Please enter brand tagline"),
    description: Yup.string()
      .trim()
      .max(500, "Brand description not more than 500 characters")
      .required("Please enter brand description"),
    deliveryRadius: Yup.number()
      .positive()
      .min(minDeliveryDistance)
      .max(maxDeliveryDistance)
      .required("Delivery Radius is required"),
    preparationTime: Yup.number()
      .positive()
      .min(minPrepTime)
      .max(maxPrepTime)
      .required("Preparation time is required"),
    tipAmount: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(0)
      .max(10000)
      .required("Please enter tip amount"),
    monday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
    tuesday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
    wednesday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
    thursday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
    friday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
    saturday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
    sunday: Yup.object().shape({
      opensAt: Yup.string().trim().required(),
      closesAt: Yup.string().trim().required(),
      isActive: Yup.boolean().required(),
    }),
  });

  const getBrandDetails = async () => {
    setisLoadingOnGet(true);
    await getVirtualBrand(brandId)
      .then((res) => {
        bindDataToForm(res.data);
        brandCtx.setVirtualBrandName(res.data.name);
        setisLoadingOnGet(false);
      })
      .catch(() => {
        setisLoadingOnGet(false);
      });
  };

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      deliveryRadius: minDeliveryDistance,
      preparationTime: minPrepTime,
      tipAmount: minTipAmt
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = methods;

  const onRadiusChange = (val) => {
    setValue("deliveryRadius", val, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setRadiusValue(val);
  };

  const onPrepTimeChange = (val) => {
    setValue("preparationTime", val, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setprepTimeValue(val);
  };

  const onSubmit = async (data) => {
    if (
      brandMode === VirtualBrandMode.SHARED ||
      brandMode === VirtualBrandMode.VIEW ||
      userInfo === brandDetail?.createdBy
    ) {
      props.nextStep();
      return;
    }

    if (brandId || !isDirty) {
      setisLoading(true);
      await editVirtualBrandBasicInfo({
        brandId: brandId,
        ...data,
      })
        .then((res) => {
          props.nextStep();
          brandCtx.setVirtualBrandInfo(res.data);
          setisLoading(false);
        })
        .catch(() => setisLoading(false));
    } else {
      await addVirtualBrandBasicInfo(data)
        .then((res) => {
          props.nextStep();
          brandCtx.setVirtualBrandInfo(res.data);
          setisLoading(false);
        })
        .catch(() => setisLoading(false));
    }
  };

  const bindDataToForm = (data) => {
    reset({
      name: data.name,
      tagline: data.tagline,
      description: data.description,
      deliveryRadius: data.deliveryRadius,
      preparationTime: data.preparationTime,
      tipAmount: data.tipAmount,
      ...data.virtualBrandAvailability,
    });
    setRadiusValue(data.deliveryRadius);
    setprepTimeValue(data.preparationTime);
    brandDetail = data;
  };
  
  return (
    <>
      {isLoadingOnGet && <LoaderFull />}
      {isLoadingOnGet ? null : (
        <>
          <div className="basic__info">
            <div className="main__heading">
              <h2>Basic Info</h2>
              {brandMode === VirtualBrandMode.VIEW ? null : (
                <p>
                  Create a name for your virtual brand and upload your branding
                  assets.
                </p>
              )}
            </div>
            <>
              <div className="basic__info__content">
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="no-gutters justify-content-between">
                      <Col sm={12} lg={5}>
                        <div className="form-row no-gutters">
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="brandName">
                              Brand Name<span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="brandName"
                              placeholder="e.g. Willie"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              {...register("name")}
                              maxLength="30"
                              disabled={isFieldsDisabled}
                            />
                            {errors?.name?.message && (
                              <div className="invalid-feedback d-block">
                                {errors?.name?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="brandTagline">
                              Brand Tagline<span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="brandTagline"
                              placeholder="e.g. tasty food"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              {...register("tagline")}
                              maxLength="120"
                              disabled={isFieldsDisabled}
                            />
                            {errors?.tagline?.message && (
                              <div className="invalid-feedback d-block">
                                {errors?.tagline?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="brandDescription">
                              Brand Description
                              <span className="mendatory">*</span>
                            </label>
                            <textarea
                              id="brandDescription"
                              placeholder="Add Description"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              {...register("description")}
                              maxLength="500"
                              rows="3"
                              disabled={isFieldsDisabled}
                            ></textarea>
                            {errors?.description?.message && (
                              <div className="invalid-feedback d-block">
                                {errors?.description?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3">
                            <div className="form-group">
                              <label htmlFor="deliveryRadius">
                                Delivery Radius
                                <span className="mendatory">*</span>
                              </label>
                              <Ranger
                                value={radiusValue}
                                setRadius={onRadiusChange}
                                minDistance={minDeliveryDistance}
                                maxDistance={maxDeliveryDistance}
                                label="mi"
                                disabled={isFieldsDisabled}
                              />
                              {errors?.deliveryRadius?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors?.deliveryRadius?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 mb-3 pb-3">
                            <div className="form-group">
                              <label htmlFor="preparationTime">
                                Estimated Preparation Time
                                <span className="mendatory">*</span>
                              </label>
                              <Ranger
                                value={prepTimeValue}
                                setRadius={onPrepTimeChange}
                                minDistance={minPrepTime}
                                maxDistance={maxPrepTime}
                                label="Min"
                                disabled={isFieldsDisabled}
                              />
                              {errors?.preparationTime?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors?.preparationTime?.message}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="tipAmount">
                              Tip Amount
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              id="tipAmount"
                              placeholder="e.g. 9.99"
                              className={`form-control ${
                                errors?.tipAmount ? "is-invalid" : ""
                              }`}
                              {...register("tipAmount")}
                              disabled={isFieldsDisabled}
                            />
                            {errors?.tipAmount?.message && (
                              <div className="invalid-feedback d-block">
                                {errors?.tipAmount?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} lg={6}>
                        <MenuAvailability
                          disabled={isFieldsDisabled}
                          text={"Default Operating Hours"}
                        />
                      </Col>
                    </Row>
                    <div className="multiForm__actions">
                      <button
                        style={{ float: "right" }}
                        className="red-btn"
                        type="submit"
                        disabled={isLoading || !isValid}
                      >
                        {isLoading ? (
                          <LoaderInline />
                        ) : (
                          <>
                            Continue
                            <span className="icons-right">
                              <img src={rightArrowIcon} alt="arrow icon" />
                            </span>
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default BasicInfo;
