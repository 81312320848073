import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Autocomplete from "react-google-autocomplete";

import emailicon from "assets/images/icon-email.svg";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import btnWhiteArrow from "assets/images/white-right-arrow-btn.svg";
import backArrowIcon from "assets/images/back-arrow.svg";

import "../Onboarding.scss";
import LoaderInline from "shared/loader/LoaderInline";
import { addKitchen } from "services/api/kitchen";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { useMediaQuery } from "react-responsive";
import GoogleMap from "UI/Map/GoogleMap";
import Geocode from "react-geocode";
import { redirectToHome } from "shared/navigation-utils/index";

const KitchenRegister = (props) => {
  const autoCompleteApiKey = process.env.REACT_APP_GOOGLE_PLACES_API;
  const [showPassword, setshowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [latVal, setLatVal] = useState();
  const [lngVal, setLngVal] = useState();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50)
      .required("Please enter the business name"),
    address: Yup.string()
      .trim()
      .max(500)
      .required("Please enter the business address"),
    latitude: Yup.number(),
    longitude: Yup.number(),
    contactName: Yup.string()
      .trim()
      .max(50)
      .required("Please enter the contact name"),
    phone: Yup.string()
      .transform((value) => (value ? value.replace(/\D/g, "") : ""))
      .test(
        "len",
        "Phone number must be exactly 10 characters long",
        (value) => value && value.length === 10
      )
      .required("Please enter the phone number"),
    listedPhoneNumber: Yup.string()
      .transform((value) => (value ? value.replace(/\D/g, "") : ""))
      .test(
        "len",
        "Phone number must be exactly 10 characters long",
        (value) => value && value.length === 10
      ),
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .max(50)
      .required("Please enter a valid email address"),
    password: Yup.string()
      .trim()
      .transform((x) => (x === "" ? undefined : x))
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[\d])(?=.*[@$!%*#?&^_-])[A-Za-z\d@$!%*#?&^_-]{8,15}$/,
        "Password allows 8-15 alphanumeric characters with at least 1 digit and 1 special character [@$!%*#?&^_-]"
      ),
    termAndCond: Yup.boolean()
      .default(false)
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const togglePassword = () => {
    setshowPassword(!showPassword);
  };

  const onChangeAddress = (place) => {
     const selectedAddress = document.getElementById("address").value;
     Geocode.setApiKey(autoCompleteApiKey);
     Geocode.fromAddress(selectedAddress)
       .then((response) => {
         const result = response.results[0];
         if (result) {
           setValue("address", result.formatted_address);
           document.getElementById("address").value = result?.formatted_address;
           setValue("latitude", result.geometry.location.lat);
           setValue("longitude", result.geometry.location.lng);
           setLatVal(result.geometry.location.lat);
           setLngVal(result.geometry.location.lng);
         } else {
           window.alert("No results found");
         }
       })
       .catch((e) => window.alert("Geocoder failed due to: " + e));
  };

  const onSubmit = async (data) => {
    setisLoading(true);
    await addKitchen(data)
      .then(() => {
        setisLoading(false);
        props.setEmail(data.email);
        props.nextStep();
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const geocodeData = (lat, lng, type) => {
    Geocode.setApiKey(autoCompleteApiKey);
    Geocode.fromLatLng(lat, lng)
      .then((response) => {
        const result = response.results[0];
        if (result) {
          setValue("address", result?.formatted_address);
          document.getElementById("address").value = result?.formatted_address;
          if (type === "marker") {
            const location = result?.geometry?.location;
            setValue("latitude", location?.lat);
            setValue("longitude", location?.lng);
            setLatVal(location?.lat);
            setLngVal(location?.lng);
          }
          else {
            setValue("latitude", lat);
            setValue("longitude", lng);
            setLatVal(lat);
            setLngVal(lng);
          }
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    Geocode.setApiKey(autoCompleteApiKey);
    geocodeData(lat, lng, 'marker')
  };

  const onLatLngChange = () => {
    const latEle = document.getElementById("lat");
    const lngEle = document.getElementById("lng");
    if (latEle?.value && lngEle?.value) {
      geocodeData((latEle.value), (lngEle.value), '');
    }
  };

  return (
    <>
      <Subheader
        title={isMobile ? null : "Sign Up"}
        extraClass="non-auth-subheader-desktop"
      >
        <div className="goBack mb-3">
          <Link to="/home" className="back-btn-red" title="back to Home">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Home
          </Link>
        </div>
      </Subheader>
      <div className="signIn__wrapper about__business__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3 pb-3 d-none d-md-block">
              <div className="goBack">
                <button
                  className="back-btn-red"
                  title="back to home"
                  onClick={redirectToHome}
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back to Home
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="loginHeading__wrapper">
                <h1>Tell Us About Your Business</h1>
                <p>
                  Let’s create your account first. Please provide the details.
                </p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="signcard__wrapper">
              <div className="row no-gutters">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="leftcard">
                    <div className="leftcard__inner">
                      <div className="form-row no-gutters">
                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="bname">
                            Business Name<span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="bname"
                            placeholder="e.g. True’s Pizza"
                            maxLength="50"
                            {...register("name")}
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                          />
                          {errors.name?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.name?.message}
                            </div>
                          )}
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="address">
                            Business Address<span className="mendatory">*</span>
                          </label>
                          <Autocomplete
                            id="address"
                            apiKey={autoCompleteApiKey}
                            onPlaceSelected={onChangeAddress}
                            inputAutocompleteValue="address"
                            autoComplete="address"
                            placeholder="e.g. 3045 Eagandale Place, Eagan, MN, USA"
                            className={`form-control ${
                              errors.address ? "is-invalid" : ""
                            }`}
                            options={{ types: ["address"] }}
                          />
                          {errors.address?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.address?.message}
                            </div>
                          )}
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                          <div className="detailCard restra__g__map">
                            <GoogleMap
                              width="100%"
                              height="243px"
                              latitude={latVal || 45.552509}
                              longitude={lngVal || -94.196804}
                              draggable={true}
                              onMarkerDragEnd={onMarkerDragEnd}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 pb-3 d-flex">
                          <div className="col-md-6 pl-0">
                            <label htmlFor="lat">
                              Latitude<span className="mendatory">*</span>
                            </label>
                            <input
                              id="lat"
                              placeholder="e.g. Latitude"
                              {...register("latitude")}
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              onChange={onLatLngChange}
                            />
                            {errors.name?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.name?.message}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 pr-0">
                            <label htmlFor="lng">
                              Longitude<span className="mendatory">*</span>
                            </label>
                            <input
                              id="lng"
                              placeholder="e.g. Longitude"
                              {...register("longitude")}
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              onChange={onLatLngChange}
                            />
                            {errors.name?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.name?.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="conatctName">
                            Contact Name<span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="conatctName"
                            placeholder="e.g. Matthew True"
                            maxLength="50"
                            {...register("contactName")}
                            className={`form-control ${
                              errors.contactName ? "is-invalid" : ""
                            }`}
                          />
                          {errors.contactName?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.contactName?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="conatctNo">
                            Phone Number<span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="conatctNo"
                            placeholder="e.g. 234 567 8907"
                            {...register("phone")}
                            className={`form-control ${
                              errors.phone ? "is-invalid" : ""
                            }`}
                          />
                          {errors.phone?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.phone?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="listedNo">
                            Order Confirm Phone Number
                          </label>
                          <input
                            type="text"
                            id="listedNo"
                            placeholder="e.g. 234 567 8907"
                            {...register("listedPhoneNumber")}
                            className={`form-control ${
                              errors.listedPhoneNumber ? "is-invalid" : ""
                            }`}
                          />
                          {errors.listedPhoneNumber?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.listedPhoneNumber?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="email">
                            Email Address<span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="email"
                            placeholder="Enter your email address"
                            maxLength="50"
                            {...register("email")}
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <span className="input-icon d-none">
                            <img src={emailicon} alt="" />
                          </span>
                          {errors.email?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.email?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-3 pb-3">
                          <label htmlFor="password">
                            Password<span className="mendatory">*</span>
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            placeholder="Enter your password"
                            {...register("password")}
                            maxLength="50"
                            className={`form-control ${
                              errors.password ? "is-invalid" : ""
                            }`}
                          />
                          <span className="input-icon">
                            <img
                              onClick={togglePassword}
                              src={
                                showPassword ? showpasswordicon : passwordicon
                              }
                              alt={showPassword ? "hide" : "show"}
                              title={showPassword ? "hide" : "show"}
                            />
                          </span>
                          {errors.password?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.password?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="custom-checkbox-wrapper">
                            <label className="custom-checkbox-container">
                              <input
                                type="checkbox"
                                {...register("termAndCond")}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <span className="terms-text">
                              By signing up, you are accepting the{" "}
                              {/*  <Link to="/agreement" target="_blank">
                                restaurant agreement
                              </Link>
                              ,{" "} */}
                              <Link to="/privacy-policy" target="_blank">
                                privacy policy
                              </Link>{" "}
                              and{" "}
                              <Link to="/terms-of-use" target="_blank">
                                terms of use
                              </Link>{" "}
                              of this platform.
                            </span>
                          </div>
                          {errors.termAndCond?.message && (
                            <div className="invalid-feedback d-block position-absolute">
                              {errors.termAndCond?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-3 pb-3 action__wrapper">
                          <button type="submit" disabled={isLoading}>
                            {isLoading ? (
                              <LoaderInline />
                            ) : (
                              <>
                                Complete Signup{" "}
                                <img
                                  className="icon"
                                  src={btnWhiteArrow}
                                  alt="arrow"
                                />
                              </>
                            )}
                          </button>
                        </div>
                        <div className="col-12 d-md-none righside__mobile">
                          <div className="or">
                            <p>OR</p>
                          </div>
                          <div className="loginHeading__wrapper">
                            <h1>Already have an account?</h1>
                          </div>
                          <div className="rightside">
                            <div className="content__box">
                              <div className="action__wrapper">
                                <Link to="/login">
                                  <button type="button">Sign In</button>
                                </Link>
                              </div>
                              <div className="rightside__links">
                                <Link
                                  to="/how-it-works"
                                  className="rightside__link"
                                >
                                  How it Works
                                </Link>
                                <Link to="/faq" className="rightside__link">
                                  FAQs
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 d-none d-md-block">
                  <div className="rightside">
                    <div className="rightside__bg aboutBusiness__bg">
                      <div className="content__box">
                        <h2>Already have an account?</h2>
                        <div className="action__wrapper">
                          <Link to="/login">
                            <button
                              type="button"
                              className="red-button-filled-hover"
                            >
                              Login
                            </button>
                          </Link>
                        </div>
                        <div className="rightside__links">
                          <Link to="/how-it-works" className="rightside__link">
                            How it Works
                          </Link>
                          <Link to="/faq" className="rightside__link">
                            FAQs
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default KitchenRegister;
