import React, { useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { DeviceStatus } from "constants/deviceStatus";
import { changeDeviceStatus, deleteDevice } from "services/api/device";
import toastify from "shared/toastr";

const ChangeStatus = ({
  needDropdown = true,
  deviceId,
  onComplete,
  deviceStatus,
}) => {
  const [status, setstatus] = useState(deviceStatus === DeviceStatus.ACTIVE);

  const onDelete = async () => {
    await deleteDevice(deviceId).then((res) => {
      onComplete();
      toastify("success", res.message);
    });
  };

  const onChangeStatus = async () => {
    let deviceStatus = status ? DeviceStatus.INACTIVE : DeviceStatus.ACTIVE;
    await changeDeviceStatus(deviceId, deviceStatus).then((res) => {
      setstatus(deviceStatus === DeviceStatus.ACTIVE);
      toastify("success", res.message);
    });
  };

  return (
    <>
      <div className="right_content">
        <span
          className={`custom__badge ${status ? "badge__red" : "badge__gray"}`}
        >
          {status ? "Active" : "Inactive"}
        </span>
        {needDropdown ? (
          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdown-button-drop-left"
            ></button>
            <div className="dropdown-menu">
            <div className="dropdown__innerWrapper">
                <button onClick={onChangeStatus} className="dropdown-item">
                  <span>Set As {status ? "Inactive" : "Active"}</span>
                </button>
              </div>
              <div className="dropdown__innerWrapper">
                <Link
                  to={"/device/" + deviceId + "/edit"}
                  className="dropdown-item"
                >
                  <span>Edit</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <button onClick={onDelete} className="dropdown-item">
                  <span>Delete</span>
                </button>
              </div>  
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

ChangeStatus.propTypes = {
  deviceStatus: PropTypes.number,
  deviceId: PropTypes.number,
  onComplete: PropTypes.func,
};

export default ChangeStatus;
