import React, { useContext } from "react";
import "./AddNewBrand.scss";
import addIcon from "../../assets/images/addIcon.svg";
import { Link } from "react-router-dom";
import VirtualBrandContext from "store/virtual-brand-context";

function AddNewBrand() {
  const brandCtx = useContext(VirtualBrandContext);

  const clearBrandId = () => {
    brandCtx.resetAllVariables();
  };

  return (
    <div className="create__card">
      <div className="card__inner">
        <div className="logo-image-wrapper">
          <div className="inner-content">
            <div className="icon">
              <img src={addIcon} alt="add icon" />
            </div>
            <Link
              to="/brand/add"
              className="new-virtual-btn-create"
              title="click to create"
              onClick={clearBrandId}
            >
              Create a New Virtual Brand
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewBrand;
