import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./RecipeGuideBasicInfo.scss";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import Ranger from "UI/Ranger/ProgressBar";
import MenuItemImage from "./MenuItemImage";
import {
    GetMenuItemsWithRecipeGuide,
    GetRecipeGuideBasicInfo,
    addRecipeGuideBasicInfo, editRecipeGuideBasicInfo
} from "services/api/recipeguide";
import LoaderInline from "shared/loader/LoaderInline";
import RecipeGuideContext from "store/recipe-guide-context";
import LoaderFull from "shared/loader/LoaderFull";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import { useParams, useHistory } from "react-router";
import MenuItemFilter from "../listing/MenuItemFilter";
import PopUpModal from "UI/Modal/PopUpModal";

const RecipeGuideBasicInfo = (props) => {
    const minPrepTime = 5;
    const maxPrepTime = 90;
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingOnGet, setisLoadingOnGet] = useState(false);
    const [prepTimeValue, setprepTimeValue] = useState(minPrepTime);
    const { brandId } = useParams("brandId");
    const recipeguideCtx = useContext(RecipeGuideContext);
    const menuItemId = recipeguideCtx.virtualBrandMenuItemId;
    const recipeGuideId = recipeguideCtx.recipeGuideId;
    const recipeGuideMode = recipeguideCtx.recipeGuideMode;
    const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
    const [selectedMenuItemId, setselectedMenuItemId] = useState(null);
    const [menuItems, setmenuItems] = useState([]);
    const [menuItemImage, setmenuItemImage] = useState(null);
    const [vbName, setvbName] = useState(null);
    const [selectedMenuItem, setselectedMenuItem] = useState(null);
    const [showPopUp, setshowPopUp] = useState(false);

    useEffect(() => {
        if (brandId) {
            getRecipeGuideDetails();
            if (recipeGuideId || menuItemId) {
                getRecipeGuideBasicInfo();
            }
        }
    }, [brandId, recipeGuideId]);

    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .trim()
            .max(500, ({ max }) => `Recipe Description not more than ${max} characters`)
            .required("Please enter brand description"),
        preparationTime: Yup.number()
            .positive()
            .min(minPrepTime)
            .max(maxPrepTime)
            .required("Preparation time is required"),
    });

    const getRecipeGuideDetails = async () => {
        setisLoadingOnGet(true);
        await GetMenuItemsWithRecipeGuide(brandId)
            .then((res) => {
                const data = res.data?.menuItemWithRecipeGuide;
                setvbName( res.data?.virtualBrandName);
                if (data && data.length) {
                    const menuItems = data.map((res) => ({
                        value: res.id,
                        label: res?.recipeGuideId && res?.recipeGuideId !== recipeGuideId ? res.itemName  + " - NA" : res.itemName,
                        imageUrl: res.coverURL,
                        recipeGuideId: res?.recipeGuideId
                    }));
                    setmenuItems(menuItems);
                } else setmenuItems([{ value: 0, label: "No Menu Items" }]);
                setisLoadingOnGet(false);
            })
            .catch(() => {
                setisLoadingOnGet(false);
            });
    };

    const getRecipeGuideBasicInfo = async () => {
        setisLoadingOnGet(true);
        await GetRecipeGuideBasicInfo(brandId, 0, recipeGuideId)
            .then((res) => {
                setselectedMenuItem(res.data?.title);
                setselectedMenuItemId(res.data?.menuItemId);
                recipeguideCtx.setRecipeGuideInfo(res.data);
                setmenuItemImage(res.data?.imageUrl);
                bindDataToForm(res.data);
                setisLoadingOnGet(false);
            })
            .catch(() => {
                setisLoadingOnGet(false);
            });
    }

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            preparationTime: minPrepTime
        },
    });

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isValid, isDirty },
    } = methods;

    const onPrepTimeChange = (val) => {
        setValue("preparationTime", val, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        setprepTimeValue(val);
    };

    const onSubmit = async (data) => {
        var error = document.getElementById("error");
        if (!selectedMenuItemId) {
            error.textContent = "Please select Menu Item";
        }
        else {
            const model = {
                brandId: brandId,
                menuItemId: selectedMenuItemId,
                menuItem: selectedMenuItem,
                menuItemImage: menuItemImage,
                ...data
            }
            error.textContent = "";
            setisLoading(true);
            if ((recipeGuideId) || !isDirty) {
                await editRecipeGuideBasicInfo(recipeGuideId, model)
                    .then((res) => {
                        props.nextStep(recipeGuideId);
                        recipeguideCtx.saveMenuItemId(selectedMenuItemId);
                        recipeguideCtx.saveVBId(brandId);
                        recipeguideCtx.setRecipeGuideInfo({id: recipeGuideId, title: selectedMenuItem});
                        setisLoading(false);
                    })
                    .catch(() => setisLoading(false));
            } else {
                await addRecipeGuideBasicInfo(model)
                    .then((res) => {
                        props.nextStep(res?.data);
                        recipeguideCtx.saveMenuItemId(selectedMenuItemId);
                        recipeguideCtx.setRecipeGuideInfo({id: res?.data, title: selectedMenuItem});
                        recipeguideCtx.saveVBId(brandId);
                        setisLoading(false);
                    })
                    .catch(() => setisLoading(false));
            }
        }
    };

    const bindDataToForm = (data) => {
        reset({
            description: data.description,
            preparationTime: data.estimatedPrepTimeInMinutes,
        });
        setprepTimeValue(data.estimatedPrepTimeInMinutes);
    };
    const onMenuItemSelect = (data) => {
        if (data) {
            if (data?.recipeGuideId && data?.recipeGuideId !== recipeGuideId) {
                setshowPopUp(true);
            }
            else {
                setselectedMenuItemId(data.value);
                setmenuItemImage(data.imageUrl);
                setselectedMenuItem(data.label);
            }
        }
    };

    const onCancel = () => setshowPopUp(false);

    return (
        <>
            {isLoadingOnGet ? <LoaderFull /> : (
                <>
                    <div className="basic__info">
                        <div className="main__heading">
                            <h2>Basic Info</h2>
                            {recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                                <p>
                                    Create a Recipe Guide and enter all the details.
                                </p>
                            )}
                        </div>
                        <>

                            <>
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="rg_basic__info__content">
                                            <div className="sub_heading">Recipe Guide Details</div>
                                            <div className="sub_heading1">Which menu item do you want to create a recipe guide for?</div>
                                            <Row className="no-gutters justify-content-between" style={{ marginTop: "40px" }}>
                                                <Col sm={12} lg={5}>
                                                    <div className="form-row no-gutters">
                                        
                                                        <div className="col-md-12 mb-3 pb-3">
                                                            <label htmlFor="brandTagline">
                                                                Virtual Brand
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="brandTagline"
                                                                placeholder="e.g. tasty food"
                                                                className={`form-control`}
                                                                value={vbName || "Virtual Brand Name"}
                                                                maxLength="120"
                                                                disabled={true}
                                                            />
                                                        </div>

                                                        <div className="col-md-12 mb-3 pb-3">
                                                            <label htmlFor="menuItem">
                                                                Menu Item/Recipe Guide Name<span className="mendatory">*</span>
                                                            </label>
                                                            <MenuItemFilter
                                                                value={selectedMenuItemId}
                                                                onSelect={onMenuItemSelect}
                                                                data={menuItems}
                                                                selectedMenuItem={selectedMenuItem}
                                                            />
                                
                                                            <span className="invalid-feedback d-block" id="error"></span>
                                                        </div>

                                                        <div className="col-md-12 mb-3 pb-3">
                                                            <label htmlFor="brandDescription">
                                                                Recipe Guide Description<span className="mendatory">*</span>
                                                            </label>
                                                            <textarea
                                                                id="brandDescription"
                                                                placeholder="Add Description"
                                                                className={`form-control ${errors.name ? "is-invalid" : ""
                                                                    }`}
                                                                {...register("description")}
                                                                maxLength="500"
                                                                rows="3"
                                                                disabled={isFieldsDisabled}
                                                            ></textarea>
                                                            {errors?.description?.message && (
                                                                <div className="invalid-feedback d-block">
                                                                    {errors?.description?.message}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-md-12 mb-3 pb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="preparationTime">
                                                                    Estimated Prep Time
                                                                    <span className="mendatory">*</span>
                                                                </label>
                                                                <Ranger
                                                                    value={prepTimeValue}
                                                                    setRadius={onPrepTimeChange}
                                                                    minDistance={minPrepTime}
                                                                    maxDistance={maxPrepTime}
                                                                    label="Min"
                                                                    disabled={isFieldsDisabled}
                                                                />
                                                                {errors?.preparationTime?.message && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errors?.preparationTime?.message}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={12} lg={6}>
                                                    <MenuItemImage
                                                        imageUrl={menuItemImage}
                                                        vbName={vbName}
                                                        menuItemName={selectedMenuItem}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="multiForm__actions">
                                            <button
                                                style={{ float: "right" }}
                                                className="red-btn"
                                                type="submit"
                                                disabled={isLoading || !isValid}
                                            >
                                                {isLoading ? (
                                                    <LoaderInline />
                                                ) : (
                                                    <>
                                                        Continue
                                                        <span className="icons-right">
                                                            <img src={rightArrowIcon} alt="arrow icon" />
                                                        </span>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>
                            </>
                        </>
                    </div>
                </>
            )}
            {showPopUp && (
                <PopUpModal
                    show={showPopUp}
                    title="Recipe guide already exists"
                    subtitle={`Recipe guide already exists. Please Choose other Menu Item`}
                    onCancel={onCancel}
                    onSubmit={onCancel}
                    onLoading={false}
        />
      )}
        </>
    );
};

export default RecipeGuideBasicInfo;
