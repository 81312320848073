import React, { useState } from "react";
import { RecipeGuideSelectDropdownSearch } from "./RecipeGuideSelectDropdownSearch";

const MenuItemFilter = (props) => {
  const intialValue = props.value;
  const onSelect = props.onSelect;
  const [selectedMenuItem, setselectedMenuItem] = useState(intialValue);
  const data = props?.data;

  const onMenuItemValueSelected = (val) => {
    setselectedMenuItem(val.value);
    onSelect(val);
  };

  return (
    <>
      {data && (
        <RecipeGuideSelectDropdownSearch
          options={data}
          placeholder="Select Option"
          selectedValue={onMenuItemValueSelected}
          value={selectedMenuItem}
          searchPlaceholder="Enter Menu item to Search"
          disabled={props?.disabled}  
          selectedMenuItem={props?.selectedMenuItem}       
        />
      )}
    </>
  );
};

export default MenuItemFilter;
