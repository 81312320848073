import React, { useEffect } from "react";
import { getBasicToken } from "services/api/auth";
import Header from "./header/Header";
import Footer from "./footer/Footer";

const AuthLayout = (props) => {
  useEffect(() => {
    getBasicToken();
  }, []);
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
};

export default AuthLayout;
