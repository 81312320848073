import React from "react";
import "./AddButton.scss";
import plusIcon from "../../assets/images/add-icon-white.svg";

function AddDropdownButton(props) {
    return (
        <div    className="button__wrapper threeDotsBropdown">
            <button type="button" className="bg-blue-btn" data-toggle="dropdown">
                <span className="icons">
                    <img src={plusIcon} alt="plus icon" />
                </span>
                {props?.title}
            </button>
            <div className="dropdown-menu">
                <div className="dropdown__innerWrapper">
                    <button
                        type="button"
                        className="dropdown-item"
                        onClick={props.openRecipeModal}
                    >
                        <span>Select New Recipe</span>
                    </button>
                </div>
                <div className="dropdown__innerWrapper">
                    <button
                        type="button"
                        className="dropdown-item"
                        onClick={props.openModal}
                    >
                        <span>Add New Recipe</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddDropdownButton;
