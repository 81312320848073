import React from 'react'
import './errorpage.scss'
import image500 from '../../assets/images/500.svg'

const page500 = () => {
    return (
        <div className="errorPage__wrapper">
            <div className="error__box">
                <div className="error__imgbox">
                    <img src={image500} alt="404 image" className="img-fluid" />
                </div>
                <div className="error__details">
                    <h1>Something is Wrong</h1>
                    <p>We are having some issues at the moment. Please try again later.</p>
                    <button className="error-btn">Refresh Page</button>
                </div>
            </div>
        </div>
    )
}

export default page500
