import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./MyAccount.scss";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import { changePassword } from "services/api/auth";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const ChangeProfilePassword = (props) => {
  const [showPassword, setshowPassword] = useState(false);
  const [showCnfmPassword, setshowCnfmPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .trim()
      .transform((x) => (x === "" ? undefined : x))
      .required("Please enter your current password")
      .max(50, ({ max }) => `Password not more than ${max} characters`),
    newPassword: Yup.string()
      .trim()
      .transform((x) => (x === "" ? undefined : x))
      .required("Please enter your new password")
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .matches(
        /^(?=.*[\d])(?=.*[@$!%*#?&^_-])[A-Za-z\d@$!%*#?&^_-]{8,15}$/,
        "New Password allows 8-15 alphanumeric characters with at least 1 digit and 1 special character [@$!%*#?&^_-]"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const togglePassword = () => setshowPassword(!showPassword);
  const toggleCnfmPassword = () => setshowCnfmPassword(!showCnfmPassword);

  const onSubmit = async (data) => {
    setisLoading(true);
    await changePassword(data.oldPassword, data.newPassword).then(
      (res) => {
        toastify("success", res.message);
        setisLoading(false);
        onBack();
      },
      () => setisLoading(false)
    );
  };

  const onBack = () => {
    props.history.push("/profile");
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="changePassword___wrapper">
        <div className="users__wrapper">
          <div className="modifierDetails__wrapper modifierEdit__wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="goBack">
                    <button
                      type="button"
                      onClick={onBack}
                      className=" back-btn-red"
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back
                    </button>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Change Password</h1>
                  </div>
                </div>
                <div className="col-12">
                  <div className="details__wrapper editModifier__wrapper">
                    <div className="details__wrapper__inner">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="basic__info__content">
                          <div className="form-row no-gutters">
                            <div className="col-md-12 px-0 px-md-3 mb-3 pb-3">
                              <label htmlFor="password">
                                Current Password<span className="mendatory">*</span>
                              </label>
                              <input
                                id="password"
                                placeholder="Enter your current password"
                                type={showPassword ? "text" : "password"}
                                {...register("oldPassword")}
                                maxLength="50"
                                className={`form-control ${
                                  errors.oldPassword ? "is-invalid" : ""
                                }`}
                              />
                              <span className="input-icon">
                                <img
                                  onClick={togglePassword}
                                  src={
                                    showPassword
                                      ? showpasswordicon
                                      : passwordicon
                                  }
                                  alt={showPassword ? "hide" : "show"}
                                  title={showPassword ? "hide" : "show"}
                                />
                              </span>
                              {errors.oldPassword?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.oldPassword?.message}
                                </div>
                              )}
                            </div>

                            <div className="col-md-12 px-0 px-md-3 mb-3">
                              <label htmlFor="newpassword">
                                New Password<span className="mendatory">*</span>
                              </label>
                              <input
                                id="newpassword"
                                placeholder="Enter your new password"
                                type={showCnfmPassword ? "text" : "password"}
                                {...register("newPassword")}
                                maxLength="50"
                                className={`form-control ${
                                  errors.newPassword ? "is-invalid" : ""
                                }`}
                              />
                              <span className="input-icon">
                                <img
                                  onClick={toggleCnfmPassword}
                                  src={
                                    showCnfmPassword
                                      ? showpasswordicon
                                      : passwordicon
                                  }
                                  alt={showCnfmPassword ? "hide" : "show"}
                                  title={showCnfmPassword ? "hide" : "show"}
                                />
                              </span>
                              {errors.newPassword?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.newPassword?.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 px-0">
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="cancel-btn red-btn-ghost btn"
                                onClick={onBack}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="red-btn next-btn btn"
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <LoaderInline />
                                ) : (
                                  "Change Password"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeProfilePassword;
