import React, { useContext, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { changePrepStepStatus, deletePrepStep } from "services/api/recipeguide";
import toastify from "shared/toastr";
import EditButton from "UI/Button/EditButton";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import ImageSrc from "shared/image-src/ImageSrc";
import RecipeGuideContext from "store/recipe-guide-context";
import playbuttonIcon from "assets/images/bx-play-circle.svg";

const PrepStepCard = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const editable = recipeguideCtx.isConfirmPage;
  const prepStepId = props.id;
  const categoryId = props.categoryId;
  const [activeStatus, setactiveStatus] = useState(props.isActive);
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const onChangePrepStepStatus = async () => {
      await changePrepStepStatus(prepStepId, !activeStatus).then((res) => {
        toastify("success", res?.message);
        setactiveStatus(!activeStatus);
      });
  };

  const onView = () => {
    recipeguideCtx.prepStepModalStatusChange(true, {
      editMode: false,
      cloneMode: false,
      viewMode: true,
      data: props,
    });
    recipeguideCtx.saveRecipeCategoryId(categoryId);
  };

  const onEdit = () => {
    recipeguideCtx.prepStepModalStatusChange(true, {
      editMode: true,
      cloneMode: false,
      viewMode: false,
      data: props,
    });
    recipeguideCtx.saveRecipeCategoryId(categoryId);
  };

 /*  const onClone = () => {
    recipeguideCtx.prepStepModalStatusChange(true, {
      editMode: false,
      cloneMode: true,
      viewMode: false,
      data: props,
    });
    recipeguideCtx.saveRecipeCategoryId(categoryId);
  }; */

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);
  const onDelete = async () => {
    setdeleteLoading(true);
    await deletePrepStep(prepStepId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  return (
    <>
      <div className="menuItem__card">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className="menuItem__card__details__wrapper">
              <div className="img-box">
                {props?.resourceType === "video" ? 
                  <>
                    <video key={props.resourceUrl} className="img-fluid cursor__pointer"  
                    onClick={recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? onView : onEdit}>
                      <source src={props.resourceUrl+'#t=0.1'} />
                    </video>
                    <button type="button" className="videoplaybtn">
                      <img src={playbuttonIcon} alt="recipe video" />
                    </button>
                  </>
                 : <ImageSrc 
                  className="img-fluid cursor__pointer"
                  orgImage={props.resourceUrl}
                  rawImage={props.resourceUrl}
                  onClick={recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? onView : onEdit}
                />}
              </div>
              <div className="menuItem_desc">
                <h5 className="cursor__pointer" onClick={recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? onView : onEdit}>
                  {"STEP " + (props?.index + 1)}
                  {editable ? (
                    recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? (
                      <ViewButton onClick={onView} />
                    ) : (
                      <EditButton onClick={onEdit} />
                    )
                  ) : null}
                </h5>
                <p  className="cursor__pointer" onClick={recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? onView : onEdit}>{props?.description}</p>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-md-6 d-md-none">
            <div className="basePrice text-uppercase">
            </div>
          </div> */}

          {recipeGuideMode === VirtualBrandMode.SHARED ||
            recipeGuideMode === VirtualBrandMode.VIEW ? null : (
            <div className="threedotsbtn btn-group dropleft">
              <DropdownButton
                key="left"
                id="dropdown-button-drop-left"
                drop="left"
                variant="secondary"
                className="leftDrowpdown"
              >
                {/* <Dropdown.Item onClick={onClone}>
                  <div className="dropdown__innerWrapper">
                    <span>Clone Item</span>
                  </div>
                </Dropdown.Item> */}
                <Dropdown.Item onClick={onChangePrepStepStatus}>
                  <div className="dropdown__innerWrapper">
                    <span>Set As {activeStatus ? "Inactive" : "Active"}</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={onEdit}>
                  <div className="dropdown__innerWrapper">
                    <span>Edit PrepStep</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={openDeleteModal}>
                  <div className="dropdown__innerWrapper">
                    <span>Delete PrepStep</span>
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          )}

          {/*    <div className="col-12 col-md-5">
            <div className="menuItem_price">
              <p>${props.pickupPrice?.toFixed(2)}</p>
            </div>
            <div className="menuItem_price">
              <p>{publishStatus ? "Active" : "Inactive"}</p>
            </div>
          </div> */}
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Prep Step"
          subtitle="Are you sure want to delete this prep step"
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

PrepStepCard.propTypes = {
  id: PropTypes.number,
  description: PropTypes.string,
  resourceUrl: PropTypes.string,
  isActive: PropTypes.bool,
  recipeCategoryId: PropTypes.number,
  categoryId: PropTypes.number,
  resourceType: PropTypes.string,
  key: PropTypes.number,
  index: PropTypes.number
};

export default PrepStepCard;
