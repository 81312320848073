import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import MarketPlaceRow from "./MarketPlaceRow";
import { useContext } from "react";
import VirtualBrandContext from "store/virtual-brand-context";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";
import LoaderInline from "shared/loader/LoaderInline";
import { BrandStatus } from "constants/brandStatus";
import { changeBrandStatus } from "services/api/brand";
import toastify from "shared/toastr";
import CredentialRow from "./CredentialRow";
import CommissionRow from "./CommissionRow";
import plusIcon from "assets/images/add-icon-white.svg";
import { RoleTypeId } from "constants/roleType";

const BrandRow = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const userCtx = useContext(UserContext);
  const userInfo = userCtx.userInfo;
  const userRole = userCtx.userRole;

  const kitchen = props?.kitchen;
  const kitchenAssociationId = props?.brand?.id;
  const brand = props?.brand?.virtualBrand;
  const [marketPlaces, setMarketPlaces] = useState(props?.brand?.marketPlace);

  const [credentials, setcredentials] = useState({
    webhookUrl: props?.brand?.webhookUrl,
    apiKey: props?.brand?.chowlyAPIKey,
    storeId: props?.brand?.storeId,
    partnerId: props?.brand?.partnerId
  });

  const [commissions, setcommissions] = useState({
    deliveryCommission: props?.brand?.bhbDeliveryCommissionPerc,
    pickupCommission: props?.brand?.bhbPickupCommissionPerc,
    affiliateUserId: props?.brand?.affiliateUserId,
    affiliatePayout: props?.brand?.affiliatePayout,
    brandOwnerId: props?.brand?.brandOwnerId,
    brandOwnerPayout: props?.brand?.brandOwnerPayout,
    restaurantPayout: props?.brand?.restaurantPayout,
    affiliatePickupRate: props?.brand?.affiliatePickupRate,
    affiliateDeliveryRate: props?.brand?.affiliateDeliveryRate,
  });

  const brandId = brand.id;
  const isAssociated = kitchenAssociationId > 0;

  const isSharedBrand =
    [RoleTypeId.ADMIN, RoleTypeId.ADMIN_STAFF, RoleTypeId.BRAND_OWNER].includes(
      brand?.creatorRole
    ) &&
    (isAssociated || kitchen?.id);

  const [brandStatus, setbrandStatus] = useState(brand.statusId);
  const [isLoading, setisLoading] = useState(false);
  const [currentStatus, setcurrentStatus] = useState({
    class: null,
    text: null,
  });

  const [accordionState, toggleaccordionState] = useState(false);
  const [orderingPlatformAccordionState, toggleorderingPlatformAccordionState] = useState(false);

  useEffect(() => {
    setStatus(brandStatus);
  }, [brand]);

  const toggleAccordion = () => {
    toggleaccordionState(!accordionState);
  };

  const toggleOrderingPlatformAccordion = () => {
    toggleorderingPlatformAccordionState(!orderingPlatformAccordionState);
  }

  const onViewLink = () => {
    brandCtx.resetAllVariables();
    let link = `/brand/${brand?.id}?step=4#view`;
    if (isSharedBrand) {
      link = `/brand/${brand?.id}/shared/${kitchen?.id}?step=4#shared-view`;
    }
    return link;
  };

  const onEditLink = () => {
    brandCtx.resetAllVariables();
    let link = `/brand/${brand?.id}?step=4#edit`;
    if (isSharedBrand) {
      link = `/brand/${brand?.id}/shared/${kitchen?.id}?step=4#shared-edit`;
      return link;
    }
    // if (brand?.createdBy === userInfo?.userId) {
    //   link = `/brand/${brand?.id}?step=4#edit`;
    //   return link;
    // }
    return link;
  };

  const setStatus = (statusId) => {
    switch (statusId) {
      case BrandStatus.DRAFT:
        setcurrentStatus({ class: "badge__gray", text: "Draft" });
        break;
      case BrandStatus.PUBLISHED:
        setcurrentStatus({ class: "badge__red", text: "Published" });
        break;
      case BrandStatus.UNPUBLISHED:
        setcurrentStatus({ class: "badge__gray", text: "Unpublished" });
        break;
      case BrandStatus.PENDING:
        setcurrentStatus({ class: "badge__blue", text: "Pending" });
        break;
      case BrandStatus.PUBLISHING:
        setcurrentStatus({ class: "badge__blue", text: "Publishing" });
        break;
      default:
        setcurrentStatus({ class: "badge__blue", text: "" });
    }
  };

  const onStatusChange = async (statusId) => {
    setisLoading(true);
    await changeBrandStatus(brandId, statusId, kitchen?.id)
      .then((res) => {
        toastify("success", res.message);
        setStatus(statusId);
        setbrandStatus(statusId);
        setisLoading(false);

        props.onReload();
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const onUpdateCredential = (data) => {
    setcredentials(data);
  };

  const onUpdateCommission = (data) => {
    setcommissions(data);
  };

  const updateCount = (id) => {
   setMarketPlaces((ele) => ele.filter((item) => item.id !== id));
  }

  return (
    <>
      <div className="market__place__card">
        <div className="shared__vbs__wrapper__inner">
          <div className="accodion__header__content">
            <div className="mobile-screen-label d-md-none">
              <span>Name</span>
            </div>
            <div className="heading">
              <span>{brand?.name}</span>
            </div>
            <div className="right__side__content">
              <div className="status__wrapper mr-3">
                <div className="mobile-screen-label d-md-none">
                  <span>Actions</span>
                </div>
                <div className={`custom__badge ${currentStatus.class}`}>
                  {isLoading ? <LoaderInline /> : currentStatus.text}
                </div>
              </div>
              <div className="btn-group dropleft threeDotsBropdown drop-icon">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="dropdown-button-drop-left"
                ></button>
                <div className="dropdown-menu">
                  {brandStatus !== BrandStatus.DRAFT && (
                    <div className="dropdown__innerWrapper">
                      <button
                        type="button"
                        onClick={() => onStatusChange(BrandStatus.DRAFT)}
                        className="dropdown-item"
                      >
                        <span>Draft</span>
                      </button>
                    </div>
                  )}
                  {brandStatus !== BrandStatus.PENDING && (
                    <div className="dropdown__innerWrapper">
                      <button
                        type="button"
                        onClick={() => onStatusChange(BrandStatus.PENDING)}
                        className="dropdown-item"
                      >
                        <span>Submit for review</span>
                      </button>
                    </div>
                  )}

                  {userRole === RoleType.ADMIN ||
                  userRole === RoleType.ADMIN_STAFF ? (
                    <>
                      {brandStatus === BrandStatus.PUBLISHED ? (
                        <div className="dropdown__innerWrapper">
                          <button
                            type="button"
                            onClick={() =>
                              onStatusChange(BrandStatus.UNPUBLISHED)
                            }
                            className="dropdown-item"
                          >
                            <span>Unpublish</span>
                          </button>
                        </div>
                      ) : (
                        <div className="dropdown__innerWrapper">
                          <button
                            type="button"
                            onClick={() =>
                              onStatusChange(BrandStatus.PUBLISHED)
                            }
                            className="dropdown-item"
                          >
                            <span>Publish</span>
                          </button>
                        </div>
                      )}
                    </>
                  ) : null}

                  {brand?.createdBy === userInfo?.userId ||
                  userRole === RoleType.ADMIN ||
                  userRole === RoleType.ADMIN_STAFF ? (
                    <div className="dropdown__innerWrapper">
                      <Link to={onEditLink()} className="dropdown-item">
                        <span>Edit</span>
                      </Link>
                    </div>
                  ) : null}

                  <div className="dropdown__innerWrapper">
                    <Link to={onViewLink()} className="dropdown-item">
                      <span>View Details</span>
                    </Link>
                  </div>

                  {userRole === RoleType.ADMIN ||
                  userRole === RoleType.ADMIN_STAFF ? (
                    <div className="dropdown__innerWrapper">
                      <Link
                        to={`/restaurants/${kitchen?.id}/connection/${kitchenAssociationId}/add`}
                        className="dropdown-item"
                      >
                        <span>Add New Connection</span>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {[RoleType.ADMIN, RoleType.ADMIN_STAFF].includes(userRole) ? (
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  onClick={toggleAccordion}
                >
                  <span
                    className={`marketPlace__counts ${
                      accordionState ? "open" : ""
                    }`}
                  >
                   Settings
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="accordion__body__wrapper">
                    <CredentialRow
                      credentials={credentials}
                      associatedBrandId={kitchenAssociationId}
                      onUpdate={onUpdateCredential}
                      brandId={brandId}
                      kitchenId={kitchen.id}
                    />
                    <CommissionRow
                      credentials={commissions}
                      associatedBrandId={kitchenAssociationId}
                      onUpdate={onUpdateCommission}
                      affiliateUserList={props?.affiliateUserList}
                      brandOwnerList={props?.brandOwnerList}
                    />
                  </div>
                  <Accordion>
                    <Card className="card__no__border">
                      <div className="d-flex justify-content-between">
                        <Card.Header className="card-header__no__padding">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            onClick={toggleOrderingPlatformAccordion}
                          >
                            <span
                              className={`marketPlace__counts ${
                                orderingPlatformAccordionState ? "open" : ""
                              }`}
                            >
                              {marketPlaces?.length} Ordering Platforms
                            </span>
                          </Accordion.Toggle>
                        </Card.Header>
                        {(userRole === RoleType.ADMIN ||
                          userRole === RoleType.ADMIN_STAFF) && (
                          <div className="adduser__btn">
                            <Link
                              to={`/restaurants/${kitchen?.id}/connection/${kitchenAssociationId}/add`}
                              className="red-btn btn addnew__platform__btn"
                            >
                              <span className="icon-right">
                                <img src={plusIcon} alt="add icon" />
                              </span>
                              Add New
                            </Link>
                          </div>
                        )}
                      </div>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="card-body__no__padding">
                          <div className="accordion__body__wrapper">
                            {marketPlaces && marketPlaces?.length ? (
                              marketPlaces.map((mrkt, idx) => (
                                <MarketPlaceRow
                                  key={idx}
                                  market={mrkt}
                                  kitchenVbAssociationId={kitchenAssociationId}
                                  services={props?.services}
                                  onDelete={updateCount}
                                />
                              ))
                            ) : (
                              <div className="text-center">
                                <p className="my-5">
                                  No Ordering Platform added yet
                                </p>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : null}
      </div>
    </>
  );
};

BrandRow.propTypes = {
  brand: PropTypes.shape({
    virtualBrand: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      statusId: PropTypes.number,
    }),
  }),
  brandOwnerList: PropTypes.array,
  affiliateUserList: PropTypes.array
};

export default BrandRow;
