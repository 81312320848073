import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import RecipeGuideContext from "store/recipe-guide-context";
/* import "./ItemModal.scss"; */
import PrepStepForm from "../recipeguide-form/PrepStepForm"

const PrepStepModal = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const modalExtras = recipeguideCtx.modalExtras;

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
        <Modal.Header closeButton>
          <Modal.Title id="addMenu__item__modal">
            <h1>
              {modalExtras?.viewMode && "View"}
              {modalExtras?.editMode && "Edit"}
              {modalExtras?.cloneMode && "Clone"}
              {!modalExtras?.editMode &&
              !modalExtras?.cloneMode &&
              !modalExtras?.viewMode
                ? "Add"
                : null}{" "}
              Prep Step
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrepStepForm/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PrepStepModal;
