import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PropTypes from "prop-types";

import "../preloginstyle.scss";
import emailicon from "assets/images/icon-email.svg";
import preLoginLogo from "assets/images/presigninlogo.svg";
import LoaderInline from "shared/loader/LoaderInline";
import { forgetPassword } from "services/api/auth";
import toastify from "shared/toastr";

const ForgetPassword = (props) => {
  const [isLoading, setisLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .max(50)
      .required("Please enter a valid email address"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setisLoading(true);
    await forgetPassword(data.email).then(
      (res) => {
        toastify("success", res.message);
        setisLoading(false);
        props.setEmail(data.email);
        props.nextStep();
      },
      (_) => {
        setisLoading(false);
      }
    );
  };

  return (
    <div className="prelogin__wrapper">
      <div className="inner__wrapepr">
        <div className="prelogin__logo">
          <img src={preLoginLogo} alt="pre login logo" />
        </div>
        <div className="prelogin__heading">
          <h1>Forgot Password</h1>
          <div className="prelogin__subtext">
            <p>
              Enter the email address associated with your account and we’ll
              send you a link to reset your password.
            </p>
          </div>
        </div>
        <div className="preloginForm__wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row no-gutters">
              <div className="col-md-12 mb-3 pb-3">
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter your email address"
                  {...register("email")}
                  maxLength="50"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                <span className="input-icon">
                  <img src={emailicon} alt="" />
                </span>
                {errors.email?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.email?.message}
                  </div>
                )}
              </div>

              <div className="col-md-12 action__wrapper">
                <button
                  type="submit"
                  className="prelogin-links-btn"
                  disabled={isLoading}
                >
                  {isLoading ? <LoaderInline /> : "Reset Password"}
                </button>
              </div>
              <div className="col-md-12 mb-3 pb-3">
                <p className="rememberPass">
                  <Link to="/login" className="prelogin-links">
                    Back to Sign In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ForgetPassword.propTypes = {
  setEmail: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ForgetPassword;
