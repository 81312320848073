import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import LoaderInline from "shared/loader/LoaderInline";

const RecipeCategoryBasicInfo = (props) => {
  let data = props.initialData;
  let isDisabled = props.disabled;
  const isLoading = props.loader;

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .max(30, "Recipe Category title not more than 30 characters")
      .required("Please enter recipe category title"),
    description: Yup.string()
      .trim()
      .max(500, "Recipe category description not more than 500 characters")
      .required("Please enter recipe category description")
  });

  useEffect(() => {
    reset({
      title: data?.title,
      description: data?.description,
    });
  }, [data]);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = (data) => {
    props.onSave(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="basic__info__content">

        <div className="form-row no-gutters">
          <div className="col-md-12 mb-3 pb-3">
            <label htmlFor="categoryName">
              Title<span className="mendatory">*</span>
            </label>
            {isDisabled ? <div className="view_form_input">{data?.title || "NA"}</div>
              : <input
                type="text"
                id="categoryName"
                placeholder="e.g. Cook the Curds"
                className={`form-control ${errors?.title ? "is-invalid" : ""}`}
                {...register("title")}
                maxLength="30"
                disabled={isDisabled}
              />}
            {errors?.title?.message && (
              <div className="invalid-feedback d-block">
                {errors?.title?.message}
              </div>
            )}
          </div>

          <div className="col-md-12">
            <label htmlFor="categoryDesc">Description<span className="mendatory">*</span>
            </label>
            {isDisabled ? <div className="view_form_input">{data?.description || "NA"}</div>
              : <textarea
                id="categoryDesc"
                placeholder="e.g. Classic cheese curds everyday menu..."
                rows="3"
                className={`form-control ${errors?.description ? "is-invalid" : ""
                  }`}
                {...register("description")}
                maxLength="500"
                disabled={isDisabled}
              ></textarea>}
            {errors?.description?.message && (
              <div className="invalid-feedback d-block">
                {errors?.description?.message}
              </div>
            )}
          </div>

          <div className="col-12">
            <div className="modal-footer">
              {props.children}

              <button
                type="button"
                onClick={props.onHide}
                className="cancel-btn red-btn-ghost btn"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="red-btn next-btn btn"
               disabled={isLoading || !isValid}
              >
                {isLoading ? (
                  <LoaderInline />
                ) : (
                  <>
                    Add
                    <span className="icons-right">
                      <img src={rightArrowIcon} alt="arrow icon" />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RecipeCategoryBasicInfo;
