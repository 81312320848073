import * as Yup from "yup";

export const AvailabilityValidationSchema = {
  monday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
  tuesday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
  wednesday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
  thursday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
  friday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
  saturday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
  sunday: Yup.object().shape({
    opensAt: Yup.string().trim().required(),
    closesAt: Yup.string().trim().required(),
    isActive: Yup.boolean().required(),
  }),
};
