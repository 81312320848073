import React, { useContext } from "react";
import RecipeGuideForm from "../recipeguide-form/RecipeGuideForm";
import { Modal } from "react-bootstrap";
import RecipeGuideContext from "store/recipe-guide-context";

const RecipeGuideModal = (props) => {
    const recipeGuideCtx = useContext(RecipeGuideContext);
    const modalExtras = recipeGuideCtx.modalExtras;

    return (
        <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
            <Modal.Header closeButton>
                <Modal.Title id="addMenu__item__modal">
                    <h1>
                        {modalExtras && modalExtras?.isEditMode && "Edit"}
                        {modalExtras && modalExtras?.isViewMode && "View"}
                        {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                            ? "Add"
                            : null}{" "}
                        Recipe Guide
                    </h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RecipeGuideForm />
            </Modal.Body>
        </Modal>
    );
};

export default RecipeGuideModal;
