import React, { useContext, useEffect, useState } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import {addRecipeCategory, editRecipeCategory} from "services/api/recipeguide";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import RecipeCategoryBasicInfo from "./RecipeCategoryBasicInfo";
import RecipeGuideContext from "store/recipe-guide-context";

const RecipeCategoryForm = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const [basicData, setbasicData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);
  const modalStatus = recipeguideCtx.recipeCategoryModalStatus;
  const modalExtras = recipeguideCtx.modalExtras;
  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
  const recipeId = recipeguideCtx.recipeId;

  useEffect(() => {
    if (modalStatus === true) {
      if (modalExtras && (modalExtras?.editMode || modalExtras?.viewMode)) {
        setbasicData(modalExtras.data);
        if (modalExtras?.viewMode) setisFieldsDisabled(true);
      }
    }
  }, [modalStatus]);


  const onCategoryBasicInfoComplete = (data) => {
    setbasicData(data);
    saveInfo(data);
  };

  const saveInfo = async (data) => {
    const recipeCategoryId = modalExtras?.data?.id;
    if (modalExtras?.viewMode) {
      closeModal();
      return;
    }
    let model = {
      ...data,
      recipeId: recipeId
    };
    setisLoading(true);
    if (modalExtras?.editMode && recipeCategoryId) {
      await editRecipeCategory(recipeCategoryId, model)
        .then((res) => {
          toastify("success", res.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
    } else {
      await addRecipeCategory(model)
        .then((res) => {
          toastify("success", res.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
    }
  };

  const closeModal = () => {
    recipeguideCtx.recipeCategoryModalStatusChange(false);
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
  };

  return (
    <>
      <div className="createcatTbs__wrapper">
        <Tab.Container
          mountOnEnter
          unmountOnExit
          id="addMenu__item__modal__Tabs"
          defaultActiveKey="first1"
        >
          <Row className="no-gutters">
            <Col sm={12}>
              <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first1"
                    active={activeStep === 1}
                    onClick={() => onTabChange(1)}
                  >
                    Category Details
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane
                  eventKey="first1"
                  active={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading">
                    <RecipeCategoryBasicInfo
                      onSave={onCategoryBasicInfoComplete}
                      onHide={closeModal}
                      initialData={basicData}
                      disabled={isFieldsDisabled}
                      loader={isLoading}
                    >
                    </RecipeCategoryBasicInfo>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default RecipeCategoryForm;
