import React from "react";
import { Link } from "react-router-dom";
import "./EditConnection.scss";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const EditConnection = () => {
  return (
    <div className="edit__connection__wrapper">
      <div className="modifierDetails__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <Link to="/manage-devices" className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Edit Connection</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                <div className="details__wrapper__inner">
                  <div className="left_content">
                  <form>
                          <div className="basic__info__content">
                            <div className="form-row">
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="username">
                                  Username
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="username"
                                  placeholder="Nombeko Mabandla"
                                  className="form-control"
                                  maxLength="30"
                                />
                              </div>
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="password">
                                  Password
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="password"
                                  placeholder="Enter password"
                                  className="form-control"
                                  maxLength="30"
                                />
                              </div>
                              <div class="col-md-12 mb-3 pb-3 px-0">
                                <label for="mandatory">
                                  Marketplace Name
                                  <span className="mendatory">*</span>
                                </label>
                                <select
                                  className="form-control big-select"
                                  placeholder="No Restaurant Selected"
                                >
                                  <option value="">
                                    No Marketplace Selected
                                  </option>
                                  <option value="">Website Admin</option>
                                  <option value="">Website Staff</option>
                                  <option value="">Restaurant Owner</option>
                                  <option value="">Restaurant Staff</option>
                                </select>
                              </div>
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="marketPlaceId">
                                  Marketplace ID
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="marketPlaceId"
                                  placeholder="E.g. 745210"
                                  className="form-control"
                                />
                              </div>
                              <div class="col-md-12 mb-3 pb-3 px-0">
                                <label for="mandatory">
                                  Marketplace Status
                                </label>
                                <select
                                  className="form-control big-select"
                                  placeholder="Inactive"
                                >
                                  <option value="">Inactive</option>
                                  <option value="">Active</option>
                                  <option value="">Website Staff</option>
                                  <option value="">Restaurant Owner</option>
                                  <option value="">Restaurant Staff</option>
                                </select>
                              </div>
                              <div className="col-md-12 mb-md-3 pb-md-3 px-0">
                                <label htmlFor="uniqueUrl">
                                  Unique URL
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="uniqueUrl"
                                  placeholder="E.g. http://example.net/"
                                  className="form-control"
                                  maxLength="30"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                  </div>
                  <div className="right_content if__active">
                    <span className="custom__badge badge__red table__badge">
                      Active
                    </span>
                    <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="dropdown-button-drop-left"
                      ></button>
                      <div className="dropdown-menu">
                        <div className="dropdown__innerWrapper">
                          <Link to="#" className="dropdown-item active">
                            <span>Set as Inactive</span>
                          </Link>
                          <Link to="#" className="dropdown-item active">
                            <span>Edit</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="cancel-btn red-btn-ghost btn"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="red-btn next-btn btn">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditConnection;
