import React from 'react'
import { Link } from 'react-router-dom';

import thankyouIcon from "../../assets/images/thankyou-check.svg";
import './Thankyou.scss'

const Thankyou = () => {
    return (
        <div className="Thankyou__wrapper">
            <div className="container">
                <div className="Thankyou__wrapper__inner">
                    <div className="thankyou__card">
                        <div className="img-box">
                            <img src={thankyouIcon} alt="thank you image icon" />
                        </div>
                        <div className="content">
                            <h1>Thank You!</h1>
                            <p>You have successfully submitted your menu for review.</p>
                            <p>Please click on “Go to My Dashboard” and you will be redirected to your Virtual Brand’s Listings Page</p>
                            <Link to="#" className="btn btn-red"> Go to My Dashboard</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thankyou
