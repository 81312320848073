import React from "react";
import PropTypes from "prop-types";
import totalOrders from "assets/images/total_Orders.svg";
import totalRevenue from "assets/images/revenue.svg";
import avgValue from "assets/images/avg_Value.svg";
import dpc from "assets/images/dp_Commision.svg";
import bbc from "assets/images/bb_Commision.svg";
import totaltaxes from "assets/images/tax.svg";
import totalPercentage from "assets/images/percent.svg";
import errorCharges from "assets/images/error_Charges.svg";
/* import totaltips from "assets/images/tips.svg";
import CancelledOrders from "assets/images/cancelled_Orders.svg";*/
import { numberFormat } from "constants/numberFormat";
import { RoleType } from "constants/roleType";

const SummarySection = (props) => {
  const data = props.data;
  const userRole = props.userRole;

  const getRevenueShareTileLabel = () => {
    switch (userRole) {
      case RoleType.AFFILIATE_USER:
        return (
          <>
            Affiliate <br /> Payout
          </>
        );
      case RoleType.BRAND_OWNER:
        return (
          <>
            Brand Owner <br /> Payout
          </>
        );
      default:
        return (
          <>
            Restaurant Payout
          </>
        );
    }
  };

  const getRevenueShareValue = () => {
    switch (userRole) {
      case RoleType.AFFILIATE_USER:
        return numberFormat(data?.affiliatePayout);
      case RoleType.BRAND_OWNER:
        return numberFormat(data?.brandOwnerPayout);
      default:
        return numberFormat(data?.revenueShare);
    }
  }

  return (
    <>
      <div className="summary__card__wrapper">
        {data?.platformName && (
          <div className="summary__restaurant__name">
            <div className="img-box">
              <img
                src={data?.platFormLogoUrl}
                alt="Restra-logo"
                className="img-fluid"
                title={data?.platformName}
              />
            </div>
            <div className="content-box">
              <h5>{data?.platformName}</h5>
            </div>
          </div>
        )}
        <div className="summary__card__wrapper__inner">
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Total Sales
                </p>
                <p className="label__data">
                  {numberFormat(data?.totalRevenue)}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totalRevenue} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">{getRevenueShareTileLabel()}</p>
                <p className="label__data">{getRevenueShareValue()}</p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totalRevenue} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                   Service Fees
                </p>
                <p className="label__data">
                  {numberFormat(
                    data?.deliveryBHBCommission + data?.pickUpBHBCommission
                  )}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={bbc} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Total Orders
                </p>
                <p className="label__data">
                  {numberFormat(data?.completedOrdersCount, false)}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totalOrders} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Total <br /> Orders
                </p>
                <p className="label__data">
                  {numberFormat(data?.totalOrders, false)}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totalOrders} alt="total orders" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Average Order Value
                </p>
                <p className="label__data">
                  {numberFormat(data?.averageValue)}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={avgValue} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Error Charges
                </p>
                <p className="label__data">
                  {numberFormat(data?.totalWaiveOffAmount)}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={errorCharges} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">Taxes</p>
                <p className="label__data">{numberFormat(data?.taxes)}</p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totaltaxes} alt="total orders" />
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">Error Rate</p>
                <p className="label__data">
                  {data?.totalOrdersCountWithWaiveOffChanges &&
                  data?.totalOrders
                    ? (
                        (data?.totalOrdersCountWithWaiveOffChanges * 100) /
                        data?.totalOrders
                      ).toFixed(2)
                    : 0}
                  %
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totalPercentage} alt="total orders" />
                </div>
              </div>
            </div>
          </div> */}
          {/*  <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Pickup <br /> Commission 
                </p>
                <p className="label__data">{data?.pickUpBHBCommission || 0}</p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={dpc} alt="total orders" />
                </div>
              </div>
            </div>
          </div> */}
          {/*  <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                 Cancelled  <br /> Orders
                </p>
                <p className="label__data">{data?.cancelledOrdersCount || 0}</p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={dpc} alt="total orders" />
                </div>
              </div>
            </div>
          </div> */}
          {/* {data?.hasOwnProperty("valuePercentage") &&
          !data?.hasOwnProperty("platformId") ? (
            <div className="summary__card">
              <div className="summary__card__data">
                <div className="summary__card__data__left">
                  <p className="label">Revenue%</p>
                  <p className="label__data">{data?.valuePercentage}%</p>
                </div>
                <div className="summary__card__data__right">
                  <div className="img-box">
                    <img src={totalPercentage} alt="total orders" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {data?.hasOwnProperty("cancelledOrders") &&
          data?.hasOwnProperty("platformId") ? (
            <div className="summary__card">
              <div className="summary__card__data">
                <div className="summary__card__data__left">
                  <p className="label">
                    Cancelled <br /> Orders
                  </p>
                  <p className="label__data">{data?.cancelledOrders}</p>
                </div>
                <div className="summary__card__data__right">
                  <div className="img-box">
                    <img src={CancelledOrders} alt="total orders" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">
                  Delivery Partner <br /> Commission
                </p>
                <p className="label__data">
                  ${data?.deliveryPartnerCommission}
                </p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={dpc} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">Tips</p>
                <p className="label__data">${data?.tips}</p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totaltips} alt="total orders" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary__card">
            <div className="summary__card__data">
              <div className="summary__card__data__left">
                <p className="label">Taxes</p>
                <p className="label__data">${data?.taxes}</p>
              </div>
              <div className="summary__card__data__right">
                <div className="img-box">
                  <img src={totaltaxes} alt="total orders" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

SummarySection.propTypes = {
  data: PropTypes.shape({
    averageValue: PropTypes.number,
    cancelledOrders: PropTypes.number,
    deliveryPartnerCommission: PropTypes.number,
    platformCommission: PropTypes.number,
    platformId: PropTypes.number,
    platformName: PropTypes.string,
    taxes: PropTypes.number,
    tips: PropTypes.number,
    totalOrders: PropTypes.number,
    totalRevenue: PropTypes.number,
    valuePercentage: PropTypes.number,
  }),
  userRole: PropTypes.string
};

export default SummarySection;
