import React, { useContext, useEffect, useState } from "react";
import { getServices } from "services/api/connection";
import LoaderBlock from "shared/loader/LoaderBlock";
import {
  addKitchenMarketPlaces,
  getKitchenMarketplaces,
} from "services/api/kitchen";
import UserContext from "store/user-context";
import toastify from "shared/toastr";
import { RoleType } from "constants/roleType";

const MarketPlaceDeliveryRestriction = (props) => {
  const [marketplaces, setMarketplaces] = useState([]);
  const [selectedMarketPlaces, setSelectedMarketPlaces] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const isEdit = userRole === RoleType.ADMIN || userRole === RoleType.ADMIN_STAFF;  

  useEffect(() => {
    getDeliveryServices();
    getKitchenMarketplaceList();
  }, []);

  const getDeliveryServices = async () => {
    setisLoading(true);
    await getServices()
      .then((res) => {
        const data = res.data;
        setMarketplaces(data);
        setisLoading(false);
      })
      .catch(() => {
        setMarketplaces([]);
        setisLoading(false);
      });
  };

  const getKitchenMarketplaceList = async () => {
    setisLoading(true);
      await getKitchenMarketplaces(props?.kitchen?.id, props?.type)
        .then((res) => {
          const data = res.data;
          if (data && data?.length) {
            const selectedMarketPlaces = data.map((option) => ({
              id: option.marketplaceId,
              isRestricted: option.isRestricted,
              name: option.name,
            }));
            setSelectedMarketPlaces(selectedMarketPlaces);
            setSelectAll(
              data.length === marketplaces.length &&
              data.every((option) => option.isRestricted)
            );
          }
          setisLoading(false);
        })
        .catch(() => {
          setSelectedMarketPlaces([]);
          setisLoading(false);
        });
  };

  const onCheckMarketPlace = (id, name) => {
    setSelectedMarketPlaces((prevSelectedOptions) => {
      const idx = prevSelectedOptions.findIndex((option) => option.id === id);
      let updatedOptions;
      if (idx > -1) {
        updatedOptions = [
          ...prevSelectedOptions.slice(0, idx),
          {
            ...prevSelectedOptions[idx],
            isRestricted: !prevSelectedOptions[idx].isRestricted,
          },
          ...prevSelectedOptions.slice(idx + 1),
        ];
      } else {
        updatedOptions = [
          ...prevSelectedOptions,
          { id: id, name: name, isRestricted: true },
        ];
      }
      const allOptionsSelected =
        updatedOptions.length === marketplaces.length &&
        updatedOptions.every((option) => option.isRestricted);
      setSelectAll(allOptionsSelected);
      return updatedOptions;
    });
  };

  const handleAllClick = () => {
    setSelectAll(!selectAll);
    const updatedOptions = marketplaces.map((option) => ({
      ...option,
      isRestricted: !selectAll,
    }));
    setSelectedMarketPlaces(updatedOptions);
  };

  const onSubmit = async () => {
    if (selectedMarketPlaces && selectedMarketPlaces?.length) {
      const optionsData = selectedMarketPlaces.map((option) => ({
        id: option.id,
        isRestricted: option.isRestricted,
        name: option.name,
      }));
      const model = {
        kitchenId: props?.kitchen?.id,
        kitchenName: props?.kitchen?.name,
        userId: userCtx?.userInfo?.userId,
        options: optionsData,
      };
      await addKitchenMarketPlaces(model, props?.type)
        .then((res) => {
          toastify("success", res.message);
          getKitchenMarketplaceList();
        })
        .catch((e) => {
          toastify("error", e.message);
        });
    }
  };

  return (
    <>
      <div className="details__wrapper">
        {isLoading ? (
          <LoaderBlock />
        ) : (
          <div className="details__wrapper__inner">
            <div className="order__filters">
              <div className="channel__wrapper">
                <div className="dropdown">
                  <div className="dropdown__action__wrapper p-0 card__no__border">
                    {marketplaces && marketplaces.length ? (
                      <div className="sortBy__marketPlaces">
                        <div className="detailCard mb-3 pb-0">
                          <h5>Marketplaces</h5>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="custom-checkbox-wrapper d-flex">
                              <label
                                htmlFor="selectAll"
                                className="custom-checkbox-container"
                              >
                                All
                                <input
                                  type="checkbox"
                                  id="selectAll"
                                  onChange={handleAllClick}
                                  checked={selectAll}
                                  disabled={!isEdit ? true : false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          {marketplaces.map((option, idx) => (
                            <div className="col-md-12 mb-3" key={idx}>
                              <div className="custom-checkbox-wrapper d-flex">
                                <label className="custom-checkbox-container">
                                  {option.name}
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      onCheckMarketPlace(option.id, option.name)
                                    }
                                    checked={
                                      selectedMarketPlaces?.find(
                                        (o) => o.id === option.id
                                      )?.isRestricted || false
                                    }
                                    disabled={!isEdit ? true : false}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {isEdit && (
                      <div className="button__wrapper">
                        <button className="btn apply-btn" onClick={onSubmit}>
                          Apply
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MarketPlaceDeliveryRestriction;
