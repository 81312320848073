import React, { useState } from "react";

import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import "./User.scss";

const AddDevice = () => {
  return (
    <div className="users__wrapper">
      <div className="modifierDetails__wrapper modifierEdit__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <Link to="/manage-devices" className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Add Devices</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper editModifier__wrapper">
                <div className="details__wrapper__inner">
                  <form>
                    <div className="basic__info__content">
                      <div className="form-row">
                        <div class="col-md-12 mb-3 pb-3 px-0">
                          <label for="mandatory">Restaurant Associated</label>
                          <select
                            className="form-control big-select"
                            placeholder="No Restaurant Selected"
                          >
                            <option value="">No Restaurant Selected</option>
                            <option value="">Website Admin</option>
                            <option value="">Website Staff</option>
                            <option value="">Restaurant Owner</option>
                            <option value="">Restaurant Staff</option>
                          </select>
                        </div>
                        <div className="col-md-12 mb-3 pb-3 px-0">
                          <label htmlFor="deviceID">
                            Device ID
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="deviceID"
                            placeholder="RT561729YU"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                        <div className="col-md-12 mb-3 pb-3 px-0">
                          <label htmlFor="deviceName">
                            Device Name
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="deviceName"
                            placeholder="Samsung Tablet S7"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                        <div class="col-md-12 mb-3 pb-3 px-0">
                          <label for="mandatory">Device Type</label>
                          <span className="mendatory">*</span>
                          <div class="check__wrapper">
                            <label class="check-container mb-0">
                              Tablet
                              <input
                                type="radio"
                                name="isAlcoholic"
                                value="true"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="check-container mb-0">
                              Printer
                              <input
                                type="radio"
                                name="isAlcoholic"
                                value="false"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-12 mb-3 pb-3 px-0">
                          <label for="mandatory">Device Connection Type</label>
                          <span className="mendatory">*</span>
                          <div class="check__wrapper">
                            <label class="check-container mb-0">
                              4G
                              <input
                                type="radio"
                                name="isAlcoholic"
                                value="true"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="check-container mb-0">
                              Wifi
                              <input
                                type="radio"
                                name="isAlcoholic"
                                value="false"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 pb-3 px-0">
                          <label htmlFor="deviceOSversion">
                            Device OS Version
                            {/* <span className="mendatory">*</span> */}
                          </label>
                          <input
                            type="text"
                            id="deviceOSversion"
                            placeholder="Android 11"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 px-0">
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn red-btn-ghost btn"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="red-btn next-btn btn"
                          >
                            Add Device
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDevice;
