import React, { useState } from "react";
import { Times } from "constants/menuAvailabilityTime";

import { useFormContext } from "react-hook-form";

const MenuAvailability = (props) => {
  const methods = useFormContext();
  const timerDropdown = Times();
  const isFieldsDisabled = props.disabled;

  // for monday
  const [monOpens, setMonOpens] = useState(timerDropdown);
  const [monCloses, setMonCloses] = useState(timerDropdown);
  const onMonOpensChange = (e) => setMonCloses(getDDsForOpen(e));
  const onMonClosesChange = (e) => setMonOpens(getDDsForClose(e));
  // for tuesday
  const [tuesOpens, setTuesOpens] = useState(timerDropdown);
  const [tuesCloses, setTuesCloses] = useState(timerDropdown);
  const onTuesOpensChange = (e) => setTuesCloses(getDDsForOpen(e));
  const onTuesClosesChange = (e) => setTuesOpens(getDDsForClose(e));
  // for wednesday
  const [wedOpens, setWedOpens] = useState(timerDropdown);
  const [wedCloses, setWedCloses] = useState(timerDropdown);
  const onWedOpensChange = (e) => setWedCloses(getDDsForOpen(e));
  const onWedClosesChange = (e) => setWedOpens(getDDsForClose(e));
  // for thursday
  const [thursOpens, setThursOpens] = useState(timerDropdown);
  const [thursCloses, setThursCloses] = useState(timerDropdown);
  const onThursOpensChange = (e) => setThursCloses(getDDsForOpen(e));
  const onThursClosesChange = (e) => setThursOpens(getDDsForClose(e));
  // for friday
  const [friOpens, setFriOpens] = useState(timerDropdown);
  const [friCloses, setFriCloses] = useState(timerDropdown);
  const onFriOpensChange = (e) => setFriCloses(getDDsForOpen(e));
  const onFriClosesChange = (e) => setFriOpens(getDDsForClose(e));
  // for saturday
  const [satOpens, setSatOpens] = useState(timerDropdown);
  const [satCloses, setSatCloses] = useState(timerDropdown);
  const onSatOpensChange = (e) => setSatCloses(getDDsForOpen(e));
  const onSatClosesChange = (e) => setSatOpens(getDDsForClose(e));
  // for sunday
  const [sunOpens, setSunOpens] = useState(timerDropdown);
  const [sunCloses, setSunCloses] = useState(timerDropdown);
  const onSunOpensChange = (e) => setSunCloses(getDDsForOpen(e));
  const onSunClosesChange = (e) => setSunOpens(getDDsForClose(e));

  const getDDsForOpen = (e) => {
    let val = e.target.value;
    let closeTimerDropdown = timerDropdown;
    closeTimerDropdown.push({ value: `00:00:00`, text: `12:00 AM` });
    let timeIndex = closeTimerDropdown.findIndex((time) => time.value === val);
    return closeTimerDropdown.slice(timeIndex + 1, closeTimerDropdown.length);
  };

  const getDDsForClose = (e) => {
    let val = e.target.value;
    let timeIndex = timerDropdown.findIndex((time) => time.value === val);
    const finalTimeIndex = timeIndex == 0 ? timerDropdown.length : timeIndex;
    return timerDropdown.slice(0, finalTimeIndex);
  };

  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <div className="basic__info__content">
      <div className="left-side-form-wrapper">
        <div className="main-heading operating-hours">
          <h3>
            {props?.text || "Operating Hours"}<span className="mendatory">*</span>
          </h3>
          <p>
            Which days and times would you like to receive orders from this
            brand?
          </p>
        </div>
        <div className="row no-gutters align-items-center">
          <div className="col-12 mb-4">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Monday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.monday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("monday.opensAt")}
                  onChange={onMonOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {monOpens &&
                    monOpens.map((time, idx) => (
                      <option key={"monOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.monday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("monday.closesAt")}
                  onChange={onMonClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {monCloses &&
                    monCloses.map((time, idx) => (
                      <option key={"monCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("monday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Tuesday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.tuesday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("tuesday.opensAt")}
                  onChange={onTuesOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {tuesOpens &&
                    tuesOpens.map((time, idx) => (
                      <option key={"tueOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.tuesday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("tuesday.closesAt")}
                  onChange={onTuesClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {tuesCloses &&
                    tuesCloses.map((time, idx) => (
                      <option key={"tueCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("tuesday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Wednesday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.wednesday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("wednesday.opensAt")}
                  onChange={onWedOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {wedOpens &&
                    wedOpens.map((time, idx) => (
                      <option key={"wedOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.wednesday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("wednesday.closesAt")}
                  onChange={onWedClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {wedCloses &&
                    wedCloses.map((time, idx) => (
                      <option key={"wedCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("wednesday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Thursday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.thursday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("thursday.opensAt")}
                  onChange={onThursOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {thursOpens &&
                    thursOpens.map((time, idx) => (
                      <option key={"thuOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.thursday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("thursday.closesAt")}
                  onChange={onThursClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {thursCloses &&
                    thursCloses.map((time, idx) => (
                      <option key={"thuCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("thursday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Friday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.friday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("friday.opensAt")}
                  onChange={onFriOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {friOpens &&
                    friOpens.map((time, idx) => (
                      <option key={"friOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.friday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("friday.closesAt")}
                  onChange={onFriClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {friCloses &&
                    friCloses.map((time, idx) => (
                      <option key={"friCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("friday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Saturday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.saturday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("saturday.opensAt")}
                  onChange={onSatOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {satOpens &&
                    satOpens.map((time, idx) => (
                      <option key={"satOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.saturday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("saturday.closesAt")}
                  onChange={onSatClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {satCloses &&
                    satCloses.map((time, idx) => (
                      <option key={"satCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("saturday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="actions-wrapper">
              <div>
                <label htmlFor="" className="days">
                  Sunday
                </label>
              </div>
              <div>
                <select
                  className={`form-control time-select ${
                    errors?.sunday?.opensAt ? "is-invalid" : ""
                  }`}
                  {...register("sunday.opensAt")}
                  onChange={onSunOpensChange}
                  disabled={isFieldsDisabled}
                >
                  {sunOpens &&
                    sunOpens.map((time, idx) => (
                      <option key={"sunOp" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <select
                  className={`form-control time-select ${
                    errors?.sunday?.closesAt ? "is-invalid" : ""
                  }`}
                  {...register("sunday.closesAt")}
                  onChange={onSunClosesChange}
                  disabled={isFieldsDisabled}
                >
                  {sunCloses &&
                    sunCloses.map((time, idx) => (
                      <option key={"sunCl" + idx} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                </select>
                <div className="switches">
                  <label className="switch">
                    <input
                      disabled={isFieldsDisabled}
                      type="checkbox"
                      {...register("sunday.isActive")}
                    />
                    <span
                      className={`slider ${
                        isFieldsDisabled ? "form-field-disabled" : ""
                      } round`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuAvailability;
