import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Global } from "constants/global";
import { Chart } from "react-google-charts";
import { getOrders } from "services/api/order";
import searchIcon from "../../assets/images/icon-search.svg";
import exportIcon from "../../assets/images/export-icon.svg";
import filterIcon from "../../assets/images/Icon_Filter_ckc.svg";
import emptyIocn from "../../assets/images/emptyIcon.svg";
import ubereats from "../../assets/images/ubereats.svg";
import SelectDropdown from "UI/Select-Dropdown/SelectDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import doordash from "../../assets/images/doordash.svg";
import grubhub from "../../assets/images/grubhub.svg";
import totalOrders from "../../assets/images/total_Orders.svg";
import totalRevenue from "../../assets/images/revenue.svg";
import avgValue from "../../assets/images/avg_Value.svg";
import totalPercentage from "../../assets/images/percent.svg";
import dpc from "../../assets/images/dp_Commision.svg";
import bbc from "../../assets/images/bb_Commision.svg";
import totaltips from "../../assets/images/tips.svg";
import totaltaxes from "../../assets/images/tax.svg";
import CancelledOrders from "../../assets/images/cancelled_Orders.svg";
import "./Reports.scss";
import KitchenFilter from "components/filters/KitchenFilter";
import DateFilter from "components/filters/DateFilter";

const Reports = () => {
  const options = [
    { value: "Kentucky Fried Chicken ", label: "Kentucky Fried Chicken " },
    { value: "Wendy’s", label: "Wendy’s" },
    { value: "McDonalds", label: "McDonalds" },
    { value: "Pizza Hut", label: "Pizza Hut" },
  ];

  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const chartOptions = {
    title: "My Daily Activities",
  };

  const pageSize = Global.pageSize;
  const [pageNum, setpageNum] = useState(1);
  const [searchTerm, setsearchTerm] = useState(null);
  const [selectedKitchenId, setSelectedKitchenId] = useState(null);
  const [selectedKitchen, setSelectedKitchen] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [marketPlaceStatus, setMarketPlaceStatus] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const [allOrders, setAllOrders] = useState(null);
  const [totalRecords, settotalRecords] = useState(0);

  const [filterToggle, setFilterToggle] = useState(false);

  useEffect(() => {
    getListing();
  }, [
    pageNum,
    searchTerm,
    selectedKitchenId,
    orderStatus,
    marketPlaceStatus,
    dateTo,
    dateFrom,
  ]);

  const getListing = async () => {
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      name: searchTerm,
      kitchenId: selectedKitchenId,
      orderStatus: orderStatus,
      marketPlaceStatus: marketPlaceStatus,
      dateTo: dateTo,
      dateFrom: dateFrom,
    };
    setisLoading(true);
    await getOrders(masks)
      .then((res) => {
        let data = res.data;
        setAllOrders(data.order);
        settotalRecords(data.pagingParams.totalRecords);
        setisLoading(false);
      })
      .catch(() => {
        setAllOrders(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    setpageNum(page);
  };

  const onSearch = (term) => {
    setsearchTerm(term);
  };

  const onFilterToggle = () => setFilterToggle(!filterToggle);

  const onStatuses = (data) => {
    setOrderStatus(data?.orderStatus?.join(","));
    setMarketPlaceStatus(data?.marketplaceStatus?.join(","));
  };

  const onKitchenSelect = (data) => {
    if (data) {
      setSelectedKitchenId(data.value);
      setSelectedKitchen(data.label);
    }
  };

  const onDates = (data) => {
    setDateTo(data?.dateTo);
    setDateFrom(data?.dateFrom);
  };

  return (
    <div className="reports__wrapper">
      <div className="orders__page__wrapper">
        <div className="managermodifier__wrapper basic__info__content">
          <div className="container">
            <div className="managermodifier__header">
              <div className="row justify-content-between">
                <div className="col-12 mb-3 pb-3 d-none d-md-block">
                  <div className="goBack">
                    <Link
                      to="/home"
                      className="back-btn-red"
                      title="back to home"
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Go to Dashboard
                    </Link>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <h1>Report Summary</h1>
                </div>
                {/* begin:: filters for web screens */}
                <div className="reports__filters__wrapper">
                  <div className="col-12 d-none d-xl-block">
                    <form className="d-flex flex-row align-items-end">
                      <div>
                        <label className="filters__label">Restaurant</label>
                        <div className="userselect mr-md-3">
                          <div className="user__custom__select">
                            <KitchenFilter
                              value={selectedKitchenId}
                              onSelect={onKitchenSelect}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="filters__label">Virtual Brand</label>
                        <div className="userselect mr-md-3">
                          <div className="user__custom__select">
                            <KitchenFilter
                              value={selectedKitchenId}
                              onSelect={onKitchenSelect}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="filters__label">Date Range</label>
                        <div className="userselect mr-md-3">
                          <div className="user__custom__select">
                            <SelectDropdown
                              options={options}
                              placeholder="Select User"
                              value={"admin"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="date_range_filter_reports">
                        <div className="date__filter__wrapper mr-md-3">
                          <DateFilter
                            dateToValue={dateTo}
                            dateFromValue={dateFrom}
                            onSelect={onDates}
                          />
                        </div>
                      </div>
                      <div>
                        <button className="filters__apply__btn btn">
                          Apply
                        </button>
                      </div>
                      <div className="vertical-seperator"></div>
                      <div className="export__wrapper">
                        <button className="export-btn btn">
                          <span>
                            <img
                              src={exportIcon}
                              alt="export icon"
                              className="img-fluid icons"
                            />
                          </span>
                          <span>Export CSV</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* end:: filters for web screens */}
                {/* begin:: filters for mobile screens */}
                <div className="order__filters__mobile__screens reports__filters__mobile__screens">
                  <div className="col-12 d-xl-none">
                    <form className="d-flex flex-column w-100">
                      <div className="filters__wrapper__mobile mb-3">
                        <div className="filters__toggle__mobile">
                          <button className="filters">
                            <span>
                              <img
                                src={filterIcon}
                                alt="export icon"
                                className="img-fluid"
                              />
                            </span>
                          </button>
                        </div>
                        <div className="export__wrapper">
                          <button className="export-btn btn">
                            <span>
                              <img
                                src={exportIcon}
                                alt="export icon"
                                className="img-fluid icons"
                              />
                            </span>
                            <span>Export CSV</span>
                          </button>
                        </div>
                      </div>
                      <div className="filters__hidden__options">
                        <div className="row no-gutters">
                          <div className="col-12 mb-3">
                            <div className="report-filter-mobile-one-col">
                              <div>
                                <label className="filters__label">
                                  Restaurant
                                </label>
                                <div className="userselect">
                                  <div className="user__custom__select">
                                    <KitchenFilter
                                      value={selectedKitchenId}
                                      onSelect={onKitchenSelect}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label className="filters__label">
                                  Virtual Brand
                                </label>
                                <div className="userselect">
                                  <div className="user__custom__select">
                                    <KitchenFilter
                                      value={selectedKitchenId}
                                      onSelect={onKitchenSelect}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="report-filter-mobile-one-col report-filter-mobile-two-col">
                              <div>
                                <label className="filters__label">
                                  Date Range
                                </label>
                                <div className="userselect">
                                  <div className="user__custom__select">
                                    <SelectDropdown
                                      options={options}
                                      placeholder="Select User"
                                      value={"admin"}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="date__filter__wrapper">
                                  <DateFilter
                                    dateToValue={dateTo}
                                    dateFromValue={dateFrom}
                                    onSelect={onDates}
                                  />
                                </div>
                              </div>
                              <div>
                                <button className="filters__apply__btn btn">
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* end:: filters for mobile screens */}
              </div>
            </div>
            <div className="managermodifier__noListing d-none">
              <div className="managermodifier__noListing__innerwrapper">
                <div className="content">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={emptyIocn}
                      alt="no list icon"
                      width="185"
                    />
                  </div>
                  <div className="content-desc">
                    <h3>No Reports Found</h3>
                  </div>
                </div>
              </div>
            </div>
            <section className="sections allrestaurants">
              <div className="row">
                <div className="col-12">
                  <div className="section__heading">
                    <h2>All Restaurants</h2>
                  </div>
                </div>
                <div className="col-12">
                  <div className="summary__card__wrapper">
                    <div className="summary__card__wrapper__inner">
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br /> Orders
                            </p>
                            <p className="label__data">110</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br />
                              Revenue
                            </p>
                            <p className="label__data">$10,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalRevenue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Average <br /> Value
                            </p>
                            <p className="label__data">$40</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={avgValue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Revenue %</p>
                            <p className="label__data">75%</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalPercentage} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Delivery Partner <br /> Commission
                            </p>
                            <p className="label__data">$2,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={dpc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Backhouse Brand <br /> Commission
                            </p>
                            <p className="label__data">$15,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={bbc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Tips</p>
                            <p className="label__data">$940</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltips} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Taxes</p>
                            <p className="label__data">$840</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltaxes} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="sections reports__graph">
              {/* graph chart will go here */}
              <div className="reports__graph__wrapper">
                <div className="reports__graph__wrapper__inner">
                  <div className="reports__graph__card">
                    <Chart
                      chartType="PieChart"
                      data={data}
                      options={chartOptions}
                      width={"300px"}
                      height={"220px"}
                    />
                  </div>
                  <div className="reports__graph__card">
                    <Chart
                      chartType="PieChart"
                      data={data}
                      options={chartOptions}
                      width={"300px"}
                      height={"220px"}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="sections reportdetails">
              <div className="row">
                <div className="col-12">
                  <div className="section__heading">
                    <h2>Report Details</h2>
                  </div>
                </div>
                <div className="col-12">
                  <div className="summary__card__wrapper">
                    <div className="summary__restaurant__name">
                      <div className="img-box">
                        <img
                          src={doordash}
                          alt="Restra-logo"
                          className="img-fluid"
                        />
                      </div>
                      <div className="content-box">
                        <h5>DoorDash Delivery</h5>
                      </div>
                    </div>
                    <div className="summary__card__wrapper__inner">
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br /> Orders
                            </p>
                            <p className="label__data">110</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br />
                              Revenue
                            </p>
                            <p className="label__data">$10,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalRevenue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Average <br /> Value
                            </p>
                            <p className="label__data">$40</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={avgValue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Cancelled <br /> Orders
                            </p>
                            <p className="label__data">4</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={CancelledOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Delivery Partner <br /> Commission
                            </p>
                            <p className="label__data">$2,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={dpc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Backhouse Brand <br /> Commission
                            </p>
                            <p className="label__data">$15,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={bbc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Tips</p>
                            <p className="label__data">$940</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltips} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Taxes</p>
                            <p className="label__data">$840</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltaxes} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="summary__card__wrapper">
                    <div className="summary__restaurant__name">
                      <div className="img-box">
                        <img
                          src={ubereats}
                          alt="Restra-logo"
                          className="img-fluid"
                        />
                      </div>
                      <div className="content-box">
                        <h5>UberEats</h5>
                      </div>
                    </div>
                    <div className="summary__card__wrapper__inner">
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br /> Orders
                            </p>
                            <p className="label__data">110</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br />
                              Revenue
                            </p>
                            <p className="label__data">$10,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalRevenue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Average <br /> Value
                            </p>
                            <p className="label__data">$40</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={avgValue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Cancelled <br /> Orders
                            </p>
                            <p className="label__data">4</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={CancelledOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Delivery Partner <br /> Commission
                            </p>
                            <p className="label__data">$2,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={dpc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Backhouse Brand <br /> Commission
                            </p>
                            <p className="label__data">$15,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={bbc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Tips</p>
                            <p className="label__data">$940</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltips} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Taxes</p>
                            <p className="label__data">$840</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltaxes} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="summary__card__wrapper">
                    <div className="summary__restaurant__name">
                      <div className="img-box">
                        <img
                          src={grubhub}
                          alt="Restra-logo"
                          className="img-fluid"
                        />
                      </div>
                      <div className="content-box">
                        <h5>GrubHub</h5>
                      </div>
                    </div>
                    <div className="summary__card__wrapper__inner">
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br /> Orders
                            </p>
                            <p className="label__data">110</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Total <br />
                              Revenue
                            </p>
                            <p className="label__data">$10,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totalRevenue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Average <br /> Value
                            </p>
                            <p className="label__data">$40</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={avgValue} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Cancelled <br /> Orders
                            </p>
                            <p className="label__data">4</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={CancelledOrders} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Delivery Partner <br /> Commission
                            </p>
                            <p className="label__data">$2,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={dpc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">
                              Backhouse Brand <br /> Commission
                            </p>
                            <p className="label__data">$15,000</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={bbc} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Tips</p>
                            <p className="label__data">$940</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltips} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="summary__card">
                        <div className="summary__card__data">
                          <div className="summary__card__data__left">
                            <p className="label">Taxes</p>
                            <p className="label__data">$840</p>
                          </div>
                          <div className="summary__card__data__right">
                            <div className="img-box">
                              <img src={totaltaxes} alt="total orders" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
