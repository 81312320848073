import { privateApi } from "../utilities/Provider";

const basicUrl = "/Device/";

const addDevice = (model) => {
  let body = {
    kitchenId: model.kitchenId,
    deviceId: model.deviceId,
    name: model.deviceName,
    type: model.deviceType,
    connectionType: model.deviceConnection,
    os: model.deviceOs,
  };

  return privateApi.post(basicUrl + "add", body);
};

const editDevice = (model) => {
  let body = {
    id: model.id,
    kitchenId: model.kitchenId,
    deviceId: model.deviceId,
    name: model.deviceName,
    type: model.deviceType,
    connectionType: model.deviceConnection,
    os: model.deviceOs,
  };

  return privateApi.put(basicUrl + "edit", body);
};

const getDevice = (deviceId) => {
  return privateApi
    .get(basicUrl + "deviceInfoById", {
      params: {
        id: deviceId,
      },
    })
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        kitchen: res.data.kitchenDeviceAssociations[0]?.kitchenMaster,
      },
    }));
};

const getDevices = ({ pageSize, pageNum, searchValue}) => {
  const params = new URLSearchParams();
  params.append("pageNum", pageNum);
  params.append("pageSize", pageSize);
  if (searchValue) params.append("searchValue", searchValue);

  return privateApi.get(basicUrl + "devicelist", { params });
};

const changeDeviceStatus = (deviceId, status) => {
  let body = {
    deviceId: deviceId,
    statusId: status,
  };

  return privateApi.put(basicUrl + "updateDeviceStatus", body);
};

const deleteDevice = (deviceId) => {
  return privateApi.delete(basicUrl + "deleteDevice", {
    params: {
      id: deviceId,
    },
  });
};

export {
  addDevice,
  editDevice,
  getDevice,
  getDevices,
  changeDeviceStatus,
  deleteDevice,
};
