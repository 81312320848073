import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useMediaQuery } from "react-responsive";

const chartOptionOrder = {
  pieStartAngle: 180,
  height: "220px",
  chartArea: {
    left: 0,
    top: 16,
    bottom: 16,
    right: 16,
    width: "100%",
    height: "100%",
  },
  legend: {
    position: "left",
    alignment: "start",
    textStyle: {
      color: "#131414",
      fontName: "Work Sans",
      fontSize: "15",
      bold: false,
      italic: false,
    },
    tooltip: {
      textStyle: {
        color: "#FF0000",
        fontName: "Work Sans",
        fontSize: "13",
      },
      showColorCode: true,
      ignoreBounds: true,
    },
  },
  //colors: ["#EF6F00", "#5FB709", "#FF3008", "#23594B", "#F6343F"],
  colors: ["#FF3008", "#00008B", "#5FB709", "#EF6F00", "#23594B"]
};

const chartOptionItem = {
  pieStartAngle: 180,
  height: "220px",
  chartArea: {
    left: 0,
    top: 16,
    bottom: 16,
    right: 16,
    width: "100%",
    height: "100%",
  },
  legend: {
    position: "left",
    alignment: "start",
    textStyle: {
      color: "black",
      fontName: "Work Sans",
      fontSize: "15",
      bold: false,
      italic: false,
    },
    tooltip: {
      textStyle: { color: "#FF0000" },
      showColorCode: true,
      ignoreBounds: true,
    },
  },
  colors: ["#FC9D9A", "#FE85B0", "#46D5E1", "#93A4FC", "#C288F4"],
};

const ChartSection = (props) => {
  const data = props.data;
  const type = props.type;
  const [dataToRender, setdataToRender] = useState(null);

  const [chartOptions, setChartOptions] = useState(null);
  const [title, setTitle] = useState(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    if (isMobile) {
      chartOptionOrder.height = "300px";
      chartOptionOrder.legend.textStyle.fontSize = "13.5";

      chartOptionItem.height = "300px";
      chartOptionItem.legend.textStyle.fontSize = "13.5";
    }
  }, [isMobile]);

  useEffect(() => {
    if (type === "items") {
      mapDataForItems();
    }
    if (type === "order") {
      mapDataForOrder();
    }
  }, [props]);

  const mapDataForOrder = () => {
    setTitle("Orders by Channel");
    setChartOptions(chartOptionOrder);
    let resultant = [];
    if (data && data?.length) {
      resultant = data.map((res) => [res.deliveryPartnerName, res.totalOrders]);
    }
    resultant.unshift(["Delivery Partners", "Total Orders"]);
    setdataToRender(resultant);
  };

  const mapDataForItems = () => {
    setTitle("Top Menu Items");
    setChartOptions(chartOptionItem);
    let resultant = [];
    if (data && data?.length) {
      resultant = data.map((res) => [res.itemName, res.totalOrders]);
    }
    resultant.unshift(["Top 5 Menu Items", "Total Orders"]);
    setdataToRender(resultant);
  };

  return (
    <>
      {title && <h5>{title}</h5>}

      {dataToRender && (
        <Chart
          chartType="PieChart"
          data={dataToRender}
          options={chartOptions}
          loader
        />
      )}
    </>
  );
};

export default ChartSection;
