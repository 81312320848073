import React, { useContext, useEffect, useState } from "react";

import { Tab, Nav, Col, Row } from "react-bootstrap";

import "./VbList.scss";
import AddNewBrand from "UI/BrandCard/AddNewBrand";
import BrandCard from "UI/BrandCard/BrandCard";
import NoVbBanner from "./NoVbBanner";
import { getVirtualBrands, hasVirtualBrand } from "services/api/brand";
import { Global } from "constants/global";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import LoaderFull from "shared/loader/LoaderFull";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { RoleType } from "constants/roleType";
import UserContext from "store/user-context";
import fileIcon from "assets/images/file-icon.svg";

const ListType = {
  All: 0,
  Added: 1,
  Shared: 2,
  Pending: 3,
};

const VbList = () => {
  const userCtx = useContext(UserContext);
  const role = userCtx.userRole;

  const [vbList, setvbList] = useState([]);
  const pageSize = Global.dashboardPageSize;
  const [activeType, setActiveType] = useState(ListType.All);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const shareableFor = [RoleType.KITCHEN_OWNER, RoleType.KITCHEN_STAFF];

  const [isVirtualBrands, setIsVirtualBrands] = useState(null);
  const [isVbLoading, setisVbLoading] = useState(false);

  useEffect(() => {
    checkBrands();
  }, []);

  useEffect(() => {
    getAllList();
  }, [activeType, pageNumber]);

  const checkBrands = async () => {
    setisVbLoading(true);
    await hasVirtualBrand()
      .then((res) => {
        setisVbLoading(false);
        setIsVirtualBrands(res?.data);
      })
      .catch(() => {
        setisVbLoading(false);
        setIsVirtualBrands(false);
      });
  };

  const getAllList = async () => {
    let pageSiz = pageSize;

    if (
      pageNumber === 1 &&
      (activeType === ListType.All || activeType === ListType.Added)
    ) {
      pageSiz = 35;
    }

    let filter = {
      pageNum: pageNumber,
      pageSize: pageSiz,
      type: activeType,
    };
    setisLoading(true);
    await getVirtualBrands(filter)
      .then((res) => {
        let data = res.data;
          setvbList(data.virtualBrands);
          setTotalRecords(data.pagingParams.totalRecords);
          setisLoading(false);
      })
      .catch((_) => {
        setvbList(null);
        setTotalRecords(0);
        setisLoading(false);
      });
  };

  const setLoading = (bool) => {
      setisLoading(bool);
  };

  const changeBrandType = (step) => {
    setActiveType(step);
    setPageNumber(1);
  };

  const onPageChange = (page) => {
    setPageNumber(page);
  };

  return (
    <>
      {isVbLoading ? (
        <LoaderFull />
      ) : true ? (
        <>
          <Subheader title="Dashboard" />
          <div className="dashboardstabs">
            <Tab.Container
              mountOnEnter
              unmountOnExit
              id="virtualBrandsTabs"
              defaultActiveKey={`step${ListType.All}`}
            >
              <div className="bg">
                <div className="container">
                  <Row>
                    <Col sm={12}>
                      <Nav variant="tabs" className="virtualBrandsTabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={`step${ListType.All}`}
                            onClick={() => changeBrandType(ListType.All)}
                          >
                            All Virtual Brands
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={`step${ListType.Added}`}
                            onClick={() => changeBrandType(ListType.Added)}
                          >
                            Your Virtual Brands
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={`step${ListType.Shared}`}
                            onClick={() => changeBrandType(ListType.Shared)}
                          >
                            Shared Virtual Brands
                          </Nav.Link>
                        </Nav.Item>
                        {role === RoleType.ADMIN ||
                        role === RoleType.ADMIN_STAFF ? (
                          <Nav.Item>
                            <Nav.Link
                              eventKey={`step${ListType.Pending}`}
                              onClick={() => changeBrandType(ListType.Pending)}
                            >
                              Pending
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                      </Nav>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="container">
                <Row>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey={`step${ListType.All}`}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div className="tab__mainHeading">
                          <h1>All Virtual Brands</h1>
                        </div>
                        {isLoading ? (
                          <LoaderBlock />
                        ) : vbList && vbList.length ? (
                          <div className="multibrand__wrapper">
                            {pageNumber === 1 && <AddNewBrand />}
                            {vbList.map((brand, idx) => (
                              <BrandCard
                                onReload={getAllList}
                                brand={brand}
                                key={idx}
                                loading={setLoading}
                              />
                            ))}
                          </div>
                        ) : (
                          <>
                            <div className="multibrand__wrapper">
                              <AddNewBrand />
                            </div>
                            <div className="text-center no-data">
                              <div className="no-data-inner-wrapper">
                                <div className="img-box">
                                  <img
                                    src={fileIcon}
                                    alt="file icon"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="content-box">
                                  <h3>No virtual brands</h3>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey={`step${ListType.Added}`}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div className="tab__mainHeading">
                          <h1>Your Virtual Brands</h1>
                        </div>
                        {isLoading ? (
                          <LoaderBlock />
                        ) : vbList && vbList.length ? (
                          <div className="multibrand__wrapper">
                            {pageNumber === 1 && <AddNewBrand />}
                            {vbList.map((brand, idx) => (
                              <BrandCard
                                onReload={getAllList}
                                brand={brand}
                                key={idx}
                                loading={setLoading}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="text-center no-data">
                            <div className="no-data-inner-wrapper">
                              <div className="img-box">
                                <img
                                  src={fileIcon}
                                  alt="file icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="content-box">
                                <h3>No virtual brands</h3>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey={`step${ListType.Shared}`}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div className="tab__mainHeading">
                          <h1>Shared Virtual Brands</h1>
                        </div>
                        {isLoading ? (
                          <LoaderBlock />
                        ) : vbList && vbList.length ? (
                          <div className="multibrand__wrapper">
                            {vbList.map((brand, idx) => (
                              <BrandCard
                                onReload={getAllList}
                                brand={brand}
                                key={idx}
                                loading={setLoading}
                                isSharable={shareableFor.includes(role)}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="text-center no-data">
                            <div className="no-data-inner-wrapper">
                              <div className="img-box">
                                <img
                                  src={fileIcon}
                                  alt="file icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="content-box">
                                <h3>No virtual brands</h3>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey={`step${ListType.Pending}`}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div className="tab__mainHeading">
                          <h1>Pending Virtual Brands</h1>
                        </div>
                        {isLoading ? (
                          <LoaderBlock />
                        ) : vbList && vbList.length ? (
                          <div className="multibrand__wrapper">
                            {vbList.map((brand, idx) => (
                              <BrandCard
                                onReload={getAllList}
                                brand={brand}
                                key={idx}
                                loading={setLoading}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="text-center no-data">
                            <div className="no-data-inner-wrapper">
                              <div className="img-box">
                                <img
                                  src={fileIcon}
                                  alt="file icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="content-box">
                                <h3>No virtual brands</h3>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
                <div className="row mt-5">
                  <div className="col-12">
                    <Paginator
                      totalCount={totalRecords}
                      currentPage={pageNumber}
                      pageSize={pageSize}
                      pageClick={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </Tab.Container>
          </div>
        </>
      ) : (
        <NoVbBanner />
      )}
    </>
  );
};

export default VbList;
