import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Global } from "constants/global";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import uploadFileToBlob from "services/azure-blob";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import { addPrepStep, editPrepStep } from "services/api/recipeguide";
import LoaderInline from "shared/loader/LoaderInline";
import { AssetType } from "constants/assetType";
import RecipeGuideContext from "store/recipe-guide-context";
import toastify from "shared/toastr";

const PrepStepBasicInfo = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const resourceSize = Global.twoHunMB;
  const [resourceUrl, setresourceUrl] = useState(null);
  const [isResourceUploading, setisResourceUploading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [resourceType, setresourceType] = useState("image");
  let initialData = props.initialData;
  const [initData, setinitData] = useState(initialData);
  const extras = props.extras;
  const isDisabled = props.disabled;
  const selectedCategoryId = recipeguideCtx.recipeCategoryId;
  const[isAddNew, setisAddNew] = useState(false);

  useEffect(() => {
    if (
      (initialData) && !isAddNew && 
      (extras?.editMode || extras?.cloneMode || extras?.viewMode)
    ) {
      setinitData(initialData);
      reset({
        description: initialData.description,
        resourceUrl: initialData.resourceUrl || "",
      });
      setresourceUrl({ filePath: initialData.resourceUrl });
      setresourceType(initialData.resourceType);
    }
  }, [initialData]);

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .trim()
      .max(500, "Prep Step description not more than 500 characters")
      .required("Please enter Prep Step description")
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema)
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  const uploadResource = async (file) => {
    setisResourceUploading(true);
    const fileExtension = file?.name.split('.').pop();
    if (["mp4", "webm", "mov"].includes(fileExtension)) {
      setresourceType("video");
    }
    else{
      setresourceType("image");
    }
    await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
      (res) => {
        setresourceUrl(res);
        setisResourceUploading(false);
        setValue("resourceUrl", res?.fileName, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      },
      () => {
        setresourceUrl(null);
        setisResourceUploading(false);
      }
    );
  };

  const formResetonAddNew = () => {
    if (isAddNew) {
      recipeguideCtx.prepStepModalStatusChange(true, {
        editMode: false,
        cloneMode: false,
        viewMode: false
      });
      setinitData({});
      reset({
        description: "",
        resourceUrl: "",
      });
      setresourceUrl({ filePath: "" });
      setresourceType("image");
    }
    else {
      props.onSave();
    }
  }

  const onSubmit = async (data) => {
    if (isDisabled) {
      props.onSave(data);
      return;
    }
    let model = {
      ...data,
      categoryId: selectedCategoryId,
      resourceType: resourceType
    };

    setisLoading(true);
    if ((extras && extras?.editMode) && initData?.id) {
      await editPrepStep(initData.id, model).then(
        (res) => {
          toastify("success", res.message);
          setisLoading(false);
          formResetonAddNew();
        },
        (_) => {
          setisLoading(false);
        }
      );
    } else {
      await addPrepStep(model).then(
        (res) => {
          toastify("success", res.message);
          setisLoading(false);
          formResetonAddNew();
        },
        (_) => {
          setisLoading(false);
        }
      );
    }
    setisAddNew(false);
  };

  return (
    <div className="uploadAssets__wrapper basic__info__content body-overflow">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row no-gutters">

          <div className="col-md-12 mb-3 pb-3">
            <label htmlFor="categoryDesc">
              Step Description<span className="mendatory">*</span>
            </label>
            {isDisabled ? <div className="view_form_input">{initialData?.description || "NA"}</div>
              : <textarea
                id="categoryDesc"
                placeholder="Add Step Description"
                rows="3"
                className={`form-control ${errors?.description ? "is-invalid" : ""
                  }`}
                {...register("description")}
                maxLength="500"
                disabled={isDisabled}
              ></textarea>}
            {errors?.description?.message && (
              <div className="invalid-feedback d-block">
                {errors?.description?.message}
              </div>
            )}
          </div>

          <div className="col-12 mb-3 pb-3">
            <label htmlFor="resourceUrl">Step Video/Image
            </label>
            <AssetDropArea
              dimensions="2000 X 1200px"
              maxSize={resourceSize}
              acceptedFormat="image/jpeg, image/png, video/mp4, video/webm, video/mov"
              showFormatText="Video(mp4, webm, mov)/Image(Jpeg, Png)"
              onFileChange={uploadResource}
              isImageUploading={isResourceUploading}
              uploadedImage={resourceUrl?.filePath}
              uploadedRawImage={resourceUrl?.filePath}
              disabled={isDisabled}
              type={resourceType}
              btnText={"Change Img/Video"}
            />
            {errors?.resourceUrl?.message && (
              <div className="invalid-feedback d-block">
                {errors?.resourceUrl?.message}
              </div>
            )}
          </div>

          <div className="col-12">
            <div className="modal-footer">
            {/*   {extras && extras?.editMode ? props.children : null} */}

              <button
                type="submit"
                className="cancel-btn red-btn-ghost btn"
               /*  onClick={props.onHide} */
              >
                {isLoading ? (
                  <LoaderInline />
                ) : (
                  <>
                    {isDisabled ? "Close" : "Save & Close"}
                  </>
                )}
              </button>
              {!isDisabled && <button
               /*  type="submit" */
                className="red-btn next-btn btn"
                disabled={isLoading || !isValid}
                onClick={() => setisAddNew(true)}
              >
                {isLoading ? (
                  <LoaderInline />
                ) : (
                  <>
                    Save & Add New
                    <span className="icons-right">
                      <img src={rightArrowIcon} alt="arrow icon" />
                    </span>
                  </>
                )}
              </button>}
            </div>
          </div>
        
        </div>
      </form>
    </div>
  );
};

export default PrepStepBasicInfo;
