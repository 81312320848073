import React from "react";

const LoaderInline = () => {
  return (
    <div className="loader__inline">
      <div className="preloader-wrapper">
        <div className="loader">
          <div className="lds-roller">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderInline;
