import React from "react";
import { Link } from "react-router-dom";

import backArrowIcon from "../../assets/images/back-arrow.svg";
import './User.scss'

const EditUser = () => {
    return (
        <div className="users__wrapper">
        <div className="modifierDetails__wrapper modifierEdit__wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="goBack">
                  <Link to="/manage-user" className=" back-btn-red">
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </Link>
                </div>
              </div>
              <div className="col-12">
                <div className="main_heading">
                  <h1>Edit User</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="details__wrapper editModifier__wrapper">
                  <div className="details__wrapper__inner">
                    <form>
                      <div className="basic__info__content">
                        <div className="form-row">
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="fullname">
                              Full Name
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="fullname"
                              placeholder="Vipin Yadav"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                          <div class="col-md-12 mb-3 pb-3 px-0">
                          <label for="mandatory">User Type</label>
                          <span className="mendatory">*</span>
                          {/* <div class="check__wrapper d-none">
                            <label class="check-container mb-0">
                              Normal User
                              <input
                                type="radio"
                                name="isAlcoholic"
                                value="true"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="check-container mb-0">
                              Staff User
                              <input
                                type="radio"
                                name="isAlcoholic"
                                value="false"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div> */}
                          <select className="form-control big-select" placeholder="Please select a role">
                              <option value="">Website Admin</option>
                              <option value="">Website Staff</option>
                              <option value="">Restaurant Owner</option>
                              <option value="">Restaurant Staff</option>
                          </select>
                        </div>
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="contactno">
                              Contact No
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="contactno"
                              placeholder="83681865188"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="accounemail">
                              Account Email
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="accounemail"
                              placeholder="xyz@gmail.com"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="addressline1">
                              Address Line 1<span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="addressline1"
                              placeholder="addressline1"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="addressline2">
                              Address Line 2<span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="addressline2"
                              placeholder="addressline2"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3 pb-3 px-0 pr-md-3 pl-md-0">
                          <label htmlFor="city">
                            City
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="city"
                            placeholder="Los Angeles"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-3 pb-3 px-0 pl-md-3 pr-md-0">
                          <label>
                            State<span className="mendatory">*</span>
                          </label>
                          <select className="form-control">
                              <option value="">California</option>
                              <option value="">California</option>
                              <option value="">California</option>
                              <option value="">California</option>
                            </select>
                        </div>
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="country">
                              Country
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="country"
                              placeholder="USA"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-lg-3 px-0">
                            <label htmlFor="zip">
                              Zip
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="zip"
                              placeholder="123456"
                              className="form-control"
                              maxLength="30"
                            />
                          </div>
                        </div>
                        <div className="col-md-12 px-0">
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="cancel-btn red-btn-ghost btn"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="red-btn next-btn btn"
                            >
                              Save User
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default EditUser
