import React, { useState }from "react";
import searchIcon from "assets/images/icon-search.svg";

const SearchFilter = (props) => {
  const initialValue = props.value;
  const sendInfo = props.onSelect;
  const placeHolder = props.placeHolder;
  const [searchTerm, setsearchTerm] = useState(null);

  const onSearch = (e) => {
    let search = e.target.value;
    setsearchTerm(search);
    /* if (e.key === "Enter" || search === "") */ sendInfo(search);
  };

  return (
    <>
      <div className="search_modifier_group">
        <div className="input-group">
          <div className="custom-file">
            <input
              type="text"
              className="custom-file form-control"
              defaultValue={initialValue}
              placeholder={placeHolder}
              onKeyUp={onSearch}
            />
            <button className="search-btn" onClick={() => sendInfo(searchTerm)}>
              <span className="icons">
                <img src={searchIcon} alt="search icon" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
