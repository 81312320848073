import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import "./PopUpModal.scss";
import LoaderInline from "shared/loader/LoaderInline";
import SelectDropdown from "UI/Select-Dropdown/SelectDropdown";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";

const ExportCSVModal = (props) => {
  const [value, setValue] = useState(1);

  const onChange = (val) => {
    setValue(val);
    props?.onSelect(val);
  };

  return (
    <>
      <div className="confirmation__wrapper">
        <Modal
          show={props.show}
          onHide={props.onCancel}
          animation={false}
          id="confirmation__modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="second-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="confirmation__heading">{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <p className="mb-0">
              {props.subtitle}
            </p>
            <div className="flex__container flex__center__container mt-4">
              <div className="font__15__semibold m-30">Select Page No.</div>
              <div className="userselect mr-md-4">
                <div className="user__custom__select selct__page__dropdown">
                  <SelectDropdown
                    options={props?.options}
                    placeholder="Select No"
                    selectedValue={(val) => onChange(val?.value)}
                    value={value}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              onClick={props.onCancel}
              className="cancel-btn red-btn-ghost btn"
              disabled={props.onLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={props.onSubmit}
              className="red-btn btn"
              disabled={props.onLoading}
            >
              {props.onLoading ? <LoaderInline /> : "Export CSV"}
              {!props.onLoading && <span className="icons-right">
                <img src={rightArrowIcon} alt="arrow icon" />
              </span>}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

ExportCSVModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLoading: PropTypes.bool.isRequired,
};

export default ExportCSVModal;
