import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./FAQ.scss";
import { Accordion, Card, Button } from "react-bootstrap";
import landingLogo__black from "assets/images/landing-logo-black.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import { getUserDataFromToken } from "services/local-storage";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const FaqContainer = () => {
  const content = [
    {
      question: "Can I download the Backhouse Brands app on my own device?",
      answer:
        "Our companion app can be downloaded on any modern Android tablet device. If you have your own Android tablet, you can download the app by visiting the Google Play Store. The app is currently not available for iPad devices.",
    },
    {
      question: "Do I have to use the app to create a virtual brand?",
      answer:
        "No, the app is used for order management. The website is where brands are created and associated with kitchens. Currently, brands can be created on the web and any iOS or Android device.",
    },
    {
      question: "How long does it take to activate a new virtual brand?",
      answer:
        "Once you submit your setup request, the activation process will take approximately 7-10 business days for a fully approved virtual brand.",
    },
    {
      question:
        "All if my menus are showing online at the same time, how can I fix this?",
      answer:
        "A virtual brand is a collection of menus and each menu can have its unique hours of availability. You can prevent a dinner menu from showing up during breakfast hours by updating the hours and availability.",
    },
    {
      question: "Can I make changes to my own menu?",
      answer:
        "Menus for brands that you fully own and operate can be changed at any time. Changes are subject to a quick review process and will be published and synced to the major 3rd party delivery services.",
    },
    {
      question: "How can I set up auto-printing of tickets?",
      answer:
        "By default, all tickets are printed automatically after being accepted. Orders can be re-printed at any time by tapping the print receipt details button on the order details screen.",
    },
    {
      question: "Who can I reach out to for tablet support?",
      answer:
        "You can contact support@backhouse.io with any questions or issues and we will respond promptly to provide any support with setting up your virtual brand or managing orders using our companion tablet.",
    },
    {
      question: "Are special instructions supported on the companion tablet?",
      answer:
        "Yes, this is supported and is turned on by default for all incoming orders.",
    },
    {
      question: "How do I deactivate my virtual brand?",
      answer:
        "If you want to deactivate your virtual brand, you can do so temporarily by disabling the menu availability. To permanently deactivate a virtual brand, contact Backhouse Brands Support.",
    },
    {
      question: "Where do I manage my operating hours?",
      answer:
        "You can manage your hours by navigating to your virtual brand from your dashboard and choosing the Edit option to update hours.",
    },
    {
      question: "Can I hide items from my DoorDash menu?",
      answer:
        "Yes. You can disable menu items and re-publish your virtual brand in order for the changes to be reflected.",
    },
    {
      question: "How do I add photos to my virtual brand menu?",
      answer:
        "You can build your own menu and upload photos during the menu creation process. Menu items can be edited at any time to add or remove photos. Note that the menu must be published again in order for the changes to take effect.",
    },
  ];

  const isLoggedIn = getUserDataFromToken();

  const [scrolled, setScrolled] = useState(false);
  const [activeToggleId, setActiveToggleId] = useState(null);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }

  const handleAccordion = (id) => {
    if (activeToggleId === id) {
      setActiveToggleId(null);
    } else {
      setActiveToggleId(id);
    }
  };

  return (
    <>
      <div className="landingPage__wrapper static__pages__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid white__logo"
                    width={120}
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                    width={120}
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                {isLoggedIn ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-link signUp__link w-100 h-100 mx-auto"
                    role="button"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-link signIn__link"
                      role="button"
                    >
                      Login
                    </Link>
                    <Link
                      to="/kitchen/register"
                      className="btn btn-link signUp__link"
                      role="button"
                    >
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        <Subheader mobileExtraClass="staticPages_subheader">
          <div className="goBack mb-3">
            <Link to="/home" className="back-btn-red" title="Go to Dashboard">
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Back
            </Link>
          </div>
        </Subheader>
        <main>
          <div className="container">
            <section className="page__title__section">
              <div className="row">
                <div className="col-12 d-none d-md-block">
                  <div className="goBack">
                    <Link to="/home" className=" back-btn-red">
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back to Home
                    </Link>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Frequently Asked Questions</h1>
                  </div>
                </div>
              </div>
            </section>
            <div className="faq__content__wrapper">
              <Accordion defaultActiveKey="0">
                {content &&
                  content.map((cnt, idx) => (
                    <Card key={idx}>
                      <Card.Header>
                        <Accordion.Toggle
                          onClick={() => handleAccordion("" + idx)}
                          as={Button}
                          variant="link"
                          eventKey={"" + idx}
                          className={
                            activeToggleId === "" + idx ? " collapsed" : ""
                          }
                        >
                          <div className="accodion__header__content">
                            <div className="heading">
                              <span>{cnt.question}</span>
                            </div>
                          </div>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={"" + idx}>
                        <Card.Body>
                          <div className="accordion__body__wrapper">
                            <div className="accordion__data__card">
                              <div className="row">
                                <div className="col-12">
                                  <div className="faq__body__content">
                                    <p>{cnt.answer}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
              </Accordion>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

const FAQ = () => {
  const [currentEventKey, setCurrentEventKey] = useState(null);

  return (
    <>
      <FaqContainer />
    </>
  );
};

export default FAQ;
