export const RoleTypeId = {
  ADMIN: 1,
  ADMIN_STAFF: 2,
  KITCHEN_STAFF: 3,
  KITCHEN_OWNER: 4,
  BRAND_OWNER: 5,
  AFFILIATE_USER: 6
};

export const RoleType = {
  ADMIN: "Admin",
  ADMIN_STAFF: "Admin Staff",
  KITCHEN_STAFF: "Staff",
  KITCHEN_OWNER: "Restaurant Owner",
  BRAND_OWNER: "Brand Owner",
  AFFILIATE_USER: "Affiliate User"
};

export const RoleTypeDropdown = [
  {
    value: RoleTypeId.ADMIN,
    text: "Admin",
  },
  {
    value: RoleTypeId.ADMIN_STAFF,
    text: "Admin Staff",
  },
  {
    value: RoleTypeId.KITCHEN_OWNER,
    text: "Restaurant Owner",
  },
  {
    value: RoleTypeId.KITCHEN_STAFF,
    text: "Restaurant Staff",
  },
  {
    value: RoleTypeId.BRAND_OWNER,
    text: "Brand Owner",
  },
  {
    value: RoleTypeId.AFFILIATE_USER,
    text: "Affiliate User",
  }
];

export const RoleTypeDropdownOnList = [
  {
    value: 0,
    text: "All Users",
  },
  {
    value: RoleTypeId.ADMIN,
    text: "Admin",
  },
  {
    value: RoleTypeId.ADMIN_STAFF,
    text: "Admin Staff",
  },
  {
    value: RoleTypeId.KITCHEN_OWNER,
    text: "Restaurant Owner",
  },
  {
    value: RoleTypeId.KITCHEN_STAFF,
    text: "Restaurant Staff",
  },
  {
    value: RoleTypeId.BRAND_OWNER,
    text: "Brand Owner",
  },
  {
    value: RoleTypeId.AFFILIATE_USER,
    text: "Affiliate User",
  }
];
