import React from "react";
import { Link } from "react-router-dom";
import { numberFormat } from "constants/numberFormat";

const ListingRow = (props) => {
    const data = props?.customer;

    return (
      <>
        <tr role="row" className="mat-row">
          <td className="mat-cell column_1" data-column="CUSTOMER">
            <Link
              to={"/customer/" + data?.id}
              className="modifierDeatil__link d-inline-block w-100"
            >
              <p>{data?.customer}</p>
            </Link>
          </td>
          <td className="mat-cell column_2" data-column="PHONE">
            <Link
              to={"/customer/" + data?.id}
              className="modifierDeatil__link d-inline-block w-100"
            >
              <p>{data?.phone}</p>
            </Link>
          </td>
          {/*  <td className="mat-cell column_3" data-column="EMAIL">
            <Link
              to={"/customer/" + data?.id}
              className="modifierDeatil__link d-inline-block w-100"
            >
              <p className="email">{data?.email}</p>
            </Link>
          </td> */}
          <td className="mat-cell column_4" data-column="# ORDERS">
            <Link
              to={"/customer/" + data?.id}
              className="modifierDeatil__link d-inline-block w-100"
            >
              <p>{data?.totalOrders}</p>
            </Link>
          </td>
          <td className="mat-cell column_5" data-column="TOTAL SALES">
            <Link
              to={"/customer/" + data?.id}
              className="modifierDeatil__link d-inline-block w-100"
            >
              <p>{numberFormat(data?.totalSales)}</p>
            </Link>
          </td>
          <td className="mat-cell column_6" data-column="LOCATION">
            <Link to={"/customer/" + data?.id} className="modifierDeatil__link">
              <div className="right_content">
                <p>{data.location && data?.location !== ", "
                  ? data.location
                  : "NA"}</p>
              </div>
            </Link>
          </td>
          {/* <td className="mat-cell column_7" data-column="ACTION">
            <div className="right_content">
              <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="dropdown-button-drop-left"
                ></button>
                <div className="dropdown-menu">
                  <div className="dropdown__innerWrapper">
                    <Link
                      to={"/customer/" + data?.id}
                      className="dropdown-item"
                    >
                      <span>View</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </td> */}
        </tr>
      </>
    );
};

export default ListingRow;
