import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import PrepStepCard from "../layout-wrapper/PrepStepCard";
import {getRecipePrepSteps, changeRecipeCategoryStatus, deleteRecipeCategory} from "services/api/recipeguide";
import { DropdownButton, Dropdown } from "react-bootstrap";
import AddButton from "UI/Button/AddButton";
import PrepStepModal from "../recipeguide-modal/PrepStepModal";
import EditButton from "UI/Button/EditButton";
import NoPrepStepCard from "./NoPrepStepCard";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import "./RecipeCategoryCard.scss";
import RecipeGuideContext from "store/recipe-guide-context";

const RecipeCategoryCard = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const recipeCategoryId = recipeguideCtx.recipeCategoryId;
  const modalStatus = recipeguideCtx.prepStepModalStatus;
  const editable = recipeguideCtx.isConfirmPage;
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;

  const categoryId = props.id;
  const [activeStatus, setactiveStatus] = useState(props.isActive);

  const [prepSteps, setprepSteps] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  useEffect(() => {
    if (categoryId) {
      getPrepSteps();
    }
  }, [categoryId]);

  useEffect(() => {
    if (categoryId === recipeCategoryId && modalStatus === false) getPrepSteps();
  }, [modalStatus]);

  const showModal = () => {
    recipeguideCtx.prepStepModalStatusChange(true);
    recipeguideCtx.saveRecipeCategoryId(categoryId);
  };

  const closeModal = () => {
    recipeguideCtx.prepStepModalStatusChange(false);
  };

  const getPrepSteps = async () => {
    setisLoading(true);
    await getRecipePrepSteps(categoryId).then((res) => {
      setisLoading(false);
      setprepSteps(res.data);
    })
    .catch(() => {
      setisLoading(false);
      setprepSteps([]);
    });
  };

  const onView = () => {
    recipeguideCtx.recipeCategoryModalStatusChange(true, {
      editMode: false,
      viewMode: true,
      data: props,
    });
  };

  const onEdit = () => {
    recipeguideCtx.recipeCategoryModalStatusChange(true, {
      editMode: true,
      viewMode: false,
      data: props,
    });
  };

  const onChangeStatus = async () => {
     await changeRecipeCategoryStatus(categoryId, !activeStatus).then((res) => {
       toastify("success", res?.message);
       setactiveStatus(!activeStatus);
     });
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);
  const onDelete = async () => {
     setdeleteLoading(true);
     await deleteRecipeCategory(categoryId)
       .then((res) => {
         toastify("success", res.message);
         setdeleteLoading(false);
         closeDeleteModal();
         props.onReload();
       })
       .catch(() => {
         setdeleteLoading(false);
       });
  };

  return (
    <>
      <div className="col-12">
        <div className="recipeCategoriesCard__wrapper">
          <div className="menuCategoriesCard__wrapper__inner">
            <div className="menuCategoriesCard">
              <div className="menuCategoriesCard__header">
                <div className="row align-items-md-end">
                  <div className="col-12 col-md-6">
                    <div className="main__heading">
                      <h2>{props?.title}
                      {editable ? (
                        recipeGuideMode === VirtualBrandMode.SHARED ||
                          recipeGuideMode === VirtualBrandMode.VIEW ? (
                          <ViewButton onClick={onView} />
                        ) : (
                          <EditButton onClick={onEdit} />
                        )
                      ) : null}</h2>
                    </div>
                  </div>
                
                  {/* <div className="d-md-flex justify-content-between align-items-center"> */}
                    {recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                      <div className="threedotsbtn btn-group dropleft">
                        <DropdownButton
                          key="left"
                          id="dropdown-button-drop-left"
                          drop="left"
                          variant="secondary"
                          className="leftDrowpdown"
                        >
                          <Dropdown.Item onClick={onChangeStatus}>
                            <div className="dropdown__innerWrapper">
                              <span>
                                Set As {activeStatus ? "Inactive" : "Active"}
                              </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={onEdit}>
                            <div className="dropdown__innerWrapper">
                              <span>Edit Category</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={openDeleteModal}>
                            <div className="dropdown__innerWrapper">
                              <span>Delete Category</span>
                            </div>
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    )}
                 {/*  </div> */}
                </div>
              </div>
              <div className="menuCategoriesCard__body">
                {isLoading ? (
                  <>
                    <ShimmerLoader />
                  </>
                ) : prepSteps && prepSteps.length ? (
                  prepSteps.map((item, idx) => (
                    <PrepStepCard
                      {...item}
                      key={idx}
                      index={idx}
                      categoryId={categoryId}
                      onReload={getPrepSteps}
                    />
                  ))
                ) : (
                  <NoPrepStepCard />
                )}
              </div>
              <div className="menuCategoriesCard__footer">
                <div className="btn-link-type">
                  {recipeGuideMode === VirtualBrandMode.SHARED ||
                    recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                    <AddButton title="Add New Prep Step" openModal={showModal} />
                  )}
                </div>
                <PrepStepModal
                  show={modalStatus}
                  className="right"
                  onHide={closeModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Recipe Category"
          subtitle="Are you sure want to delete this recipe category, all prep steps would be deleted."
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

RecipeCategoryCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  isActive: PropTypes.bool,
  onReload: PropTypes.func,
};

export default RecipeCategoryCard;
