import React, { useContext } from "react";
import "./Subheader.scss";

import PropTypes from "prop-types";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";

const Subheader = (props) => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const title = props.title;

  return (
    <>
      {![RoleType.KITCHEN_STAFF].includes(userRole) ? (
        <>
          <div className="d-none d-md-block">
            <div className={"subheader " + props.extraClass}>
              <div className="container">
                <h3>{title}</h3>
              </div>
            </div>
          </div>
          <div className="d-md-none">
            <div className={"subheader non-auth-subheader-desktop " + props.mobileExtraClass}>
              <div className="container">
                {props.children}
                <h3>{title}</h3>
              </div>
            </div>
          </div>
        </>
        ) 
      : null}
    </>
  );
};

Subheader.propTypes = {
  title: PropTypes.string,
  extraClass: PropTypes.string,
};

export default Subheader;
