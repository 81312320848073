import React, { useContext, useEffect, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import "./ManageModifierLandingpage.scss";
import "./ManageUserLandingPage.scss";
import searchIcon from "assets/images/icon-search.svg";
import plusIcon from "assets/images/add-icon-white.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import Paginator from "shared/paginator/Paginator";
import ListingRow from "./ListingRow";
import { Global } from "constants/global";
import LoaderBlock from "shared/loader/LoaderBlock";
import { getStaffs } from "services/api/staff";
import backArrowIcon from "assets/images/back-arrow.svg";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import {
  RoleType,
  RoleTypeDropdownOnList,
  RoleTypeId,
} from "constants/roleType";
import SelectDropdown from "UI/Select-Dropdown/SelectDropdown";
import UserContext from "store/user-context";

const roleMapper = (list) => {
  return list.map((res) => ({
    value: res.value,
    label: res.text,
  }));
};

const staffParams = new URLSearchParams();

const StaffListing = () => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;

  const history = useHistory();
  const location = useLocation();

  const page = new URLSearchParams(location?.search).get("page");
  const search = new URLSearchParams(location?.search).get("search");
  const role = new URLSearchParams(location?.search).get("role");

  const [pageNum, setpageNum] = useState(page || 1);
  const [searchTerm, setsearchTerm] = useState(search || null);
  const [searchValue, setsearchValue] = useState(search || null);
  const [roleId, setroleId] = useState(0);
  const [roleTypes, setRoleTypes] = useState(
    roleMapper(RoleTypeDropdownOnList)
  );

  const pageSize = Global.pageSize;

  const [allStaff, setallStaff] = useState(null);

  const [isLoading, setisLoading] = useState(false);
  const [totalRecords, settotalRecords] = useState(0);

  useEffect(() => {
    if (location) {
      if (location?.search) {
        let page = new URLSearchParams(location?.search).get("page");
        let search = new URLSearchParams(location?.search).get("search");
        let role = new URLSearchParams(location?.search).get("role");

        if (page) {
          setpageNum(+page);
          staffParams.set("page", page);
        } else {
          setpageNum(1);
          staffParams.delete("page");
        }

        if (search) {
          setsearchTerm(search);
          staffParams.set("search", search);
        } else {
          setsearchTerm(null);
          staffParams.delete("search");
        }

        if (role) {
          setroleId(Number(role));
          staffParams.set("role", role);
        } else {
          setroleId(0);
          staffParams.delete("role");
        }
      } else {
        setpageNum(1);
        staffParams.delete("page");
        setsearchTerm(null);
        staffParams.delete("search");
        setroleId(0);
        staffParams.delete("role");
      }
    }
  }, [location]);

  useEffect(() => {
    if (userRole === RoleType.ADMIN || userRole === RoleType.ADMIN_STAFF) {
      setRoleTypes(roleMapper(RoleTypeDropdownOnList));
    }
    if (
      userRole === RoleType.KITCHEN_OWNER ||
      userRole === RoleType.KITCHEN_STAFF
    ) {
      let kitchenRoles = RoleTypeDropdownOnList.filter((role) => {
        if (
          role.value === 0 ||
          role.value === RoleTypeId.KITCHEN_OWNER ||
          role.value === RoleTypeId.KITCHEN_STAFF
        )
          return role;
        else return null;
      });
      setRoleTypes(roleMapper(kitchenRoles));
    }
  }, [userRole]);

  useEffect(() => {
    getListing();
  }, [pageNum, searchTerm, roleId]);

  const getListing = async () => {
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      searchTerm: searchTerm,
      type: roleId,
    };
    setisLoading(true);
    await getStaffs(masks)
      .then((res) => {
        let data = res.data;
        setallStaff(data.users);
        settotalRecords(data.pagingParams.totalRecords);
        setisLoading(false);
      })
      .catch(() => {
        setallStaff(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    setpageNum(page);
    if (page) {
      staffParams.set("page", page);
    } else {
      staffParams.delete("page");
    }
    history.push({ search: staffParams.toString() });
  };

  const onSearch = (e) => {
    let search = e.target.value;
    setsearchValue(search);
      if (search) {
        staffParams.set("search", search);
      } else {
        staffParams.delete("search");
      }
      //setpageNum(1);
      staffParams.delete("page");
      history.push({ search: staffParams.toString() });
  };

  const onSearchValue = () => {
      if (searchValue) {
        staffParams.set("search", searchValue);
      } else {
        staffParams.delete("search");
      }
      //setpageNum(1);
      staffParams.delete("page");
      history.push({ search: staffParams.toString() });
  };

  const onRoleSelect = (val) => {
    setroleId(val);
    staffParams.set("role", val);
    //history.push({ search: staffParams.toNumber() });
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
      <div className="manageUser__landingPage__wrapper">
        <div className="managermodifier__wrapper basic__info__content">
          <div className="container">
            <div className="managermodifier__header">
              <div className="row justify-content-between">
                <div className="col-12 mb-3 pb-3 d-none d-md-block">
                  <div className="goBack">
                    <Link
                      to="/dashboard"
                      className="back-btn-red"
                      title="Go to Dashboard"
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Go to Dashboard
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-xl-4 mb-md-3 mb-xl-0">
                  <h1>Manage Users</h1>
                </div>
                <div className="col-12 col-md-12 col-xl-8">
                  <div className="staff_listing_actions">
                    <div className="form">
                      <div className="search__modifiers manage-restra-search manage-user-search mr-md-4">
                        <div className="search_modifier_group mb-0" >
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="text"
                                className="custom-file form-control "
                                placeholder="Search User"
                                defaultValue={searchTerm}
                                onKeyUp={onSearch}
                              />
                              <button type="button" className="search-btn" onClick={() => onSearchValue()}>
                                <span className="icons">
                                  <img src={searchIcon} alt="plus icon" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="userselect mr-md-4">
                        <div className="user__custom__select">
                          {roleTypes && (
                            <SelectDropdown
                              options={roleTypes}
                              placeholder="Select User"
                              selectedValue={(val) => onRoleSelect(val?.value)}
                              value={roleId}
                            />
                          )}
                        </div>
                      </div>
                      {(userRole !== RoleType.ADMIN_STAFF && userRole !== RoleType.BRAND_OWNER) ? (
                        <div className="adduser__btn">
                          <Link to="/staff/add" className="red-btn btn">
                            <span className="icons-left icons">
                              <img src={plusIcon} alt="add icon" />
                            </span>
                            Add New User
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {allStaff && totalRecords === 0 ? (
              <div className="managermodifier__noListing">
                <div className="managermodifier__noListing__innerwrapper">
                  <div className="content">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={emptyIocn}
                        alt="no list icon"
                        width="185"
                      />
                    </div>
                    <div className="content-desc">
                      <h3>
                        {searchTerm && searchTerm !== ""
                          ? "No staff(s) found"
                          : "No staffs are added"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="managermodifier__listinPage staff__listing__page">
                <div className="managermodifier__listinPage__wrapper">
                  <table className="table modifierListing__table">
                    <thead>
                      <tr role="row" className="mat-header-row">
                        <th scope="col" className="mat-header-cell column-1">
                          Name
                        </th>
                        <th scope="col" className="mat-header-cell column-2">
                          Contact
                        </th>
                        <th scope="col" className="mat-header-cell column-3">
                          Type
                        </th>
                        <th scope="col" className="mat-header-cell column-4">
                          Created On
                        </th>
                        <th scope="col" className="mat-header-cell column-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td>
                            <LoaderBlock />
                          </td>
                        </tr>
                      ) : (
                        allStaff &&
                        allStaff.map((staff, idx) => (
                          <ListingRow key={idx} staff={staff} />
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-12">
                      <Paginator
                        totalCount={totalRecords}
                        currentPage={pageNum}
                        pageSize={pageSize}
                        pageClick={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffListing;