import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "react-bootstrap";
import "./DeleteModal.scss";
import LoaderInline from "shared/loader/LoaderInline";

const DeleteModal = (props) => {
  return (
    <>
      <div className="confirmation__wrapper">
        <Modal
          show={props.show}
          onHide={props.onCancel}
          animation={false}
          id="confirmation__modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="second-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="confirmation__heading">{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <p className="mb-0">{props.subtitle}</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              onClick={props.onCancel}
              className="cancel-btn red-btn-ghost btn"
            >
              Cancel
            </Button>
            <Button
              onClick={props.onSubmit}
              className="red-btn btn"
              disabled={props.onLoading}
            >
              {props.onLoading ? <LoaderInline /> : props?.buttonText || "Yes, Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

DeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLoading: PropTypes.bool.isRequired,
};

export default DeleteModal;
