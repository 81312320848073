import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router";

import "./ModifierView.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import {
  changeStatusModifierGroup,
  getModifierGroup,
} from "services/api/modifier";
import LoaderBlock from "shared/loader/LoaderBlock";
import { ModifierType } from "constants/modifierType";
import toastify from "shared/toastr";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { numberFormat } from "constants/numberFormat";

const ModifierView = (props) => {
  const { modGroupId } = useParams();
  const [modGroup, setmodGroup] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (modGroupId) {
      if (parseInt(modGroupId) > 0) {
        getDetail(+modGroupId);
      }
    }
  }, [modGroupId]);

  const getDetail = async (id) => {
    setisLoading(true);
    await getModifierGroup(id)
      .then((res) => {
        setisLoading(false);
        setmodGroup(res.data);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.goBack();
  };

  const checkModType = (type) => {
    switch (type) {
      case ModifierType.FOOD:
        return "Food Modifier";
      case ModifierType.DRINK:
        return "Drink Modifier";
      default:
        return "NA";
    }
  };

  const onChangeStatus = async () => {
    await changeStatusModifierGroup(modGroup?.id, !modGroup?.isActive).then(
      (res) => {
        toastify("success", res.message);
        setmodGroup((prev) => ({ ...prev, isActive: res.data.isActive }));
      }
    );
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="modifierDetails__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button onClick={onBack} className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Modifiers Details</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                {isLoading ? (
                  <LoaderBlock />
                ) : (
                  <div className="details__wrapper__inner">
                    <div className="left_content">
                      <div className="detailCard mb-3 mb-md-0">
                        <h5>Modifier Group Name</h5>
                        <p>{modGroup?.name}</p>
                      </div>
                      <div className="detailCard mb-3 mb-md-0">
                        <h5>Modifier Type</h5>
                        <p>{checkModType(modGroup?.modifierType)}</p>
                      </div>
                      {modGroup?.menuItemModOptions &&
                        modGroup?.menuItemModOptions.length ? (
                        <div className="detailCard mb-3 mb-md-0">
                          <table style={{"width": "900px"}}>
                            <thead>
                              <tr>
                                <th style={{"width": "50%"}} className="mofifierOptions">
                                  Modifier Options
                                </th>
                                <th style={{"width": "15%"}}>Price</th>
                                <th style={{"width": "15%"}}>Plu</th>
                                <th style={{"width": "15%"}}>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {modGroup?.menuItemModOptions.map((option, idx) => (
                                <tr key={idx}>
                                  <td>{option?.name || ''}</td>
                                  <td>{numberFormat(option?.price || 0)}</td>
                                  <td>{option?.plu || "-"}</td>
                                  <td>{'Active'}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>No Option(s)</p>
                      )}
                    </div>
                    <div className="right_content">
                      <div className="btn-group dropleft threeDotsBropdown">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="dropdown-button-drop-left"
                        ></button>
                        <div className="dropdown-menu">
                          <div className="dropdown__innerWrapper">
                            <button
                              onClick={onChangeStatus}
                              className="dropdown-item"
                            >
                              <span>
                                Set As{" "}
                                {modGroup?.isActive ? "Inactive" : "Active"}
                              </span>
                            </button>
                          </div>
                          <div className="dropdown__innerWrapper">
                            <Link
                              to={"/modifiers/edit/" + modGroup?.id}
                              className="dropdown-item"
                            >
                              <span>Edit</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ModifierView);
