import React from "react";
import { Link } from "react-router-dom";
import "./ManageModifierLandingpage.scss";
import "./SharedVirtualBrand.scss";

const AssociatedDevices = () => {
  return (
    <div className="associated__devices__wrapper">
      <div className="managermodifier__wrapper basic__info__content">
          <div className="managermodifier__listinPage managerestra__listinPage">
            <div className="managermodifier__listinPage__wrapper">
              <table className="table modifierListing__table associatedListing__table">
                <thead>
                  <tr role="row" className="mat-header-row">
                    <th scope="col" className="mat-header-cell column-1">
                    RESTURANT NAME
                    </th>
                    <th scope="col" className="mat-header-cell column-2">
                    DEVICE
                    </th>
                    <th scope="col" className="mat-header-cell column-3">
                    TYPE
                    </th>
                    <th scope="col" className="mat-header-cell column-5">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICES">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Samsung Tab S7
                      </Link>
                    </td>
                    <td className="mat-cell column-3" data-column="TYPE">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Tablet
                      </Link>
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Pending
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Active</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Disable</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Draft</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Pending</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICES">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Samsung Tab S7
                      </Link>
                    </td>
                    <td className="mat-cell column-3" data-column="TYPE">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Tablet
                      </Link>
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Pending
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Active</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Disable</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Draft</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Pending</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICES">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Samsung Tab S7
                      </Link>
                    </td>
                    <td className="mat-cell column-3" data-column="TYPE">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Tablet
                      </Link>
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Pending
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Active</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Disable</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Draft</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Pending</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICES">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Samsung Tab S7
                      </Link>
                    </td>
                    <td className="mat-cell column-3" data-column="TYPE">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Tablet
                      </Link>
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Pending
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Active</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Disable</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Draft</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Pending</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICES">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Samsung Tab S7
                      </Link>
                    </td>
                    <td className="mat-cell column-3" data-column="TYPE">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link"
                      >
                        Tablet
                      </Link>
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Pending
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Active</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Disable</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Draft</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Pending</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
  );
};

export default AssociatedDevices;
