import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import dateFormat from "dateformat";

import "./BrandCard.scss";
import plusIcon from "assets/images/add-icon-white.svg";
import brandLogo from "assets/images/admin-created-logo.svg";
import brandImage from "assets/images/placeholder-cover.svg";
import { BrandStatus } from "constants/brandStatus";
import DeleteModal from "UI/Modal/DeleteModal";
import { changeBrandStatus, deleteVirtualBrand, updateSharedVBRemovalStatus, getVirtualBrandStatus } from "services/api/brand";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import UserContext from "store/user-context";
import { addVbToKitchen } from "services/api/kitchen";
import { RoleType, RoleTypeId } from "constants/roleType";
import VirtualBrandContext from "store/virtual-brand-context";
import ImageSrc from "shared/image-src/ImageSrc";
import PopUpModal from "UI/Modal/PopUpModal";
import RecipeGuideContext from "store/recipe-guide-context";
import recipeguideLogo from "assets/images/recipeguide-icon.svg";

const BrandCard = (props) => {
  const brand = props.brand;
  const brandId = brand.id;
  const isSharable = props.isSharable;
  const associationId = brand.kitchenVBAssociationId;
  const isKitchenAlreadyAssociated = brand.isAssociated;

  const brandCtx = useContext(VirtualBrandContext);
  const userCtx = useContext(UserContext);
  const userInfo = userCtx.userInfo;
  const userRole = userCtx.userRole;

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [removeSharedVB, setremoveSharedVB] = useState(false);

  //const [isLoading, setisLoading] = useState(false);
  const [isAdding, setisAdding] = useState(false);

  const [brandStatus, setbrandStatus] = useState(brand.statusId);

  const [currentStatus, setcurrentStatus] = useState({
    class: null,
    text: null,
  });
  const [editVBModal, seteditVBModal] = useState(false);
  const [statusVBModal, setstatusVBModal] = useState(false);
  const [isvbStatusLoading, setisvbStatusLoading] = useState(false);
  const [vBStatus, setisvBStatus] = useState(brand?.statusId);
  const isAssociated = brand.kitchenVBAssociationId > 0;
  const isSharedBrand = ([RoleTypeId.ADMIN, RoleTypeId.ADMIN_STAFF, RoleTypeId.BRAND_OWNER].includes(brand?.creatorRole) && (isAssociated || brand?.kitchenId));
  const isAvailable = [RoleTypeId.ADMIN, RoleTypeId.ADMIN_STAFF, RoleTypeId.BRAND_OWNER].includes(brand?.creatorRole);
  let vbStatus = brand?.statusId;
  const recipeguideCtx = useContext(RecipeGuideContext);
  const isNotBrandOwner = (brand?.createdBy !== userInfo?.userId && [RoleType.BRAND_OWNER].includes(userRole));

  useEffect(() => {
    setStatus(brandStatus);
  }, [brand]);
  const setStatus = (statusId) => {
    if(brand?.requestforRemoval === true){
      setcurrentStatus({ class: "badge__yellow", text: "Removal Pending" });
    }
    else {
    switch (statusId) {
      case BrandStatus.DRAFT:
        setcurrentStatus({ class: "badge__gray", text: "Draft" });
        break;
      case BrandStatus.PUBLISHED:
        setcurrentStatus({ class: "badge__red", text: "Published" });
        break;
      case BrandStatus.UNPUBLISHED:
        setcurrentStatus({ class: "badge__gray", text: "Unpublished" });
        break;
      case BrandStatus.PENDING:
        setcurrentStatus({ class: "badge__blue", text: "Pending" });
        break;
      case BrandStatus.PUBLISHING:
        setcurrentStatus({ class: "badge__blue", text: "Publishing" });
        break;
      default:
        setcurrentStatus({ class: "badge__blue", text: "" });
        break;
    }
  }
  };

  const onStatusChange = async (statusId) => {
    setisvbStatusLoading(false);
    if(statusId === BrandStatus.PUBLISHED){
      await getVirtualBrandStatus(brand?.id, brand?.kitchenId)
     .then((resp)=>{
      vbStatus = resp?.data?.virtualBrandStatus;
      setisvbStatusLoading(false);
      setisvBStatus(vbStatus);
     })
     .catch(() => {
      toastify("error", "Error Unable To Fetch VB Staus!");
    });
    if(vbStatus !== BrandStatus.PENDING){
    setstatusVBModal(true);
    }
    }
    if(statusId !== BrandStatus.PUBLISHED || vbStatus === BrandStatus.PENDING){
     props.loading(true);
    await changeBrandStatus(brandId, statusId, brand.kitchenId)
      .then((res) => {
        toastify("success", res.message);
        setStatus(statusId);
        setbrandStatus(statusId);
        props.loading(false);
        props.onReload();
      })
      .catch(() => {
        props.loading(false);
      });
    }
  };

  const onConfirmStatusChange = async () => {
    props.loading(true);
    await changeBrandStatus(brandId, 0, brand.kitchenId)
      .then((res) => {
        setStatus(0);
        setbrandStatus(0);
        props.loading(false);
      })
      .catch(() => {
        props.loading(false);
      });
    };

    const onRefresh = () => {
      props.onReload();
    };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const openremoveSharedVBModal = () => setremoveSharedVB(true);
  const closeremoveSharedVBModal = () =>  setremoveSharedVB(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    if(isSharedBrand && userRole === RoleType.KITCHEN_OWNER){
      let model = {
        brandId: brandId,
        kitchenAssociationId: associationId,
        kitchenId: brand?.kitchenId,
        vbName: brand?.name,
        kitchenName: brand?.kitchenName
      };
      await updateSharedVBRemovalStatus(model)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
    }
    else{
    await deleteVirtualBrand(brandId, associationId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
    }
  };

  const onAddVb = async () => {
    if (isKitchenAlreadyAssociated) return;
    setisAdding(true);
    props.loading(true);
    await addVbToKitchen(brand?.id, userInfo?.kitchenId)
      .then(() => {
        setisAdding(false);
        props.loading(false);
        props.history.push(`/brand/${brand?.id}/shared/${userInfo?.kitchenId}?step=4`);
      })
      .catch(() => {
        setisAdding(false);
        props.loading(false);
      });
  };

  const onClickableAreaLink = () => {
    return onViewLink();
  };

  const onViewLink = (step = 4) => {
    brandCtx.resetAllVariables();
    let link = "";
    if (isSharedBrand) {
      link = `/brand/${brand?.id}/shared/${brand?.kitchenId}?step=${step}#shared-view`;
    } else {
      link = `/brand/${brand?.id}?step=${step}#view`;
    }
    return link;
  };

  const onViewRecipeGuides = () => {
    //recipeguideCtx.resetAllVariables();
    //recipeguideCtx.saveVirtualBrandInfo(brand);
    const link = `/recipeguide/list/${brand?.id}`;
    return link;
  };

  const onEditLink = () => {
    brandCtx.resetAllVariables();
    let link = "";
      if (isSharedBrand) {
        link = `/brand/${brand?.id}/shared/${brand?.kitchenId}?step=4`;
      } else {
        link = `/brand/${brand?.id}?step=4`;
      }
      if (isSharedBrand) {
        link = link + "#shared-edit";
        return link;
      }
      if (brand?.createdBy === userInfo?.userId) {
        link = link + "#edit";
        return link;
      }
      return link;
  };

  const onCancel = () =>{
    seteditVBModal(false);
    setstatusVBModal(false);
  }
  const checkVBStatus = (item) =>{
    switch (item) {
      case BrandStatus.DRAFT:
        return "Draft";
      case BrandStatus.PENDING:
        return "Pending";
      case BrandStatus.PUBLISHED:
        return "Published";
      case BrandStatus.UNPUBLISHED:
        return "UnPublished";
      default:
        return brand?.statusId;
    }
  }

  return (
    <>
      <div className="brandcard">
        <div className="wrapper">
          <Link to={onClickableAreaLink()}>
            <div className="img__box position-relative">
              <ImageSrc
                className="img-fluid"
                orgImage={brand?.logoURL}
                rawImage={brand?.rawLogoURL}
                isThumbnailRequired={true}
                thumbImage={brandImage}
              />
              <span className="admin-logo">
                {brand?.recipeguideCount > 0 &&
                  <img
                    src={recipeguideLogo}
                    alt="recipe guide(s)"
                    title="Has Recipe Guide(s)"
                    className="img-fluid"
                  />}
                {[RoleTypeId.ADMIN, RoleTypeId.ADMIN_STAFF, RoleTypeId.BRAND_OWNER].includes(
                  brand?.creatorRole
                ) && (
                    <img
                      src={brandLogo}
                      alt="admin"
                      title="Created By Admin"
                      className="img-fluid"
                    />
                  )}
              </span>
              </div>
          </Link>

          <div className="brand__info">
            <div className="brand__info__header">
              <Link to={onClickableAreaLink()}>
                <span>ID: {brand?.id}</span>
              </Link>
              <div className="right_content d-flex justify-content-between align-items-center">
                <div className={`custom__badge ${currentStatus.class}`}>
                  {/* isLoading ? <LoaderInline /> : */ (isSharable || (isAvailable && !isAssociated)) && currentStatus.text === "Published" ? "Available" : currentStatus.text}
                </div>
                <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="dropdown-button-drop-left"
                  ></button>
                  <div className="dropdown-menu">
                    {/* {!isSharable ? ( */}
                    <>
                      {(isSharedBrand ||
                        [RoleTypeId.KITCHEN_OWNER].includes(
                          brand?.creatorRole
                        ) ||
                        brand?.createdBy === userInfo?.userId) && !brand?.requestforRemoval && !isNotBrandOwner
                      && brandStatus !== BrandStatus.DRAFT ? (
                        <div className="dropdown__innerWrapper">
                          <button
                            type="button"
                            onClick={() => onStatusChange(BrandStatus.DRAFT)}
                            className="dropdown-item"
                          >
                            <span>Draft</span>
                          </button>
                        </div>
                      ) : null}

                      {(isSharedBrand ||
                        [RoleTypeId.KITCHEN_OWNER].includes(
                          brand?.creatorRole
                        ) ||
                        brand?.createdBy === userInfo?.userId) && !brand?.requestforRemoval && !isNotBrandOwner
                      && brandStatus !== BrandStatus.PENDING ? (
                        <div className="dropdown__innerWrapper">
                          <button
                            type="button"
                            onClick={() => onStatusChange(BrandStatus.PENDING)}
                            className="dropdown-item"
                          >
                            <span>Submit for review</span>
                          </button>
                        </div>
                      ) : null}

                      {/* {brandStatus !== BrandStatus.PENDING && (
                          <div className="dropdown__innerWrapper">
                            <button
                              type="button"
                              onClick={() =>
                                onStatusChange(BrandStatus.PENDING)
                              }
                              className="dropdown-item"
                            >
                              <span>Submit for review</span>
                            </button>
                          </div>
                        )} */}
                      {!brand?.requestforRemoval && (userRole === RoleType.ADMIN ||
                      userRole === RoleType.ADMIN_STAFF) ? (
                        <>
                          {brandStatus === BrandStatus.PUBLISHED ? (
                            <div className="dropdown__innerWrapper">
                              <button
                                type="button"
                                onClick={() =>
                                  onStatusChange(BrandStatus.UNPUBLISHED)
                                }
                                className="dropdown-item"
                              >
                                <span>Unpublish</span>
                              </button>
                            </div>
                          ) : (
                            <div className="dropdown__innerWrapper">
                              <button
                                type="button"
                                onClick={() =>
                                  onStatusChange(BrandStatus.PUBLISHED)
                                }
                                className="dropdown-item"
                              >
                                <span>Publish</span>
                              </button>
                            </div>
                          )}
                        </>
                      ) : null}
                    </>
                    {/* ) : null} */}

                    {!brand?.requestforRemoval && !isNotBrandOwner && (brand?.createdBy === userInfo?.userId ||
                    isAssociated ||
                    userRole === RoleType.ADMIN ||
                    userRole === RoleType.ADMIN_STAFF) ? (
                        <div className="dropdown__innerWrapper">
                          <Link to={([RoleType.ADMIN, RoleType.ADMIN_STAFF, RoleType.BRAND_OWNER].includes(userInfo?.role) || (userInfo?.role === RoleType.KITCHEN_OWNER && brand?.statusId !== BrandStatus.PENDING)) && (onEditLink())}
                            onClick={() => (brand?.statusId == BrandStatus.PENDING && userInfo?.role === RoleType.KITCHEN_OWNER) && (seteditVBModal(true))} className="dropdown-item">
                            <span>Edit</span>
                          </Link>
                        </div>
                    ) : null}

                    <div className="dropdown__innerWrapper">
                      <Link to={onViewLink()} className="dropdown-item">
                        <span>View Details</span>
                      </Link>
                    </div>

                    <div className="dropdown__innerWrapper">
                      <Link to={onViewRecipeGuides()}
                            className="dropdown-item">
                        <span>View Recipe Guides</span>
                      </Link>
                    </div>

                    {(isSharable && !isKitchenAlreadyAssociated) && (<div className="dropdown__innerWrapper">
                      <button
                        type="button"
                        onClick={() =>
                          onAddVb()
                        }
                        className="dropdown-item"
                      >
                        <span>Add to Kitchen</span>
                      </button>
                    </div>)}

                    {!isNotBrandOwner && (brand?.createdBy === userInfo?.userId || isAssociated) ? (
                      <div className="dropdown__innerWrapper">
                        <button
                          onClick={(isSharedBrand && userRole === RoleType.KITCHEN_OWNER && !brand?.requestforRemoval) ? openremoveSharedVBModal : 
                            (!isSharedBrand || (brand?.requestforRemoval && (userRole === RoleType.ADMIN || userRole === RoleType.ADMIN_STAFF))) ? openDeleteModal : null}
                          className="dropdown-item"
                        >
                          <span>{(isSharedBrand && userRole === RoleType.KITCHEN_OWNER && !brand?.requestforRemoval) ? "Request to Remove" : 
                          brand?.requestforRemoval && (userRole === RoleType.ADMIN || userRole === RoleType.ADMIN_STAFF) ? "Accept & Remove" : !isSharedBrand ? "Remove" : null}</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <Link to={onClickableAreaLink()}>
              <div className="brand__name">
                <h3>{brand?.name}</h3>
                {brand?.kitchenName && brand?.kitchenName !== "" ? (
                  <h6>
                    <small> {brand?.kitchenName}</small>
                  </h6>
                ) : null}
              </div>
              <div className="brand__info__footer">
                <span className="lastupdate">
                  Last Update on{" "}
                  {brand?.modifiedOn
                    ? dateFormat(
                        new Date(brand?.modifiedOn + "Z"),
                        'mmm dd, yyyy "at" hh:MM TT'
                      )
                    : "NA"}
                </span>
              </div>
            </Link>

            {isSharable && (
              <>
                <div className="sharedBrand__card">
                  <div className="addThisVcard">
                    <button
                      className={`create-banner-btn ${
                        isKitchenAlreadyAssociated ? "added-btn" : ""
                      }`}
                      onClick={onAddVb}
                      disabled={isAdding || isKitchenAlreadyAssociated}
                    >
                      {isAdding ? (
                        <LoaderInline />
                      ) : (
                        <>
                          {!isKitchenAlreadyAssociated ? (
                            <span className="icons">
                              <img src={plusIcon} alt="plus icon" />
                            </span>
                          ) : null}
                          {!isKitchenAlreadyAssociated ? "Add" : "Added"} This
                          Virtual Brand
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {(deleteModal || removeSharedVB) && (
        <DeleteModal
          show={deleteModal || removeSharedVB}
          title= {deleteModal ? "Remove Virtual Brand" : "Request to Remove Virtual Brand"}
          subtitle="Are you sure you want to remove this Virtual Brand? All menus and categories will be removed."
          onCancel={deleteModal ? closeDeleteModal : closeremoveSharedVBModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
          buttonText= {deleteModal ? "Yes, Remove" : "Yes, Request to Remove"}
        />
      )}

      {editVBModal && (
        <PopUpModal
          show={editVBModal}
          title="Edit Virtual Brand"
          subtitle="Editing a submitted brand is locked. Do you want to cancel the submission and resume editing this brand?"
          onCancel={onCancel}
          onSubmit={() => onConfirmStatusChange()}
          onLink={onEditLink}
          onLoading={deleteLoading}
          dialog={"editVB"}
        />
      )}
        {statusVBModal && (
        <PopUpModal
          show={statusVBModal}
          title="Check VirtualBrand Status"
          subtitle={`The status of VB is ${checkVBStatus(vBStatus)}. Please click on Continue to Refresh`}
          onCancel={onCancel}
          onSubmit={onRefresh}
          onLoading={isvbStatusLoading}
        />
      )}
    </>
  );
};

BrandCard.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    chowlyStoreId: PropTypes.string,
    logoURL: PropTypes.string,
    coverURL: PropTypes.string,
    description: PropTypes.string,
    tagline: PropTypes.string,
    createdBy: PropTypes.string,
    statusId: PropTypes.number,
    isActive: PropTypes.bool,
    preparationTime: PropTypes.number,
    createdOn: PropTypes.string,
    modifiedOn: PropTypes.string,
    deliveryRadius: PropTypes.number,
    kitchenId: PropTypes.number,
    kitchenName: PropTypes.string,
    kitchenVBAssociationId: PropTypes.number,
    orderFormat: PropTypes.number,
    creatorRoleId: PropTypes.number,
    creatorRole: PropTypes.number,
    isAssociated: PropTypes.bool,
  }),
  isSharable: PropTypes.bool,
  onReload: PropTypes.func,
};

export default withRouter(BrandCard);
