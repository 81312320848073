import React, { useState, useContext } from "react";
import dateFormat from "dateformat";
import { ServiceType } from "constants/serviceType";
import { useEffect } from "react";
import doordashImg from "assets/images/Doordash1.svg";
import grubhubImg from "assets/images/Grubhub1.svg";
import ubereatsImg from "assets/images/Ubereats1.svg";
import postmatesImg from "assets/images/Postman1.svg";
import fooddudesImg from "assets/images/Fooddudes1.svg";
import BHBImg from "assets/images/BB.png";
import { numberFormat } from "constants/numberFormat";
import DotsLoader from "./DotsLoader";
import Timer from "../view/Timer";
import {
  OrderStatusLabel,
  DriverStatus,
  screenNames,
  OrderStatus,
  orderStatusDropdown,
  OrderDetailsStrings
} from "constants/orderStatus";
import OrderDetailsModal from "../view/OrderDetailsModal";
import PrepTimeModal from "../view/PrepTimeModal";
import toastify from "shared/toastr";
import {
  changeOrderStatus,
  updatePrepTime,
} from "services/api/order";
import { useMediaQuery } from "react-responsive";
import OrdersNotificationContext from "store/orders-notification-context";

const ListingRow = (props) => {
  const data = props.order;
  const latestStatus = props.updatedStatus;
  const latestDriverStatus = props.updatedDriverStatus;
  const itemNames = data.orderItems.map((res) => res.title).join(", ");

  const [status, updateStatus] = useState(null);
  const [pickUpEta, setPickUpEta] = useState(null);
  const [dropOffEta, setDropOffEta] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [showPrepTimeModal, setshowPrepTimeModal] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [statusName, setStatusName] = useState("");
  const kitchen = data?.kitchen;
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);

    const isMobile = useMediaQuery({
      query: "(max-width: 767px)",
    });

  useEffect(() => {
    getStatus(data?.orderStatusId);
    setPickUpEta(data?.pickupEta);
    setDropOffEta(data?.dropOffEta);
    orderStatusDropdownUpdate();
  }, [data]);

  useEffect(() => {
    if (latestStatus) {
      getStatus(latestStatus?.statusId);
      orderStatusDropdownUpdate();
    }
  }, [latestStatus]);

  useEffect(() => {
    if (latestDriverStatus) {
      setPickUpEta(latestDriverStatus?.pickUpEta);
      setDropOffEta(latestDriverStatus?.dropOffEta);
    }
  }, [latestDriverStatus]);

  const getStatus = (statusId) => {
    updateStatus(OrderStatusLabel.find((it) => it.value === statusId));
  };

  const checkServiceType = (item) => {
    switch (true) {
      case ServiceType.DOORDASH.includes(item):
        return doordashImg;
      case ServiceType.GRUBHUB.includes(item):
        return grubhubImg;
      case ServiceType.UBEREATS.includes(item):
        return ubereatsImg;
      case ServiceType.POSTMATES.includes(item):
        return postmatesImg;
      case ServiceType.FOODDUDES.includes(item):
        return fooddudesImg;
      case ServiceType.GARCON.includes(item):
        return BHBImg;
      default:
        return BHBImg;
    }
  };

  const getDriverStatus = (data) => {
    const {
      deliveryJobRequired,
      deliveryStatusId,
      //pickupEta,
      //dropOffEta,
      expectedAt,
      //orderStatusId,
    } = data || {};
    const latestOrderStatusId = status?.value;
    const pickUpDriverEta = deliveryJobRequired ? pickUpEta : expectedAt;
    const dropOffDriverEta = deliveryJobRequired ? dropOffEta : expectedAt;
    const deliveryFailed = [DriverStatus.DELIVERYFAILED, DriverStatus.DELIVERYJOBCREATIONFAILED].includes(deliveryStatusId)
    if (
      latestOrderStatusId === OrderStatus.PREPARING ||
      latestOrderStatusId === OrderStatus.READY
    ) {
      return pickUpDriverEta ? (
        <div className="driver-status-timer">
          <Timer
            time={data?.modifiedAt}
            pickUpTime={pickUpDriverEta}
            screens={screenNames.driver}
            timeOffset={data?.timeOffset}
          />
        </div>
      ) : deliveryFailed ? (
        <div>--:--:--</div>
      ) : (
        <div className="pb-2">
        <DotsLoader />
        </div>
      );
    }
    if (latestOrderStatusId === OrderStatus.COMPLETED && dropOffDriverEta) {
      return (
          <div>
            {dateFormat(new Date(dropOffDriverEta), "hh:MM TT")}
          </div>
      );
    }
    return (
        <div>--:--:--</div>
    );
  };

  const onOrderDetails = () => {
    setIsOpen(true);
    window.history.replaceState(null, "", `/order/${data?.id}`);
    OrdersNotificationCtx.saveDrawerState({ isOpen: true, orderId: data?.id });
  };

  const closeModal = () => {
    setIsOpen(false);
    window.history.replaceState(null, "", `/orders`);
    OrdersNotificationCtx.saveDrawerState({ isOpen: false, orderId: null });
  }

  const orderStatusDropdownUpdate = () => {
    const details = orderStatusDropdown.filter(
      (item) =>
        item?.orderStatusId === (latestStatus?.statusId || data?.orderStatusId)
    );
    if (details && details?.length) {
      const detailObj = details[0];
      setStatusName(detailObj.statusName);
      const dropDownItems = detailObj.items?.map((item) => ({
        label: item.statusDisplayText,
        value: item.id,
        disabled: item?.disabled,
      }));
      setOrderStatuses(dropDownItems);
    }
  };

  const onCheckOrderStatus = (value) => {
     setSelectedOrderStatus(value);
    if (value && data?.id && kitchen.id) {
      if (statusName === OrderDetailsStrings.pending) {
        if (value === 2) {
          setshowPrepTimeModal(true);
        }
      } else {
         onChangeOrderStatus(
          value,
          data?.id,
          kitchen.id
        ); 
      }
    }
  };

  const onCancel = () => setshowPrepTimeModal(false);

  const onConfirmStatusChange = (time) => {
    updatePreparationTime(
      time,
      selectedOrderStatus,
      data?.id,
      kitchen.id
    );
  };

  const onChangeOrderStatus = async (statusId, orderId, kitchenId) => {
    await changeOrderStatus(statusId, orderId, kitchenId)
      .then((res) => {
        updateStatus(OrderStatusLabel.find((it) => it.value === statusId));
        toastify("success", res.message);
      })
      .catch(() => {
      });
  };

  const updatePreparationTime = async (time, statusId, orderId, kitchenId) => {
    await updatePrepTime(time, orderId, kitchenId)
      .then((res) => {
        onChangeOrderStatus(statusId, orderId, kitchenId);
        onCancel();
        toastify("success", res.message);
      })
      .catch(() => {
      });
  };

  return (
    <>
      <tr role="row" className={`mat-row ${status?.colourClass}`}>
        {isMobile && (
          <div className="flex-container-action col-12 pl-0 no-gutters">
            <td
              className="mat-cell column-1 col-10"
              data-column="CUSTOMER"
              onClick={onOrderDetails}
            >
              {/* <Link
            to={"/order/" + data?.id}
            className="modifierDeatil__link d-inline-block w-100"
          > */}
              <div className="customer-data mt-1 mt-lg-0">
                <div className="delivery__service__icon img-box">
                  <img
                    src={checkServiceType(
                      data?.deliveryService?.name.toLowerCase()
                    )}
                    alt="icon"
                    //className="img-fluid"
                  />
                </div>
                <div className="content-box">
                  <p className="order-no">#{data?.id}</p>
                  <p className="customer-name">
                    {props?.screen === "customer"
                      ? data?.deliveryService?.name
                      : data?.customer?.name}
                  </p>
                </div>
              </div>
              {/*  </Link> */}
            </td>
            <td className="d-block d-md-none mat-cell col-2">
              <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="dropdown-button-drop-left"
                ></button>
                <div className="dropdown-menu">
                  {orderStatuses && orderStatuses.length ? (
                    <div className="dropdown__innerWrapper">
                      {orderStatuses.map((odrSts, idx) => (
                        <button
                          onClick={() => onCheckOrderStatus(odrSts.value)}
                          className="dropdown-item"
                        >
                          <span>{odrSts.label}</span>
                        </button>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </td>
          </div>
        )}
        <td
          className="d-none d-md-block mat-cell column-1"
          data-column="CUSTOMER"
          onClick={onOrderDetails}
        >
          <div className="customer-data mt-1 mt-lg-0">
            <div className="delivery__service__icon img-box">
              <img
                src={checkServiceType(
                  data?.deliveryService?.name.toLowerCase()
                )}
                alt="icon"
                //className="img-fluid"
              />
            </div>
            <div className="content-box">
              <p className="order-no">#{data?.id}</p>
              <p className="customer-name">
                {props?.screen === "customer"
                  ? data?.deliveryService?.name
                  : data?.customer?.name}
              </p>
            </div>
          </div>
        </td>
        <td
          className="mat-cell column-2"
          data-column="ORDER"
          onClick={onOrderDetails}
        >
          <div className="row no-gutters">
            <div className="col-12">
              <p className="kitchen-name item-names">{data?.virtualBrand?.name} {data?.isSharedVB && `- ${data.kitchen.name}`}</p>
              <p className="item-name item-names">{itemNames}</p>
            </div>
          </div>
        </td>
        <td
          className="mat-cell column-3"
          data-column="DATE"
          onClick={onOrderDetails}
        >
          <div className="row no-gutters">
            {data?.orderDate ? (
              <div className="col-12">
                <p className="order-time">
                  {dateFormat(new Date(data?.orderDate), "hh:MM TT")}
                </p>
                <p className="order-date">
                  {dateFormat(new Date(data?.orderDate), "mmm dd, yyyy")}
                </p>
              </div>
            ) : (
              "NA"
            )}
          </div>
        </td>
        <td
          className="mat-cell column-4 pl-0"
          data-column="STATUS"
          onClick={onOrderDetails}
        >
          <div className="table__actions">
            <div className={isMobile ? "" : "center__content flex__start"}>
              <div className="row no-gutters">
                <div className="col-12">
                  <p
                    className={`order-status label__background ${status?.labelColor}`}
                  >
                    {status?.label}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td
          className="mat-cell column-5"
          data-column="DRIVER"
          onClick={onOrderDetails}
        >
          <div className="table__actions">
            <div className="right_content">
              <div className="row no-gutters">
                <div className="col-12">
                  {getDriverStatus(data)}
                  <span className="driver-status-text">Est. Arrival</span>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td
          className="mat-cell column-6"
          data-column="TOTAL"
          onClick={onOrderDetails}
        >
          <div className="table__actions">
            <div className="right_content">
              <div className="row no-gutters">
                <div className="col-12">
                  <p className="order-status">
                    {numberFormat(
                      data?.orderTransaction.subTotal +
                        data?.orderTransaction.waiveOffAmount
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td
          className="d-none d-md-block mat-cell column-7"
          data-column="ACTION"
        >
          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdown-button-drop-left"
            ></button>
            <div className="dropdown-menu">
              {orderStatuses && orderStatuses.length ? (
                <div className="dropdown__innerWrapper">
                  {orderStatuses.map((odrSts, idx) => (
                    <button
                      onClick={() => onCheckOrderStatus(odrSts.value)}
                      className="dropdown-item"
                    >
                      <span>{odrSts.label}</span>
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </td>
      </tr>
      {isOpen && <OrderDetailsModal
        show={isOpen}
        className="right"
        onHide={closeModal}
        data={data}
      />}
      {showPrepTimeModal && (
        <PrepTimeModal
          show={showPrepTimeModal}
          onCancel={onCancel}
          data={data}
          onConfirm={onConfirmStatusChange}
        />
      )}
    </>
  );
};

export default ListingRow;
