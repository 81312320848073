import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import dateFormat from "dateformat";

import "./ManageResturantsDetails.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import ChangeStatus from "../change-status/ChangeStatus";
import LoaderBlock from "shared/loader/LoaderBlock";
import { getStaff } from "services/api/staff";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { useContext } from "react";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";

const StaffView = (props) => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      getDetail(userId);
    }
  }, [userId]);

  const getDetail = async (id) => {
    setisLoading(true);
    await getStaff(id)
      .then((res) => {
        setUser(res.data);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.goBack();
  };

  const checkDropdownNeeded = () => {
    if (userRole === RoleType.ADMIN_STAFF) {
      if (user?.roleName === RoleType.ADMIN) {
        return false;
      } else return true;
    } else return true;
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>

      <div className="modifierDetails__wrapper restaurantDetails__wrapper user__details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button
                  type="button"
                  onClick={onBack}
                  className=" back-btn-red"
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Manage User - Details</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                {isLoading ? (
                  <LoaderBlock />
                ) : (
                  <div className="details__wrapper__inner">
                    <div className="left_content">
                      <div className="row">
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Full Name</h5>
                            <p>{user?.name || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>User Type</h5>
                            <p>{user?.roleName || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Contact No</h5>
                            <p>{user?.contact || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Restaurant Name</h5>
                            <p>{user?.kitchenName || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Account Email</h5>
                            <p>{user?.email || "NA"}</p>
                          </div>
                        </div>
                         <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Creation Date</h5>
                            <p>
                              {user?.createdOn
                                ? dateFormat(
                                    new Date(user?.createdOn + "Z"),
                                    "mmm dd, yyyy"
                                  )
                                : "NA"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Address Line 1</h5>
                            <p>{user?.addressOne || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Address Line 2</h5>
                            <p>{user?.addressTwo || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>City</h5>
                            <p>{user?.city || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>State</h5>
                            <p>{user?.stateName || "NA"}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-0">
                          <div className="detailCard">
                            <h5>Zip</h5>
                            <p>{user?.zip || "NA"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {user && (
                      <ChangeStatus
                        userId={user?.userId}
                        status={!user?.lockoutEnabled}
                        needDropdown={checkDropdownNeeded()}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffView;
