import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../logo.svg";
import "./Footer.scss";

function Footer() {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="footer">
      {isMobile ? (
        <div className="container">
          <div className="footer-wrapper">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-6">
                <div className="footer-left">
                  <div className="footer-logo">
                    <img src={logo} alt="footer logo" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/privacy-policy" target="_blank">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use" target="_blank">
                        Terms of Use
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" target="_blank">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-3">
                <div className="footer-left">
                  <div className="copyright">
                    <p>©{new Date().getFullYear()}. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="footer-wrapper">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-6">
                <div className="footer-left">
                  <div className="footer-logo">
                    <img src={logo} alt="footer logo" className="img-fluid" />
                  </div>
                  <div className="copyright">
                    <p>©{new Date().getFullYear()}. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-6 px-0 px-lg-3">
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/privacy-policy" target="_blank">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use" target="_blank">
                        Terms of Use
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" target="_blank">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
