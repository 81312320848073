import React, { useContext, useEffect, useState } from "react";
/* import "./RecipeGuideInfo.scss"; */
import "../../../pages/create-brand-form/menu-form/banner-section/VirtualBrandInfo.scss"
import brandLogoImage from "assets/images/placeholder-logo.png";
import LoaderBlock from "shared/loader/LoaderBlock";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import EditButton from "UI/Button/EditButton";
import { GetRecipeGuideBasicInfo, getAssetsByRecipeGuideId, getRecipeGuideResources } from "services/api/recipeguide";
import RecipeGuideContext from "store/recipe-guide-context";
import RecipeGuideModal from "../recipeguide-modal/RecipeGuideModal";
import { useParams } from "react-router";
import attachmentsIcon from "assets/images/attachments-icon.svg";
import AttachmentsModal from "../recipeguide-modal/AttachmentsModal";

const RecipeGuideInfo = () => {
  const [recipeGuideInfo, setrecipeGuideInfo] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const recipeguideCtx = useContext(RecipeGuideContext);
  const editable = recipeguideCtx.isConfirmPage;
  const modalStatus = recipeguideCtx.recipeGuideModalStatus;
  const { recipeGuideId } = useParams("recipeGuideId");
  const { brandId } = useParams("brandId");
  const menuItemId = recipeguideCtx.virtualBrandMenuItemId;
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;
  const attachmentsmodalStatus = recipeguideCtx.attachmentsModalStatus;
  const [attachmentsCount, setattachmentsCount] = useState(null);
  const [attachments, setattachments] = useState();

  useEffect(() => {
    if (recipeGuideId || menuItemId) getRecipeGuideBasicInfo();
    if (recipeGuideId) getRecipeGuideResources();
  }, [recipeGuideId]);

  useEffect(() => {
    if (modalStatus === false) getRecipeGuideBasicInfo();
    if (attachmentsmodalStatus === false) getRecipeGuideResources();
  }, [modalStatus, attachmentsmodalStatus]);

  const getRecipeGuideBasicInfo = async () => {
    setisLoading(true);
    await GetRecipeGuideBasicInfo(brandId, 0, recipeGuideId)
      .then((res) => {
        setrecipeGuideInfo(res.data);
        recipeguideCtx.saveVirtualBrandInfo({ id: brandId, name: res.data?.virtualBrandName });
        recipeguideCtx.saveMenuItemId(menuItemId);
        recipeguideCtx.setRecipeGuideInfo(res.data);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const getRecipeGuideResources = async () => {
    setisLoading(true);
    await getAssetsByRecipeGuideId(recipeGuideId).then((res) => {
      setisLoading(false);
      let data = res.data?.recipeGuideResources;
      if (data) {
        setattachmentsCount(data?.length);
        setattachments(data);
      }
    })
      .catch(() => {
        setisLoading(false);
      });
  };

  const onView = () => {
    recipeguideCtx.recipeGuideModalStatusChange(true, {
      isEditMode: false,
      isViewMode: true,
      data: recipeGuideInfo,
    });
  };

  const onEdit = () => {
    recipeguideCtx.recipeGuideModalStatusChange(true, {
      isEditMode: true,
      isViewMode: false,
      data: recipeGuideInfo,
    });
  };

  const closeModal = () => {
    recipeguideCtx.recipeGuideModalStatusChange(false);
  };

  const openAttachmentsModal = () => {
    recipeguideCtx.attachmentsModalStatusChange(true,{
      data: attachments,
    });
  };

  const closeAttachmentsModal = () => {
    recipeguideCtx.attachmentsModalStatusChange(false);
  };

  return (
    <div className="createMenuBanner__wrapper">
      <div
        className="bg"
        style={{
          backgroundImage: `url(${recipeGuideInfo?.virtualBrandCoverUrl
            || "assets/images/placeholder-cover.png"
            })`,
        }}       
      >
        {![VirtualBrandMode.ADD].includes(recipeGuideMode) && <button type="button" className="attachments-btn" onClick={openAttachmentsModal}>
          <img src={attachmentsIcon} alt="attachments" />{attachmentsCount} Attachments
        </button>}
        <div className="content">
          <div className="logo">
            <div
              className="logo-wrapper"
              style={{
                backgroundImage: `url(${isLoading
                  ? null
                  :
                  recipeGuideInfo?.imageUrl || brandLogoImage
                  })`,
              }}
            >
              {isLoading ? <LoaderBlock isTextNeeded={false} /> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="brand__details">
        <div className="left__side">
          <div className="brand__name">
            <h5 className="text-uppercase">Recipe Guide Name</h5>
            <p>{recipeGuideInfo?.title || "N/A"}</p>
          </div>
          <div className="brand__desc">
            <h5 className="d-flex text-uppercase">Recipe Guide Description
              {editable ? (
                recipeGuideMode === VirtualBrandMode.SHARED ||
                  recipeGuideMode === VirtualBrandMode.VIEW ? (
                  <ViewButton onClick={() => onView()} />
                ) : (
                  <EditButton onClick={() => onEdit()} />
                )
              ) : null}
            </h5>
            <p>{recipeGuideInfo?.description || "N/A"}</p>
            <RecipeGuideModal
              show={modalStatus}
              className="right"
              onHide={closeModal}
            />
             <AttachmentsModal
              show={attachmentsmodalStatus}
              className="right"
              onHide={closeAttachmentsModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeGuideInfo;
