import React, { useContext } from "react";
/* import RecipeCategoryForm from "../recipeguide-form/RecipeCategoryForm"; */
import { Modal } from "react-bootstrap";
import RecipeGuideContext from "store/recipe-guide-context";
import PrepStepSlideShow from "../view/PrepStepSlideShow";

const PrepStepSlideShowModal = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const modalExtras = recipeguideCtx.modalExtras;

  return (
    <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
      <Modal.Header closeButton>
       {/*  <Modal.Title id="addMenu__item__modal">
          <h1>
            {modalExtras && modalExtras?.editMode && "Edit"}
            {modalExtras && modalExtras?.viewMode && "View"}
            {!modalExtras?.viewMode && !modalExtras?.editMode
              ? "Add"
              : null}{" "}
            Prep Step Slide Show
          </h1>
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <PrepStepSlideShow/>
      </Modal.Body>
    </Modal>
  );
};

export default PrepStepSlideShowModal;
