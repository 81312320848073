import React from "react";
import "./OrderView.scss";
import moment from 'moment';
import { stepIndicatorTitles } from "constants/orderStatus";

const StepsIndicator = (props) => {
    const data = props?.data;
  const steps = props?.steps;

    const getServiceOrRestaurantName = (label) => {
      if (label?.toLowerCase() === "new") {
        return data?.deliveryService?.name.toUpperCase();
      }
      return data?.kitchen?.name;
    };

    const getStatusImageclass = (pos) => {
      let imgSrc = "";
      if (pos === 0) {
        imgSrc = "current";
      }
      else
        imgSrc = "is-done";
      return imgSrc;
    }
    
    const getStatusLabel =  (statusId) => {
      const statusLabel = stepIndicatorTitles.filter(obj => obj.orderStatusId === statusId)
      if (statusLabel.length > 0) {
        return statusLabel[0]?.status;
      }
      return "";
    }

  const acknowledgeTime = (val) => {
    const time = moment(val)
      //.add(orderStatusId === 2 ? data?.preparationTime : 0, "minutes")
      .format(moment().day() === moment(val).day() ? "hh:mm A" : "MM/DD ddd hh:mm A");
    return time;
     //.utcOffset("+11:00")
      /*  if (orderStatusId === 2) {
      date.setMinutes(date.getMinutes() + data?.preparationTime);
    } */
  };

  const getTime = (orderStatusId, modifiedAt) => {
        const obj = steps?.find((item) => {
          return item.orderStatusId === orderStatusId && item?.modifiedAt === modifiedAt;
        });
        if (obj === undefined) {
          return "NA";
        }
      const ackTime = acknowledgeTime(obj?.createdAt);
        return ackTime ? ackTime : "";
      };

    return (
        <>
            {steps?.map((ele, idx) => (
                <li className={`StepProgress-item ${getStatusImageclass(idx)}`} key={idx}>
                  <span>
                    <div className="title">{getStatusLabel(ele?.orderStatusId)}</div>
                    <div className="subTitle1">{getServiceOrRestaurantName(ele?.statusCode)}</div>
                    <div className="subTitle2">{getTime(ele?.orderStatusId, ele?.modifiedAt)}</div>
                    </span>
                </li>
            ))}
        </>
    );
}
export default StepsIndicator;