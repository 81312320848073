import React from "react";
import "./SelectModifiersOptions.scss";
import rightArrowIcon from "../../assets/images/white-right-arrow-btn.svg";
import plusIconRed from "../../assets/images/add-redIcon.svg";

const SelectModifiersOptions = () => {
  return (
    <div className="SelectModifiersOptions__wrapper">
      <a
        className="addModifiers"
        data-toggle="modal"
        href="#SelectModifiersOptions"
      >
        <span className="icons">
          <img src={plusIconRed} alt="plus icon" />
        </span>
        Add Modifer Options
      </a>
      <div
        className="modal fade centered"
        id="SelectModifiersOptions"
        tabIndex="-1"
        aria-labelledby="SelectModifiersOptionsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="modifiertype">
                Select Modifier Options{" "}
              </h2>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="SelectModifiersOptions__table">
                <div className="row">
                  <div className="col-12">
                    <table className="table modifierGroupTable">
                      <tbody>
                        <tr>
                          <td>
                            <div className="custom-checkbox-wrapper">
                              <label className="custom-checkbox-container">
                                Parmesian Cheese
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <span className="modifiers__type price">
                              $ 2.00
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="custom-checkbox-wrapper">
                              <label className="custom-checkbox-container">
                                Parmesian Cheese
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <span className="modifiers__type price">
                              $ 2.00
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="custom-checkbox-wrapper">
                              <label className="custom-checkbox-container">
                                Parmesian Cheese
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <span className="modifiers__type price">
                              $ 2.00
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="modal-footer">
                <button className="cancel-btn red-btn-ghost btn">Cancel</button>
                <button className="red-btn next-btn btn">
                  Add Modifier Options
                  <span className="icons-right">
                    <img src={rightArrowIcon} alt="arrow icon" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectModifiersOptions;
