import React from "react";
import "./AddButton.scss";
import plusIcon from "../../assets/images/play-icon.svg";

function PrepStepSlideShowButton(props) {
  return (
    <div className="button__wrapper">
      <button type="button" className="bg-blue-btn" onClick={props.openModal}>
        <img src={plusIcon} alt="plus icon" style={{ position: "relative", right: "10px" }} />
        <span /* className="icons" */>
        </span>
        {props?.title}
      </button>
    </div>
  );
}

export default PrepStepSlideShowButton;
