import React from "react";
import { Link } from "react-router-dom";
import "./OrderDetails.scss";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import ubereats from "../../assets/images/ubereats.svg";

const OrderDetails = () => {
  return (
    <div className="order__details__wrapper">
      <div className="modifierDetails__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <Link to="/orders" className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Order Details</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="detailsCard__wrapper">
                <div className="row no-gutters">
                  <div className="col-12 col-md-7">
                    <div className="order-details-body-header">
                      <span>Status</span>
                      <span>Pending</span>
                    </div>
                    <div className="order-details-body-details border-right">
                      <div className="body-heading">
                        <h2>Brainerd Burger Pirate</h2>
                      </div>
                      <div className="order-detail-card">
                        <label>Pickup Time</label>
                        <p>ASAP</p>
                      </div>
                      <div className="order-detail-card">
                        <label>Order Type</label>
                        <p>Delivery</p>
                      </div>
                      <div className="order-detail-card">
                        <label>Delivery by</label>
                        <p>DoorDash</p>
                      </div>
                      <div className="order-detail-card">
                        <label>Customer Info</label>
                        <p>
                          No Customer information is available for this order
                        </p>
                      </div>
                      <div className="order-detail-card">
                        <label>Delivery Address</label>
                        <p>
                          123 Eagle lake Rd, Saint Cloud, MN 56063 (5th Floor,
                          Apt C)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="row no-gutters">
                      <div className="col-12">
                        <div className="logo__area">
                          <div className="img-box">
                            <img
                              src={ubereats}
                              alt="brand-logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content-box">
                            <h3>3 Items for karla Vaga</h3>
                            <span className="order-no">Order- #3244</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="items__price__breakups">
                          <div className="main__heading">
                            <label>Items</label>
                          </div>
                          <div className="item__block">
                            <div className="item">
                              <span>1x BBQ Cheese Curds</span>
                              <span>$8.99</span>
                            </div>
                            <div className="item__modifiers__wrapper">
                              <div>
                                <label>Modifiers</label>
                              </div>
                              <div className="item-modifiers">
                                <span>1x Cola-Small</span>
                                <span>$1.90</span>
                              </div>
                              <div className="item-modifiers">
                                <span>1x Dipping Cheese</span>
                                <span>$2.90</span>
                              </div>
                            </div>
                            <div className="item__notes">
                              <div>
                                <label>Notes</label>
                              </div>
                              <p>
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                              </p>
                            </div>
                          </div>
                          <div className="item__block">
                            <div className="item">
                              <span>1x BBQ Cheese Curds</span>
                              <span>$8.99</span>
                            </div>
                            <div className="item__notes">
                              <div>
                                <label>Notes</label>
                              </div>
                              <p>
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                              </p>
                            </div>
                          </div>
                          <div className="item__block">
                            <div className="item">
                              <span>1x BBQ Cheese Curds</span>
                              <span>$8.99</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
