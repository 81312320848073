import React from "react";
import "./AddnewmodifierModal.scss";
import plusIcon from "../../assets/images/add-icon-white.svg";
import rightArrowIcon from "../../assets/images/white-right-arrow-btn.svg";
import SelectModifiersOptions from "./SelectModifiersOptions";
import deleteIocn from "../../assets/images/delete-icon.svg";

const AddnewmodifierModal = () => {
  return (
    <div className="addNewModifier__wrapper">
      <a
        className="bg-blue-btn"
        data-toggle="modal"
        href="#AddnewmodifierModal"
      >
        <span className="icons">
          <img src={plusIcon} alt="plus icon" />
        </span>
        New Modifier
      </a>
      <div
        className="modal fade centered"
        id="AddnewmodifierModal"
        tabIndex="-1"
        aria-labelledby="AddnewmodifierModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="modifiertype">
                Add New Modifier
              </h2>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="basic__info__content addNewModifier__wrapper__inner">
                <form>
                  <div className="form-row no-gutters">
                    <div className="col-md-12 mb-3 pb-3">
                      <label htmlFor="modifiergroupname">
                        Modifier Group Name<span className="mendatory">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="modifiergroupname"
                        placeholder="Toppings"
                        value=""
                        required
                      />
                    </div>
                    <div className="col-md-12 mb-3 pb-3">
                      <label htmlFor="modifiertype">
                        Modifier Type<span className="mendatory">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="modifierType"
                        placeholder="Food Modifiers"
                        value=""
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="modifieroptions__wrap d-none d-md-flex">
                        <div className="modifieroptions">
                          <label htmlFor="modifieroptions">
                            Modifier Options<span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mb-1"
                            id="modifieroptions"
                            placeholder="Extra Beef"
                            value=""
                            required
                          />
                          <input
                            type="text"
                            className="form-control mb-1"
                            id="modifieroptions"
                            placeholder="Extra Chicken"
                            value=""
                            required
                          />
                          <input
                            type="text"
                            className="form-control mb-1"
                            id="modifieroptions"
                            placeholder="Tomatoes"
                            value=""
                            required
                          />
                        </div>
                        <div className="modifieroptions__prices">
                          <label htmlFor="modifierprice">Price</label>
                          <div className="delete_modifier_wrapper">
                            <input
                              type="text"
                              className="form-control mb-1"
                              id="price"
                              placeholder="5"
                              value=""
                              required
                            />
                            <button className="delete_modifier">
                              <span>
                                <img
                                  src={deleteIocn}
                                  className="icons img-fluid"
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>
                          <div className="delete_modifier_wrapper">
                            <input
                              type="text"
                              className="form-control mb-1"
                              id="price"
                              placeholder="5"
                              value=""
                              required
                            />
                            <button className="delete_modifier">
                              <span>
                                <img
                                  src={deleteIocn}
                                  className="icons img-fluid"
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>
                          <div className="delete_modifier_wrapper">
                            <input
                              type="text"
                              className="form-control mb-1"
                              id="price"
                              placeholder="5"
                              value=""
                              required
                            />
                            <button className="delete_modifier">
                              <span>
                                <img
                                  src={deleteIocn}
                                  className="icons img-fluid"
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="d-md-none modifieroptions__wrap-mobile">
                        <div className="row">
                          <div className="col-12 mb-3 pb-3">
                            <div className="modifieroptions">
                              <label htmlFor="modifieroptions">
                                Modifier Options
                                <span className="mendatory">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mb-1"
                                id="modifieroptions"
                                placeholder="Extra Beef"
                                value=""
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="modifieroptions__prices">
                              <label htmlFor="modifierprice">Price</label>
                              <input
                                type="text"
                                className="form-control mb-1"
                                id="price"
                                placeholder="5"
                                value=""
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addmodifiers__options">
                        <SelectModifiersOptions />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="modal-footer">
                        <button className="cancel-btn red-btn-ghost btn">
                          Cancel
                        </button>
                        <button className="red-btn next-btn btn">
                          Save and Continue
                          <span className="icons-right">
                            <img src={rightArrowIcon} alt="arrow icon" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddnewmodifierModal;
