import React, { useState, useEffect, useContext } from "react";
import { useFieldArray } from "react-hook-form";
import { Modal } from "react-bootstrap";
import plusIconRed from "assets/images/add-redIcon.svg";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import deleteIcon from "assets/images/delete-icon.svg";
import { getModifierOptionsById, changeStatusOfSharedModOption } from "services/api/menu";
import LoaderBlock from "shared/loader/LoaderBlock";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import VirtualBrandContext from "store/virtual-brand-context";
import toastify from "shared/toastr";

const ItemModifierOption = ({
  index,
  control,
  selectedGroup,
  maxOptionAllowedChange,
  disabled,
  onReload,
  errors,
  register
}) => {
  const isDisabled = disabled;
  const [optionsLoading, setoptionsLoading] = useState(false);
  const [modOptions, setmodOptions] = useState(null);
  const [showModOptions, setShowModOptions] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [optionsSelected, setoptionsSelected] = useState([0]);
  const brandCtx = useContext(VirtualBrandContext);
  const brandMode = brandCtx.virtualBrandMode;
  const brandId = brandCtx.virtualBrandId;
  const kitchenId = brandCtx.associationKitchenId;

  const [defaultMaxOptionSelectedValue, setdefaultMaxOptionSelectedValue] =
    useState(null);

  const [isAnyOptionSelected, setisAnyOptionSelected] = useState(false);

  const { fields, append, remove } = useFieldArray({
    name: `modifier.${index}.menuItemModOptions`,
    control,
    keyName: "keyId",
  });

  const menuItemId = brandCtx.virtualBrandMenuItemId;
  const modGroupId = selectedGroup?.menuItemModGroup?.id;

  useEffect(() => {
    if (fields && fields.length) {
      let container = [];
      for (let index = 0; index <= fields.length; index++) {
        container.push(index);
      }
      setoptionsSelected(container);
    }
  }, [fields]);

  useEffect(() => {
    if (modOptions && modOptions.length) {
      let checked = modOptions.filter((res) => res.isChecked);
      setisAnyOptionSelected(checked.length > 0);
      const isSelectAllChecked = checked?.length === modOptions?.length;
      setSelectAll(isSelectAllChecked);
    }
  }, [modOptions]);

  useEffect(() => {
    if (selectedGroup) {
      setdefaultMaxOptionSelectedValue(selectedGroup.maxOptionsAllowed);
    }
  }, [selectedGroup]);

  const onSelectChange = (event) => {
    let value = event.target.value;
    setdefaultMaxOptionSelectedValue(value);
    maxOptionAllowedChange({ value, index });
  };

  const handleClose = () => setShowModOptions(false);

  const handleShow = (groupTab) => {
    const { value } = groupTab;
    setShowModOptions(true);
    modOptionsList(value.menuItemModGroup.id);
  };

  const modOptionsList = async (modGroupId) => {
    setoptionsLoading(true);
    await getModifierOptionsById(modGroupId)
      .then((res) => {
        let data = res.data;
        if (data && data.length) {
          if (fields && fields.length) {
            let temp = data.map((res) => {
              if (fields.findIndex((val) => val.id === res.id) > -1)
                return { ...res, isChecked: true };
              else return { ...res, isChecked: false };
            });
            setmodOptions(temp);
          } else setmodOptions(data);
        } else setmodOptions([]);
        setoptionsLoading(false);
      })
      .catch(() => {
        setoptionsLoading(false);
      });
  };

  const addRemoveOptionsToArray = (option, ev) => {
    let isChecked = ev.target.checked;
    let tempArr = modOptions;
    let changedArray,
      count = 0;
    if (ev.target.value === "selectAll") {
      changedArray = tempArr.map((res) => {
        return { ...res, isChecked: isChecked };
      });
      isChecked ? setSelectAll(true) : setSelectAll(false);
    } else {
      changedArray = tempArr.map((res) => {
        if (res.id === option.id) return { ...res, isChecked: isChecked };
        else return { ...res };
      });
      setSelectAll(false);
    }
    changedArray.map((ele) => {
      if (ele.isChecked === true) return count++;
      else return 0;
    });
    if (count === tempArr?.length && selectAll === false) setSelectAll(true);
    setmodOptions(changedArray);
  };

  const addOptionsToModifier = () => {
    if (modOptions) {
      const checked = modOptions.filter((res) => res.isChecked);
      const data = new Set(fields.map((field) => field.id));
      const filtered = checked.filter((field) => !data.has(field.id));
      const fieldArr = filtered.map((checked) => {
        const fieldObj = fields.find((item) => item.id === checked.id);
        return {
          ...checked,
          priority: fieldObj?.priority || 100,
        };
      });
      append(fieldArr);
    }
    handleClose();
  };

  const onChangeModOptionStatus = async (option) => {
    const model = {
      kitchenId: kitchenId,
      brandId: brandId,
      menuItemId: menuItemId,
      menuItemModId: modGroupId,
      menuItemModOptionId: option?.id,
      status: !option?.isActive
    };
    await changeStatusOfSharedModOption(model).then((res) => {
      onReload();
      toastify("success", res?.message);
    });
  };

  return (
    <>
      <div className="col-md-12 mb-2">
        <div className="heading__wrapper">
          <h5>Modifier Options</h5>
          {isDisabled ? null : (
            <button
              type="button"
              className="addModifiers"
              onClick={() => handleShow({ value: selectedGroup, index: index })}
            >
              <span className="icons">
                <img src={plusIconRed} alt="plus icon" />
              </span>
              Add Modifier Options
            </button>
          )}
        </div>
      </div>
      {fields && fields?.length ? (
        <div class="col-md-12 d-flex">
          <h5 style={{ maxWidth: "200px", width: "100%" }}>Name</h5>
          <h5 style={{ maxWidth: "280px", width: "100%" }}>Price</h5>
          <h5 style={{ whiteSpace: "nowrap" }}>Sort Order</h5>
        </div>
      ): null}
      <div className="col-md-12">
        {fields && fields?.length ? (
          <div className="addedModifiers__list__wrap">
            <ul>
              {fields.map((option, idx) => (
                <li key={option.keyId} className="modifiersadded">
                  <p className="modifierName">{option.name}</p>
                  <p>${option.price?.toFixed(2)}</p>
                  <div className="flex_col_container ml__auto">
                    <div className="input_end_container">
                      <div className="input__container">
                        <input
                          key={option.id}
                          type="number"
                          placeholder="Priority"
                          id="priority"
                          disabled={isDisabled}
                          defaultValue={100}
                          {...register(
                            `modifier.${index}.menuItemModOptions.${idx}.priority`,
                            { valueAsNumber: true }
                          )}
                          className={`form-control ${
                            errors?.modifier?.[index]?.menuItemModOptions?.[idx]
                              ?.priority
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{
                            width: 75,
                            height: 32,
                          }}
                        />
                      </div>
                      {isDisabled ? null : (
                        <button
                          type="button"
                          className="delete_modifier"
                          onClick={() => remove(idx)}
                          style={{ marginLeft: "5px" }}
                        >
                          <span>
                            <img src={deleteIcon} className="" alt="delete" />
                          </span>
                        </button>
                      )}
                    </div>
                    {errors?.modifier?.[index]?.menuItemModOptions?.[idx]
                      ?.priority?.message && (
                      <div className="invalid-feedback d-block">
                        {
                          errors?.modifier?.[index]?.menuItemModOptions?.[idx]
                            ?.priority?.message
                        }
                      </div>
                    )}
                  </div>
                  {brandMode === VirtualBrandMode.SHARED && (
                    <div className="threedotsbtn btn-group dropleft">
                      <DropdownButton
                        key="left"
                        id="dropdown-button-drop-left"
                        drop="left"
                        variant="secondary"
                        className="leftDrowpdown"
                        style={{marginLeft: "25px"}}
                      >
                        <Dropdown.Item
                          onClick={() => onChangeModOptionStatus(option)}
                        >
                          <div className="dropdown__innerWrapper">
                            <span>
                              Set As {option?.isActive ? "Inactive" : "Active"}
                            </span>
                          </div>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="noSelected">
            <p>No Modifier Option Selected</p>
          </div>
        )}
      </div>
      <div className="col-md-12">
        {fields && fields?.length ? (
          <div className="sort_end_info">
            Sort Order from 1 to 1000, 1 top of list
          </div>
        ): null}
        <div className="optionsallowed">
          <label htmlFor="categoryDesc">
            Maximum Options Allowed for Selection:
          </label>
          <div className="actions-wrapper">
            <select
              className="form-control time-select"
              value={defaultMaxOptionSelectedValue}
              onChange={onSelectChange}
              disabled={isDisabled}
            >
              {optionsSelected &&
                optionsSelected.map((opt, idx) => (
                  <option key={idx} value={opt}>
                    {opt}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <Modal
        show={showModOptions}
        onHide={handleClose}
        id="modifierOptionsModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Modifier Options</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 px-0">
          <div className="SelectModifiersOptions__table">
            <div className="row">
              <div className="col-12">
                {optionsLoading ? (
                  <LoaderBlock />
                ) : modOptions && modOptions?.length ? (
                  <table className="table modifierGroupTable">
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-checkbox-wrapper">
                            <label className="custom-checkbox-container">
                              {"Select All"}
                              <input
                                type="checkbox"
                                value={"selectAll"}
                                onChange={(ev) =>
                                  addRemoveOptionsToArray("", ev)
                                }
                                checked={selectAll}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      {modOptions.map((option, idx) => (
                        <tr key={idx}>
                          <td>
                            <div className="custom-checkbox-wrapper">
                              <label className="custom-checkbox-container">
                                {option?.name}
                                <input
                                  type="checkbox"
                                  onChange={(ev) =>
                                    addRemoveOptionsToArray(option, ev)
                                  }
                                  checked={option.isChecked}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <span className="modifiers__type price">
                              ${option?.price?.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h5>No Option(s) added yet</h5>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-3">
          <button
            type="button"
            className="cancel-btn red-btn-ghost btn"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="red-btn next-btn btn"
            disabled={
              optionsLoading || !modOptions?.length || !isAnyOptionSelected
            }
            onClick={addOptionsToModifier}
          >
            Add Modifier Options
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ItemModifierOption;
