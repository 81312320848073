export const CmsPage = {
  FAQ: {
    pageId: 1,
    text: "FAQ",
  },
  HELP: {
    pageId: 2,
    text: "How it work",
  },
  AGREEMENT: {
    pageId: 3,
    text: "Restaurant Agreement",
  },
  PRIVACY: {
    pageId: 4,
    text: "Privacy Policy",
  },
  TERMS: {
    pageId: 5,
    text: "Terms of use",
  },
  CALLBACK: {
    pageId: 6,
    text: "Request a callback",
  },
  CALLBACK: {
    pageId: 7,
    text: "Security",
  },
};
