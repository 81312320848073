import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";

import "./MainMenu.scss";
import { Times } from "constants/menuAvailabilityTime";
import CategorySection from "../../category-section/layout-wrapper/CategorySection";
import EditButton from "UI/Button/EditButton";
import VirtualBrandContext from "store/virtual-brand-context";
import { deleteBrandMenu, statusChangeOfMenu } from "services/api/menu";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import ImageSrc from "shared/image-src/ImageSrc";

const MainMenu = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const editable = brandCtx.isConfirmPage;
  const brandMode = brandCtx.virtualBrandMode;
  const operatingHours = props.menuAvailabilities;
  const menuId = props.id;
  const showingHours = [];
  const times = Times();

  const [activeStatus, setactiveStatus] = useState(props.isActive);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  if (operatingHours) {
    for (const key in operatingHours) {
      if (Object.hasOwnProperty.call(operatingHours, key)) {
        let element = props.menuAvailabilities[key];
        let title = key;
        const opensAtTiming =
          element?.timings && element?.timings[0]
            ? element?.timings[0]?.opensAt
            : "";
        const closesAtTiming =
          element?.timings && element?.timings[0]
            ? element?.timings[0]?.closesAt
            : "";
        let opens = times.find((time) => time.value === opensAtTiming)?.text;
        let closes = times.find((time) => time.value === closesAtTiming)?.text;
        let mod = {
          title: title,
          opensAt: opens,
          closesAt: closes,
          status: element?.isActive || false
        };
        showingHours.push(mod);
      }
    }
  }

  const onChangeStatus = async () => {
    await statusChangeOfMenu(menuId, !activeStatus).then((res) => {
      toastify("success", res?.message);
      setactiveStatus(!activeStatus);
    });
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteBrandMenu(menuId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const onView = (step) => {
    brandCtx.menuModalStatusChange(true, {
      activeStep: step,
      isEditMode: false,
      isViewMode: true,
      data: props,
    });
  };

  const onEdit = (step) => {
    brandCtx.menuModalStatusChange(true, {
      activeStep: step,
      isEditMode: true,
      isViewMode: false,
      data: props,
    });
  };

  return (
    <>
      <div className="mainMenu__wrapper">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-12 col-md-6 leftside">
            <div className="menu__title">
              <h5 className="d-flex d-md-block">
                <span className="text-uppercase">Menu Title</span>

                {editable ? (
                  brandMode === VirtualBrandMode.SHARED ||
                  brandMode === VirtualBrandMode.VIEW ? (
                    <ViewButton onClick={() => onView(1)} />
                  ) : (
                    <EditButton onClick={() => onEdit(1)} />
                  )
                ) : null}

                {brandMode === VirtualBrandMode.SHARED ||
                brandMode === VirtualBrandMode.VIEW ? null : (
                  <div className="d-md-none mobile-dots-btn">
                    <div className="threedotsbtn btn-group dropleft">
                      <DropdownButton
                        key="left"
                        id="dropdown-button-drop-left"
                        drop="left"
                        variant="secondary"
                        className="leftDrowpdown"
                      >
                        <Dropdown.Item onClick={onChangeStatus}>
                          <div className="dropdown__innerWrapper">
                            <span>
                              Set As {activeStatus ? "Inactive" : "Active"}
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={openDeleteModal}>
                          <div className="dropdown__innerWrapper">
                            <span>Delete</span>
                          </div>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                )}
              </h5>
              <p>{props.title || "NA"}</p>
            </div>

            <div className="menu__desc">
              <h5 className=" text-uppercase">Menu Description</h5>
              <p>{props.description || "NA"}</p>
            </div>

            <div className="operatingHours">
              <h5 className=" text-uppercase">
                Operation Hours
                {editable ? (
                  brandMode === VirtualBrandMode.VIEW ? (
                    <ViewButton onClick={() => onView(2)} />
                  ) : (
                    <EditButton onClick={() => onEdit(2)} />
                  )
                ) : null}
              </h5>
              {showingHours && showingHours.length
                ? showingHours.map((time, idx) => (
                    <p key={idx}>
                      <span className="text-capitalize">{time.title}</span>
                      {time.status === false ? (
                        "Closed"
                      ) : (
                        <span>
                          {time.opensAt} - {time.closesAt}
                        </span>
                      )}
                    </p>
                  ))
                : "No active operating hour(s)"}
            </div>
          </div>

          <div className="col-12 col-md-6 rightside mb-3 pb-2 mb-md-0 pb-md-0">
            {brandMode === VirtualBrandMode.SHARED ||
            brandMode === VirtualBrandMode.VIEW ? null : (
              <div className="d-none d-md-flex justify-content-end">
                <div className="threedotsbtn btn-group dropleft">
                  <DropdownButton
                    key="left"
                    id="dropdown-button-drop-left"
                    drop="left"
                    variant="secondary"
                    className="leftDrowpdown"
                  >
                    <Dropdown.Item onClick={onChangeStatus}>
                      <div className="dropdown__innerWrapper">
                        <span>
                          Set As {activeStatus ? "Inactive" : "Active"}
                        </span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={openDeleteModal}>
                      <div className="dropdown__innerWrapper">
                        <span>Delete Menu</span>
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            )}
            <ImageSrc
              className="img-fluid"
              orgImage={props.coverUrl}
              rawImage={props.rawCoverUrl}
              isThumbnailRequired={false}
            />
            {/* <img alt="" src={props.coverUrl} /> */}
          </div>
        </div>

        {brandMode === VirtualBrandMode.SHARED ||
        brandMode === VirtualBrandMode.VIEW ? null : (
          <div className="addmenu-mobile d-lg-none">{props.children}</div>
        )}

        <CategorySection />
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Menu"
          subtitle="Are you sure want to delete this menu, all categories would be deleted."
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

MainMenu.propTypes = {
  id: PropTypes.number,
  coverUrl: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  minimumValue: PropTypes.number,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  menuAvailabilities: PropTypes.shape({
    monday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    tuesday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    wednesday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    thursday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    friday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    saturday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    sunday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  }),
  isActive: PropTypes.bool,
  onReload: PropTypes.func,
};

export default MainMenu;
