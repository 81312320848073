import React, { useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";

import "./MenuCategoriesCard.scss";
import MenuItemCard from "../item-section/item-card/MenuItemCard";
import {
  getMenuItems,
  statusChangeOfMenuCategory,
  deleteMenuCategory,
} from "services/api/menu";
import { DropdownButton, Dropdown } from "react-bootstrap";
import AddButton from "UI/Button/AddButton";
import VirtualBrandContext from "store/virtual-brand-context";
import ItemModal from "../item-section/item-modal/ItemModal";
import EditButton from "UI/Button/EditButton";
import NoMenuItemCard from "../item-section/item-card/NoMenuItemCard";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";

const MenuCategoryCard = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const menuCategoryId = brandCtx.virtualBrandMenuCategoryId;
  const modalStatus = brandCtx.menuItemModalStatus;
  const editable = brandCtx.isConfirmPage;
  const brandMode = brandCtx.virtualBrandMode;

  const categoryId = props.id;
  const [activeStatus, setactiveStatus] = useState(props.isActive);

  const [menuItems, setmenuItems] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const brandId = brandCtx.virtualBrandId;
  const kitchenId = brandCtx.associationKitchenId;

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    if (categoryId === menuCategoryId && modalStatus === false) getItems();
  }, [modalStatus]);

  const showModal = () => {
    brandCtx.menuItemModalStatusChange(true);
    brandCtx.saveMenuCategoryId(categoryId);
  };

  const closeModal = () => {
    brandCtx.menuItemModalStatusChange(false);
  };

  const getItems = async () => {
    setisLoading(true);
    await getMenuItems(categoryId, brandId, kitchenId).then((res) => {
      setisLoading(false);
      setmenuItems(res.data);
    });
  };

  const onView = () => {
    brandCtx.menuCategoryModalStatusChange(true, {
      editMode: false,
      viewMode: true,
      data: props,
    });
  };

  const onEdit = () => {
    brandCtx.menuCategoryModalStatusChange(true, {
      editMode: true,
      viewMode: false,
      data: props,
    });
  };

  const onChangeStatus = async () => {
    await statusChangeOfMenuCategory(categoryId, !activeStatus).then((res) => {
      toastify("success", res?.message);
      setactiveStatus(!activeStatus);
    });
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);
  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteMenuCategory(categoryId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  return (
    <>
      <div className="col-12">
        <div className="menuCategoriesCard__wrapper">
          <div className="menuCategoriesCard__wrapper__inner">
            <div className="menuCategoriesCard">
              <div className="menuCategoriesCard__header">
                <div className="row align-items-md-end">
                  <div className="col-12 col-md-6">
                    <div className="main__heading">
                      <h2>{props.name}</h2>
                      {editable ? (
                        brandMode === VirtualBrandMode.SHARED ||
                        brandMode === VirtualBrandMode.VIEW ? (
                          <ViewButton onClick={onView} />
                        ) : (
                          <EditButton onClick={onEdit} />
                        )
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-none d-md-flex justify-content-between align-items-center">
                    <p className="d-none d-md-block text-uppercase">
                      Base Price
                    </p>
                    <p className="d-none d-md-block text-uppercase">
                      Sort Order
                    </p>
                    {brandMode === VirtualBrandMode.VIEW ? null : (
                      <div
                        className="threedotsbtn btn-group dropleft"
                        style={{
                          visibility: brandMode === VirtualBrandMode.SHARED
                            ? "hidden" : ""
                        }}
                      >
                        <DropdownButton
                          key="left"
                          id="dropdown-button-drop-left"
                          drop="left"
                          variant="secondary"
                          className="leftDrowpdown"
                        >
                          <Dropdown.Item onClick={onChangeStatus}>
                            <div className="dropdown__innerWrapper">
                              <span>
                                Set As {activeStatus ? "Inactive" : "Active"}
                              </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={onEdit}>
                            <div className="dropdown__innerWrapper">
                              <span>Edit Category</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={openDeleteModal}>
                            <div className="dropdown__innerWrapper">
                              <span>Delete Category</span>
                            </div>
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="menuCategoriesCard__body">
                {isLoading ? (
                  <>
                    <ShimmerLoader />
                  </>
                ) : menuItems && menuItems.length ? (
                  menuItems.map((item, idx) => (
                    <MenuItemCard
                      {...item}
                      key={idx}
                      categoryId={categoryId}
                      onReload={getItems}
                    />
                  ))
                ) : (
                  <NoMenuItemCard />
                )}
              </div>
              <div className="menuCategoriesCard__footer">
                <div className="sort_end_info">
                  Sort Order from 1 to 1000, 1 top of list
                </div>
                <div className="btn-link-type">
                  {brandMode === VirtualBrandMode.SHARED ||
                  brandMode === VirtualBrandMode.VIEW ? null : (
                    <AddButton title="Add New Item" openModal={showModal} />
                  )}
                </div>
                <ItemModal
                  show={modalStatus}
                  className="right"
                  onHide={closeModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Menu Category"
          subtitle="Are you sure want to delete this menu category, all items would be deleted."
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

MenuCategoryCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  menuCategoryAvailability: PropTypes.shape({
    monday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    tuesday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    wednesday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    thursday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    friday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    saturday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    sunday: PropTypes.shape({
      closesAt: PropTypes.string,
      opensAt: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  }),
  isActive: PropTypes.bool,
  onReload: PropTypes.func,
};

export default MenuCategoryCard;
