import React from 'react'
import './errorpage.scss'
import noresultImg from '../../assets/images/noresult.svg'

const noresultfond = () => {
    return (
        <div className="errorPage__wrapper">
            <div className="error__box">
                <div className="error__imgbox">
                    <img src={noresultImg} alt="404 image" className="img-fluid" />
                </div>
                <div className="error__details">
                    <h1>No Results Found</h1>
                    <p>We couldn’t find what you’re looking for.</p>
                </div>
            </div>
        </div>
    )
}

export default noresultfond
