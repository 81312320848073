import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import EditButton from "UI/Button/EditButton";
import { deleteRecipeForRecipeGuide, changeRecipeStatus } from "services/api/recipeguide";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import RecipeCategorySection from "./RecipeCategorySection";
import RecipeGuideContext from "store/recipe-guide-context";

const MainRecipe = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const recipeGuideId = recipeguideCtx.recipeGuideId;
  const [activeStatus, setactiveStatus] = useState(props.isActive);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const recipe = props?.recipe;
  const recipeId = recipe?.id;
  const editable = recipeguideCtx.isConfirmPage;
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;

  const onChangeStatus = async () => {
    await changeRecipeStatus(recipeId, !activeStatus).then((res) => {
      toastify("success", res?.message);
      setactiveStatus(!activeStatus);
    });
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteRecipeForRecipeGuide(recipeId, recipeGuideId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const onView = (step) => {
    recipeguideCtx.recipeModalStatusChange(true, {
      activeStep: step,
      isEditMode: false,
      isViewMode: true,
      data: recipe,
    });
  };

  const onEdit = (step) => {
    recipeguideCtx.recipeModalStatusChange(true, {
      activeStep: step,
      isEditMode: true,
      isViewMode: false,
      data: recipe,
    });
  };

  return (
    <>
      <div className="mainMenu__wrapper">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-12 col-md-6 leftside">
            {/*   <div className="d-flex"> */}
            <div className="menu__title col-md-5 px-0">
              <h5 className="d-flex d-md-block">
                <span className="text-uppercase">Recipe Title</span>

                {editable ? (
                  recipeGuideMode === VirtualBrandMode.SHARED ||
                    recipeGuideMode === VirtualBrandMode.VIEW ? (
                    <ViewButton onClick={() => onView(1)} />
                  ) : (
                    <EditButton onClick={() => onEdit(1)} />
                  )
                ) : null}

                {recipeGuideMode === VirtualBrandMode.SHARED ||
                  recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                  <div className="d-md-none mobile-dots-btn">
                    <div className="threedotsbtn btn-group dropleft">
                      <DropdownButton
                        key="left"
                        id="dropdown-button-drop-left"
                        drop="left"
                        variant="secondary"
                        className="leftDrowpdown"
                      >
                        <Dropdown.Item onClick={onChangeStatus}>
                          <div className="dropdown__innerWrapper">
                            <span>
                              Set As {activeStatus ? "Inactive" : "Active"}
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={openDeleteModal}>
                          <div className="dropdown__innerWrapper">
                            <span>Delete</span>
                          </div>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                )}
              </h5>
              <p>{recipe?.title || "NA"}</p>
            </div>

            <div className="menu__desc">
              <h5 className="text-uppercase">Recipe Description</h5>
              <p>{recipe?.description || "NA"}</p>
            </div>
            {/*   </div> */}
            <div>
              <h5 className="text-uppercase">
                Recipe setup
              </h5>
              <p>{recipe?.setupInstructions || "NA"}</p>
            </div>
            <div>
              <h5 className="text-uppercase">
                Ingredients
              </h5>
              {recipe?.recipeIngredients && recipe.recipeIngredients?.length ?
                recipe.recipeIngredients.map((ele, idx) => (
                  <div className="col-md-12 px-0 d-flex">
                    <div className="col-md-4 px-0 pb-2">
                      <p className="ingredient__value ">{ele?.quantity || "NA"}</p>
                    </div>
                    <div className="col-md-8 px-0 pb-2">
                      <p>{ele?.ingredient || "NA"}</p>
                    </div>
                  </div>
                )) : null}
            </div>
          </div>

          <div className="col-12 col-md-6 rightside mb-3 pb-2 mb-md-0 pb-md-0">
            {recipeGuideMode === VirtualBrandMode.SHARED ||
              recipeGuideMode === VirtualBrandMode.VIEW ? null : (
              <div className="d-none d-md-flex justify-content-end">
                <div className="threedotsbtn btn-group dropleft">
                  <DropdownButton
                    key="left"
                    id="dropdown-button-drop-left"
                    drop="left"
                    variant="secondary"
                    className="leftDrowpdown"
                  >
                    <Dropdown.Item onClick={onChangeStatus}>
                      <div className="dropdown__innerWrapper">
                        <span>
                          Set As {activeStatus ? "Inactive" : "Active"}
                        </span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={openDeleteModal}>
                      <div className="dropdown__innerWrapper">
                        <span>Delete</span>
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            )}
            {recipe?.recipeVideoUrl ? <video width="560" key={recipe?.recipeVideoUrl} height="375" className="img-fluid" controls>
              <source src={recipe?.recipeVideoUrl+'#t=0.1'} />
            </video> : null}
            {/* </div> */}
          </div>
        </div>

        {recipeGuideMode === VirtualBrandMode.SHARED /* ||
          recipeGuideMode === VirtualBrandMode.VIEW */ ? null : (
          <div className="addmenu-mobile d-lg-none">{props.children}</div>
        )}

        <RecipeCategorySection />
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Recipe"
          subtitle="Are you sure want to delete this recipe, all categories would be deleted."
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

MainRecipe.propTypes = {
  recipe: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    setupInstructions: PropTypes.string,
    recipeIngredients: PropTypes.array,
    recipeVideoUrl: PropTypes.string,
  }).isRequired,
  onReload: PropTypes.func,
  isActive: PropTypes.bool,
};

export default MainRecipe;
