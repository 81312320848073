import React from "react";
import "./AddButton.scss";
import plusIcon from "../../assets/images/add-icon-white.svg";

function AddButton(props) {
  return (
    <div className="button__wrapper">
      <button type="button" className="bg-blue-btn" onClick={props.openModal}>
        <span className="icons">
          <img src={plusIcon} alt="plus icon" />
        </span>
        {props?.title}
      </button>
    </div>
  );
}

export default AddButton;
