import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import backArrowIcon from "assets/images/back-arrow.svg";
import "pages/create-brand-form/layout-wrapper/CreateVirtualBrand.scss";
import { Dropdown } from "react-bootstrap";
import { getRecipeByRecipeId } from "services/api/recipeguide";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import LoaderFull from "shared/loader/LoaderFull";
import RecipeGuideContext from "store/recipe-guide-context";
import CreateRecipesTabs from "../../../pages/recipeguide/layout-wrapper/CreateRecipesTabs";

const CreateRecipe = (props) => {
    const location = useLocation();
    const history = useHistory();
    const recipeGuideMode = location.hash.substring(1);
    const recipeguideCtx = useContext(RecipeGuideContext);
    const currentRecipeMode = recipeguideCtx.recipeGuideMode;
    const { recipeId } = useParams("recipeId");
    const [recipeTitle, setRecipeTitle] = useState(null);
    const [fullLoading, setFullLoading] = useState(false);
    const[recipe, setRecipe] = useState(null);

    useEffect(() => {
        if (recipeGuideMode) {
            let mode = recipeGuideMode.toLowerCase();
            switch (mode) {
                case "view":
                    recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.VIEW);
                    recipeguideCtx.isItConfirmPage(true);
                    break;
                case "edit":
                    recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.EDIT);
                    recipeguideCtx.isItConfirmPage(true);
                    break;
                default:
                    recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.ADD);
                    break;
            }
        }
    }, [recipeGuideMode]);

    useEffect(() => {
        return () => {
            recipeguideCtx.resetAllVariables();
        };
    }, []);

    useEffect(() => {
        if (recipeId) {
            getRecipeById();
        }
    }, [recipeId]);

    const getRecipeById = async () => {
        setFullLoading(true);
        await getRecipeByRecipeId(recipeId)
            .then((res) => {
                const data = res?.data
                setFullLoading(false);
                setRecipeTitle(data?.title);
                setRecipe(data);
            })
            .catch(() => {
                setFullLoading(false);
                setRecipe(null);
            });
    };

    const onEditRecipeButton = () => {
        history.push(history.location.pathname + createEditLink());
    };

    const createEditLink = () => {
        let newHash = "";
        let hash = history.location.hash;
        if (hash) {
            if (hash.includes("view")) {
                newHash = hash.replace("view", "edit");
            }
        }
        return newHash;
    };

    const onBack = () => {
        return props.history.goBack();
      };

    return (
      <>
        {fullLoading ? (
          <LoaderFull />
        ) : (
          <>
            <div className="CreateVirtualBrand__wrapper">
              <div className="container">
                <div
                  className={`goBack ${
                    currentRecipeMode !== VirtualBrandMode.ADD
                      ? "view-mode"
                      : ""
                  }`}
                >
                  <button onClick={onBack} className=" back-btn-red">
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </button>
                </div>
                <div className="confirm_page_subheader">
                  <div className="row align-items-lg-end">
                    <div className="col-12 col-md-6 col-lg-6">
                      <h2>{recipeTitle}</h2>
                    </div>
                    {/* <div className={`col-12 col-md-6 col-lg-6`}>
                                            <div className="savedraft__wrapper pb-lg-0">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        id="dropdown-basic"
                                                        className="saveAsDraft__btn"
                                                    >
                                                        Choose an option
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={onEditRecipeButton}
                                                            disabled={currentRecipeMode === VirtualBrandMode.EDIT ? true : false}
                                                        >
                                                            Edit Recipe Details
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div> */}
                  </div>
                </div>
                <div className="createmenu__wrapper">
                  <div className="inner__wrapper">
                    <CreateRecipesTabs recipeId={recipeId} recipe={recipe} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
};

export default withRouter(CreateRecipe);
