import React from 'react'
import { Link } from "react-router-dom";
import './errorpage.scss'
import image404 from '../../assets/images/404.svg'

const page404 = () => {
    return (
        <div className="errorPage__wrapper">
            <div className="error__box">
                <div className="error__imgbox">
                    <img src={image404} alt="404 image" className="img-fluid" />
                </div>
                <div className="error__details">
                    <h1>Page not found</h1>
                    <p>The page you’re looking for doesn’t exists.</p>
                    <Link to="/dashboard" className="error-btn">Go Back to Dashboard</Link>
                </div>
            </div>
        </div>
    )
}

export default page404

