import React, { useContext, useEffect, useState } from "react";
import RecipeCategoryCard from "./RecipeCategoryCard";
import { getRecipeCategories } from "services/api/recipeguide";
import NoRecipeCategoryCard from "./NoRecipeCategoryCard";
import AddButton from "UI/Button/AddButton";
import PrepStepSlideShowButton from "UI/Button/PrepStepSlideShowButton";
import RecipeCategoryModal from "../recipeguide-modal/RecipeCategoryModal";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import RecipeGuideContext from "store/recipe-guide-context";
import PrepStepSlideShowModal from "../recipeguide-modal/PrepStepSlideShowModal";

const RecipeCategorySection = () => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const modalStatus = recipeguideCtx.recipeCategoryModalStatus;
  const prepStepSlideShowStatus = recipeguideCtx.prepStepSlideShow;
  const recipeId = recipeguideCtx.recipeId;
  const [categories, setcategories] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;
  const [prepStepSlideShow, setprepStepSlideShow] = useState(false);

  useEffect(() => {
    if (recipeId) getRecipeRelatedCategory();
  }, [recipeId]);

  useEffect(() => {
    if (modalStatus === false && recipeId) getRecipeRelatedCategory();
  }, [modalStatus]);

  const showModal = () => {
    recipeguideCtx.recipeCategoryModalStatusChange(true);
  };

  const closeModal = () => {
    recipeguideCtx.recipeCategoryModalStatusChange(false);
  };

  const showprepStepModal = () => {
    recipeguideCtx.prepStepSlideShowChange(true);
  };

  const closeprepStepModal = () => {
    recipeguideCtx.prepStepSlideShowChange(false);
  };

  const getRecipeRelatedCategory = async () => {
    setisLoading(true);
    await getRecipeCategories(recipeId)
      .then((res) => {
        setisLoading(false);
        setcategories(res.data);
        recipeguideCtx.saveRecipeId(recipeId);
      })
      .catch(() => {
        setisLoading(false);
        setcategories(null);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="addnewCat__wrapper addnewrecipeCat__wrapper">
                <div className="row justify-content-between align-items-md-center">
                  <div className="col-12 col-md-6">
                    <div className="main__heading">
                      <h1>Preparation Method</h1>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-none d-md-block">
                    <div className="CreateCatagoryModal__wrapper">
                      <div className="d-flex flex-row-reverse">
                        {recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                          <AddButton
                            title="Add Recipe Category"
                            openModal={showModal}
                          />)}
                        <div className="oulined-button-wrapper">
                          <PrepStepSlideShowButton
                            title="Prep Step SlideShow"
                            openModal={showprepStepModal}
                            className="oulined-button-wrapper"
                          />
                        </div>
                      </div>
                      <RecipeCategoryModal
                        show={modalStatus}
                        className="right"
                        onHide={closeModal}
                      />
                      <PrepStepSlideShowModal
                        show={prepStepSlideShowStatus}
                        className="right"
                        onHide={closeprepStepModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 d-md-none">
              <div className="oulined-button-wrapper">
                <PrepStepSlideShowButton
                  title="Prep Step SlideShow"
                  openModal={showprepStepModal}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {isLoading ? (
              <>
                <div className="col-12">
                  <ShimmerLoader />
                </div>
              </>
            ) : categories && categories.length ? (
              categories.map((category, idx) => (
                <RecipeCategoryCard
                  {...category}
                  key={idx}
                  isActive={category?.isActive}
                  onReload={getRecipeRelatedCategory}
                />
              ))
            ) : (
              <NoRecipeCategoryCard />
            )}

            <div className="col-12 col-md-6 d-md-none pb-3">
              {recipeGuideMode === VirtualBrandMode.SHARED ||
              recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                <AddButton title="Add Recipe Category" openModal={showModal} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipeCategorySection;
