import React from "react";
import { Link } from "react-router-dom";
import "../preloginstyle.scss";
import successIcon from "assets/images/success-logo.svg";

const PasswordChanged = () => {
  return (
    <>
      <div className="prelogin__heading">
        <div className="success mb-3 pb-2">
          <img src={successIcon} alt="password changed" />
        </div>
        <h1>Password Changed</h1>
        <div className="prelogin__subtext">
          <p>
            Congrats! Your password has now changed. Please login with your new
            password.
          </p>
        </div>
      </div>
      <div className="preloginForm__wrapper">
        <form>
          <div className="form-row no-gutters">
            <div className="col-md-12 mb-3 action__wrapper">
              <Link to="/login" className="prelogin-links-btn">
                Back to Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PasswordChanged;
