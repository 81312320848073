import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTimer } from "react-timer-hook";
import { useStopwatch } from "react-timer-hook";
import { colour, screenNames } from "constants/orderStatus";
import "./OrderView.scss";

const Timer = ({ time, pickUpTime, screens, timeOffset }) => {
  const [isExpire, setExpire] = useState(false);
  const orderPrepStartTime = new Date(moment(time));
  const orderPrepStartTimes = new Date(moment(time));
  const initialdate = new Date();
  initialdate.setSeconds(0);
  initialdate.setMinutes(0);
  initialdate.setHours(0);

  const fromattedPickupTime = new Date(moment(pickUpTime));
  const pickupTimeinMin =
    fromattedPickupTime.getHours() * 60 + fromattedPickupTime.getMinutes();
  const orderModifiedTimeinMin =
    orderPrepStartTime.getHours() * 60 + orderPrepStartTime.getMinutes();
  const estdPickUpTimeinMin = pickupTimeinMin - orderModifiedTimeinMin;

  const getCurrentDateTime = () => {
    const currentTime = new Date();
    currentTime.setTime(currentTime.getTime() + (timeOffset * 1000));
    const currentUTCDateTime = currentTime.toUTCString();
    const currentDate = moment(
      currentUTCDateTime,
      "ddd, DD MMM YYYY HH:mm:ss [GMT]"
    );
    return currentDate;
  };

  const getPickUpLocalTime = () => {
    const pickupTimeObj = new Date(pickUpTime);
    pickupTimeObj.setTime(pickupTimeObj.getTime() - (timeOffset * 1000));
    const formattedPickupTime = moment(pickupTimeObj).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    const pickUpLocalTime = new Date(
      moment.utc(formattedPickupTime).local().format()
    );
    return pickUpLocalTime;
  };

  const currentDate = getCurrentDateTime();

  useEffect(() => {
    if (pickUpTime) {
      startsTimer();
    } else {
      pauses();
      pause();
    }
  }, []);

  const startsTimer = () => {
    orderPrepStartTime.setMinutes(
      orderPrepStartTime.getMinutes() + estdPickUpTimeinMin
    );
    const t = moment(orderPrepStartTime);
    if (t.isBefore(currentDate)) {
      setExpire(true);
      starts();
    }
  };
  const onExpire = () => {
    setExpire(true);
    starts();
  };

  const { start: starts, pause: pauses } = useStopwatch({ autoStart: true });
  const expireTime = getCurrentDateTime();
  const mins = moment
    .utc(
      moment(currentDate, "HH:mm:ss").diff(
        moment(orderPrepStartTimes, "HH:mm:ss")
      )
    )
    .format("mm");
  const Hour = moment
    .utc(
      moment(currentDate, "HH:mm:ss").diff(
        moment(orderPrepStartTimes, "HH:mm:ss")
      )
    )
    .format("HH");
  const Sec = moment
    .utc(
      moment(currentDate, "HH:mm:ss").diff(
        moment(orderPrepStartTimes, "HH:mm:ss")
      )
    )
    .format("ss");
  expireTime.set({
    hour: Hour,
    minute: mins - estdPickUpTimeinMin,
    second: Sec,
  });

  const { seconds, minutes, hours, pause } = useTimer({
    expiryTimestamp: getPickUpLocalTime(),
    onExpire: () => onExpire(),
  });
  const date = currentDate;
  date.set({
    hour: hours,
    minute: minutes,
    second: seconds,
  });

  return (
    <div
      className="timerStyle"
      style={{
        color: isExpire
          ? colour.red
          : screens === screenNames.orderDetails
          ? colour.white
          : colour.Timer,
      }}
    >
      {isExpire && expireTime > initialdate ? "-" : " "}
      {moment(isExpire ? expireTime : date).format("HH:mm:ss")}
    </div>
  );
};
export default Timer;
