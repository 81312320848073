import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HowItWorks.scss";
import landingLogo__black from "../../assets/images/landing-logo-black.svg";
import section1Image from "../../assets/images/how-it-works-1.png";
import section2Image from "../../assets/images/how-it-works-2.png";
import section3Image from "../../assets/images/how-it-works-3.png";
import section4Image from "../../assets/images/how-it-works-4.png";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import { getUserDataFromToken } from "services/local-storage";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const LandingPage = () => {
  const isLoggedIn = getUserDataFromToken();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }
  return (
    <>
      <div className="landingPage__wrapper static__pages__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid white__logo"
                    width={120}
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                    width={120}
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                {isLoggedIn ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-link signUp__link w-100 h-100 mx-auto"
                    role="button"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-link signIn__link"
                      role="button"
                    >
                      Login
                    </Link>
                    <Link
                      to="/kitchen/register"
                      className="btn btn-link signUp__link"
                      role="button"
                    >
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        <Subheader mobileExtraClass="staticPages_subheader">
          <div className="goBack mb-3">
            <Link to="/home" className="back-btn-red" title="Go to Dashboard">
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Back
            </Link>
          </div>
        </Subheader>
        <main>
          <div className="container">
            <section className="page__title__section">
              <div className="row">
                <div className="col-12 d-none d-md-block">
                  <div className="goBack">
                    <Link to="/home" className=" back-btn-red">
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back to Home
                    </Link>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>How It Works</h1>
                  </div>
                </div>
              </div>
            </section>
            <div className="inner__section__wrapper">
              <section className="sections section-1">
                <div className="bg">
                  <div className="section-content">
                    <div className="row align-items-start">
                      <div className="col-12 col-lg-6">
                        <div className="section-content">
                          <div className="steps-count">
                            <span>01</span>
                          </div>
                          <h3>Create Brands</h3>
                          <h4>
                            Create and publish a new or existing virtual brand
                          </h4>
                          <p>
                            Sign in to our self-serve web portal to create a new
                            virtual brand, or choose an existing brand from our
                            marketplace.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                        <div className="sectio__img__box">
                          <img
                            src={section1Image}
                            className="img-fluid"
                            alt="how to works"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="sections section-2">
                <div className="bg">
                  <div className="section-content">
                    <div className="row align-items-start">
                      <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <div className="sectio__img__box">
                          <img
                            src={section2Image}
                            className="img-fluid"
                            alt="how it works"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 order-1 order-lg-2 pt-lg-4 mt-lg-2">
                        <div className="section-content">
                          <div className="steps-count">
                            <span>02</span>
                          </div>
                          <h3>Accept Orders</h3>
                          <h4>
                            Start receiving orders on our companion tablet
                          </h4>
                          <p>
                            We setup all delivery platforms & channels and
                            provide you with a dedicated Backhouse Brands tablet
                            to start receiving and accepting orders.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="sections section-3">
                <div className="bg">
                  <div className="section-content">
                    <div className="row align-items-start">
                      <div className="col-12 col-lg-6">
                        <div className="section-content">
                          <div className="steps-count">
                            <span>03</span>
                          </div>
                          <h3>Prepare Food</h3>
                          <h4>Prepared food for pickup or delivery</h4>
                          <p>
                            As new orders arrive from various online ordering
                            channels, tickets are printed and the food is
                            prepared for pickup or delivery.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                        <div className="sectio__img__box">
                          <img
                            src={section3Image}
                            className="img-fluid"
                            alt="how it work"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="sections section-4">
                <div className="bg">
                  <div className="section-content">
                    <div className="row align-items-start">
                      <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <div className="sectio__img__box">
                          <img
                            src={section4Image}
                            className="img-fluid"
                            alt="how it works"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 order-1 order-lg-2">
                        <div className="section-content">
                          <div className="steps-count">
                            <span>04</span>
                          </div>
                          <h3>Get Paid</h3>
                          <h4>Collect weekly revenue share payouts</h4>
                          <p>
                            We offer a blended rate for pickup and delivery
                            across all delivery channels and issue a
                            consolidated weekly revenue share payout.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default LandingPage;
