import jwt from "jwt-decode";

const setBasicToken = (token) => {
  localStorage.setItem("basicToken", token + "");
};

const getBasicToken = () => {
  return localStorage.getItem("basicToken");
};

const removeBasicToken = () => {
  localStorage.removeItem("basicToken");
};

const setUserToken = (tokens) => {
  let token = JSON.stringify(tokens);
  localStorage.setItem("userToken", token);
};

const getUserToken = () => {
  let tokens = JSON.parse(localStorage.getItem("userToken"));
  return tokens?.token;
};

const getUserDataFromToken = () => {
  let token = getUserToken();
  if (token) {
    let tokenInfo = jwt(token);
    return {
      email: tokenInfo.email,
      userId: tokenInfo.userid,
      kitchenId: +tokenInfo.kitchenid,
      role: tokenInfo.role,
      username: tokenInfo.username,
    };
  }
  return null;
};

const getUserRefreshToken = () => {
  let tokens = JSON.parse(localStorage.getItem("userToken"));
  return tokens?.refreshToken;
};

const removeUserToken = () => {
  localStorage.removeItem("userToken");
};

export {
  getBasicToken,
  setBasicToken,
  removeBasicToken,
  setUserToken,
  getUserToken,
  getUserRefreshToken,
  removeUserToken,
  getUserDataFromToken,
};
