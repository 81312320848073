import React from "react";
import "./RecipeGuideBasicInfo.scss";
import noMenuItemImage from "../../../assets/images/menuitem-noimage.svg";

const MenuItemImage = (props) => {
    return (
        <div className="basic__info__content">
            <label htmlFor="preparationTime">
                Menu Item/Recipe Guide Image
            </label>
            <div className="right-side-form-wrapper">
                <div className="imgBox">
                {props?.imageUrl ?
                    <img className="imgStyle" src={props?.imageUrl} />
                    : <img className="img-fluid"  src={noMenuItemImage} />
                }
                </div>
                <div className="item_name">
                    {props?.menuItemName || "Menu Item Name"}
                </div>
                <div className="vb_name">
                    {props?.vbName || "Virtual Brand Name"}
                </div>
            </div>
        </div>
    );
};

export default MenuItemImage;
