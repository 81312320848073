import React, { useEffect, useState, useContext } from "react";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import plusIcon from "assets/images/add-icon-white.svg";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import deleteIocn from "assets/images/delete-icon.svg";
import searchIcon from "assets/images/icon-search.svg";
import crossswbgIcon from "assets/images/crosswbgicon.svg";
import {
  addModifierToMenuItem,
  editModifierToMenuItem,
  getModifierGroups,
} from "services/api/menu";
import {  getModifierGroup } from "services/api/modifier";
import { ModifierType } from "constants/modifierType";
import LoaderInline from "shared/loader/LoaderInline";
import VirtualBrandContext from "store/virtual-brand-context";
import ViewModifierModal from "./ViewModifierModal";
import LoaderFull from "shared/loader/LoaderFull";

import "./AddNewItemModal.scss";
import ItemModifierOption from "./ItemModifierOption";
import AddModifierModal from "./AddModifierModal";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import toastify from "shared/toastr";
import ViewButton from "UI/Button/ViewButton";

let orgGroupList = [];

const ItemModifier = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const addedMenuItemId = brandCtx.virtualBrandMenuItemId;

  // get these when we have edit mode
  const data = props.initialData;
  const extras = props.extras;
  const isDisabled = props.disabled;

  // groupList : groups list from API
  const [groupList, setgroupList] = useState(null);

  // selected groups from accordian wizard
  const [selectedModGroups, updateSelectedModGroups] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isModgroupLoading, setisModgroupLoading] = useState(false);
  const [isShowViewModifierModal, setIsShowViewModifierModal] = useState(false);
  const [modGroup, setModGroup] = useState({});

  // modal toggler for add modifier modal
  const [modifierModal, setaddModModal] = useState(false);

  // toggle accordian
  const [isWizardOpen, setisWizardOpen] = useState(true);

  useEffect(() => {
    return () => props.onChangeTab(getValues());
  }, []);

  // validation schema for mod options
  const optionSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string(),
    price: Yup.number(),
    priority: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(1, "Value must be at least 1")
      .max(1000, "Value cannot be more than 1000")
  });

  // validation schema for overall form
  const validationSchema = Yup.object().shape({
    modifier: Yup.array().of(
      Yup.object().shape({
        menuItemModGroup: Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
          modifierType: Yup.number(),
          isActive: Yup.boolean(),
          createdOn: Yup.string(),
          modifiedOn: Yup.string(),
        }),
        isMandatory: Yup.boolean().required(),
        maxOptionsAllowed: Yup.number(),
        menuItemModOptions: Yup.array().of(optionSchema).min(1),
      })
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "modifier",
    keyName: "groupId",
  });

  // on component mount, hit API to get all groups
  useEffect(() => {
    modifierGroupsList();
  }, []);

  // bind data to form when in clone or edit mode
  useEffect(() => {
    if (data && (extras?.editMode || extras?.cloneMode || extras?.viewMode)) {
      let mods = data;
      if (mods) {
        const mappedMods = mods?.length && mods.map((modifier) => {
          return {
            isMandatory: "" + modifier.isMandatory,
            maxOptionsAllowed: modifier.maxOptionAllowed,
            menuItemModGroup: modifier.menuItemMod,
            menuItemModOptions: modifier.modOptionsForMenuItems.map(
              (opt) => ({ ...opt.menuItemModOption, priority: opt.priority })
            ),
          };
        });
        const modifierData = data?.modifier || mappedMods;
        reset({modifier: modifierData});
      }
    }
  }, [data]);

  const modifierGroupsList = async () => {
    const modifierData = data?.modifier || data;
    let mods = fields?.length ? fields : modifierData;
    let groupsList = [];
    setisModgroupLoading(true);
    await getModifierGroups().then((res) => {
      if (mods?.length) {
        let selModifiers = mods?.map((item) => {
          return item?.menuItemModGroup || item?.menuItemMod;
        })
        for (var i = 0; i < res.data?.length; i++) {
          for (var j = 0; j < selModifiers?.length; j++) {
            if (res.data[i].id == selModifiers[j].id) 
              res.data[i]["isChecked"] = true
          }
          res.data[i]["isDeleting"] = false;
          groupsList.push(res.data[i]);
        }
        updateSelectedModGroups([...selModifiers])
      }
      else {
        groupsList = res.data?.map((group) => ({
          ...group,
          isChecked: false,
          isDeleting: false,
        }));
      }
      setgroupList(groupsList);
      orgGroupList = groupsList;
      setisModgroupLoading(false);
    });
  };

  const checkModType = (type) => {
    switch (type) {
      case ModifierType.FOOD:
        return "Food Modifier";
      case ModifierType.DRINK:
        return "Drink Modifier";
      default:
        return "NA";
    }
  };

  const selectModGroup = (modGroup, ev) => {
    let isChecked = ev.target.checked;
    if (isChecked) {
      updateGroupListAndSelectedGroups(modGroup);
    } else {
      removeGroupListAndSelectedGroups(modGroup);
    }
  };

  const updateGroupListAndSelectedGroups = (modGroup) => {
    updateSelectedModGroups([
      ...selectedModGroups,
      { ...modGroup, isDeleting: false },
    ]);
    // marked check in group list
    let deleteSelectedModGroup = groupList.filter(
      (val) => val.id !== modGroup.id
    );
    let checkGroup = { ...modGroup, isChecked: true };
    let sorted = sortGroupList([checkGroup, ...deleteSelectedModGroup]);
    setgroupList(sorted);
    orgGroupList = orgGroupList.map((res) => {
      if (res.id === modGroup.id) return { ...res, isChecked: true };
      else return res;
    });
  };

  const removeGroupListAndSelectedGroups = (modGroup) => {
    let tempGroupList = selectedModGroups;
    let deletingGroupList = tempGroupList.map((data) => {
      if (data.id === modGroup.id) return { ...data, isDeleting: true };
      return data;
    });
    updateSelectedModGroups(deletingGroupList);
    // marked uncheck in group list
    let deleteSelectedModGroup = groupList.filter(
      (val) => val.id !== modGroup.id
    );
    let uncheckGroup = { ...modGroup, isChecked: false };
    let sorted = sortGroupList([uncheckGroup, ...deleteSelectedModGroup]);
    setgroupList(sorted);
    orgGroupList = orgGroupList.map((res) => {
      if (res.id === modGroup.id) return { ...res, isChecked: false };
      else return res;
    });
  };

  const sortGroupList = (list) => {
    return list.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );
  };

  const submitOnClick = () => {
    const values = getValues();
    trigger().then((val) => {
      if (val) {
        onSubmit(values);
      }
      else{
        toastify("error", "Please Select At Least One Modifier Option");
      }
    });
  };

  const onSubmit = async (data) => {
    if (isDisabled) {
      props.onSave();
      return;
    }

    let model = { menuItemId: addedMenuItemId, ...data };
    setisLoading(true);
    if (extras && extras?.editMode) {
      await editModifierToMenuItem(model)
        .then(() => {
          setisLoading(false);
          props.onSave();
        })
        .catch(() => {
          setisLoading(false);
        });
    } else {
      await addModifierToMenuItem(model)
        .then(() => {
          setisLoading(false);
          props.onSave();
        })
        .catch(() => {
          setisLoading(false);
        });
    }
  };

  const onSelectedModifiers = () => {
    if (selectedModGroups.length >= fields.length) {
      selectedModGroups.forEach((element) => {
        let findedIndex = fields.findIndex(
          (fds) => fds?.menuItemModGroup?.id === element.id
        );
        // if found the object then remove it from the fieldArray
        if (element.isDeleting) {
          remove(findedIndex);
        }
        // if not then add it to the fieldArray
        if (findedIndex === -1) {
          append({
            menuItemModGroup: element,
            isMandatory: "false",
            menuItemModOptions: [],
            maxOptionsAllowed: 0,
          });
        }
      });
    }
    removeIsDeletingFlaggedGroups();
    setisWizardOpen(false);
  };

  const removeIsDeletingFlaggedGroups = () => {
    let filteredGroupList = selectedModGroups.filter((val) => !val.isDeleting);
    updateSelectedModGroups(filteredGroupList);
  };

  const removeAddedGroup = (group, index) => {
    remove(index);
    // remove from selected items
    let modGroup = group.menuItemModGroup;
    let tempGroupList = selectedModGroups;
    let deletingGroupList = tempGroupList.map((data) => {
      if (data.id === modGroup.id) return { ...data, isDeleting: true };
      return data;
    });
    let filteredGroupList = deletingGroupList.filter((val) => !val.isDeleting);
    updateSelectedModGroups(filteredGroupList);
    // marked uncheck in group list
    let deleteSelectedModGroup = groupList.filter(
      (val) => val.id !== modGroup.id
    );
    let uncheckGroup = { ...modGroup, isChecked: false };
    let sorted = sortGroupList([uncheckGroup, ...deleteSelectedModGroup]);
    setgroupList(sorted);
    orgGroupList = orgGroupList.map((res) => {
      if (res.id === modGroup.id) return { ...res, isChecked: false };
      else return res;
    });
  };

  const openAddModModal = () => setaddModModal(true);
  const closeAddModModal = () => setaddModModal(false);

  const onSearch = (e) => {
    let searchTerm = e.target.value;
    let searched = orgGroupList.filter((grp) =>
      grp.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setgroupList(searched);
  };

  const onMaxOptionAllowedChange = ({ value, index }) => {
    setValue(`modifier.${index}.maxOptionsAllowed`, value, {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onViewModifier = async (modGroup) => {
    setisLoading(true)
    const {id} = modGroup;
    await getModifierGroup(id)
    .then((res) => {
      openViewModifierModal();
      setModGroup(res?.data ? res.data : {});
      setisLoading(false);
    })
    .catch(error => {
      setisLoading(false);
    })
  }

  const openViewModifierModal = () => setIsShowViewModifierModal(true);
  const closeViewModifierModal = () => setIsShowViewModifierModal(false);

  const onFormSubmit = () => {
    return;
  };

  return (
    <div className="CreateMenuAvailabity__wrapper basic__info__content">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row no-gutters">
          {/* added modifiers starts here */}
          {fields && fields.length ? (
            <div className="col-12 mb-3 pb-2">
              {fields.map((item, index) => (
                <div
                  key={item.groupId}
                  className={"item-modifiers " + (isDisabled ? "is__disabled" : "modifiers__wrapper")}
                >
                  <div className="modifiersCard">
                    <div className="modifiersCard__inner">
                      <div className="modifiersCard__header">
                        <div className="heading__wrapper">
                          <h3>{item.menuItemModGroup?.name}</h3>
                          {isDisabled ? null : (
                            <button
                              type="button"
                              onClick={() => removeAddedGroup(item, index)}
                              className="delete_modifier"
                            >
                              <span>
                                <img
                                  src={deleteIocn}
                                  className=""
                                  alt="delete"
                                />
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="modifiersCard__body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <h5>Mandatory</h5>
                            <div className="check__wrapper">
                              <label className="check-container">
                                Yes
                                <input
                                  type="radio"
                                  {...register(`modifier.${index}.isMandatory`)}
                                  value={true}
                                  disabled={isDisabled}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="check-container">
                                No
                                <input
                                  type="radio"
                                  {...register(`modifier.${index}.isMandatory`)}
                                  value={false}
                                  disabled={isDisabled}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          <ItemModifierOption
                            index={index}
                            control={control}
                            register={register}
                            selectedGroup={item}
                            maxOptionAllowedChange={onMaxOptionAllowedChange}
                            disabled={isDisabled}
                            onReload={props.onReload}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {/* added modifiers ends here */}

          {/* accordion starts here  */}
          {isDisabled ? null : (
            <div className="col-12">
              <div className="accordion" id="modifierGroup">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Select Modifier Groups
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className={`collapse ${isWizardOpen ? "show" : ""}`}
                    aria-labelledby="headingOne"
                    data-parent="#modifierGroup"
                  >
                    <div className="card-body modifierGroup__cardBody">
                      <div className="form-row no-gutters">
                        <div className="col-12">
                          <div className="modifierGroupFrom__header">
                            <div className="search_modifier_group">
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="text"
                                    className="custom-file form-control"
                                    placeholder="Search modifier groups"
                                    onChange={onSearch}
                                  />
                                  <button type="button" className="search-btn">
                                    <span className="icons">
                                      <img src={searchIcon} alt="plus icon" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="button__wrapper">
                              <button
                                type="button"
                                className="bg-blue-btn"
                                onClick={openAddModModal}
                              >
                                <span className="icons">
                                  <img src={plusIcon} alt="plus icon" />
                                </span>
                                New Modifier
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <table className="table modifierGroupTable itemModifierGroupTable">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="custom-checkbox-wrapper">
                                    Group Name
                                  </div>
                                </th>
                                <th scope="col">Modifier Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupList && groupList.length ? (
                                groupList.map((modGroup, idx) => (
                                  <tr key={idx}>
                                    <td className="d-flex">
                                      <div className="custom-checkbox-wrapper">
                                        <label className="custom-checkbox-container">
                                          {modGroup.name}
                                          <input
                                            type="checkbox"
                                            onClick={(ev) =>
                                              selectModGroup(modGroup, ev)
                                            }
                                            checked={modGroup.isChecked == true ? true : false}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                      <div>
                                        <ViewButton height={16} width={16} onClick={() => onViewModifier(modGroup)} />
                                      </div>
                                    </td>
                                    <td>
                                      <span className="modifiers__type">
                                        {checkModType(modGroup.modifierType)}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : isModgroupLoading ? (
                                <tr>
                                  <td>
                                    <ShimmerLoader />
                                  </td>
                                </tr>
                              ) : (
                                <div className="content-desc">
                                <h6>No Modifier Groups Found</h6>
                              </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {selectedModGroups && selectedModGroups?.length ? (
                        <>
                          <div className="row no-gutters">
                            <div className="col-12">
                              <div className="selectedModifiers__wrapper">
                                <h3>Selected Modifiers</h3>
                                <div className="selectedModifiers">
                                  {selectedModGroups.map((group, idx) => {
                                    if (!group.isDeleting) {
                                      return (
                                        <div key={idx}>
                                          <span>{group.name}</span>
                                          <span>
                                            <button
                                              type="button"
                                              className="icon-btn"
                                            >
                                              <img
                                                className="icons"
                                                src={crossswbgIcon}
                                                alt=""
                                                onClick={() =>
                                                  removeGroupListAndSelectedGroups(
                                                    group
                                                  )
                                                }
                                              />
                                            </button>
                                          </span>
                                        </div>
                                      );
                                    } else return null;
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 continue-btn-wrapper">
                              <button
                                type="button"
                                className="red-btn"
                                onClick={onSelectedModifiers}
                              >
                                <span>Continue</span>
                                <span className="icons-right">
                                  <img src={rightArrowIcon} alt="arrow icon" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* accordion ends here  */}
        </div>
      </form>

      {fields && fields.length ? (
        <div className="col-12 m-0 p-0">
          <div className="modal-footer">
            <button
              type="button"
              onClick={props.onHide}
              className="cancel-btn red-btn-ghost btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={submitOnClick}
              className="red-btn next-btn btn"
              disabled={isLoading || !isValid}
            >
              {isLoading ? (
                <LoaderInline />
              ) : (
                <>
                  <span>Save and Continue</span>
                  <span className="icons-right">
                    <img src={rightArrowIcon} alt="arrow icon" />
                  </span>
                </>
              )}
            </button>
          </div>
        </div>
      ) : isDisabled ? (
        <>
          <div className="text-center">
            <h5>No Modifier(s) Added</h5>
          </div>
        </>
      ) : null}

      {(!fields?.length && !selectedModGroups?.length) && (
         <div className="modal-footer">
        <button
          type="button"
          onClick={submitOnClick}
          className="red-btn next-btn btn"
          disabled={isLoading}
        >
          {isLoading ? (
            <LoaderInline />
          ) : (
            <>
              <span>Save</span>
              <span className="icons-right">
                <img src={rightArrowIcon} alt="arrow icon" />
              </span>
            </>
          )}
        </button>
        </div>)}
        

      {modifierModal && (
        <AddModifierModal
          show={modifierModal}
          onHide={closeAddModModal}
          onSave={modifierGroupsList}
        />
      )}
      
      {isLoading && !isShowViewModifierModal && <LoaderFull />}
      {isShowViewModifierModal && (
        <ViewModifierModal
          show={isShowViewModifierModal}
          modGroup={modGroup}
          onHide={closeViewModifierModal}
        />
      )}
    </div>
  );
};

export default ItemModifier;
