import React from "react";
import { Link } from "react-router-dom";
import "../ManageResturants/ManageResturantsDetails.scss";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const ManageUserDetails = () => {
  return (
    <div className="modifierDetails__wrapper restaurantDetails__wrapper user__details">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="goBack">
              <Link to="/manage-user" className=" back-btn-red">
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="main_heading">
              <h1>User Details</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="details__wrapper">
              <div className="details__wrapper__inner">
                <div className="left_content">
                  <div className="row">
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Full Name</h5>
                        <p>Drew Rice</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>User Type</h5>
                        <p>Normal User</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Contact No</h5>
                        <p>234 678 0987</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Account Email</h5>
                        <p>drew.rice@gmail.com</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Address Line 1</h5>
                        <p>3045 Eagandale Place</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Address Line 2</h5>
                        <p>3045 Eagandale Place</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>City</h5>
                        <p>Los Angeles</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>State</h5>
                        <p>California</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Country</h5>
                        <p>USA</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Zip</h5>
                        <p>226021</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_content if__active">
                  <span className="custom__badge badge__red table__badge">
                    Active
                  </span>
                  <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-button-drop-left"
                    ></button>
                    <div className="dropdown-menu">
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item active">
                          <span>Set as Inactive</span>
                        </Link>
                        <Link to="#" className="dropdown-item active">
                          <span>Edit</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserDetails;
