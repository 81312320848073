import { createContext, useState } from "react";

const VirtualBrandContext = createContext({
  virtualBrandId: null,
  virtualBrandMenuId: null,
  virtualBrandMenuCategoryId: null,
  virtualBrandMenuItemId: null,
  saveBrandId: (brandId) => {},
  saveMenuId: (brandMenuId) => {},
  saveMenuCategoryId: (menuCategoryId) => {},
  saveMenuItemId: (menuItemId) => {},
  vbModalStatus: null,
  menuModalStatus: null,
  menuModalStatusChange: (status, extras = null) => {},
  vbModalStatusChange: (status, extras = null) => {},

  menuCategoryModalStatus: null,
  menuCategoryModalStatusChange: (status, extras = null) => {},

  menuItemModalStatus: null,
  menuItemModalStatusChange: (status, extras = null) => {},

  isConfirmPage: null,
  isItConfirmPage: (isConfirm) => {},

  selectedVirtualBrandName: null,
  setVirtualBrandName: (name) => {},

  virtualBrandInfo: null,
  setVirtualBrandInfo: (info) => {},

  resetAllVariables: () => {},

  modalExtras: null,

  virtualBrandMode: null,
  setVirtualBrandMode: (status) => {},

  associationKitchenId: null,
  saveAssociationKitchenId: (id) => {},
});

export function VirtualBrandContextProvider(props) {
  const [brandId, setBrandId] = useState(null);
  const [brandMenuId, setBrandMenuId] = useState(null);
  const [brandMenuCategoryId, setBrandMenuCategoryId] = useState(null);
  const [brandMenuItemId, setBrandMenuItemId] = useState(null);
  const virtualBrandIdHandler = (brandId) => setBrandId(brandId);
  const virtualBrandMenuIdHandler = (brandMenuId) =>
    setBrandMenuId(brandMenuId);
  const virtualBrandMenuCategoryIdHandler = (brandMenuCategoryId) =>
    setBrandMenuCategoryId(brandMenuCategoryId);
  const virtualBrandMenuItemIdHandler = (brandMenuItemId) =>
    setBrandMenuItemId(brandMenuItemId);

  const [menuModal, setmenuModal] = useState(null);
  const [vbModal, setvbModal] = useState(null);
  const menuModalHandler = (status, extras = null) => {
    setmenuModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };
  const vbModalHandler = (status, extras = null) => {
    setvbModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const [menuCategoryModal, setmenuCategoryModal] = useState(null);
  const menuCategoryModalHandler = (status, extras = null) => {
    setmenuCategoryModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const [menuItemModal, setmenuItemModal] = useState(null);
  const menuItemModalHandler = (status, extras = null) => {
    setmenuItemModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const [isConfirmPage, setisConfirmPage] = useState(null);
  const isItConfirmPageHandler = (isConfirm) => setisConfirmPage(isConfirm);

  const [brandName, setbrandName] = useState(null);
  const virtualBrandNameHandler = (name) => setbrandName(name);

  const [brandInfo, setbrandInfo] = useState(null);
  const virtualBrandInfoHandler = (info) => {
    setbrandInfo(info);
    setBrandId(info?.id);
    setbrandName(info?.name);
  };

  const [brandMode, setbrandMode] = useState(null);
  const virtualBrandModeHandler = (status) => setbrandMode(status);

  const [modalExtrasProps, setmodalExtrasProps] = useState(null);

  const [kitchenId, setkitchenId] = useState(null);
  const kitchenIdHandler = (id) => setkitchenId(id);

  const deleteAllVariables = () => {
    setBrandId(null);
    setBrandMenuId(null);
    setBrandMenuCategoryId(null);
    setBrandMenuItemId(null);
    setmenuModal(null);
    setvbModal(null);
    setmenuCategoryModal(null);
    setmenuItemModal(null);
    setisConfirmPage(null);
    setbrandName(null);
    setbrandInfo(null);
    setbrandMode(null);
  };

  // actual context below from here
  const context = {
    virtualBrandId: brandId,
    virtualBrandMenuId: brandMenuId,
    virtualBrandMenuCategoryId: brandMenuCategoryId,
    virtualBrandMenuItemId: brandMenuItemId,
    saveBrandId: virtualBrandIdHandler,
    saveMenuId: virtualBrandMenuIdHandler,
    saveMenuCategoryId: virtualBrandMenuCategoryIdHandler,
    saveMenuItemId: virtualBrandMenuItemIdHandler,

    menuModalStatus: menuModal,
    vbModalStatus: vbModal,
    menuModalStatusChange: menuModalHandler,
    vbModalStatusChange: vbModalHandler,

    menuCategoryModalStatus: menuCategoryModal,
    menuCategoryModalStatusChange: menuCategoryModalHandler,

    menuItemModalStatus: menuItemModal,
    menuItemModalStatusChange: menuItemModalHandler,

    isConfirmPage: isConfirmPage,
    isItConfirmPage: isItConfirmPageHandler,

    selectedVirtualBrandName: brandName,
    setVirtualBrandName: virtualBrandNameHandler,

    virtualBrandInfo: brandInfo,
    setVirtualBrandInfo: virtualBrandInfoHandler,

    resetAllVariables: deleteAllVariables,

    modalExtras: modalExtrasProps,

    virtualBrandMode: brandMode,
    setVirtualBrandMode: virtualBrandModeHandler,

    associationKitchenId: kitchenId,
    saveAssociationKitchenId: kitchenIdHandler,
  };

  return (
    <VirtualBrandContext.Provider value={context}>
      {props.children}
    </VirtualBrandContext.Provider>
  );
}

export default VirtualBrandContext;
