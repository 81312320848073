import React, { useEffect, useState } from "react";
import { useForm, FormProvider} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import LoaderInline from "shared/loader/LoaderInline";
import { AvailabilityValidationSchema } from "shared/menu-availability/AvailabilityTimingsSchema";
import plusIconRed from "assets/images/add-redIcon.svg";
import { Times } from "constants/menuAvailabilityTime";
import deleteIcon from "assets/images/delete-icon.svg";

const MenuAvailabilityFormNew = (props) => {
  const isLoading = props.loader;
  const data = props.initialData;
  const timerDropdown = Times();
  const isFieldsDisabled = props.disabled;
  const [fields, setFields] = useState({});

  useEffect(() => {
    return () => props.onChangeTab(getValues());
  }, []);

  const validationSchema = Yup.object().shape(AvailabilityValidationSchema);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    register,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    if (data) {
      const availability = data;
      const fieldArrayData = Object.entries(availability).reduce(
        (acc, [day, timing]) => {
          let timings = [];
          if (timing?.timings?.length && Array.isArray(timing?.timings)) {
            timings = timing.timings.map((t) => ({
              opensAt: t.opensAt,
              closesAt: t.closesAt,
              opensDropdown: [...timerDropdown],
              closesDropdown: [...timerDropdown],
            }));
          } else if (typeof timing === "object") {
            timings = [
              {
                opensAt: timing?.opensAt,
                closesAt: timing?.closesAt,
                opensDropdown: [...timerDropdown],
                closesDropdown: [...timerDropdown],
              },
            ];
          }
          acc[day] = { timings, isActive: timing?.isActive };
          return acc;
        },
        {}
      );
      reset(fieldArrayData);
      setFields(fieldArrayData);
    }
  }, [data]);

  const onOpensChange = (e, day, idx) => {
    const updatedFields = { ...fields };
    const selectedTiming = updatedFields[day]?.timings?.[idx];
    if (selectedTiming) {
      const closesDropdown = getDDsForOpen(e);
      selectedTiming.closesDropdown = closesDropdown;
      selectedTiming.opensAt = e.target.value;
      selectedTiming.closesAt = closesDropdown[0]?.value || `00:00:00`;
      setFields(updatedFields);
    }
  };

  const onClosesChange = (e, day, idx) => {
    const updatedFields = { ...fields };
    const selectedTiming = updatedFields[day]?.timings?.[idx];
    if (selectedTiming) {
      const opensDropdown = getDDsForClose(e);
      selectedTiming.opensDropdown = opensDropdown;
      selectedTiming.closesAt = e.target.value;
      setFields(updatedFields);
    }
  };

  const getDDsForOpen = (e) => {
    let val = e.target.value;
    let closeTimerDropdown = timerDropdown;
    closeTimerDropdown.push({ value: `00:00:00`, text: `12:00 AM` });
    let timeIndex = closeTimerDropdown.findIndex((time) => time.value === val);
    return closeTimerDropdown.slice(timeIndex + 1, closeTimerDropdown.length);
  };

  const getDDsForClose = (e) => {
    let val = e.target.value;
    let timeIndex = timerDropdown.findIndex((time) => time.value === val);
    const finalTimeIndex = timeIndex == 0 ? timerDropdown.length : timeIndex;
    return timerDropdown.slice(0, finalTimeIndex);
  };

  const addTimings = (day) => {
    const newFields = { ...fields };
    const timings = newFields[day]?.timings || [];
    timings.push({
      opensAt: "00:00:00",
      closesAt: "00:00:00",
      opensDropdown: [...timerDropdown],
      closesDropdown: [...timerDropdown],
    });
    newFields[day] = { ...newFields[day], timings };
    setFields(newFields);
    setValue(`${day}.timings`, timings);
  };

  const removeTiming = (day, idx) => {
    const newFields = { ...fields };
    const timings = newFields[day]?.timings || [];
    timings.splice(idx, 1);
    newFields[day] = { ...newFields[day], timings };
    setFields(newFields);
    setValue(`${day}.timings`, timings);
  };

  const onSubmit = (data) => {
    props.onSave(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="basic__info__content">
          <div className="left-side-form-wrapper">
            <div className="main-heading operating-hours">
              <h3>
                {props?.text || "Operating Hours"}
                <span className="mendatory">*</span>
              </h3>
              <p>
                Which days and times would you like to receive orders from this
                brand?
              </p>
            </div>
            <div className="row no-gutters align-items-center">
              {Object.keys(fields).map((day) => (
                <div className="col-12 mb-4">
                  <div className="actions-wrapper">
                    <div>
                      <div className="switches right__switch mb-2">
                        <label className="switch">
                          <input
                            disabled={isFieldsDisabled}
                            type="checkbox"
                            {...register(`${day}.isActive`)}
                            key={day}
                          />
                          <span
                            className={`slider ${
                              isFieldsDisabled ? "form-field-disabled" : ""
                            } round`}
                          ></span>
                        </label>
                      </div>
                      <label htmlFor="" className="days">
                        {day}
                      </label>
                    </div>
                    <table>
                      <tbody>
                        {fields[day]?.timings &&
                          fields[day].timings.map((timing, idx) => (
                            <div className="timing__row">
                              <select
                                className={`form-control time-select ${
                                  errors?.monday?.opensAt ? "is-invalid" : ""
                                }`}
                                {...register(`${day}.timings[${idx}].opensAt`)}
                                onChange={(e) => onOpensChange(e, day, idx)}
                                disabled={isFieldsDisabled}
                              >
                                {timing?.opensDropdown &&
                                  timing.opensDropdown.map(
                                    (time, optionIdx) => (
                                      <option
                                        key={"monOp" + optionIdx}
                                        value={time.value}
                                      >
                                        {time.text}
                                      </option>
                                    )
                                  )}
                              </select>
                              <select
                                className={`form-control time-select ${
                                  errors?.monday?.closesAt ? "is-invalid" : ""
                                }`}
                                {...register(`${day}.timings[${idx}].closesAt`)}
                                onChange={(e) => onClosesChange(e, day, idx)}
                                disabled={isFieldsDisabled}
                              >
                                {timing?.closesDropdown &&
                                  timing.closesDropdown.map(
                                    (time, optionIdx) => (
                                      <option
                                        key={"monCl" + optionIdx}
                                        value={time.value}
                                      >
                                        {time.text}
                                      </option>
                                    )
                                  )}
                              </select>
                              {idx === fields[day].timings.length - 1 ? (
                                <button
                                  type="button"
                                  onClick={() => addTimings(day)}
                                  className="timings__icon mr-1"
                                  disabled={isFieldsDisabled}
                                >
                                  <span>
                                    <img src={plusIconRed} alt="add" />
                                  </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => removeTiming(day, idx)}
                                  className="timings__icon"
                                  disabled={isFieldsDisabled}
                                >
                                  <span>
                                    <img src={deleteIcon} alt="remove" />
                                  </span>
                                </button>
                              )}
                            </div>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal.Footer>
          {props.children}

          <button
            type="button"
            onClick={props.onHide}
            className="cancel-btn red-btn-ghost btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="red-btn next-btn btn"
            disabled={isLoading || !isValid}
          >
            {isLoading ? (
              <LoaderInline />
            ) : (
              <>
                Save and Continue
                <span className="icons-right">
                  <img src={rightArrowIcon} alt="arrow icon" />
                </span>
              </>
            )}
          </button>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export default MenuAvailabilityFormNew;
