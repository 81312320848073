import React, { useEffect } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import LoaderInline from "shared/loader/LoaderInline";
import deleteIcon from "assets/images/delete-icon.svg";
import plusIconRed from "assets/images/add-redIcon.svg";
import "./RecipeForm.scss";

const IngredientsForm = (props) => {
    const isLoading = props.loader;
    const data = props.initialData;
    const isDisabled = props.disabled;

    useEffect(() => {
        return () => props.onChangeTab(getValues());
    }, []);

    const optionsSchema = Yup.object().shape({
        id: Yup.number().default(0).required(),
        ingredient: Yup.string()
        .trim()
        .max(120, ({ max }) => `Name not more than ${max} characters`)
        .required("Please enter ingredient"),
        quantity: Yup.string()
        .trim()
        .max(120, ({ max }) => `Quantity not more than ${max} characters`)
        .required("Please enter quantity"),
    });

    const validationSchema = Yup.object().shape({
        recipeIngredients: Yup.array()
            .of(optionsSchema)
         .min(1, "Minimum one ingredient is required"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema)
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { errors, isValid },
    } = methods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "recipeIngredients",
        keyName: "ingId",
    });

    useEffect(() => {
        if (data) {
            reset({
                id: data?.id,
                setupInstructions: data?.setupInstructions,
                portion: data?.portion,
                yield: data?.yield,
                recipeIngredients: data?.recipeIngredients
            });
        }
        if(!data?.recipeIngredients) {
            append({ id: 0, ingredient: "", quantity: "" })
        }
    }, [data]);

    const onSubmit = (data) => {
        props.onSave(data);
    };

    const addIngredient = () => {
        append({ id: 0, ingredient: "", quantity: "" });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="createMenu__wrapper uploadAssets__wrapper basic__info__content CreateMenuModalContent__wrapper">
                    <div className="uploadAssets-wrapper">
                        <div className="row no-gutters">
                            <div className="col-md-12 mb-3 pb-3">
                                <label htmlFor="menuTitle">
                                    Recipe Title
                                </label>
                                <div className="ingredientform_recipe_title">{props?.recipeTitle || "NA"}</div>
                            </div>

                            <div className="col-md-12 mb-3 pb-3">
                                <label htmlFor="menuDescription">Setup
                                </label>
                                {isDisabled ? <div className="view_form_input">{data?.setupInstructions || "NA"}</div>
                                    : <textarea
                                        id="menuDescription"
                                        placeholder="e.g. Deep friend wiscosin cheese curds"
                                        rows="3"
                                        className={`form-control ${errors?.setupInstructions ? "is-invalid" : ""
                                            }`}
                                        {...register("setupInstructions")}
                                        maxLength="500"
                                        disabled={isDisabled}
                                    ></textarea>}
                                {errors?.setupInstructions?.message && (
                                    <div className="invalid-feedback d-block">
                                        {errors?.setupInstructions?.message}
                                    </div>
                                )}
                            </div>

                            <div className="row d-flex w-100 mb-3 pb-3">
                                <div className="col-md-6 pr-0">
                                    <label htmlFor="minOrderValue">Portion
                                    </label>
                                    {isDisabled ? <div className="view_form_input">{data?.portion || "NA"}</div>
                                        : <input
                                            type="text"
                                            id="minOrderValue"
                                            placeholder="e.g. 1 Serving"
                                            className={`form-control ${errors?.portion ? "is-invalid" : ""
                                                }`}
                                            {...register("portion")}
                                            disabled={isDisabled}
                                        />}
                                    {errors?.portion?.message && (
                                        <div className="invalid-feedback d-block">
                                            {errors?.portion?.message}
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6 pr-0">
                                    <label htmlFor="minOrderValue">Yield
                                    </label>
                                    {isDisabled ? <div className="view_form_input">{data?.yield || "NA"}</div>
                                        : <input
                                            type="text"
                                            id="minOrderValue"
                                            placeholder="e.g. 32 oz"
                                            className={`form-control ${errors?.yield ? "is-invalid" : ""
                                                }`}
                                            {...register("yield")}
                                            disabled={isDisabled}
                                        />}
                                    {errors?.yield?.message && (
                                        <div className="invalid-feedback d-block">
                                            {errors?.yield?.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="row w-100">
                                <div className="col-md-12 d-flex p-0">
                                    <div className="col-md-6">
                                        <label htmlFor="modifieroptions">
                                            Ingredient
                                            <span className="mendatory">*</span>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="modifieroptions">
                                            Unit
                                        </label>
                                    </div>
                                </div>
                                {fields &&
                                    fields.map((field, idx) => (
                                        <div className="col-md-12 pb-2 px-0 d-flex">
                                            <div className="col-md-6 pr-0">
                                                {isDisabled ? <div className="view_form_input">{field?.ingredient || "NA"}</div>
                                                    : <input
                                                        key={field.ingId}
                                                        type="text"
                                                        placeholder="e.g. Onions"
                                                        className={`form-control mb-0 ${errors?.recipeIngredients?.[idx]?.ingredient
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        {...register(`recipeIngredients.${idx}.ingredient`)}
                                                        disabled={isDisabled}
                                                    />}
                                                {errors?.recipeIngredients?.[idx]?.ingredient?.message && (
                                                    <div className="invalid-feedback d-block">
                                                        {errors?.recipeIngredients[idx].ingredient?.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6 d-flex pr-0">
                                                <div>
                                                    {isDisabled ? <div className="view_form_input">{field?.quantity || "NA"}</div>
                                                        : <input
                                                            key={field.ingId}
                                                            type="text"
                                                            placeholder="e.g. 32 oz"
                                                            className={`form-control mb-0 ${errors?.recipeIngredients?.[idx]?.quantity
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            {...register(`recipeIngredients.${idx}.quantity`)}
                                                            disabled={isDisabled}
                                                        />}
                                                {errors?.recipeIngredients?.[idx]?.quantity?.message && (
                                                    <div className="invalid-feedback d-block">
                                                        {errors?.recipeIngredients[idx].quantity?.message}
                                                    </div>
                                                )}
                                                </div>
                                                {idx !== 0 && (<button
                                                    type="button"
                                                    onClick={() => remove(idx)}
                                                    className="delete_ingredient"
                                                >
                                                    <span>
                                                        <img
                                                            src={deleteIcon}
                                                            className="icons"
                                                            alt="remove"
                                                        />
                                                    </span>
                                                </button>)}
                                            </div>
                                        </div>
                                    ))}
                                <div className="addingredients_options mt-3 pt-3 pl-3">
                                    <button
                                        type="button"
                                        onClick={addIngredient}
                                        className="addModifiers addIngredients-btn"
                                        disabled={isDisabled}
                                    >
                                        <span className="icons">
                                            <img src={plusIconRed} alt="add" />
                                        </span>
                                        Add Ingredients
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal.Footer>
                    {props.children}

                    <button
                        type="button"
                        onClick={props.onHide}
                        className="cancel-btn red-btn-ghost btn"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="red-btn next-btn btn"
                        disabled={isLoading || !isValid}
                    >
                        {isLoading ? (
                            <LoaderInline />
                        ) : (
                            <>
                                Save and Continue
                                <span className="icons-right">
                                    <img src={rightArrowIcon} alt="arrow icon" />
                                </span>
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </form>
        </FormProvider>
    );
};

export default IngredientsForm;
