import React, { useEffect, useState } from "react";
import KitchenRegister from "../kitchen-register/KitchenRegister";
import KitchenResend from "../kitchen-resend/KitchenResend";

const KitchenLayout = () => {
  const kitchenRegisterWorkflow = [
    { step: 1, component: KitchenRegister },
    { step: 2, component: KitchenResend },
  ];

  const [activeStep, setactiveStep] = useState(1);
  const [email, setemail] = useState(null);
  let ActiveComponent = kitchenRegisterWorkflow[activeStep - 1].component;

  useEffect(() => {
    changeComponent(activeStep);
  }, [activeStep]);

  const changeComponent = (step) => {
    ActiveComponent = kitchenRegisterWorkflow[step - 1].component;
  };

  const goToNextStep = () => {
    setactiveStep(activeStep + 1);
  };

  const goToPrevStep = () => {
    setactiveStep(activeStep - 1);
  };

  const setEmailAddress = (val) => {
    setemail(val);
  };

  return (
    <>
      {ActiveComponent && (
        <ActiveComponent
          email={email}
          nextStep={goToNextStep}
          prevStep={goToPrevStep}
          setEmail={setEmailAddress}
        />
      )}
    </>
  );
};

export default KitchenLayout;
