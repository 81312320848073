import React from "react";
import { Link } from "react-router-dom";
import "../ManageResturants/ManageModifierLandingpage.scss";
import "./ManageUserLandingPage.scss";
import searchIcon from "../../assets/images/icon-search.svg";
import plusIcon from "../../assets/images/add-icon-white.svg";
import noDeviceIcon from "../../assets/images/no-device-icon.svg";
import editIcon from "../../assets/images/edit-blue.svg";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";

const ManageDevicesLandingPage = () => {
  return (
    <div className="manageUser__landingPage__wrapper manageDevices__landingPage__wrapper">
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header">
            <div className="row justify-content-between">
              <div className="col-12 col-md-6 mb-md-3 mb-xl-0">
                <h1>Manage Devices</h1>
              </div>
              <div className="col-12 col-md-6">
                <form>
                  <div className="adduser__btn">
                    <Link to="/add-device" className="red-btn btn">
                      <span className="icons-left icons">
                        <img src={plusIcon} alt="add icon" />
                      </span>
                      Add New Device
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="managermodifier__noListing manageruser__noListing d-none">
            <div className="managermodifier__noListing__innerwrapper">
              <div className="content">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src={noDeviceIcon}
                    alt="no list icon"
                    width="185"
                  />
                </div>
                <div className="content-desc">
                  <h3>No devices are added</h3>
                  <p>Please click on “Add New Device” to create new devices.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="managermodifier__listinPage managerestra__listinPage ">
            <div className="managermodifier__listinPage__wrapper">
              <table className="table modifierListing__table">
                <thead>
                  <tr role="row" className="mat-header-row">
                    <th scope="col" className="mat-header-cell column-1">
                    Resturant Name
                    </th>
                    <th scope="col" className="mat-header-cell column-2">
                    Device
                    </th>
                    <th scope="col" className="mat-header-cell column-3">
                      Type
                    </th>
                    <th scope="col" className="mat-header-cell column-5">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/device-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICE">
                      <div>Samsung Tab S7</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Tablet
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/device-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICE">
                      <div>Samsung Tab S7</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Tablet
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/device-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICE">
                      <div>Samsung Tab S7</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Tablet
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/device-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICE">
                      <div>Samsung Tab S7</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Tablet
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/device-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICE">
                      <div>Samsung Tab S7</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Tablet
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="RESTURANT NAME">
                      <Link
                        to="/device-details"
                        className="modifierDeatil__link"
                      >
                        Kentucky Fried Chicken
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="DEVICE">
                      <div>Samsung Tab S7</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Tablet
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="table pagination">
                <ul className="pagination justify-content-center justify-content-lg-end">
                  <li className="page-item disabled">
                    <a
                      className="page-link first-child"
                      href="/"
                      tabIndex="-1"
                      aria-disabled="true"
                    >
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="/">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDevicesLandingPage;
