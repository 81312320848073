import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { MultiStepForm, Step } from "react-multi-form";
import { useMediaQuery } from "react-responsive";

import BasicInfo from "../basic-form/BasicInfo";
import backArrowIcon from "assets/images/back-arrow.svg";
import CreateMenu from "../menu-form/layout-wrapper/CreateMenu";
import "./CreateVirtualBrand.scss";
import AssetUpload from "../asset-form/AssetUpload";
import VirtualBrandContext from "store/virtual-brand-context";
import { Dropdown } from "react-bootstrap";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { changeBrandStatus, getVirtualBrand } from "services/api/brand";
import { BrandStatus } from "constants/brandStatus";
import toastify from "shared/toastr";
import ReviewModal from "UI/Modal/ReviewModal";
import VbComplete from "../thank-you-page/VbComplete";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import { RoleType, RoleTypeId } from "constants/roleType";
import UserContext from "store/user-context";
import LoaderFull from "shared/loader/LoaderFull";

const CreateVirtualBrand = (props) => {
  const location = useLocation();
  const history = useHistory();
  const userCtx = useContext(UserContext);
  const userInfo = userCtx.userInfo;
  const userRole = userCtx.userRole;
  const brandCtx = useContext(VirtualBrandContext);
  const queryParam = location.search;
  const brandIdParam = new URLSearchParams(location.search).get("id");
  const vbMode = location.hash.substring(1);

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const steps = [1, 2, 3, 4];
  const [activeStep, setActiveStep] = useState(1);
  const { brandId } = useParams("brandId");
  const { kitchenId } = useParams("kitchenId");

  const virtualBrandId = brandCtx.virtualBrandId;
  const virtualBrand = brandCtx.virtualBrandInfo;
  const currentVbMode = brandCtx.virtualBrandMode;

  const [isEdit, setisEdit] = useState(false);
  const [flag, setFlag] = useState(false);

  const [subHeaderTitle, setsubHeaderTitle] = useState(null);
  const [isReviewModal, setisReviewModal] = useState(false);
  const [reviewLoading, setreviewLoading] = useState(false);
  const [isSubmittedForReview, setisSubmittedForReview] = useState(false);

  const [fullLoading, setFullLoading] = useState(false);

  const [actionButtons, setActionButtons] = useState({
    draftButton: false,
    reviewButton: false,
    editVbButton: false,
  });

  useEffect(() => {
    if (virtualBrand) {
      if ([RoleType.KITCHEN_OWNER].includes(userRole)) {
        if (
          ![RoleTypeId.KITCHEN_OWNER].includes(virtualBrand.creatorRole) &&
          !virtualBrand.isAssociated
        ) {
          setActionButtons({
            draftButton: false,
            reviewButton: false,
            editVbButton: false,
          });
        } else {
          setActionButtons({
            draftButton: true,
            reviewButton: true,
            editVbButton: true,
          });
        }
      } 
      if ([RoleType.BRAND_OWNER].includes(userRole)) {
        if (virtualBrand?.createdBy !== userInfo?.userId) {
          setActionButtons({
            draftButton: false,
            reviewButton: false,
            editVbButton: false,
          });
        } else {
          setActionButtons({
            draftButton: true,
            reviewButton: true,
            editVbButton: true,
          });
        }
      } else {
        setActionButtons({
          draftButton: true,
          reviewButton: true,
          editVbButton: true,
        });
      }
    }
  }, [virtualBrand]);

  useEffect(() => {
    return () => {
      brandCtx.resetAllVariables();
    };
  }, []);

  useEffect(() => {
    if (kitchenId || brandId) {
      if (kitchenId) {
        brandCtx.saveAssociationKitchenId(+kitchenId);
      } else {
        brandCtx.saveAssociationKitchenId(null);
      }
      if (brandId) {
        setFlag(true);
        brandCtx.setVirtualBrandMode(VirtualBrandMode.EDIT);
      }
      if (brandId && kitchenId) {
        setFlag(true);
        brandCtx.setVirtualBrandMode(VirtualBrandMode.SHARED);
      }
      if (brandId && vbMode.toLowerCase() === "view") {
        setFlag(true);
        brandCtx.setVirtualBrandMode(VirtualBrandMode.VIEW);
      }
    } else {
      brandCtx.setVirtualBrandMode(VirtualBrandMode.ADD);
    }
  }, [brandId, kitchenId]);

  useEffect(() => {
    if (vbMode) {
      let mode = vbMode.toLowerCase();
      switch (mode) {
        case "view":
          brandCtx.setVirtualBrandMode(VirtualBrandMode.VIEW);
          changeSubHeaderText("");
          break;
        case "edit":
          brandCtx.setVirtualBrandMode(VirtualBrandMode.EDIT);
          changeSubHeaderText("Edit");
          break;
        case "shared-view":
          brandCtx.setVirtualBrandMode(VirtualBrandMode.VIEW);
          changeSubHeaderText("");
          break;
        case "shared-edit":
          brandCtx.setVirtualBrandMode(VirtualBrandMode.SHARED);
          changeSubHeaderText("Edit");
          break;
        default:
          brandCtx.setVirtualBrandMode(VirtualBrandMode.ADD);
          activeStep(1);
          changeSubHeaderText("Create");
          break;
      }
    }
  }, [vbMode]);

  useEffect(() => {
    if (queryParam) {
      let param = new URLSearchParams(location.search).get("step");
      if (param) {
        let stepNum = +param;
        if (stepNum > 0) {
          steps.includes(stepNum) ? setActiveStep(stepNum) : setActiveStep(1);
        }
      }
    }
  }, [queryParam]);

  useEffect(() => {
    if (brandId && currentVbMode) {
      getVirtualBrandInfo();
      setisEdit(true);
    }
  }, [brandId, currentVbMode]);

  useEffect(() => {
    if (!isEdit && brandIdParam) {
      brandCtx.saveBrandId(+brandIdParam);
    }
  }, [brandIdParam]);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkConfirmPage();
  }, [activeStep]);

  const getVirtualBrandInfo = async () => {
    setFullLoading(true);
    let mode = currentVbMode === VirtualBrandMode.VIEW;
    await getVirtualBrand(+brandId, mode)
      .then((res) => {
        brandCtx.setVirtualBrandInfo(res.data);
        setFullLoading(false);
      })
      .catch(() => {
        setFullLoading(false);
      });
  };

  const changeSubHeaderText = (mode) => {
    let prefix = mode ? mode + " " : "";
    let suffix = "Virtual Brand";
    let text = prefix + suffix;
    if ([3, 4].includes(activeStep)) {
      text = text + `${virtualBrand ? "- " + virtualBrand.name : ""}`;
    }
    setsubHeaderTitle(text);
  };

  const checkConfirmPage = () => {
    if (activeStep === 4) brandCtx.isItConfirmPage(true);
    else brandCtx.isItConfirmPage(false);
  };

  const goToNextStep = () => {
    let step = activeStep;
    if (steps.includes(activeStep)) step++;
    history.replace({
      pathname: window.location.pathname,
      search: `?step=${step}`,
      hash: window.location.hash,
    });
  };

  const goToPrevStep = () => {
    let step = activeStep;
    if (steps.includes(activeStep)) step--;
    history.replace({
      pathname: window.location.pathname,
      search: `?step=${step}`,
      hash: window.location.hash,
    });
  };

  const openReviewModal = () => setisReviewModal(true);
  const closeReviewModal = () => setisReviewModal(false);

  const onChangeStatus = async (statusId) => {
    setreviewLoading(true);
    await changeBrandStatus(virtualBrandId, statusId, +kitchenId)
      .then((res) => {
        setreviewLoading(false);
        closeReviewModal(false);
        toastify("success", res.message);
        if (statusId === BrandStatus.PENDING) {
          setisSubmittedForReview(true);
        } else {
          props.history.push("/dashboard");
        }
      })
      .catch(() => {
        setreviewLoading(false);
        closeReviewModal(false);
      });
  };

  const onEditVbButton = () => {
    history.push(history.location.pathname + "?step=4" + createEditLink());
  };

  const createEditLink = () => {
    let newHash = "";
    let hash = history.location.hash;
    if (hash) {
      if (hash.includes("view")) {
        newHash = hash.replace("view", "edit");
      }
    }
    return newHash;
  };

  return (
    <>
      {fullLoading ? (
        <LoaderFull />
      ) : (
        <>
          <Subheader title={isMobile ? null : subHeaderTitle}>
            <div
              className={`goBack mb-3 ${
                currentVbMode === VirtualBrandMode.VIEW ? "view-mode" : ""
              }`}
            >
              {(activeStep === 1 || flag === true) ? (
                <Link
                  to="/dashboard"
                  className="back-btn-red"
                  title="back to dashboard"
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="plus icon" />
                  </span>
                  {subHeaderTitle}
                </Link>
              ) : (
                <button
                  type="button"
                  className="back-btn-red"
                  onClick={goToPrevStep}
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="plus icon" />
                  </span>
                  {subHeaderTitle}
                </button>
              )}
            </div>
          </Subheader>

          {isSubmittedForReview ? (
              <VbComplete
                heading={"You have successfully submitted your menu for review."}
                subHeading={"Please click on “Go to My Dashboard” and you will be redirected to your Virtual Brand’s Listings Page"}
                buttonText={"Go to My Dashboard"}
                linkText={"/dashboard"}
              />
          ) : (
            <div className="CreateVirtualBrand__wrapper">
              <div className="container">
                <div
                  className={`goBack ${
                    currentVbMode !== VirtualBrandMode.ADD  ? "view-mode" : ""
                  }`}
                >
                  {(activeStep === 1 || flag === true) ? (
                    <Link
                      to="/dashboard"
                      className="back-btn-red"
                      title="back to dashboard"
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="plus icon" />
                      </span>
                      Back
                    </Link>
                  ) : (
                    <button
                      type="button"
                      className="back-btn-red"
                      onClick={goToPrevStep}
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="plus icon" />
                      </span>
                      Back
                    </button>
                  )}
                </div>
                { currentVbMode !== VirtualBrandMode.ADD ? (
                  <>
                    {activeStep === 1 && <BasicInfo nextStep={goToNextStep} />}
                    {activeStep === 2 && (
                      <AssetUpload nextStep={goToNextStep} />
                    )}
                    {activeStep === 3 && (
                      <>
                        <div className="confirm_page_subheader">
                          <h2>Create Menu</h2>
                        </div>
                        <CreateMenu nextStep={goToNextStep} />
                      </>
                    )}
                    {activeStep === 4 && (
                      <>
                        <div className="confirm_page_header">
                          <div className="row align-items-lg-end">
                            <div className="col-12 col-md-6 col-lg-6">
                              {[
                                VirtualBrandMode.ADD
                              ].includes(currentVbMode) ? (
                                <>
                                  <h1>Confirm</h1>
                                  <p>
                                    We’re currently setting up your account and
                                    will notify you when its ready.
                                  </p>
                                </>
                              ) : (
                                <h1>{virtualBrand?.name || "Virtual Restaurant"}</h1>
                              )}
                            </div>
                            <div className={`col-12 col-md-6 col-lg-6`}>
                              <div className="savedraft__wrapper pb-lg-0">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="saveAsDraft__btn"
                                  >
                                    Choose an option
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        onChangeStatus(BrandStatus.DRAFT)
                                      }
                                      disabled={!actionButtons.draftButton}
                                    >
                                      Save as Draft
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={openReviewModal}
                                      disabled={!actionButtons.reviewButton}
                                    >
                                      Submit for Review
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={onEditVbButton}
                                      disabled={!actionButtons.editVbButton}
                                    >
                                      Edit Virtual Brand
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                         {[
                          VirtualBrandMode.ADD,
                        ].includes(currentVbMode) && (
                          <div className="confirm_page_subheader">
                            <h2>{virtualBrand?.name || "Virtual Restaurant"}</h2>
                          </div>
                        )}
                        {activeStep === 4 && (
                          <CreateMenu nextStep={goToNextStep} />
                        )}
                        <div className="savedraft__wrapper">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="saveAsDraft__btn"
                            >
                              Choose an option
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  onChangeStatus(BrandStatus.DRAFT)
                                }
                                disabled={!actionButtons.draftButton}
                              >
                                Save as Draft
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={openReviewModal}
                                disabled={!actionButtons.reviewButton}
                              >
                                Submit for Review
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={onEditVbButton}
                                disabled={!actionButtons.editVbButton}
                              >
                                Edit Virtual Brand
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="step__wrapper">
                    <MultiStepForm activeStep={activeStep}>
                      <Step label="Basic Info">
                        <div className="mobile-steps d-md-none">
                          <p>Step 01 of 04</p>
                        </div>
                        {activeStep === 1 && (
                          <BasicInfo nextStep={goToNextStep} />
                        )}
                      </Step>
                      <Step label="Upload Assets">
                        <div className="mobile-steps d-md-none">
                          <p>Step 02 of 04</p>
                        </div>
                        {activeStep === 2 && (
                          <AssetUpload nextStep={goToNextStep} />
                        )}
                      </Step>
                      <Step label="Create Menu">
                        <div className="mobile-steps d-md-none">
                          <p>Step 03 of 04</p>
                        </div>
                        <div className="confirm_page_subheader">
                          <h2>Create Menu</h2>
                        </div>
                        {activeStep === 3 && (
                          <CreateMenu nextStep={goToNextStep} />
                        )}
                      </Step>
                      <Step label="Confirm">
                        <div className="mobile-steps d-md-none">
                          <p>Step 04 of 04</p>
                        </div>
                        <div className="confirm_page_header">
                          <div className="row align-items-lg-end">
                            <div className="col-12 col-md-6 col-lg-6">
                              {[
                                VirtualBrandMode.VIEW,
                                VirtualBrandMode.SHARED,
                              ].includes(currentVbMode) ? (
                                <h1>{virtualBrand?.name || "Virtual Restaurant"}</h1>
                              ) : (
                                <>
                                  <h1>Confirm</h1>
                                  <p>
                                    We’re currently setting up your account and
                                    will notify you when its ready.
                                  </p>
                                </>
                              )}
                            </div>
                            <div className={`col-12 col-md-6 col-lg-6`}>
                              <div className="savedraft__wrapper pb-lg-0">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="saveAsDraft__btn"
                                  >
                                    Choose an option
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        onChangeStatus(BrandStatus.DRAFT)
                                      }
                                      disabled={!actionButtons.draftButton}
                                    >
                                      Save as Draft
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={openReviewModal}
                                      disabled={!actionButtons.reviewButton}
                                    >
                                      Submit for Review
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={onEditVbButton}
                                      disabled={!actionButtons.editVbButton}
                                    >
                                      Edit Virtual Brand
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        {![
                          VirtualBrandMode.VIEW,
                          VirtualBrandMode.SHARED,
                        ].includes(currentVbMode) && (
                          <div className="confirm_page_subheader">
                            <h2>{virtualBrand?.name || "Virtual Restaurant"}</h2>
                          </div>
                        )}
                        {activeStep === 4 && (
                          <CreateMenu nextStep={openReviewModal} isPrimary={true} />
                        )}
                        {/* <div className="savedraft__wrapper">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="saveAsDraft__btn"
                            >
                              Choose an option
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  onChangeStatus(BrandStatus.DRAFT)
                                }
                                disabled={!actionButtons.draftButton}
                              >
                                Save as Draft
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={openReviewModal}
                                disabled={!actionButtons.reviewButton}
                              >
                                Submit for Review
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={onEditVbButton}
                                disabled={!actionButtons.editVbButton}
                              >
                                Edit Virtual Brand
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                      </Step>
                    </MultiStepForm>
                  </div>
                )}
              </div>
            </div>
          )}

          {isReviewModal && (
            <ReviewModal
              show={isReviewModal}
              onCancel={closeReviewModal}
              onSubmit={() => onChangeStatus(BrandStatus.PENDING)}
              onLoading={reviewLoading}
            />
          )}
        </>
      )}
    </>
  );
};

export default withRouter(CreateVirtualBrand);
