import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import PropTypes from "prop-types";

import "../preloginstyle.scss";
import preLoginLogo from "assets/images/presigninlogo.svg";
import { Global } from "constants/global";
import { resendOtp, validateOtp } from "services/api/auth";
import LoaderInline from "shared/loader/LoaderInline";
import toastify from "shared/toastr";
import LoaderFull from "shared/loader/LoaderFull";

const Otp = (props) => {
  const email = props.email;
  const otpLength = Global.otpLength;
  const otpPlaceholder = Global.otpMask;
  const timing = Global.resendCodeTimer;
  const [otp, setotp] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const [isResending, setisResending] = useState(false);
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(true);

  useEffect(() => {
    const timer = startTimer();
    return () => clearTimeout(timer);
  }, [isResendCodeDisabled]);

  const handleOtpChange = (val) => setotp(val);
  const goToBack = () => props.prevStep();
  const clearOtp = () => setotp("");

  const startTimer = () =>
    setTimeout(() => {
      changeVisibilityOfResendCode(false);
    }, timing);

  const changeVisibilityOfResendCode = (isHide) =>
    setIsResendCodeDisabled(isHide);

  const onResendCode = async () => {
    setisResending(true);
    await resendOtp(email).then(
      (res) => {
        clearOtp();
        changeVisibilityOfResendCode(true);
        toastify("success", res.message);
        setisResending(false);
      },
      () => setisResending(false)
    );
  };

  const onValidateCode = async (ev) => {
    ev.preventDefault();
    setisSubmitting(true);
    await validateOtp(email, otp).then(
      (res) => {
        toastify("success", res.message);
        setisSubmitting(false);
        props.nextStep();
      },
      (_) => {
        clearOtp();
        setisSubmitting(false);
      }
    );
  };

  return (
    <>
      {isResending && <LoaderFull />}

      <div className="prelogin__wrapper">
        <div className="inner__wrapepr">
          <div className="prelogin__logo">
            <img src={preLoginLogo} alt="pre login logo" />
          </div>
          <div className="prelogin__heading">
            <h1>Enter OTP</h1>
            <div className="prelogin__subtext">
              <p>
                Please enter OTP which we have sent on your email address. Then
                you can reset your password.
              </p>
            </div>
          </div>
          <div className="preloginForm__wrapper otp__form">
            <form>
              <div className="form-row no-gutters">
                <div className="col-md-12 mb-3 pb-3">
                  <div className="otp__field__wrapper">
                    <OtpInput
                      onChange={handleOtpChange}
                      value={otp}
                      numInputs={otpLength}
                      placeholder={otpPlaceholder.repeat(otpLength)}
                      separator={<span> </span>}
                      containerStyle={"otpInput__wrap"}
                      inputStyle={"otpInput__box"}
                      isInputNum
                      shouldAutoFocus
                      hasErrored
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 action__wrapper">
                  <button
                    type="submit"
                    className="prelogin-links-btn"
                    onClick={onValidateCode}
                    disabled={otp.length !== otpLength || isSubmitting}
                  >
                    {isSubmitting ? <LoaderInline /> : "Verify OTP"}
                  </button>
                </div>
                <div className="col-md-12 mb-3 action__wrapper">
                  <button
                    type="button"
                    onClick={goToBack}
                    className="prelogin-links-btn prelogin-links-outline-btn"
                  >
                    Go Back
                  </button>
                </div>
                <div className="col-md-12 mb-3 text-center">
                  <a
                    href
                    type="button"
                    onClick={onResendCode}
                    className={`prelogin-links ${
                      isResendCodeDisabled ? "disable-link" : "enable-link"
                    }`}
                    title={
                      isResendCodeDisabled
                        ? `Wait for ${timing / 1000} seconds`
                        : ""
                    }
                  >
                    Resend Code
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

Otp.propTypes = {
  email: PropTypes.string.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default Otp;
