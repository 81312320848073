import { BlobServiceClient } from "@azure/storage-blob";
import { AssetType } from "constants/assetType";
import { getSasToken } from "services/api/auth";
import { v4 as uuidv4 } from "uuid";
import toastify from "shared/toastr";

const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
const logoContainerName = process.env.REACT_APP_AZURE_CONTAINER_NAME_LOGO;
const coverContainerName = process.env.REACT_APP_AZURE_CONTAINER_NAME_COVER;

const storageName = process.env.REACT_APP_AZURE_STORAGE_NAME;

const getContainerName = (assetType) => {
  switch (assetType) {
    case AssetType.LOGOIMAGE:
      return logoContainerName;
    case AssetType.COVERIMAGE:
      return coverContainerName;
    default:
      return containerName;
  }
};

const createBlobInContainer = async (containerClient, { file, fileName }) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(fileName);

  // set mimetype as determined from browser with file upload control
  const options = {
    blobHTTPHeaders: { blobContentType: file.type },
  };

  // upload file
  await blobClient.uploadBrowserData(file, options);
};

const getImageUrl = (fileName, assetType) => {
  let containerName = getContainerName(assetType);
  let filePath = `https://${storageName}.blob.core.windows.net/${containerName}/${fileName}`;
  return { filePath, fileName };
};

const uploadFileToBlob = async (file, assetType) => {
  if (!file) return null;
  // change name of the file
  let fileName = uuidv4();

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  let token = null;
  try {
    let hit = await getSasToken();
    token = hit.data;
  } catch (error) {
    return;
  }
  const azureUrl = `https://${storageName}.blob.core.windows.net/?${token}`;

  const blobService = new BlobServiceClient(azureUrl);

  // get Container - full public read access
  let actualContainerName = getContainerName(assetType);
  const containerClient = blobService.getContainerClient(actualContainerName);

  // upload file
  try {
    await createBlobInContainer(containerClient, { file, fileName });
    return getImageUrl(fileName, assetType);
  } catch (error) {
    console.log("error cannot upload file to blob", error);
    toastify("error", error.message);
    //return null;
  }
};

export default uploadFileToBlob;
