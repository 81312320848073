import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "../view/ManageResturantsDetails.scss";
import "./EditRestaurant.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import LoaderInline from "shared/loader/LoaderInline";
import LoaderFull from "shared/loader/LoaderFull";
import toastify from "shared/toastr";
import { getStaff, addStaff, editStaff, getStates } from "services/api/staff";
import { RoleType, RoleTypeId, RoleTypeDropdown } from "constants/roleType";
import SelectDropdown from "UI/Select-Dropdown/SelectDropdown";
import { SelectDropdownSearch } from "UI/Select-Dropdown/SelectDropdownSearch";
import UserContext from "store/user-context";
import { getActiveKitchens } from "services/api/kitchen";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const roleMapper = (list) => {
  return list.map((res) => ({
    value: res.value,
    label: res.text,
  }));
};

const StaffForm = (props) => {
  const userCtx = useContext(UserContext);

  const [roles, setRoles] = useState(roleMapper(RoleTypeDropdown));
  const { userId } = useParams();

  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const userRole = userCtx.userRole;

  const [states, setStates] = useState([]);
  const [isStatesLoading, setisStatesLoading] = useState(false);

  const [kitchens, setkitchens] = useState(null);
  const [showKitchens, setshowKitchens] = useState(false);
  const [selectedKitchen, setselectedKitchen] = useState(null);
  const [selectedRole, setselectedRole] = useState(null);

  useEffect(() => {
    if (userRole) checkRolesToShow();
  }, [userRole]);

  useEffect(() => {
    if (userId) {
      setisEditMode(true);
      getDetail(userId);
    }
  }, [userId]);

  useEffect(() => {
    getStatesForDD();
    getKitchensForDD();
  }, []);

  const checkRolesToShow = () => {
    if (userRole === RoleType.ADMIN || userRole === RoleType.ADMIN_STAFF) {
      setRoles(roleMapper(RoleTypeDropdown));
    }
    if (
      userRole === RoleType.KITCHEN_OWNER ||
      userRole === RoleType.KITCHEN_STAFF
    ) {
      let kitchenRoles = RoleTypeDropdown.filter((role) => {
        if (
          role.value === RoleTypeId.KITCHEN_OWNER ||
          role.value === RoleTypeId.KITCHEN_STAFF
        )
          return role;
        else return null;
      });
      setRoles(roleMapper(kitchenRoles));
      setshowKitchens(true);
    }
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    fullName: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(50, ({ max }) => `Business name not more than ${max} characters`)
      .required("Please enter business name"),
    roleId: Yup.number().default(null),
    kitchenId: Yup.number().default(0),
    phone: Yup.string()
      .transform((value) => (value ? value.replace(/\D/g, "") : ""))
      .test("len", "Phone number must be exactly 10 characters long",
      (value) => value && value.length === 10)
      .required("Please enter the phone number"),
    email: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .email("Please enter a valid email address")
      .max(50)
      .required("Please enter a valid email address"),
    addressOne: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(250, ({ max }) => `Address line 1 not more than ${max} characters`)
      .required("Please enter the address line 1"),
    addressTwo: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(250, ({ max }) => `Address line 2 not more than ${max} characters`),
    city: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(50, ({ max }) => `City not more than ${max} characters`),
    stateId: Yup.number().default(0),
    zip: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .test(
        "len",
        "Zip must be exactly 5 characters long",
        (value) =>
          value === "" ||
          value === undefined ||
          value === null ||
          (value && value.length === 5)
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onRoleSelection = (selected) => {
    setshowKitchens(
      +selected === RoleTypeId.KITCHEN_OWNER ||
        +selected === RoleTypeId.KITCHEN_STAFF
    );
    setValue("roleId", +selected);
    setselectedRole(+selected);
  };

  const getKitchensForDD = async () => {
    await getActiveKitchens().then((res) => {
      let data = res.data;
      if (data && data.length) {
        let kitchens = data.map((res) => ({ value: res.id, label: res.name }));
        setkitchens(kitchens);
      }
      setisStatesLoading(false);
    });
  };

  const getStatesForDD = async () => {
    setisStatesLoading(true);
    await getStates()
      .then((res) => {
        let data = res.data;
        if (data && data.length) {
          let states = data.map((res) => ({ value: res.id, text: res.name }));
          setStates(states);
        }
        setisStatesLoading(false);
      })
      .catch(() => setisStatesLoading(false));
  };

  const getDetail = async (id) => {
    setisLoading(true);
    await getStaff(id)
      .then((res) => {
        let data = res.data;
        reset({
          id: data?.userId,
          fullName: data?.name,
          phone: data.contact,
          email: data?.email,
          addressOne: data?.addressOne,
          addressTwo: data?.addressTwo,
          city: data?.city,
          stateId: "" + data?.stateId,
          zip: data?.zip,
          roleId: data?.roleId,
          kitchenId: data?.kitchenId,
        });
        onRoleSelection(data?.roleId);
        setselectedKitchen(data?.kitchenId);
        setselectedRole(data?.roleId);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.push("/staff");
  };

  const onSubmit = async (data) => {
    setisSubmitting(true);

    if (isEditMode) {
      await editStaff(data)
        .then((res) => {
          toastify("success", res.message);
          setisSubmitting(false);
          onBack();
        })
        .catch(() => {
          setisSubmitting(false);
        });
    } else {
      await addStaff(data)
        .then((res) => {
          toastify("success", res.message);
          setisSubmitting(false);
          onBack();
        })
        .catch(() => {
          setisSubmitting(false);
        });
    }
  };

  const onKitchenValueSelected = (val) => {
    setValue("kitchenId", val.value);
    setselectedKitchen(val.value);
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="users__wrapper">
        <div className="modifierDetails__wrapper modifierEdit__wrapper">
          {isLoading && <LoaderFull />}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="goBack">
                  <button onClick={onBack} className=" back-btn-red">
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="main_heading">
                  <h1>{isEditMode ? "Edit" : "Add"} User</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="details__wrapper editModifier__wrapper">
                  <div className="details__wrapper__inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="basic__info__content">
                        <div className="form-row">
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="fullname">
                              Full Name
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="fullname"
                              placeholder="e.g. David"
                              maxLength="50"
                              {...register("fullName")}
                              className={`form-control ${
                                errors.fullName ? "is-invalid" : ""
                              }`}
                            />
                            {errors.fullName?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.fullName?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label>
                              User Type<span className="mendatory">*</span>
                            </label>
                            <div className="user__custom__select">
                              {roles && (
                                <SelectDropdown
                                  options={roles}
                                  placeholder="Please select a role"
                                  selectedValue={(val) =>
                                    onRoleSelection(val.value)
                                  }
                                  value={selectedRole}
                                />
                              )}
                            </div>
                          </div>

                          {showKitchens ? (
                            <div className="col-md-12 mb-3 pb-3 px-0">
                              <label>
                                Restaurant Associated
                                <span className="mendatory">*</span>
                              </label>
                              {kitchens && (
                                <SelectDropdownSearch
                                  options={kitchens}
                                  placeholder="No Restaurant Selected"
                                  selectedValue={onKitchenValueSelected}
                                  value={selectedKitchen}
                                  searchPlaceholder="Search Restaurant"
                                />
                              )}
                            </div>
                          ) : null}

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="contactno">
                              Contact No
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="contactno"
                              placeholder="e.g. 234 567 8907"
                              {...register("phone")}
                              className={`form-control ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                            />
                            {errors.phone?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.phone?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="accounemail">
                              Account Email
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="accounemail"
                              placeholder="Enter your email address"
                              maxLength="50"
                              {...register("email")}
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                            />
                            {errors.email?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.email?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="addressline1">
                              Address Line 1<span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="addressline1"
                              placeholder="e.g. Address line"
                              maxLength="250"
                              {...register("addressOne")}
                              className={`form-control ${
                                errors.addressOne ? "is-invalid" : ""
                              }`}
                            />
                            {errors.addressOne?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.addressOne?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="addressline2">Address Line 2</label>
                            <input
                              type="text"
                              id="addressline2"
                              placeholder="e.g. Address line"
                              maxLength="250"
                              {...register("addressTwo")}
                              className={`form-control ${
                                errors.addressTwo ? "is-invalid" : ""
                              }`}
                            />
                            {errors.addressTwo?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.addressTwo?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-md-6 mb-3 pb-3 px-0 pr-md-2 pl-md-0">
                            <label htmlFor="city">City</label>
                            <input
                              type="text"
                              id="city"
                              placeholder="e.g. Address line"
                              maxLength="50"
                              {...register("city")}
                              className={`form-control ${
                                errors.city ? "is-invalid" : ""
                              }`}
                            />
                            {errors.city?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.city?.message}
                              </div>
                            )}
                          </div>

                          {/* TODO: Need to add react-select */}
                          <div className="col-12 col-md-6 mb-3 pb-3 px-0 pl-md-2 pr-md-0">
                            <label>State</label>
                            {states && states.length ? (
                              <select
                                className={`form-control ${
                                  errors?.stateId ? "is-invalid" : ""
                                }`}
                                {...register("stateId")}
                              >
                                <option value={0}>Select State...</option>
                                {states &&
                                  states.map((state, idx) => (
                                    <option key={idx} value={state?.value}>
                                      {state?.text}
                                    </option>
                                  ))}
                              </select>
                            ) : null}
                          </div>

                          <div className="col-md-12 mb-3 pb-lg-3 px-0">
                            <label htmlFor="zip">Zip</label>
                            <input
                              type="text"
                              id="zip"
                              placeholder="e.g. 123456"
                              maxLength="5"
                              {...register("zip")}
                              className={`form-control ${
                                errors.zip ? "is-invalid" : ""
                              }`}
                            />
                            {errors.zip?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.zip?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 px-0">
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={onBack}
                              className="cancel-btn red-btn-ghost btn"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="red-btn next-btn btn"
                              disabled={!isValid || isSubmitting}
                            >
                              {isSubmitting ? (
                                <LoaderInline />
                              ) : (
                                <>{userId ? "Save " : "Add "}User</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffForm;
