import React, { useContext, useEffect, useState } from "react";

import { Tab, Nav } from "react-bootstrap";

import AddButton from "UI/Button/AddButton";
import "./CreateMenuTabs.scss";
import MainMenu from "../menu-card/MainMenu";
import MenuModal from "../menu-modal/MenuModal";
import { getBrandMenus } from "services/api/menu";
import VirtualBrandContext from "store/virtual-brand-context";
import NoMenuCard from "./NoMenuCard";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import { VirtualBrandMode } from "constants/virtualBrandMode";

function CreateMenuTabs() {
  const brandCtx = useContext(VirtualBrandContext);
  const brandId = brandCtx.virtualBrandId;
  const modalStatus = brandCtx.menuModalStatus;
  const brandMode = brandCtx.virtualBrandMode;
  const kitchenId = brandCtx.associationKitchenId;

  const [activeTab, setactiveTab] = useState(1);

  const [menus, setMenus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (brandId) getMenus();
  }, [brandId]);

  useEffect(() => {
    if (modalStatus === false) getMenus();
  }, [modalStatus]);

  const showModal = () => {
    brandCtx.menuModalStatusChange(true);
  };

  const closeModal = () => {
    brandCtx.menuModalStatusChange(false);
  };

  const getMenus = async () => {
    setIsLoading(true);
    await getBrandMenus(brandId, kitchenId)
      .then((res) => {
        setIsLoading(false);
        setMenus(res.data);
        if (res.data && res.data?.length) changeStep(0, res.data[0]);
      })
      .catch(() => {
        setIsLoading(false);
        setMenus(null);
      });
  };

  const changeStep = (tabIndex, menu) => {
    setactiveTab(tabIndex);
    brandCtx.saveMenuId(menu.id);
  };

  return (
    <>
      {isLoading ? (
        <>
          <ShimmerLoader />
        </>
      ) : (
        <div className="createMenuTbs__wrapper">
          <Tab.Container
            mountOnEnter
            unmountOnExit
            id="myTab"
            activeKey={`tab-${activeTab}`}
          >
            <div className="flex-container">
              <Nav className="virtualBrandsTabs nav-tabs">
                {menus && menus.length
                  ? menus.map((menu, idx) => (
                      <Nav.Item key={idx}>
                        <Nav.Link
                          eventKey={`tab-${idx}`}
                          onClick={() => changeStep(idx, menu)}
                        >
                          {menu.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  : null}
              </Nav>
              <div className="addMenubuttonwrapper no__wrap mb-2">
                {brandMode === VirtualBrandMode.SHARED ||
                brandMode === VirtualBrandMode.VIEW ? null : (
                  <AddButton title={"Add New Menu"} openModal={showModal} />
                )}
                <MenuModal
                  show={modalStatus}
                  className="right"
                  onHide={closeModal}
                />
              </div>
            </div>
            <Tab.Content>
              {menus && menus.length ? (
                menus.map((menu, idx) => (
                  <Tab.Pane
                    key={idx}
                    mountOnEnter
                    unmountOnExit
                    eventKey={`tab-${idx}`}
                  >
                    <MainMenu {...menu} onReload={getMenus}>
                      {brandMode === VirtualBrandMode.SHARED ||
                      brandMode === VirtualBrandMode.VIEW ? null : (
                        <AddButton
                          title={"Add New Menu"}
                          openModal={showModal}
                        />
                      )}
                    </MainMenu>
                  </Tab.Pane>
                ))
              ) : (
                <>
                  <NoMenuCard />
                  <div className="addmenu-mobile d-lg-none">
                    {brandMode === VirtualBrandMode.SHARED ||
                    brandMode === VirtualBrandMode.VIEW ? null : (
                      <AddButton title={"Add New Menu"} openModal={showModal} />
                    )}
                  </div>
                </>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </>
  );
}

export default CreateMenuTabs;
