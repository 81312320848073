import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { MultiStepForm, Step } from "react-multi-form";
import { useMediaQuery } from "react-responsive";
import RecipeGuideBasicInfo from "../../recipeguide/recipeguide-form/RecipeGuideBasicInfo";
import CreateRecipes from "../../recipeguide/layout-wrapper/CreateRecipes";
import UploadAttachments from "../../recipeguide/layout-wrapper/UploadAttachments";
import backArrowIcon from "assets/images/back-arrow.svg";
import "pages/create-brand-form/layout-wrapper/CreateVirtualBrand.scss";
import { Dropdown } from "react-bootstrap";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { GetRecipeGuideBasicInfo } from "services/api/recipeguide";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import LoaderFull from "shared/loader/LoaderFull";
import RecipeGuideContext from "store/recipe-guide-context";
import VbComplete from "../../../pages/create-brand-form/thank-you-page/VbComplete";
import { RoleType } from "constants/roleType";
import UserContext from "store/user-context";

const CreateRecipeGuide = (props) => {
  const location = useLocation();
  const history = useHistory();
  const queryParam = location.search;
  const recipeGuideMode = location.hash.substring(1);
  const recipeguideCtx = useContext(RecipeGuideContext);
  const recipeGuideTitle = recipeguideCtx.selectedRecipeGuideName;

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const steps = [1, 2, 3, 4];
  const [activeStep, setActiveStep] = useState(1);
  const currentVbMode = recipeguideCtx.recipeGuideMode;
  const menuItemId = recipeguideCtx.virtualBrandMenuItemId
  const { recipeGuideId } = useParams("recipeGuideId");
  const recipeGuide = recipeguideCtx.recipeGuideInfo;
  //const brandId = recipeguideCtx.virtualBrandId;
  const { brandId } = useParams("brandId");
  const [isEdit, setisEdit] = useState(false);
  const [subHeaderTitle, setsubHeaderTitle] = useState(null);
  const [fullLoading, setFullLoading] = useState(false);
  const [isConfirm, setisConfirm] = useState(false);
  const vbName = recipeguideCtx.virtualBrandName;
  const userCtx = useContext(UserContext);
  const role =  userCtx?.userInfo?.role;

  useEffect(() => {
      let mode = recipeGuideMode.toLowerCase();
      switch (mode) {
        case "view":
          recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.VIEW);
          changeSubHeaderText("");
          break;
        case "edit":
          recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.EDIT);
          changeSubHeaderText("Edit");
          break;
          case "add":
            recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.ADD);
            changeSubHeaderText("Create");
            break;
        default:
          recipeguideCtx.setRecipeGuideMode(VirtualBrandMode.VIEW);
          changeSubHeaderText("");
          break;
      }
  }, [recipeGuideMode, vbName]);

  useEffect(() => {
    return () => {
      recipeguideCtx.resetAllVariables();
    };
  }, []);

  useEffect(() => {
    if (queryParam) {
      let param = new URLSearchParams(location.search).get("step");
      if (param) {
        let stepNum = +param;
        if (stepNum > 0) {
          steps.includes(stepNum) ? setActiveStep(stepNum) : setActiveStep(1);
        }
      }
    }
  }, [queryParam]);

  useEffect(() => {
    if (brandId) {
      getRecipeGuideInfo();
      setisEdit(true);
    }
  }, [brandId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkConfirmPage();
  }, [activeStep]);

  const getRecipeGuideInfo = async () => {
    await GetRecipeGuideBasicInfo(brandId, menuItemId, recipeGuideId)
      .then((res) => {
        if (res.data?.id) {
          recipeguideCtx.setRecipeGuideInfo(res.data);
        }
        recipeguideCtx.saveVirtualBrandInfo({ id: brandId, name: res.data?.virtualBrandName });
      /*   recipeguideCtx.saveRecipeGuideId(res.data?.id); */
        setFullLoading(false);
      })
      .catch(() => {
        setFullLoading(false);
      });
  } 

  const changeSubHeaderText = (mode) => {
    let prefix = mode ? mode + " " : "";
    let suffix = "Recipe Guide";
    let text = vbName + " - " + prefix + suffix;
    setsubHeaderTitle(text);
  };

  const checkConfirmPage = () => {
    if ([2,4].includes(activeStep) || ![VirtualBrandMode.ADD].includes(currentVbMode)) 
    recipeguideCtx.isItConfirmPage(true);
    else recipeguideCtx.isItConfirmPage(false);
  };

  const goToNextStep = (recipeGuideId) => {
    let step = activeStep;
    if (steps.includes(activeStep)) step++;
    history.replace({
      pathname: `/recipeguide/${brandId}/${recipeGuideId}`,
      search: `?step=${step}`,
      hash: window.location.hash,
    });
  };

  const goToPrevStep = () => {
    let step = activeStep;
    if (steps.includes(activeStep)) step--;
    history.replace({
      pathname: window.location.pathname,
      search: `?step=${step}`,
      hash: window.location.hash,
    });
  };

  const onConfirm = () => setisConfirm(true);

  const onEditRgButton = () => {
    history.push(history.location.pathname + createEditLink());
  };

  const createEditLink = () => {
    let newHash = "";
    let hash = history.location.hash;
    if (hash) {
      if (hash.includes("view")) {
        newHash = hash.replace("view", "edit");
      }
    }
    return newHash;
  };

  return (
    <>
      {fullLoading ? (
        <LoaderFull />
      ) : (
        <>
          {role !== RoleType.KITCHEN_STAFF && <Subheader title={isMobile ? null : subHeaderTitle}>
            <div
              className={`goBack mb-3 ${
                currentVbMode === VirtualBrandMode.VIEW ? "view-mode" : ""
              }`}
            >
              {(activeStep === 1) ? (
                <Link
                  to={`/recipeguide/list/${brandId}`}
                  className="back-btn-red"
                  title="back to dashboard"
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="plus icon" />
                  </span>
                  {subHeaderTitle}
                </Link>
              ) : (
                <button
                  type="button"
                  className="back-btn-red"
                  onClick={goToPrevStep}
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  {subHeaderTitle}
                </button>
              )}
            </div>
          </Subheader>}

            {isConfirm ? (
              <VbComplete
                heading={"You have successfully created a Recipe Guide."}
                subHeading={"Please click on “Go to My Recipe Guides” and you will be redirected to your Recipe Guide’s Listings Page"}
                buttonText={"Go to My Recipe Guides"}
                linkText={`/recipeguide/list/${brandId}`}
              />
            ) : (
            <div className="CreateVirtualBrand__wrapper">
              <div className="container">
                {role !== RoleType.KITCHEN_STAFF && <div
                  className={`goBack ${
                    currentVbMode !== VirtualBrandMode.ADD  ? "view-mode" : ""
                  }`}
                >
                  {(activeStep === 1) ? (
                        <button
                          type="button"
                          className="back-btn-red"
                          onClick={() => history.goBack()}
                        >
                          <span className="icons">
                            <img src={backArrowIcon} alt="plus icon" />
                          </span>
                          Back
                        </button>
                  ) : (
                    <button
                      type="button"
                      className="back-btn-red"
                      onClick={goToPrevStep}
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="plus icon" />
                      </span>
                      Back
                    </button>
                  )}
                </div>}

                { currentVbMode !== VirtualBrandMode.ADD ? (
                      <>
                        <div className="confirm_page_subheader">
                          <div className="row align-items-lg-end">
                            <div className="col-12 col-md-6 col-lg-6">
                              <h2>{recipeGuideTitle}</h2>
                            </div>
                            {role !== RoleType.KITCHEN_STAFF && <div className={`col-12 col-md-6 col-lg-6`}>
                              <div className="savedraft__wrapper pb-lg-0">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="saveAsDraft__btn"
                                  >
                                    Choose an option
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                    onClick={onEditRgButton}
                                    disabled={currentVbMode === VirtualBrandMode.EDIT ? true : false}
                                    >
                                      Edit Recipe Guide
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>}
                          </div>
                        </div>
                        <CreateRecipes nextStep={goToNextStep} onConfirm={onConfirm} step={activeStep} />
                      </>
                ) : (
                  <div className="step__wrapper">
                    <MultiStepForm activeStep={activeStep}>
                      <Step label="Basic Info">
                       {/*  <div className="mobile-steps d-md-none">
                          <p>Step 01 of 04</p>
                        </div> */}
                        {activeStep === 1 && (
                          <RecipeGuideBasicInfo nextStep={goToNextStep} />
                        )}
                      </Step>
                      <Step label="Create Recipe Guide">
                       {/*  <div className="mobile-steps d-md-none">
                          <p>Step 02 of 04</p>
                        </div> */}
                        {activeStep === 2 && (
                                <>
                                  <div className="confirm_page_subheader">
                                    <h2>{/* Create RecipeGuide -  */}{recipeGuideTitle}</h2>
                                  </div>
                                  <CreateRecipes nextStep={goToNextStep} onConfirm={onConfirm} step={activeStep}/>
                                </>
                        )}
                      </Step>
                      <Step label="Upload Attachments">
                        {/* <div className="mobile-steps d-md-none">
                          <p>Step 03 of 04</p>
                        </div> */}
                        {activeStep === 3 && (
                          <UploadAttachments nextStep={goToNextStep} />
                        )}
                      </Step>
                      <Step label="Confirm">
                        {/* <div className="mobile-steps d-md-none">
                          <p>Step 04 of 04</p>
                        </div> */}
                        <div className="confirm_page_header">
                          <div className="row align-items-lg-end">
                            <div className="col-12 col-md-6 col-lg-6">
                                <>
                                  <h1>Confirm - {recipeGuideTitle}</h1>
                                  <p>
                                    Create recipes within the recipe guide and Upload
                                    all the required data.
                                  </p>
                                </>
                            </div>
                          </div>
                        </div>
                        {activeStep === 4 && (
                          <CreateRecipes nextStep={goToNextStep} onConfirm={onConfirm} step={activeStep}/>
                        )}
                      </Step>
                    </MultiStepForm>
                  </div>)}
              </div>
            </div>
            )} 
        </>
      )}
    </>
  );
};

export default withRouter(CreateRecipeGuide);
