import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dateFormat from "dateformat";
import toastify from "shared/toastr";
import { Modal, Button } from "react-bootstrap";
import DeleteModal from "UI/Modal/DeleteModal";
import { deleteRecipe, changeRecipeStatus } from "services/api/recipeguide";

const ListingRow = (props) => {
  const recipe = props.recipe;
  const refreshList = props.refreshList;
  const [status, setstatus] = useState(recipe?.isActive);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  useEffect(() => {
    setstatus(recipe?.isActive);
  }, [recipe?.isActive]);

  const onDelete = async () => {
   setdeleteLoading(true);  
   await deleteRecipe(recipe.id).then((res) => {
    setdeleteLoading(false);  
      refreshList();
      toastify("success", res.message);
    },
    (_) => {
      setdeleteLoading(false);
    });
  };

  const onChangeStatus = async () => {
    await changeRecipeStatus(recipe.id, !status).then((res) => {
      setstatus(!status);
      toastify("success", res.message);
    });
  };

  const truncate = (str) => {
    return str.substring(0, 69) + "...";
  }

  const closePopUpModal= () => {
    setdeleteModal(false);
  };

  const setDeletePopup = () => {
    setdeleteModal(true);
  }

  return (
    <>
    <tr role="row" className="mat-row">
      <td className="mat-cell column-1" data-column="NAME">
        <Link to={`/recipe/${recipe.id}#view`} className="modifierDeatil__link">
          {recipe.title?.length > 70 ? truncate(recipe.title) : recipe.title}
        </Link>
      </td>
      <td className="mat-cell column-2" data-column="LAST MODIFIED">
        <Link to={`/recipe/${recipe.id}#view`} className="modifierDeatil__link">
          {recipe.modifiedAt
            ? dateFormat(new Date(recipe.modifiedAt + "Z"), "mmm dd, hh:MM TT")
            : "NA"}
        </Link>
      </td>
      <td className="mat-cell column-4" data-column="STATUS">
      <div className="right_content">
        <span
            className={`custom__badge ${status ? "badge__red" : "badge__gray"}`}
          >
            {status ? "Active" : "Inactive"}
          </span>
          </div>
      </td>
      <td className="mat-cell column-5" data-column="ACTION">
        <div className="right_content">
          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdown-button-drop-left"
            ></button>
            <div className="dropdown-menu">
              <div className="dropdown__innerWrapper">
                <Link
                  to={`/recipe/${recipe.id}#view`}
                  className="dropdown-item active"
                >
                  <span>View</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <Link
                  to={`/recipe/${recipe.id}#edit`}
                  className="dropdown-item"
                >
                  <span>Edit</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <button onClick={()=>setDeletePopup()} className="dropdown-item">
                  <span>Delete</span>
                </button>
              </div>
              <div className="dropdown__innerWrapper">
                <button onClick={onChangeStatus} className="dropdown-item">
                  <span>Set As {status ? "Inactive" : "Active"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>

       {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Recipe"
          subtitle="Are you sure want to delete this recipe, It would be deleted from all recipe guides along with categories."
          onCancel={closePopUpModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

ListingRow.propTypes = {
  recipe: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    isActive: PropTypes.bool,
    modifiedAt: PropTypes.string,
  }),
  refreshList: PropTypes.func,
};

export default ListingRow;
