import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PropTypes from "prop-types";

import "../preloginstyle.scss";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import preLoginLogo from "assets/images/presigninlogo.svg";
import PasswordChanged from "./PasswordChanged";
import { resetPassword } from "services/api/auth";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";

const ResetPassword = (props) => {
  const email = props.email;
  const [showPassword, setshowPassword] = useState(false);
  const [showCnfmPassword, setshowCnfmPassword] = useState(false);
  const [isPasswordReset, setisPasswordReset] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .trim()
      .transform((x) => (x === "" ? undefined : x))
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[\d])(?=.*[@$!%*#?&^_-])[A-Za-z\d@$!%*#?&^_-]{8,15}$/,
        "Password allows 8-15 alphanumeric characters with at least 1 digit and 1 special character [@$!%*#?&^_-]"
      ),
    confirmPassword: Yup.string()
      .trim()
      .transform((x) => (x === "" ? undefined : x))
      .when("password", (password, schema) => {
        if (password)
          return schema.required("Please enter your confirm password");
      })
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Please enter your confirm password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const togglePassword = () => setshowPassword(!showPassword);
  const toggleCnfmPassword = () => setshowCnfmPassword(!showCnfmPassword);

  const onSubmit = async (data) => {
    setisLoading(true);
    await resetPassword(email, data.password).then(
      (res) => {
        toastify("success", res.message);
        setisLoading(false);
        setisPasswordReset(true);
      },
      (_) => setisLoading(false)
    );
  };

  return (
    <div className="prelogin__wrapper">
      <div className="inner__wrapepr">
        <div className="prelogin__logo">
          <img src={preLoginLogo} alt="pre login logo" />
        </div>

        {!isPasswordReset ? (
          <>
            <div className="prelogin__heading">
              <h1>Reset Password</h1>
            </div>
            <div className="preloginForm__wrapper">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row no-gutters">
                  <div className="col-md-12 mb-3 pb-3">
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      maxLength="50"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                    />
                    <span className="input-icon">
                      <img
                        onClick={togglePassword}
                        src={showPassword ? showpasswordicon : passwordicon}
                        alt={showPassword ? "hide" : "show"}
                        title={showPassword ? "hide" : "show"}
                      />
                    </span>
                    {errors.password?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.password?.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3 pb-3">
                    <label htmlFor="cnfpassword">Confirm Password</label>
                    <input
                      id="cnfpassword"
                      placeholder="Confirm your password"
                      type={showCnfmPassword ? "text" : "password"}
                      {...register("confirmPassword")}
                      maxLength="50"
                      className={`form-control ${
                        errors.confirmPassword ? "is-invalid" : ""
                      }`}
                    />
                    <span className="input-icon">
                      <img
                        onClick={toggleCnfmPassword}
                        src={showCnfmPassword ? showpasswordicon : passwordicon}
                        alt={showCnfmPassword ? "hide" : "show"}
                        title={showCnfmPassword ? "hide" : "show"}
                      />
                    </span>
                    {errors.confirmPassword?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.confirmPassword?.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 action__wrapper">
                    <button
                      type="submit"
                      className="prelogin-links-btn"
                      disabled={isLoading}
                    >
                      {isLoading ? <LoaderInline /> : "Change Password"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <PasswordChanged />
        )}
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  email: PropTypes.string.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ResetPassword;
