import { privateApi } from "../utilities/Provider";
import getFileNameFromUrl from "../utilities/Helper";

const basicUrl = "/VirtualBrand/";
const emailUrl = "/Email/";

const getVirtualBrands = ({ pageSize, pageNum, type }) => {
  const params = new URLSearchParams();
  params.append("pageNum", pageNum);
  params.append("pageSize", pageSize);
  params.append("virtualBrandType", type);
  return privateApi.get(basicUrl + "virtualbrands", { params });
};

const addVirtualBrandBasicInfo = (model) => {
  let body = {
    name: model.name,
    tagline: model.tagline,
    description: model.description,
    deliveryRadius: model.deliveryRadius,
    preparationTime: model.preparationTime,
    tipAmount: model.tipAmount,
    operatingHours: {
      monday: model.monday,
      tuesday: model.tuesday,
      wednesday: model.wednesday,
      thursday: model.thursday,
      friday: model.friday,
      saturday: model.saturday,
      sunday: model.sunday,
    },
  };
  return privateApi.post(basicUrl + "virtualbrand", body);
};

const editVirtualBrandBasicInfo = (model) => {
  let body = {
    id: model.brandId,
    name: model.name,
    tagline: model.tagline,
    description: model.description,
    deliveryRadius: model.deliveryRadius,
    preparationTime: model.preparationTime,
    logoImageBlobName: getFileNameFromUrl(model.logoURL),
    coverImageBlobName: getFileNameFromUrl(model.coverURL),
    tipAmount: model.tipAmount,
    operatingHours: {
      monday: model.monday,
      tuesday: model.tuesday,
      wednesday: model.wednesday,
      thursday: model.thursday,
      friday: model.friday,
      saturday: model.saturday,
      sunday: model.sunday,
    },
  };
  return privateApi.put(basicUrl + "virtualbrand", body);
};

const deleteVirtualBrand = (brandId, kitchenAssociationId) => {
  return privateApi.delete(basicUrl + "virtualbrand", {
    params: {
      id: brandId,
      kitchenVBAssociationId: kitchenAssociationId,
    },
  });
};

const deleteVirtualBrandLogoImage = (brandId) => {
  return privateApi.delete(basicUrl + "logoImage", {
    params: {
      id: brandId
    },
  });
};

const deleteVirtualBrandCoverImage = (brandId) => {
  return privateApi.delete(basicUrl + "coverImage", {
    params: {
      id: brandId
    },
  });
};

const updateSharedVBRemovalStatus = (model) => {
  let body = {
    virtualBrandId: model.brandId,
    kitchenVBAssociationId: model.kitchenAssociationId,
    kitchenId: model.kitchenId,
    vbName: model.vbName,
    kitchenName: model.kitchenName
  };
  return privateApi.put(basicUrl + "sharedvbremovalrequest", body);
};

const addBrandAsset = (model) => {
  let body = {
    virtualBrandId: model.brandId,
    logoImageBlobName: getFileNameFromUrl(model.logoImage),
    coverImageBlobName: getFileNameFromUrl(model.coverImage),
  };

  return privateApi.put(basicUrl + "virtualbrandimages", body);
};

const hasVirtualBrand = () => {
  return privateApi.get(basicUrl + "hasvirtualbrands");
};

const getBrandAsset = (id) => {
  return privateApi.get(basicUrl + "virtualbrandassets", {
    params: {
      virtualbrandId: id,
    },
  });
};

const getVirtualBrand = (brandId, isViewMode) => {
  return privateApi.get(basicUrl + "virtualbrand", {
    params: {
      virtualbrandId: brandId,
      isView: isViewMode,
    },
  });
};

const changeBrandStatus = (brandId, statusId, kitchenId = 0) => {
  let body = {
    virtualBrandId: brandId,
    statusId: statusId,
    kitchenId: kitchenId,
  };

  return privateApi.put(basicUrl + "virtualbrandstatus", body);
};

const getVirtualBrandsByKitchen = (kitchenId) => {
  const params = new URLSearchParams();
  if (kitchenId) params.append("kitchenId", kitchenId);

  return privateApi.get(basicUrl + "virtualbrandsbykitchen", { params });
};

const addNewVbCredential = ({ brandId, url, key, storeId, partnerId }) => {
  let body = {
    vbKitchenAssociationId: brandId,
    webHookUrl: url,
    apiKey: key,
    storeId: storeId,
    partnerId: partnerId
  };
  return privateApi.post(basicUrl + "createapikeyandurl", body);
};

const editVbCredential = ({ brandId, url, key, storeId, partnerId }) => {
  let body = {
    vbKitchenAssociationId: brandId,
    webHookUrl: url,
    apiKey: key,
    storeId: storeId,
    partnerId: partnerId
  };
  return privateApi.put(basicUrl + "updateeapikeyandurl", body);
};

const UpdateBHBCommissions = (model) => {
  let body = {
    kitchenVBId: model.kitchenVBId,
    bHBDeliveryCommissionPercentage: model.deliveryCommission,
    bHBPickupCommissionPercentage: model.pickupCommission,
    affiliateUserId: model.affiliateUserId,
    affiliatePayout: model.affiliatePayout,
    brandOwnerId: model.brandOwnerId,
    brandOwnerPayout: model.brandOwnerPayout,
    affiliatePickupRate: model?.affiliatePickupRate,
    affiliateDeliveryRate: model?.affiliateDeliveryRate,
    restaurantPayout: model.restaurantPayout
  };
  return privateApi.put(basicUrl + "kvbbhbcommission", body);
};

const sendToSupportEmail = ({ brandId, key }) => {
  const params = new URLSearchParams();
  if (brandId) params.append("KitchenVirtualBrandAssociationId", brandId);
  if (key) params.append("ApiKey", key);
  return privateApi.post(emailUrl + "SendToSupportEmail"+'?ApiKey=' + key+'&KitchenVirtualBrandAssociationId='+brandId);
};

const getVirtualBrandStatus = (VBId, KitchenId) => {
  const params = new URLSearchParams();
  if (VBId) params.append("virtualbrandId", VBId);
  if (KitchenId) params.append("kitchenId", KitchenId);

  return privateApi.get(basicUrl + "virtualbrandstatus", { params });
};

const getLandingPageData = (kitchenId, vbId) => {
  const params = new URLSearchParams();
  params.append("kitchenId", kitchenId);
  params.append("virtualbrandId", vbId);
  return privateApi.get(basicUrl + "landingpage", { params });
};

const getTrackingPageData = (orderId) => {
  const params = new URLSearchParams();
  params.append("orderId", orderId);
  return privateApi.get(basicUrl + "trackingpage", { params });
};

export {
  getVirtualBrands,
  addVirtualBrandBasicInfo,
  editVirtualBrandBasicInfo,
  deleteVirtualBrand,
  deleteVirtualBrandLogoImage,
  deleteVirtualBrandCoverImage,
  updateSharedVBRemovalStatus,
  addBrandAsset,
  hasVirtualBrand,
  getBrandAsset,
  getVirtualBrand,
  changeBrandStatus,
  getVirtualBrandsByKitchen,
  addNewVbCredential,
  editVbCredential,
  sendToSupportEmail,
  getVirtualBrandStatus,
  UpdateBHBCommissions,
  getLandingPageData,
  getTrackingPageData
};
