import React from "react";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import "./AddNewConnection.scss";

const AddNewConnection = () => {
  return (
    <div className="AddNewConnection__wrapper">
      <div className="modifierDetails__wrapper modifierEdit__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <Link to="#" className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Add New Connection</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper editModifier__wrapper">
                <div className="details__wrapper__inner">
                  <form>
                    <div className="basic__info__content">
                      <div className="form-row">
                      <div className="col-md-12 mb-3 pb-3 px-0">
                          <label htmlFor="username">
                          Username
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="username"
                            placeholder="Nombeko Mabandla"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                        <div className="col-md-12 mb-3 pb-3 px-0">
                          <label htmlFor="password">
                          Password
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="password"
                            placeholder="Enter password"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                        <div class="col-md-12 mb-3 pb-3 px-0">
                          <label for="mandatory">Marketplace Name<span className="mendatory">*</span></label>
                          <select
                            className="form-control big-select"
                            placeholder="No Restaurant Selected"
                          >
                            <option value="">No Marketplace Selected</option>
                            <option value="">Website Admin</option>
                            <option value="">Website Staff</option>
                            <option value="">Restaurant Owner</option>
                            <option value="">Restaurant Staff</option>
                          </select>
                        </div>
                        <div className="col-md-12 mb-3 pb-3 px-0">
                          <label htmlFor="marketPlaceId">
                          Marketplace ID
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="marketPlaceId"
                            placeholder="E.g. 745210"
                            className="form-control"
                          />
                        </div>
                        <div class="col-md-12 mb-3 pb-3 px-0">
                          <label for="mandatory">Marketplace Status</label>
                          <select
                            className="form-control big-select"
                            placeholder="Inactive"
                          >
                            <option value="">Inactive</option>
                            <option value="">Active</option>
                            <option value="">Website Staff</option>
                            <option value="">Restaurant Owner</option>
                            <option value="">Restaurant Staff</option>
                          </select>
                        </div>
                        <div className="col-md-12 mb-md-3 pb-md-3 px-0">
                          <label htmlFor="uniqueUrl">
                          Unique URL
                            <span className="mendatory">*</span>
                          </label>
                          <input
                            type="text"
                            id="uniqueUrl"
                            placeholder="E.g. http://example.net/"
                            className="form-control"
                            maxLength="30"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 px-0">
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn red-btn-ghost btn"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="red-btn next-btn btn"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewConnection;
