import React, { useState, useRef, useEffect } from "react";
import calendarIcon from "assets/images/calender.svg";
import clearIcon from "assets/images/ico-calendar-reset.svg";
import { DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateRangeFilter = (props) => {
  const dateTo = props.dateToValue;
  const dateFrom = props.dateFromValue;
  const sendInfo = props.onSelect;

  const [range, setRange] = useState([
    {
      startDate: dateFrom,
      endDate: dateTo,
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const refOne = useRef(null);

  const isDateRangeMatching = (fromStr, toStr, daysAgo) => {
    const from = moment(fromStr);
    const to = moment(toStr);
    const startDate = moment().subtract(daysAgo, "days");
    return startDate.isSame(from, "day") && moment().isSameOrAfter(to, "day");
  };

  const dateRangeConfigArr = [
    {
      label: "Today",
      value: 1,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 0),
    },
    {
      label: "Yesterday",
      value: 2,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 1),
    },
    {
      label: "Last Week",
      value: 3,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 7),
    },
    {
      label: "Last 30 Days",
      value: 4,
      match: (fromStr, toStr) => isDateRangeMatching(fromStr, toStr, 30),
    },
  ];

  const onDateSelect = (data) => {
    setCount(count + 1);
    if (count >= 1 && data?.startDate !== data?.endDate) {
      setOpen(false);
      setCount(0);
    }
    setRange([data]);
    if (data?.key === "selection") {
      const matchingObj = dateRangeConfigArr.find((config) =>
        config.match(data.startDate, data.endDate)
      );
      sendInfo({
        dateFrom: data.startDate,
        dateTo: data.endDate,
        selectedValue: matchingObj ? matchingObj.value : 5,
      });
    } else {
      sendInfo({
        dateFrom: null,
        dateTo: null,
        selectedValue: 0,
      });
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  useEffect(() => {
    setRange([
      {
        startDate: dateFrom,
        endDate: dateTo,
        key: "selection",
      },
    ]);
  }, [dateTo, dateFrom]);

  return (
    <div className="calendarWrap">
      <div className="input-group">
        <div className="custom-file">
          <input
            className="custom-file form-control date-range-picker"
            value={
              dateFrom && dateTo
                ? `${moment(range[0].startDate).format(
                    "MM/DD/yyyy"
                  )}  to  ${moment(range[0].endDate).format("MM/DD/yyyy")}`
                : ""
            }
            placeholder="mm/dd/yyyy to mm/dd/yyyy"
            onClick={() => setOpen((open) => !open)}
            readOnly
          />
        </div>

        {dateFrom && dateTo && (
          <button
            style={{ right: 24 }}
            className="search-btn"
            onClick={() =>
              onDateSelect({
                startDate: null,
                endDate: null,
                key: "clear",
              })
            }
          >
            <span className="icons">
              <img src={clearIcon} alt="clear icon" />
            </span>
          </button>
        )}
        <button
          style={{ right: 0 }}
          className="search-btn"
          onClick={() => setOpen((open) => !open)}
        >
          <span className="icons">
            <img src={calendarIcon} alt="calender icon" />
          </span>
        </button>
      </div>

      <div ref={refOne}>
        {open && (
          <DateRange
            onChange={(item) => {
              onDateSelect(item.selection);
            }}
            showSelectionPreview={true}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            rangeColors={["#ec380b", "#76baff", "#ec380b"]}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        )}
      </div>
    </div>
  );
};

export default DateRangeFilter;
