import React, { useContext } from "react";
import CategoryForm from "../category-form/CategoryForm";
import { Modal } from "react-bootstrap";
import VirtualBrandContext from "store/virtual-brand-context";

const CategoryModal = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const modalExtras = brandCtx.modalExtras;

  return (
    <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
      <Modal.Header closeButton>
        <Modal.Title id="addMenu__item__modal">
          <h1>
            {modalExtras && modalExtras?.editMode && "Edit"}
            {modalExtras && modalExtras?.viewMode && "View"}
            {!modalExtras?.viewMode && !modalExtras?.editMode
              ? "Add"
              : null}{" "}
            Menu Category
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CategoryForm />
      </Modal.Body>
    </Modal>
  );
};

export default CategoryModal;
