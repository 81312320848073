import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "logo.svg";
import "./Header.scss";
import SidebarMenu from "../sidenav/SidebarMenu";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";
import filterIcon from "assets/images/Icon_Filter_ckc.svg";
import OrdersNotificationContext from "store/orders-notification-context";
import OrderFilterModal from "pages/order/listing/OrderFilterModal";
import fullScreenIcon from "assets/images/icon-fullscreen.svg";

function Header() {
  const userCtx = useContext(UserContext);
  const userName = userCtx?.userInfo?.username;
  const role = userCtx?.userInfo?.role;
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const useFilters = OrdersNotificationCtx?.filtersData;

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["header"];
  if (scrolled) {
    x.push("sticky");
  };

   const onOrderFilterToggle = () => {
     setIsOpen(true);
   };
  
  const closeModal = () => {
    setIsOpen(false);
  };

  const onOrderFullScreenToggle = () => {
    OrdersNotificationCtx.saveFullScreenState(!OrdersNotificationCtx.isFullScreen);
  };

  return (
    <div className={x.join(" ")}>
      {(
        <div className="header-container">
          <div className="header-wrapper">
            <div className="header-left">
              <div className="toggle-menu">
                <SidebarMenu />
              </div>
              <div className="logo">
                <Link to="/dashboard">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="header-right">
              {OrdersNotificationCtx?.isFullScreen && path === "/orders" ? (
                <div className="d-none d-xl-flex align-items-center">
                  <button
                    type="button"
                    className={
                      "header__fullscreen__icon filters filter__applied filter__applied__fullscreen"
                    }
                    onClick={onOrderFullScreenToggle}
                  >
                    <span>
                      <img
                        src={fullScreenIcon}
                        alt="fullScreen icon"
                        className="img-fluid"
                      />
                    </span>
                  </button>
                  <div className="ml-3">
                    <button
                      type="button"
                      className={
                        "header__fullscreen__icon filters filter__applied filter__applied__fullscreen"
                      }
                      onClick={onOrderFilterToggle}
                    >
                      <span>
                        <img
                          src={filterIcon}
                          alt="export icon"
                          className="img-fluid"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <Link to="/profile">
                  <div className="profile">
                    <div className="profile-initials">
                      <span>{userName && userName[0]?.toUpperCase()}</span>
                    </div>
                    <div className="profile-dropdown">{userName}</div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <OrderFilterModal
          show={isOpen}
          className="right order__filters__modal"
          onHide={closeModal}
          filtersData={useFilters}
          type={"orders"}
          title={"Filter Orders"}
        />
      )}
    </div>
  );
}

export default Header;
