import React, { useEffect, useState } from "react";
import "../../../modifier/listing/ModifierListing.scss";
import "./Providers.scss";
import searchIcon from "assets/images/icon-search.svg";
import emptyIcon from "assets/images/emptyIcon.svg";
import { Global } from "constants/global";
import { getKitchenModswithModOptions } from "services/api/modifier";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import ProvidersRow from "./ProvidersRow";
import toastify from "shared/toastr";
import noresultsIcon from "assets/images/noresult.svg";
import { Link } from "react-router-dom";
import plusIcon from "assets/images/add-icon-white.svg";
import {
  getProvidersList,
  getProvidersDropdownList,
} from "services/api/provider";
import ProvidersEdit from "./ProvidersEdit";

const ProvidersList = (props) => {
  const pageSize = Global.pageSize;
  const [pageNum, setpageNum] = useState(1);
  const [searchValue, setsearchValue] = useState(null);
  const kitchen = props.kitchen;
  const kitchenId = kitchen?.id;

  const [allProviders, setallProviders] = useState(null);
  const [dropdownProvidersList, setDropdownProvidersList] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [isShowAddProvider, setIsShowAddProvider] = useState(false);

  useEffect(() => {
    if (kitchenId) {
      getListing();
    }
  }, [kitchenId, pageNum, searchValue]);

  const openModal = () => {
    setIsShowAddProvider(true);
  };
  const closeModal = () => {
    setIsShowAddProvider(false);
  };

  const getListing = async () => {
    let masks = {
      kitchenId: kitchenId,
      pageNum: pageNum,
      pageSize: pageSize,
      searchTerm: searchValue,
    };
    setisLoading(true);

    await getProvidersDropdownList()
      .then((res) => {
        setDropdownProvidersList(res?.data);
      })
      .catch((e) => {
        toastify("error", e.message);
      });

    await getProvidersList(masks)
      .then((res) => {
        let data = res.data;
        setallProviders(data?.kitchenDeliveryProvider);
        settotalRecords(data?.pagingParams?.totalRecords);
        setisLoading(false);
      })
      .catch((e) => {
        setallProviders(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    setpageNum(page);
  };

  const onChangeSearch = (e) => {
    setsearchValue(e?.target?.value);
  };

  const onSearch = () => {
    setsearchValue(searchValue);
  };

  return (
    <>
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header modifiers_heading_padding">
            <div className="row">
              <div className="col-12 col-lg-6 mb-0 mb-md-3 mb-lg-0">
                <h2>Providers</h2>
              </div>
              <div className="col-12 col-lg-6">
                <div
                  className="form flex-column flex-md-row"
                  id="manage__modifier__search__form"
                >
                  <div className="search__modifiers">
                    {/* <div className="search_modifier_group">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="text"
                            className="custom-file form-control"
                            placeholder="Search providers"
                            defaultValue={searchValue}
                            onKeyUp={onChangeSearch}
                          />
                          <button
                            type="button"
                            className="search-btn"
                            onClick={onSearch}
                          >
                            <span className="icons">
                              <img src={searchIcon} alt="plus icon" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <button onClick={openModal} className="red-btn btn">
                    <span className="icons-left icons">
                      <img src={plusIcon} alt="add icon" />
                    </span>
                    Add New Provider
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!allProviders?.length && totalRecords === 0 && !isLoading ? (
            <div className="restaurantdetails__noListing">
              <div className="managermodifier__noListing__innerwrapper">
                <div className="content">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={
                        searchValue && searchValue !== ""
                          ? noresultsIcon
                          : emptyIcon
                      }
                      alt="no list icon"
                      width="185"
                    />
                  </div>
                  <div className="content-desc">
                    <h3>
                      {searchValue && searchValue !== ""
                        ? "No provider's found"
                        : "No providers are added"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="managermodifier__listinPage">
              <div className="managermodifier__listinPage__wrapper">
                <table className="table modifierListing__table">
                  <thead>
                    <tr role="row" className="mat-header-row">
                      <th scope="col" className="mat-header-cell column_1">
                        Name
                      </th>
                      <th scope="col" className="mat-header-cell column-5">
                        <span className="mr-4 pr-2">Status</span> Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td>
                          <LoaderBlock />
                        </td>
                      </tr>
                    ) : (
                      allProviders?.map((provider, idx) => (
                        <ProvidersRow
                          key={idx}
                          getListing={getListing}
                          provider={provider}
                          dropdownProvidersList={dropdownProvidersList}
                          kitchenId={kitchenId}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-12">
                    <Paginator
                      totalCount={totalRecords}
                      currentPage={pageNum}
                      pageSize={pageSize}
                      pageClick={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isShowAddProvider && (
        <ProvidersEdit
          getListing={getListing}
          kitchenId={kitchenId}
          dropdownProvidersList={dropdownProvidersList}
          modalShow={isShowAddProvider}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default ProvidersList;
