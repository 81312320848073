import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "react-bootstrap";
import "./DeleteModal.scss";
import LoaderInline from "shared/loader/LoaderInline";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";

const ReviewModal = (props) => {
  return (
    <>
      <div className="confirmation__wrapper">
        <Modal
          show={props.show}
          onHide={props.onCancel}
          animation={false}
          id="confirmation__modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="confirmation__heading">
              Review and Confirm
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <p className="mb-0">
              Do you want to submit your Virtual Brand for review?
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              onClick={props.onCancel}
              className="cancel-btn red-btn-ghost btn"
            >
              Cancel
            </Button>
            <Button
              onClick={props.onSubmit}
              className="red-btn btn"
              disabled={props.onLoading}
            >
              {props.onLoading ? (
                <LoaderInline />
              ) : (
                <>
                  {"Submit for Review  "}
                  <img src={rightArrowIcon} alt="arrow icon" className="ml-2" />
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

ReviewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLoading: PropTypes.bool.isRequired,
};

export default ReviewModal;
