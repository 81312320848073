import React, { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Global } from "constants/global";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import uploadFileToBlob from "services/azure-blob";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import { addMenuItem, editMenuItem } from "services/api/menu";
import VirtualBrandContext from "store/virtual-brand-context";
import LoaderInline from "shared/loader/LoaderInline";
import { AssetType } from "constants/assetType";
import { deleteMenuItemImage } from "services/api/menu/index";

const ItemBasicInfo = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const selectedCategoryId = brandCtx.virtualBrandMenuCategoryId;
  const coverSize = Global.fiveMB;
  const [coverImage, setcoverImage] = useState(null);
  const [rawCoverImage, setRawCoverImage] = useState(null);

  const [isCoverUploading, setisCoverUploading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const initData = props.initialData;
  const extras = props.extras;
  const isDisabled = props.disabled;
  const virtualBrandInfo = brandCtx.virtualBrandInfo;

  useEffect(() => {
    if (
      initData /* &&
      (extras?.editMode || extras?.cloneMode || extras?.viewMode) */
    ) {
      reset({
        itemName: initData.itemName + (extras?.cloneMode ? " (copy)" : ""),
        coverURL: initData.coverURL || "",
        description: initData.description,
        pickupPrice: initData.pickupPrice.toFixed(2),
        pickupPriceTax: initData.pickupPriceTax.toFixed(2),
        preparationTime: initData.preparationTime || virtualBrandInfo?.preparationTime,
        priority:  initData.priority,
        isAlcoholic: "" + initData.isAlcoholic,
        isActive: "" + initData.isActive,
      });
      setcoverImage({ filePath: initData.coverURL });
      setRawCoverImage(initData.rawCoverURL);
    }
    else {
      reset({
        preparationTime: virtualBrandInfo?.preparationTime,
      });
    }
  }, [initData]);

  const validationSchema = Yup.object().shape({
    itemName: Yup.string()
      .trim()
      .max(120, "Item title not more than 120 characters")
      .required("Please enter item title"),
    coverURL: Yup.string().trim(),
    description: Yup.string()
      .trim()
      .max(500, "Item description not more than 500 characters")
      .required("Please enter item description"),
    pickupPrice: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(0)
      .max(10000)
      .required("Please enter base price"),
    pickupPriceTax: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(0)
      .max(100)
      .required("Please enter tax rate"),
    preparationTime: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(1)
      .max(1000)
      .required("Please enter preparation time"),
    priority: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(1, "Value must be at least 1")
      .max(1000, "Value can't be more than 1000"),
    isAlcoholic: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isAlcoholic: "false",
      isActive: "true",
    },
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  const uploadCoverImage = async (file) => {
    setisCoverUploading(true);
    await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
      (res) => {
        setcoverImage(res);
        setisCoverUploading(false);
        setValue("coverURL", res?.fileName, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      },
      () => {
        setcoverImage(null);
        setisCoverUploading(false);
      }
    );
  };

  const deleteItemImage = async () => {
    if (initData?.id) {
      setisCoverUploading(true);
      await deleteMenuItemImage(initData.id).then(
        () => {
          setValue("coverURL", null);
          setcoverImage(null);
          setRawCoverImage(null);
          setisCoverUploading(false);
        },
        () => {
          setcoverImage(null);
          setRawCoverImage(null);
          setisCoverUploading(false);
        }
      );
    }
  };

  const onSubmit = async (data) => {
    if (isDisabled) {
      props.onSave(data);
      return;
    }

    let model = {
      ...data,
      categoryId: selectedCategoryId,
    };
    setisLoading(true);
    if (extras && extras?.editMode) {
      await editMenuItem(initData.id, model).then(
        (res) => {
          setisLoading(false);
          model["id"] = res?.data?.id
          props.onSave(model);
        },
        (_) => {
          setisLoading(false);
        }
      );
    } else {
      await addMenuItem(model).then(
        (res) => {
          setisLoading(false);
          props.onSave(res.data);
        },
        (_) => {
          setisLoading(false);
        }
      );
    }
  };

  return (
    <div className="uploadAssets__wrapper basic__info__content body-overflow">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row no-gutters">
          <div className="col-12 mb-3 pb-3">
            <label>Main image</label>
            <AssetDropArea
              dimensions="2000 X 1200px"
              maxSize={coverSize}
              acceptedFormat="image/jpeg, image/png"
              showFormatText="Jpeg, Png"
              onFileChange={uploadCoverImage}
              isImageUploading={isCoverUploading}
              uploadedImage={coverImage?.filePath}
              uploadedRawImage={rawCoverImage}
              disabled={isDisabled}
              btnText={"Change Image"}
              onDeleteImg={deleteItemImage}
            />
            {errors?.coverURL?.message && (
              <div className="invalid-feedback d-block">
                {errors?.coverURL?.message}
              </div>
            )}
          </div>

          <div className="col-md-12 mb-3 pb-3">
            <label htmlFor="categoryName">
              Title<span className="mendatory">*</span>
            </label>
            {isDisabled ? (
              <div className="view_form_input">
                {initData?.itemName + (extras?.cloneMode ? " (copy)" : "") ||
                  "NA"}
              </div>
            ) : (
              <input
                type="text"
                id="categoryName"
                placeholder="e.g. Classic Cheese Curds"
                className={`form-control ${
                  errors?.itemName ? "is-invalid" : ""
                }`}
                {...register("itemName")}
                maxLength="120"
                disabled={isDisabled}
              />
            )}
            {errors?.itemName?.message && (
              <div className="invalid-feedback d-block">
                {errors?.itemName?.message}
              </div>
            )}
          </div>

          <div className="col-md-12 mb-3 pb-3">
            <label htmlFor="categoryDesc">
              Menu Item Description<span className="mendatory">*</span>
            </label>
            {isDisabled ? (
              <div className="view_form_input">
                {initData?.description || "NA"}
              </div>
            ) : (
              <textarea
                id="categoryDesc"
                placeholder="Add Menu Item Description"
                rows="3"
                className={`form-control ${
                  errors?.description ? "is-invalid" : ""
                }`}
                {...register("description")}
                maxLength="500"
                disabled={isDisabled}
              ></textarea>
            )}
            {errors?.description?.message && (
              <div className="invalid-feedback d-block">
                {errors?.description?.message}
              </div>
            )}
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 mb-3 pb-3">
                <label htmlFor="pickupPrice">
                  Base Price<span className="mendatory">*</span>
                </label>
                {isDisabled ? (
                  <div className="view_form_input">
                    {initData?.pickupPrice || "NA"}
                  </div>
                ) : (
                  <input
                    id="pickupPrice"
                    placeholder="e.g. 9.99"
                    className={`form-control ${
                      errors?.pickupPrice ? "is-invalid" : ""
                    }`}
                    {...register("pickupPrice")}
                    disabled={isDisabled}
                  />
                )}
                {errors?.pickupPrice?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.pickupPrice?.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3 pb-3">
                <label htmlFor="pickupPriceTax">
                  Tax Rate<span className="mendatory">*</span>
                </label>
                {isDisabled ? (
                  <div className="view_form_input">
                    {initData?.pickupPriceTax || "NA"}
                  </div>
                ) : (
                  <input
                    id="pickupPriceTax"
                    placeholder="e.g. 3"
                    className={`form-control ${
                      errors?.pickupPriceTax ? "is-invalid" : ""
                    }`}
                    {...register("pickupPriceTax")}
                    disabled={isDisabled}
                  />
                )}
                {errors?.pickupPriceTax?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.pickupPriceTax?.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 mb-3 pb-3">
                <label htmlFor="preparationTime">
                  Estimated Prep Time (in Mins)
                  <span className="mendatory">*</span>
                </label>
                {isDisabled ? (
                  <div className="view_form_input">
                    {initData?.preparationTime ||
                      virtualBrandInfo?.preparationTime ||
                      "NA"}
                  </div>
                ) : (
                  <input
                    id="preparationTime"
                    placeholder="in Mins"
                    className={`form-control ${
                      errors?.preparationTime ? "is-invalid" : ""
                    }`}
                    {...register("preparationTime")}
                    disabled={isDisabled}
                  />
                )}
                {errors?.preparationTime?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.preparationTime?.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-2 pb-2">
                <label htmlFor="priority">
                  Sort Order<span className="mendatory">*</span>
                </label>
                {isDisabled ? (
                  <div className="view_form_input">
                    {initData?.priority}
                  </div>
                ) : (
                  <input
                    id="priority"
                    placeholder="e.g. 3"
                    className={`form-control ${
                      errors?.priority ? "is-invalid" : ""
                    }`}
                    defaultValue={100}
                    {...register("priority")}
                    disabled={isDisabled}
                  />
                )}
                {errors?.priority?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.priority?.message}
                  </div>
                )}
                <div className="sort__info mt-1 pt-1">
                  Sort Order from 1 to 1000, 1 top of list
                </div> 
              </div>
            </div>
          </div>

          <div className="col-md-12 mb-3 pb-3">
            <label htmlFor="mandatory">Alcoholic</label>
            {isDisabled ? (
              <div className="view_form_input">
                {props?.initialData?.isAlcoholic ? "Yes" : "No"}
              </div>
            ) : (
              <div className="check__wrapper">
                <label className="check-container">
                  Yes
                  <input
                    type="radio"
                    {...register("isAlcoholic")}
                    value={"true"}
                    disabled={isDisabled}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="check-container">
                  No
                  <input
                    type="radio"
                    name="mandatory"
                    {...register("isAlcoholic")}
                    value={"false"}
                    disabled={isDisabled}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            {errors?.isAlcoholic?.message && (
              <div className="invalid-feedback d-block">
                {errors?.isAlcoholic?.message}
              </div>
            )}
          </div>

          <div className="col-md-12">
            <label htmlFor="status">Status</label>
            {isDisabled ? (
              <div className="view_form_input">
                {" "}
                {props?.initialData?.isActive ? "Active" : "Inactive"}
              </div>
            ) : (
              <div className="check__wrapper">
                <label className="check-container">
                  Active
                  <input
                    type="radio"
                    {...register("isActive")}
                    value={"true"}
                    disabled={isDisabled}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="check-container">
                  Inactive
                  <input
                    type="radio"
                    {...register("isActive")}
                    value={"false"}
                    disabled={isDisabled}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            {errors?.isActive?.message && (
              <div className="invalid-feedback d-block">
                {errors?.isActive?.message}
              </div>
            )}
          </div>

          <div className="col-12">
            <div className="modal-footer">
              {extras && extras?.editMode ? props.children : null}

              <button
                type="button"
                className="cancel-btn red-btn-ghost btn"
                onClick={props.onHide}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="red-btn next-btn btn"
                disabled={isLoading || !isValid}
              >
                {isLoading ? (
                  <LoaderInline />
                ) : (
                  <>
                    Next
                    <span className="icons-right">
                      <img src={rightArrowIcon} alt="arrow icon" />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ItemBasicInfo;
