import {
  getUserDataFromToken,
  getUserRefreshToken,
  removeUserToken,
  setBasicToken,
  setUserToken,
} from "services/local-storage";
import { privateApi, publicApi } from "../utilities/Provider";
import { handleError } from "../utilities/Response";

const basicUrl = "/auth/";

const getBasicToken = () => {
  const params = new URLSearchParams();
  params.append("clientId", "" + process.env.REACT_APP_CLIENT_ID);
  params.append("clientSecret", "" + process.env.REACT_APP_CLIENT_SECRET);

  return publicApi
    .get(basicUrl + "token", { params })
    .then((res) => {
      setBasicToken(res.data);
    })
    .catch(handleError);
};

const loginUser = (email, password) => {
  let body = {
    email: "" + email,
    password: "" + password,
  };

  return publicApi.post(basicUrl + "web/login", body).then((res) => {
    let data = res.data;
    setUserToken({ token: data?.token, refreshToken: data?.refreshToken });
    let extractedData = getUserDataFromToken();
    return { user: extractedData, ...data };
  });
};

const forgetPassword = (email) => {
  let body = {
    email: "" + email,
  };

  return publicApi.post(basicUrl + "forgotpassword", body);
};

const validateOtp = (email, otp) => {
  let body = {
    email: "" + email,
    otp: "" + otp,
  };

  return publicApi.post(basicUrl + "validateotp", body);
};

const resendOtp = (email) => {
  let body = {
    email: "" + email,
  };

  return publicApi.post(basicUrl + "resendotp", body);
};

const resetPassword = (email, password) => {
  let body = {
    userId: "" + email,
    newPassword: "" + password,
  };
  return publicApi.post(basicUrl + "resetpassword", body);
};

const getRefreshToken = () => {
  let body = {
    token: "" + getUserRefreshToken(),
  };

  return privateApi.post(basicUrl + "web/refreshtoken", body).then((res) => {
    let data = res.data;
    setUserToken({ token: data?.token, refreshToken: data?.refreshToken });
  });
};

const validateUser = (userToken) => {
  let body = {
    user: "" + userToken,
  };

  return publicApi.put(basicUrl + "user/verifyaccount", body).then((res) => {
    let data = res.data;
    setUserToken({ token: data?.token, refreshToken: data?.refreshToken });
  });
};

const resendEmail = (email) => {
  let body = {
    email: "" + email,
  };

  return publicApi.put(basicUrl + "user/resendemail", body);
};

const changePassword = (oldPassword, newPassword) => {
  let body = {
    currentPassword: "" + oldPassword,
    newPassword: "" + newPassword,
    confirmNewPassword: "" + newPassword,
  };

  return privateApi.post(basicUrl + "changePassword", body);
};

const getSasToken = () => {
  return privateApi.get(basicUrl + "sas");
};

const logoutUser = () => {
  removeUserToken();
  window.location.replace("/login");
  return;
};

const getKitchenStaffToken = (model) => {
  let body = {
    deviceTokenId: model?.deviceTokenId,
    virtualBrandId: model?.brandId,
    recipeGuideId: model?.recipeGuideId,
    secretKey: "JbXCNu74TRcuHHx7ZzKd&TUfHkJt7jZE"
  };
  return privateApi.post(basicUrl + "devicetoken", body);
};

export {
  loginUser,
  forgetPassword,
  validateOtp,
  resendOtp,
  resetPassword,
  getBasicToken,
  getRefreshToken,
  validateUser,
  resendEmail,
  changePassword,
  getSasToken,
  logoutUser,
  getKitchenStaffToken
};
