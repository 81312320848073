import React, { useContext, useState, useEffect } from "react";

import { Tab, Nav, Col, Row } from "react-bootstrap";

import "UI/Button/AddButton.scss";
import "../../../Info-section/create-form/CreateMenuModalContent.scss";
import ItemBasicInfo from "./ItemBasicInfo";
import ItemModifier from "./ItemModifier";

import VirtualBrandContext from "store/virtual-brand-context";
import { deleteMenuItem, getMenuItemWithModById } from "services/api/menu";
import LoaderBlock from "shared/loader/LoaderBlock";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";

//let isBasicDataComplete = false;

const ItemForm = () => {
  const brandCtx = useContext(VirtualBrandContext);

  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);

  const modalStatus = brandCtx.menuItemModalStatus;
  const modalExtras = brandCtx.modalExtras;

  const [initialData, setinitialData] = useState(null);
  const [modifierData, setModifierData] = useState(null);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const [isDisabledFields, setisDisabledFields] = useState(false);
  const brandMode = brandCtx.virtualBrandMode;

  const brandId = brandCtx.virtualBrandId;
  const kitchenId = brandCtx.associationKitchenId;
  const menuItemId = brandCtx.virtualBrandMenuItemId;
  
  useEffect(() => {
    if (modalStatus === true) {
      if (
        modalExtras && (
          modalExtras?.editMode ||
            modalExtras?.cloneMode ||
            modalExtras?.viewMode
        )
      ) {
        getItemDetail(modalExtras?.data.id);
      }
      if (modalExtras?.viewMode || brandMode === VirtualBrandMode.SHARED) setisDisabledFields(true);
    }
  }, [modalStatus]);

  const getItemDetail = async (itemId) => {
    setisLoading(true);
    await getMenuItemWithModById(itemId || menuItemId, brandId, kitchenId)
      .then((res) => {
        setisLoading(false);
        setinitialData(res?.data);
        setModifierData(res?.data?.modsForMenuItems)
      })
      .catch(() => {
        setisLoading(false);
        closeModal();
      });
  };

  const onItemBasicInfoComplete = (data) => {
    brandCtx.saveMenuItemId(data.id || initialData?.id || menuItemId);
    //isBasicDataComplete = true;
    setinitialData({ ...data });
    setactiveStep(2);
  };

  const onItemModifierComplete = () => {
    closeModal();
  };

  const closeModal = () => {
    brandCtx.menuItemModalStatusChange(false);
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteMenuItem(initialData.id)
      .then(() => {
        setdeleteLoading(true);
        closeDeleteModal();
        closeModal();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
    if (step === 2) {
      brandCtx.saveMenuItemId(initialData?.id || menuItemId);
      if (initialData) setactiveStep(2);
    }
  };

  const saveModifierData = (data) => {
    setModifierData(data);
  };

  return (
    <>
      {isLoading ? (
        <LoaderBlock />
      ) : (
        <div className="createcatTbs__wrapper">
          <Tab.Container
            id="addMenu__item__modal__Tabs"
            defaultActiveKey="first"
            mountOnEnter
            unmountOnExit
          >
            <Row className="no-gutters">
              <Col sm={12}>
                <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      active={activeStep === 1}
                      onClick={() => onTabChange(1)}
                    >
                      {modalExtras?.viewMode && ""}
                      {modalExtras?.editMode && "Edit"}
                      {!modalExtras?.editMode &&
                      !modalExtras?.cloneMode &&
                      !modalExtras?.viewMode
                        ? "Create"
                        : null}{" "}
                      Menu Item
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      active={activeStep === 2}
                      onClick={() => onTabChange(2)}
                    >
                      {modalExtras?.viewMode && ""}
                      {modalExtras?.editMode && "Edit"}
                      {!modalExtras?.editMode &&
                      !modalExtras?.cloneMode &&
                      !modalExtras?.viewMode
                        ? "Add"
                        : null}{" "}
                      Modifiers
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    active={activeStep === 1}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div className="tab__mainHeading">
                      <ItemBasicInfo
                        onSave={onItemBasicInfoComplete}
                        onHide={closeModal}
                        initialData={initialData}
                        extras={modalExtras}
                        disabled={isDisabledFields}
                      >
                        {modalExtras &&
                        (modalExtras?.editMode || modalExtras?.cloneMode) ? (
                          <button
                            type="button"
                            onClick={openDeleteModal}
                            className="delete-option"
                          >
                            Delete
                          </button>
                        ) : null}
                      </ItemBasicInfo>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    active={activeStep === 2}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div className="tab__mainHeading body-overflow">
                      <ItemModifier
                        onSave={onItemModifierComplete}
                        onHide={closeModal}
                        onChangeTab={saveModifierData}
                        initialData={modifierData}
                        extras={modalExtras}
                        disabled={isDisabledFields}
                        onReload={getItemDetail}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Menu Item"
          subtitle="Are you sure want to delete this menu item"
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

export default ItemForm;
