import React from 'react';

const DotsLoader = () => {
  return (
    <div className="loader">
    <div className="dot dot1"></div>
    <div className="dot dot2"></div>
    <div className="dot dot3"></div>
  </div>
  );
};
export default DotsLoader;
