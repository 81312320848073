import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dateFormat from "dateformat";
import { ModifierType } from "constants/modifierType";
import { updateKitchenModOptionStatus } from "services/api/modifier";
import toastify from "shared/toastr";

const RestaurantModifierRow = (props) => {
  const modifier = props.modifier;
  const [status, setstatus] = useState(modifier.isActive);

  useEffect(() => {
    if (modifier) {
      setstatus(modifier?.isActive);
    }
  }, [modifier]);

  const checkModType = (type) => {
    switch (type) {
      case ModifierType.FOOD:
        return "Food Modifier";
      case ModifierType.DRINK:
        return "Drink Modifier";
      default:
        return "NA";
    }
  };

  const onChangeStatus = async () => {
    const masks = {
      kitchenId: props?.kitchenId,
      modOptionId: modifier.modOptionId,
      isActiveStatus: !status,
    };
    await updateKitchenModOptionStatus(masks)
      .then((res) => {
        setstatus(!status);
        toastify("success", res.message);
      })
      .catch((e) => {
        toastify("error", e.message);
      });
  };

  const truncate = (str) => {
    return str.substring(0, 69) + "...";
  };

  return (
    <>
      <tr role="row" className="mat-row">
        <td className="mat-cell column_1" data-column="OPTION">
          <Link
            to={"/modifiers/" + modifier.modId}
            className="modifierDeatil__link"
          >
            {modifier.modOptionName?.length > 70
              ? truncate(modifier.modOptionName)
              : modifier.modOptionName}
          </Link>
        </td>
        <td className="mat-cell column_2" data-column="GROUP">
          <Link
            to={"/modifiers/" + modifier.modId}
            className="modifierDeatil__link"
          >
            {modifier.modName?.length > 70
              ? truncate(modifier.modName)
              : modifier.modName}
          </Link>
        </td>
        <td className="mat-cell column-3" data-column="GROUP TYPE">
          <Link
            to={"/modifiers/" + modifier.modId}
            className="modifierDeatil__link"
          >
            {checkModType(modifier.modType)}
          </Link>
        </td>
        <td className="mat-cell column-4" data-column="LAST MODIFIED">
          <Link
            to={"/modifiers/" + modifier.modId}
            className="modifierDeatil__link"
          >
            {modifier.modifiedOn
              ? dateFormat(
                  new Date(modifier.modifiedOn + "Z"),
                  "mmm dd, hh:MM TT"
                )
              : "NA"}
          </Link>
        </td>
        <td className="mat-cell column-5" data-column="ACTION">
          <div className="right_content">
            <span
              className={`custom__badge ${
                status ? "badge__red" : "badge__gray"
              }`}
            >
              {status ? "Active" : "Inactive"}
            </span>
            <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
              <button
                type="button"
                className="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="dropdown-button-drop-left"
              ></button>
              <div className="dropdown-menu">
                <div className="dropdown__innerWrapper">
                  <button onClick={onChangeStatus} className="dropdown-item">
                    <span>Set As {status ? "Inactive" : "Active"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

RestaurantModifierRow.propTypes = {
  modifierGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    modifierType: PropTypes.number,
    isActive: PropTypes.bool,
    modifiedOn: PropTypes.string,
    createdOn: PropTypes.string,
  }),
};

export default RestaurantModifierRow;
