import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import searchIcon from "assets/images/icon-search.svg";
import RecipeGuideContext from "store/recipe-guide-context";
import plusIcon from "assets/images/add-icon-white.svg";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import ViewButton from "UI/Button/ViewButton";
import { getAllRecipes } from "services/api/recipeguide";

const SelectRecipePopUp = (props) => {
    const recipeguideCtx = useContext(RecipeGuideContext);
    const [isWizardOpen, setisWizardOpen] = useState(true);
    const [isLoading, setisLoading] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [blurBackdrop, setBlurBackdrop] = useState(null);
    const [recipeList, setRecipeList] = useState([]);
    const [orgRecipeList, setOrgRecipeList] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const getRecipes = async () => {
            try {
              setisLoading(true);
              const res = await getAllRecipes({});
              if (isMounted === true) {
                const recipeList = res.data?.recipes;
                const fileteredRecipes = recipeList.filter((el) => {
                  return el.isActive === true;
                });
                  setRecipeList(fileteredRecipes);
                  setOrgRecipeList(fileteredRecipes);
                  setisLoading(false);
              }
          } catch (err) {
                setRecipeList([]);
                setisLoading(false);
          }
        };
        getRecipes();

        return () => {
            isMounted = false;
        };
    }, []);

    const onSearch = (e) => {
        let searchTerm = e.target.value;
          let searched = orgRecipeList.filter((recipe) =>
            recipe.title.toLowerCase().includes(searchTerm.toLowerCase())
          );
            setRecipeList(searched);
    };

    const openRecipeModal = () => {
        recipeguideCtx.recipeModalStatusChange(true);
        props.onHide();
    };

    const selectModGroup = (recipe, evt) => {
        if (recipe) {
            setSelectedRecipe(recipe);
        }
    };

    const setRecipeModalSatus = () => {
        props.onHide();
        recipeguideCtx.recipeModalStatusChange(true, {
            type: "select",
            isEditMode: false,
            isViewMode: true,
            data: selectedRecipe,
        });
    };

    const onView = (recipe) => {
        recipeguideCtx.recipeModalStatusChange(true, {
            type: "select",
            isEditMode: false,
            isViewMode: true,
            data: recipe,
        });
        setBlurBackdrop("modal-backdrop");
    };

    return (
        <Modal
            {...props}
            id="modifierOptionsModal"
            centered
            className={`recipeSelectionModal ${blurBackdrop}`}
        >
            {/*  <div className="col-12 px-0"> */}
            <Modal.Body className="py-0 px-0">
                <div className="accordion" id="modifierGroup">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                                <button
                                    className="btn btn-block text-left"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Select Recipe
                                </button>
                            </h2>
                        </div>
                        <div
                            id="collapseOne"
                            className={`collapse ${isWizardOpen ? "show" : ""}`}
                            aria-labelledby="headingOne"
                            data-parent="#modifierGroup"
                        >
                            <div className="card-body modifierGroup__cardBody pb-0">
                                <div className="form-row no-gutters">
                                    <div className="col-12">
                                        <div className="modifierGroupFrom__header">
                                            <div className="search_modifier_group">
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="text"
                                                            className="custom-file form-control"
                                                            placeholder="Search recipe"
                                                            onChange={onSearch}
                                                        />
                                                        <button type="button" className="search-btn">
                                                            <span className="icons">
                                                                <img src={searchIcon} alt="plus icon" />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="button__wrapper">
                                                <button
                                                    type="button"
                                                    className="bg-blue-btn"
                                                    onClick={openRecipeModal}
                                                >
                                                    <span className="icons">
                                                        <img src={plusIcon} alt="plus icon" />
                                                    </span>
                                                    New Recipe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table modifierGroupTable itemModifierGroupTable mb-0 w-100">
                                            <thead className="d-block">
                                                <tr>
                                                    <th scope="col">
                                                        Recipe Name
                                                    </th>
                                                    {/* <th></th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="selectrecipe-tbody">
                                                {recipeList && recipeList.length ? (
                                                    recipeList.map((recipe, idx) => (
                                                        <tr key={idx} className="w-100">
                                                            <td className="w-100 pt-1 pb-1">
                                                                <input
                                                                    type="button"
                                                                    value={recipe.title}
                                                                    className="btn__no__style item-style"
                                                                    onClick={(evt) =>
                                                                        selectModGroup(recipe, evt)
                                                                    }
                                                                />
                                                                <ViewButton onClick={() => onView(recipe)} width={20} height={20} />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                ) : isLoading ? (
                                                    <>
                                                    <ShimmerLoader />
                                                    </>
                                                ) : (
                                                    <div className="content-desc">
                                                        <h6>No Recipes Found</h6>
                                                        <p>Search with a different Recipe Name or Add a New Recipe</p>
                                                    </div>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  </div> */}
            </Modal.Body>
            <Modal.Footer className="py-0">
                <button
                    type="button"
                    className="cancel-btn red-btn-ghost btn"
                    onClick={props.onHide}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="red-btn next-btn btn"
                    disabled={isLoading || !selectedRecipe}
                    onClick={() => setRecipeModalSatus()}
                >
                    Add Recipe
                    <span className="icons-right">
                        <img src={rightArrowIcon} alt="arrow icon" />
                    </span>
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default SelectRecipePopUp;