import React from "react";
import { Link } from "react-router-dom";

import "../onboarding/Onboarding.scss";
import "../MyAccount/MyAccount.scss";

import emailicon from "assets/images/icon-email.svg";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import btnWhiteArrow from "../../assets/images/white-right-arrow-btn.svg";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const ChangePassword = () => {
  return (
    <div className="changePassword___wrapper">
      <div className="users__wrapper">
        <div className="modifierDetails__wrapper modifierEdit__wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="goBack">
                  <Link to="/account" className=" back-btn-red">
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </Link>
                </div>
              </div>
              <div className="col-12">
                <div className="main_heading">
                  <h1>Change Password</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="details__wrapper editModifier__wrapper">
                  <div className="details__wrapper__inner">
                    <form>
                      <div className="basic__info__content">
                        <div className="form-row">
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="password">
                              Old Password<span className="mendatory">*</span>
                            </label>
                            <input
                              type="password"
                              id="password"
                              placeholder="Enter your password"
                              maxLength="50"
                              className="form-control"
                            />
                            <span className="input-icon">
                              <img src={passwordicon} alt="showPassword" />
                            </span>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label htmlFor="password">
                              New Password<span className="mendatory">*</span>
                            </label>
                            <input
                              type="password"
                              id="password"
                              placeholder="Enter your password"
                              maxLength="50"
                              className="form-control"
                            />
                            <span className="input-icon">
                              <img src={passwordicon} alt="showPassword" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 px-0">
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="cancel-btn red-btn-ghost btn"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="red-btn next-btn btn"
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
