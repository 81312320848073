import React, { useContext, useEffect, useState } from "react";

import { Tab, Nav, Col, Row } from "react-bootstrap";
import VBBasicInfoForm from "./VBBasicInfoForm";
import VBAvailabilityForm from "./VBAvailabilityForm";
import VirtualBrandContext from "store/virtual-brand-context";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import {
  addVirtualBrandBasicInfo,
  editVirtualBrandBasicInfo,
  getVirtualBrand,
} from "services/api/brand";
import LoaderBlock from "shared/loader/LoaderBlock";

const VBForm = () => {
  const brandCtx = useContext(VirtualBrandContext);
  const brandId = brandCtx.virtualBrandId;
  const brandMode = brandCtx.virtualBrandMode;

  const [basicData, setbasicData] = useState(null);
  const [availData, setAvailData] = useState(null);
  let availabilityData = null;

  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);

  const modalStatus = brandCtx.vbModalStatus;
  const modalExtras = brandCtx.modalExtras;

  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);  
  const [isLoadingOnGet, setisLoadingOnGet] = useState(false);

  useEffect(() => {
    if (brandMode) {
      if (
        brandMode === VirtualBrandMode.SHARED ||
        brandMode === VirtualBrandMode.VIEW
      ) {
        setisFieldsDisabled(true);
      } else {
        setisFieldsDisabled(false);
      }
    }
  }, [brandMode]);

  useEffect(() => {
    if (brandId) {
      getBrandDetails();
    }
  }, [brandId]);

  const getBrandDetails = async () => {
    setisLoadingOnGet(true);
    await getVirtualBrand(brandId)
      .then((res) => {
        setbasicData(res.data);
        setAvailData(res.data.virtualBrandAvailability);
        brandCtx.setVirtualBrandName(res.data.name);
        setisLoadingOnGet(false);
      })
      .catch(() => {
        setisLoadingOnGet(false);
      });
  };

  useEffect(() => {
    if (modalStatus === true) {
      if (modalExtras && modalExtras?.activeStep)
        setactiveStep(modalExtras?.activeStep);
      if (modalExtras && (modalExtras?.isEditMode || modalExtras?.isViewMode)) {
        setbasicData(modalExtras?.data);
        setAvailData(modalExtras?.data?.virtualBrandAvailability);
      }
    }
  }, [modalStatus]);

  const onVBBasicInfoComplete = (data) => {
    setbasicData({ ...data });
    setactiveStep(2);
  };

  const onVBAvailabilityComplete = (data) => {
    availabilityData = data;
    saveInfo();
  };

  const saveInfo = async () => {
    if (brandMode === VirtualBrandMode.VIEW || brandMode === VirtualBrandMode.SHARED) {
      closeModal();
      return;
    }
    let model = {
      brandId: brandId,
      ...basicData,
      ...availabilityData,
    };
    setisLoading(true);
    if (brandId && modalExtras?.isEditMode) {
      await editVirtualBrandBasicInfo(model)
        .then((res) => {
          brandCtx.setVirtualBrandInfo(res.data);
          closeModal();
          setisLoading(false);
        })
        .catch(() => setisLoading(false));
    } else {
      await addVirtualBrandBasicInfo()
        .then((res) => {
          brandCtx.setVirtualBrandInfo(res.data);
          closeModal();
          setisLoading(false);
        })
        .catch(() => setisLoading(false));
    }
  };

  const closeModal = () => {
    brandCtx.vbModalStatusChange(false);
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
    if (step === 2) {
      if (basicData) setactiveStep(2);
    }
  };

  const saveAvailTimings = (data) => {
    setAvailData(data);
  };

  return (
    <>
      {isLoadingOnGet ? (
        <LoaderBlock />
      ) : (
      <div className="createMenustabs">
        <Tab.Container
          id="addMenu__item__modal__Tabs"
          defaultActiveKey="first"
          mountOnEnter
          unmountOnExit
        >
          <Row className="no-gutters">
            <Col sm={12}>
              <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    active={activeStep === 1}
                    onClick={() => onTabChange(1)}
                  >
                    {modalExtras?.isEditMode && "Edit"}
                    {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                      ? "Add"
                      : null}{" "}
                    Virtual Brand Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    active={activeStep === 2}
                    onClick={() => onTabChange(2)}
                  >
                    Availability
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane
                  eventKey="first"
                  active={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading">
                    <VBBasicInfoForm
                      onSave={onVBBasicInfoComplete}
                      onHide={closeModal}
                      initialData={basicData}
                      disabled={isFieldsDisabled}
                    >
                    </VBBasicInfoForm>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  active={activeStep === 2}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading body-overflow">
                    <div className="CreateMenuAvailabity__wrapper basic__info__content mb-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="main-heading">
                            <h2>
                              Virtual Brand Availability
                              <span className="mendatory">*</span>
                            </h2>
                            <p>
                              Which days and times would you like to receive
                              orders from this menu?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <VBAvailabilityForm
                      onSave={onVBAvailabilityComplete}
                      loader={isLoading}
                      onHide={closeModal}
                      initialData={availData}
                      onChangeTab={saveAvailTimings}
                      disabled={isFieldsDisabled}
                    >
                    </VBAvailabilityForm>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      )}
    </>
  );
};

export default VBForm;
