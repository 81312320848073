import React, {useState} from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import "../../../UI/Modal/PopUpModal.scss";
import { Link, withRouter } from "react-router-dom";
import LoaderInline from "shared/loader/LoaderInline";
import doordashImg from "assets/images/Doordash1.svg";
import grubhubImg from "assets/images/Grubhub1.svg";
import ubereatsImg from "assets/images/Ubereats1.svg";
import postmatesImg from "assets/images/Postman1.svg";
import fooddudesImg from "assets/images/Fooddudes1.svg";
import BHBImg from "assets/images/BB.png";
import { ServiceType } from "constants/serviceType";
import Ranger from "UI/Ranger/ProgressBar";

const PrepTimeModal = (props) => {
    const minPrepTime = 5;
    const maxPrepTime = 90;
    const data = props?.data;
    const [prepTimeValue, setprepTimeValue] = useState(minPrepTime);


    const checkServiceType = (item) => {
        if (ServiceType.DOORDASH.includes(item))
            return doordashImg;
        else if (ServiceType.GRUBHUB.includes(item))
            return grubhubImg;
        else if (ServiceType.UBEREATS.includes(item))
            return ubereatsImg;
        else if (ServiceType.POSTMATES.includes(item))
            return postmatesImg;
        else if (ServiceType.FOODDUDES.includes(item))
            return fooddudesImg;
        else
            return BHBImg;
    };

    const onPrepTimeChange = (val) => {
        setprepTimeValue(val);
      };

    const onSubmit = () => {
        props.onConfirm(prepTimeValue);
    }

    return (
        <>
            <div className="confirmation__wrapper">
                <Modal
                    show={props.show}
                    onHide={props.onCancel}
                    animation={false}
                    id="confirmation__modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="second-modal"
                >
                    <div className="img-box">
                        <img
                            src={checkServiceType(data?.deliveryService?.name.toLowerCase())}
                            alt="icon"
                            className="img-fluid"
                        />
                    </div>
                    <Modal.Header closeButton>
                        <Modal.Title id="confirmation__heading">{data?.orderItems?.length + " item(s) for " + data?.customer?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <p className="mb-0">{props.onLoading ? <LoaderInline /> : "Prep time is " + prepTimeValue} minutes</p>
                        <div className="mt-3 pt-3">
                        <Ranger
                            value={prepTimeValue}
                            setRadius={onPrepTimeChange}
                            minDistance={minPrepTime}
                            maxDistance={maxPrepTime}
                            label="Min"
                        />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button
                            onClick={props.onCancel}
                            className="cancel-btn red-btn-ghost btn"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onSubmit}
                            className="red-btn btn"
                            disabled={props.onLoading}
                        >
                            {props.onLoading ? <LoaderInline /> : "Yes, Continue"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

PrepTimeModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLoading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
};

export default PrepTimeModal;
