import React from "react";

import ChangeStatus from "pages/device/change-status/ChangeStatus";
import { DeviceType } from "constants/deviceType";
import { useContext } from "react";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";

const DeviceRow = (props) => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const device = props?.device;

  const checkDeviceType = (type) => {
    switch (type) {
      case DeviceType.PRINTER:
        return "Printer";
      case DeviceType.TABLET:
        return "Tablet";
      default:
        return "NA";
    }
  };

  const refreshList = () => {
    props?.refreshList(device);
  };

  return (
    <tr role="row" className="mat-row">
      <td className="mat-cell column-1" data-column="DEVICE">
        {device?.name}
      </td>
      <td className="mat-cell column-2" data-column="DEVICE ID">
        {device?.deviceId}
      </td>
      <td className="mat-cell column-3" data-column="TYPE">
        {checkDeviceType(device?.type)}
      </td>
      <td className="mat-cell column-5" data-column="ACTION">
        <div className="table__actions">
          <ChangeStatus
            needDropdown={
              ![RoleType.KITCHEN_STAFF, RoleType.KITCHEN_OWNER].includes(
                userRole
              )
            }
            deviceId={device?.id}
            deviceStatus={device?.deviceStatusId}
            onComplete={refreshList}
          />
        </div>
      </td>
    </tr>
  );
};

export default DeviceRow;
