import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "logo.svg";
import "./AuthHeader.scss";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["header"];
  if (scrolled) {
    x.push("sticky");
  }

  if (window.location.pathname !== "/")
    return (
      <div className={x.join(" ")}>
        <div className="header-container">
          <div className="header-wrapper">
            <div className="header-left">
              <div className="logo auth">
                <Link to="/home">
                  <img src={logo} alt="Backhouse Brands" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={x.join(" ")}>
      <div className="header-container">
        <div className="header-wrapper">
          <div className="header-left">
            <div className="logo auth">
              <Link to="/home">
                <img src={logo} alt="Backhouse Brands" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
