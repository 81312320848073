import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { DeviceType } from "constants/deviceType";
import ChangeStatus from "../change-status/ChangeStatus";

const ListingRow = (props) => {
  const device = props.device;
  const refreshList = props.refreshList;

  const checkDeviceType = (type) => {
    switch (type) {
      case DeviceType.PRINTER:
        return "Printer";
      case DeviceType.TABLET:
        return "Tablet";
      default:
        return "NA";
    }
  };

  return (
    <tr role="row" className="mat-row">
      <td className="mat-cell column-6" data-column="RESTURANT NAME">
        <Link to={"/device/" + device.id} className="modifierDeatil__link">
          {device.kitchenName}
        </Link>
      </td>
      <td className="mat-cell column-7" data-column="DEVICE">
        <Link to={"/device/" + device.id} className="modifierDeatil__link">
          {device.name}
        </Link>
      </td>
      <td className="mat-cell column-8" data-column="DEVICE">
        <Link to={"/device/" + device.id} className="modifierDeatil__link">
          {device.deviceId}
        </Link>
      </td>
      <td className="mat-cell column-9" data-column="TYPE">
        <Link to={"/device/" + device.id} className="modifierDeatil__link">
          {checkDeviceType(device.type)}
        </Link>
      </td>
      <td className="mat-cell column-10" data-column="ACTION">
        <ChangeStatus
          deviceId={device.id}
          deviceStatus={device.deviceStatusId}
          onComplete={refreshList}
        />
      </td>
    </tr>
  );
};

ListingRow.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.number,
    kitchenName: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.number,
    deviceStatusId: PropTypes.bool,
  }),
  refreshList: PropTypes.func,
};

export default ListingRow;
