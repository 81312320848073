import React, { useContext } from "react";
import RecipeGuideInfo from "../view/RecipeGuideInfo";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import CreateRecipesTabs from "./CreateRecipesTabs";
import RecipeGuideContext from "store/recipe-guide-context";
import { useParams } from "react-router";

const CreateRecipes = (props) => {
  const skipStep = () => props.nextStep(recipeGuideId);
  const recipeguideCtx = useContext(RecipeGuideContext);
  const isItLastPage = recipeguideCtx.isConfirmPage;
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;
  const step = props?.step;
  const { recipeGuideId } = useParams("recipeGuideId");
  const nextStep = () => props.nextStep(recipeGuideId);

  return (
    <>
      <div className="createmenu__wrapper">
        <div className="inner__wrapper">
          <RecipeGuideInfo />
          <CreateRecipesTabs />
        </div>
      </div>
      {step === 4 || [VirtualBrandMode.EDIT].includes(recipeGuideMode) ? <div className="multiForm__actions">
        <button
          style={{ float: "right" }}
          className="confirm-red-btn"
          type="button"
          onClick={props.onConfirm}
        >
          Confirm & Create
          <span className="icons-right">
            <img src={rightArrowIcon} alt="arrow icon" />
          </span>
        </button>
      </div> : 
      [VirtualBrandMode.ADD].includes(recipeGuideMode) ? (
        <div className="multiForm__actions">
          {recipeGuideMode === VirtualBrandMode.SHARED ||
            recipeGuideMode === VirtualBrandMode.VIEW ? null : (
            <button className="skip__link" type="button" onClick={skipStep}>
              Skip this Step
            </button>
          )}
          <button
            style={{ float: "right" }}
            className="red-btn"
            type="button"
            onClick={nextStep}
          >
            Continue
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default CreateRecipes;
