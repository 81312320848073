import React, { useState } from "react";
import ForgetPassword from "../forget-password/ForgetPassword";
import Otp from "../otp/Otp";
import ResetPassword from "../reset-password/ResetPassword";

const PasswordWrapper = () => {
  const passwordWorkflow = [
    {
      step: 1,
      component: ForgetPassword,
    },
    {
      step: 2,
      component: Otp,
    },
    {
      step: 3,
      component: ResetPassword,
    },
  ];

  const [activeStep, setactiveStep] = useState(1);
  const [email, setemail] = useState(null);
  let ActiveComponent = passwordWorkflow[activeStep - 1].component;

  const changeComponent = (step) => {
    ActiveComponent = passwordWorkflow[step - 1].component;
  };

  const goToNextStep = () => {
    setactiveStep(activeStep + 1);
    changeComponent(activeStep);
  };

  const goToPrevStep = () => {
    setactiveStep(activeStep - 1);
    changeComponent(activeStep);
  };

  const setEmailAddress = (val) => {
    setemail(val);
  };

  return (
    <>
      {ActiveComponent && (
        <ActiveComponent
          email={email}
          nextStep={goToNextStep}
          prevStep={goToPrevStep}
          setEmail={setEmailAddress}
        />
      )}
    </>
  );
};

export default PasswordWrapper;
