import React, { useEffect, useState } from "react";
import Select from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: () => ({
    innerHeight: 48,
    height: 48,
    outerWidth: "100%",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const SelectDropdown = (props) => {
  const options = props.options;
  const placeholder = props.placeholder;
  const sendSelectedValue = props.selectedValue;
  const [value, setvalue] = useState(null);

  useEffect(() => {
    if (props && props.options && props.options.length) {
      let findVal = options.find((res) => res.value === props.value);
      setvalue(findVal);
    }
  }, [props]);

  const onSelectChange = (value) => {
    setvalue(value);
    sendSelectedValue(value);
  };

  return (
    <>
      <Select
        options={options}
        styles={customStyles}
        closeMenuOnSelect
        closeMenuOnScroll
        isMulti={false}
        isSearchable={false}
        loadingMessage={"loading..."}
        placeholder={placeholder}
        onChange={onSelectChange}
        value={value}
        isClearable={false}
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        classNamePrefix="custom-select-ckc"
      />
    </>
  );
};

export default SelectDropdown;
