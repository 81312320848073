export const ModifierType = {
  FOOD: 0,
  DRINK: 1,
};

export const ModifierTypeDropdown = [
  {
    value: ModifierType.FOOD,
    text: "Food Modifier",
  },
  {
    value: ModifierType.DRINK,
    text: "Drink Modifier",
  },
];
