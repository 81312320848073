import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router";
import ListingRow from "../listing/AttachmentsListingRow";
import LoaderBlock from "shared/loader/LoaderBlock";
import { deleteRecipeGuideResources, getAssetsByRecipeGuideId } from "services/api/recipeguide";
import DeleteModal from "UI/Modal/DeleteModal";
import toastify from "shared/toastr";
import emptyIocn from "assets/images/emptyIcon.svg";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import plusIconRed from "assets/images/add-redIcon.svg";
import { Link } from "react-router-dom";
import RecipeGuideContext from "store/recipe-guide-context";
import { VirtualBrandMode } from "constants/virtualBrandMode";

const AttachmentsModal = (props) => {
    const [deleteModal, setdeleteModal] = useState(false);
    const [idxValue, setidxValue] = useState(null);
    const [removedFile, setremovedFile] = useState(null);
    const [deleteLoading, setdeleteLoading] = useState(false);
    const [attachments, setattachments] = useState();
    const [isresourceLoading, setisresourceLoading] = useState(false);
    const { recipeGuideId } = useParams("recipeGuideId");
    const { brandId } = useParams("brandId");
    const recipeguideCtx = useContext(RecipeGuideContext);
    const modalExtras = recipeguideCtx.modalExtras;
    const modalStatus = recipeguideCtx.attachmentsModalStatus;
    const recipeGuideMode = recipeguideCtx.recipeGuideMode;

    useEffect(() => {
        if (modalStatus === true) {
            if (recipeGuideId && !modalExtras?.data) getRecipeGuideResources();
            else setattachments(modalExtras?.data);
        }
    }, [recipeGuideId, modalStatus]);

    const getRecipeGuideResources = async () => {
        setisresourceLoading(true);
        await getAssetsByRecipeGuideId(recipeGuideId).then((res) => {
            setisresourceLoading(false);
            let data = res.data?.recipeGuideResources;
            if (data) {
                setattachments(data);
            }
        })
            .catch(() => {
                setisresourceLoading(false);
            });
    };

    const deleteFile = (idx, file) => {
        setdeleteModal(true);
        setidxValue(idx);
        setremovedFile(file);
    }

    const closeDeleteModal = () => setdeleteModal(false);

    const onDelete = async () => {
        const id = attachments[idxValue]?.id || 0;
        attachments.splice(idxValue, 1);
        setattachments(attachments);
        if (id) {
            setdeleteLoading(true);
            await deleteRecipeGuideResources(id)
                .then((res) => {
                    toastify("success", res.message);
                    setdeleteLoading(false);
                    closeDeleteModal();
                    getRecipeGuideResources();
                })
                .catch(() => {
                    setdeleteLoading(false);
                });
        }
        else {
            closeDeleteModal();
        }
    }

    const createResourceLink = () => {
        return `/recipeguide/add/${brandId}/${recipeGuideId}?step=3#add`;
    }

    return (
        <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
            <Modal.Header closeButton>
                <Modal.Title id="addMenu__item__modal">
                    <h1>
                        Attachments {'(' + attachments?.length + ')'}
                    </h1>
                    <div className="confirm_page_header">
                        <p>{attachments?.length ? "Click on the file to download them." : ""}</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="manageUser__landingPage__wrapper" style={{ paddingRight: "25px !important" }}>
                    <div className="managermodifier__wrapper basic__info__content pb-0">
                        {/*  <div className="container"> */}
                        {!attachments?.length ? (
                            <div className="managermodifier__noListing">
                                <div className="managermodifier__noListing__innerwrapper">
                                    <div className="content">
                                        <div className="img-box">
                                            <img
                                                className="img-fluid"
                                                src={emptyIocn}
                                                alt="no attachments icon"
                                                width="185"
                                            />
                                        </div>
                                        <div className="content-desc">
                                            <h3>
                                                No Attachments are added
                                            </h3>
                                            {![VirtualBrandMode.VIEW].includes(recipeGuideMode) && <p>Click on Add More Files to add a new one</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            (<div className="managermodifier__listinPage__wrapper">
                                <table className="table modifierListing__table ordersListing-table">
                                    <tbody>
                                        {isresourceLoading ? (
                                            <tr>
                                                <td>
                                                    <LoaderBlock />
                                                </td>
                                            </tr>
                                        ) : (
                                            attachments.map((file, idx) => (
                                                <ListingRow key={idx} file={file} idx={idx} delete={deleteFile} isDownload={false} />
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                {/* </div> */}
                            </div>)}

                    </div>
                </div>
                {![VirtualBrandMode.VIEW].includes(recipeGuideMode) && <div className="addingredients_options pt-3" onClick={props.onHide}>
                    <Link to={createResourceLink()}
                        className="addModifiers addIngredients-btn"
                    >
                        <span className="icons">
                            <img src={plusIconRed} alt="add" />
                        </span>
                        Add More Files
                    </Link>
                </div>}
                {deleteModal && (
                    <DeleteModal
                        show={deleteModal}
                        title="Delete Attachment"
                        subtitle={"Are you sure want to delete the attachment - " + removedFile?.name}
                        onCancel={closeDeleteModal}
                        onSubmit={onDelete}
                        onLoading={deleteLoading}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                        <button
                            type="button"
                            onClick={props.onHide}
                            className="cancel-btn red-btn-ghost btn"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={props.onHide}
                            className="red-btn next-btn btn"
                        /*   disabled={isLoading || !isValid} */
                        >
                            {/*  {isLoading ? (
                        <LoaderInline />
                    ) : ( */}
                            <>
                                Save
                                <span className="icons-right">
                                    <img src={rightArrowIcon} alt="arrow icon" />
                                </span>
                            </>
                            {/* )} */}
                        </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AttachmentsModal;
