import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import editIcon from "../../assets/images/edit.svg";
import "./ManageModifierLandingpage.scss";
import "./SharedVirtualBrand.scss";

const SharedVirtualBrand = () => {
  return (
    <div className="shared__vbs__wrapper">
      <div className="accordion__header d-none d-md-flex">
        <span>Name</span>
        <span>Actions</span>
      </div>
      <div className="marketplace__accordion__wrapper">
        <div className="market__place__card">
          <div className="shared__vbs__wrapper__inner">
            <div className="accodion__header__content">
              <div className="mobile-screen-label d-md-none">
                <span>Name</span>
              </div>
              <div className="heading">
                <span>Kentucky Fried Chicken</span>
              </div>
              <div className="right__side__content">
                <div className="status__wrapper mr-3">
                  <div className="mobile-screen-label d-md-none">
                    <span>Actions</span>
                  </div>
                  <span className="custom__badge badge__blue table__badge">
                    Pending
                  </span>
                </div>
                <div className="btn-group dropleft threeDotsBropdown drop-icon">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="dropdown-button-drop-left"
                  ></button>
                  <div className="dropdown-menu">
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item active">
                        <span>Active</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Disable</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Draft</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Pending</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <span className="marketPlace__counts">0 Marketplaces</span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="accordion__body__wrapper">
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>URL</label>
                                <p>https://www.ubereats.com/</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-5 pb-3 mb-md-4">
                              <div className="data">
                                <label>API Key</label>
                                <p>zaCELgL.0imfnc8mVLWwsAawjYr4Rx-Af50DDqtlx</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Markerplace Name</label>
                                <p>Uber Eats</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Username</label>
                                <p>George1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Password</label>
                                <p>geowatson1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Markerplace ID</label>
                                <p>745210</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Status</label>
                                <p>Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Markerplace Name</label>
                                <p>Uber Eats</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Username</label>
                                <p>George1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Password</label>
                                <p>geowatson1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Markerplace ID</label>
                                <p>745210</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Status</label>
                                <p>Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        <div className="market__place__card">
          <div className="shared__vbs__wrapper__inner">
            <div className="accodion__header__content">
              <div className="mobile-screen-label d-md-none">
                <span>Name</span>
              </div>
              <div className="heading">
                <span>Kentucky Fried Chicken</span>
              </div>
              <div className="right__side__content">
                <div className="status__wrapper mr-3">
                  <div className="mobile-screen-label d-md-none">
                    <span>Actions</span>
                  </div>
                  <span className="custom__badge badge__blue table__badge">
                    Pending
                  </span>
                </div>
                <div className="btn-group dropleft threeDotsBropdown drop-icon">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="dropdown-button-drop-left"
                  ></button>
                  <div className="dropdown-menu">
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item active">
                        <span>Active</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Disable</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Draft</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Pending</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <span className="marketPlace__counts">13 Marketplaces</span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="accordion__body__wrapper">
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>URL</label>
                                <p>https://www.ubereats.com/</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-5 pb-3 mb-md-4">
                              <div className="data">
                                <label>API Key</label>
                                <p>zaCELgL.0imfnc8mVLWwsAawjYr4Rx-Af50DDqtlx</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Markerplace Name</label>
                                <p>Uber Eats</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Username</label>
                                <p>George1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Password</label>
                                <p>geowatson1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Markerplace ID</label>
                                <p>745210</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Status</label>
                                <p>Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Markerplace Name</label>
                                <p>Uber Eats</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Username</label>
                                <p>George1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Password</label>
                                <p>geowatson1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Markerplace ID</label>
                                <p>745210</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Status</label>
                                <p>Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        <div className="market__place__card">
          <div className="shared__vbs__wrapper__inner">
            <div className="accodion__header__content">
              <div className="mobile-screen-label d-md-none">
                <span>Name</span>
              </div>
              <div className="heading">
                <span>Kentucky Fried Chicken</span>
              </div>
              <div className="right__side__content">
                <div className="status__wrapper mr-3">
                  <div className="mobile-screen-label d-md-none">
                    <span>Actions</span>
                  </div>
                  <span className="custom__badge badge__blue table__badge">
                    Pending
                  </span>
                </div>
                <div className="btn-group dropleft threeDotsBropdown drop-icon">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="dropdown-button-drop-left"
                  ></button>
                  <div className="dropdown-menu">
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item active">
                        <span>Active</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Disable</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Draft</span>
                      </Link>
                    </div>
                    <div className="dropdown__innerWrapper">
                      <Link to="#" className="dropdown-item">
                        <span>Pending</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <span className="marketPlace__counts">9 Marketplaces</span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <div className="accordion__body__wrapper">
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>URL</label>
                                <p>https://www.ubereats.com/</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-5 pb-3 mb-md-4">
                              <div className="data">
                                <label>API Key</label>
                                <p>zaCELgL.0imfnc8mVLWwsAawjYr4Rx-Af50DDqtlx</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Markerplace Name</label>
                                <p>Uber Eats</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Username</label>
                                <p>George1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Password</label>
                                <p>geowatson1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Markerplace ID</label>
                                <p>745210</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Status</label>
                                <p>Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__data__card">
                      <div className="row no-gutters justify-content-between">
                        <div className="col-10 col-md-10">
                          <div className="row">
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Markerplace Name</label>
                                <p>Uber Eats</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Username</label>
                                <p>George1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 mb-md-4">
                              <div className="data">
                                <label>Password</label>
                                <p>geowatson1234</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Markerplace ID</label>
                                <p>745210</p>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 pb-3 pb-md-4">
                              <div className="data">
                                <label>Status</label>
                                <p>Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="edit__button__wrapper">
                            <button className="btn edit-btn m-0">
                              <span>
                                <img src={editIcon} alt="edit-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default SharedVirtualBrand;
