import React from "react";
import { Modal } from "react-bootstrap";
import OrderFilters from "./OrderFilters";

const OrderFilterModal = (props) => {
  const onSubmit = (data) => {
    props.onApplyFilters(data);
  };

  const onHide = () => props.onHide();

  return (
    <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
      <Modal.Header closeButton>
        <Modal.Title id="addMenu__item__modal">
          <h1>{props?.title}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OrderFilters
          filtersData={props.filtersData}
          onHide={onHide}
          type={props.type}
        />
      </Modal.Body>
    </Modal>
  );
};

export default OrderFilterModal;
