import React from "react";
import { Link } from "react-router-dom";
import "./OrdersLandingPage.scss";
import searchIcon from "../../assets/images/icon-search.svg";
import exportIcon from "../../assets/images/export-icon.svg";
import filterIcon from "../../assets/images/Icon_Filter_ckc.svg";
import emptyIocn from "../../assets/images/emptyIcon.svg";
import ubereats from "../../assets/images/ubereats.svg";
import SelectDropdown from "UI/Select-Dropdown/SelectDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const OrdersLandingPage = () => {
  const options = [
    { value: "Kentucky Fried Chicken ", label: "Kentucky Fried Chicken " },
    { value: "Wendy’s", label: "Wendy’s" },
    { value: "McDonalds", label: "McDonalds" },
    { value: "Pizza Hut", label: "Pizza Hut" },
  ];

  return (
    <div className="orders__page__wrapper">
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header">
            <div className="row justify-content-between">
            <div className="col-12 mb-3 pb-3 d-none d-md-block">
              <div className="goBack">
                <Link to="/home" className="back-btn-red" title="back to home">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Go to Dashboard
                </Link>
              </div>
            </div>
              <div className="col-12 mb-4">
                <h1>All Orders</h1>
              </div>
              {/* begin:: filters for web screens */}
              <div className="col-12 d-none d-xl-block">
                <form className="d-flex flex-row">
                  <div className="userselect mr-md-3">
                    <div className="user__custom__select">
                      <SelectDropdown
                        options={options}
                        placeholder="Search Restaurant"
                        classNamePrefix="custom-select-ckc"
                      />
                    </div>
                  </div>
                  <div className="search__modifiers mr-md-3">
                    <div className="search_modifier_group">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="search"
                            id=""
                            className="custom-file form-control"
                            placeholder="Search Customer"
                          />
                          <button className="search-btn">
                            <span className="icons">
                              <img src={searchIcon} alt="search icon" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order__status__multiselect__wrapper mr-md-3">
                    <Dropdown>
                      <Dropdown.Toggle id="allorders">
                        All Orders
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="dropdown__action__wrapper">
                          <div className="main__heading">
                            <p>All Orders</p>
                          </div>
                          <div className="sortBy__orderStatus">
                            <div className="sub__heading">
                              <p>Order Status</p>
                            </div>
                            <div className="row no-gutters">
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    New
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Preparing
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Ready
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Completed
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Cancelled
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sortBy__marketPlaces">
                            <div className="sub__heading">
                              <p>Marketplaces</p>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Uber Eats
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Food Panda
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper d-flex">
                                  <label className="custom-checkbox-container">
                                    Door Dash
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="button__wrapper">
                            <button className="btn apply-btn">Apply</button>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="date__filter__wrapper mr-md-3">
                    <div className="date__filter__inner">
                      <span>
                        <input type="date" name="date" />
                      </span>
                      <span>to</span>
                      <span>
                        <input type="date" name="date" />
                      </span>
                    </div>
                  </div>
                  <div className="export__wrapper">
                    <button className="export-btn btn">
                      <span>
                        <img
                          src={exportIcon}
                          alt="export icon"
                          className="img-fluid icons"
                        />
                      </span>
                      <span>Export CSV</span>
                    </button>
                  </div>
                </form>
              </div>
              {/* end:: filters for web screens */}
              {/* begin:: filters for mobile screens */}
              <div className="order__filters__mobile__screens">
                <div className="col-12 d-xl-none">
                  <form className="d-flex flex-column w-100">
                    <div className="userselect w-100 mb-3">
                      <div className="user__custom__select">
                        <SelectDropdown
                          options={options}
                          placeholder="Search Restaurant"
                        />
                      </div>
                    </div>
                    <div className="filters__wrapper__mobile mb-3">
                      <div className="filters__toggle__mobile">
                        <button className="filters">
                          <span>
                            <img
                              src={filterIcon}
                              alt="export icon"
                              className="img-fluid"
                            />
                          </span>
                        </button>
                      </div>
                      <div className="export__wrapper">
                        <button className="export-btn btn">
                          <span>
                            <img
                              src={exportIcon}
                              alt="export icon"
                              className="img-fluid icons"
                            />
                          </span>
                          <span>Export CSV</span>
                        </button>
                      </div>
                    </div>
                    <div className="filters__hidden__options">
                      <div className="row">
                        <div className="col-12 col-md-7">
                          <div className="d-flex search_n_orderFilters">
                            <div className="search__modifiers">
                              <div className="search_modifier_group">
                                <div className="input-group">
                                  <div className="custom-file">
                                    <input
                                      type="search"
                                      id=""
                                      className="custom-file form-control"
                                      placeholder="Search Customer"
                                    />
                                    <button className="search-btn">
                                      <span className="icons">
                                        <img
                                          src={searchIcon}
                                          alt="search icon"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="order__status__multiselect__wrapper">
                              <Dropdown>
                                <Dropdown.Toggle id="allorders">
                                  All Orders
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div className="dropdown__action__wrapper">
                                    <div className="main__heading">
                                      <p>All Orders</p>
                                    </div>
                                    <div className="sortBy__orderStatus">
                                      <div className="sub__heading">
                                        <p>Order Status</p>
                                      </div>
                                      <div className="row no-gutters">
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              New
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Preparing
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Ready
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Completed
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Cancelled
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="sortBy__marketPlaces">
                                      <div className="sub__heading">
                                        <p>Marketplaces</p>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Uber Eats
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Food Panda
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="custom-checkbox-wrapper d-flex">
                                            <label className="custom-checkbox-container">
                                              Door Dash
                                              <input type="checkbox" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="button__wrapper">
                                      <button className="btn apply-btn">
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="date__filter__wrapper">
                            <div className="date__filter__inner">
                              <span>
                                <input type="date" name="date" />
                              </span>
                              <span>to</span>
                              <span>
                                <input type="date" name="date" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* end:: filters for mobile screens */}
            </div>
          </div>
          <div className="managermodifier__noListing d-none">
            <div className="managermodifier__noListing__innerwrapper">
              <div className="content">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src={emptyIocn}
                    alt="no list icon"
                    width="185"
                  />
                </div>
                <div className="content-desc">
                  <h3>No Order Found</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="managermodifier__listinPage orderListing__listinPage">
            <div className="managermodifier__listinPage__wrapper">
              <table className="table modifierListing__table ordersListing-table">
                <thead>
                  <tr role="row" className="mat-header-row">
                    <th scope="col" className="mat-header-cell column-1">
                      Customer
                    </th>
                    <th scope="col" className="mat-header-cell column-2">
                      Order
                    </th>
                    <th scope="col" className="mat-header-cell column-3">
                      Date
                    </th>
                    <th scope="col" className="mat-header-cell column-5">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="mat-row preparing">
                    <td className="mat-cell column-1" data-column="CUSTOMER">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link d-inline-block w-100"
                      >
                        <div className="customer-data mt-1 mt-lg-0">
                          <div className="img-box">
                            <img
                              src={ubereats}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content-box">
                            <p className="order-no">#3245</p>
                            <p className="customer-name">Kate Schultz</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="ORDER">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="kitchen-name">House of Curds</p>
                          <p className="item-name">Texas BBQ Cheese Curds …</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-3" data-column="DATE">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="order-time">4:30 PM</p>
                          <p className="order-date">Nov 25, 2021</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-5" data-column="STATUS">
                      <div className="table__actions">
                        <div className="right_content">
                          <div className="row no-gutters">
                            <div className="col-12">
                              <p className="order-status">Preparing</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row cancelled">
                    <td className="mat-cell column-1" data-column="CUSTOMER">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link d-inline-block w-100"
                      >
                        <div className="customer-data mt-1 mt-lg-0">
                          <div className="img-box">
                            <img
                              src={ubereats}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content-box">
                            <p className="order-no">#3245</p>
                            <p className="customer-name">Kate Schultz</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="ORDER">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="kitchen-name">House of Curds</p>
                          <p className="item-name">Texas BBQ Cheese Curds …</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-3" data-column="DATE">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="order-time">4:30 PM</p>
                          <p className="order-date">Nov 25, 2021</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-5" data-column="STATUS">
                      <div className="table__actions">
                        <div className="right_content">
                          <div className="row no-gutters">
                            <div className="col-12">
                              <p className="order-status">Cancelled</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row completed">
                    <td className="mat-cell column-1" data-column="CUSTOMER">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link d-inline-block w-100"
                      >
                        <div className="customer-data mt-1 mt-lg-0">
                          <div className="img-box">
                            <img
                              src={ubereats}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content-box">
                            <p className="order-no">#3245</p>
                            <p className="customer-name">Kate Schultz</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="ORDER">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="kitchen-name">House of Curds</p>
                          <p className="item-name">Texas BBQ Cheese Curds …</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-3" data-column="DATE">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="order-time">4:30 PM</p>
                          <p className="order-date">Nov 25, 2021</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-5" data-column="STATUS">
                      <div className="table__actions">
                        <div className="right_content">
                          <div className="row no-gutters">
                            <div className="col-12">
                              <p className="order-status">Completed</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row new">
                    <td className="mat-cell column-1" data-column="CUSTOMER">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link d-inline-block w-100"
                      >
                        <div className="customer-data mt-1 mt-lg-0">
                          <div className="img-box">
                            <img
                              src={ubereats}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content-box">
                            <p className="order-no">#3245</p>
                            <p className="customer-name">Kate Schultz</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="ORDER">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="kitchen-name">House of Curds</p>
                          <p className="item-name">Texas BBQ Cheese Curds …</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-3" data-column="DATE">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="order-time">4:30 PM</p>
                          <p className="order-date">Nov 25, 2021</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-5" data-column="STATUS">
                      <div className="table__actions">
                        <div className="right_content">
                          <div className="row no-gutters">
                            <div className="col-12">
                              <p className="order-status">New</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row ready">
                    <td className="mat-cell column-1" data-column="CUSTOMER">
                      <Link
                        to="/resturants-details"
                        className="modifierDeatil__link d-inline-block w-100"
                      >
                        <div className="customer-data mt-1 mt-lg-0">
                          <div className="img-box">
                            <img
                              src={ubereats}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="content-box">
                            <p className="order-no">#3245</p>
                            <p className="customer-name">Kate Schultz</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="ORDER">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="kitchen-name">House of Curds</p>
                          <p className="item-name">Texas BBQ Cheese Curds …</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-3" data-column="DATE">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <p className="order-time">4:30 PM</p>
                          <p className="order-date">Nov 25, 2021</p>
                        </div>
                      </div>
                    </td>
                    <td className="mat-cell column-5" data-column="STATUS">
                      <div className="table__actions">
                        <div className="right_content">
                          <div className="row no-gutters">
                            <div className="col-12">
                              <p className="order-status">Ready</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="table pagination">
                <ul className="pagination justify-content-center justify-content-lg-end">
                  <li className="page-item disabled">
                    <a
                      className="page-link first-child"
                      href="/"
                      tabIndex="-1"
                      aria-disabled="true"
                    >
                      Previous<span className="arrow"></span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="/">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      <span className="arrow"></span>Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersLandingPage;
