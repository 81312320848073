import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { logoutUser } from "services/api/auth";
import UserContext from "store/user-context";

const Logout = (props) => {
  const userCtx = useContext(UserContext);

  useEffect(() => {
    logoutUser();
    props.history.push("/login");
    userCtx.removeUserInfo();
  }, []);
  return null;
};

export default withRouter(Logout);
