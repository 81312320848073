import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Autocomplete from "react-google-autocomplete";
import "../view/ManageResturantsDetails.scss";
import "./EditRestaurant.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import LoaderInline from "shared/loader/LoaderInline";
import LoaderFull from "shared/loader/LoaderFull";
import toastify from "shared/toastr";
import { editKitchen, getKitchenById } from "services/api/kitchen";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import GoogleMap from "UI/Map/GoogleMap";
import Geocode from "react-geocode";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";

const RestaurantForm = (props) => {
  const autoCompleteApiKey = process.env.REACT_APP_GOOGLE_PLACES_API;
  const { restId } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [restaurant, setrestaurant] = useState(null);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [latVal, setLatVal] = useState(null);
  const [lngVal, setLngVal] = useState(null);
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;

  useEffect(() => {
    if (restId) {
      if (parseInt(restId) > 0) {
        getDetail(+restId);
      }
    }
  }, [restId]);

  const validationSchema = Yup.object().shape({
    id: Yup.number().required(),
    businessName: Yup.string()
      .trim()
      .max(50, ({ max }) => `Business name not more than ${max} characters`)
      .required("Please enter business name"),
    businessAddress: Yup.string()
      .trim()
      .max(500, ({ max }) => `Business address not more than ${max} characters`)
      .required("Please enter business address"),
    latitude: Yup.number(),
    longitude: Yup.number(),
    ownerId: Yup.string().required(),
    contactName: Yup.string()
      .trim()
      .max(50, ({ max }) => `Contact name not more than ${max} characters`)
      .required("Please enter contact name"),
    /* phone: Yup.number()
      .typeError("Only digits allowed")
      .positive()
      .min(1000000000, "Phone number must be exactly 10 characters long")
      .max(9999999999, "Phone number must be exactly 10 characters long")
      .required("Please enter the phone number"), */
    phone: Yup.string()
      .transform((value) => (value ? value.replace(/\D/g, "") : ""))
      .test(
        "len",
        "Phone number must be exactly 10 characters long",
        (value) => value && value.length === 10
      )
      .required("Please enter the phone number"),
    listedPhoneNumber: Yup.string()
      .transform((value) => (value ? value.replace(/\D/g, "") : ""))
      .test(
        "len",
        "Phone number must be exactly 10 characters long",
        (value) => value && value.length === 10
      ),
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .max(50)
      .required("Please enter a valid email address"),
    salesTaxIndicator: Yup.boolean().default(false),
    callRestaurantEnabled: Yup.boolean().default(false),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onChangeAddress = (place) => {
    const selectedAddress = document.getElementById("address").value;
    Geocode.setApiKey(autoCompleteApiKey);
    Geocode.fromAddress(selectedAddress)
      .then((response) => {
        const result = response.results[0];
        if (result) {
          setValue("businessAddress", result.formatted_address);
          document.getElementById("address").value = result?.formatted_address;
          setValue("latitude", result.geometry.location.lat);
          setValue("longitude", result.geometry.location.lng);
          setLatVal(result.geometry.location.lat);
          setLngVal(result.geometry.location.lng);
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };

  const getDetail = async (id) => {
    setisLoading(true);
    await getKitchenById(id)
      .then((res) => {
        let data = res.data;
        reset({
          id: data?.id,
          businessName: data?.name,
          businessAddress: data?.businessAddress,
          latitude: data?.latitude,
          longitude: data?.longitude,
          ownerId: data?.kitchenOwner?.id,
          email: data?.kitchenOwner?.email,
          contactName: data?.kitchenOwner?.fullName,
          phone: data?.kitchenOwner?.phoneNumber,
          listedPhoneNumber:
            data?.listedPhoneNumber || data?.kitchenOwner?.phoneNumber,
          salesTaxIndicator: data?.salesTaxIndicator,
          callRestaurantEnabled: data?.callRestaurantEnabled
        });
        setrestaurant(data);
        setLatVal(data?.latitude);
        setLngVal(data?.longitude);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.goBack();
  };

  const onSubmit = async (data) => {
    setisSubmitting(true);
    await editKitchen(data)
      .then((res) => {
        toastify("success", res.message);
        setisSubmitting(false);
        onBack();
      })
      .catch(() => {
        setisSubmitting(false);
      });
  };

  const geocodeData = (lat, lng, type) => {
    Geocode.setApiKey(autoCompleteApiKey);
    Geocode.fromLatLng(lat, lng)
      .then((response) => {
        const result = response.results[0];
        if (result) {
          setValue("businessAddress", result?.formatted_address);
          document.getElementById("address").value = result?.formatted_address;
          if (type === "marker") {
            const location = result?.geometry?.location;
            setValue("latitude", location?.lat);
            setValue("longitude", location?.lng);
            setLatVal(location?.lat);
            setLngVal(location?.lng);
          }
          else {
            setValue("latitude", lat);
            setValue("longitude", lng);
            setLatVal(lat);
            setLngVal(lng);
          }
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    Geocode.setApiKey(autoCompleteApiKey);
    geocodeData(lat, lng, 'marker')
  };

  const onLatLngChange = () => {
    const latEle = document.getElementById("lat");
    const lngEle = document.getElementById("lng");
    if (latEle?.value && lngEle?.value) {
      geocodeData((latEle.value), (lngEle.value), '');
    }
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="modifierDetails__wrapper restaurantDetails__wrapper">
        {isLoading && <LoaderFull />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button onClick={onBack} className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Edit Restaurant</h1>
              </div>
            </div>
            <div className="col-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="details__wrapper edit__restaurant">
                  <div className="details__wrapper__inner">
                    <div className="signcard__wrapper">
                      <div className="row no-gutters">
                        <div className="col-12 col-md-6 col-lg-6">
                          <div className="form-row no-gutters">
                            <div className="col-md-12 mb-3 pb-3">
                              <label htmlFor="bname">
                                Business Name
                                <span className="mendatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="bname"
                                placeholder="e.g. True’s Pizza"
                                maxLength="50"
                                {...register("businessName")}
                                className={`form-control ${
                                  errors.businessName ? "is-invalid" : ""
                                }`}
                              />
                              {errors.businessName?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.businessName?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 mb-3 pb-3">
                              <label htmlFor="address">
                                Business Address
                                <span className="mendatory">*</span>
                              </label>
                              <Autocomplete
                                id="address"
                                apiKey={autoCompleteApiKey}
                                onPlaceSelected={onChangeAddress}
                                inputAutocompleteValue="address"
                                autoComplete="address"
                                placeholder="e.g. 3045 Eagandale Place, Eagan, MN, USA"
                                className={`form-control ${
                                  errors.address ? "is-invalid" : ""
                                }`}
                                defaultValue={restaurant?.businessAddress}
                                options={{ types: ["address"] }}
                              />
                              {errors.businessAddress?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.businessAddress?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 mb-3 pb-3">
                              <div className="detailCard restra__g__map">
                                <GoogleMap
                                  width="100%"
                                  height="243px"
                                  latitude={latVal || 45.552509}
                                  longitude={lngVal || -94.196804}
                                  draggable={true}
                                  onMarkerDragEnd={onMarkerDragEnd}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 pb-3 d-flex">
                              <div className="col-md-6 pl-0">
                                <label htmlFor="lat">
                                  Latitude<span className="mendatory">*</span>
                                </label>
                                <input
                                  id="lat"
                                  placeholder="e.g. Latitude"
                                  {...register("latitude")}
                                  className={`form-control ${
                                    errors.latitude ? "is-invalid" : ""
                                  }`}
                                  onChange={onLatLngChange}
                                />
                                {errors.latitude?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.latitude?.message}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6 pr-0">
                                <label htmlFor="lng">
                                  Longitude<span className="mendatory">*</span>
                                </label>
                                <input
                                  id="lng"
                                  placeholder="e.g. Longitude"
                                  {...register("longitude")}
                                  className={`form-control ${
                                    errors.longitude ? "is-invalid" : ""
                                  }`}
                                  onChange={onLatLngChange}
                                />
                                {errors.longitude?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.longitude?.message}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 pb-3">
                              <label htmlFor="conatctName">
                                Contact Name
                                <span className="mendatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="conatctName"
                                placeholder="e.g. Matthew True"
                                maxLength="50"
                                {...register("contactName")}
                                className={`form-control ${
                                  errors.contactName ? "is-invalid" : ""
                                }`}
                              />
                              {errors.contactName?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.contactName?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 mb-3 pb-3">
                              <label htmlFor="conatctNo">
                                Phone Number
                                <span className="mendatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="conatctNo"
                                placeholder="e.g. 234 567 8907"
                                {...register("phone")}
                                className={`form-control ${
                                  errors.phone ? "is-invalid" : ""
                                }`}
                              />
                              {errors.phone?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.phone?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 mb-3 pb-3">
                              <label htmlFor="listedNo">
                                Order Confirm Phone Number
                              </label>
                              <input
                                type="text"
                                id="listedNo"
                                placeholder="e.g. 234 567 8907"
                                {...register("listedPhoneNumber")}
                                className={`form-control ${
                                  errors.listedPhoneNumber ? "is-invalid" : ""
                                }`}
                              />
                              {errors.listedPhoneNumber?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.listedPhoneNumber?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 mb-3 pb-3">
                              <label htmlFor="email">
                                Email Address
                                <span className="mendatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="email"
                                placeholder="Enter your email address"
                                maxLength="50"
                                {...register("email")}
                                className={`form-control ${
                                  errors.email ? "is-invalid" : ""
                                }`}
                              />
                              {errors.email?.message && (
                                <div className="invalid-feedback d-block">
                                  {errors.email?.message}
                                </div>
                              )}
                            </div>
                            {userRole === RoleType.ADMIN && (
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper">
                                  <label className="custom-checkbox-container">
                                    <input
                                      type="checkbox"
                                      {...register("salesTaxIndicator")}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label
                                    htmlFor="salesTaxIndicator"
                                    style={{ paddingLeft: "24px" }}
                                  >
                                    Pay Sales Tax
                                  </label>
                                </div>
                                {errors.salesTaxIndicator?.message && (
                                  <div className="invalid-feedback d-block position-absolute">
                                    {errors.salesTaxIndicator?.message}
                                  </div>
                                )}
                              </div>
                            )}
                            {[
                              RoleType.ADMIN,
                              RoleType.ADMIN_STAFF,
                              RoleType.KITCHEN_OWNER,
                              RoleType.KITCHEN_STAFF,
                            ].includes(userRole) && (
                              <div className="col-md-12 mb-3">
                                <div className="custom-checkbox-wrapper">
                                  <label className="custom-checkbox-container">
                                    <input
                                      type="checkbox"
                                      {...register("callRestaurantEnabled")}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <label
                                    htmlFor="callRestaurantEnabled"
                                    style={{ paddingLeft: "24px" }}
                                  >
                                    Call to Confirm Order
                                  </label>
                                </div>
                                {errors.callRestaurantEnabled?.message && (
                                  <div className="invalid-feedback d-block position-absolute">
                                    {errors.callRestaurantEnabled?.message}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-md-3 pb-md-4">
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={onBack}
                          className="cancel-btn red-btn-ghost btn"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="red-btn next-btn btn"
                          disabled={!isValid || isSubmitting}
                        >
                          {isSubmitting ? <LoaderInline /> : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestaurantForm;
