import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import "./SidebarMenu.scss";
import menuIcon from "assets/images/menu-icon.svg";
import menuCross from "assets/images/menu-cross.svg";
import logo from "logo.svg";
import dashboardIcon from "assets/images/dashboard-icon.svg";
import dashboardIconHover from "assets/images/dashboard-hover.svg";
import manageModifier from "assets/images/manage-modifier.svg";
import manageModifierHover from "assets/images/manage-modifiers-hover.svg";
import manageUser from "assets/images/manage-user.svg";
import manageUserHover from "assets/images/manage-users-hover.svg";
import settings from "assets/images/settings-icon.svg";
import settingsHover from "assets/images/settings-icon-hover.svg";
import myAccount from "assets/images/my-account.svg";
import myAccountHover from "assets/images/my-account-hover.svg";
import logout from "assets/images/logout.svg";
import logoutHover from "assets/images/logout-hover.svg";
import manageRestaurants from "assets/images/restaurant_grey.svg";
import manageRestaurantsHover from "assets/images/restaurant_red.svg";
import order from "assets/images/ico-order-inactive.svg";
import orderHover from "assets/images/ico-order-active.svg";
import report from "assets/images/reports-non-hover.svg";
import reportHover from "assets/images/reports-hover.svg";
import manageDevices from "assets/images/manageDevices.svg";
import manageDevicesHover from "assets/images/manageDevicesHover.svg";
import { RoleType } from "constants/roleType";
import UserContext from "store/user-context";

const sidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: [dashboardIcon, dashboardIconHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      //RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER
    ],
  },
  {
    title: "Manage Users",
    path: "/staff",
    icon: [manageUser, manageUserHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF
    ],
  },
  {
    title: "Manage Customers",
    path: "/customer",
    icon: [manageUser, manageUserHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF
    ],
  },
  {
    title: "Manage Modifiers",
    path: "/modifiers",
    icon: [manageModifier, manageModifierHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      //RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER
    ],
  },
  {
    title: "Manage Restaurants",
    path: "/restaurants",
    icon: [manageRestaurants, manageRestaurantsHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      //RoleType.KITCHEN_STAFF,
    ],
  },
  {
    title: "Manage Devices",
    path: "/devices",
    icon: [manageDevices, manageDevicesHover],
    cName: "nav__text",
    accessor: [RoleType.ADMIN, RoleType.ADMIN_STAFF],
  },
  {
    title: "Manage Recipes",
    path: "/recipes",
    icon: [order, orderHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER
    ],
  },
  {
    title: "Orders",
    path: "/orders",
    icon: [order, orderHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER
    ],
  },
  {
    title: "Reports",
    path: "/report",
    icon: [report, reportHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER
    ],
  },
  {
    title: "My Account",
    path: "/profile",
    icon: [myAccount, myAccountHover],
    cName: "nav__text",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER
    ],
  },
  {
    title: "Settings",
    path: "/settings",
    icon: [settings, settingsHover],
    cName: "nav__text last__tab",
    accessor: [
      RoleType.ADMIN,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_OWNER,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    title: "Logout",
    path: "/logout",
    icon: [logout, logoutHover],
    cName: "nav__text logout",
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER
    ],
  },
];

const SidebarMenu = () => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div id="mySidenav" className="sidenav">
      <div className="navbar">
        <Link to="#" className="sidenav__close" role="button">
          <img src={menuIcon} alt="burger menu" onClick={handleShow} />
        </Link>
      </div>
      <Modal show={show} onHide={handleClose} className="left" id="sidebarnav">
        <Modal.Body>
          <nav className={show ? "nav__menu active" : "nav__menu"}>
            <ul className="nav__menu__items">
              <li className="navbar__toggle">
                <Link
                  to="#"
                  className="menu__bars"
                  role="button"
                  onClick={handleClose}
                >
                  <img src={menuCross} alt="cross icon" />
                </Link>
                <span className="sidebar__logo">
                  <img src={logo} alt="logo" />
                </span>
              </li>
              {sidebarData.map((item, index) => {
                if (!item.accessor.includes(userRole)) {
                  return null;
                } else
                  return (
                    <li key={index} className={item.cName}>
                      <NavLink
                        activeClassName="active"
                        to={item.path}
                        onClick={handleClose}
                      >
                        <span className="icons">
                          <img src={item.icon[0]} alt="icon" />
                          <img
                            src={item.icon[1]}
                            alt="icon"
                            className="hover-icon"
                          />
                        </span>
                        <span>{item.title}</span>
                      </NavLink>
                    </li>
                  );
              })}
            </ul>
          </nav>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SidebarMenu;
