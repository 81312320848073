const getFileNameFromUrl = (file) => {
  let returnedFileName = null;
  if (file && file !== "") {
    let urlSplits = file?.split("/");
    returnedFileName = urlSplits[urlSplits.length - 1];
  }
  return returnedFileName;
};

export default getFileNameFromUrl;
