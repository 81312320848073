import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import RecipeForm from "../recipeguide-form/RecipeForm";
import "./RecipeModal.scss";
import RecipeGuideContext from "store/recipe-guide-context";

const RecipeModal = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const modalExtras = recipeguideCtx.modalExtras;

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
        <Modal.Header closeButton>
          <Modal.Title id="addMenu__item__modal">
            <h1>
              {modalExtras?.isEditMode && "Edit"}
              {modalExtras?.isViewMode && "View"}
              {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                ? "Add New"
                : null}{" "}
              Recipe
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RecipeForm/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RecipeModal;
