import { privateApi } from "../utilities/Provider";
import dateFormat from "dateformat";

const basicUrl = "/Menu/";
// YYYY-MM-DD
const getAllCustomers = ({
  pageSize,
  pageNum,
  kitchenId,
  serviceId,
  orderStatus,
  dateTo,
  dateFrom,
  name,
  marketPlaceStatus,
  brandIds
}) => {
  const params = new URLSearchParams();

  if (marketPlaceStatus) params.append('platformIds', marketPlaceStatus);
  if (orderStatus) params.append('orderStatusIds', orderStatus);
  if (brandIds) params.append("virtualBrandIds", brandIds);
  if (dateFrom) {
    let frmtDate = dateFormat(new Date(dateFrom), "yyyy-mm-dd");
    params.append("fromDate", frmtDate);
  }
  if (dateTo) {
    let frmtDate = dateFormat(new Date(dateTo), "yyyy-mm-dd");
    params.append("toDate", frmtDate);
  }
  if (pageNum) params.append("pageNumber", pageNum);
  if (pageSize) params.append("pageSize", pageSize);
  if (kitchenId) params.append("kitchenId", kitchenId);
  if (name) params.append("customerName", name);

  return privateApi.get(basicUrl + "getkitchenCustomerList", { params });
};

const getCustomerDetails = (customerId) => {
  const params = new URLSearchParams();
  params.append("customerId", customerId);

  return privateApi.get(basicUrl + "getkitchenCustomerDetails", { params });
};

const downloadOrderPayload = (orderId) => {
  const params = new URLSearchParams();
  params.append("orderId", orderId);

  return privateApi.get(basicUrl + "downloadorderpayload", { params });
};


const createCsv = ({
  pageSize,
  pageNum,
  searchFilter,
  kitchenFilter,
  statusFilter,
  marketplaceFilter,
  dateFromFilter,
  dateToFilter,
  serviceId,
}) => {
  const params = new URLSearchParams();
  if (serviceId) params.append("deliveryServiceId", serviceId);
  if (marketplaceFilter) params.append("marketPlaceStatus", marketplaceFilter);

  // if (dateFrom)
  //   params.append("from", dateFormat(new Date(dateFrom), "yyyy-mm-dd"));
  // if (dateTo) params.append("to", dateFormat(new Date(dateTo), "yyyy-mm-dd"));

  if (dateFromFilter) {
    let frmtDate = new Date(new Date(dateFromFilter).toUTCString()).toISOString();
    params.append("from", frmtDate);
  }
  if (dateToFilter) {
    let frmtDate = new Date(new Date(dateToFilter).toUTCString()).toISOString();
    params.append("to", frmtDate);
  }

  if (pageNum) params.append("pagenum", pageNum);
  if (pageSize) params.append("pagesize", pageSize);
  if (kitchenFilter) params.append("kitchenId", kitchenFilter);
  if (searchFilter) params.append("customerName", searchFilter);
  if (statusFilter) params.append("orderStatus", statusFilter);

  return privateApi.get(basicUrl + "generateorderhistorycsv", { params });
};

export { getAllCustomers, getCustomerDetails, createCsv, downloadOrderPayload };
