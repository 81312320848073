import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import warningIcon from "assets/images/warningIcon.svg";

const CategoryBasicInfo = (props) => {
  let data = props.initialData;
  let isDisabled = props.disabled;

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .max(30, "Menu title not more than 30 characters")
      .required("Please enter menu category title"),
    description: Yup.string()
      .trim()
      .max(500, "Menu category description not more than 500 characters"),
  });

  useEffect(() => {
    reset({
      title: data?.name || data?.title,
      description: data?.description,
    });
  }, [data]);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = (data) => {
    props.onSave(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="basic__info__content">
        <div className="warning__wrapper mb-3 pb-3">
          {errors && (
            <div className="warning__box">
              <p>
                <span className="icons">
                  <img src={warningIcon} alt="" />
                </span>
                In order to continue to next screen you should fill all
                mandatory fields
              </p>
            </div>
          )}
        </div>

        <div className="form-row no-gutters">
          <div className="col-md-12 mb-3 pb-3">
            <label htmlFor="categoryName">
              Title<span className="mendatory">*</span>
            </label>
            {isDisabled ? <div className="view_form_input">{data?.name || data?.title || "NA"}</div>
              : <input
                type="text"
                id="categoryName"
                placeholder="e.g. Classic Cheese Curds"
                className={`form-control ${errors?.title ? "is-invalid" : ""}`}
                {...register("title")}
                maxLength="30"
                disabled={isDisabled}
              />}
            {errors?.title?.message && (
              <div className="invalid-feedback d-block">
                {errors?.title?.message}
              </div>
            )}
          </div>

          <div className="col-md-12">
            <label htmlFor="categoryDesc">Description</label>
            {isDisabled ? <div className="view_form_input">{data?.description || "NA"}</div>
              : <textarea
                id="categoryDesc"
                placeholder="Add Description"
                rows="3"
                className={`form-control ${errors?.description ? "is-invalid" : ""
                  }`}
                {...register("description")}
                maxLength="500"
                disabled={isDisabled}
              ></textarea>}
            {errors?.description?.message && (
              <div className="invalid-feedback d-block">
                {errors?.description?.message}
              </div>
            )}
          </div>

          <div className="col-12">
            <div className="modal-footer">
              {props.children}

              <button
                type="button"
                onClick={props.onHide}
                className="cancel-btn red-btn-ghost btn"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="red-btn next-btn btn"
                disabled={!isValid}
              >
                Save and Continue
                <span className="icons-right">
                  <img src={rightArrowIcon} alt="arrow icon" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CategoryBasicInfo;
