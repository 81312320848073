import React, { useState, useEffect } from "react";
// import { Suspense } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { authRoutes, homeRoute, privateRoutes } from "./routes/Routes";
import AuthMiddleware from "./routes/auth-middleware/AuthMiddleware";
import AuthLayout from "./layout/auth-layout/AuthLayout";
import NonAuthLayout from "./layout/non-auth-layout/NonAuthLayout";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { VirtualBrandContextProvider } from "store/virtual-brand-context";
import { UserContextProvider } from "store/user-context";
import { RecipeGuideContextProvider } from "store/recipe-guide-context";
//import { Offline } from "react-detect-offline";
import Alert from "react-bootstrap/Alert";
import HomeLayout from "layout/home/HomeLayout";
import { OrdersNotificationContextProvider } from "store/orders-notification-context";
import VBLandingPage from "pages/vblandingpage/VBLandingPage";
import { Global } from "constants/global";
import { redirectToHome } from "shared/navigation-utils/index";
// import LoaderFull from "shared/loader/LoaderFull";

const App = () => {

  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setOnline(true));
    window.addEventListener('offline', () => setOnline(false));

    return () => {
      window.removeEventListener('online', () => setOnline(true));
      window.removeEventListener('offline', () => setOnline(false));
    };
  }, []);

  const domain = window.location.hostname;

  return (
    <div className="app">
      <UserContextProvider>
        <VirtualBrandContextProvider>
          <RecipeGuideContextProvider>
            <OrdersNotificationContextProvider>
              {/* <Suspense fallback={<LoaderFull />}> */}
              <ToastContainer
                enableMultiContainer={true}
                containerId="global"
                limit={1}
              />
              {!online && (
                <Alert className="text-center" variant="dark">
                  No Internet Connection
                </Alert>
              )}
              {/*  <Offline>
            <Alert className="text-center" variant="dark">
              No Internet Connection
            </Alert>
          </Offline> */}
              <Router>
                <Switch>
                  {domain === Global.landingPageDomain && (
                    <>
                      <Route path="/:kitchenVbId" exact component={VBLandingPage}/>
                      <Route path="/track/:orderId" exact component={VBLandingPage}/>
                      <Route path="/" exact component={redirectToHome} />
                    </>
                  )}
                  {homeRoute.map((route, idx) => (
                    <AuthMiddleware
                      exact
                      key={idx}
                      path={route.path}
                      component={route.component}
                      isAuthProtected={false}
                      openRoute
                      layout={HomeLayout}
                      {...route}
                    />
                  ))}

                  {authRoutes.map((route, idx) => (
                    <AuthMiddleware
                      exact
                      key={idx}
                      path={route.path}
                      component={route.component}
                      isAuthProtected={false}
                      layout={AuthLayout}
                      {...route}
                    />
                  ))}

                  {privateRoutes.map((route, idx) => (
                    <AuthMiddleware
                      exact
                      key={idx}
                      path={route.path}
                      component={route.component}
                      isAuthProtected={true}
                      layout={NonAuthLayout}
                      {...route}
                    />
                  ))}
                </Switch>
              </Router>
              {/* </Suspense> */}
            </OrdersNotificationContextProvider>
          </RecipeGuideContextProvider>
        </VirtualBrandContextProvider>
      </UserContextProvider>
    </div>
  );
};

export default App;
