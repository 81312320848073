import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import { changeStaffStatus } from "services/api/staff";

const ChangeStatus = ({ needDropdown = true, userId, status }) => {
  const [currentStatus, setcurrentStatus] = useState(status);
  const [isLoading, setisLoading] = useState(false);

  const onStatusChange = async () => {
    setisLoading(true);
    await changeStaffStatus(userId, !currentStatus).then((res) => {
      setcurrentStatus(!currentStatus);
      toastify("success", res.message);
      setisLoading(false);
    });
  };

  return (
    <>
      <div className="right_content">
        <span
          className={`custom__badge ${
            currentStatus ? "badge__red" : "badge__gray"
          }`}
        >
          {isLoading ? <LoaderInline /> : currentStatus ? "Active" : "Inactive"}
        </span>
        {needDropdown ? (
          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdown-button-drop-left"
            ></button>
            <div className="dropdown-menu">
              <div className="dropdown__innerWrapper">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => onStatusChange()}
                >
                  <span>Set As {!currentStatus ? "Active" : "Inactive"}</span>
                </button>
              </div>
              <div className="dropdown__innerWrapper">
                <Link to={`/staff/${userId}/edit`} className="dropdown-item">
                  <span>Edit</span>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

ChangeStatus.propTypes = {
  userId: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  onActionComplete: PropTypes.func,
};

export default ChangeStatus;
