import React, { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router";

import "./DeviceView.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import LoaderBlock from "shared/loader/LoaderBlock";
import { getDevice } from "services/api/device";
import ChangeStatus from "../change-status/ChangeStatus";
import { DeviceType } from "constants/deviceType";
import { DeviceConnection } from "constants/deviceConnectionType";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const DeviceView = (props) => {
  const { deviceId } = useParams();
  const [deviceData, setDeviceData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (deviceId) {
      if (parseInt(deviceId) > 0) {
        getDetail(+deviceId);
      }
    }
  }, [deviceId]);

  const getDetail = async (id) => {
    setisLoading(true);
    await getDevice(id)
      .then((res) => {
        setisLoading(false);
        setDeviceData(res.data);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const checkDeviceType = (type) => {
    switch (type) {
      case DeviceType.PRINTER:
        return "Printer";
      case DeviceType.TABLET:
        return "Tablet";
      default:
        return "NA";
    }
  };

  const checkDeviceConnection = (type) => {
    switch (type) {
      case DeviceConnection.FOUR_G:
        return "4G";
      case DeviceConnection.WIFI:
        return "Wifi";
      default:
        return "NA";
    }
  };

  const onBack = () => {
    props.history.goBack();
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="modifierDetails__wrapper restaurantDetails__wrapper user__details devices__details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button onClick={onBack} className=" back-btn-red">
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Device Details</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                {isLoading ? (
                  <LoaderBlock />
                ) : (
                  <div className="details__wrapper__inner">
                    <div className="left_content">
                      <div className="row">
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Restaurant Associated</h5>
                            <p>{deviceData?.kitchen?.name}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Device ID</h5>
                            <p>{deviceData?.deviceId}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Device Name</h5>
                            <p>{deviceData?.name}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Device Type</h5>
                            <p>{checkDeviceType(deviceData?.type)}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Device Connection Type</h5>
                            <p>
                              {checkDeviceConnection(
                                deviceData?.connectionType
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                          <div className="detailCard">
                            <h5>Device OS Version</h5>
                            <p>{deviceData?.os}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {deviceData && (
                      <ChangeStatus
                        deviceId={deviceData?.id}
                        deviceStatus={deviceData?.deviceStatusId}
                        onComplete={onBack}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(DeviceView);
