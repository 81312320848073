import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./NoVbBanner.scss";
import plusIcon from "assets/images/add-icon-white.svg";
import bannerMobile from "assets/images/mobile_banner.jpg";
import fileIcon from "assets/images/file-icon.svg";

const NoVbBanner = () => {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div id="main">
      <section id="homepage-banner">
        {isMobile ? (
          <div className="homepage-mobile">
            <div className="container">
              <div className="banner-content-wrapper">
                <h1>Create and manage your own virtual brands</h1>
                <p>Get started for free with our setup wizard</p>
                <Link
                  to="/brand/add"
                  className="create-banner-btn"
                  title="click to create"
                >
                  <span className="icons">
                    <img src={plusIcon} alt="plus icon" className="img-fluid" />
                  </span>
                  Create Virtual Brand
                </Link>
              </div>
            </div>
            <div className="banner__image">
              <img src={bannerMobile} alt="banner" className="img-fluid" />
            </div>
          </div>
        ) : (
          <div className="banner bannerhompega-bg">
            <div className="banner-content-wrapper">
              <h1>Create and manage your own virtual brands</h1>
              <p>Get started for free with our setup wizard</p>
              <Link
                to="/brand/add"
                className="create-banner-btn"
                title="click to create"
              >
                <span className="icons">
                  <img src={plusIcon} alt="plus icon" className="img-fluid" />
                </span>
                Create Virtual Brand
              </Link>
            </div>
          </div>
        )}
      </section>
      <section className="hompage-main">
        <div className="container">
          <div className="section-wrapper">
            <div className="file-icon">
              <img src={fileIcon} alt="file icon" className="img-fluid" />
            </div>
            <h2>No virtual brands are added</h2>
            <p>
              Please click on{" "}
              <span className="quoted-text">“Create Virtual Brand”</span> to
              create a brand.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NoVbBanner;
