import React from "react";
import "./Loader.scss";
import PropTypes from "prop-types";

const LoaderFull = ({ isTextNeeded = true }) => {
  return (
    <div className="loader__full">
      <div className="preloader-wrapper">
        <div className="preloader">
          <div className="loader">
            <div className="lds-roller">
              <div></div>
              {isTextNeeded ? <p>Loading...</p> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoaderFull.propTypes = {
  isTextNeeded: PropTypes.bool,
};

export default LoaderFull;
