import React, { useContext } from "react";
import "./CreateMenu.scss";
import VirtualBrandInfo from "../banner-section/VirtualBrandInfo";
import CreateMenuTabs from "../Info-section/menu-tabs/CreateMenuTabs";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import VirtualBrandContext from "store/virtual-brand-context";
import { VirtualBrandMode } from "constants/virtualBrandMode";

const CreateMenu = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const skipStep = () => props.nextStep();
  const nextStep = () => props.nextStep();
  const isPrimary = props?.isPrimary ? true : false;
  const isItLastPage = brandCtx.isConfirmPage;
  const brandMode = brandCtx.virtualBrandMode;

  return (
    <>
      <div className=" createmenu__wrapper">
        <div className="inner__wrapper">
          <VirtualBrandInfo />
          <CreateMenuTabs />
        </div>
      </div>
      {!isItLastPage && (
        <div className="multiForm__actions">
          {brandMode === VirtualBrandMode.SHARED ||
          brandMode === VirtualBrandMode.VIEW ? null : (
            <button className="skip__link" type="button" onClick={skipStep}>
              Skip this Step
            </button>
          )}
          <button
            style={{ float: "right" }}
            className="red-btn"
            type="button"
            onClick={nextStep}
          >
            Continue
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </button>
        </div>
      )}
      {
        isPrimary && 
        <div className="multiForm__actions">
          <button
            style={{ float: "right", width: '224px' }}
            className="red-btn"
            type="button"
            onClick={nextStep}
          >
            Submit For Review
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </button>
        </div>
      }
    </>
  );
};

export default CreateMenu;
