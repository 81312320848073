import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.scss";
import landingLogo__white from "../../assets/images/landing-logo-white.svg";
import landingLogo__black from "../../assets/images/landing-logo-black.svg";
import redExport__icon from "../../assets/images/red_export_icon.svg";
import section2Image from "../../assets/images/section-2-img.png";
import section3Image from "../../assets/images/section-3-img1.png";
import ipadImageWeb from "../../assets/images/tablet-bg.png";
import ipadImageWebM from "../../assets/images/tablet-bg-m.png";
import { getUserDataFromToken } from "services/local-storage";

const LandingPage = () => {
  const isLoggedIn = getUserDataFromToken();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }
  return (
    <>
      <div className="landingPage__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__white}
                    alt="logo"
                    className="img-fluid white__logo"
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                {isLoggedIn ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-link signUp__link w-100 h-100 mx-auto"
                    role="button"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-link signIn__link"
                      role="button"
                    >
                      Login
                    </Link>
                    <Link
                      to="/kitchen/register"
                      className="btn btn-link signUp__link"
                      role="button"
                    >
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        <main>
          <section className="landing__banner sections section1">
            <div className="bg">
              <div className="container-customs">
                <div className="banner__content">
                  <div className="left__content">
                    <h1>Create and Manage Virtual Restaurant Brands</h1>
                    <p>
                      Backhouse brands are online-only brands that operate out
                      of existing commercial kitchens. Learn how our technology
                      platform can open up new revenue streams for restaurants.
                    </p>
                    <div className="section__action__wrapper">
                      <Link
                        to="/home"
                        className="btn section__action__link"
                        role="button"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                  <div className="right-content big-screens d-none">
                    <div className="img-box">
                      <img src={ipadImageWeb} alt="" />
                      <div className="video-box">
                        {/* <iframe width="100%" height="550" src="https://www.youtube.com/embed/PCwL3-hkKrg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                        <video controls loop autoPlay>
                          <source
                            src="http://techslides.com/demos/sample-videos/small.mp4"
                            type="video/mp4"
                          ></source>
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="right-content big-screens d-none d-xl-block">
                    <div className="img-box">
                      {/* <img src={ipadImageWeb} alt=""/> */}
                      <div className="video-box">
                        <video controls loop autoPlay>
                          <source
                            src="http://techslides.com/demos/sample-videos/small.mp4"
                            type="video/mp4"
                          ></source>
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                  <div className="right-content small-screens d-xl-none">
                    <div className="img-box">
                      {/* <img src={ipadImageWebM} alt="" className="img-fluid"/> */}
                      <div className="video-box">
                        <video controls loop autoPlay>
                          <source
                            src="http://techslides.com/demos/sample-videos/small.mp4"
                            type="video/mp4"
                          ></source>
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sections section2">
            <div className="bg">
              <div className="container">
                <div className="section-content">
                  <h2>
                    The Virtual Brands Marketplace for Independent Restaurant
                    Operators
                  </h2>
                  <p>
                    Backhouse brands are online-only brands that operate out of
                    existing commercial kitchens. <br /> Learn how our
                    technology platform can open up new revenue streams for
                    restaurants.
                  </p>
                  <div className="section__action__wrapper">
                    <Link
                      to="/home"
                      className="btn section__action__link"
                      role="button"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sections section3">
            <div className="bg">
              <div className="container">
                <div className="section-content">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                      <div className="section-content">
                        <h3>
                          Meet Local Consumer Demand for Greater Food Choices
                        </h3>
                        <p>
                          Backhouse brands are online-only brands that operate
                          out of existing commercial kitchens. Learn how our
                          technology platform can open up new revenue streams
                          for restaurants.
                        </p>
                        <div className="section__action__wrapper">
                          <Link
                            to="/home"
                            className="section__action__link__inline"
                            role="button"
                          >
                            <span>
                              <img src={redExport__icon} alt="Learn more" />
                            </span>
                            Learn More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                      <div className="sectio__img__box">
                        <img
                          src={section2Image}
                          className="img-fluid"
                          alt="section"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sections section4">
            <div className="bg">
              <div className="container">
                <div className="section-content">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                      <div className="sectio__img__box">
                        <img
                          src={section3Image}
                          className="img-fluid"
                          alt="section"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="section-content">
                        <h3>Simplify Order Management for Virtual Brands</h3>
                        <p>
                          Backhouse brands are online-only brands that operate
                          out of existing commercial kitchens. Learn how our
                          technology platform can open up new revenue streams
                          for restaurants.
                        </p>
                        <div className="section__action__wrapper">
                          <Link
                            to="/home"
                            className="section__action__link__inline"
                            role="button"
                          >
                            <span>
                              <img src={redExport__icon} alt="Learn more" />
                            </span>
                            Learn More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sections section5">
            <div className="bg">
              <div className="container">
                <div className="section-content">
                  <h2>
                    The Virtual Brands Marketplace for Independent Restaurant
                    Operators
                  </h2>
                  <p>
                    Backhouse brands are online-only brands that operate out of
                    existing commercial kitchens. <br /> Learn how our
                    technology platform can open up new revenue streams for
                    restaurants.
                  </p>
                  <div className="section__action__wrapper">
                    <Link
                      to="/home"
                      className="btn section__action__link"
                      role="button"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default LandingPage;
