import React from "react";
import noMenuIcon from "assets/images/no_menu_icon.svg";

const NoRecipeCard = () => {
  return (
    <>
      <div className="nothing__card no__menu">
        <div className="nothing__card__wrapperInner">
          <div className="img__box">
            <img src={noMenuIcon} alt="icon" className="img-fluid" />
          </div>
          <div className="content__box">
            <h2>No Recipe added</h2>
            <p>
              Please click on{" "}
              <span className="text__highlighted">“Add New Recipe</span> to
              create a recipe.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoRecipeCard;
