import React from "react";
import { Link } from "react-router-dom";
import "./Onboarding.scss";
import emailIcon from "../../assets/images/email.svg";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const Resendmail = () => {
  return (
    <div className="message__wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3 pb-3">
            <div className="goBack">
              <Link
                to="/home"
                className="back-btn-red"
                title="back to dashboard"
              >
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back to Home
              </Link>
            </div>
          </div>
        </div>
        <div className="message__wrapper__inner">
          <div className="content">
            <div className="img-box">
              <img src={emailIcon} alt="" className="img-fluid" />
            </div>
            <div className="content-box">
              <h1>Check Your Email</h1>
              <p>
                We have sent you a link on your email address. Please click on
                the link to verify your email address.
              </p>
              <p>
                Didn’t receive any mail? Please click on “Resend” button and we
                will email you the link again.
              </p>
              <div className="action__wrapper">
                <button type="submit">Resend</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resendmail;
