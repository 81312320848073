import React, { useEffect } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import MenuAvailability from "shared/menu-availability/MenuAvailability";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import LoaderInline from "shared/loader/LoaderInline";
import { AvailabilityValidationSchema } from "shared/menu-availability/AvailabilitySchema";

const CategoryAvailability = (props) => {
  const data = props.initialData;
  const isLoading = props.loading;
  const isDisabled = props.disabled;

  useEffect(() => {
    return () => props.onChangeTab(getValues());
  }, []);

  const validationSchema = Yup.object().shape(AvailabilityValidationSchema);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (data) {
      let availability = data;
      reset(availability);
    }
  }, [data]);

  const onSubmit = (data) => {
    props.onSave(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="CreateMenuAvailabity__wrapper basic__info__content">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="main-heading pb-3">
                <h2>
                  Menu Availability<span className="mendatory">*</span>
                </h2>
                <p>
                  Which days and times would you like to receive orders from
                  this menu?
                </p>
              </div>
            </div>
            <div className="col-12">
              <MenuAvailability disabled={isDisabled} />
            </div>
            <div className="col-12">
              <div className="modal-footer">
                {props.children}

                <button
                  type="button"
                  onClick={props.onHide}
                  className="cancel-btn red-btn-ghost btn"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="red-btn next-btn btn"
                  disabled={isLoading || !isValid}
                >
                  {isLoading ? (
                    <LoaderInline />
                  ) : (
                    <>
                      <span>Save and Continue</span>
                      <span className="icons-right">
                        <img src={rightArrowIcon} alt="arrow icon" />
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CategoryAvailability;
