import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./CreateMenuModalContent.scss";
import warningIcon from "assets/images/warningIcon.svg";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import { Global } from "constants/global";
import { AssetType } from "constants/assetType";
import uploadFileToBlob from "services/azure-blob";
import { deleteMenuCoverImage } from "services/api/menu/index";

const MenuBasicInfoForm = (props) => {
  const coverSize = Global.tenMB;
  const [coverImage, setcoverImage] = useState(null);
  const [isCoverUploading, setisCoverUploading] = useState(false);
  const data = props.initialData;
  const isDisabled = props.disabled;
  const [rawCoverImage, setRawCoverImage] = useState(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .max(30, "Menu title not more than 30 characters")
      .required("Please enter menu title"),
    subTitle: Yup.string()
      .trim()
      .max(30, "Menu subtitle not more than 30 characters")
      .required("Please enter menu subtitle"),
    coverImage: Yup.string().trim(),
    description: Yup.string()
      .trim()
      .max(500, "Menu description not more than 500 characters"),
    currency: Yup.string()
      .default("USD")
      .trim()
      .max(30, "Menu currency not more than 30 characters")
      .required("Currency is required"),
    minimumOrder: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive()
      .min(1)
      .max(10000),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    if (data) {
      reset({
        title: data?.title,
        subTitle: data?.subTitle,
        coverImage: data?.coverUrl || "",
        description: data?.description,
        currency: data?.currency,
        minimumOrder: data?.minimumValue || data?.minimumOrder,
      });
      setcoverImage({ filePath: data?.coverUrl });
      setRawCoverImage(data?.rawCoverUrl);
    }
  }, [data]);

  const uploadCoverImage = async (file) => {
    setisCoverUploading(true);
    await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
      (res) => {
        setcoverImage(res);
        setisCoverUploading(false);
        setValue("coverImage", res?.fileName, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      },
      () => {
        setcoverImage(null);
        setisCoverUploading(false);
      }
    );
  };

  const deleteCoverImage = async () => {
    if (data?.id) {
      setisCoverUploading(true);
      await deleteMenuCoverImage(data.id).then(
        () => {
          setValue("coverImage", null);
          setcoverImage(null);
          setRawCoverImage(null);
          setisCoverUploading(false);
        },
        () => {
          setcoverImage(null);
          setRawCoverImage(null);
          setisCoverUploading(false);
        }
      );
    }
  };

  const onSubmit = (data) => {
    props.onSave(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="createMenu__wrapper uploadAssets__wrapper basic__info__content CreateMenuModalContent__wrapper">
        <div className="uploadAssets-wrapper">
          <div className="warning__wrapper mb-3 pb-3">
            {errors && (
              <div className="warning__box">
                <p>
                  <span className="icons">
                    <img src={warningIcon} alt="" />
                  </span>
                  In order to continue to next screen you should fill all
                  mandatory fields
                </p>
              </div>
            )}
          </div>
          <div className="row no-gutters">
            <div className="col-md-12 mb-3 pb-3">
              <label htmlFor="menuTitle">
                Menu Title<span className="mendatory">*</span>
              </label>
              {isDisabled ? (
                <div className="view_form_input">{data?.title || "NA"}</div>
              ) : (
                <input
                  type="text"
                  id="menuTitle"
                  placeholder="Enter Title Name"
                  className={`form-control ${
                    errors?.title ? "is-invalid" : ""
                  }`}
                  {...register("title")}
                  maxLength="30"
                  disabled={isDisabled}
                />
              )}
              {errors?.title?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.title?.message}
                </div>
              )}
            </div>

            <div className="col-md-12 mb-3 pb-3">
              <label htmlFor="menuSubtitle">
                Menu Subtitle<span className="mendatory">*</span>
              </label>
              {isDisabled ? (
                <div className="view_form_input">{data?.subTitle || "NA"}</div>
              ) : (
                <input
                  type="text"
                  id="menuSubtitle"
                  placeholder="e.g. House of Curds All Day Menu"
                  className={`form-control ${
                    errors?.subTitle ? "is-invalid" : ""
                  }`}
                  {...register("subTitle")}
                  maxLength="30"
                  disabled={isDisabled}
                />
              )}
              {errors?.subTitle?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.subTitle?.message}
                </div>
              )}
            </div>

            <div className="col-12 mb-3 pb-3">
              <label>Menu Cover Image</label>
              <AssetDropArea
                dimensions="2000 X 1200px"
                maxSize={coverSize}
                acceptedFormat="image/jpeg, image/png"
                showFormatText="Jpeg, Png"
                onFileChange={uploadCoverImage}
                isImageUploading={isCoverUploading}
                uploadedImage={coverImage?.filePath}
                uploadedRawImage={rawCoverImage}
                disabled={isDisabled}
                btnText={"Change Image"}
                onDeleteImg={deleteCoverImage}
              />
              {errors?.coverImage?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.coverImage?.message}
                </div>
              )}
            </div>

            <div className="col-md-12 mb-3 pb-3">
              <label htmlFor="menuDescription">Menu Description</label>
              {isDisabled ? (
                <div className="view_form_input">
                  {data?.description || "NA"}
                </div>
              ) : (
                <textarea
                  id="menuDescription"
                  placeholder="e.g. Deep friend wiscosin cheese curds"
                  rows="3"
                  className={`form-control ${
                    errors?.description ? "is-invalid" : ""
                  }`}
                  {...register("description")}
                  maxLength="500"
                  disabled={isDisabled}
                ></textarea>
              )}
              {errors?.description?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.description?.message}
                </div>
              )}
            </div>

            <div className="col-md-12 mb-3 pb-3 d-none">
              <label htmlFor="menuCurrency">
                Menu Currency <span className="mendatory">*</span>
              </label>
              <input
                type="text"
                id="menuCurrency"
                placeholder="USD"
                readOnly
                disabled
                className={`form-control ${
                  errors?.currency ? "is-invalid" : ""
                }`}
                {...register("currency")}
                maxLength="30"
              />
              {errors?.currency?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.currency?.message}
                </div>
              )}
            </div>

            <div className="col-md-12">
              <label htmlFor="minOrderValue">Minimum Order Value</label>
              {isDisabled ? (
                <div className="view_form_input">
                  {data?.minimumValue || data?.minimumOrder || "NA"}
                </div>
              ) : (
                <input
                  type="number"
                  id="minOrderValue"
                  placeholder="e.g. 30"
                  className={`form-control ${
                    errors?.minimumOrder ? "is-invalid" : ""
                  }`}
                  {...register("minimumOrder")}
                  disabled={isDisabled}
                />
              )}
              {errors?.minimumOrder?.message && (
                <div className="invalid-feedback d-block">
                  {errors?.minimumOrder?.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal.Footer>
        {props.children}

        <button
          type="button"
          onClick={props.onHide}
          className="cancel-btn red-btn-ghost btn"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="red-btn next-btn btn"
          disabled={!isValid}
        >
          Next
          <span className="icons-right">
            <img src={rightArrowIcon} alt="arrow icon" />
          </span>
        </button>
      </Modal.Footer>
    </form>
  );
};

export default MenuBasicInfoForm;
