import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import landingLogo__white from "../../assets/images/landing-logo-white.svg";
import landingLogo__black from "../../assets/images/landing-logo-black.svg";
import "./ContactUs.scss";

const ContactUs = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }
  return (
    <>
      <div className="landingPage__wrapper static__pages__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid white__logo"
                    width={120}
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                    width={120}
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                <Link
                  to="/login"
                  className="btn btn-link signIn__link"
                  role="button"
                >
                  Login
                </Link>
                <Link
                  to="/kitchen/register"
                  className="btn btn-link signUp__link"
                  role="button"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="container">
            <section className="page__title__section">
              <div className="row">
                <div className="col-12 d-none d-md-block">
                  <div className="goBack">
                    <Link to="/home" className=" back-btn-red">
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back to Home
                    </Link>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Contact Us</h1>
                    <p>Enter your information below to request a callback.</p>
                  </div>
                </div>
              </div>
            </section>
            <div className="conatctUs__wrapper">
              <div className="modifierDetails__wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="details__wrapper editModifier__wrapper">
                      <div className="details__wrapper__inner">
                        <form>
                          <div className="basic__info__content">
                            <div className="form-row">
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="name">
                                  Name
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  placeholder="Nombeko Mabandla"
                                  className="form-control"
                                  maxLength="30"
                                />
                              </div>
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="Phone Number">
                                  Phone Number
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="phoneNumber"
                                  placeholder="234 567 8907"
                                  className="form-control"
                                  maxLength="30"
                                />
                              </div>
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="emailAddress">
                                  Email Address
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="email"
                                  id="emailAddress"
                                  placeholder="brandon.well@houseofcurds.com"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-12 mb-md-3 pb-md-3 px-0">
                                <label htmlFor="BusinessName">
                                  Business Name
                                </label>
                                <input
                                  type="text"
                                  id="BusinessName"
                                  placeholder="True’s Pizza"
                                  className="form-control"
                                  maxLength="30"
                                />
                              </div>
                            </div>
                            <div className="col-md-12 px-0">
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="cancel-btn red-btn-ghost btn"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="red-btn next-btn btn"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ContactUs;
