export const ServiceType = {
    DOORDASH: "doordash",
    GRUBHUB: "grubhub",
    UBEREATS :"ubereats",
    POSTMATES: "postmates",
    FOODDUDES: "fooddudes",
    GARCON: "garcon"
};
  
export const ServiceId = {
  DOORDASH: 1,
  GRUBHUB: 2,
  UBEREATS: 3,
  POSTMATES: 4,
  FOODDUDES: 5,
  GARCON: 6,
  MENUFY: 7
};
  