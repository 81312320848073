import React, { useState, useEffect } from "react";
import DeviceRow from "./DeviceRow";
import { Global } from "constants/global";
import { getDeviceListByKitchenId } from "services/api/kitchen";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import noDeviceIcon from "assets/images/no-device-icon.svg";
import noresultsIcon from "assets/images/noresult.svg";

const DeviceList = (props) => {
  const kitchen = props?.kitchen;
  const kitchenId = kitchen?.id;
  const pageSize = Global.pageSize;

  const [devices, setDevices] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setsearchTerm] = useState(null);
  
  useEffect(() => {
    if(kitchenId){
      getAllList();
    }
  }, [kitchenId, pageNumber]);

  const getAllList = async () => {
    const model = {
      kitchenId: kitchenId,
      pageNum: pageNumber,
      pageSize: pageSize
    }
    setisLoading(true);
    await getDeviceListByKitchenId(model)
      .then((res) => {
        let data = res.data;
        setDevices(data?.kitchenDeviceAssociation);
        setTotalRecords(data?.pagingParams?.totalRecords);
        setisLoading(false);
      })
      .catch((_) => {
        setDevices(null);
        setTotalRecords(0);
        setisLoading(false);
      });
  };

  const onRefresh = (data) => {
    let prevDevices = devices.filter((res) => res.id !== data.id);
    setDevices(prevDevices);
  };

  const onPageChange = (page) => {
    setPageNumber(page);
  };

  return (
    <>
        <div className="row">
          <div className="col-12">
            <div className="shared__vb__listing__wrapper associated__devices__listing__wrapper">
              {/* <h2>Devices</h2> */}
              <div className="associated__devices__wrapper">
                <div className="managermodifier__wrapper basic__info__content">
                {devices && totalRecords === 0 && !isLoading ? (
                  <div className="restaurantdetails__noListing">
                    <div className="managermodifier__noListing__innerwrapper">
                      <div className="content">
                        <div className="img-box">
                          <img
                            className="img-fluid"
                            src={searchTerm && searchTerm !== "" ? noresultsIcon : noDeviceIcon}
                            alt="no list icon"
                            width="185"
                          />
                        </div>
                        <div className="content-desc">
                          <h3>
                            {searchTerm && searchTerm !== ""
                              ? "No devices(s) found"
                              : "No devices are added"}
                          </h3>
                         {/*  <p>
                            Please click on “Add New Device” to create new
                            Devices.
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) :
                  (<div className="managermodifier__listinPage managerestra__listinPage managedevice_listinPage">
                    <div className="managermodifier__listinPage__wrapper">
                      <table className="table modifierListing__table associatedListing__table">
                        <thead>
                          <tr role="row" className="mat-header-row">
                            <th
                              scope="col"
                              className="mat-header-cell column-1"
                            >
                              DEVICE
                            </th>
                            <th
                              scope="col"
                              className="mat-header-cell column-2"
                            >
                              DEVICE ID
                            </th>
                            <th
                              scope="col"
                              className="mat-header-cell column-3"
                            >
                              TYPE
                            </th>
                            <th
                              scope="col"
                              className="mat-header-cell column-5"
                            >
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <LoaderBlock />
                          ) : (
                            devices?.map((device, idx) => (
                              <DeviceRow
                                key={idx}
                                device={device}
                                kitchen={kitchen}
                                refreshList={onRefresh}
                              />
                            ))
                          )}
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-12">
                          <Paginator
                            totalCount={totalRecords}
                            currentPage={pageNumber}
                            pageSize={pageSize}
                            pageClick={onPageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default DeviceList;