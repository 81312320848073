import React, { useContext, useEffect, useState } from "react";

import { Tab, Nav, Col, Row } from "react-bootstrap";

import "./CreateMenuModal.scss";
import MenuBasicInfoForm from "./MenuBasicInfoForm";
import VirtualBrandContext from "store/virtual-brand-context";
import {
  addBrandMenu,
  addMenuAvailToKitchen,
  deleteBrandMenu,
  editBrandMenu,
} from "services/api/menu";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import MenuAvailabilityFormNew from "./MenuAvailabilityFormNew";

const MenuForm = () => {
  const brandCtx = useContext(VirtualBrandContext);
  const brandId = brandCtx.virtualBrandId;
  const kitchenId = brandCtx.associationKitchenId;
  const brandMode = brandCtx.virtualBrandMode;

  const [basicData, setbasicData] = useState(null);
  const [availData, setAvailData] = useState(null);
  let availabilityData = null;

  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);

  const modalStatus = brandCtx.menuModalStatus;
  const modalExtras = brandCtx.modalExtras;

  const [initialData, setinitialData] = useState(null);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
  const [isAvailDisabled, setisAvailDisabled] = useState(false);
  const virtualBrandInfo = brandCtx.virtualBrandInfo;

  useEffect(() => {
    if (brandMode) {
      if (
        brandMode === VirtualBrandMode.SHARED ||
        brandMode === VirtualBrandMode.VIEW
      ) {
        setisFieldsDisabled(true);
      }
      if (brandMode === VirtualBrandMode.VIEW) {
        setisAvailDisabled(true);
      }
    }
  }, [brandMode]);

  useEffect(() => {
    if (modalStatus === true) {
      if (modalExtras && modalExtras?.activeStep)
        setactiveStep(modalExtras?.activeStep);
      if (modalExtras && (modalExtras?.isEditMode || modalExtras?.isViewMode)) {
        setinitialData(modalExtras?.data);
        setbasicData(modalExtras?.data);
        setAvailData(modalExtras?.data?.menuAvailabilities || virtualBrandInfo?.virtualBrandAvailability);
      }
      else{
        setAvailData(virtualBrandInfo?.virtualBrandAvailability);
      }
    }
  }, [modalStatus]);

  const onMenuBasicInfoComplete = (data) => {
    setbasicData({ ...data });
    setactiveStep(2);
  };

  const onMenuAvailabilityComplete = (data) => {
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const modifiedData = {};
    for (const day of daysOfWeek) {
      if (data[day] && data[day].timings) {
        modifiedData[day] = {
          timings: data[day].timings.map(timing => ({
            opensAt: timing.opensAt,
            closesAt: timing.closesAt
          })),
          isActive: data[day].isActive
        };
      }
    }
    availabilityData = modifiedData;
    saveInfo();
  };

  const saveInfo = async () => {
    if (brandMode === VirtualBrandMode.VIEW) {
      closeModal();
      return;
    }

    let model = {
      ...basicData,
      brandId: brandId,
      kitchenId: kitchenId,
      operatingHours: availabilityData,
    };
    setisLoading(true);

    if (brandMode === VirtualBrandMode.SHARED) {
      let keys = { menuId: initialData?.id, kitchenId, brandId };
      await addMenuAvailToKitchen(keys, model)
        .then((res) => {
          toastify("success", res?.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
      return;
    }

    if (modalExtras?.isEditMode) {
      await editBrandMenu(initialData?.id, model)
        .then((res) => {
          toastify("success", res?.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
      return;
    } else {
      await addBrandMenu(model)
        .then((res) => {
          toastify("success", res?.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
      return;
    }
  };

  const closeModal = () => {
    brandCtx.menuModalStatusChange(false);
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteBrandMenu(initialData.id)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(true);
        closeDeleteModal();
        closeModal();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
    if (step === 2) {
      if (basicData) setactiveStep(2);
    }
  };

  const saveAvailTimings = (data) => {
    setAvailData(data);
  };

  return (
    <>
      <div className="createMenustabs">
        <Tab.Container
          id="addMenu__item__modal__Tabs"
          defaultActiveKey="first"
          mountOnEnter
          unmountOnExit
        >
          <Row className="no-gutters">
            <Col sm={12}>
              <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    active={activeStep === 1}
                    onClick={() => onTabChange(1)}
                  >
                    {modalExtras?.isEditMode && "Edit"}
                    {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                      ? "Add"
                      : null}{" "}
                    Menu Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    active={activeStep === 2}
                    onClick={() => onTabChange(2)}
                  >
                    Availability
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane
                  eventKey="first"
                  active={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading">
                    <MenuBasicInfoForm
                      onSave={onMenuBasicInfoComplete}
                      onHide={closeModal}
                      initialData={basicData}
                      disabled={isFieldsDisabled}
                    >
                      {![VirtualBrandMode.SHARED].includes(brandMode) ? (
                        <button
                          type="button"
                          onClick={openDeleteModal}
                          className="delete-option"
                        >
                          Delete
                        </button>
                      ) : null}
                    </MenuBasicInfoForm>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  active={activeStep === 2}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading body-overflow">
                    <div className="CreateMenuAvailabity__wrapper basic__info__content mb-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="main-heading">
                            <h2>
                              Menu Availability
                              <span className="mendatory">*</span>
                            </h2>
                            <p>
                              Which days and times would you like to receive
                              orders from this menu?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <MenuAvailabilityFormNew
                      onSave={onMenuAvailabilityComplete}
                      loader={isLoading}
                      onHide={closeModal}
                      initialData={availData}
                      onChangeTab={saveAvailTimings}
                      disabled={isAvailDisabled}
                    >
                      {![VirtualBrandMode.SHARED].includes(brandMode) ? (
                        <button
                          type="button"
                          onClick={openDeleteModal}
                          className="delete-option"
                        >
                          Delete
                        </button>
                      ) : null}
                    </MenuAvailabilityFormNew>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Menu"
          subtitle="Are you sure want to delete this menu, all categories would be deleted."
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

export default MenuForm;
