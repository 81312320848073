import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import deleteIocn from "assets/images/delete-icon.svg";
import plusIconRed from "assets/images/add-redIcon.svg";
import { ModifierTypeDropdown } from "constants/modifierType";
import { addModifierGroup } from "services/api/modifier";
import LoaderInline from "shared/loader/LoaderInline";
import toastify from "shared/toastr";

import "./AddModifierModal.scss";

const AddModifierModal = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const types = ModifierTypeDropdown;

  const optionsSchema = Yup.object().shape({
    id: Yup.number().default(0).required(),
    name: Yup.string()
      .trim()
      .max(120, ({ max }) => `Name not more than ${max} characters`)
      .required("Please enter option name"),
    price: Yup.number()
      .typeError("Only digits allowed")
      .moreThan(-1, ({ more }) => `Price cannot be negative`)
      .max(10000, ({ max }) => `Price not more than ${max}`)
      .required("Please enter price"),
  });

  const validationSchema = Yup.object().shape({
    id: Yup.number().default(0).required(),
    name: Yup.string()
      .trim()
      .max(120, ({ max }) => `Name not more than ${max} characters`)
      .required("Please enter modifier group name"),
    modifierType: Yup.number().required(),
    options: Yup.array()
      .of(optionsSchema)
      .min(1, "Minimum one option is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
    keyName: "addOptId",
  });

  const addOption = () => {
    append({ id: 0, name: "", price: 0 });
  };

  const onSubmit = async (data) => {
    setisLoading(true);

    await addModifierGroup(data)
      .then((res) => {
        setisLoading(false);
        toastify("success", res.message);
        props.onHide();
        props.onSave();
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  return (
    <Modal
      id="AddnewmodifierModal"
      className="centered"
      show={props.show}
      centered
    >
      <div className="addNewModifier__wrapper">
        <Modal.Header>
          <h2 id="modifiertype">Add New Modifier</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="basic__info__content addNewModifier__wrapper__inner">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row no-gutters">
                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="modifiergroupname">
                    Modifier Group Name<span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="modifiergroupname"
                    placeholder="e.g. Toppings"
                    className={`form-control ${
                      errors?.name ? "is-invalid" : ""
                    }`}
                    {...register("name")}
                    maxLength="120"
                  />
                  {errors?.name?.message && (
                    <div className="invalid-feedback d-block">
                      {errors?.name?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="modifiertype">
                    Modifier Type<span className="mendatory">*</span>
                  </label>
                  <select
                    className={`form-control ${
                      errors?.modifierType ? "is-invalid" : ""
                    }`}
                    {...register("modifierType")}
                  >
                    {types &&
                      types.map((opt, idx) => (
                        <option key={"opt" + idx} value={opt.value}>
                          {opt.text}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <div className="modifieroptions__wrap d-flex">
                    <div className="modifieroptions">
                      <label htmlFor="modifieroptions">
                        Modifier Options<span className="mendatory">*</span>
                      </label>
                      {fields &&
                        fields.map((field, idx) => (
                          <span key={field.addOptId}>
                            <input
                              type="text"
                              placeholder="e.g. Extra Beef"
                              className={`form-control mb-1 ${
                                errors?.options?.[idx]?.name ? "is-invalid" : ""
                              }`}
                              {...register(`options.${idx}.name`)}
                            />
                            {errors?.options?.[idx]?.name?.message && (
                              <div className="invalid-feedback d-block">
                                {errors?.options[idx].name?.message}
                              </div>
                            )}
                          </span>
                        ))}
                    </div>
                    <div className="modifieroptions__prices">
                      <label htmlFor="modifierprice">Price</label>
                      {fields &&
                        fields.map((field, idx) => (
                          <span key={field.addOptId}>
                            <div className="delete_modifier_wrapper">
                              <input
                                type="text"
                                placeholder="e.g. $5"
                                className={`form-control mb-1 ${
                                  errors?.options?.[idx]?.price
                                    ? "is-invalid"
                                    : ""
                                }`}
                                {...register(`options.${idx}.price`)}
                              />
                              <button
                                type="button"
                                onClick={() => remove(idx)}
                                className="delete_modifier"
                              >
                                <span>
                                  <img
                                    src={deleteIocn}
                                    className=""
                                    alt="remove"
                                  />
                                </span>
                              </button>
                            </div>
                            {errors?.options?.[idx]?.price?.message && (
                              <div className="invalid-feedback d-block">
                                {errors?.options[idx].price?.message}
                              </div>
                            )}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="addmodifiers__options mb-2">
                    <button
                      type="button"
                      className="addModifiers addModifiers-btn"
                      onClick={addOption}
                    >
                      <span className="icons">
                        <img src={plusIconRed} alt="plus icon" />
                      </span>
                      Add Modifer Options
                    </button>
                  </div>
                  {errors?.options?.message && (
                    <div className="invalid-feedback d-block">
                      {errors?.options?.message}
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <Modal.Footer>
                    <button
                      type="button"
                      className="cancel-btn red-btn-ghost btn"
                      onClick={props.onHide}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="red-btn next-btn btn"
                      disabled={isLoading || !isValid}
                    >
                      {isLoading ? (
                        <LoaderInline />
                      ) : (
                        <>
                          Save and Continue
                          <span className="icons-right">
                            <img src={rightArrowIcon} alt="arrow icon" />
                          </span>
                        </>
                      )}
                    </button>
                  </Modal.Footer>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default AddModifierModal;
