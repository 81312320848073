import React from "react";
import "./RecipeCategoryCard.scss";
/* import "../category-form/CreateCatagoryModal.scss"; */
import noMenuIcon from "assets/images/no_menu_icon.svg";

const NoRecipeCategoryCard = (props) => {
  return (
    <>
      <div className="col-12">
        <div className="menuCategoriesCard__wrapper">
          <div className="menuCategoriesCard__wrapper__inner">
            <div className="menuCategoriesCard">
              <div className="nothing__card no__category">
                <div className="nothing__card__wrapperInner">
                  <div className="img__box">
                    <img src={noMenuIcon} alt="icon" className="img-fluid" />
                  </div>
                  <div className="content__box">
                    <h2>No category added</h2>
                    <p>
                      Please click on{" "}
                      <span className="text__highlighted">
                        “Add Recipe Category”
                      </span>{" "}
                      to create a category.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoRecipeCategoryCard;
