import React from "react";
import PropTypes from "prop-types";
// Paginator
import ReactPaginate from "react-paginate";

const Paginator = (props) => {
  const pageNum = props.currentPage;
  const handlePageChange = ({ selected }) => {
    props.pageClick(selected + 1);
  };

  return (
    <>
      <div className="mt-5">
        {props.totalCount > props.pageSize ? (
          <ReactPaginate
            pageCount={props.totalCount / props.pageSize}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            onPageChange={handlePageChange}
            initialPage={0}
            forcePage={pageNum - 1}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-link"}
            breakLinkClassName={"page-item"}
            activeClassName={"active"}
            activeLinkClassName={"active"}
            disabledClassName={"disabled"}
            disableInitialCallback
          />
        ) : null}
      </div>
    </>
  );
};

Paginator.propTypes = {
  pageClick: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Paginator;
