import React from "react";
import { Link } from "react-router-dom";
import "./ManageResturantsDetails.scss";
import "./EditRestaurant.scss";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import emailicon from "assets/images/icon-email.svg";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import btnWhiteArrow from "../../assets/images/white-right-arrow-btn.svg";

const EditRestaurant = () => {
  return (
    <div className="modifierDetails__wrapper restaurantDetails__wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="goBack">
              <Link to="/manage-resturants" className=" back-btn-red">
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="main_heading">
              <h1>Edit Restaurant</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="details__wrapper edit__restaurant">
              <div className="details__wrapper__inner">
                <form>
                  <div className="signcard__wrapper">
                    <div className="row no-gutters">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="form-row no-gutters">
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="bname">
                              Business Name
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="bname"
                              placeholder="True’s Pizza"
                              maxLength="50"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="baddress">
                              Business Address
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="baddress"
                              placeholder="3045 Eagandale Place, Eagan, MN, USA"
                              maxLength="50"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="conatctName">
                              Contact Name
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="conatctName"
                              placeholder="Matthew True"
                              maxLength="50"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="conatctNo">
                              Phone Number
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="conatctNo"
                              placeholder="234 567 8907"
                              maxLength="50"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="email">
                              Email Address
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="email"
                              placeholder="Enter your email address"
                              maxLength="50"
                              className="form-control"
                            />
                          </div>
                          <div className="col-12">
                            <div className="detailCard">
                              <h5>Shared Virtual Brands</h5>
                              <div className="shared__vbrands">
                                <p>House of Curds</p>
                                <p>Pizza Club</p>
                                <p>Sandwich Palace</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-12 mb-md-3 pb-md-4">
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="cancel-btn red-btn-ghost btn"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="red-btn next-btn btn">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRestaurant;
