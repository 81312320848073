import React, { useEffect, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import "./DeviceListing.scss";
import plusIcon from "assets/images/add-icon-white.svg";
import noDeviceIcon from "assets/images/no-device-icon.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import { Global } from "constants/global";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import ListingRow from "./ListingRow";
import { getDevices } from "services/api/device";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import SearchFilter from "components/filters/SearchFilter";

const deviceParams = new URLSearchParams();

const DeviceListing = () => {
  const pageSize = Global.pageSize;
 
  const history = useHistory();
  const { search } = useLocation();
  
  const page = new URLSearchParams(search).get("page");
  const searchParam = new URLSearchParams(search).get("search");
  const [pageNum, setpageNum] = useState(page || 1);
  const [searchTerm, setsearchTerm] = useState(searchParam || null);

  const [allDevice, setallDevice] = useState(null);
  const [totalRecords, settotalRecords] = useState(0);

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (search) {
      let page = new URLSearchParams(search).get("page");
      let searchVal = new URLSearchParams(search).get("search");
      if (page) {
        setpageNum(+page);
        deviceParams.set("page", page);
      } else {
        setpageNum(1);
        deviceParams.delete("page");
      }
      if (searchVal) {
        setsearchTerm(searchVal);
        deviceParams.set("search", searchVal);
      } else {
        setsearchTerm(null);
        deviceParams.delete("search");
      }
    } else {
      setpageNum(1);
      deviceParams.delete("page");
      setsearchTerm(null);
      deviceParams.delete("search");
    }
  }, [search]);

  useEffect(() => {
    if (pageNum || searchTerm) getListing();
  }, [pageNum, searchTerm]);

  const getListing = async () => {
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      searchValue: searchTerm,
    };
    setisLoading(true);
    await getDevices(masks)
      .then((res) => {
        let data = res.data;
        setallDevice(data.devices);
        settotalRecords(data.pagingParams.totalRecords);
        setisLoading(false);
      })
      .catch(() => {
        setallDevice(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    if (page) {
      deviceParams.set("page", page);
    } else {
      deviceParams.delete("page");
    }
    history.push({ search: deviceParams.toString() });
  };

  const onSearch = (term) => {
    if (term) {
      deviceParams.set("search", term);
    } else {
      deviceParams.delete("search");
    }
    //setpageNum(1);
    deviceParams.delete("page");
    history.push({ search: deviceParams.toString() });
  };

  return (
    <>
          <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
      <div className="manageUser__landingPage__wrapper manageDevices__landingPage__wrapper">
        <div className="managermodifier__wrapper basic__info__content">
          <div className="container">
            <div className="managermodifier__header">
              <div className="row justify-content-between">
                <div className="col-12 mb-3 pb-3 d-none d-md-block">
                  <div className="goBack">
                    <Link
                      to="/dashboard"
                      className="back-btn-red"
                      title="Go to Dashboard"
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Go to Dashboard
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-md-3 mb-lg-0">
                  <h1>Manage Devices</h1>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form flex-column flex-md-row" 
                   id="manage__modifier__search__form">
                  <div className="search__modifiers">
                      <SearchFilter value={searchTerm} onSelect={onSearch} placeHolder={"Search By Device Id"}/>
                    </div>
                      <Link to="/device/add" className="red-btn btn">
                        <span className="icons-left icons">
                          <img src={plusIcon} alt="add icon" />
                        </span>
                        Add New Device
                      </Link>
                  </div>
                </div>
              </div>
            </div>
            {allDevice && totalRecords === 0 ? (
              <div className="managermodifier__noListing manageruser__noListing">
                <div className="managermodifier__noListing__innerwrapper">
                  <div className="content">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={noDeviceIcon}
                        alt="no list icon"
                        width="185"
                      />
                    </div>
                    <div className="content-desc">
                      <h3>No devices are added</h3>
                      <p>
                        Please click on “Add New Device” to create new devices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="managermodifier__listinPage  managerestra__listinPage">
                <div className="managermodifier__listinPage__wrapper">
                  <table className="table modifierListing__table">
                    <thead>
                      <tr role="row" className="mat-header-row">
                        <th scope="col" className="mat-header-cell column-6">
                          Resturant Name
                        </th>
                        <th scope="col" className="mat-header-cell column-7">
                          Device
                        </th>
                        <th scope="col" className="mat-header-cell column-8">
                          Device Id
                        </th>
                        <th scope="col" className="mat-header-cell column-9">
                          Type
                        </th>
                        <th scope="col" className="mat-header-cell column-10">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td>
                            <LoaderBlock />
                          </td>
                        </tr>
                      ) : (
                        allDevice?.map((device, idx) => (
                          <ListingRow
                            key={idx}
                            device={device}
                            refreshList={getListing}
                          />
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-12">
                      <Paginator
                        totalCount={totalRecords}
                        currentPage={pageNum}
                        pageSize={pageSize}
                        pageClick={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceListing;
