import React, { useEffect, useState, useContext } from "react";
import "./Home.scss";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../auth/kitchen-auth/Onboarding.scss";
import emailicon from "assets/images/icon-email.svg";
import btnWhiteArrow from "assets/images/white-right-arrow-btn.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import { loginUser } from "services/api/auth";
import LoaderInline from "shared/loader/LoaderInline";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import UserContext from "store/user-context";
import { useMediaQuery } from "react-responsive";
import logo from "logo.svg";
import { getUserDataFromToken } from "services/local-storage";
import { redirectToHome } from "shared/navigation-utils/index";
import toastify from "shared/toastr";
import { Global } from "constants/global";
import { RoleType } from "constants/roleType";

const Home2 = (props) => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
    
      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    let x = ["header"];
    if (scrolled) {
        x.push("sticky");
    }

    const userCtx = useContext(UserContext);
    const [showPassword, setshowPassword] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email("Please enter a valid email address")
            .max(50)
            .required("Please enter a valid email address"),
        password: Yup.string()
            .trim()
            .transform((x) => (x === "" ? undefined : x))
            .concat(
                Yup.string().trim().max(50).required("Please enter your password")
            ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
    });

    const togglePassword = () => {
        setshowPassword(!showPassword);
    };

    const onSubmit = async (data) => {
        setisLoading(true);
        await loginUser(data.email, data.password).then(
            (res) => {
                if (res?.token == null) {
                    toastify("error", Global.loginErrorForKitchenStaff);
                  }
                userCtx.setUserInfo(res.user);
                setisLoading(false);
                res.user?.role === RoleType.KITCHEN_STAFF ? (props.history.push("/profile")) : (props.history.push("/dashboard"));
            },
            (error) => {
                toastify("success", error.message);
                setisLoading(false);
              }
        );
    };

    const isMobile = useMediaQuery({
        query: "(max-width: 767px)",
    });

    return (
        <>
            <div className={x.join(" ")}>
                <div className="header-container">
                    <div className="header-wrapper">
                        <div className="header-left">
                            <div className="logo auth">
                                <Link to="/login">
                                    <img src={logo} alt="Backhouse Brands" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Subheader
                title={isMobile ? null : "Sign In"}
                extraClass="non-auth-subheader-desktop preloginPage"
            >
                <div className="goBack mb-3">
                    <Link to="/home" className="back-btn-red" title="back to home">
                        <span className="icons">
                            <img src={backArrowIcon} alt="back icon" />
                        </span>
                        Go to Home
                    </Link>
                </div>
            </Subheader>
            <div className="signIn__wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-3 pb-3 d-none d-md-block">
                            <div className="goBack">
                                <button className="back-btn-red" title="back to home" onClick={redirectToHome}>
                                    <span className="icons">
                                        <img src={backArrowIcon} alt="back icon" />
                                    </span>
                                    Back to Home
                                </button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="loginHeading__wrapper">
                                <h1>Welcome!</h1>
                                <p>Sign in to your account.</p>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="signcard__wrapper">
                            <div className="row no-gutters">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="leftcard">
                                        <div className="leftcard__inner">
                                            <div className="form-row no-gutters">
                                                <div className="col-md-12 mb-3 pb-3">
                                                    <label htmlFor="email">Email Address</label>
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        placeholder="Enter your email address"
                                                        {...register("email")}
                                                        maxLength="50"
                                                        className={`form-control ${errors.email ? "is-invalid" : ""
                                                            }`}
                                                    />
                                                    <span className="input-icon">
                                                        <img src={emailicon} alt="" />
                                                    </span>
                                                    {errors.email?.message && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.email?.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        id="password"
                                                        placeholder="Enter your password"
                                                        {...register("password")}
                                                        maxLength="50"
                                                        className={`form-control ${errors.password ? "is-invalid" : ""
                                                            }`}
                                                    />
                                                    <span className="input-icon">
                                                        <img
                                                            onClick={togglePassword}
                                                            src={
                                                                showPassword ? showpasswordicon : passwordicon
                                                            }
                                                            alt={showPassword ? "hide" : "show"}
                                                            title={showPassword ? "hide" : "show"}
                                                        />
                                                    </span>
                                                    {errors.password?.message && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.password?.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <span className="col-md-12 mb-3 pb-3 text-right">
                                                    <Link to="/login/forget" className="prelogin-links">
                                                        Forgot Password?
                                                    </Link>
                                                </span>
                                                <div className="col-md-12 action__wrapper">
                                                    <button type="submit" disabled={isLoading}>
                                                        {isLoading ? (
                                                            <LoaderInline />
                                                        ) : (
                                                            <>
                                                                Sign In{" "}
                                                                <img
                                                                    className="icon"
                                                                    src={btnWhiteArrow}
                                                                    alt="arrow"
                                                                />
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                                <div className="col-12 d-md-none righside__mobile">
                                                    <div className="or">
                                                        <p>OR</p>
                                                    </div>
                                                    <div className="loginHeading__wrapper">
                                                        <h1>Don’t have an account?</h1>
                                                    </div>
                                                    <div className="rightside">
                                                        <div className="content__box">
                                                            <div className="action__wrapper">
                                                                <Link to="/kitchen/register">
                                                                    <button
                                                                        type="button"
                                                                        className="red-button-filled-hover"
                                                                    >
                                                                        Sign Up
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                            <div className="rightside__links">
                                                                <Link
                                                                    to="/how-it-works"
                                                                    className="rightside__link"
                                                                >
                                                                    How it Works
                                                                </Link>
                                                                <Link
                                                                    to="/faq"
                                                                    className="rightside__link"
                                                                >
                                                                    FAQs
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 d-none d-md-block">
                                    <div className="rightside">
                                        <div className="rightside__bg signIn__bg">
                                            <div className="content__box">
                                                <h2>Don’t have an account?</h2>
                                                <div className="action__wrapper">
                                                    <Link to="/kitchen/register">
                                                        <button
                                                            type="button"
                                                            className="red-button-filled-hover"
                                                        >
                                                            Sign Up
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="rightside__links">
                                                    <Link
                                                        to="/how-it-works"
                                                        className="rightside__link"
                                                    >
                                                        How it Works
                                                    </Link>
                                                    <Link
                                                        to="/faq"
                                                        className="rightside__link"
                                                    >
                                                        FAQs
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Home2;
