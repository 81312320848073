import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const ApiKey = process.env.REACT_APP_GOOGLE_PLACES_API;

const GoogleMap = (props) => {
  const lat = props.latitude;
  const lng = props.longitude;
  const height = props.height;
  const width = props.width;
  const draggable = props?.draggable;
  const onMarkerDragEnd = props.onMarkerDragEnd;

  const style = {
    width: width,
    height: height,
  };

  return (
    <>
      {lat || lng ? (
        <Map
          google={props.google}
          zoom={14}
          style={style}
          initialCenter={{
            lat: lat,
            lng: lng,
          }}
          center={{
            lat: lat,
            lng: lng,
          }}
          scrollwheel
          draggable
          zoomControl
          fullscreenControl
          streetViewControl={false}
        >
          <Marker
            position={{
              lat: lat,
              lng: lng,
            }}
            draggable={draggable}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
          />
        </Map>
      ) : null}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: ApiKey,
  libraries: ["places"],
})(GoogleMap);
