import React, { useContext, useEffect, useState } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import "../recipeguide-modal/RecipeModal";
import IngredientsForm from "./IngredientsForm";
import RecipeDetailsForm from "./RecipeDetailsForm";
import { addRecipeForRecipeGuide, addRecipe, editRecipe} from "services/api/recipeguide";
import toastify from "shared/toastr";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import RecipeGuideContext from "store/recipe-guide-context";

const RecipeForm = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const recipeGuideId = recipeguideCtx.recipeGuideId;

  const [basicData, setbasicData] = useState(null);
  const [availData, setAvailData] = useState(null);
  let availabilityData = null;

  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);
  const [initialData, setinitialData] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
  const modalStatus = recipeguideCtx.recipeModalStatus;
  const modalExtras = recipeguideCtx.modalExtras;
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;

   useEffect(() => {
     if (recipeGuideMode) {
       if (
         recipeGuideMode === VirtualBrandMode.SHARED ||
         recipeGuideMode === VirtualBrandMode.VIEW || (modalStatus === true && modalExtras?.isViewMode)
       ) {
         setisFieldsDisabled(true);
       }
     }
   }, [recipeGuideMode]);

   useEffect(() => {
     if (modalStatus === true) {
       if (modalExtras && modalExtras?.activeStep)
         setactiveStep(modalExtras?.activeStep);
       if (modalExtras && (modalExtras?.isEditMode || modalExtras?.isViewMode)) {
         setbasicData(modalExtras?.data);
         setAvailData(modalExtras?.data);
       }
     }
   }, [modalStatus]);

  const onMenuBasicInfoComplete = (data) => {
    setbasicData({ ...data });
    setactiveStep(2);
  };

  const onMenuAvailabilityComplete = (data) => {
    availabilityData = data;
    saveInfo();
  };

  const saveInfo = async () => {
    const recipeId = modalExtras?.data?.id;
    const type =  modalExtras?.type;
    if (recipeGuideMode === VirtualBrandMode.VIEW) {
      closeModal();
      return;
    }

    let model = {
      ...basicData,
      recipeGuideId: recipeGuideId,
      ...availabilityData,
    };
    setisLoading(true);
    if (type === "select") {
      await addRecipeForRecipeGuide(recipeId, recipeGuideId)
        .then((res) => {
          recipeguideCtx.selectRecipeModalStatusChange(false);
          recipeguideCtx.saveRecipeId(recipeId);
          closeModal();
          toastify("success", res?.message);
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
      return;
    }
    else {
      if (modalExtras?.isEditMode && recipeId) {
        recipeguideCtx.isrecipeAdded(false);
        recipeguideCtx.saveRecipeId(recipeId);
        await editRecipe(recipeId, model)
          .then((res) => {
            toastify("success", res?.message);
            closeModal();
            setisLoading(false);
          })
          .catch(() => {
            setisLoading(false);
          });
        return;
      } else {
        recipeguideCtx.isrecipeAdded(true);
        recipeguideCtx.saveRecipeId(null);
        await addRecipe(model)
          .then(async (res) => {
            if (res?.data) {
              await addRecipeForRecipeGuide(res?.data, recipeGuideId)
              .then((res) => {
                closeModal();
                toastify("success", res?.message);
                setisLoading(false);
              })
                .catch(() => {
                  setisLoading(false);
                });
            }
          })
          .catch(() => {
            setisLoading(false);
          });
      }
    }
  };

  const closeModal = () => {
    const recipeId = modalExtras?.data?.id;
    recipeguideCtx.recipeModalStatusChange(false);
    recipeguideCtx.saveRecipeId(recipeId);
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
    if (step === 2) {
      if (basicData) setactiveStep(2);
    }
  };

  const saveRecipeDetails = (data) => {
    setbasicData(data);
  };

  const saveAvailTimings = (data) => {
    setAvailData(data);
  };

  return (
    <>
      <div className="createMenustabs">
        <Tab.Container
          id="addMenu__item__modal__Tabs"
          defaultActiveKey="first"
          mountOnEnter
          unmountOnExit
        >
          <Row className="no-gutters">
            <Col sm={12}>
              <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    active={activeStep === 1}
                    onClick={() => onTabChange(1)}
                  >
                    {modalExtras?.isEditMode && "Edit"}
                    {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                      ? "Add"
                      : null}{" "}
                    Recipe Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    active={activeStep === 2}
                    onClick={() => onTabChange(2)}
                  >
                    Ingredients
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane
                  eventKey="first"
                  active={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading">
                    <RecipeDetailsForm
                      onSave={onMenuBasicInfoComplete}
                      onHide={closeModal}
                      initialData={basicData}
                      disabled={isFieldsDisabled}
                      onChangeTab={saveRecipeDetails}
                    >
                    </RecipeDetailsForm>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  active={activeStep === 2}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading body-overflow">
                    <IngredientsForm
                      onSave={onMenuAvailabilityComplete}
                      loader={isLoading}
                      onHide={closeModal}
                      initialData={availData}
                      onChangeTab={saveAvailTimings}
                      disabled={isFieldsDisabled}
                      recipeTitle={basicData?.title}
                    >
                    </IngredientsForm>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default RecipeForm;
