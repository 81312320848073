import React, { useContext, useState, useEffect } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import "UI/Button/AddButton.scss";
/* import "../../../Info-section/create-form/CreateMenuModalContent.scss"; */
import PrepStepBasicInfo from "./PrepStepBasicInfo";
import LoaderBlock from "shared/loader/LoaderBlock";
import RecipeGuideContext from "store/recipe-guide-context";

const PrepStepForm = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);
  const modalStatus = recipeguideCtx.prepStepModalStatus;
  const modalExtras = recipeguideCtx.modalExtras;
  const [isDisabledFields, setisDisabledFields] = useState(false);
  const [basicData, setbasicData] = useState(null);

  useEffect(() => {
    if (modalStatus === true) {
      if (modalExtras && modalExtras?.activeStep)
        setactiveStep(modalExtras?.activeStep);
      if (modalExtras && (modalExtras?.editMode || modalExtras?.viewMode || modalExtras?.cloneMode)) {
        setbasicData(modalExtras?.data);
      }
      if (modalExtras?.viewMode) setisDisabledFields(true);
    }
  }, [modalStatus]);

  const onItemBasicInfoComplete = () => {
    closeModal();
  };

  const closeModal = () => {
    recipeguideCtx.prepStepModalStatusChange(false);
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoaderBlock />
      ) : (
        <div className="createcatTbs__wrapper">
          <Tab.Container
            id="addMenu__item__modal__Tabs"
            defaultActiveKey="first"
            mountOnEnter
            unmountOnExit
          >
            <Row className="no-gutters">
              <Col sm={12}>
                <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      active={activeStep === 1}
                      onClick={() => onTabChange(1)}
                    >
                      {modalExtras?.viewMode && ""}
                      {modalExtras?.editMode && "Edit"}
                      {!modalExtras?.editMode &&
                        !modalExtras?.cloneMode &&
                        !modalExtras?.viewMode
                        ? "Add"
                        : null}{" "}
                      Step Details
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    active={activeStep === 1}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div className="tab__mainHeading">
                      <PrepStepBasicInfo
                        onSave={onItemBasicInfoComplete}
                        onHide={closeModal}
                        initialData={basicData}
                        extras={modalExtras}
                        disabled={isDisabledFields}
                      >
                      </PrepStepBasicInfo>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </>
  );
};

export default PrepStepForm;
