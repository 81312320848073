import { privateApi } from "../utilities/Provider";
import getFileNameFromUrl from "../utilities/Helper";

const basicUrl = "/RecipeGuide/";

const addRecipeGuideBasicInfo = (model) => {
  let body = {
    virtualBrandId: model.brandId,
    menuItemId: model.menuItemId,
    tagline: model.tagline,
    description: model.description,
    estimatedPrepTimeInMinutes: model.preparationTime,
    title: model.menuItem,
    imageUrl: getFileNameFromUrl(model.menuItemImage)
  };
  return privateApi.post(basicUrl + "recipeguide", body);
};

const editRecipeGuideBasicInfo = (recipeGuideId, model) => {
  let body = {
    id: recipeGuideId,
    virtualBrandId: model.brandId,
    menuItemId: model.menuItemId,
    tagline: model.tagline,
    description: model.description,
    estimatedPrepTimeInMinutes: model.preparationTime,
    title: model.menuItem,
    imageUrl: getFileNameFromUrl(model.menuItemImage)
  };
  return privateApi.put(basicUrl + "recipeguide", body);
};

const GetMenuItemsWithRecipeGuide = (brandId) => {
  return privateApi.get(basicUrl + "menuitemswithrecipeguide", {
    params: {
      virtualbrandId: brandId
    },
  });
};

const GetRecipeGuideBasicInfo = (brandId, menuItemId, recipeGuideId) => {
  return privateApi.get(basicUrl + "recipeguidebasicinfo", {
    params: {
      virtualbrandId: brandId,
      menuItemId: menuItemId,
      recipeGuideId: recipeGuideId
    },
  });
};

const addRecipeForRecipeGuide = (recipeId, recipeGuideId) => {
  const body = {
    recipeGuideId: recipeGuideId,
    recipeId: recipeId,
  };
  return privateApi.post(basicUrl + "recipeInRecipeGuide", body);
};

const deleteRecipeForRecipeGuide = (recipeId, recipeGuideId) => {
  return privateApi.delete(basicUrl + "recipeInRecipeGuide", {
    params: {
      recipeGuideId: recipeGuideId,
      recipeId: recipeId
    },
  });
};

const addRecipe = (model) => {
  const body = {
    recipeGuideId: model.recipeGuideId,
    id: model.Id,
    title: model.title,
    description: model.description,
    recipeVideoUrl: getFileNameFromUrl(model.recipeVideoUrl),
    prepTimeInMinutes: model.prepTimeInMinutes,
    shelfLifeInDays: model.shelfLifeInDays,
    portion: model.portion,
    yield: model.yield,
    setupInstructions: model.setupInstructions,
    recipeIngredients: model?.recipeIngredients
  };
  return privateApi.post(basicUrl + "recipe", body);
};

const editRecipe = (recipeId, model) => {
  const body = {
    recipeGuideId: model.recipeGuideId,
    id: recipeId,
    title: model.title,
    description: model.description,
    recipeVideoUrl: getFileNameFromUrl(model.recipeVideoUrl),
    prepTimeInMinutes: model.prepTimeInMinutes,
    shelfLifeInDays: model.shelfLifeInDays,
    portion: model.portion,
    yield: model.yield,
    setupInstructions: model.setupInstructions,
    recipeIngredients: model?.recipeIngredients
  };
  return privateApi.put(basicUrl + "recipe", body);
};

const getRecipeByRecipeId = (recipeId) => {
  return privateApi.get(basicUrl + "recipe", {
    params: {
      recipeId: recipeId
    },
  });
};

const getRecipesByRecipeGuideId = (recipeGuideId) => {
  return privateApi.get(basicUrl + "recipeswithrecipeguideid", {
    params: {
      recipeGuideId: recipeGuideId
    },
  });
};

const getAllRecipes = (model) => {
  const params = new URLSearchParams();
  if (model.pageNum) params.append("pageNumber", model.pageNum);
  if (model.pageSize) params.append("pageSize", model.pageSize);
  if (model.searchTerm) params.append("recipeTitle", model.searchTerm);
  return privateApi.get(basicUrl + "recipes", { params });
};

const getRecipeCategories = (recipeId) => {
  return privateApi.get(basicUrl + "recipecategoriesbyrecipeid", {
    params: {
      recipeId: recipeId,
    },
  });
};

const getRecipePrepSteps = (recipeCategoryId) => {
  return privateApi.get(basicUrl + "recipepreparationstepsbyrecipecategoryid", {
    params: {
      recipeCategoryId: recipeCategoryId,
    },
  });
};

const getAssetsByRecipeGuideId = (recipeGuideId) => {
  return privateApi.get(basicUrl + "recipeguideresource", {
    params: {
      recipeGuideId: recipeGuideId,
    },
  });
};

const addRecipeCategory = (model) => {
  const body = {
    recipeId: model.recipeId,
    title: model.title,
    description: model.description,
  };
  return privateApi.post(basicUrl + "recipecategory", body);
};

const editRecipeCategory = (recipeCategoryId, model) => {
  const body = {
    id: recipeCategoryId,
    recipeId: model.recipeId,
    title: model.title,
    description: model.description,
  };
  return privateApi.put(basicUrl + "recipecategory", body);
};

const addPrepStep = (model) => {
  const body = {
    recipeCategoryId: model.categoryId,
    description: model.description,
    resourceUrl: getFileNameFromUrl(model.resourceUrl),
    resourceType: model.resourceType
  };
  return privateApi.post(basicUrl + "recipepreparationstep", body);
};

const editPrepStep = (prepStepId, model) => {
  const body = {
    id: prepStepId,
    recipeCategoryId: model.categoryId,
    description: model.description,
    resourceUrl: getFileNameFromUrl(model.resourceUrl),
    resourceType: model.resourceType
  };
  return privateApi.put(basicUrl + "recipepreparationstep", body);
};

const addRecipeGuideAsset = (model) => {
  let body = {
    recipeGuideId: model.recipeGuideId,
    recipeGuideResourceFiles: model?.recipeGuideResources
  };

  return privateApi.post(basicUrl + "recipeguideresource", body);
};

const editRecipeGuideAsset = (model) => {
  const body = {
    recipeGuideId: model.recipeGuideId,
    recipeGuideResourceFiles: model?.recipeGuideResources
  };
  return privateApi.put(basicUrl + "recipeguideresource", body);
};

const deleteRecipeGuideResources = (resourceId) => {
  return privateApi.delete(basicUrl + "recipeguideresource", {
    params: {
      recipeGuideResourceId: resourceId,
    },
  });
};

const getRecipeGuidesList = ({ pageSize, pageNum, searchTerm, brandId }) => {
   const params = new URLSearchParams();
   params.append("pageNum", pageNum);
   params.append("pageSize", pageSize);
   if (searchTerm) params.append("recipeGuideTitle", searchTerm);
   if (brandId) params.append("virtualBrandId", brandId);
   return privateApi.get(basicUrl + "recipeguideswithvbid", { params });
};

const deleteRecipeGuide = (recipeGuideId) => {
  return privateApi.delete(basicUrl + "recipeguide", {
    params: {
      recipeGuideId: recipeGuideId,
    },
  });
};

const deleteRecipe = (recipeId) => {
  return privateApi.delete(basicUrl + "recipe", {
    params: {
      recipeId: recipeId,
    },
  });
};

const deleteRecipeCategory = (recipeCategoryId) => {
  return privateApi.delete(basicUrl + "recipecategory", {
    params: {
      recipeCategoryId: recipeCategoryId,
    },
  });
};

const deletePrepStep = (recipePrepStepId) => {
  return privateApi.delete(basicUrl + "recipepreparationstep", {
    params: {
      recipePreparationStepId: recipePrepStepId,
    },
  });
};

const changeRecipeStatus = (recipeId, statusId) => {
  let body = {
    Id: recipeId,
    status: statusId
  };
  return privateApi.put(basicUrl + "recipeisctivestatus", body);
};

const checkRecipeGuideCount = (recipeId) => {
  return privateApi.get(basicUrl + "recipeguideCount", {
    params: {
      recipeId: recipeId,
    },
  });
};


const changeRecipeGuideStatus = (recipeId, statusId) => {
  let body = {
    Id: recipeId,
    status: statusId
  };
  return privateApi.put(basicUrl + "recipeguideaisctivestatus", body);
};

const changePrepStepStatus = (recipePrepStepId, statusId) => {
  let body = {
    Id: recipePrepStepId,
    status: statusId
  };
  return privateApi.put(basicUrl + "recipepreparationstepisactivestatus", body);
};

const changeRecipeCategoryStatus = (recipeId, statusId) => {
  let body = {
    Id: recipeId,
    status: statusId
  };
  return privateApi.put(basicUrl + "recipecategoryisactivestatus", body);
};

const getVirtualBrandStatus = (VBId, KitchenId) => {
  const params = new URLSearchParams();
  if (VBId) params.append("virtualbrandId", VBId);
  if (KitchenId) params.append("kitchenId", KitchenId);

  return privateApi.get(basicUrl + "virtualbrandstatus", { params });
};

export {
  getVirtualBrandStatus,
  GetMenuItemsWithRecipeGuide,
  addRecipeGuideBasicInfo,
  editRecipeGuideBasicInfo,
  GetRecipeGuideBasicInfo,
  addRecipe,
  editRecipe,
  getRecipeByRecipeId,
  deleteRecipe,
  changeRecipeStatus,
  changeRecipeGuideStatus,
  changeRecipeCategoryStatus,
  addRecipeCategory,
  addPrepStep,
  editPrepStep,
  getRecipesByRecipeGuideId,
  getRecipeCategories,
  getRecipePrepSteps,
  getAssetsByRecipeGuideId,
  addRecipeGuideAsset,
  getRecipeGuidesList,
  deleteRecipeGuide,
  deleteRecipeCategory,
  editRecipeCategory,
  changePrepStepStatus,
  deletePrepStep,
  editRecipeGuideAsset,
  deleteRecipeGuideResources,
  getAllRecipes,
  addRecipeForRecipeGuide,
  checkRecipeGuideCount,
  deleteRecipeForRecipeGuide
};
