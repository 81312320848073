import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { ModifierTypeDropdown } from "constants/modifierType";
import LoaderInline from "shared/loader/LoaderInline";

import "./AddModifierModal.scss";

const ViewModifierModal = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const modGroup = props?.modGroup;

  return (
    <Modal
      id="AddnewmodifierModal"
      className="centered"
      show={props.show}
      centered
    >
      <div className="addNewModifier__wrapper">
        <Modal.Header>
          <h2 id="modifiertype" className="mb-4">
            Modifier Details
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="basic__info__content addNewModifier__wrapper__inner">
            <div className="form-row no-gutters">
              <div className="col-md-12 pb-3">
                <label htmlFor="modifiergroupname" className="label__header">
                  Modifier Group Name
                </label>
                <p className="label__info">{modGroup?.name}</p>
              </div>

              <div className="col-md-12 pb-3">
                <label htmlFor="modifiertype" className="label__header">
                  Modifier Type
                </label>
                <p className="label__info">
                  {ModifierTypeDropdown[+modGroup.modifierType]?.text}
                </p>
              </div>

              <div className="col-md-12">
                <div className="d-flex">
                  <div className="col-10 p-0 mb-2">
                    <label htmlFor="modifieroptions" className="label__header">
                      Modifier Options
                    </label>
                  </div>
                  <div className="col-2 p-0">
                    <label htmlFor="modifierprice" className="label__header">
                      Price
                    </label>
                  </div>
                </div>
                {modGroup &&
                  modGroup?.menuItemModOptions?.map((field, idx) => (
                    <div key={field.id} className="d-flex pb-2 mb-2" style={{borderBottom: '1px solid #f2efef'}}>
                      <div className="col-10 p-0">
                        <p className="label__info m-0">{field.name}</p>
                      </div>
                      <div className="col-2 p-0">
                        <p className="label__info m-0">{field.price?.toFixed(2)}</p>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="col-12">
                <Modal.Footer>
                  <button
                    type="button"
                    className="red-btn next-btn btn"
                    onClick={props.onHide}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ViewModifierModal;
