import { privateApi } from "../utilities/Provider";

const basicUrl = "/MarketPlace/";

const getServices = () => {
  return privateApi.get("DeliveryService/all");
};

const getConnection = (connectionId) => {
  const params = new URLSearchParams();
  params.append("id", connectionId);
  return privateApi.get(basicUrl + "marketplacebyid", { params });
};

const addConnection = (associationId, model) => {
  let body = {
    kitchenVirtualBrandAssociationId: associationId,
    userName: model.username,
    password: model.password,
    platformMasterId: model.serviceId,
    marketPlaceId: model.marketplaceId,
    platformPickupRate: model?.platformPickupRate,
    platformDeliveryRate: model?.platformDeliveryRate,
    status: model.status === "true",
    pausedUntil: model?.pausedUntil,
    url: model.url,
    reason: model?.reason
  };
  return privateApi.post(basicUrl + "add", body);
};

const editConnection = (connectionId, model, kitchenVbAssociationId) => {
  let body = {
    id: connectionId,
    kitchenVirtualBrandAssociationId: kitchenVbAssociationId,
    userName: model.username,
    password: model.password,
    platformMasterId: model.serviceId,
    marketPlaceId: model.marketplaceId,
    status: model.status === "true",
    pausedUntil: model?.pausedUntil,
    platformPickupRate: model?.platformPickupRate,
    platformDeliveryRate: model?.platformDeliveryRate,
    url: model.url,
    reason: model?.reason
  };
  return privateApi.put(basicUrl + "edit", body);
};

const changeStatusConnection = (connectionId, status) => {
  let body = {
    id: connectionId,
    isActive: status,
  };
  return privateApi.put(basicUrl + "marketplacestatus", body);
};

const deleteConnection = (connectionId) => {
  const params = new URLSearchParams();
  params.append("id", connectionId);
  return privateApi.delete(basicUrl + "deletemarketplace", { params });
};

export {
  getServices,
  getConnection,
  addConnection,
  editConnection,
  changeStatusConnection,
  deleteConnection,
};
