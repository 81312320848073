import React, { useContext, useEffect, useState } from "react";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import { Global } from "constants/global";
import uploadFileToBlob from "services/azure-blob";
import { addRecipeGuideAsset, getAssetsByRecipeGuideId, editRecipeGuideAsset, deleteRecipeGuideResources } from "services/api/recipeguide";
import LoaderInline from "shared/loader/LoaderInline";
import { AssetType } from "constants/assetType";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import LoaderFull from "shared/loader/LoaderFull";
import ListingRow from "../listing/AttachmentsListingRow";
import DeleteModal from "UI/Modal/DeleteModal";
import "../../../pages/staff/listing/ManageModifierLandingpage.scss";
import RecipeGuideContext from "store/recipe-guide-context";
import { useParams } from "react-router";
import toastify from "shared/toastr";

const UploadAttachments = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const { recipeGuideId } = useParams("recipeGuideId");
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;
  const coverSize = Global.fiveMB;

  const [isLoading, setisLoading] = useState(false);
  const [coverImage, setcoverImage] = useState([]);
  const [isCoverUploading, setisCoverUploading] = useState(false);
  const [isAssetsUploading, setisAssetsUploading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [idxValue, setidxValue] = useState(null);
  const [removedFile, setremovedFile] = useState(null);

  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const[recipeguideTitle, setrecipeguideTitle] = useState(recipeguideCtx.selectedRecipeGuideName);
  console.log("UploadAttachments::recipeguideTitle", recipeguideTitle);

  /*  useEffect(() => {
     if (recipeGuideMode) {
       if (
         recipeGuideMode === VirtualBrandMode.SHARED ||
         recipeGuideMode === VirtualBrandMode.VIEW
       ) {
         setisFieldsDisabled(true);
       } else {
         setisFieldsDisabled(false);
       }
     }
   }, [recipeGuideMode]); */

  useEffect(() => {
    if (recipeGuideId) getAssets();
  }, [recipeGuideId]);

  const getAssets = async () => {
    console.log("getAssets::");
    setisLoading(true);
    await getAssetsByRecipeGuideId(recipeGuideId).then((res) => {
      setrecipeguideTitle(res.data?.recipeGuideTitle);
      let data = res.data?.recipeGuideResources;
      setisLoading(false);
      if (data?.length) {
        setcoverImage(data);
        //recipeguideCtx.setRecipeGuideInfo({res.data});
      }
    })
    .catch(() => {
      setisLoading(false);
    });
  };

  const skipStep = () => props.nextStep(recipeGuideId);
  const nextStep = () => props.nextStep(recipeGuideId);


  const uploadCoverImage = async (file) => {
    setisCoverUploading(true);
    await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
      (res) => {
        file["filePath"] = res?.filePath;
        setcoverImage(res);
        setisCoverUploading(false);
      },
      () => {
        setcoverImage(null);
        setisCoverUploading(false);
      }
    )
    setcoverImage(() => [...coverImage, file]);
  };

  const deleteFile = (idx, file) => {
    setdeleteModal(true);
    setidxValue(idx);
    setremovedFile(file);
  }

  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    const id = coverImage[idxValue]?.id || 0;
    coverImage.splice(idxValue, 1);
    setcoverImage(coverImage);
    if (id) {
      setdeleteLoading(true);
      await deleteRecipeGuideResources(id)
        .then((res) => {
          toastify("success", res.message);
          setdeleteLoading(false);
          closeDeleteModal();
        })
        .catch(() => {
          setdeleteLoading(false);
        });
    }
    else {
      closeDeleteModal();
    }
  }

  const onSubmit = async () => {
    /*  if (
       recipeGuideMode === VirtualBrandMode.SHARED ||
       recipeGuideMode === VirtualBrandMode.VIEW
     ) {
       props.nextStep();
       return;
     } */
    if (coverImage && coverImage.length) {
      let resourceArray;
      resourceArray = coverImage.map((res) => ({
        id: res?.id || 0,
        resourceUrl: res.filePath || res.resourceUrl,
        name: res.name,
        type: res.type,
        size: res.size
      }));

      let model = {
        recipeGuideId,
        recipeGuideResources: resourceArray,
      };
      setisAssetsUploading(true);
      if (resourceArray[0].id) {
        await editRecipeGuideAsset(model)
          .then((res) => {
            setisAssetsUploading(false);
            nextStep();
          })
          .catch((_) => {
            setisAssetsUploading(false);
          });
      }
      else {
        await addRecipeGuideAsset(model)
          .then((res) => {
            setisAssetsUploading(false);
            nextStep();
          })
          .catch((_) => {
            setisAssetsUploading(false);
          });
      }
    }
    else {
      nextStep();
    }
  };

  return (
    <>
      {isLoading ? <LoaderFull /> : null}{" "}
      <div className="uploadAssets__wrapper basic__info">
        <div className="main__heading">
          <h2>Upload Attachments - {recipeguideTitle}</h2>
          <p>Add any documents or images related to the recipe here.</p>
        </div>
        <div className="uploadAssets-wrapper pb-4">
          <div className="row">
            <div className="col">
              <h3>Upload Files</h3>
              <AssetDropArea
                dimensions="2000 X 1200px"
                maxSize={coverSize}
                acceptedFormat="application/pdf,.docx,.pptx, image/*"
                showFormatText="PDF, DOCX, PPTX / Images"
                onFileChange={uploadCoverImage}
                isImageUploading={isCoverUploading}
                uploadedImage={coverImage?.length}
                uploadedRawImage={coverImage?.length}
                disabled={isFieldsDisabled}
                multiple={true}
                type={"files"}
                btnText={"Add More File(s)"}
              />
            </div>
          </div>
        </div>


        {(coverImage && coverImage.length) ?
          (<>
            <div className="main__heading pb-0">
              <h2>Attachments {'(' + coverImage?.length + ')'}</h2>
            </div>
            <div className="manageUser__landingPage__wrapper">
              <div className="managermodifier__wrapper basic__info__content pb-0">
                <div className="container">
                  {/*   <div className="managermodifier__listinPage managerestra__listinPage"> */}
                  <div className="managermodifier__listinPage__wrapper">
                    <table className="table modifierListing__table ordersListing-table">
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td>
                              {/*  <LoaderBlock /> */}
                            </td>
                          </tr>
                        ) : (
                          coverImage.map((file, idx) => (
                            <ListingRow key={idx} file={file} retry={uploadCoverImage} isDownload={true} idx={idx} delete={deleteFile} />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </>) : null}
      </div>

      <div className="multiForm__actions mt-1">
        {recipeGuideMode === VirtualBrandMode.SHARED ||
          recipeGuideMode === VirtualBrandMode.VIEW ? null : (
          <button className="skip__link" type="button" onClick={skipStep}>
            Skip this Step
          </button>
        )}
        <button
          style={{ float: "right" }}
          className="red-btn"
          type="button"
          onClick={onSubmit}
          disabled={isCoverUploading}
        >
          {isCoverUploading ? (
            <LoaderInline />
          ) : (
            <>
              Continue
              <span className="icons-right">
                <img src={rightArrowIcon} alt="arrow icon" />
              </span>
            </>
          )}
        </button>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Attachment"
          subtitle={"Are you sure want to delete the attachment - " + removedFile?.name}
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

export default UploadAttachments;
