import React from "react";
import { Link } from "react-router-dom";
import "./Onboarding.scss";
import congrats from "../../assets/images/green-check-icon.svg";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const Congrats = () => {
  return (
    <div className="message__wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3 pb-3">
            <div className="goBack">
              <Link
                to="/home"
                className="back-btn-red"
                title="back to dashboard"
              >
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back to Home
              </Link>
            </div>
          </div>
        </div>
        <div className="message__wrapper__inner">
          <div className="content">
            <div className="img-box">
              <img src={congrats} alt="" className="img-fluid" />
            </div>
            <div className="content-box">
              <h1>Congrats!</h1>
              <p>You have successfully verified your email address.</p>
              <p>
                Please click on “Go to My Dashboard” and you will be redirected
                to your Virtual Brand’s Listings Page
              </p>
              <div className="action__wrapper">
                <button type="submit">Go to My Dashboard</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congrats;
