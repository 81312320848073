import React from "react";

const CommonCms = (props) => {
  const pageInfo = props.pageType;
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col text-center my-3">
            <h1>{pageInfo.text}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonCms;
