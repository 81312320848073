import React from "react";
import { Link } from "react-router-dom";
import "./ManageModifierLandingpage.scss";
import searchIcon from "../../assets/images/icon-search.svg";
import plusIcon from "../../assets/images/add-icon-white.svg";
import emptyIocn from "../../assets/images/emptyIcon.svg";
import editIcon from "../../assets/images/edit-blue.svg";

const ManageResturants = () => {
  return (
    <div className="managermodifier__wrapper basic__info__content">
      <div className="container">
        <div className="managermodifier__header">
          <div className="row justify-content-between">
            <div className="col-12 col-md-6">
              <h1>Manage Restaurant</h1>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <form>
                <div className="search__modifiers manage-restra-search">
                  <div className="search_modifier_group">
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="search"
                          id=""
                          className="custom-file form-control"
                          placeholder="Search Restaurant Name"
                        />
                        <button className="search-btn">
                          <span className="icons">
                            <img src={searchIcon} alt="plus icon" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="managermodifier__noListing d-none">
          <div className="managermodifier__noListing__innerwrapper">
            <div className="content">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src={emptyIocn}
                  alt="no list icon"
                  width="185"
                />
              </div>
              <div className="content-desc">
                <h3>No modifiers are added</h3>
                <p>
                  Please click on “Add New Modifier” to create new modifiers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="managermodifier__listinPage managerestra__listinPage">
          <div className="managermodifier__listinPage__wrapper">
            <table className="table modifierListing__table">
              <thead>
                <tr role="row" className="mat-header-row">
                  <th scope="col" className="mat-header-cell column-1">
                    Name
                  </th>
                  <th scope="col" className="mat-header-cell column-2">
                    Contact
                  </th>
                  <th scope="col" className="mat-header-cell column-3">
                    No. of Virtual Brands
                  </th>
                  <th scope="col" className="mat-header-cell column-5">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" className="mat-row">
                  <td className="mat-cell column-1" data-column="Name">
                    <Link
                      to="/resturants-details"
                      className="modifierDeatil__link"
                    >
                      Kentucky Fried Chicken
                    </Link>
                  </td>
                  <td className="mat-cell column-2" data-column="Contact">
                    <div>123 456 6679</div>
                  </td>
                  <td
                    className="mat-cell column-3"
                    data-column="No. of Virtual Brands"
                  >
                    2
                  </td>
                  <td className="mat-cell column-5" data-column="Action">
                    <div className="table__actions">
                      {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                      <div className="right_content">
                        <span
                          className="custom__badge badge__blue table__badge"
                        >
                          Request Pending
                        </span>
                        <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-button-drop-left"
                          ></button>
                          <div className="dropdown-menu">
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item active">
                                <span>Active</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Disable</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Draft</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Pending</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr role="row" className="mat-row">
                  <td className="mat-cell column-1" data-column="Name">
                    <Link
                      to="/resturants-details"
                      className="modifierDeatil__link"
                    >
                      Kentucky Fried Chicken
                    </Link>
                  </td>
                  <td className="mat-cell column-2" data-column="Contact">
                    <div>123 456 6679</div>
                  </td>
                  <td
                    className="mat-cell column-3"
                    data-column="No. of Virtual Brands"
                  >
                    2
                  </td>
                  <td className="mat-cell column-5" data-column="Action">
                    <div className="table__actions">
                      {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                      <div className="right_content">
                        <span
                          className="custom__badge badge__blue table__badge"
                        >
                          Request Pending
                        </span>
                        <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-button-drop-left"
                          ></button>
                          <div className="dropdown-menu">
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item active">
                                <span>Active</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Disable</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Draft</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Pending</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr role="row" className="mat-row">
                  <td className="mat-cell column-1" data-column="Name">
                    <Link
                      to="/resturants-details"
                      className="modifierDeatil__link"
                    >
                      Kentucky Fried Chicken
                    </Link>
                  </td>
                  <td className="mat-cell column-2" data-column="Contact">
                    <div>123 456 6679</div>
                  </td>
                  <td
                    className="mat-cell column-3"
                    data-column="No. of Virtual Brands"
                  >
                    2
                  </td>
                  <td className="mat-cell column-5" data-column="Action">
                    <div className="table__actions">
                      {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                      <div className="right_content">
                        <span
                          className="custom__badge badge__blue table__badge"
                        >
                          Request Pending
                        </span>
                        <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-button-drop-left"
                          ></button>
                          <div className="dropdown-menu">
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item active">
                                <span>Active</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Disable</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Draft</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Pending</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr role="row" className="mat-row">
                  <td className="mat-cell column-1" data-column="Name">
                    <Link
                      to="/resturants-details"
                      className="modifierDeatil__link"
                    >
                      Kentucky Fried Chicken
                    </Link>
                  </td>
                  <td className="mat-cell column-2" data-column="Contact">
                    <div>123 456 6679</div>
                  </td>
                  <td
                    className="mat-cell column-3"
                    data-column="No. of Virtual Brands"
                  >
                    2
                  </td>
                  <td className="mat-cell column-5" data-column="Action">
                    <div className="table__actions">
                      {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                      <div className="right_content">
                        <span
                          className="custom__badge badge__blue table__badge"
                        >
                          Request Pending
                        </span>
                        <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-button-drop-left"
                          ></button>
                          <div className="dropdown-menu">
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item active">
                                <span>Active</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Disable</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Draft</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Pending</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr role="row" className="mat-row">
                  <td className="mat-cell column-1" data-column="Name">
                    <Link
                      to="/resturants-details"
                      className="modifierDeatil__link"
                    >
                      Kentucky Fried Chicken
                    </Link>
                  </td>
                  <td className="mat-cell column-2" data-column="Contact">
                    <div>123 456 6679</div>
                  </td>
                  <td
                    className="mat-cell column-3"
                    data-column="No. of Virtual Brands"
                  >
                    2
                  </td>
                  <td className="mat-cell column-5" data-column="Action">
                    <div className="table__actions">
                      {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                      <div className="right_content">
                        <span
                          className="custom__badge badge__blue table__badge"
                        >
                          Request Pending
                        </span>
                        <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-button-drop-left"
                          ></button>
                          <div className="dropdown-menu">
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item active">
                                <span>Active</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Disable</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Draft</span>
                              </Link>
                            </div>
                            <div className="dropdown__innerWrapper">
                              <Link to="#" className="dropdown-item">
                                <span>Pending</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <nav aria-label="table pagination">
              <ul className="pagination justify-content-center justify-content-lg-end">
                <li className="page-item disabled">
                  <a
                    className="page-link first-child"
                    href="/"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    Previous<span className="arrow"></span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="/">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/">
                    <span className="arrow"></span>Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageResturants;
