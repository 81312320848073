export const Times = () => {
  let times = [];
  times.push({ value: `00:00:00`, text: `12:00 AM` });
  times.push({ value: `00:30:00`, text: `12:30 AM` });
  for (let i = 1; i <= 11; i++) {
    times.push({
      value: `${String(i).padStart(2, "0")}:00:00`,
      text: `${i}:00 AM`,
    });
    times.push({
      value: `${String(i).padStart(2, "0")}:30:00`,
      text: `${i}:30 AM`,
    });
  }
  times.push({ value: `12:00:00`, text: `12:00 PM` });
  times.push({ value: `12:30:00`, text: `12:30 PM` });
  for (let i = 1; i <= 11; i++) {
    times.push({ value: `${12 + i}:00:00`, text: `${i}:00 PM` });
    times.push({ value: `${12 + i}:30:00`, text: `${i}:30 PM` });
  }
  return times;
};
