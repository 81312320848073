import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import LoaderInline from "shared/loader/LoaderInline";
import backArrowIcon from "assets/images/back-arrow.svg";
import landingLogo__black from "assets/images/landing-logo-black.svg";
import "./ContactUs.scss";
import { sendContactUsDetails } from "services/api/staff";
import toastify from "shared/toastr";
import { getUserDataFromToken } from "services/local-storage";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { redirectToHome } from "shared/navigation-utils/index";

const Contact = () => {
  const isLoggedIn = getUserDataFromToken();

  const history = useHistory();
  const [scrolled, setScrolled] = useState(false);

  const [isLoading, setisLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().max(50).required("Please enter the name"),

    phone: Yup.string()
    .transform((value) => (value ? value.replace(/\D/g, "") : ""))
    .test("len", "Phone number must be exactly 10 characters long",
    (value) => value && value.length === 10)
    .required("Please enter the phone number"),
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .max(50)
      .required("Please enter a valid email address"),
    businessName: Yup.string().trim().max(50),
    message: Yup.string().trim().max(500).required("Please enter message")
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }

  const onSubmit = async (data) => {
    setisLoading(true);
    await sendContactUsDetails(data)
      .then((res) => {
        toastify("success", res.message);
        setisLoading(false);
        onBack();
      })
      .catch(() => setisLoading(false));
  };

  const onBack = () => {
    history.push("/home");
  };

  return (
    <>
      <div className="landingPage__wrapper static__pages__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid white__logo"
                    width={120}
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                    width={120}
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                {isLoggedIn ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-link signUp__link w-100 h-100 mx-auto"
                    role="button"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-link signIn__link"
                      role="button"
                    >
                      Login
                    </Link>
                    <Link
                      to="/kitchen/register"
                      className="btn btn-link signUp__link"
                      role="button"
                    >
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        <main>
          <Subheader mobileExtraClass="staticPages_subheader">
            <div className="goBack mb-3">
              <button className="back-btn-red" title="back to home" onClick={redirectToHome}>
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back to Home
              </button>
            </div>
          </Subheader>
          <div className="container">
            <section className="page__title__section">
              <div className="row">
                <div className="col-12 d-none d-md-block">
                  <div className="goBack">
                    <button className="back-btn-red" title="back to home" onClick={redirectToHome}>
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back to Home
                    </button>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Contact Us</h1>
                    <p>Enter your information below to request a callback.</p>
                  </div>
                </div>
              </div>
            </section>
            <div className="conatctUs__wrapper">
              <div className="modifierDetails__wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="details__wrapper editModifier__wrapper">
                      <div className="details__wrapper__inner">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="basic__info__content">
                            <div className="form-row">
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="name">
                                  Name
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  placeholder="e.g. Nombeko Mabandla"
                                  {...register("name")}
                                  maxLength="50"
                                  className={`form-control ${
                                    errors.name ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.name?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.name?.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="Phone Number">
                                  Phone Number
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="phoneNumber"
                                  placeholder="e.g. 234 567 8907"
                                  {...register("phone")}
                                  maxLength="50"
                                  className={`form-control ${
                                    errors.phone ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.phone?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.phone?.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="emailAddress">
                                  Email Address
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="email"
                                  id="emailAddress"
                                  placeholder="e.g. abc@example.com"
                                  {...register("email")}
                                  maxLength="50"
                                  className={`form-control ${
                                    errors.email ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.email?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.email?.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-md-3 pb-md-3 px-0">
                                <label htmlFor="BusinessName">
                                  Business Name
                                </label>
                                <input
                                  type="text"
                                  id="BusinessName"
                                  placeholder="e.g. True’s Pizza"
                                  {...register("businessName")}
                                  maxLength="50"
                                  className={`form-control ${
                                    errors.businessName ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.businessName?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.businessName?.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="message">
                                  Message<span className="mendatory">*</span>
                                </label>
                                <textarea
                                  id="message"
                                  placeholder="Add Message"
                                  rows="3"
                                  className={`form-control ${errors?.message ? "is-invalid" : ""
                                    }`}
                                  {...register("message")}
                                  maxLength="500"
                                ></textarea>
                                {errors?.message?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors?.message?.message}
                                  </div>
                                )}
                              </div>

                            </div>
                            <div className="col-md-12 px-0">
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="cancel-btn red-btn-ghost btn"
                                  onClick={onBack}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="red-btn next-btn btn"
                                  disabled={isLoading || !isValid}
                                >
                                  {isLoading ? <LoaderInline /> : "Submit"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Contact;
