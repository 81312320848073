import React, { useState, useEffect } from "react";

import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import editIcon from "assets/images/edit.svg";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import { addNewVbCredential, editVbCredential, sendToSupportEmail } from "services/api/brand";
import deleteIocn from "assets/images/delete-icon.svg";
import DeleteModal from "UI/Modal/DeleteModal";
import { Global } from "constants/global"

const CredentialRow = (props) => {
  const brandId = props?.associatedBrandId;
  const [creds, setCreds] = useState(props?.credentials);
  const sendInfo = props?.onUpdate;

  const [modalShow, setModalShow] = useState(false);
  const [apiKey, setApiKey] = useState(props?.credentials?.apiKey);
  const [popupShow, setPopupShow] = useState(false);

  useEffect(() => {
    reset({
      webhookUrl: creds?.webhookUrl || getWebhookUrl(),
      apiKey: creds?.apiKey,
      storeId: creds?.storeId,
    });
  }, [creds]);

  const [isLoading, setisLoading] = useState(false);
  const [iscontactSupportloading, setIscontactSupportloading] = useState(false);
  const [deleteCredsPopUp, setdeleteCredsPopUp] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    webhookUrl: Yup.string()
      .nullable()
      .trim()
      .url("Please enter a valid webhook URL")
      .max(500)
      .required("Please enter webhook URL"),
    apiKey: Yup.string()
      .nullable()
      .trim()
      .max(500)
      .required("Please enter an API Key"),
    storeId: Yup.string()
      .nullable()
      .trim()
      .max(500)
      .required("Please enter the store id"),
    partnerId: Yup.string()
      .nullable()
      .trim()
      .max(500)
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const getWebhookUrl = () => {
    let webHookUrl = "";
    let webhookKitchenId = String(props?.kitchenId);
    let webhookBrandId = String(props?.brandId);
    if (props?.kitchenId && props?.brandId) {
      webHookUrl = Global.webHookUrl;
      while (webhookKitchenId.length < 3) {
        webhookKitchenId = "0" + webhookKitchenId;
      }
      while (webhookBrandId.length < 5) {
        webhookBrandId = "0" + webhookBrandId;
      }
      webHookUrl = webHookUrl + webhookKitchenId + webhookBrandId;
    }
    return webHookUrl;
  };

  const openModal = () => {
    reset({
      webhookUrl: creds?.webhookUrl || getWebhookUrl(),
      apiKey: creds?.apiKey,
      storeId: creds?.storeId,
      partnerId: creds?.partnerId
    });
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
    reset();
  };

  const closePopUpModal= () => {
    setPopupShow(false);
  };

  const onSubmit = async (data) => {
    setisLoading(true);
    let model = {
      brandId,
      url: data?.webhookUrl,
      key: data?.apiKey,
      storeId: data?.storeId,
      partnerId: data?.partnerId
    };
    if (creds?.apiKey || creds?.webhookUrl || creds?.storeId) {
      await editVbCredential(model).then(
        (res) => {
          toastify("success", res.message);
          setCreds({
            webhookUrl: data?.webhookUrl,
            apiKey: data?.apiKey,
            storeId: data?.storeId,
            partnerId: data?.partnerId
          });
          sendInfo({
            webhookUrl: data?.webhookUrl,
            apiKey: data?.apiKey,
            storeId: data?.storeId,
            partnerId: data?.partnerId
          });
          setisLoading(false);
          setModalShow(false);
        },
        (_) => {
          setisLoading(false);
          setPopupShow(true);
          setApiKey(data?.apiKey);
        }
      );
    } else {
      await addNewVbCredential(model).then(
        (res) => {
          toastify("success", res.message);
          setCreds({
            webhookUrl: data?.webhookUrl,
            apiKey: data?.apiKey,
            storeId: data?.storeId,
            partnerId: data?.partnerId
          });
          sendInfo({
            webhookUrl: data?.webhookUrl,
            apiKey: data?.apiKey,
            storeId: data?.storeId,
            partnerId: data?.partnerId
          });
          setisLoading(false);
          setModalShow(false);
        },
        (_) => {
          setisLoading(false);
          setPopupShow(true);
          setApiKey(data?.apiKey);
        }
      );
    }
  };

const onDeleteCreds = async() =>{
   setdeleteLoading(true);
    let model = {
      brandId,
      url: null,
      key: null,
      storeId: null,
      partnerId: null
    };
    await editVbCredential(model).then(
      (res) => {
        toastify("success", res.message);
        setCreds({
          webhookUrl: "",
          apiKey: "",
          storeId: "",
          partnerId: ""
        });
        sendInfo({
          webhookUrl: "",
          apiKey: "",
          storeId: "",
          partnerId: ""
        });
        setdeleteLoading(false);
  });
  closeDeleteModal();
}

  const onContactSupport = async () => {
    setIscontactSupportloading(true)
    let model = {
      brandId,
      key: apiKey || creds?.apiKey,
    };
    await sendToSupportEmail(model).then(
      (res) => {
        setIscontactSupportloading(false);
        setPopupShow(false);
        toastify("success", res.message);
      },
      (_) => {
        setPopupShow(true);
        setIscontactSupportloading(false)
      }
    );

  }
  const closeDeleteModal = () => setdeleteCredsPopUp(false);
  return (
    <>
      <div className="accordion__data__card">
        <div className="row no-gutters justify-content-between">
          <div className="col-10 col-md-10">
            <div className="row">
              <div className="col-12 col-md-4 pb-3 mb-md-4">
                <div className="data">
                  <label>Webhook URL</label>
                  <p>{creds?.webhookUrl || getWebhookUrl()}</p>
                </div>
              </div>
              <div className="col-12 col-md-4 pb-3 mb-md-4">
                <div className="data">
                  <label>API Key</label>
                  <p>{creds?.apiKey || "-"}</p>
                </div>
              </div>
              <div className="col-12 col-md-2 pb-3 mb-md-4">
                <div className="data">
                  <label>Store Id</label>
                  <p>{creds?.storeId || "-"}</p>
                </div>
              </div>
              <div className="col-12 col-md-2 pb-3 mb-md-4">
                <div className="data">
                  <label>Partner Id</label>
                  <p>{creds?.partnerId || "-"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 col-md-1">
            <div className="edit__button__wrapper display__flex">
              <button
                title={creds?.apiKey ? "Edit" : "Add"}
                className="btn edit-btn m-0"
                onClick={openModal}
              >
                <span>
                  <img src={editIcon} className="mr-3" alt="edit-icon" />
                </span>
              </button>
              {(creds?.webhookUrl || creds?.apiKey || creds?.storeId) && (
                <button
                  type="button"
                  className="btn edit-btn m-0"
                  onClick={() => setdeleteCredsPopUp(true)}
                >
                  <span>
                    <img src={deleteIocn} className="icons m-0" alt="remove" />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={closeModal}
          centered
          className="credentials__modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {creds?.apiKey ? "Edit" : "Add"} Credentials
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row no-gutters">
                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="webhookUrl">
                    Webhook URL <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="webhookUrl"
                    placeholder="Enter Webhook URL"
                    {...register("webhookUrl")}
                    maxLength="500"
                    className={`form-control ${
                      errors.webhookUrl ? "is-invalid" : ""
                    }`}
                  />
                  {errors.webhookUrl?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.webhookUrl?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="apiKey">
                    API Key <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="apiKey"
                    placeholder="Enter Webhook URL"
                    {...register("apiKey")}
                    maxLength="500"
                    className={`form-control ${
                      errors.apiKey ? "is-invalid" : ""
                    }`}
                  />
                  {errors.apiKey?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.apiKey?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="storeId">
                    Store Id <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="storeId"
                    placeholder="Enter Store Id"
                    {...register("storeId")}
                    maxLength="500"
                    className={`form-control ${
                      errors.storeId ? "is-invalid" : ""
                    }`}
                  />
                  {errors.storeId?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.storeId?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="partnerId">Partner Id</label>
                  <input
                    type="text"
                    id="partnerId"
                    placeholder="Enter Partner Id"
                    {...register("partnerId")}
                    maxLength="500"
                    className={`form-control ${
                      errors.partnerId ? "is-invalid" : ""
                    }`}
                  />
                  {errors.partnerId?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.partnerId?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-12 action__wrapper d-flex flex-column-reverse d-md-flex justify-content-between">
                  <button
                    type="button"
                    className="cancel-btn red-btn-ghost btn mr-5"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="prelogin-links-btn mb-4"
                    disabled={isLoading}
                  >
                    {isLoading ? <LoaderInline /> : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {popupShow && (
        <div className="confirmation__wrapper">
          <Modal
            show={popupShow}
            onHide={closePopUpModal}
            animation={false}
            id="confirmation__modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="second-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="confirmation__heading">
                {"Api Key already exists"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <p className="mb-0">
                {"Is this a duplicate API Key?"}
                <br />
                {
                  "We are unable to save this API Key because it is either invalid or is already associated with another virtual brand. Please try again or contact support"
                }
              </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                onClick={closePopUpModal}
                className="cancel-btn red-btn-ghost btn"
              >
                Cancel
              </Button>
              <Button
                onClick={onContactSupport}
                className="red-btn btn"
                disabled={props.onLoading}
              >
                {iscontactSupportloading ? <LoaderInline /> : "Contact Support"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {deleteCredsPopUp && (
        <DeleteModal
          show={deleteCredsPopUp}
          title="Delete Virtual Brand Credentials"
          subtitle="Are you sure you want to delete Virtual Brand Credentials?"
          onCancel={closeDeleteModal}
          onSubmit={onDeleteCreds}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

export default CredentialRow;
