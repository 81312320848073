import React, { useState, useEffect } from "react";
import { getVirtualBrandsByKitchen } from "services/api/brand";

import { SelectDropdownSearch } from "UI/Select-Dropdown/SelectDropdownSearch";

const BrandFilter = (props) => {
  const kitchenId = props.kitchenId;
  let intialValue = props.value;
  const onSelect = props.onSelect;

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(intialValue);

  useEffect(() => {
    getBrandsForDD();
  }, [kitchenId]);

  const getBrandsForDD = async () => {
    setBrands([{ value: 0, label: "All" }]);
    //setSelectedBrand(0);
    await getVirtualBrandsByKitchen(kitchenId).then((res) => {
      let data = res.data;
      if (data && data.length) {
        let brands = data.map((res) => ({
          value: res.id,
          label: res.name,
        }));
        brands.unshift({ value: 0, label: "All" });
        setBrands(brands);
      } else setBrands([{ value: 0, label: "All" }]);
    });
  };

  const onKitchenValueSelected = (val) => {
    setSelectedBrand(val.value);
    onSelect(val);
  };

  return (
    <>
      {brands && (
        <SelectDropdownSearch
          options={brands}
          placeholder="Virtual Brand"
          selectedValue={onKitchenValueSelected}
          value={selectedBrand}
          searchPlaceholder="Search Virtual Brand"
        />
      )}
    </>
  );
};

export default BrandFilter;
