import * as Yup from "yup";

export const TimingSchema = Yup.object().shape({
  opensAt: Yup.string().trim().required(),
  closesAt: Yup.string().trim().required(),
});

export const AvailabilityValidationSchema = {
  monday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
  tuesday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
  wednesday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
  thursday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
  friday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
  saturday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
  sunday: Yup.object().shape({
    timings: Yup.array().of(TimingSchema).min(1).required(),
    isActive: Yup.boolean().required(),
  }),
};
