import React from "react";
import editIcon from "assets/images/edit.svg";

const EditButton = (props) => {
  return (
    <button {...props} type="button" className="icons edit-btn">
      <img src={editIcon} alt="edit" />
    </button>
  );
};

export default EditButton;
