import React, { useEffect, useState } from "react";

import Select from "react-select";

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

export const RecipeGuideSelectDropdownSearch = (props) => {
  const options = props.options;
  const placeholder = props.placeholder;
  const sendSelectedValue = props.selectedValue;
  const searchPlaceholder = props.searchPlaceholder || "Search..";
  const textType = props.type;
  const [isOpen, setisOpen] = useState(false);
  const [value, setvalue] = useState(null);

  useEffect(() => {
    if (props && props.options && props.options.length) {
      let findVal = options.find((res) => res.value === props.value);
      setvalue(findVal);
    }
  }, [props]);

  const toggleOpen = () => {
    setisOpen(!isOpen);
  };

  const onSelectChange = (value) => {
    toggleOpen();
    setvalue(value);
    sendSelectedValue(value);
  };

  const truncate = (str) => {
    return str.substring(0, 35) + "..."
  }

  const finalValue = value?.recipeGuideId && props?.selectedMenuItem ? props.selectedMenuItem : (value?.label || placeholder)

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <button
          type="button"
          className={`form-control text-left searchable-menu-${
            isOpen ? "open" : "close"
          }`}
          onClick={toggleOpen}
        >
          {finalValue?.length > 36 ? `${truncate(finalValue)}` : finalValue}
        </button>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        options={options}
        placeholder={searchPlaceholder}
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
        classNamePrefix="searchable__dropdown__ckc"
        isDisabled={props?.disabled}
      />
    </Dropdown>
  );
};

// styled components

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      className="searchable-dropbox"
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
        width: "100%",
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
