import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import VirtualBrandContext from "store/virtual-brand-context";
import MenuForm from "../create-form/MenuForm";
import "./CreateMenuModal.scss";

const MenuModal = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const modalExtras = brandCtx.modalExtras;

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
        <Modal.Header closeButton>
          <Modal.Title id="addMenu__item__modal">
            <h1>
              {modalExtras?.isEditMode && "Edit"}
              {modalExtras?.isViewMode && "View"}
              {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                ? "Add"
                : null}{" "}
              Menu
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MenuForm />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MenuModal;
