import React, { useState, useEffect } from "react";
import "./VBLandingPage.scss";
import { useParams } from "react-router";
import { getLandingPageData, getTrackingPageData } from "services/api/brand";
import LoaderBlock from "shared/loader/LoaderBlock";
import { useMediaQuery } from "react-responsive";
import doordashImg from "assets/images/Doordash1.svg";
import grubhubImg from "assets/images/Grubhub1.svg";
import ubereatsImg from "assets/images/Ubereats1.svg";
import postmatesImg from "assets/images/Postman1.svg";
import fooddudesImg from "assets/images/Fooddudes1.svg";
import BHBImg from "assets/images/BB.png";
import { ServiceId } from "constants/serviceType";
import OrderTrackingModal from "pages/order/view/OrderTrackingModal";

const VBLandingPage = (props) => {
  const { kitchenVbId, orderId } = useParams();
  let kitchenId, vbId;
  if (kitchenVbId && parseInt(kitchenVbId) > 0) {
    kitchenId =
      kitchenVbId[0] === "0"
        ? kitchenVbId.slice(1, 3)
        : kitchenVbId.slice(0, 3);
    vbId = kitchenVbId.slice(4, kitchenVbId.length);
  }
  const [isLoading, setisLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (orderId && parseInt(orderId) > 0) {
      gettrackingDetail();
    }
    else if ( kitchenId && vbId &&
      parseInt(kitchenId) > 0 && parseInt(vbId) > 0
    ) {
      getDetail();
    }
  }, [kitchenId, vbId, orderId]);

   const getDetail = async () => {
     setisLoading(true);
     await getLandingPageData(kitchenId, vbId)
       .then((res) => {
         setData(res?.data);
         setisLoading(false);
       })
       .catch((err) => {
         setisLoading(false);
       });
   };

  const gettrackingDetail = async () => {
    setisLoading(true);
    await getTrackingPageData(orderId)
      .then((res) => {
        setData(res?.data);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const getServiceImageUrl = (id) => {
    switch (id) {
      case ServiceId.DOORDASH:
        return doordashImg;
      case ServiceId.GRUBHUB:
        return grubhubImg;
      case ServiceId.UBEREATS:
        return ubereatsImg;
      case ServiceId.POSTMATES:
        return postmatesImg;
      case ServiceId.FOODDUDES:
        return fooddudesImg;
      case ServiceId.GARCON:
        return BHBImg;
      default:
        return BHBImg;
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

    return (
      <>
        {isMobile ? (
          <div className="landing__page__wrapper">
            {isLoading ? (
              <LoaderBlock />
            ) : (
              <div className="background__image__container">
                <div
                  className="background__image"
                  style={{
                    backgroundImage: `url(${
                      data?.brandCoverUrl ||
                      "assets/images/placeholder-cover.png"
                    })`,
                  }}
                ></div>
                <div className="overlay__container">
                  <div className="overlay__inner">
                    <div className="image__container">
                      <img
                        src={
                          data?.brandLogoUrl ||
                          "assets/images/placeholder-logo.png"
                        }
                        alt="virtual brand logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="vb__desc">
                      <p>{data?.brandDescription}</p>
                    </div>
                    {data?.deliveryJobId && (
                      <button
                        onClick={() => setIsOpen(true)}
                        className="track__btn landing__track__btn"
                      >
                        {`TRACK ORDER #${orderId}`}
                      </button>
                    )}
                  </div>
                  <div className="order__text">Order Online</div>
                  <div className="order__platform__icons">
                      {data?.orderingPlatforms?.length > 0 &&
                        data.orderingPlatforms.map((item, idx) => (
                          item?.isActive === true && (<div className="icon" key={idx}>
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={getServiceImageUrl(item.platformMasterId)}
                                alt="virtual brand logo"
                                className="img-fluid"
                              />
                            </a>
                          </div>)
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="landing__page__wrapper">
            {isLoading ? (
              <LoaderBlock />
            ) : (
              <div className="landing__page__container">
                <div className="landing__page__left__card">
                  <div className="left__card__inner">
                    <div className="image__container">
                      <img
                        src={
                          data?.brandLogoUrl ||
                          "assets/images/placeholder-logo.png"
                        }
                        alt="virtual brand logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="vb__desc">
                      <p>{data?.brandDescription}</p>
                    </div>
                    <div className="order__text">Order Online</div>
                    <div className="order__platform__icons">
                      {data?.orderingPlatforms?.length > 0 &&
                        data.orderingPlatforms.map((item, idx) => (
                          item?.isActive === true && (<div className="icon" key={idx}>
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={getServiceImageUrl(item.platformMasterId)}
                                alt="virtual brand logo"
                                className="img-fluid"
                              />
                            </a>
                          </div>)
                        ))}
                    </div>
                    {/* <div className="preview">
                  <button
                    type="button"
                    className="preview__btn"
                    disabled={true}
                  >
                    Preview Menu
                  </button>
                </div> */}
                  </div>
                </div>
                {data?.deliveryJobId ? (
                  <div className="landing__page__right__card">
                    <iframe
                      src={`https://portal.usenash.com/tracking/${data?.deliveryJobId}`}
                      title="TrackingPortal"
                      className="tracking__iframe"
                    />
                  </div>
                ) : (
                  <div
                    className="landing__page__right__card"
                    style={{
                      backgroundImage: `url(${
                        data?.brandCoverUrl ||
                        "assets/images/placeholder-cover.png"
                      })`,
                    }}
                  ></div>
                )}
              </div>
            )}
          </div>
        )}
        {isOpen && (
          <OrderTrackingModal
            show={isOpen}
            className="right tracking"
            onHide={closeModal}
            deliveryJobId={data?.deliveryJobId}
          />
        )}
      </>
    );
};

export default VBLandingPage;
