import React from "react";
import { Modal } from "react-bootstrap";
import OrderDetails from "./OrderDetails";

const OrderDetailsModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="order__details__modal"
        id="order__details__modal"
      >
        <Modal.Body>
          <OrderDetails data={props.data} onHide={props.onHide} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderDetailsModal;
