import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import "./ManageResturantsDetails.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import { getKitchenById } from "services/api/kitchen";
import ChangeStatus from "../change-status/ChangeStatus";
import LoaderBlock from "shared/loader/LoaderBlock";
import GoogleMap from "UI/Map/GoogleMap";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import BrandList from "./associated-brand/BrandList";
import DeviceList from "./associated-device/DeviceList";
import ProvidersList from "./associated-providers/ProvidersList";
import RestaurantModifierListing from "./associated-modifiers/RestaurantModifierListing";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";
import MarketPlaceDeliveryRestriction from "./MarketplaceDeliveryRestriction";

const RestaurantView = (props) => {
  const { restId } = useParams();
  const [restaurant, setrestaurant] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [activeStep, setactiveStep] = useState(1);
   const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;

  useEffect(() => {
    if (restId) {
      if (parseInt(restId) > 0) {
        getDetail(+restId);
      }
    }
  }, [restId]);

  const getDetail = async (id) => {
    setisLoading(true);
    await getKitchenById(id)
      .then((res) => {
        setrestaurant(res.data);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.goBack();
  };

  const onTabChange = (step) => {
    setactiveStep(step);
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>

      <div className="modifierDetails__wrapper restaurantDetails__wrapper restaurantDetails__wrapper-map">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="goBack">
                <button
                  type="button"
                  onClick={onBack}
                  className=" back-btn-red"
                >
                  <span className="icons">
                    <img src={backArrowIcon} alt="back icon" />
                  </span>
                  Back
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="main_heading">
                <h1>Restaurant Details</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                {isLoading ? (
                  <LoaderBlock />
                ) : (
                  <div className="details__wrapper__inner">
                    <div className="left_content">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard">
                              <h5>Business Name</h5>
                              <p className="mb-0">{restaurant?.name || "NA"}</p>
                            </div>
                          </div>
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard">
                              <h5>Contact Name</h5>
                              <p className="mb-0">
                                {restaurant?.kitchenOwner?.fullName || "NA"}
                              </p>
                            </div>
                          </div>
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard">
                              <h5>Email Address</h5>
                              <p className="mb-0">
                                {restaurant?.kitchenOwner?.email || "NA"}
                              </p>
                            </div>
                          </div>
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard">
                              <h5>Phone Number</h5>
                              <p className="mb-0">
                                {restaurant?.kitchenOwner?.phoneNumber || "NA"}
                              </p>
                            </div>
                          </div>
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard">
                              <h5>Order Confirm Phone Number</h5>
                              <p className="mb-0">
                                {restaurant?.listedPhoneNumber ||
                                  restaurant?.kitchenOwner?.phoneNumber}
                              </p>
                            </div>
                          </div>
                          {[
                            RoleType.ADMIN,
                            RoleType.ADMIN_STAFF,
                            RoleType.KITCHEN_OWNER,
                            RoleType.KITCHEN_STAFF,
                          ].includes(userRole) && (
                            <div className="mb-3 pb-md-3">
                              <div className="custom-checkbox-wrapper">
                                <label className="custom-checkbox-container">
                                  <input
                                    type="checkbox"
                                    checked={
                                      restaurant?.callRestaurantEnabled === true
                                        ? true
                                        : false
                                    }
                                    disabled={true}
                                  />
                                  <span
                                    className="checkmark"
                                    style={{ top: "0px" }}
                                  ></span>
                                </label>
                                <div
                                  className="detailCard no__wrap"
                                  style={{ paddingLeft: "24px" }}
                                >
                                  <h5>Call to Confirm Order</h5>
                                </div>
                              </div>
                            </div>
                          )}
                          {userRole === RoleType.ADMIN && (
                            <div className="mb-3 mb-md-0">
                              <div className="custom-checkbox-wrapper">
                                <label className="custom-checkbox-container">
                                  <input
                                    type="checkbox"
                                    checked={
                                      restaurant?.salesTaxIndicator === true
                                        ? true
                                        : false
                                    }
                                    disabled={true}
                                  />
                                  <span
                                    className="checkmark"
                                    style={{ top: "0px" }}
                                  ></span>
                                </label>
                                <div
                                  className="detailCard"
                                  style={{ paddingLeft: "24px" }}
                                >
                                  <h5>Pay Sales Tax</h5>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard">
                              <h5>Business Address</h5>
                              <p className="mb-0">
                                {restaurant?.businessAddress || "NA"}
                              </p>
                            </div>
                          </div>
                          <div className="mb-3 pb-md-4">
                            <div className="detailCard restra__g__map">
                              <GoogleMap
                                width="100%"
                                height="234px"
                                latitude={restaurant?.latitude}
                                longitude={restaurant?.longitude}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ChangeStatus
                      restaurantId={restaurant?.id}
                      status={restaurant?.status}
                      onActionComplete={onBack}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <Tab.Container defaultActiveKey="first" mountOnEnter unmountOnExit>
            <div className="tab_details__wrapper">
              <Row className="no-gutters">
                <Col>
                  <Nav variant="tabs" className="nav_tabs_styling">
                    <Nav.Item className="nav_item_styling">
                      <Nav.Link
                        eventKey="first"
                        active={activeStep === 1}
                        onClick={() => onTabChange(1)}
                      >
                        Virtual Brands
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav_item_styling">
                      <Nav.Link
                        eventKey="second"
                        active={activeStep === 2}
                        onClick={() => onTabChange(2)}
                      >
                        Modifiers
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav_item_styling">
                      <Nav.Link
                        eventKey="third"
                        active={activeStep === 3}
                        onClick={() => onTabChange(3)}
                      >
                        Devices
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav_item_styling">
                      <Nav.Link
                        eventKey="fourth"
                        active={activeStep === 4}
                        onClick={() => onTabChange(4)}
                      >
                        Providers
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav_item_styling">
                      <Nav.Link
                        eventKey="fifth"
                        active={activeStep === 5}
                        onClick={() => onTabChange(5)}
                      >
                        Delivery
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="sixth"
                        active={activeStep === 6}
                        onClick={() => onTabChange(6)}
                      >
                        Payout
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </div>
            <Row className="no-gutters">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    active={activeStep === 1}
                    mountOnEnter
                    unmountOnExit
                  >
                    {/* associated kitchens */}
                    <BrandList kitchen={restaurant} />
                  </Tab.Pane>
                  {/* associated modifer options with modifiers data for all VB's in a restaurant */}
                  <Tab.Pane
                    eventKey="second"
                    active={activeStep === 2}
                    mountOnEnter
                    unmountOnExit
                  >
                    <RestaurantModifierListing kitchen={restaurant} />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="third"
                    active={activeStep === 3}
                    mountOnEnter
                    unmountOnExit
                  >
                    {/* associated devices */}
                    <DeviceList kitchen={restaurant} />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="fourth"
                    active={activeStep === 4}
                    mountOnEnter
                    unmountOnExit
                  >
                    {/* associated devices */}
                    <ProvidersList kitchen={restaurant} />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="fifth"
                    active={activeStep === 5}
                    mountOnEnter
                    unmountOnExit
                  >
                    <MarketPlaceDeliveryRestriction
                      kitchen={restaurant}
                      type="delivery"
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="sixth"
                    active={activeStep === 6}
                    mountOnEnter
                    unmountOnExit
                  >
                    <MarketPlaceDeliveryRestriction
                      kitchen={restaurant}
                      type="payout"
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default RestaurantView;