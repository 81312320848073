import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "react-bootstrap";
import "./PopUpModal.scss";
import { Link, withRouter } from "react-router-dom";
import LoaderInline from "shared/loader/LoaderInline";

const PopUpModal = (props) => {
  return (
    <>
      <div className="confirmation__wrapper">
        <Modal
          show={props.show}
          onHide={props.onCancel}
          animation={false}
          id="confirmation__modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="second-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="confirmation__heading">{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <p className="mb-0">{props.onLoading ? <LoaderInline /> : props.subtitle}</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              onClick={props.onCancel}
              className="cancel-btn red-btn-ghost btn"
            >
              Cancel
            </Button>
            {props.dialog === "editVB" ?
                (<Link to={props.onLink}><Button
                  onClick={props.onSubmit}
                  className="red-btn btn"
                  disabled={props.onLoading}
                > {"Yes, Continue"}</Button>
              </Link>) :
               (<Button
               onClick={props.onSubmit}
               className="red-btn btn"
               disabled={props.onLoading}
             >
               {props.onLoading ? <LoaderInline /> : "Yes, Continue"}
             </Button>)}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

PopUpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLoading: PropTypes.bool.isRequired,
};

export default PopUpModal;
