import React, { useState, useEffect, useContext } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import backArrowIcon from "../../assets/images/back-arrow.svg";
import "./MyAccount.scss";
import LoaderInline from "shared/loader/LoaderInline";
import LoaderFull from "shared/loader/LoaderFull";
import toastify from "shared/toastr";
import { editUserAccount, getStates, getUserAccount } from "services/api/staff";
import UserContext from "store/user-context";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const EditProfile = (props) => {
  const userCtx = useContext(UserContext);
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const [states, setStates] = useState([]);
  const [isStatesLoading, setisStatesLoading] = useState(false);

  useEffect(() => {
    getDetail();
    getStatesForDD();
  }, []);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(50, ({ max }) => `Business name not more than ${max} characters`)
      .required("Please enter business name"),
    phone: Yup.string()
      .transform((value) => (value ? value.replace(/\D/g, "") : ""))
      .test("len", "Phone number must be exactly 10 characters long",
      (value) => value && value.length === 10)
      .required("Please enter the phone number"),
    email: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .email("Please enter a valid email address")
      .max(50)
      .required("Please enter a valid email address"),
    addressOne: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(250, ({ max }) => `Address line 1 not more than ${max} characters`)
      .required(),
    addressTwo: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(250, ({ max }) => `Address line 2 not more than ${max} characters`),
    city: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .trim()
      .max(50, ({ max }) => `City not more than ${max} characters`),
    stateId: Yup.number().default(0),
    zip: Yup.string()
      .transform((val) => (val ? val : undefined))
      .nullable()
      .test(
        "len",
        "Zip must be exactly 5 characters long",
        (value) =>
          value === "" ||
          value === undefined ||
          value === null ||
          (value && value.length === 5)
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const getStatesForDD = async () => {
    setisStatesLoading(true);
    await getStates()
      .then((res) => {
        let data = res.data;
        if (data && data.length) {
          let states = data.map((res) => ({ value: res.id, text: res.name }));
          setStates(states);
        }
        setisStatesLoading(false);
      })
      .catch(() => setisStatesLoading(false));
  };

  const getDetail = async () => {
    setisLoading(true);
    await getUserAccount()
      .then((res) => {
        let data = res.data;
        reset({
          fullName: data?.name,
          phone: data.contact,
          email: data?.email,
          addressOne: data.addressOne,
          addressTwo: data.addressTwo,
          city: data?.city,
          stateId: "" + data?.stateId,
          zip: data?.zip,
        });
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.push("/profile");
  };

  const onSubmit = async (data) => {
    setisSubmitting(true);
    await editUserAccount(data)
      .then((res) => {
        toastify("success", res.message);
        userCtx.setUserInfo({ username: data.fullName });
        setisSubmitting(false);
        onBack();
      })
      .catch(() => {
        setisSubmitting(false);
      });
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>

      <div className="users__wrapper">
        <div className="modifierDetails__wrapper modifierEdit__wrapper">
          {isLoading && <LoaderFull />}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="goBack">
                  <button
                    type="button"
                    onClick={onBack}
                    className=" back-btn-red"
                  >
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="main_heading">
                  <h1>Edit Account</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="details__wrapper editModifier__wrapper">
                  <div className="details__wrapper__inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="basic__info__content">
                        <div className="form-row">
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="fullname">
                              Full Name
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="fullname"
                              placeholder="e.g. David"
                              maxLength="50"
                              {...register("fullName")}
                              className={`form-control ${
                                errors.fullName ? "is-invalid" : ""
                              }`}
                            />
                            {errors.fullName?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.fullName?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="contactno">
                              Contact No
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="contactno"
                              placeholder="e.g. 234 567 8907"
                              {...register("phone")}
                              className={`form-control ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                            />
                            {errors.phone?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.phone?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="accounemail">
                              Account Email
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="accounemail"
                              placeholder="Enter your email address"
                              className="form-control"
                              {...register("email")}
                              disabled
                            />
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="addressline1">
                              Address Line 1<span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="addressline1"
                              placeholder="e.g. Address line"
                              maxLength="250"
                              {...register("addressOne")}
                              className={`form-control ${
                                errors.addressOne ? "is-invalid" : ""
                              }`}
                            />
                            {errors.addressOne?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.addressOne?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="addressline2">Address Line 2</label>
                            <input
                              type="text"
                              id="addressline2"
                              placeholder="e.g. Address line"
                              maxLength="250"
                              {...register("addressTwo")}
                              className={`form-control ${
                                errors.addressTwo ? "is-invalid" : ""
                              }`}
                            />
                            {errors.addressTwo?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.addressTwo?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-md-6 mb-3 pb-3 px-0 pr-md-3 pl-md-0">
                            <label htmlFor="city">City</label>
                            <input
                              type="text"
                              id="city"
                              placeholder="e.g. Address line"
                              maxLength="50"
                              {...register("city")}
                              className={`form-control ${
                                errors.city ? "is-invalid" : ""
                              }`}
                            />
                            {errors.city?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.city?.message}
                              </div>
                            )}
                          </div>

                          {/* TODO: Need to add react-select */}
                          <div className="col-12 col-md-6 mb-3 pb-3 px-0 pl-md-3 pr-md-0">
                            <label>State</label>
                            {states && states.length ? (
                              <select
                                className={`form-control ${
                                  errors?.stateId ? "is-invalid" : ""
                                }`}
                                {...register("stateId")}
                              >
                                <option value={0}>Select State...</option>
                                {states &&
                                  states.map((state) => (
                                    <option value={state?.value}>
                                      {state?.text}
                                    </option>
                                  ))}
                              </select>
                            ) : null}
                          </div>

                          <div className="col-md-12 mb-3 pb-lg-3 px-0">
                            <label htmlFor="zip">Zip</label>
                            <input
                              type="text"
                              id="zip"
                              placeholder="e.g. 123456"
                              maxLength="5"
                              {...register("zip")}
                              className={`form-control ${
                                errors.zip ? "is-invalid" : ""
                              }`}
                            />
                            {errors.zip?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.zip?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 px-0">
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={onBack}
                              className="cancel-btn red-btn-ghost btn"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="red-btn next-btn btn"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? <LoaderInline /> : "Save Account"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
