import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ChangeStatus from "../change-status/ChangeStatus";
import { useContext } from "react";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";
import dateFormat from "dateformat";

const ListingRow = (props) => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const staff = props.staff;

  const checkDropdownNeeded = () => {
    if (userRole === RoleType.ADMIN_STAFF) {
      if (staff?.roleName === RoleType.ADMIN) {
        return false;
      } else return true;
    } else return true;
  };

  return (
    <tr role="row" className="mat-row">
      <td className="mat-cell column-1" data-column="NAME">
        <Link to={`/staff/${staff?.userId}`} className="modifierDeatil__link">
          {staff?.name || "NA"}
        </Link>
      </td>
      <td className="mat-cell column-2" data-column="CONTACT">
        <Link to={`/staff/${staff?.userId}`} className="modifierDeatil__link">
          <div>{staff?.contact || "NA"}</div>
        </Link>
      </td>
      <td className="mat-cell column-3" data-column="TYPE">
        <Link to={`/staff/${staff?.userId}`} className="modifierDeatil__link">
          {staff?.roleName === RoleType.KITCHEN_STAFF
            ? "Restaurant Staff"
            : staff?.roleName || "NA"}
        </Link>
      </td>
      <td className="mat-cell column-4" data-column="CREATEDON">
        <Link to={`/staff/${staff?.userId}`} className="modifierDeatil__link d-inline-block w-100">
          <div className="row no-gutters">
            {staff?.createdOn ? (
              <div className="col-12">
                <p className="m-0">
                  {dateFormat(new Date(staff?.createdOn), "hh:MM TT")}
                </p>
                <p className="m-0">
                  {dateFormat(
                    new Date(staff?.createdOn + "Z"),
                    "mmm dd, yyyy"
                  )}
                </p>
              </div>
            ) : (
              <div className="col-12">
                <p className="m-0">
                  12:00 AM
                </p>
                <p className="m-0">
                  Jan 01, 2022
                </p>
              </div>
            )}
          </div>
        </Link>
      </td>
      <td className="mat-cell column-5" data-column="ACTION">
        <div className="table__actions ">
          <ChangeStatus
            userId={staff?.userId}
            status={!staff?.lockoutEnabled}
            needDropdown={checkDropdownNeeded()}
          />
        </div>
      </td>
    </tr>
  );
};

ListingRow.propTypes = {
  staff: PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
    contact: PropTypes.string,
    roleName: PropTypes.string,
    isActive: PropTypes.number,
  }).isRequired,
};

export default ListingRow;
