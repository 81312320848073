import React from "react";
import brandLogoImage from "assets/images/placeholder-logo.png";

const ImageSrc = ({
  orgImage,
  rawImage,
  isThumbnailRequired = true,
  thumbImage = brandLogoImage,
  ...rest
}) => {
  return (
    <>
      {orgImage || rawImage ? (
        <img
          src={orgImage}
          alt="asset"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = rawImage;
          }}
          {...rest}
        />
      ) : isThumbnailRequired ? (
        <img src={thumbImage} alt="asset" {...rest} />
      ) : null}
    </>
  );
};

export default ImageSrc;
