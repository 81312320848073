import { toast } from "react-toastify";

const toastrOptions = {
  containerId: "global",
  position: "top-center",
  autoClose: 5000,
  limit: 1,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: false,
  theme: "colored",
};

function toastify(toastType, message) {
  switch (toastType) {
    case "info":
      toast.info(message, toastrOptions);
      break;
    case "warn":
      toast.warn(message, toastrOptions);
      break;
    case "warning":
      toast.warning(message, toastrOptions);
      break;
    case "error":
      toast.error(message, toastrOptions);
      break;
    case "success":
      toast.success(message, toastrOptions);
      break;
    default:
      toast(message, toastrOptions);
      break;
  }
  toast.clearWaitingQueue();
}

export default toastify;
