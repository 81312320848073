import React, { useEffect, useState } from "react";
import "../../../modifier/listing/ModifierListing.scss";
import searchIcon from "assets/images/icon-search.svg";
import emptyIcon from "assets/images/emptyIcon.svg";
import { Global } from "constants/global";
import { getKitchenModswithModOptions } from "services/api/modifier";
import Paginator from "shared/paginator/Paginator";
import LoaderBlock from "shared/loader/LoaderBlock";
import RestaurantModifierRow from "./RestaurantModifierRow";
import toastify from "shared/toastr";
import noresultsIcon from "assets/images/noresult.svg";

const RestaurantModifierListing = (props) => {
  const pageSize = Global.pageSize;
  const [pageNum, setpageNum] = useState(1);
  const [searchValue, setsearchValue] = useState(null);
  const kitchen = props.kitchen;
  const kitchenId = kitchen?.id;

  const [allModifiers, setallModifiers] = useState(null);
  const [totalRecords, settotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (kitchenId) {
      getListing();
    }
  }, [kitchenId, pageNum, searchValue]);

  const getListing = async () => {
    let masks = {
      kitchenId: kitchenId,
      pageNum: pageNum,
      pageSize: pageSize,
      searchTerm: searchValue,
    };
    setisLoading(true);
    await getKitchenModswithModOptions(masks)
      .then((res) => {
        let data = res.data;
        setallModifiers(data?.modOptionsForKitchenData);
        settotalRecords(data?.totalRecordCount);
        setisLoading(false);
      })
      .catch((e) => {
        toastify("error", e.message);
        setallModifiers(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    setpageNum(page);
  };

  const onChangeSearch = (e) => {
    setsearchValue(e?.target?.value);
  };

  const onSearch = () => {
    setsearchValue(searchValue);
  };

  return (
    <>
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header modifiers_heading_padding">
            <div className="row">
              <div className="col-12 col-lg-6 mb-0 mb-md-3 mb-lg-0">
                <h2>Modifiers</h2>                
              </div>
              <div className="col-12 col-lg-6">
                <div
                  className="form flex-column flex-md-row"
                  id="manage__modifier__search__form"
                >
                  <div className="search__modifiers">
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="text"
                          className="custom-file form-control"
                          placeholder="Search modifier options"
                          value={searchValue}
                          onChange={onChangeSearch}
                        />
                        <button
                          type="button"
                          className="search-btn"
                          onClick={onSearch}
                        >
                          <span className="icons">
                            <img src={searchIcon} alt="plus icon" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {allModifiers && totalRecords === 0 && !isLoading ? (
            <div className="restaurantdetails__noListing">
              <div className="managermodifier__noListing__innerwrapper">
                <div className="content">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={
                        searchValue && searchValue !== ""
                          ? noresultsIcon
                          : emptyIcon
                      }
                      alt="no list icon"
                      width="185"
                    />
                  </div>
                  <div className="content-desc">
                    <h3>
                      {searchValue && searchValue !== ""
                        ? "No modifier(s) found"
                        : "No modifiers are added"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="managermodifier__listinPage">
              <div className="managermodifier__listinPage__wrapper">
                <table className="table modifierListing__table">
                  <thead>
                    <tr role="row" className="mat-header-row">
                      <th scope="col" className="mat-header-cell column_1">
                        Option
                      </th>
                      <th scope="col" className="mat-header-cell column_2">
                        Group
                      </th>
                      <th scope="col" className="mat-header-cell column-3">
                        Group Type
                      </th>
                      <th scope="col" className="mat-header-cell column-4">
                        Last Modified
                      </th>
                      <th scope="col" className="mat-header-cell column-5">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td>
                          <LoaderBlock />
                        </td>
                      </tr>
                    ) : (
                      allModifiers?.map((modifier, idx) => (
                        <RestaurantModifierRow
                          key={idx}
                          modifier={modifier}
                          kitchenId={kitchenId}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-12">
                    <Paginator
                      totalCount={totalRecords}
                      currentPage={pageNum}
                      pageSize={pageSize}
                      pageClick={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RestaurantModifierListing;