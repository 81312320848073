import React from "react";
import { Link } from "react-router-dom";
import "./ManageResturantsDetails.scss";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import SharedVirtualBrand from "./SharedVirtualBrand";
import AssociatedDevices from "./AssociatedDevices";

const ManageResturantsDetails = () => {
  return (
    <div className="modifierDetails__wrapper restaurantDetails__wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="goBack">
              <Link to="/manage-resturants" className=" back-btn-red">
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="main_heading">
              <h1>Restaurant Details</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="details__wrapper">
              <div className="details__wrapper__inner">
                <div className="left_content">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Business Name</h5>
                          <p>True’s Pizza</p>
                        </div>
                      </div>
                      <div className="mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Contact Name</h5>
                          <p>Matthew True</p>
                        </div>
                      </div>
                      <div className="mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Email Address</h5>
                          <p>brandon.well@houseofcurds.com</p>
                        </div>
                      </div>
                      <div className="mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Phone Number</h5>
                          <p>234 567 8907</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <h5>Business Address</h5>
                          <p>3045 Eagandale Place, Eagan, MN, USA</p>
                        </div>
                      </div>
                      <div className="mb-md-3 pb-md-4">
                        <div className="detailCard">
                          <div className="address-map"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Business Name</h5>
                        <p>True’s Pizza</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Business Address</h5>
                        <p>3045 Eagandale Place, Eagan, MN, USA</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Contact Name</h5>
                        <p>Matthew True</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Email Address</h5>
                        <p>brandon.well@houseofcurds.com</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Phone Number</h5>
                        <p>234 567 8907</p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="right_content if__pending d-none">
                  <span className="custom__badge badge__blue table__badge">
                    Request Pending
                  </span>
                  <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-button-drop-left"
                    ></button>
                    <div className="dropdown-menu">
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item active">
                          <span>Accept</span>
                        </Link>
                      </div>
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item">
                          <span>Reject</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_content if__active d-none">
                  <span className="custom__badge badge__red table__badge">
                    Active
                  </span>
                  <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-button-drop-left"
                    ></button>
                    <div className="dropdown-menu">
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item active">
                          <span>Set As Inactive </span>
                        </Link>
                      </div>
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item">
                          <span>Edit Restaurant</span>
                        </Link>
                      </div>
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item">
                          <span>Delete Restaurant</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_content if__rejected">
                  <span className="custom__badge badge__gray table__badge">
                    Rejected
                  </span>
                  <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-button-drop-left"
                    ></button>
                    <div className="dropdown-menu">
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item active">
                          <span>Delete Restaurant</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*begin:: new updates for shared VB */}
        <div className="row">
          <div className="col-12">
            <div className="shared__vb__listing__wrapper">
              <h2>Virtual Brands</h2>
              <SharedVirtualBrand />
            </div>
          </div>
        </div>
        {/*end:: new updates for shared VB */}

        {/*begin:: new updates for Associated Devices */}
        <div className="row">
          <div className="col-12">
            <div className="shared__vb__listing__wrapper associated__devices__listing__wrapper">
              <h2>Devices</h2>
              <AssociatedDevices />
            </div>
          </div>
        </div>
        {/*end:: new updates for Associated Devices */}
      </div>
    </div>
  );
};

export default ManageResturantsDetails;
