import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import "./ProgressBar.scss";

const Ranger = (props) => {
  const initialValue = props.value;
  const minDistance = props.minDistance;
  const maxDistance = props.maxDistance;
  const isDisabled = props.disabled;
  const label = props.label;
  const [sliderValue, setSliderValue] = useState(initialValue);

  const changeValue = (value) => setSliderValue(value);
  const handleChange = () => props.setRadius(sliderValue);

  useEffect(() => {
    if (initialValue) {
      setSliderValue(initialValue);
    }
  }, [initialValue]);

  return (
    <>
      <div className="deliveryRadiuscount">
        <span>{minDistance + " " + label}</span>
        <div className={"radius__value " + (isDisabled ? "rangeslider-disabled" : "")}>
          <span>{sliderValue + " " + label}</span>
        </div>
        <span>{maxDistance + " " + label}</span>
      </div>
      <div
        className={"progressBar " + (isDisabled ? "rangeslider-disabled" : "")}
      >
        <div
          className="slider"
          style={{ pointerEvents: isDisabled ? "none" : "all" }}
        >
          <Slider
            min={minDistance}
            max={maxDistance}
            format={(value) => value}
            tooltip={true}
            onChange={changeValue}
            onChangeComplete={handleChange}
            value={sliderValue}
          />
        </div>
      </div>
    </>
  );
};

Ranger.propType = {
  value: PropTypes.number.isRequired,
  setRadius: PropTypes.func.isRequired,
  minDistance: PropTypes.number.isRequired,
  maxDistance: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default Ranger;
