import React, { useContext } from "react";
import VBForm from "./VBForm";
import { Modal } from "react-bootstrap";
import VirtualBrandContext from "store/virtual-brand-context";

const VBModal = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const modalExtras = brandCtx.modalExtras;

  return (
    <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
      <Modal.Header closeButton>
        <Modal.Title id="addMenu__item__modal">
          <h1>
            {modalExtras && modalExtras?.isEditMode && "Edit"}
            {modalExtras && modalExtras?.isViewMode && "View"}
            {!modalExtras?.isViewMode && !modalExtras?.isEditMode
              ? "Add"
              : null}{" "}
           Virtual Brand Model
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VBForm />
      </Modal.Body>
    </Modal>
  );
};

export default VBModal;
