import React, { useEffect, useState } from "react";
import ProvidersEdit from "./ProvidersEdit";
import { deleteProvider } from "services/api/provider";
import toastify from "shared/toastr";

const ProvidersRow = (props) => {
  const provider = props.provider;
  const kitchenId = props?.kitchenId;
  const getListing = props.getListing;
  const dropdownProvidersList = props.dropdownProvidersList;

  const [status, setstatus] = useState(provider.isActive);
  const [isShowEditProvider, setIsShowEditProvider] = useState(false);

  const openModal = () => {
    setIsShowEditProvider(true);
  };
  const closeModal = () => {
    setIsShowEditProvider(false);
  };

  useEffect(() => {
    if (provider) {
      setstatus(provider?.isActive);
    }
  }, [provider]);

  const onDelete = async () => {
    await deleteProvider(kitchenId).then((res) => {
      getListing();
      toastify("success", res.message);
    });
  };

  return (
    <>
      <tr role="row" className="mat-row">
        <td className="mat-cell column__1" data-column="NAME">
          {provider?.name}
        </td>

        <td className="mat-cell column-5" data-column="ACTION">
          <div className="right_content">
            <span
              className={`custom__badge ${
                status ? "badge__red" : "badge__gray"
              }`}
            >
              {status ? "Active" : "Inactive"}
            </span>
            <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
              <button
                type="button"
                className="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="dropdown-button-drop-left"
              ></button>
              <div className="dropdown-menu">
                <div className="dropdown__innerWrapper">
                  <button onClick={openModal} className="dropdown-item">
                    <span>Edit</span>
                  </button>
                </div>
                <div className="dropdown__innerWrapper">
                  <button onClick={onDelete} className="dropdown-item">
                    <span>Delete</span>
                  </button>
                </div>
                {/* <div className="dropdown__innerWrapper">
                  <button onClick={onChangeStatus} className="dropdown-item">
                    <span>Set As {status ? "Inactive" : "Active"}</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </td>
      </tr>

      {isShowEditProvider && (
        <ProvidersEdit
          provider={provider}
          kitchenId={kitchenId}
          getListing={getListing}
          dropdownProvidersList={dropdownProvidersList}
          modalShow={isShowEditProvider}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default ProvidersRow;
