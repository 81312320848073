import React, { useState, useEffect } from "react";
import exportIcon from "assets/images/export-icon.svg";
import { createCsv } from "services/api/order";
import toastify from "shared/toastr";
import ExportCSVModal from "UI/Modal/ExportCSVModal";

const ExportCsv = (props) => {
  const {
    searchFilter,
    kitchenFilter,
    statusFilter,
    marketplaceFilter,
    dateFromFilter,
    dateToFilter,
    pageSize,
    totalRecords
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push({ label: i, value: i });
    }
    setPageNumbers(numbers);
  }, [pageSize, totalRecords]);

  const onSubmit = async () => {
    setIsLoading(true);
    await createCsv({
      pageNum,
      pageSize,
      searchFilter,
      kitchenFilter,
      statusFilter,
      marketplaceFilter,
      dateFromFilter,
      dateToFilter,
    })
    .then((res) => {
      let data = res.data;
      setIsLoading(false);
      if(data){
        window.open(data, '_self');
        toastify("success", "Success");
      }
    })
    .catch(() => {
      setIsLoading(false);
      toastify("error", "Export CSV Failed");
    });
  };

  const onCancel = () => setIsOpen(false);

  const getSubTitle = () => {
    return (
      <p>
        There are total of <span className="font__15__semibold">{totalRecords}{" "}</span>
        orders. Select the page number in order to export the csv with the
        specified data.
      </p>
    );
  };

  const onPageChange = (val) => {
    setPageNum(val);
  }

  return (
    <>
      <div className="export__wrapper">
        <button
          className="export-btn btn"
          onClick={(e) => setIsOpen(true)}
          style={{ width: "130px", whiteSpace: "nowrap" }}
        >
          <span>
            <img
              src={exportIcon}
              alt="export icon"
              className="img-fluid icons"
            />
          </span>
          <span>Export CSV</span>
        </button>
      </div>
      {isOpen && (
        <ExportCSVModal
          show={isOpen}
          title="Export CSV"
          subtitle={getSubTitle()}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onLoading={isLoading}
          options={pageNumbers}
          onSelect={onPageChange}
        />
      )}
    </>
  );
};

export default ExportCsv;
