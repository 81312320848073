import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import VirtualBrandContext from "store/virtual-brand-context";
import ItemForm from "../item-form/ItemForm";
import "./ItemModal.scss";

const ItemModal = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const modalExtras = brandCtx.modalExtras;

  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="addMenu__item__modal">
        <Modal.Header closeButton>
          <Modal.Title id="addMenu__item__modal">
            <h1>
              {modalExtras?.viewMode && "View"}
              {modalExtras?.editMode && "Edit"}
              {modalExtras?.cloneMode && "Clone"}
              {!modalExtras?.editMode &&
              !modalExtras?.cloneMode &&
              !modalExtras?.viewMode
                ? "Add New"
                : null}{" "}
              Item
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemForm />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ItemModal;
