import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import totalOrders from "assets/images/total_Orders.svg";
import totalRevenue from "assets/images/revenue.svg";
import avgValue from "assets/images/avg_Value.svg";
import bbc from "assets/images/bb_Commision.svg";
import totaltaxes from "assets/images/tax.svg";
import totalPercentage from "assets/images/percent.svg";
import errorCharges from "assets/images/error_Charges.svg";
import LoaderInline from "shared/loader/LoaderInline";
import KitchenFilter from "components/filters/KitchenFilter";
import toastify from "shared/toastr";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { sendTestOrder } from "services/api/order";
import { getServices } from "services/api/connection";
import { RoleType } from "constants/roleType";

const Widgets = (props) => {
  const userRole = props.userRole;
  const onSubmit = props.onSubmit;
  const data = props.data;
  const isLoading = props.isLoading;
  const isEditMode = props.isEditMode;
  const setisEditMode = props.setisEditMode;

  const serviceCards = [
    {
      title: "Auto Confirm Orders",
      status: data[0]?.isEnabled,
      keyName: "autoConfirmOrders",
      id: 1,
    },
    {
      title: "Print Two Receipts",
      status: data[1]?.isEnabled,
      keyName: "printTwoReceipts",
      id: 2,
    },
    {
      title: "Mute Notification Sound",
      status: data[2]?.isEnabled,
      keyName: "muteNotificationSound",
      id: 3,
    },
  ];

  const emailReportCards = [
    {
      title: "Restaurant Owner",
      status: data[3]?.isEnabled,
      keyName: "restaurantOwner",
      id: 4,
    },
    {
      title: "Restaurant Staff",
      status: data[4]?.isEnabled,
      keyName: "restaurantStaff",
      id: 5,
    },
    {
      title: "Brand Owner",
      status: data[5]?.isEnabled,
      keyName: "brandOwner",
      id: 6,
    },
    {
      title: "Affiliate User",
      status: data[6]?.isEnabled,
      keyName: "affiliateUser",
      id: 7,
    },
  ];

  const widgetCards = [
    {
      title1: "Total",
      title2: "Sales",
      status: data[7]?.isEnabled,
      img: totalRevenue,
      keyName: "totalRevenue",
      id: 8,
    },
    {
      title1: "Revenue",
      title2: "Share",
      status: data[8]?.isEnabled,
      img: totalRevenue,
      keyName: "revenueShare",
      id: 9,
    },
    {
      title1: "Backhouse Brands",
      title2: "Commission",
      status: data[9]?.isEnabled,
      img: bbc,
      keyName: "deliveryBHBCommission",
      id: 10,
    },
    {
      title1: "Total",
      title2: "Orders",
      status: data[10]?.isEnabled,
      img: totalOrders,
      keyName: "totalOrders",
      id: 11,
    },
    {
      title1: "Average Order",
      title2: "Value",
      status: data[11]?.isEnabled,
      img: avgValue,
      keyName: "averageValue",
      id: 12,
    },
    {
      title1: "Error",
      title2: "Charges",
      status: data[12]?.isEnabled,
      img: errorCharges,
      keyName: "errorCharges",
      id: 13,
    }
  ];
  
  const _onCheckWidgetCards = (e, card) => {
    card.status = e.target.checked;
    for (var i = 0; i <= data?.length; i++) {
      if (data[i]?.id === card?.id) {
        data[i].isEnabled = e.target.checked;
      }
    }
  };

  const validationSchema = Yup.object().shape({
    platformName: Yup.string()
    .test("isSelected", "Please select platform name", (value) => +value !== 0)
    .required("Please select platform name"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const [isTestOrderLoading, setIsTestOrderLoading] = useState();
  const [services, setServices] = useState([]);

  useEffect(() => {
    getDeliveryServices();
    reset({platformName: 0});
  }, []);

  const getDeliveryServices = () => {
    getServices().then((res) => {
      let data = res.data;
      if (data && data?.length) {
        let filtered = data.map((res) => ({ label: res.name, value: res.id }));
        setServices(filtered);
      }
    });
  };

  const onSendTestOrder = async ({platformName}) => {
    setIsTestOrderLoading(true);
    await sendTestOrder(platformName)
    .then((res) => {
      toastify("success", res?.message);
      setIsTestOrderLoading(false);
    })
    .catch(() => {
      toastify("error", "Failed to send the test order");
      setIsTestOrderLoading(false);
    });
    reset({platformName: 0});
  };

  return (
    <>
      <div className="summary__card__wrapper">
        <div className="edit-btn" style={{zIndex: 0}}>
          {!isEditMode && (
            <button
              type="button"
              onClick={() => setisEditMode(true)}
              className="red-btn-ghost btn"
            >
              Edit
            </button>
          )}
        </div>
        <div>
          <div className="row px-3">
            {serviceCards?.map((card, idx) => (
              <div key={idx} className="col-12 col-lg-3 pl-1 mb-2">
                <p className="side-label mb-2">{card.title}</p>
                {isEditMode ? (
                  <div>
                    <div className="switches right__switch mb-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            _onCheckWidgetCards(e, card)
                          }
                          defaultChecked={card.status}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                ) : (
                  <p className="label__status">{card.status ? "Yes" : "No"}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        <hr className="mt-2" style={{ marginBottom: "32px" }} />

        <p className="side-label pl-1 mt-4 mb-0">Email Reports By Role</p>
        <div className="row px-3" style={{ marginBottom: "32px" }}>
          {emailReportCards?.map((card, idx) => (
            <div
              className="col-12 col-md-6 col-lg-3 col-xl-2 pl-1 mt-3"
              key={idx}
            >
              {isEditMode ? (
                <div className="custom-checkbox-wrapper d-flex">
                  <label className="custom-checkbox-container">
                    {card.title}
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        _onCheckWidgetCards(e, card)
                      }
                      defaultChecked={card.status}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              ) : (
                <div>
                  <p className="side-label mb-2">{card.title}</p>
                  <p className="label__status mb-0">
                    {card.status ? "Yes" : "No"}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        <hr className="mt-0" style={{ marginBottom: "32px" }} />

        <p className="side-label pl-1">Dashboard Widgets</p>
        <div className="summary__card__wrapper__inner">
          {widgetCards?.map((card, idx) => (
            <div className="summary__card" key={idx}>
              <div className="summary__card__data">
                <div className="summary__card__data__left">
                  <p className="label">
                    {card.title1}
                    <br />
                    {card.title2}
                  </p>
                  {isEditMode ? (
                    <div>
                      <div className="switches right__switch mb-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              _onCheckWidgetCards(e, card)
                            }
                            defaultChecked={card.status}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <p className="card__status">{card.status ? "Yes" : "No"}</p>
                  )}
                </div>
                <div className="summary__card__data__right">
                  <div className="img-box">
                    <img src={card.img} alt="total orders" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {(userRole === RoleType.ADMIN || userRole === RoleType.ADMIN_STAFF) && (
          <>
            <hr style={{ margin: "32px 0" }} />

            <form onSubmit={handleSubmit(onSendTestOrder)}>
              <div className="search-filter pl-1">
                <p className="side-label mb-2">Test Order</p>
                <p className="side-label-sub">
                  Send yourself a test order to make sure everything is
                  functioning correctly.
                </p>
                <div className="row">
                  {/* <div className="col-12 col-md-6 col-lg-4">
                    <label className="side-label-sub" style={{ fontWeight: 600}}>Restaurant</label>
                    <div>
                      <KitchenFilter
                        value={selectedKitchenId}
                        onSelect={onKitchenSelect}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
                  <label className="side-label-sub" style={{ fontWeight: 600}}>Virtual Brand</label>
                    <div>
                      <KitchenFilter
                        value={selectedKitchenId}
                        onSelect={onKitchenSelect}
                      />
                    </div>
                  </div> */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <label className="side-label-sub" style={{ fontWeight: 600}} htmlFor="platformName">
                      Platform <span className="mendatory">*</span>
                    </label>
                    {services && services?.length && (
                      <>
                        <select
                          {...register("platformName")}
                          className={`form-control big-select ${
                            errors.platformName ? "is-invalid" : ""
                          }`}
                          defaultValue={0}
                        >
                          <option value={0}>Select platform type</option>
                          {services?.map((res, idx) => (
                              <option key={idx} value={res.label}>
                                {res.label}
                              </option>
                            ))}
                        </select>
                        {errors.platformName?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.platformName?.message}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="test-order-btn mt-4">
                  <button
                    type="submit"
                    className="red-btn btn"
                  >
                    {isTestOrderLoading ? (
                      <LoaderInline />
                    ) : (
                      <>Send Test Order</>
                    )}
                  </button>
                </div>
              </div>
            </form>

            <hr style={{ margin: "32px 0" }} />
          </>
        )}

        <div className="submit-btn mt-4">
          {isEditMode && (
            <button
              type="submit"
              onClick={() => {
                onSubmit(data);
                reset({platformName: 0})
              }}
              className="red-btn btn"
            >
              {isLoading ? <LoaderInline /> : <>Save</>}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

Widgets.propTypes = {
  data: PropTypes.array,
  userRole: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
  setisEditMode: PropTypes.func,
};

export default Widgets;
