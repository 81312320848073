import React, { useContext, useEffect, useState } from "react";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import { Global } from "constants/global";
import uploadFileToBlob from "services/azure-blob";
import { addBrandAsset, getBrandAsset } from "services/api/brand";
import LoaderInline from "shared/loader/LoaderInline";
import VirtualBrandContext from "store/virtual-brand-context";
import { AssetType } from "constants/assetType";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import LoaderFull from "shared/loader/LoaderFull";

const AssetUpload = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const virtualBrand = brandCtx.virtualBrandInfo;
  const brandId = brandCtx.virtualBrandId;
  const brandMode = brandCtx.virtualBrandMode;
  const logoSize = Global.fiveMB;
  const coverSize = Global.fiveMB;

  const [isLoading, setisLoading] = useState(false);
  const [logoImage, setlogoImage] = useState(null);
  const [coverImage, setcoverImage] = useState(null);
  const [rawLogoImage, setRawLogoImage] = useState(null);
  const [rawCoverImage, setRawCoverImage] = useState(null);

  const [isLogoUploading, setisLogoUploading] = useState(false);
  const [isCoverUploading, setisCoverUploading] = useState(false);
  const [isAssetsUploading, setisAssetsUploading] = useState(false);

  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);

  useEffect(() => {
    if (brandMode) {
      if (
        brandMode === VirtualBrandMode.SHARED ||
        brandMode === VirtualBrandMode.VIEW
      ) {
        setisFieldsDisabled(true);
      } else {
        setisFieldsDisabled(false);
      }
    }
  }, [brandMode]);

  useEffect(() => {
    if (brandId) getAssetsById();
  }, [brandId]);

  const getAssetsById = async () => {
    setisLoading(true);
    await getBrandAsset(brandId).then((res) => {
      let data = res.data;
      setisLoading(false);
      if (data) {
        setlogoImage({ filePath: data.logoURL });
        setcoverImage({ filePath: data.coverURL });
        setRawLogoImage(data.rawLogoURL);
        setRawCoverImage(data.rawCoverURL);
      }
    });
  };

  const skipStep = () => props.nextStep();
  const nextStep = () => props.nextStep();

  const uploadLogoImage = async (file) => {
    setisLogoUploading(true);
    await uploadFileToBlob(file, AssetType.LOGOIMAGE).then(
      (res) => {
        setlogoImage(res);
        setisLogoUploading(false);
      },
      () => {
        setlogoImage(null);
        setisLogoUploading(false);
      }
    );
  };

  const uploadCoverImage = async (file) => {
    setisCoverUploading(true);
    await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
      (res) => {
        setcoverImage(res);
        setisCoverUploading(false);
      },
      () => {
        setcoverImage(null);
        setisCoverUploading(false);
      }
    );
  };

  const onSubmit = async () => {
    if (
      brandMode === VirtualBrandMode.SHARED ||
      brandMode === VirtualBrandMode.VIEW
    ) {
      props.nextStep();
      return;
    }

    let model = {
      brandId,
      logoImage: logoImage?.fileName || logoImage?.filePath,
      coverImage: coverImage?.fileName || coverImage?.filePath,
    };
    setisAssetsUploading(true);
    await addBrandAsset(model)
      .then((res) => {
        let data = res.data;
        brandCtx.setVirtualBrandInfo({
          ...virtualBrand,
          coverURL: data.coverURL,
          logoURL: data.logoURL,
          rawCoverURL: data.rawCoverURL,
          rawLogoURL: data.rawLogoURL,
        });
        setisAssetsUploading(false);
        nextStep();
      })
      .catch((_) => {
        setisAssetsUploading(false);
      });
  };

  return (
    <>
      {isLoading ? <LoaderFull /> : null}{" "}
      <div className="uploadAssets__wrapper basic__info">
        <div className="main__heading">
          <h2>Upload Assets</h2>
          <p>Add logo and cover image for your brand.</p>
        </div>
        <div className="uploadAssets-wrapper">
          <div className="row">
            <div className="col-12 col-md-4 col-xl-3 mb-3 pb-2 mb-md-0 pb-md-0 mr-0 mr-md-4 mr-lg-0">
              <h3>Logo Image</h3>
              <AssetDropArea
                extraClass="logo-image-wrapper"
                dimensions="1200 X 640px"
                maxSize={logoSize}
                acceptedFormat="image/jpeg, image/png, image/svg+xml"
                showFormatText="JPEG, PNG, SVG"
                onFileChange={uploadLogoImage}
                isImageUploading={isLogoUploading}
                uploadedImage={logoImage?.filePath}
                uploadedRawImage={rawLogoImage}
                disabled={isFieldsDisabled}
                btnText={"Change Image"}
              />
            </div>
            <div className="col">
              <h3>Cover Image</h3>
              <AssetDropArea
                dimensions="2000 X 1200px"
                maxSize={coverSize}
                acceptedFormat="image/jpeg, image/png, image/svg+xml"
                showFormatText="JPEG, PNG, SVG"
                onFileChange={uploadCoverImage}
                isImageUploading={isCoverUploading}
                uploadedImage={coverImage?.filePath}
                uploadedRawImage={rawCoverImage}
                disabled={isFieldsDisabled}
                btnText={"Change Image"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="multiForm__actions">
        {brandMode === VirtualBrandMode.SHARED ||
        brandMode === VirtualBrandMode.VIEW ? null : (
          <button className="skip__link" type="button" onClick={skipStep}>
            Skip this Step
          </button>
        )}
        <button
          style={{ float: "right" }}
          className="red-btn"
          type="button"
          onClick={onSubmit}
          disabled={isAssetsUploading}
        >
          {isAssetsUploading ? (
            <LoaderInline />
          ) : (
            <>
              Continue
              <span className="icons-right">
                <img src={rightArrowIcon} alt="arrow icon" />
              </span>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default AssetUpload;
