import React from "react";
import { Link } from "react-router-dom";

import "./VbComplete.scss";
import thankyouIcon from "assets/images/thankyou-check.svg";

const VbComplete = (props) => {
  return (
    <div className="Thankyou__wrapper">
      <div className="container">
        <div className="Thankyou__wrapper__inner">
          <div className="thankyou__card">
            <div className="img-box">
              <img src={thankyouIcon} alt="thank you" />
            </div>
            <div className="content">
              <h1>Thank You!</h1>
              <p>{props?.heading}</p>
              <p>
                {props?.subHeading}
              </p>
              <Link to={props?.linkText} className="btn btn-red">
               {props?.buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VbComplete;
