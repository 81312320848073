import React, { useEffect, useState, useContext } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./RecipeGuideBasicInfo.scss";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import Ranger from "UI/Ranger/ProgressBar";
import {
    GetMenuItemsWithRecipeGuide,
    GetRecipeGuideBasicInfo,
    addRecipeGuideBasicInfo, editRecipeGuideBasicInfo
} from "services/api/recipeguide";
import LoaderInline from "shared/loader/LoaderInline";
import RecipeGuideContext from "store/recipe-guide-context";
import LoaderFull from "shared/loader/LoaderFull";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import { useParams } from "react-router";
import MenuItemFilter from "../listing/MenuItemFilter";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import uploadFileToBlob from "services/azure-blob";
import { Global } from "constants/global";
import { AssetType } from "constants/assetType";
import { Modal } from "react-bootstrap";
import PopUpModal from "UI/Modal/PopUpModal";
import toastify from "shared/toastr";

const RecipeGuideForm = (props) => {
    const minPrepTime = 5;
    const maxPrepTime = 90;
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingOnGet, setisLoadingOnGet] = useState(false);
    const [prepTimeValue, setprepTimeValue] = useState(minPrepTime);
    const recipeguideCtx = useContext(RecipeGuideContext);
    const menuItemId = recipeguideCtx.virtualBrandMenuItemId;
    const recipeGuideId = recipeguideCtx.recipeGuideId;
    const [isFieldsDisabled, setisFieldsDisabled] = useState(false);
    const [selectedMenuItemId, setselectedMenuItemId] = useState(null);
    const [menuItems, setmenuItems] = useState([]);
    const [menuItemImage, setmenuItemImage] = useState(null);
    const [selectedMenuItem, setselectedMenuItem] = useState(null);
    const modalExtras = recipeguideCtx.modalExtras;
    const coverSize = Global.fiveMB;
    const [isRecipeGuideImageUploading, setisRecipeGuideImageUploading] = useState(false);
    const { brandId } = useParams("brandId");
    const recipeGuideMode = recipeguideCtx.recipeGuideMode;
    const modalStatus = recipeguideCtx.recipeGuideModalStatus;
    const [vbName, setvbName] = useState(null);
    const [showPopUp, setshowPopUp] = useState(false);
    const [recipeGuideInfo, setRecipeGuideInfo] = useState(modalExtras?.data);
    
    useEffect(() => {
        if (brandId && modalStatus === true) {
            getRecipeGuideDetails();
            if ((recipeGuideId) && (!modalExtras?.data)) {
                getRecipeGuideBasicInfo();
            }
        }
        if (modalStatus === true && (modalExtras?.data && (modalExtras?.isEditMode || modalExtras?.isViewMode))) {
            bindDataToForm(modalExtras?.data);
        }
    }, [brandId, recipeGuideId]);

     useEffect(() => {
        if (recipeGuideMode) {
          if (
            recipeGuideMode === VirtualBrandMode.SHARED ||
            recipeGuideMode === VirtualBrandMode.VIEW
          ) {
            setisFieldsDisabled(true);
          } else {
            setisFieldsDisabled(false);
          }
        }
      }, [recipeGuideMode]);

    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .trim()
            .max(500, ({ max }) => `Recipe Description not more than ${max} characters`)
            .required("Please enter brand description"),
        preparationTime: Yup.number()
            .positive()
            .min(minPrepTime)
            .max(maxPrepTime)
            .required("Preparation time is required"),
    });

    const getRecipeGuideDetails = async () => {
        setisLoadingOnGet(true);
        await GetMenuItemsWithRecipeGuide(brandId)
            .then((res) => {
                const data = res?.data?.menuItemWithRecipeGuide;
                setvbName(res.data?.virtualBrandName);
                if (data && data.length) {
                    const menuItems = data.map((res) => ({
                        value: res.id,
                        label: res?.recipeGuideId && res?.recipeGuideId !== recipeGuideId ? res.itemName  + " - NA" : res.itemName,
                        imageUrl: res.coverURL,
                        recipeGuideId: res?.recipeGuideId
                    }));
                    setmenuItems(menuItems);
                } else setmenuItems([{ value: 0, label: "No MenuItems" }]);
                setisLoadingOnGet(false);
            })
            .catch(() => {
                setisLoadingOnGet(false);
            });
    };

    const getRecipeGuideBasicInfo = async () => {
        await GetRecipeGuideBasicInfo(brandId, 0, recipeGuideId)
            .then((res) => {
                setselectedMenuItem(res.data?.title);
                setselectedMenuItemId(res.data?.menuItemId);
                recipeguideCtx.setRecipeGuideInfo(res.data);
                setmenuItemImage(res.data?.imageUrl);
                bindDataToForm(res.data);
                setRecipeGuideInfo(res.data);
                setisLoadingOnGet(false);
            })
            .catch(() => {
                setisLoadingOnGet(false);
            });
    }

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            preparationTime: minPrepTime
        },
    });

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isValid, isDirty },
    } = methods;

    const onPrepTimeChange = (val) => {
        setValue("preparationTime", val, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        setprepTimeValue(val);
    };

    const onSubmit = async (data) => {
        if (
            recipeGuideMode === VirtualBrandMode.SHARED ||
            recipeGuideMode === VirtualBrandMode.VIEW
        ) {
            closeModal();
            return;
        } 
        var error = document.getElementById("error");
        if (!selectedMenuItemId) {
            error.textContent = "Please select Menu Item";
        }
        else {
            error.textContent = "";
            const model = {
                brandId: brandId,
                menuItemId: selectedMenuItemId,
                menuItem: selectedMenuItem,
                menuItemImage: menuItemImage,
                ...data
            }
            setisLoading(true);
            if ((recipeGuideId && modalExtras?.isEditMode) || !isDirty) {
                await editRecipeGuideBasicInfo(recipeGuideId, model)
                    .then((res) => {
                        toastify("success", res?.message);
                        closeModal();
                        recipeguideCtx.saveMenuItemId(selectedMenuItemId);
                        recipeguideCtx.saveRecipeGuideId(recipeGuideId);
                        recipeguideCtx.saveVBId(brandId);
                        setisLoading(false);
                    })
                    .catch(() => setisLoading(false));
            } else {
                await addRecipeGuideBasicInfo(model)
                    .then((res) => {
                        toastify("success", res?.message);
                        closeModal();
                        recipeguideCtx.saveMenuItemId(selectedMenuItemId);
                        recipeguideCtx.saveRecipeGuideId(res?.data);
                        recipeguideCtx.saveVBId(brandId);
                        setisLoading(false);
                    })
                    .catch(() => setisLoading(false));
            }
        }
    };

    const bindDataToForm = (data) => {
        reset({
            description: data.description,
            preparationTime: data.estimatedPrepTimeInMinutes,
            coverURL: data.imageUrl,
        });
        setprepTimeValue(data.estimatedPrepTimeInMinutes);
        setmenuItemImage(data.imageUrl);
        setselectedMenuItem(data?.title);
        setselectedMenuItemId(data?.menuItemId);
    };
    const onMenuItemSelect = (data) => {
        if (data) {
            if (data?.recipeGuideId && data?.recipeGuideId !== recipeGuideId) {
                setshowPopUp(true);
            }
            else{
            setselectedMenuItemId(data.value);
            setmenuItemImage(data.imageUrl);
            setselectedMenuItem(data.label);
            }
        }
    };

    const onCancel = () => setshowPopUp(false);

    const closeModal = () => {
        recipeguideCtx.recipeGuideModalStatusChange(false);
    };

    const uploadCoverImage = async (file) => {
        setisRecipeGuideImageUploading(true);
        await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
            (res) => {
                setValue("coverURL", res?.filePath || res?.fileName, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                });
                setmenuItemImage(res);
                setisRecipeGuideImageUploading(false);
            },
            () => {
                setmenuItemImage(null);
                setisRecipeGuideImageUploading(false);
            }
        );
    };

    return (
        <>
            {isLoadingOnGet && <LoaderFull />}
            {isLoadingOnGet ? null : (
                <>
                    <div className="createMenustabs">
                        <Tab.Container
                            id="addMenu__item__modal__Tabs"
                            defaultActiveKey="first"
                            mountOnEnter
                            unmountOnExit
                        >
                            <Row className="no-gutters">
                                <Col sm={12}>
                                    <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="first"
                                                active={true}
                                            /* onClick={() => onTabChange(1)} */
                                            >
                                                {modalExtras?.isEditMode && "Edit"}
                                                {!modalExtras?.isViewMode && !modalExtras?.isEditMode
                                                    ? "Add"
                                                    : null}{" "}
                                                Recipe Guide Details
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                            <Row className="no-gutters">
                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane
                                            eventKey="first"
                                            active={true}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            <div className="tab__mainHeading">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="createMenu__wrapper uploadAssets__wrapper basic__info__content CreateMenuModalContent__wrapper">
                                                        <div className="uploadAssets-wrapper">
                                                            <div className="row no-gutters">

                                                                <div className="col-md-12 mb-3 pb-3">
                                                                    <label htmlFor="name">
                                                                        Virtual Brand
                                                                    </label>
                                                                    <div className="view_form_input">{vbName || "NA"}</div>
                                                                       {/* <input
                                                                            type="text"
                                                                            id="name"
                                                                            placeholder="Virtual Brand Name"
                                                                            className={`form-control`}
                                                                            value={vbName || "Virtual Brand Name"}
                                                                            maxLength="120"
                                                                            disabled={true}
                                                                        /> */}
                                                                </div>

                                                                <div className="col-md-12 mb-3 pb-3">
                                                                    <label htmlFor="menuItem">
                                                                        Menu Item<span className="mendatory">*</span>
                                                                    </label>
                                                                    <MenuItemFilter
                                                                        value={selectedMenuItemId}
                                                                        onSelect={onMenuItemSelect}
                                                                        data={menuItems}
                                                                        selectedMenuItem={selectedMenuItem}
                                                                        disabled={isFieldsDisabled}
                                                                    />
                                                                    <span className="invalid-feedback d-block" id="error"></span>
                                                                </div>

                                                                <div className="col-md-12 mb-3 pb-3">
                                                                    <label htmlFor="brandDescription">
                                                                        Recipe Description<span className="mendatory">*</span>
                                                                    </label>
                                                                    {isFieldsDisabled ? <div className="view_form_input">{recipeGuideInfo?.description || "NA"}</div>
                                                                        : <textarea
                                                                            id="brandDescription"
                                                                            placeholder="Add Description"
                                                                            className={`form-control ${errors.name ? "is-invalid" : ""
                                                                                }`}
                                                                            {...register("description")}
                                                                            maxLength="500"
                                                                            rows="3"
                                                                            disabled={isFieldsDisabled}
                                                                        ></textarea>}
                                                                    {errors?.description?.message && (
                                                                        <div className="invalid-feedback d-block">
                                                                            {errors?.description?.message}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="col-12 mb-3 pb-3">
                                                                    <label htmlFor="coverURL">Menu Item Image</label>
                                                                    <AssetDropArea
                                                                        dimensions="2000 X 1200px"
                                                                        maxSize={coverSize}
                                                                        acceptedFormat="image/jpeg, image/png, image/svg+xml"
                                                                        showFormatText="JPEG, PNG, SVG"
                                                                        onFileChange={uploadCoverImage}
                                                                        isImageUploading={isRecipeGuideImageUploading}
                                                                        uploadedImage={menuItemImage}
                                                                        uploadedRawImage={menuItemImage}
                                                                        disabled={isFieldsDisabled}
                                                                        compType={"recipeGuideForm"}
                                                                        btnText={"Change Image"}
                                                                    />
                                                                </div>

                                                                <div className="col-md-12 mb-3 pb-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="preparationTime">
                                                                            Estimated Prep Time
                                                                            <span className="mendatory">*</span>
                                                                        </label>
                                                                        <Ranger
                                                                            value={prepTimeValue}
                                                                            setRadius={onPrepTimeChange}
                                                                            minDistance={minPrepTime}
                                                                            maxDistance={maxPrepTime}
                                                                            label="Min"
                                                                            disabled={isFieldsDisabled}
                                                                        />
                                                                        {errors?.preparationTime?.message && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors?.preparationTime?.message}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal.Footer>
                                                        {props.children}

                                                        <button
                                                            type="button"
                                                            onClick={closeModal}
                                                            className="cancel-btn red-btn-ghost btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="red-btn next-btn btn"
                                                            disabled={isLoading || !isValid}
                                                        >
                                                            {isLoading ? (
                                                                <LoaderInline />
                                                            ) : (
                                                                <>
                                                                    Save
                                                                    <span className="icons-right">
                                                                        <img src={rightArrowIcon} alt="arrow icon" />
                                                                    </span>
                                                                </>
                                                            )}
                                                        </button>
                                                    </Modal.Footer>
                                                </form>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </>
            )
            }
            {showPopUp && (
                <PopUpModal
                    show={showPopUp}
                    title="Recipe guide already exists"
                    subtitle={`Recipe guide already exists. Please Choose other Menu Item`}
                    onCancel={onCancel}
                    onSubmit={onCancel}
                    onLoading={false}
                />
            )}
        </>
    );
};

export default RecipeGuideForm;
