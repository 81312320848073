import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Terms.scss";
import landingLogo__white from "../../assets/images/landing-logo-white.svg";
import landingLogo__black from "../../assets/images/landing-logo-black.svg";
import redExport__icon from "../../assets/images/red_export_icon.svg";
import section1Image from "../../assets/images/how-it-works-1.png";
import sections3Image from "../../assets/images/how-it-works-3.png";
import section4Image from "../../assets/images/how-it-works-4.svg";
import section3Image from "../../assets/images/section-3-img1.png";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import { getUserDataFromToken } from "services/local-storage";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const Terms = () => {
  const isLoggedIn = getUserDataFromToken();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }
  return (
    <>
      <div className="landingPage__wrapper static__pages__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid white__logo"
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                {isLoggedIn ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-link signUp__link w-100 h-100 mx-auto"
                    role="button"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-link signIn__link"
                      role="button"
                    >
                      Login
                    </Link>
                    <Link
                      to="/kitchen/register"
                      className="btn btn-link signUp__link"
                      role="button"
                    >
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        <Subheader mobileExtraClass="staticPages_subheader">
          <div className="goBack mb-3">
            <Link to="/home" className="back-btn-red" title="Go to Dashboard">
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Back
            </Link>
          </div>
        </Subheader>
        <main>
          <div className="privacy__wrapper">
          <div className="container">
            <section className="page__title__section">
              <div className="row">
                <div className="col-12 d-none d-md-block">
                  <div className="goBack">
                    <Link to="/home" className=" back-btn-red">
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back to Home
                    </Link>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Terms and Conditions</h1>
                  </div>
                </div>
              </div>
            </section>
            <div className="terms__content__wrapper">
              <section className="sections pr__section">
                <h3>Acceptance of the Terms of Use</h3>
                <p>
                These terms and conditions, together with any documents they expressly incorporate by reference (collectively,  “Terms of Use”), are entered into by and between Backhouse Brands, together with its affiliated companies (collectively, “Company,” “we,” or “us”), and you personally, together with any entity that is accessing or using the Website at your direction or on your behalf (collectively, “You”).
                </p>
                <p>
                These Terms of Use govern all access to and use of backhouse.io, including any content, functionality, and services offered on or through backhouse.io as well as any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Website”), whether such access or use is as a guest or as a registered user.
                </p>
                <p>
                Please read these Terms of Use carefully before You start to use the Website. By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to You, You accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at backhouse.io/privacypolicy, incorporated herein by reference. If You do not want to agree to these Terms of Use or the Privacy Policy, You must not access or use the Website.
                </p>
                <p className="static_page_important">
                IMPORTANT: PLEASE REVIEW THE “DISPUTES” SECTION OF THESE TERMS OF USE CAREFULLY, AS IT AFFECTS YOUR LEGAL RIGHTS. THESE TERMS OF USE REQUIRE YOU TO RESOLVE ALL DISPUTES WITH US THROUGH BINDING INDIVIDUAL ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT YOU AND COMPANY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY. YOU FURTHER ACKNOWLEDGE AND AGREE THAT YOU ARE WAIVING YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING AGAINST COPMANY. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD, AND AGREE TO BE BOUND BY, ALL OF THESE TERMS OF USE, INCLUDING THE TERMS AND CONDITIONS OF THE “DISPUTES” SECTION OF THESE TERMS OF USE.
                </p>
                <p>
                This Website is offered and available to users who are 18 years of age or older and who reside in the United States. By using this Website, You represent and warrant that You are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If You are using this Website on behalf of an entity, You further represent and warrant that You are duly authorized by such entity to form a binding contract between it and the Company. If You do not meet all of these requirements, You must not access or use the Website.
                </p>
                <p className="static_page_important">
                YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM THIS WEBSITE IF YOU (A) DO NOT AGREE TO THESE TERMS OF USE, (B) ARE NOT THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT WITH COMPANY, OR (C) ARE PROHIBITED FROM ACCESSING OR USING THIS WEBSITE OR ANY OF THIS WEBSITE’S CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.
                </p>
              </section>
              <section className="sections pr__section">
                <h3>Changes to the Terms of Use</h3>
                <p>
                We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website.
                </p>
                  <p>
                  Your continued use of the Website following the posting of revised Terms of Use means that You accept and agree to the changes. You are expected to check this page each time You access this Website so that You are aware of any changes, as they are binding on You.
                  </p>
              </section>
              <section className="sections restrictions__section">
                <h3>Accessing the Website and Account Security</h3>
                <p>
                We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.
                </p>
                <p>
                You are responsible for both:
                </p>
                <ul>
                  <li>Making all arrangements necessary for You to have access to the Website.</li>
                  <li>Ensuring that all persons who access the Website through Your internet connection are aware of these Terms of Use and comply with them.</li>
                </ul>
                <div className="mt-3">
                  <p>To access the Website or some of the resources it offers, You may be asked to provide certain registration details or other information. It is a condition of Your use of the Website that all the information You provide on the Website is correct, current, and complete. You agree that all information You provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our Privacy Policy backhouse.io/privacypolicy, and You consent to all actions we take with respect to Your information consistent with our Privacy Policy.</p>
                  <p>
                  If You choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, You must treat such information as confidential, and You must not disclose it to any other person or entity. You also acknowledge that Your account is personal to You and agree not to provide any other person with access to this Website or portions of it using Your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of Your user name or password or any other breach of security. You also agree to ensure that You exit from Your account at the end of each session. You should use particular caution when accessing Your account from a public or shared computer so that others are not able to view or record Your password or other personal information.
                  </p>
                  <p>We have the right to disable any user name, password, or other identifier, whether chosen by You or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, You have violated any provision of these Terms of Use</p>
                </div>
              </section>
              <section className="sections restrictions__section">
                <h3>Intellectual Property Rights</h3>
                <p>The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
                <p>You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</p>
                <ul>
                  <li>Your computer may temporarily store copies of such materials in RAM incidental to Your accessing and viewing those materials.</li>
                  <li>You may store files that are automatically cached by Your Web browser for display enhancement purposes.</li>
                  <li>If we provide desktop, mobile, or other applications for download, You may download and use such applications, provided You agree to be bound by our end user license agreement for such applications.</li>
                  <li>If we provide social media features with certain content, You may take such actions as are enabled by such features.</li>
                </ul>
                <div className="mt-3">
                <p>You must not:</p>
                <ul>
                  <li>Modify copies of any materials from this site.</li>
                  <li>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
                  <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>
                </ul>
                </div>
                <div className="mt-3">
                  <p>If You print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, Your right to use the Website will stop immediately and You must, at our option, return or destroy any copies of the materials You have made. No right, title, or interest in or to the Website or any content on the Website is transferred to You, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</p>
                </div>
              </section>
              <section className="sections pr__section">
                <h3>Trademarks</h3>
                <p>
                The Company name, the terms Backhouse Brands and backhouse.io, the Company logos, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.
                </p>
              </section>
              <section className="sections restrictions__section">
                <h3>Prohibited Uses</h3>
                <p>
                You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:
                </p>
                <ul>
                  <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
                  <li>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with these Terms of Use.</li>
                  <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</li>
                  <li>
                  To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.
                  </li>
                </ul>
                <div className="mt-3">
                <p>Additionally, You agree not to:</p>
                <ul>
                  <li>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website.</li>
                  <li>Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>
                  <li>Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
                  <li>Use any device, software, or routine that interferes with the proper working of the Website.</li>
                  <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                  <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.</li>
                  <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                  <li>Otherwise attempt to interfere with the proper working of the Website.</li>
                </ul>
                </div>
              </section>
              <section className="sections restrictions__section">
                <h3>User Contributions</h3>
                <p>The Website may contain or provide links to message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, social media services, and other interactive features (collectively, “Interactive Services”) that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, “post”) content or materials (collectively, “User Contributions”) on or through the Website.</p>
                <p>All User Contributions must comply with the Content Standards set out in these Terms of Use. Any User Contribution You post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Website, You grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose.</p>
                <p>You represent and warrant that:</p>
                <ul>
                  <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.</li>
                  <li>All of Your User Contributions do and will comply with these Terms of Use.</li>
                </ul>
                <div className="mt-3">
                  <p>You understand and acknowledge that You are responsible for any User Contributions You submit or contribute, and You, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</p>
                  <p>We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by You or any other user of the Website.</p>
                </div>
              </section>
              <section className="sections restrictions__section">
                <h3>Monitoring and Enforcement; Termination</h3>
                <p>We have the right to:</p>
                <ul>
                  <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
                  <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public, or could create liability for the Company.</li>
                  <li>Disclose Your identity or other information about You to any third party who claims that material posted by You violates their rights, including their intellectual property rights or their right to privacy.</li>
                  <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website.</li>
                  <li>Terminate or suspend Your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</li>
                </ul>
                <div className="mt-3">
                  <p>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. <span className="static_page_important">YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span></p>
                  <p>However, we cannot review all material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
                </div>
              </section>
              <section className="sections restrictions__section">
                <h3>Content Standards</h3>
                <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
                <ul>
                  <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                  <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
                  <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
                  <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use, including our Privacy Policy located at backhouse.io/privacypolicy.</li>
                  <li>Be likely to deceive any person.</li>
                  <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                  <li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
                  <li>Impersonate any person, or misrepresent Your identity or affiliation with any person or organization.</li>
                  <li>Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.</li>
                  <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
                </ul>
              </section>
              <section className="sections pr__section">
                <h3>Copyright Infringement</h3>
                <p>If You believe that any User Contributions violate Your copyright, please send us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.</p>
              </section>
              <section className="sections pr__section">
                <h3>Reliance on Information Posted</h3>
                <p>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance You place on such information is strictly at Your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by You or any other visitor to the Website, or by anyone who may be informed of any of its contents.</p>
                <p>
                This Website may include content provided by third parties, including materials provided by our service partners as well as other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to You or any third party, for the content or accuracy of any materials provided by any third parties.
                </p>
              </section>
              <section className="sections pr__section">
                <h3>Changes to the Website</h3>
                <p>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>
              </section>
              <section className="sections pr__section">
                <h3>Information About You and Your Visits to the Website</h3>
                <p>All information we collect on this Website is subject to our Privacy Policy, located at backhouse.io/privacypolicy. By using the Website, You consent to all actions taken by us with respect to Your information in compliance with the Privacy Policy.</p>
              </section>
              <section className="sections pr__section">
                <h3>Online Purchases and Other Terms and Conditions</h3>
                <p>All purchases through our site or other transactions for the sale of goods or services formed through the Website, or resulting from visits made by You (in all cases, an “order”), are governed by these Terms of Use.</p>
                <p>You agree that any order constitute offers to buy, under these Terms of Use, all products and services listed in such order. All orders must be accepted by us or we will not be obligated to sell the products or services to You. We may Choose not to accept any orders in our sole discretion. After having received Your order, we will send You a confirmation email with Your order number and details of the items You have ordered. Acceptance of Your order and the formation of the contract of sale between Company and You will not take place unless and until You have received Your order confirmation email.</p>
                <p>All prices posted on the Website are subject to change without notice. The price charged for a product or service will be the price in effect at the time the order is placed and will be set out in Your order confirmation email. Price increases will only apply to orders placed after such changes. Posted prices do not include taxes or charges for shipping and handling. All such taxes and charges will be added to Your purchase total and will be itemized in Your shopping cart and in Your order confirmation email. We are not responsible for pricing, typographical, or other errors in any offer by us and we reserve the right to cancel any orders arising from such errors.</p>
                <p>Terms of payment are within our sole discretion and, unless otherwise agreed by us in writing, payment must be received by us before our acceptance of an order. You represent and warrant that (i) any credit card information You supply to us is true, correct, and complete, (ii) You are duly authorized to use such credit card for the purchase, (iii) charges incurred by You will be honored by Your credit card company, and (iv) You will pay charges incurred by You at the posted prices, including all applicable taxes, if any.</p>
                <p>Additional terms and conditions may also apply to specific portions, services, or features of the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use.</p>
              </section>
              <section className="sections restrictions__section">
                <h3>Linking to the Website and Social Media Features</h3>
                <p>You may link to our homepage, provided You do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.</p>
                <p>This Website may provide certain social media features that enable You to:</p>
                <ul>
                  <li>Link from Your own or certain third-party websites to certain content on this Website.</li>
                  <li>Send emails or other communications with certain content, or links to certain content, on this Website.</li>
                  <li>Cause limited portions of content on this Website to be displayed or appear to be displayed on Your own or certain third-party websites.</li>
                </ul>
                <div className="mt-3">
                  <p>You may use these features solely as they are provided by us and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, You must not:</p>
                  <ul>
                    <li>Establish a link from any website that is not owned by You.</li>
                    <li>Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</li>
                    <li>Link to any part of the Website other than the homepage.</li>
                    <li>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <p>The website from which You are linking, or on which You make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.</p>
                  <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.</p>
                  <p>We may disable all or any social media features and any links at any time without notice in our discretion.</p>
                </div>
              </section>
              <section className="sections pr__section">
                <h3>Links from the Website</h3>
                <p>If the Website contains links to other sites and resources provided by third parties, these links are provided for Your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from Your use of them. If You decide to access any of the third-party websites linked to this Website, You do so entirely at Your own risk and subject to the terms and conditions of use for such websites.</p>
              </section>
              <section className="sections pr__section">
                <h3>Geographic Restrictions</h3>
                <p>The owner of the Website is based in the State of Minnesota in the United States. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If You access the Website from outside the United States, You do so on Your own initiative and are responsible for compliance with local laws.</p>
              </section>
              <section className="sections pr__section">
                <h3>Disclaimer of Warranties</h3>
                <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.</p>
                <p className="static_page_important">TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
                <p className="static_page_important">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                <p className="static_page_important">TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
                <p className="static_page_important">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
              </section>
              <section className="sections pr__section">
                <h3>Limitation on Liability</h3>
                <p className="static_page_important">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>
                <p>The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct.</p>
                <p className="static_page_important">THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
              </section>
              <section className="sections pr__section">
                <h3>Indemnification</h3>
                <p>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to Your violation of these Terms of Use or Your use of the Website, including, but not limited to, Your User Contributions, any use of the Website’s content, services, and products other than as expressly authorized in these Terms of Use, or Your use of any information obtained from the Website.</p>
              </section>
              <section className="sections pr__section">
                <h3>Governing Law</h3>
                <p>All matters relating to the Website, these Terms of Use, any goods or services provided by Company, and any dispute, claim, disagreement, or other matter arising from or relating to any of the foregoing (in each case, including non-contractual disputes or claims, a “Claim”), shall be governed by and construed in accordance with the internal laws of the State of Minnesota without giving effect to any choice or conflict of law provision or rule (whether of the State of Minnesota or any other jurisdiction). </p>
              </section>
              <section className="sections pr__section">
                <h3>Disputes</h3>
                <p className="static_page_important">BY AGREEING TO THESE TERMS OF USE, YOU ARE AGREEING THAT YOU AND COMPANY ARE EACH GIVING UP THE RIGHT TO A JURY TRIAL OR TO FILE A LAWSUIT IN COURT AGAINST THE OTHER. YOU FURTHER ACKNOWLEDGE AND AGREE THAT YOU AND COMPANY ARE EACH GIVING UP THE RIGHT TO BRING OR PARTICPATE IN A CLASS OR COLLECTIVE ACTION AGAINST THE OTHER, WHETHER IN COURT OR IN ARBITRATION.</p>
                <p>You and Company agree to settle all Claims by binding individual arbitration between You and Company, and not in a court of law. You acknowledge and agree that You and Company are each waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Unless both You and Company otherwise agree in writing, any arbitration will be conducted only on an individual basis and not in a class, collective, consolidated, or representative proceeding. However, You and Company each retain the right to bring an individual action in small claims court. You and Company also each retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of copyrights, trademarks, trade secrets, patents, or other intellectual property rights.</p>
                <p>The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the AAA’s Consumer Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this Arbitration Agreement. As of the most recent Modified Date of these Terms of Use, the AAA Rules were available at https://www.adr.org/Rules or by calling the AAA at 1-800-778-7879.</p>
                <p>You and Company agree that the arbitrator (“Arbitrator”), and not any federal, state, or local court or agency, shall have exclusive authority to resolve any disputes relating to the interpretation, applicability, enforceability or formation of this Dispute section of these Terms of Use, including any claim that all or any part of this Dispute section is void or voidable. The Arbitrator shall also be responsible for determining all threshold arbitrability issues, including issues relating to whether the Terms of Use are unconscionable or illusory and any defense to arbitration, including waiver, delay, laches, or estoppel.</p>
                <p>Notwithstanding any choice of law or other provision in these Terms of Use, You and Company agree and acknowledge that this Dispute section relates to interstate commerce and that the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (“FAA”), will govern its interpretation and enforcement and proceedings pursuant thereto. It is the intent of the parties that the FAA and AAA Rules shall preempt all state laws to the fullest extent permitted by law. If the FAA and AAA Rules are found to not apply to any issue that arises under this Dispute section or the enforcement thereof, then that issue shall be resolved under the laws of the State of Minnesota.</p>
                <p>A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a form Demand for Arbitration - Consumer Arbitration Rules at www.adr.org or by calling the AAA at 1-800-778-7879). The Arbitrator will be either (1) a retired judge or (2) an attorney specifically licensed to practice law in the state where the arbitration occurs and will be selected by the parties from the AAA’s roster of consumer dispute arbitrators. If the parties are unable to agree upon an Arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the Arbitrator in accordance with the AAA Rules.</p>
                <p>Unless You and Company otherwise agree, the arbitration will be conducted in the county where You reside. If Your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents You and Company submit to the Arbitrator, unless You request a hearing or the Arbitrator determines that a hearing is necessary. If Your claim exceeds $10,000, Your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the Arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration. You understand and agree that these exchanges of information as well as certain other rights, such as the discovery and appeal rights normally available during litigation in a court of law, will be different and more limited in arbitration than they would be in a court of law.</p>
                <p>The Arbitrator will render an award within the time frame specified in the AAA Rules. Judgment on the arbitration award may be entered in any court having competent jurisdiction to do so. The Arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. An Arbitrator’s decision shall be final and binding on all parties. An Arbitrator’s decision and judgment thereon shall have no precedential or collateral estoppel effect.</p>
                <p>Your responsibility to pay any AAA filing, administrative, and arbitrator fees will be solely as set forth in the AAA Rules. However, if Your claim for damages does not exceed $75,000, and if You anticipate financial hardship in bringing Your Claim as a result of such fees, You may submit a written request explaining your circumstances to Company by email to support@backhouse.io or by mail to 26 32nd Ave S, St. Cloud, MN 56301, and Company may in its sole discretion elect to pay all such fees on your behalf, unless the Arbitrator finds that either the substance of Your claim or the relief sought in Your Demand for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)).</p>
                <p>If Company changes this Dispute section after the date You first agreed to the Terms of Use (or to any subsequent changes to the Terms of Use), You may reject any such change by providing Company with written notice of such rejection within 30 days of the date such change became effective, as indicated in the “Last Modified” date above. This written notice must be provided by mail to 26 32nd Ave S, St. Cloud, MN 56301 or by email to support@backhouse.io. In order to be effective, the notice must include Your full name and clearly indicate Your intent to reject changes to this Dispute section of these Terms of Use. By rejecting changes, You are agreeing that You will arbitrate any dispute between You and Company in accordance with the provisions of this Dispute section as of the date You first agreed to the Terms of Use (or to any subsequent changes to the Terms of Use).</p>
                <p>If any portion of the Dispute section of these Terms of Use is found to be unenforceable or unlawful for any reason, (1) the unenforceable or unlawful provision shall be severed from these Terms of Use; (2) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of the this Dispute section of these Terms of Use or the parties’ ability to compel arbitration of any remaining claims on an individual basis pursuant to this Dispute section of these Terms of Use; and (3) to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in arbitration.</p>
              </section>
              <section className="sections pr__section">
                <h3>Limitation on Time to File Claims</h3>
                <p className="static_page_important">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
              </section>
              <section className="sections pr__section">
                <h3>Waiver and Severability</h3>
                <p>No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
                <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
              </section>
              <section className="sections pr__section">
                <h3>Entire Agreement</h3>
                <p>The Terms of Use constitute the sole and entire agreement between You and Company regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.</p>
              </section>
              <section className="sections pr__section">
                <h3>Your Comments and Concerns</h3>
                <p>
                This website is operated by:
                </p>
                <div className="privacy__address">
                  <p>Backhouse Brands <br/> 26 32nd Ave S <br/> St Cloud, MN 56301</p>
                  <p><a href="mailto:support@backhouse.io">Email: support@backhouse.io</a></p>
                  <p><a href="tel:(800) 599-5770">Phone: (800) 599-5770</a></p>
                </div>
                <p>All notices, feedback, comments, requests for technical support, and other communications relating to the Website should be directed to the address, email, or phone number listed above, as appropriate.</p>
              </section>
            </div>
          </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Terms;
