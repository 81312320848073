import React, { useContext, useEffect, useState } from "react";
import "./VirtualBrandInfo.scss";
import brandLogoImage from "assets/images/placeholder-logo.png";
import { getVirtualBrand } from "services/api/brand";
import LoaderBlock from "shared/loader/LoaderBlock";
import VirtualBrandContext from "store/virtual-brand-context";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import EditButton from "UI/Button/EditButton";
import VBModal from "../../basic-form/VBModal";
import attachmentsIcon from "assets/images/attachments-icon.svg";
import { Link } from "react-router-dom";

const VirtualBrandInfo = () => {
  const brandCtx = useContext(VirtualBrandContext);
  const brandId = brandCtx.virtualBrandId;

  const [brandInfo, setbrandInfo] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const editable = brandCtx.isConfirmPage;
  const brandMode = brandCtx.virtualBrandMode;
  const modalStatus = brandCtx.vbModalStatus;

  useEffect(() => {
    if (brandId) getDetails();
  }, []);

  useEffect(() => {
    if (modalStatus === false) getDetails();
  }, [modalStatus]);

  const getDetails = async () => {
    setisLoading(true);
    await getVirtualBrand(brandId)
      .then((res) => {
        setbrandInfo(res.data);
        brandCtx.setVirtualBrandName(res.data.name);
        brandCtx.setVirtualBrandInfo(res.data);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const onView = () => {
    brandCtx.vbModalStatusChange(true, {
      isEditMode: false,
      isViewMode: true,
      data: brandInfo,
    });
  };

  const onEdit = () => {
    brandCtx.vbModalStatusChange(true, {
      isEditMode: true,
      isViewMode: false,
      data: brandInfo,
    });
  };

  const closeModal = () => {
    brandCtx.vbModalStatusChange(false);
  };

  const onViewRecipeGuides = () => {
    //recipeguideCtx.resetAllVariables();
    //recipeguideCtx.saveVirtualBrandInfo(brand);
    const link = `/recipeguide/list/${brandId}`;
    return link;
  };

  return (
    <div className="createMenuBanner__wrapper">
      <div
        className="bg"
        style={{
          backgroundImage: `url(${brandInfo?.rawCoverURL
            ? brandInfo?.rawCoverURL
            : brandInfo?.coverURL
              ? brandInfo?.coverURL
              : "assets/images/placeholder-cover.png"})`
        }}
      >

        {![VirtualBrandMode.ADD].includes(VirtualBrandMode) && (
          <Link to={onViewRecipeGuides()}>
            <button type="button" className="attachments-btn recipeguides-btn">
              <img src={attachmentsIcon} alt="attachments" />{"View Recipe Guides"}
            </button>
          </Link>
        )}

      <div className="content">
        <div className="logo">
          <div
            className="logo-wrapper"
            style={{
              backgroundImage: `url(${isLoading
                ? null
                : brandInfo?.rawLogoURL
                  ? brandInfo?.rawLogoURL
                  : brandLogoImage})`
            }}
          >
            {isLoading ? <LoaderBlock isTextNeeded={false} /> : null}
          </div>
        </div>
      </div>
    </div>
    <div className="brand__details">
        <div className="left__side">
          <div className="brand__name">
            <h5 className="text-uppercase">Brand Name</h5>
            <p>{brandInfo?.name}</p>
          </div>
          <div className="brand__desc">
            <h5 className="d-flex text-uppercase">Brand Description
              {editable ? (
                brandMode === VirtualBrandMode.SHARED ||
                  brandMode === VirtualBrandMode.VIEW ? (
                  <ViewButton onClick={() => onView()} />
                ) : (
                  <EditButton onClick={() => onEdit()} />
                )
              ) : null}
            </h5>
            <p>{brandInfo?.description}</p>
            <VBModal
              show={modalStatus}
              className="right"
              onHide={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualBrandInfo;
