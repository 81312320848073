import React, { useContext, useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import AddButton from "UI/Button/AddButton";
import AddDropdownButton from "UI/Button/AddDropdownButton";
import { getRecipesByRecipeGuideId } from "services/api/recipeguide";
import NoRecipeCard from "./NoRecipeCard";
import ShimmerLoader from "UI/Shimmer/ShimmerLoader";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import RecipeModal from "../recipeguide-modal/RecipeModal";
import MainRecipe from "./MainRecipe";
import RecipeGuideContext from "store/recipe-guide-context";
import { getRecipeByRecipeId } from "services/api/recipeguide";
import SelectRecipePopUp from "../recipeguide-form/SelectRecipePopUp";

const CreateRecipesTabs = (props) => {
  const recipeguideCtx = useContext(RecipeGuideContext);
  const recipeGuideId = recipeguideCtx.recipeGuideId;
  const modalStatus = recipeguideCtx.recipeModalStatus;
  const selectRecipeModalStatus = recipeguideCtx.selectRecipeModalStatus;
  const [activeTab, setactiveTab] = useState(0);
  const recipe = props?.recipe;
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const recipeGuideMode = recipeguideCtx.recipeGuideMode;
  const recipeId = props?.recipeId;

  useEffect(() => {
    if (recipeGuideId) getRecipes();
  }, [recipeGuideId]);

  useEffect(() => {
    let isMounted = true;
    if (recipeId) {
      const getRecipeById = async () => {
        setIsLoading(true);
        await getRecipeByRecipeId(recipeId)
          .then((res) => {
            if (isMounted) {
            setIsLoading(false);
            const data = res?.data;
            setRecipes([data]);
            recipeguideCtx.saveRecipeId(data.id);
              
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setRecipes([]);
          });
      };
      getRecipeById();
    } 
     return () => {
       isMounted = false;
     };
  }, [recipeId]);

  useEffect(() => {
    if (modalStatus === false) {
      if(!recipeId)
      getRecipes();
      else
      getRecipeById();
    }
  }, [modalStatus]);

  const showModal = () => {
    recipeguideCtx.recipeModalStatusChange(true);
  };

  const showRecipeModal = () =>{
    recipeguideCtx.selectRecipeModalStatusChange(true);
  }

  const closeModal = () => {
    recipeguideCtx.recipeModalStatusChange(false);
  };

  const closeRecipeModal = () => {
    recipeguideCtx.selectRecipeModalStatusChange(false);
  };

  const getRecipeById = async () => {
    setIsLoading(true);
    await getRecipeByRecipeId(recipeId)
      .then((res) => {
        setIsLoading(false)
          const data = res?.data;
          setRecipes([data]);
          recipeguideCtx.saveRecipeId(data.id);
      })
      .catch((err) => {
        setIsLoading(false);
        setRecipes([]);
      });
  };

  const getRecipes = async () => {
    const recipeId = recipeguideCtx.recipeId;
    const isrecipeAdded = recipeguideCtx.isRecipeAdded;
    setIsLoading(true);
    await getRecipesByRecipeGuideId(recipeGuideId)
      .then((res) => {
        setIsLoading(false);
        setRecipes(res.data);
        if (res.data && res.data?.length) {
          if (recipeId && !isrecipeAdded) {
            const recipeData = res.data.filter((item) => item.id === recipeId)
            const index = res.data.findIndex((item) => item.id === recipeId)
            index && recipeData.length ? changeStep(index, recipeData) :  changeStep(0, res.data[0]);
          }
          else if(isrecipeAdded === true){
            changeStep(res.data.length-1, res.data[res.data.length-1]);
          }
          else {
            changeStep(0, res.data[0]);
          }
        } 
      })
      .catch(() => {
        setIsLoading(false);
        setRecipes(null);
      });
  };

  const changeStep = (tabIndex, recipe) => {
    setactiveTab(tabIndex);
    recipeguideCtx.saveRecipeId(recipe.id);
  };
  return (
    <>
      {isLoading ? (
        <>
          <ShimmerLoader />
        </>
      ) : (
        <div className="createMenuTbs__wrapper">
          <Tab.Container
            mountOnEnter
            unmountOnExit
            id="myTab"
            activeKey={`tab-${activeTab}`}
          >
            {!recipeId && (
              <div className="flex-container">
                <Nav className="virtualBrandsTabs nav-tabs">
                  {recipes && recipes.length
                    ? recipes.map((recipe, idx) => (
                        <Nav.Item key={idx}>
                          <Nav.Link
                            eventKey={`tab-${idx}`}
                            onClick={() => changeStep(idx, recipe)}
                          >
                            {recipe?.title}
                          </Nav.Link>
                        </Nav.Item>
                      ))
                    : null}
                </Nav>
                <div className="addMenubuttonwrapper no__wrap mb-2">
                  {recipeGuideMode === VirtualBrandMode.SHARED ||
                  recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                    /*  <AddButton title={"Add New Recipe"} openModal={showModal} /> */
                    <AddDropdownButton
                      title={"Add New Recipe"}
                      openModal={showModal}
                      openRecipeModal={showRecipeModal}
                    />
                  )}
                  <RecipeModal
                    show={modalStatus}
                    className="right"
                    onHide={closeModal}
                  />
                  <SelectRecipePopUp
                    show={selectRecipeModalStatus}
                    className="right"
                    onHide={closeRecipeModal}
                    data={recipes}
                  />
                </div>
              </div>
            )}
            <Tab.Content>
              {recipes && recipes.length ? (
                recipes.map((recipe, idx) => (
                  <Tab.Pane
                    key={idx}
                    mountOnEnter
                    unmountOnExit
                    eventKey={`tab-${idx}`}
                  >
                    <MainRecipe
                      recipe={recipe}
                      onReload={getRecipes}
                      isActive={recipe?.isActive}
                    >
                      {recipeGuideMode === VirtualBrandMode.SHARED ||
                      recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                        <AddDropdownButton
                          title={"Add New Recipe"}
                          openModal={showModal}
                          openRecipeModal={showRecipeModal}
                        />
                      )}
                      <RecipeModal
                        show={modalStatus}
                        className="right"
                        onHide={closeModal}
                      />
                    </MainRecipe>
                  </Tab.Pane>
                ))
              ) : (
                <>
                  <NoRecipeCard />
                  <div className="addmenu-mobile d-lg-none">
                    {recipeGuideMode === VirtualBrandMode.SHARED ||
                    recipeGuideMode === VirtualBrandMode.VIEW ? null : (
                      <AddDropdownButton
                        title={"Add New Recipe"}
                        openModal={showModal}
                        openRecipeModal={showRecipeModal}
                      />
                    )}
                  </div>
                </>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </>
  );
}

export default CreateRecipesTabs;
