import React, { useContext, useEffect, useState } from "react";

import { Tab, Nav, Col, Row } from "react-bootstrap";
import {
  addMenuCategory,
  deleteMenuCategory,
  editMenuCategory,
} from "services/api/menu";
import toastify from "shared/toastr";

import VirtualBrandContext from "store/virtual-brand-context";
import DeleteModal from "UI/Modal/DeleteModal";
import CategoryAvailability from "./CategoryAvailability";
import CategoryBasicInfo from "./CategoryBasicInfo";

const CategoryForm = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const menuId = brandCtx.virtualBrandMenuId;

  const [basicData, setbasicData] = useState(null);
  const [availData, setAvailData] = useState(null);
  let availabilityData = null;

  const [isLoading, setisLoading] = useState(false);
  const [activeStep, setactiveStep] = useState(1);

  const modalStatus = brandCtx.menuCategoryModalStatus;
  const modalExtras = brandCtx.modalExtras;

  const [initialData, setinitialData] = useState(null);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const [isFieldsDisabled, setisFieldsDisabled] = useState(false);

  useEffect(() => {
    if (modalStatus === true) {
      if (modalExtras && (modalExtras?.editMode || modalExtras?.viewMode)) {
        setinitialData(modalExtras.data);
        setbasicData(modalExtras.data);
        setAvailData(modalExtras?.data?.menuCategoryAvailability);
        if (modalExtras?.viewMode) setisFieldsDisabled(true);
      }
    }
  }, [modalStatus]);

  const onCategoryBasicInfoComplete = (data) => {
    setbasicData(data);
    //setactiveStep(2);
    saveInfo(data);
  };

  const onCategoryAvailabilityComplete = (data) => {
    availabilityData = data;
    saveInfo();
  };

  const categoryAvailabilityObj = {
    "monday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false },
    "tuesday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false },
    "wednesday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false },
    "thursday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false },
    "friday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false },
    "saturday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false },
    "sunday": { "opensAt": "00:00:00", "closesAt": "00:00:00", "isActive": false }
  };

  const saveInfo = async (data) => { 
    if (modalExtras?.viewMode) {
      closeModal();
      return;
    }
    let model = {
      ...data,
      menuId: menuId,
      operatingHours: categoryAvailabilityObj/* availabilityData */,
    };
    setisLoading(true);

    if (modalExtras?.editMode) {
      await editMenuCategory(initialData?.id, model)
        .then((res) => {
          toastify("success", res.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
    } else {
      await addMenuCategory(model)
        .then((res) => {
          toastify("success", res.message);
          closeModal();
          setisLoading(false);
        })
        .catch(() => {
          setisLoading(false);
        });
    }
  };

  const closeModal = () => {
    brandCtx.menuCategoryModalStatusChange(false);
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteMenuCategory(initialData.id)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(true);
        closeDeleteModal();
        closeModal();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const onTabChange = (step) => {
    if (step === 1) {
      setactiveStep(1);
    }
   /*  if (step === 2) {
      if (basicData) setactiveStep(2);
    } */
  };

  const saveAvailTimings = (data) => {
    setAvailData(data);
  };

  return (
    <>
      <div className="createcatTbs__wrapper">
        <Tab.Container
          mountOnEnter
          unmountOnExit
          id="addMenu__item__modal__Tabs"
          defaultActiveKey="first1"
        >
          <Row className="no-gutters">
            <Col sm={12}>
              <Nav variant="tabs" className="addMenu__item__modal__Tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first1"
                    active={activeStep === 1}
                    onClick={() => onTabChange(1)}
                  >
                    Category Details
                  </Nav.Link>
                </Nav.Item>
               {/*  <Nav.Item>
                  <Nav.Link
                    eventKey="second1"
                    active={activeStep === 2}
                    onClick={() => onTabChange(2)}
                  >
                    Availability
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane
                  eventKey="first1"
                  active={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading">
                    <CategoryBasicInfo
                      onSave={onCategoryBasicInfoComplete}
                      onHide={closeModal}
                      initialData={basicData}
                      disabled={isFieldsDisabled}
                    >
                      {modalExtras && modalExtras?.editMode ? (
                        <button
                          type="button"
                          onClick={openDeleteModal}
                          className="delete-option"
                        >
                          Delete
                        </button>
                      ) : null}
                    </CategoryBasicInfo>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second1"
                  active={activeStep === 2}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="tab__mainHeading">
                    <CategoryAvailability
                      onSave={onCategoryAvailabilityComplete}
                      loading={isLoading}
                      onHide={closeModal}
                      initialData={availData}
                      onChangeTab={saveAvailTimings}
                      disabled={isFieldsDisabled}
                    >
                      {modalExtras && modalExtras?.editMode ? (
                        <button
                          type="button"
                          onClick={openDeleteModal}
                          className="delete-option"
                        >
                          Delete
                        </button>
                      ) : null}
                    </CategoryAvailability>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Menu Category"
          subtitle="Are you sure want to delete this menu category, all items would be deleted."
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

export default CategoryForm;
