import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { orderStatusDropdown, OrderDetailsStrings } from "constants/orderStatus";
import { changeOrderStatus } from "services/api/order";
import toastify from "shared/toastr";
import PrepTimeModal from "./PrepTimeModal";

const OrderStatusFilter = (props) => {
    const orderDetails = props?.orderDetails;
    const kitchen = orderDetails?.kitchen;
    const latestOrderStatus = props?.orderStatusId;

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [loading, setLoading] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
    const [rerenderList, setrerenderList] = useState(true);
    const [showPrepTimeModal, setshowPrepTimeModal] = useState(false);
    const [statusName, setStatusName] = useState("");

    useEffect(() => {
        if (orderDetails) {
            orderStatusDropdownUpdate();
        }
    }, [orderDetails, latestOrderStatus]);

    useEffect(() => {
        if (selectedOrderStatus && orderDetails.id && kitchen.id) {
            if (statusName === OrderDetailsStrings.pending) {
                if (selectedOrderStatus === 2) {
                    setshowPrepTimeModal(true);
                }
            }
            else {
                props.onChangeOrderStatus(selectedOrderStatus, orderDetails?.id, kitchen.id);
            }
        }
    }, [selectedOrderStatus]);

    const orderStatusDropdownUpdate = () => {
        const details = orderStatusDropdown.filter(
            (item) => item?.orderStatusId === latestOrderStatus
        );
        if (details && details?.length) {
            const detailObj = details[0];
            setStatusName(detailObj.statusName);
            const dropDownItems = detailObj.items?.map((item) => ({
                label: item.statusDisplayText,
                value: item.id,
                disabled: item?.disabled,
            }));
            setOrderStatuses(dropDownItems);
        }
    }

    const onCheckOrderStatus = (value) => {
        setSelectedOrderStatus(value);
    };

    const changeToggle = (data) => {
        setToggleDropdown(data);
        if (data === true) setrerenderList(true);
    };

    const onCancel = () => setshowPrepTimeModal(false);

    const onConfirmStatusChange = (data) => {
        props.updatePreparationTime(data, selectedOrderStatus, orderDetails?.id, kitchen.id);
    }

    return (
        <>
            <div className="orderstatus__wrapper">
                <Dropdown show={toggleDropdown} onToggle={changeToggle}>
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        className="saveAsDraft__btn"
                    >
                        {/*  Select Action */}
                    </Dropdown.Toggle>
                    {rerenderList ? (
                        <Dropdown.Menu>
                            {orderStatuses && orderStatuses.length ?
                                (<div>
                                    {orderStatuses.map((odrSts, idx) => (
                                        <Dropdown.Item
                                            onClick={() => onCheckOrderStatus(odrSts.value)}
                                        >
                                            {odrSts.label}
                                        </Dropdown.Item>
                                    ))}
                                </div>) : null}
                        </Dropdown.Menu>
                    ) : null}
                </Dropdown>
            </div>
            {showPrepTimeModal && (
                <PrepTimeModal
                    show={showPrepTimeModal}
                    onCancel={onCancel}
                    data={orderDetails}
                    onConfirm={onConfirmStatusChange}
                /* onLoading={deleteLoading} */
                />
            )}
        </>
    );
};

export default OrderStatusFilter;
