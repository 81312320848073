import React, { useEffect, useState, useContext } from "react";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import MultiSelectFilter from "../../pages/order/listing/MultiSelectFilter";
import moment from "moment";
import OrdersNotificationContext from "store/orders-notification-context";

const SelectedFilters = (props) => {
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);
  const initialValue = props.value;
  const [selectedFilters, setSelectedFilters] = useState(props.value);
  useEffect(() => {
    var filterArr = [];
    if (initialValue?.dateFrom && initialValue?.dateTo) {
      filterArr.push({
        type: "date",
        value: `${moment(initialValue?.dateFrom).format(
          "MM/DD/yyyy"
        )}  to  ${moment(initialValue?.dateTo).format("MM/DD/yyyy")}`,
      });
    }
    if (initialValue?.selectedKitchen) {
      filterArr.push({
        type: "kitchen",
        value: initialValue?.selectedKitchen,
      });
    }
    setSelectedFilters(filterArr);
  }, [initialValue]);

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.value}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      border: "1px solid #c0c0c8",
      minHeight: 48,
          minWidth: 222,
          maxWidth: 500,
      "&:focus": {
        borderColor: "none",
        boxShadow: "none",
        outline: "none",
        border: 0,
      },
    }),
  };

  const handleChange = (arr) => {
    const filters = initialValue;
    if (arr?.length > 0) {
      if (arr.some((obj) => obj.type !== "date")) {
        filters["dateFrom"] = null;
        filters["dateTo"] = null;
        filters["selectedTimeRange"] = 0;
      } else {
        filters["selectedKitchenId"] = null;
      }
    } else {
      filters["dateFrom"] = null;
      filters["dateTo"] = null;
      filters["selectedTimeRange"] = 0;
      filters["selectedKitchenId"] = null;
    }
    OrdersNotificationCtx.saveReportsFilterData(filters);
    props.onSelect(filters);
    setSelectedFilters(arr);
  };

  return (
    <MultiSelectFilter
      options={[]}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        MultiValue,
        animatedComponents,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      menuIsOpen={false}
      onChange={handleChange}
      //allowSelectAll={true}
      value={selectedFilters}
      styles={customStyles}
    />
  );
};
export default SelectedFilters;
