import { BrandStatus } from "constants/brandStatus";
import { privateApi, publicApi } from "../utilities/Provider";

const basicUrl = "/Kitchen/";

const addKitchen = (model) => {
  let body = {
    kitchenInfoRequest: {
      name: model.name,
      businessAddress: model.address,
      latitude: model.latitude,
      longitude: model.longitude,
      listedPhoneNumber: model.listedPhoneNumber || model.phone,
    },
    userInfoRequest: {
      contactName: model.contactName,
      phoneNumber: "" + model.phone,
      emailAddress: model.email,
      password: model.password,
      confirmPassword: model.password,
    },
  };
  return publicApi.post(basicUrl + "kitchen", body);
};

const getKitchens = ({ pageSize, pageNum, searchTerm }) => {
  const params = new URLSearchParams();
  params.append("pageNum", pageNum);
  params.append("pageSize", pageSize);
  if (searchTerm) params.append("name", searchTerm);
  return privateApi.get(basicUrl + "kitchenlisting", { params });
};

const editKitchen = (model) => {
  let body = {
    kitchenInfoRequest: {
      id: model.id,
      name: model.businessName,
      businessAddress: model.businessAddress,
      latitude: model.latitude,
      longitude: model.longitude,
      salesTaxIndicator: model.salesTaxIndicator,
      callRestaurantEnabled: model.callRestaurantEnabled,
      listedPhoneNumber: model.listedPhoneNumber || model.phone
    },
    userInfoRequest: {
      id: model.ownerId,
      contactName: model.contactName,
      phoneNumber: "" + model.phone,
      emailAddress: model.email,
    },
  };

  return privateApi.put(basicUrl + "kitchen", body);
};

const deleteKitchen = (kitchenId) => {
  return privateApi.delete(basicUrl + "kitchen", {
    params: {
      id: kitchenId,
    },
  });
};

const getKitchenById = (kitchenId) => {
  return privateApi.get(basicUrl + "kitchen", {
    params: {
      id: kitchenId,
    },
  });
};

const getVBListByKitchenId = (model) => {
  return privateApi.get(basicUrl + "kitchenVBList", {
    params: {
      id: model.kitchenId,
      pageNum: model.pageNum, 
      pageSize: model.pageSize
    },
  });
};

const getDeviceListByKitchenId = (model) => {
  return privateApi.get(basicUrl + "kitchenDeviceList", {
    params: {
      id: model.kitchenId,
      pageNum: model.pageNum, 
      pageSize: model.pageSize
    },
  });
};


const changeKitchenStatus = (kitchenId, statusId) => {
  let body = {
    kitchenId: kitchenId,
    statusId: statusId,
  };
  return privateApi.put(basicUrl + "updatekitchenstatus", body);
};

const getActiveKitchens = (searchTerm = null) => {
  const params = new URLSearchParams();
  if (searchTerm) params.append("name", searchTerm);

  return privateApi.get(basicUrl + "allactivekitchens", { params });
};

const addVbToKitchen = (brandId, kitchenId) => {
  let body = {
    virtualBrandId: brandId,
    kitchenId: kitchenId,
    statusId: BrandStatus.DRAFT,
  };
  return privateApi.put(basicUrl + "addvbtokitchen", body);
};

const addKitchenMarketPlaces = (model, type) => {
  let body = {
    kitchenId: model.kitchenId,
    kitchenName: model.kitchenName,
    userId: model.userId,
    Marketplaces: model.options.map((opt) => ({
      MarketplaceId: opt.id,
      IsRestricted: opt.isRestricted,
      name: opt.name
    })),
  };
  if (type === "delivery") {
    return privateApi.post(basicUrl + "addmarketplacesfordelivery", body);
  } else if (type === "payout") {
    return privateApi.post(basicUrl + "addmarketplacesforpayout", body);
  } 
};

const getKitchenMarketplaces = (kitchenId, type) => {
  if (type === "delivery") {
    return privateApi.get(basicUrl + "marketplacelistfordelivery", {
      params: {
        kitchenId: kitchenId,
      },
    });
  }
  else if (type === "payout") {
    return privateApi.get(basicUrl + "marketplacelistforpayout", {
      params: {
        kitchenId: kitchenId,
      },
    });
  }
};

export {
  addKitchen,
  editKitchen,
  getKitchens,
  getKitchenById,
  deleteKitchen,
  changeKitchenStatus,
  getActiveKitchens,
  addVbToKitchen,
  getVBListByKitchenId,
  getDeviceListByKitchenId,
  addKitchenMarketPlaces,
  getKitchenMarketplaces
};
