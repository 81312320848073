import React, { useEffect } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";

import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import MenuAvailability from "shared/menu-availability/MenuAvailability";
import LoaderInline from "shared/loader/LoaderInline";
import { AvailabilityValidationSchema } from "shared/menu-availability/AvailabilitySchema";

const VBAvailabilityForm = (props) => {
  const isLoading = props.loader;
  const data = props.initialData;
  const isDisabled = props?.disabled;

  useEffect(() => {
    return () => props.onChangeTab(getValues());
  }, []);

  const validationSchema = Yup.object().shape(AvailabilityValidationSchema);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (data) {
      let availability = data;
      reset(availability);
    }
  }, [data]);

  const onSubmit = (data) => {
    props.onSave(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MenuAvailability disabled={isDisabled} text={"Default Operating Hours"}/>
        <Modal.Footer>
          {props.children}

          <button
            type="button"
            onClick={props.onHide}
            className="cancel-btn red-btn-ghost btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="red-btn next-btn btn"
            disabled={isLoading || !isValid}
          >
            {isLoading ? (
              <LoaderInline />
            ) : (
              <>
                Save and Continue
                <span className="icons-right">
                  <img src={rightArrowIcon} alt="arrow icon" />
                </span>
              </>
            )}
          </button>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

export default VBAvailabilityForm;
