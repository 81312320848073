import React from "react";

import './NothingAdded.scss'
import noMenuIcon from '../../assets/images/no_menu_icon.svg'

const NothingAdded = () => {
  return (
    <div className="nothingadded__wrapper">
      <div className="container">
        <div className="nothing__card__wrapper">
          <div className="nothing__card no__menu">
            <div className="nothing__card__wrapperInner">
              <div className="img__box">
                  <img src={noMenuIcon} alt="icon" className="img-fluid" />
              </div>
              <div className="content__box">
                  <h2>No menu added</h2>
                  <p>Please click on <span className="text__highlighted">“Add New Menu”</span> to create a menu.</p>
              </div>
            </div>
          </div>
          <div className="nothing__card no__category">
            <div className="nothing__card__wrapperInner">
              <div className="img__box">
                  <img src={noMenuIcon} alt="icon" className="img-fluid" />
              </div>
              <div className="content__box">
                  <h2>No category added</h2>
                  <p>Please click on <span className="text__highlighted">“Add Menu Category”</span> to create a category.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NothingAdded;
