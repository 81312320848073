import React from "react";

import { Link } from "react-router-dom";

import "./Onboarding.scss";

import emailicon from "assets/images/icon-email.svg";
import passwordicon from "assets/images/icon-password.svg";
import showpasswordicon from "assets/images/showpassword.svg";
import btnWhiteArrow from "../../assets/images/white-right-arrow-btn.svg";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const AboutBusiness = () => {
  return (
    <div className="signIn__wrapper about__business__wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3 pb-3">
            <div className="goBack">
              <Link
                to="/home"
                className="back-btn-red"
                title="back to dashboard"
              >
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back to Home
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="loginHeading__wrapper">
              <h1>Tell Us About Your Business</h1>
              <p>
                Let’s create your account first. Please provide the details.
              </p>
            </div>
          </div>
        </div>
        <form>
          <div className="signcard__wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="leftcard">
                  <div className="leftcard__inner">
                    <div className="form-row no-gutters">
                      <div className="col-md-12 mb-3 pb-3">
                        <label htmlFor="bname">
                          Business Name<span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="bname"
                          placeholder="True’s Pizza"
                          maxLength="50"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-3 pb-3">
                        <label htmlFor="baddress">
                          Business Address<span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="baddress"
                          placeholder="3045 Eagandale Place, Eagan, MN, USA"
                          maxLength="50"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-3 pb-3">
                        <label htmlFor="conatctName">
                          Contact Name<span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="conatctName"
                          placeholder="Matthew True"
                          maxLength="50"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-3 pb-3">
                        <label htmlFor="conatctNo">
                          Phone Number<span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="conatctNo"
                          placeholder="234 567 8907"
                          maxLength="50"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-3 pb-3">
                        <label htmlFor="email">
                          Email Address<span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="email"
                          placeholder="Enter your email address"
                          maxLength="50"
                          className="form-control"
                        />
                        <span className="input-icon">
                          <img src={emailicon} alt="" />
                        </span>
                      </div>
                      <div className="col-md-12 mb-3 pb-3">
                        <label htmlFor="password">
                          Password<span className="mendatory">*</span>
                        </label>
                        <input
                          type="password"
                          id="password"
                          placeholder="Enter your password"
                          maxLength="50"
                          className="form-control"
                        />
                        <span className="input-icon">
                          <img src={passwordicon} alt="showPassword" />
                        </span>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="custom-checkbox-wrapper d-flex">
                          <label className="custom-checkbox-container">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                          <span className="terms-text">
                            By signing up, you are accepting the{" "}
                            <a href="#agreement">restaurant agreement</a>,{" "}
                            <a href="#privacy policy">privacy policy</a> and{" "}
                            <a href="#terms of use">terms of use</a> of this
                            platform.
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 pb-3 action__wrapper">
                        <button type="submit">
                          Complete Signup{" "}
                          <img
                            className="icon"
                            src={btnWhiteArrow}
                            alt="arrow"
                          />
                        </button>
                      </div>
                      <div className="col-12 d-md-none righside__mobile">
                        <div className="or">
                          <p>OR</p>
                        </div>
                        <div className="loginHeading__wrapper">
                          <h1>Already have an account?</h1>
                        </div>
                        <div className="rightside">
                          <div className="content__box">
                            <div className="action__wrapper">
                              <button type="submit">Sign In</button>
                            </div>
                            <div className="rightside__links">
                              <Link to="#" className="rightside__link">
                                How it Works?
                              </Link>
                              <Link to="#" className="rightside__link">
                                FAQ’s
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 d-none d-md-block">
                <div className="rightside">
                  <div className="rightside__bg aboutBusiness__bg">
                    <div className="content__box">
                      <div>
                        <h2>Already have an account?</h2>
                        <div className="action__wrapper">
                          <button
                            type="submit"
                            className="red-button-filled-hover"
                          >
                            Login
                          </button>
                        </div>
                      </div>
                      <div className="rightside__links">
                        <Link to="#" className="rightside__link">
                          How it Works?
                        </Link>
                        <Link to="#" className="rightside__link">
                          FAQ’s
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AboutBusiness;
