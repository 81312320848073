import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../../../pages/staff/listing/ManageModifierLandingpage.scss";
import searchIcon from "assets/images/icon-search.svg";
import plusIcon from "assets/images/add-icon-white.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import Paginator from "shared/paginator/Paginator";
import ListingRow from "./RecipeGuideListingRow";
import { Global } from "constants/global";
import LoaderBlock from "shared/loader/LoaderBlock";
import { getRecipeGuidesList } from "services/api/recipeguide";
import backArrowIcon from "assets/images/back-arrow.svg";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { useParams } from "react-router";
import RecipeGuideContext from "store/recipe-guide-context";
import { useMediaQuery } from "react-responsive";

const staffParams = new URLSearchParams();

const RecipeGuideListing = () => {
  const history = useHistory();
  const location = useLocation();

  const [pageNum, setpageNum] = useState(1);
  const [searchTerm, setsearchTerm] = useState(null);
  const pageSize = Global.pageSize;
  const [isLoading, setisLoading] = useState(false);
  const [totalRecords, settotalRecords] = useState(0);
  const [recipeGuides, setRecipeGuides] = useState([]);
  const { brandId } = useParams("brandId");
  const recipeguideCtx = useContext(RecipeGuideContext);
  const [subHeaderTitle, setsubHeaderTitle] = useState(null);
  const virtualBrandName = recipeguideCtx.virtualBrandName;
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    getListing();
  }, [pageNum, searchTerm, brandId]);

  useEffect(() => {
    changeSubHeaderText();
  }, [brandId, virtualBrandName]);

  useEffect(() => {
    if (location) {
      if (location?.search) {
        let page = new URLSearchParams(location?.search).get("page");
        let search = new URLSearchParams(location?.search).get("search");

        if (page) {
          setpageNum(+page);
          staffParams.set("page", page);
        } else {
          setpageNum(1);
          staffParams.delete("page");
        }

        if (search) {
          setsearchTerm(search);
          staffParams.set("search", search);
        } else {
          setsearchTerm(null);
          staffParams.delete("search");
        }

      } else {
        setpageNum(1);
        staffParams.delete("page");
        setsearchTerm(null);
        staffParams.delete("search");
      }
    }
  }, [location]);

  const changeSubHeaderText = () => {
    let prefix = virtualBrandName ? virtualBrandName + "- " : "";
    let suffix = "Recipe Guide";
    let text = prefix + suffix;
    setsubHeaderTitle(text);
  };

  const getListing = async () => {
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      searchTerm: searchTerm,
      brandId: brandId
    };
    setisLoading(true);
    await getRecipeGuidesList(masks)
      .then((res) => {
        const data = res.data?.recipeGuides;
        setRecipeGuides(data);
        settotalRecords(res.data?.totalCount);
        setisLoading(false);
        recipeguideCtx.saveVirtualBrandInfo({id: brandId, name: res.data?.virtualBrandName});
      })
      .catch(() => {
        setRecipeGuides(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    setpageNum(page);
    if (page) {
      staffParams.set("page", page);
    } else {
      staffParams.delete("page");
    }
    history.push({ search: staffParams.toString() });
  };

  const onSearch = (e) => {
    let search = e.target.value;
    if (e.key === "Enter") {
      if (search) {
        staffParams.set("search", search);
      } else {
        staffParams.delete("search");
      }
      setpageNum(1);
      staffParams.delete("page");
      history.push({ search: staffParams.toString() });
    }
  };

  const createRecipeLink = () => {
    return `/recipeguide/${brandId}/${0}#add`;
  }

  return (
    <>
      <Subheader title={isMobile ? null : subHeaderTitle}>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            {isMobile ? subHeaderTitle : "Go to Dashboard" }
          </Link>
        </div>
      </Subheader>
      <div className="manageUser__landingPage__wrapper">
        <div className="managermodifier__wrapper basic__info__content">
          <div className="container">
            <div className="managermodifier__header">
              <div className="row justify-content-between">
                <div className="col-12 mb-3 pb-3 d-none d-md-block">
                  <div className="goBack">
                    <Link
                      to="/dashboard"
                      className="back-btn-red"
                      title="Go to Dashboard"
                    >
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Go to Dashboard
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-xl-4 mb-md-3 mb-xl-0">
                  <h1>Recipe Guides</h1>
                </div>
                <div className="col-12 col-md-12 col-xl-8">
                  <div className="staff_listing_actions">
                    <div className="form">
                      <div className="search__modifiers manage-restra-search manage-user-search mr-md-4">
                        <div className="search_modifier_group">
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="text"
                                className="custom-file form-control"
                                placeholder="Search Recipes"
                                onKeyUp={onSearch}
                              />
                              <button type="button" className="search-btn">
                                <span className="icons">
                                  <img src={searchIcon} alt="plus icon" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="adduser__btn">
                        <Link to={createRecipeLink()} className="red-btn btn">
                          <span className="icons-left icons">
                            <img src={plusIcon} alt="add icon" />
                          </span>
                          Create Recipe Guide
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!recipeGuides ? (
              <div className="managermodifier__noListing">
                <div className="managermodifier__noListing__innerwrapper">
                  <div className="content">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={emptyIocn}
                        alt="no list icon"
                        width="185"
                      />
                    </div>
                    <div className="content-desc">
                      <h3>
                        {searchTerm && searchTerm !== ""
                          ? "No Recipe Guide(s) found"
                          : "No Recipe Guides are added"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="managermodifier__listinPage managerestra__listinPage">
                <div className="managermodifier__listinPage__wrapper">
                  <table className="table modifierListing__table  ordersListing-table">
                    <thead>
                      <tr role="row" className="mat-header-row">
                        <th scope="col" className="mat-header-cell column-1">
                          Name
                        </th>
                        <th scope="col" className="mat-header-cell column-2">
                          # of Recipes
                        </th>
                        <th scope="col" className="mat-header-cell column-3">
                          Last Modified
                        </th>
                        <th scope="col" className="mat-header-cell column-5">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td>
                            <LoaderBlock />
                          </td>
                        </tr>
                      ) : (
                        recipeGuides &&
                        recipeGuides.map((recipeGuide, idx) => (
                          <ListingRow key={idx} recipeGuide={recipeGuide} brandId = {brandId} onReloadList={getListing} isActive={recipeGuide.isActive}/>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-12">
                      <Paginator
                        totalCount={totalRecords}
                        currentPage={pageNum}
                        pageSize={pageSize}
                        pageClick={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipeGuideListing;
