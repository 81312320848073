import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { KitchenStatus } from "constants/kitchenStatus";
import { changeKitchenStatus, deleteKitchen } from "services/api/kitchen";
import toastify from "shared/toastr";
import DeleteModal from "UI/Modal/DeleteModal";
import LoaderInline from "shared/loader/LoaderInline";
import UserContext from "store/user-context";

const ChangeStatus = (props) => {
  const kitchen = props.restaurant;
  const restId = props.restaurantId;
  const status = props.status;
  const kitchenStatuses = KitchenStatus;

  const userCtx = useContext(UserContext);
  const userInfo = userCtx.userInfo;

  useEffect(() => {
    statusToShow(status);
  }, [restId]);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [currentStatus, setcurrentStatus] = useState(status);
  const [selectedStatusWithClass, setselectedStatusWithClass] = useState({
    class: null,
    text: null,
  });

  const statusesWithClasses = {
    pending: { text: "Request Pending", class: "badge__blue" },
    accept: { text: "", class: "badge__red" },
    reject: { text: "Rejected", class: "badge__gray" },
    active: { text: "Active", class: "badge__red" },
    inactive: { text: "Inactive", class: "badge__gray" },
  };

  const onStatusChange = async (statusId) => {
    setisLoading(true);
    await changeKitchenStatus(restId, statusId).then((res) => {
      let status = res.data.status;
      toastify("success", res.message);
      statusToShow(status);
      setcurrentStatus(status);
      setisLoading(false);
    });
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteKitchen(restId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        props.onActionComplete();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const statusToShow = (statusId) => {
    switch (statusId) {
      case kitchenStatuses.PENDING:
        setselectedStatusWithClass(statusesWithClasses.pending);
        break;
      case kitchenStatuses.ACCEPT:
        setselectedStatusWithClass(statusesWithClasses.accept);
        break;
      case kitchenStatuses.REJECT:
        setselectedStatusWithClass(statusesWithClasses.reject);
        break;
      case kitchenStatuses.ACTIVE:
        setselectedStatusWithClass(statusesWithClasses.active);
        break;
      case kitchenStatuses.INACTIVE:
        setselectedStatusWithClass(statusesWithClasses.inactive);
        break;
      default:
        setselectedStatusWithClass(statusesWithClasses.pending);
        break;
    }
  };

  return (
    <>
      <div className="right_content">
        <span className={`custom__badge ${selectedStatusWithClass.class}`}>
          {isLoading ? <LoaderInline /> : selectedStatusWithClass.text}
        </span>
        <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
          <button
            type="button"
            className="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            id="dropdown-button-drop-left"
          ></button>
          <div className="dropdown-menu">
            {/* for pending starts */}
            {currentStatus === kitchenStatuses.PENDING ? (
              <>
                <div className="dropdown__innerWrapper">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => onStatusChange(kitchenStatuses.ACTIVE)}
                  >
                    <span>Accept</span>
                  </button>
                </div>
                <div className="dropdown__innerWrapper">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => onStatusChange(kitchenStatuses.REJECT)}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </>
            ) : null}
            {/* for pending ends */}
            {/* for accepted starts */}
            {currentStatus === kitchenStatuses.INACTIVE ||
            currentStatus === kitchenStatuses.ACTIVE ? (
              <>
                {currentStatus === kitchenStatuses.INACTIVE ? (
                  <div className="dropdown__innerWrapper">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => onStatusChange(kitchenStatuses.ACTIVE)}
                    >
                      <span>Set As Active </span>
                    </button>
                  </div>
                ) : (
                  <div className="dropdown__innerWrapper">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => onStatusChange(kitchenStatuses.INACTIVE)}
                    >
                      <span>Set As Inactive </span>
                    </button>
                  </div>
                )}
                <div className="dropdown__innerWrapper">
                  <Link
                    to={`/restaurants/${restId}/edit`}
                    className="dropdown-item"
                  >
                    <span>Edit Restaurant</span>
                  </Link>
                </div>
                <div className="dropdown__innerWrapper">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={openDeleteModal}
                    disabled={kitchen?.createdBy === userInfo?.userId}
                  >
                    <span>Delete Restaurant</span>
                  </button>
                </div>
              </>
            ) : null}
            {/* for accepted ends */}
            {/* for rejected starts  */}
            {currentStatus === kitchenStatuses.REJECT ? (
              <div className="dropdown__innerWrapper">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={openDeleteModal}
                  disabled={kitchen?.createdBy === userInfo?.userId}
                >
                  <span>Delete Restaurant</span>
                </button>
              </div>
            ) : null}
            {/* for rejected ends */}
          </div>
        </div>
      </div>

      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Restaurant"
          subtitle="Are you sure want to delete this restaurant"
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

ChangeStatus.propTypes = {
  restaurantId: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  onActionComplete: PropTypes.func,
};

export default ChangeStatus;
