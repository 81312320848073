import Footer from "layout/auth-layout/footer/Footer";
import React from "react";

const HomeLayout = (props) => {
  return (
    <>
      {props.children}
      <Footer />
    </>
  );
};

export default HomeLayout;
