import React, { useContext, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { changeStatusOfMenuItem, deleteMenuItem, changeStatusOfSharedMenuItem } from "services/api/menu";
import toastify from "shared/toastr";
import EditButton from "UI/Button/EditButton";
import VirtualBrandContext from "store/virtual-brand-context";
import DeleteModal from "UI/Modal/DeleteModal";
import { VirtualBrandMode } from "constants/virtualBrandMode";
import ViewButton from "UI/Button/ViewButton";
import ImageSrc from "shared/image-src/ImageSrc";

const MenuItemCard = (props) => {
  const brandCtx = useContext(VirtualBrandContext);
  const editable = brandCtx.isConfirmPage;
  const brandMode = brandCtx.virtualBrandMode;
  const menuItemId = props.id;
  const categoryId = props.categoryId;
  const [publishStatus, setpublishStatus] = useState(null);
  const brandId = brandCtx.virtualBrandId;
  const kitchenId = brandCtx.associationKitchenId;

  useEffect(() => {
    if (props) {
      setpublishStatus(props.isActive);
    }
  }, [props]);

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const onChangePublishStatus = async () => {
    if (brandMode === VirtualBrandMode.SHARED) {
      await changeStatusOfSharedMenuItem(menuItemId, !publishStatus, brandId, kitchenId).then((res) => {
        toastify("success", res?.message);
        setpublishStatus(!publishStatus);
      });
    }
    else {
      await changeStatusOfMenuItem(menuItemId, !publishStatus).then((res) => {
        toastify("success", res?.message);
        setpublishStatus(!publishStatus);
      });
    }
  };

  const onView = () => {
    brandCtx.menuItemModalStatusChange(true, {
      editMode: false,
      cloneMode: false,
      viewMode: true,
      data: props,
    });
    brandCtx.saveMenuCategoryId(categoryId);
  };

  const onEdit = () => {
    brandCtx.menuItemModalStatusChange(true, {
      editMode: true,
      cloneMode: false,
      viewMode: false,
      data: props,
    });
    brandCtx.saveMenuCategoryId(categoryId);
  };

  const onClone = () => {
    brandCtx.menuItemModalStatusChange(true, {
      editMode: false,
      cloneMode: true,
      viewMode: false,
      data: props,
    });
    brandCtx.saveMenuCategoryId(categoryId);
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);
  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteMenuItem(menuItemId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onReload();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  return (
    <>
      <div className="menuItem__card">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className="menuItem__card__details__wrapper">
              <div className="img-box">
                <ImageSrc
                  className="img-fluid"
                  orgImage={props.coverURL}
                  rawImage={props.rawCoverURL}
                />
              </div>
              <div className="menuItem_desc">
                <h5>
                  {props.itemName}
                  {editable ? (
                    brandMode === VirtualBrandMode.SHARED ||
                    brandMode === VirtualBrandMode.VIEW ? (
                      <ViewButton onClick={onView} />
                    ) : (
                      <EditButton onClick={onEdit} />
                    )
                  ) : null}
                </h5>
                <p>{props.description}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 flex__container basePrice text-uppercase d-md-none">
            <p>Base Price</p>
            <p>Sort Order</p>
          </div>

          {/* {brandMode === VirtualBrandMode.SHARED ||
          brandMode === VirtualBrandMode.VIEW ? null : (
            <div className="threedotsbtn btn-group dropleft">
              <DropdownButton
                key="left"
                id="dropdown-button-drop-left"
                drop="left"
                variant="secondary"
                className="leftDrowpdown"
              >
                <Dropdown.Item onClick={onClone}>
                  <div className="dropdown__innerWrapper">
                    <span>Clone Item</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={onChangePublishStatus}>
                  <div className="dropdown__innerWrapper">
                    <span>Set As {publishStatus ? "Inactive" : "Active"}</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={onEdit}>
                  <div className="dropdown__innerWrapper">
                    <span>Edit Item</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={openDeleteModal}>
                  <div className="dropdown__innerWrapper">
                    <span>Delete Item</span>
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          )}

          {brandMode === VirtualBrandMode.SHARED && (
            <div className="threedotsbtn btn-group dropleft">
              <DropdownButton
                key="left"
                id="dropdown-button-drop-left"
                drop="left"
                variant="secondary"
                className="leftDrowpdown"
              >
                <Dropdown.Item onClick={onChangePublishStatus}>
                  <div className="dropdown__innerWrapper">
                    <span>Set As {publishStatus ? "Inactive" : "Active"}</span>
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          )} */}

          <div className="col-12 col-md-6 menuItem_price flex__container">
            <div>
              <p>${props.pickupPrice?.toFixed(2)}</p>
              <p>{publishStatus ? "Active" : "Inactive"}</p>
            </div>
            <div>
              <p>{props?.priority}</p>
            </div>
            {brandMode === VirtualBrandMode.SHARED ||
            brandMode === VirtualBrandMode.VIEW ? null : (
              <div className="threedots_btn">
                <DropdownButton
                  key="left"
                  id="dropdown-button-drop-left"
                  drop="left"
                  variant="secondary"
                  className="leftDrowpdown"
                >
                  <Dropdown.Item onClick={onClone}>
                    <div className="dropdown__innerWrapper">
                      <span>Clone Item</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onChangePublishStatus}>
                    <div className="dropdown__innerWrapper">
                      <span>
                        Set As {publishStatus ? "Inactive" : "Active"}
                      </span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onEdit}>
                    <div className="dropdown__innerWrapper">
                      <span>Edit Item</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openDeleteModal}>
                    <div className="dropdown__innerWrapper">
                      <span>Delete Item</span>
                    </div>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
            {brandMode === VirtualBrandMode.SHARED && (
              <div className="threedots_btn">
                <DropdownButton
                  key="left"
                  id="dropdown-button-drop-left"
                  drop="left"
                  variant="secondary"
                  className="leftDrowpdown"
                >
                  <Dropdown.Item onClick={onChangePublishStatus}>
                    <div className="dropdown__innerWrapper">
                      <span>
                        Set As {publishStatus ? "Inactive" : "Active"}
                      </span>
                    </div>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Menu Item"
          subtitle="Are you sure want to delete this menu item"
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

MenuItemCard.propTypes = {
  id: PropTypes.number,
  itemName: PropTypes.string,
  description: PropTypes.string,
  coverURL: PropTypes.string,
  logoURL: PropTypes.string,
  pickupPrice: PropTypes.number,
  pickupPriceTax: PropTypes.number,
  deliveryPrice: PropTypes.number,
  deliveryPriceTax: PropTypes.number,
  isActive: PropTypes.bool,
  categoryId: PropTypes.number,
};

export default MenuItemCard;
