import { privateApi } from "../utilities/Provider";
import getFileNameFromUrl from "../utilities/Helper";
const basicUrl = "/Menu/";

// Menu API
const addBrandMenu = (model) => {
  let body = {
    virtualBrandId: model.brandId,
    title: model.title,
    subTitle: model.subTitle,
    coverImageBlobName: getFileNameFromUrl(
      model?.coverImage || model?.coverUrl
    ),
    description: model.description,
    currency: model.currency,
    minimumValue: model.minimumOrder,
    menuAvailability: model.operatingHours,
  };

  return privateApi.post(basicUrl + "menu", body);
};

const editBrandMenu = (menuId, model) => {
  let body = {
    id: menuId,
    virtualBrandId: model.brandId,
    title: model.title,
    subTitle: model.subTitle,
    coverImageBlobName: getFileNameFromUrl(model.coverImage || model?.coverUrl),
    description: model.description,
    currency: model.currency,
    minimumValue: model.minimumOrder,
    menuAvailability: model.operatingHours,
    kitchenId: model.kitchenId
  };

  return privateApi.put(basicUrl + "menu", body);
};

const deleteBrandMenu = (menuId) => {
  return privateApi.delete(basicUrl + "menu", {
    params: {
      id: menuId,
    },
  });
};

const deleteMenuCoverImage = (menuId) => {
  return privateApi.delete(basicUrl + "coverImage", {
    params: {
      id: menuId,
    },
  });
};

const getBrandMenus = (brandId, kitchenId = 0) => {
  return privateApi.get(basicUrl + "menubyvirtualbrandid", {
    params: {
      virtualbrandId: brandId,
      kitchenId: kitchenId,
    },
  });
};

const getBrandMenuById = (menuId) => {
  return privateApi.get(basicUrl + "menubyId", {
    params: {
      menuId: menuId,
    },
  });
};

const statusChangeOfMenu = (menuId, isActive) => {
  return privateApi.put(basicUrl + "menuStatus", {
    menuId: menuId,
    isActive: isActive,
  });
};

// Menu Category API
const addMenuCategory = (model) => {
  let body = {
    name: model.title || model.name,
    description: model.description,
    menuId: model.menuId,
    menuCategoryAvailability: model.operatingHours,
  };

  return privateApi.post(basicUrl + "menuCategory", body);
};

const editMenuCategory = (categoryId, model) => {
  let body = {
    id: categoryId,
    menuId: model.menuId,
    name: model.title || model.name,
    description: model.description,
    menuCategoryAvailability: model.operatingHours,
  };

  return privateApi.put(basicUrl + "menuCategory", body);
};

const deleteMenuCategory = (categoryId) => {
  return privateApi.delete(basicUrl + "menuCategory", {
    params: {
      id: categoryId,
    },
  });
};

const statusChangeOfMenuCategory = (categoryId, isActive) => {
  return privateApi.put(basicUrl + "menuCategoryStatus", {
    menuCategoryId: categoryId,
    isActive: isActive,
  });
};

const getMenuCategories = (menuId) => {
  return privateApi.get(basicUrl + "menucategoriesbymenuid", {
    params: {
      menuId: menuId,
    },
  });
};

const getMenuCategoryById = (menuCategoryId) => {
  return privateApi.get(basicUrl + "menuCategories", {
    params: {
      menuCategoryId: menuCategoryId,
    },
  });
};

// Menu Item API
const addMenuItem = (model) => {
  let body = {
    menuCategoryId: model.categoryId,
    coverUrl: getFileNameFromUrl(model.coverURL),
    itemName: model.itemName,
    description: model.description,
    preparationTime: model.preparationTime,
    basePrice: model.pickupPrice,
    taxRate: model.pickupPriceTax,
    priority: model.priority,
    isAlcoholic: model.isAlcoholic,
    isActive: model.isActive,
    plu: "",
  };

  return privateApi.post(basicUrl + "menuItem", body);
};

const editMenuItem = (itemId, model) => {
  let body = {
    id: itemId,
    menuCategoryId: model.categoryId,
    coverUrl: getFileNameFromUrl(model.coverURL),
    itemName: model.itemName,
    description: model.description,
    preparationTime: model.preparationTime,
    basePrice: model.pickupPrice,
    taxRate: model.pickupPriceTax,
    priority: model.priority,
    isAlcoholic: model.isAlcoholic,
    isActive: model.isActive,
  };

  return privateApi.put(basicUrl + "menuItem", body);
};

const deleteMenuItem = (itemId) => {
  return privateApi.delete(basicUrl + "menuItem", {
    params: {
      id: itemId,
    },
  });
};

const deleteMenuItemImage = (itemId) => {
  return privateApi.delete(basicUrl + "menuItemImage", {
    params: {
      id: itemId,
    },
  });
};

const addModifierToMenuItem = (model) => {
  let modifier = model.modifier;

  let modOptions = modifier.map((res) => ({
    menuItemModId: res.menuItemModGroup.id,
    isMandatory: res.isMandatory === "true",
    ModOptions: res.menuItemModOptions.map((res) => ({
      id: res.id,
      priority: res.priority,
    })),
    maxOptionAllowed: +res.maxOptionsAllowed,
  }));

  let body = {
    menuItemId: model.menuItemId,
    menuItemModOptions: modOptions,
  };
  return privateApi.post(basicUrl + "menuitemmodoptions", body);
};

const editModifierToMenuItem = (model) => {
  let modifier = model.modifier;

  let modOptions = modifier.map((res) => ({
    menuItemModId: res.menuItemModGroup.id,
    isMandatory: res.isMandatory === "true",
    ModOptions: res.menuItemModOptions.map((res) => ({
      id: res.id,
      priority: res.priority,
    })),
    maxOptionAllowed: +res.maxOptionsAllowed,
  }));

  let body = {
    menuItemId: model.menuItemId,
    menuItemModOptions: modOptions,
  };
  return privateApi.put(basicUrl + "menuitemmodoptions", body);
};

const getModifierGroups = () => {
  return privateApi.get(basicUrl + "itemModGroups");
};

const getModifierOptionsById = (groupId) => {
  return privateApi.get(basicUrl + "itemmodoptions", {
    params: {
      itemModId: groupId,
    },
  });
};

const getMenuItems = (categoryId, brandId, kitchenId) => {
  return privateApi.get(basicUrl + "menuitemsbycategoryid", {
    params: {
      categoryId: categoryId,
      kitchenId: kitchenId,
      virtualBrandId: brandId
    },
  });
};

const getMenuItemWithModById = (itemId, brandId, kitchenId) => {
  return privateApi.get(basicUrl + "menuItemwithmods", {
    params: {
      menuItemId: itemId,
      kitchenid: kitchenId,
      virtualBrandId: brandId
    },
  });
};

const changeStatusOfMenuItem = (itemId, publishStatus) => {
  return privateApi.put(basicUrl + "menuItemStatus", {
    menuItemId: itemId,
    isPublished: publishStatus,
  });
};

const changeStatusOfSharedMenuItem = (itemId, publishStatus, brandId, kitchenId) => {
  let body = {
    kitchenId: kitchenId,
    virtualBrandId: brandId,
    menuItemId: itemId,
    isActive: publishStatus,
  };
  return privateApi.put(basicUrl + "kitchenmenuitemavailabilityupdate", body);
};

const changeStatusOfSharedModOption = (model) => {
  let body = {
    kitchenId: model.kitchenId,
    virtualBrandId: model.brandId,
    menuItemId: model.menuItemId,
    menuItemModId: model.menuItemModId,
    menuItemModOptionId: model.menuItemModOptionId,
    isActive: model.status,
  };
  return privateApi.put(basicUrl + "kitchenmenuitemmodoptionavailabilityupdate", body);
};

const addMenuAvailToKitchen = ({ menuId, kitchenId, brandId }, model) => {
  let body = {
    menuId: menuId,
    virtualBrandId: brandId,
    kitchenId: kitchenId,
    menuAvailability: model.operatingHours,
  };

  return privateApi.put(basicUrl + "menuavailabilityforkitchen", body);
};

export {
  addBrandMenu,
  editBrandMenu,
  deleteBrandMenu,
  statusChangeOfMenu,
  getBrandMenus,
  getBrandMenuById,
  addMenuCategory,
  editMenuCategory,
  statusChangeOfMenuCategory,
  deleteMenuCategory,
  getMenuCategories,
  getMenuCategoryById,
  addMenuItem,
  editMenuItem,
  deleteMenuItem,
  addModifierToMenuItem,
  editModifierToMenuItem,
  getMenuItems,
  getMenuItemWithModById,
  getModifierGroups,
  getModifierOptionsById,
  changeStatusOfMenuItem,
  addMenuAvailToKitchen,
  changeStatusOfSharedMenuItem,
  changeStatusOfSharedModOption,
  deleteMenuCoverImage,
  deleteMenuItemImage,
};
