import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import ScrollToTop from "shared/scroll-to-top/ScrollToTop";
import { getUserDataFromToken, getUserToken } from "services/local-storage";
import { getKitchenStaffToken } from "services/api/auth";
import { RoleType } from "constants/roleType";

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  openRoute,
  path,
  ...rest
}) => {

  let user = getUserToken();
  const params = rest?.computedMatch?.params;

  useEffect(() => {
    if (path === "/recipeguide/:brandId/:recipeGuideId/:deviceTokenId" && params) {
      if (params?.brandId && params?.recipeGuideId && params?.deviceTokenId) {
        const getKitchenStaffTokenInfo = async () => {
          const model = {
            deviceTokenId: params?.deviceTokenId,
            brandId: params?.brandId,
            recipeGuideId: params?.recipeGuideId,
          };
          await getKitchenStaffToken(model)
            .then((res) => {
              if (res?.data?.token) {
                localStorage.setItem("userToken", JSON.stringify({ token: res?.data?.token }));
                window.location.replace(`/recipeguide/${params?.brandId}/${params?.recipeGuideId}`);
              
              }
            })
            .catch(() => {
              localStorage.removeItem("userToken"); 
            });
        }
        getKitchenStaffTokenInfo();
      }
      return () => {
        localStorage.removeItem("userToken"); 
      };
    }
  }, []);
   
  const role = getUserDataFromToken()?.role;
  const accessor = rest?.accessor;

  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        if (!openRoute) {
          if (isAuthProtected) {
            if (user && accessor && !accessor.includes(role)) {
              return role === RoleType.KITCHEN_STAFF ? (
                <Redirect
                  to={{
                    pathname: "/profile",
                    state: { from: props.location },
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
            if (!user) {
              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              );
            }
          }

          if (!isAuthProtected) {
            if (user) {
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        }

        return (
          <Layout>
            <ScrollToTop />
            <Component {...props} {...rest} />
          </Layout>
        );
      }}
    />
  );
};

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  path: PropTypes.string,
  layout: PropTypes.any,
};

export default withRouter(AuthMiddleware);
