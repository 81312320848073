import { privateApi } from "../utilities/Provider";

const basicUrl = "/Menu/";

const getModifierGroups = ({ pageSize, pageNum, searchTerm }) => {
  const params = new URLSearchParams();
  if (pageNum) params.append("pagenum", pageNum);
  if (pageSize) params.append("pagesize", pageSize);
  if (searchTerm) params.append("name", searchTerm);
  return privateApi.get(basicUrl + "menuitemmodslist", { params });
};

const getKitchenModswithModOptions = (model) => {
  return privateApi.get(basicUrl + "getkitchenmodswithmodoptions", {
    params: {
      kitchenId: model.kitchenId,
      pageNumber: model.pageNum, 
      pageSize: model.pageSize,
      modOptionName: model.searchTerm
    },
  });
};

const getModifierGroup = (modGroupId) => {
  const params = new URLSearchParams();
  params.append("menuItemModId", modGroupId);
  return privateApi.get(basicUrl + "menuitemmodbyid", { params });
};

const addModifierGroup = (model) => {
  let body = {
    id: model.id,
    name: model.name,
    modifierType: model.modifierType,
    menuItemModOptions: model.options.map((opt) => ({
      id: opt.id,
      name: opt.name,
      price: opt.price,
    })),
  };
  return privateApi.post(basicUrl + "modifiergroup", body);
};

const editModifierGroup = (model) => {
  let body = {
    id: model.id,
    name: model.name,
    modifierType: model.modifierType,
    menuItemModOptions: model.options.map((opt) => ({
      id: opt.id,
      name: opt.name,
      price: opt.price,
    })),
  };
  return privateApi.put(basicUrl + "modifiergroup", body);
};

const changeStatusModifierGroup = (modGroupId, status) => {
  let body = {
    menuItemModId: modGroupId,
    isActive: status,
  };
  return privateApi.put(basicUrl + "menuitemmodstatus", body);
};

const updateKitchenModOptionStatus = (model) => {
  const body = {
    kitchenId: model.kitchenId,
    modOptionId: model.modOptionId,
    isActiveStatus: model.isActiveStatus
  };
  return privateApi.post(basicUrl + "updateKitchenModOption", body);
};

const deleteModifierGroup = (modGroupId) => {
  const params = new URLSearchParams();
  params.append("id", modGroupId);
  return privateApi.delete(basicUrl + "menuitemmod", { params });
};

export {
  getModifierGroups,
  getModifierGroup,
  addModifierGroup,
  editModifierGroup,
  changeStatusModifierGroup,
  deleteModifierGroup,
  getKitchenModswithModOptions,
  updateKitchenModOptionStatus
};
