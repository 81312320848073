import React from "react";
import { Link } from "react-router-dom";
import "../ManageResturants/ManageModifierLandingpage.scss";
import "./ManageUserLandingPage.scss";
import searchIcon from "../../assets/images/icon-search.svg";
import plusIcon from "../../assets/images/add-icon-white.svg";
import noUserIcon from "../../assets/images/no-user-icon.svg";
import editIcon from "../../assets/images/edit-blue.svg";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";

const ManageUserLandingPage = () => {
  return (
    <div className="manageUser__landingPage__wrapper">
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header">
            <div className="row justify-content-between">
              <div className="col-12 col-md-12 col-xl-3 mb-md-3 mb-xl-0">
                <h1>Manage Users</h1>
              </div>
              <div className="col-12 col-md-12 col-xl-9">
                <form>
                  <div className="search__modifiers manage-restra-search manage-user-search mr-md-4">
                    <div className="search_modifier_group">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="search"
                            id=""
                            className="custom-file form-control"
                            placeholder="Search User"
                          />
                          <button className="search-btn">
                            <span className="icons">
                              <img src={searchIcon} alt="plus icon" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="userselect mr-md-4">
                    <div className="savedraft__wrapper pb-0">
                      <Dropdown
                        as={ButtonGroup}
                        className="saveAsDraft__btn saveAsDraft__btn__gray"
                      >
                        <Button type="button">All Users</Button>
                        <Dropdown.Toggle split id="dropdown-split-basic" />
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            All Users
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Normal Users
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Staff Users
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="adduser__btn">
                    <Link to="/add-user" className="red-btn btn">
                      <span className="icons-left icons">
                        <img src={plusIcon} alt="add icon" />
                      </span>
                      Add New User
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="managermodifier__noListing manageruser__noListing d-none">
            <div className="managermodifier__noListing__innerwrapper">
              <div className="content">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src={noUserIcon}
                    alt="no list icon"
                    width="185"
                  />
                </div>
                <div className="content-desc">
                  <h3>No users are added</h3>
                  <p>Please click on “Add New User” to create new users.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="managermodifier__listinPage managerestra__listinPage">
            <div className="managermodifier__listinPage__wrapper">
              <table className="table modifierListing__table">
                <thead>
                  <tr role="row" className="mat-header-row">
                    <th scope="col" className="mat-header-cell column-1">
                    Name
                    </th>
                    <th scope="col" className="mat-header-cell column-2">
                      Contact
                    </th>
                    <th scope="col" className="mat-header-cell column-3">
                      Type
                    </th>
                    <th scope="col" className="mat-header-cell column-5">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="NAME">
                      <Link
                        to="/user-details"
                        className="modifierDeatil__link"
                      >
                        Marc Snyder
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="CONTACT">
                      <div>123 456 6679</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Normal User
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="NAME">
                      <Link
                        to="/user-details"
                        className="modifierDeatil__link"
                      >
                        Marc Snyder
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="CONTACT">
                      <div>123 456 6679</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Normal User
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="NAME">
                      <Link
                        to="/user-details"
                        className="modifierDeatil__link"
                      >
                        Marc Snyder
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="CONTACT">
                      <div>123 456 6679</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Normal User
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="NAME">
                      <Link
                        to="/user-details"
                        className="modifierDeatil__link"
                      >
                        Marc Snyder
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="CONTACT">
                      <div>123 456 6679</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Normal User
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="NAME">
                      <Link
                        to="/user-details"
                        className="modifierDeatil__link"
                      >
                        Marc Snyder
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="CONTACT">
                      <div>123 456 6679</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Normal User
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr role="row" className="mat-row">
                    <td className="mat-cell column-1" data-column="NAME">
                      <Link
                        to="/user-details"
                        className="modifierDeatil__link"
                      >
                        Marc Snyder
                      </Link>
                    </td>
                    <td className="mat-cell column-2" data-column="CONTACT">
                      <div>123 456 6679</div>
                    </td>
                    <td
                      className="mat-cell column-3"
                      data-column="TYPE"
                    >
                      Normal User
                    </td>
                    <td className="mat-cell column-5" data-column="ACTION">
                      <div className="table__actions">
                        {/* <div className="onOff-toggle-action">
                        <div className="switches">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="edit-action">
                        <Link to="/editModifier" className="edit-link">
                          <img src={editIcon} alt="edit" width="26" />
                        </Link>
                      </div> */}
                        <div className="right_content">
                          <span className="custom__badge badge__blue table__badge">
                            Active
                          </span>
                          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="dropdown-button-drop-left"
                            ></button>
                            <div className="dropdown-menu">
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item active">
                                  <span>Set as Inactive</span>
                                </Link>
                              </div>
                              <div className="dropdown__innerWrapper">
                                <Link to="#" className="dropdown-item">
                                  <span>Edit</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="table pagination">
                <ul className="pagination justify-content-center justify-content-lg-end">
                  <li className="page-item disabled">
                    <a
                      className="page-link first-child"
                      href="/"
                      tabIndex="-1"
                      aria-disabled="true"
                    >
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="/">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserLandingPage;
