import { createContext, useState } from "react";

const RecipeGuideContext = createContext({
  virtualBrandId: null,
  virtualBrandMenuItemId: null,
  recipeId: null,
  recipeGuideId: null,
  recipeGuideModalStatus: null,
  recipeModalStatus: null,
  selectRecipeModalStatus: null,
  recipeCategoryModalStatus: null,
  recipeCategoryId: null,
  prepStepModalStatus: null,
  recipeGuideMode: null,
  selectedRecipeGuideName: null,
  recipeGuideInfo: null,
  modalExtras: null,
  virtualBrandName: null,
  isConfirmPage: null,
  prepStepSlideShow: null,
  attachmentsModalStatus: null,
  isRecipeAdded: null,
  saveVBId: (brandId) => { },
  saveRecipeId: (recipeId) => { },
  isrecipeAdded: (recipeId) => { },
  saveMenuItemId: (menuItemId) => { },
  resetAllVariables: () => { },
  saveVirtualBrandInfo: (info) => { },
  saveRecipeGuideId: (recipeId) => { },
  recipeGuideModalStatusChange: (status, extras = null) => {},
  prepStepSlideShowChange: (status, extras = null) => {},
  recipeModalStatusChange: (status, extras = null) => { },
  selectRecipeModalStatusChange: (status) => { },
  recipeCategoryModalStatusChange: (status, extras = null) => { },
  saveRecipeCategoryId: (recipeCategoryId) => { },
  prepStepModalStatusChange: (status, extras = null) => { },
  setRecipeGuideMode: (status) => { },
  setRecipeGuideInfo: (info) => { },
  isItConfirmPage: (isConfirm) => {},
  attachmentsModalStatusChange: (status, extras = null) => {},
});

export function RecipeGuideContextProvider(props) {
  const [brandId, setBrandId] = useState(null);
  const [brandMenuItemId, setBrandMenuItemId] = useState(null);
  const [recipeGuideId, setrecipeGuideId] = useState(null);
  const [brandInfo, setbrandInfo] = useState(null);
  const [brandName, setbrandName] = useState(null);
  const [recipeId, setRecipeId] = useState(null);
  const [isRecipeAdded, setisRecipeAdded] = useState(null);
  const [recipeGuideModal, setrecipeGuideModal] = useState(null);
  const [recipeModal, setrecipeModal] = useState(null);
  const [selectRecipeModal, setselectRecipeModal] = useState(null);
  const [modalExtrasProps, setmodalExtrasProps] = useState(null);
  const [recipeCategoryModal, setrecipeCategoryModal] = useState(null);
  const [recipeCategoryId, setrecipeCategoryId] = useState(null);
  const [prepStepModal, setprepStepModal] = useState(null);
  const [recipeGuideMode, setrecipeGuideMode] = useState(null);
  const [recipeGuideName, setrecipeGuideName] = useState(null);
  const [recipeGuideInfo, setrecipeGuideInfo] = useState(null);
  const [isConfirmPage, setisConfirmPage] = useState(null);
  const [prepStepSlideShow, setprepStepSlideShow] = useState(null);
  const [attachmentsModal, setattachmentsModal] = useState(null);

  const virtualBrandIdHandler = (brandId) => setBrandId(brandId);

  const virtualBrandMenuItemIdHandler = (brandMenuItemId) =>
    setBrandMenuItemId(brandMenuItemId);

  const recipeGuideIdHandler = (recipeGuideId) =>
    setrecipeGuideId(recipeGuideId);

  const virtualBrandInfoHandler = (info) => {
    setbrandInfo(info);
    setBrandId(info?.id);
    setbrandName(info?.name);
  };

  const recipeIdHandler = (recipeId) => {
    setRecipeId(recipeId);
  };

  const isrecipeAddedHandler = (recipeId) => {
    setisRecipeAdded(recipeId);
  };

  const recipeModalHandler = (status, extras = null) => {
    setrecipeModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const selectRecipeModalHandler = (status) => {
    setselectRecipeModal(status);
  };

  const recipeCategoryModalHandler = (status, extras = null) => {
    setrecipeCategoryModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const prepStepSlideShowHandler = (status, extras = null) => {
    setprepStepSlideShow(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const recipeGuideRecipeCategoryIdHandler = (recipeCategoryId) =>
    setrecipeCategoryId(recipeCategoryId);

  const prepStepModalHandler = (status, extras = null) => {
    setprepStepModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const recipeGuideModeHandler = (status) => setrecipeGuideMode(status);

  const recipeGuideModalHandler = (status, extras = null) => {
    setrecipeGuideModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const attachmentsModalHandler = (status, extras = null) => {
    setattachmentsModal(status);
    setmodalExtrasProps(extras);
    if (status === false) setmodalExtrasProps(null);
  };

  const recipeGuideInfoHandler = (info) => {
    setrecipeGuideInfo(info);
    setrecipeGuideId(info?.id);
    setrecipeGuideName(info?.title);
  };

  const isItConfirmPageHandler = (isConfirm) => setisConfirmPage(isConfirm);

  const deleteAllVariables = () => {
    setBrandId(null);
    setbrandName(null);
    setBrandMenuItemId(null);
    setrecipeGuideId(null);
    setRecipeId(null);
    setrecipeGuideModal(null);
    setrecipeModal(null);
    setselectRecipeModal(null);
    setrecipeCategoryModal(null);
    setrecipeCategoryId(null);
    setprepStepModal(null);
    setrecipeGuideMode(null);
    setrecipeGuideInfo(null);
    setrecipeGuideName(null);
    setprepStepSlideShow(null);
    setattachmentsModal(null);
    setisRecipeAdded(null);
  };

  // actual context below from here
  const context = {
    virtualBrandId: brandId,
    virtualBrandMenuItemId: brandMenuItemId,
    recipeGuideId: recipeGuideId,
    virtualBrandName: brandName,
    virtualBrandInfo: brandInfo,
    recipeId: recipeId,
    modalExtras: modalExtrasProps,
    recipeGuideModalStatus: recipeGuideModal,
    recipeModalStatus: recipeModal,
    selectRecipeModalStatus: selectRecipeModal,
    recipeCategoryModalStatus: recipeCategoryModal,
    recipeCategoryId: recipeCategoryId,
    prepStepModalStatus: prepStepModal,
    recipeGuideMode: recipeGuideMode,
    selectedRecipeGuideName: recipeGuideName,
    recipeGuideInfo: recipeGuideInfo,
    isConfirmPage: isConfirmPage,
    prepStepSlideShow: prepStepSlideShow,
    attachmentsModalStatus: attachmentsModal,
    isRecipeAdded: isRecipeAdded,
    saveVBId: virtualBrandIdHandler,
    saveMenuItemId: virtualBrandMenuItemIdHandler,
    saveRecipeGuideId: recipeGuideIdHandler,
    saveRecipeId: recipeIdHandler,
    isrecipeAdded: isrecipeAddedHandler,
    resetAllVariables: deleteAllVariables,
    saveVirtualBrandInfo: virtualBrandInfoHandler,
    recipeGuideModalStatusChange: recipeGuideModalHandler,
    recipeModalStatusChange: recipeModalHandler,
    selectRecipeModalStatusChange: selectRecipeModalHandler,
    recipeCategoryModalStatusChange: recipeCategoryModalHandler,
    saveRecipeCategoryId: recipeGuideRecipeCategoryIdHandler,
    prepStepModalStatusChange: prepStepModalHandler,
    setRecipeGuideMode: recipeGuideModeHandler,
    setRecipeGuideInfo: recipeGuideInfoHandler,
    isItConfirmPage: isItConfirmPageHandler,
    prepStepSlideShowChange: prepStepSlideShowHandler,
    attachmentsModalStatusChange:  attachmentsModalHandler
  };

  return (
    <RecipeGuideContext.Provider value={context}>
      {props.children}
    </RecipeGuideContext.Provider>
  );
}

export default RecipeGuideContext;
