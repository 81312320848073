import React, { useState } from "react";

import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";

import "./UploadAssets.scss";
import uploadIcon from "assets/images/upload.svg";
import canceErrorIcon from "assets/images/error-cross-icon.svg";
import errorPleaceholder from "assets/images/errorPleaceholder.svg";
import ImageSrc from "shared/image-src/ImageSrc";
import deleteIcon from "assets/images/delete-icon.svg";

const AssetDropArea = (props) => {
  const dims = props.dimensions;
  const maxFileSize = props.maxSize;
  const maxFileSizeInMB = props.maxSize / (1024 * 1024);
  const formats = props.acceptedFormat;
  const formatText = props.showFormatText;
  const isDisabled = props.disabled;
  const multiple = props?.multiple;
  const type = props?.type;
  const video = props.uploadedImage;

  const [errorFileMsg, seterrorFileMsg] = useState(null);

  const onAcceptingDropFileChange = (file) => {
    seterrorFileMsg(null);
    let uploadingFile = file[0];
    props.onFileChange(uploadingFile);
  };

  const onRejectingDropFileChange = (error) => {
    const { file, errors } = error[0];
    let errorFile = file;
    let message = `${errorFile.name} is invalid`;
    errors.map((e) => (message = message + `, ${e.message}`));
    seterrorFileMsg(message);
  };

  const onDeleteImg = (evt) => {
    evt.stopPropagation();
    props.onDeleteImg();
  }
  
  return (
    <>
      {props.uploadedImage && type !== "files" ? (
        <>
          <div className="menu__image__wrapper text-center mt-0">
            <div className="row">
              <div className="col-12 mb-3 pb-3">
                <div className="inner__wrapper">
                  <div className="inner-content">
                    <div className="banner__image">
                      {type === "video" ? (
                        <video
                          width="100%"
                          key={props.uploadedImage}
                          className="img-fluid"
                          controls
                        >
                          <source src={props.uploadedImage + "#t=0.1"} />
                        </video>
                      ) : (
                        <ImageSrc
                          orgImage={props.uploadedImage}
                          rawImage={props.uploadedRawImage}
                          className="img-fluid"
                        />
                      )}
                    </div>
                    {isDisabled ||
                    props?.compType === "recipeGuideForm" ? null : (
                      <>
                        <Dropzone
                          accept={formats}
                          maxFiles={1}
                          maxSize={maxFileSize}
                          multiple={multiple || false}
                          onDropAccepted={onAcceptingDropFileChange}
                          onDropRejected={onRejectingDropFileChange}
                          disabled={props.isImageUploading || isDisabled}
                          noClick={props.isImageUploading || isDisabled}
                          noDrag={props.isImageUploading || isDisabled}
                          noDragEventsBubbling={
                            props.isImageUploading || isDisabled
                          }
                          noKeyboard={props.isImageUploading || isDisabled}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="custom-file-input"
                                id="coverImage"
                                multiple
                              />

                              {!props.isImageUploading && (
                                <div className="button__wrapper">
                                  <button
                                    type="button"
                                    className="btn btn-blue mr-0"
                                  >
                                    {props.btnText || "Choose File"}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={onDeleteImg}
                                    className="delete_ingredient"
                                  >
                                    <span>
                                      <img
                                        src={deleteIcon}
                                        className="icons"
                                        alt="remove"
                                      />
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </Dropzone>
                        {props.isImageUploading && (
                          <ProgressBar now={100} animated label="uploading" />
                        )}
                        <div className="instructions">
                          <span>
                            Recommended ({dims}), Max {maxFileSizeInMB}MB in{" "}
                            {formatText} format
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Dropzone
            accept={formats}
            maxFiles={1}
            maxSize={maxFileSize}
            multiple={false}
            onDropAccepted={onAcceptingDropFileChange}
            onDropRejected={onRejectingDropFileChange}
            disabled={props.isImageUploading || isDisabled}
            noClick={props.isImageUploading || isDisabled}
            noDrag={props.isImageUploading || isDisabled}
            noDragEventsBubbling={props.isImageUploading || isDisabled}
            noKeyboard={props.isImageUploading}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className={`wrapper ${props.extraClass}`}
                {...getRootProps()}
              >
                <div className="inner-content">
                  <div className="icon">
                    <img src={uploadIcon} alt="upload icon" />
                  </div>
                  <p>Drag and Drop files here</p>
                  <div className="browsefile">
                    <input
                      {...getInputProps()}
                      className="custom-file-input"
                      id="coverImage"
                    />
                  </div>
                  {type === "files" && (
                    <div className="menu__image__wrapper mt-0">
                      <input
                        {...getInputProps()}
                        className="custom-file-input"
                        id="coverImage"
                        multiple
                      />
                      <div className="button__wrapper">
                        {!props.isImageUploading && (
                          <button type="button" className="btn btn-blue">
                            Choose File (s)
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="uploaded__files upload__error d-none">
                    <div className="file">
                      <img src={errorPleaceholder} alt="" />
                    </div>
                    <div className="file__name">
                      <span>Internal server error</span>
                    </div>
                    <div className="item__remove__btn">
                      <button type="button">
                        <img src={canceErrorIcon} alt="" />
                      </button>
                    </div>
                  </div>
                  {props.isImageUploading && (
                    <ProgressBar now={100} animated label="uploading" />
                  )}
                  <div className="instructions">
                    <span>
                      Recommended ({dims}), Max {maxFileSizeInMB}MB in{" "}
                      {formatText} format
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </>
      )}
      {errorFileMsg && (
        <div className="my-2 uploaded__files upload__error">
          <div className="file">
            <img src={errorPleaceholder} alt="error" />
          </div>
          <div className="file__name">
            <span>{errorFileMsg}</span>
          </div>
          <div className="item__remove__btn">
            <button type="button" onClick={() => seterrorFileMsg(null)}>
              <img src={canceErrorIcon} alt="cancel" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

AssetDropArea.propTypes = {
  extraClass: PropTypes.string,
  dimensions: PropTypes.string.isRequired,
  maxSize: PropTypes.number.isRequired,
  acceptedFormat: PropTypes.string.isRequired,
  showFormatText: PropTypes.string.isRequired,
  onFileChange: PropTypes.func,
  isImageUploading: PropTypes.bool,
  uploadedImage: PropTypes.string,
  uploadedRawImage: PropTypes.string,
  type: PropTypes.string
};

export default AssetDropArea;
