import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import backArrowIcon from "assets/images/back-arrow.svg";
import "./Settings.scss";
import Widgets from "./Widgets";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import UserContext from "store/user-context";
import LoaderFull from "../../shared/loader/LoaderFull";
import KitchenFilter from "components/filters/KitchenFilter";
import {
  addRestaurantSettings,
  getDefaultSettings,
  getSettingsByKitchenId,
} from "services/api/settings/index";
import toastify from "shared/toastr";

const SettingsLayout = () => {
  const [isPageLoading, setisPageLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [error, setError] = useState('');
  const [selectedKitchen, setSelectedKitchen] = useState(0);
  const [selectedKitchenId, setSelectedKitchenId] = useState(0);
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const [state, setState] = useState([]);

    useEffect(() => {
      getSettings();
    }, []);

  useEffect(() => {
    if (selectedKitchenId > 0) getRestaurantSettings(selectedKitchenId);
  }, [selectedKitchenId]);

  const getSettings = async () => {
    setisLoading(true);
    await getDefaultSettings()
      .then((res) => {
        setisLoading(false);
        setState(res?.data);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const getRestaurantSettings = async (kitchenId) => {
    setisLoading(true);
    await getSettingsByKitchenId(kitchenId)
      .then((res) => {
        setisLoading(false);
        if (res?.data?.length) {
          setState(res?.data);
        }
      })
      .catch(() => {
        setisLoading(false);
      });
  };

    const onKitchenSelect = (data) => {
      if (data) {
        setSelectedKitchenId(data.value);
        setSelectedKitchen(data.label);
      }
    };

  const onSubmit = async (data) => {
    if (selectedKitchenId > 0) {
      const model = {
        settings: data,
        kitchenId: selectedKitchenId,
      };
      console.log({ submitted: model });
      setisLoading(true);
      await addRestaurantSettings(model).then(
        (res) => {
          setState(data);
          setisLoading(false);
          setisEditMode(false);
          toastify("success", res?.message);
        },
        (_) => {
          setisLoading(false);
        }
      );
    }
    else {
      setError("Please select Restaurant");
    } 
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
      {isLoading ? (
        <LoaderFull />
      ) : (
        <div className="reports__wrapper">
          <div className="orders__page__wrapper">
            <div className="managermodifier__wrapper basic__info__content">
              <div className="container">
                <div className="managermodifier__header">
                  <div className="row justify-content-between">
                    <div className="col-12 mb-3 pb-3 d-none d-md-block">
                      <div className="goBack">
                        <Link
                          to="/dashboard"
                          className="back-btn-red"
                          title="back to home"
                        >
                          <span className="icons">
                            <img src={backArrowIcon} alt="back icon" />
                          </span>
                          Go to Dashboard
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 flex__container">
                      <h1>Settings</h1>
                      <div className="col-md-6 mb-0 pb-0 mt-0 pt-0">
                        <label>
                          Restaurant <span className="mendatory">*</span>
                        </label>
                        <div className="filter__height">
                          <KitchenFilter
                            value={selectedKitchenId}
                            onSelect={onKitchenSelect}
                          />
                          {(!selectedKitchenId && error) && (
                            <div className="invalid-feedback d-block">
                              {error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="sections">
                  <div className="row">
                    <div className="col-12">
                      <Widgets
                        userRole={userRole}
                        data={state}
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        isEditMode={isEditMode}
                        setisEditMode={setisEditMode}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsLayout;
