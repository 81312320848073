import React, { useState } from "react";

import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import dateFormat from "dateformat";

import { ModifierType } from "constants/modifierType";
import {
  changeStatusModifierGroup,
  deleteModifierGroup,
} from "services/api/modifier";
import toastify from "shared/toastr";
import { Modal, Button } from "react-bootstrap";

const ListingRow = (props) => {
  const group = props.modifierGroup;
  const refreshList = props.refreshList;
  const [status, setstatus] = useState(group.isActive);
  const [popupShow, setPopupShow] = useState(false);

  const checkModType = (type) => {
    switch (type) {
      case ModifierType.FOOD:
        return "Food Modifier";
      case ModifierType.DRINK:
        return "Drink Modifier";
      default:
        return "NA";
    }
  };

  const onDelete = async () => {
    await deleteModifierGroup(group.id).then((res) => {
      refreshList();
      toastify("success", res.message);
    },
    (_) => {
      setPopupShow(true);
    });
  };

  const onChangeStatus = async () => {
    await changeStatusModifierGroup(group.id, !status).then((res) => {
      setstatus(!status);
      toastify("success", res.message);
    });
  };

  const truncate = (str) => {
    return str.substring(0, 69) + "...";
  }

  const closePopUpModal= () => {
    setPopupShow(false);
  };

  return (
    <>
    <tr role="row" className="mat-row">
      <td className="mat-cell column-1" data-column="NAME">
        <Link to={"/modifiers/" + group.id} className="modifierDeatil__link">
          {group.name?.length > 70 ? truncate(group.name) : group.name}
        </Link>
      </td>
      <td className="mat-cell column-2" data-column="MODIFIER TYPE">
        <Link to={"/modifiers/" + group.id} className="modifierDeatil__link">
          {checkModType(group.modifierType)}
        </Link>
      </td>
    {/*   <td className="mat-cell column-3" data-column="CREATION DATE">
        <Link to={"/modifiers/" + group.id} className="modifierDeatil__link">
          {group.createdOn
            ? dateFormat(new Date(group.createdOn + "Z"), "mmm dd, hh:MM TT")
            : "NA"}
        </Link>
      </td> */}
      <td className="mat-cell column-4" data-column="LAST MODIFIED">
        <Link to={"/modifiers/" + group.id} className="modifierDeatil__link">
          {group.modifiedOn
            ? dateFormat(new Date(group.modifiedOn + "Z"), "mmm dd, hh:MM TT")
            : "NA"}
        </Link>
      </td>
      <td className="mat-cell column-5" data-column="ACTION">
        <div className="right_content">
          <span
            className={`custom__badge ${status ? "badge__red" : "badge__gray"}`}
          >
            {status ? "Active" : "Inactive"}
          </span>
          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdown-button-drop-left"
            ></button>
            <div className="dropdown-menu">
              <div className="dropdown__innerWrapper">
                <Link
                  to={"/modifiers/" + group.id}
                  className="dropdown-item active"
                >
                  <span>View</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <Link
                  to={"/modifiers/clone/" + group.id}
                  className="dropdown-item"
                >
                  <span>Clone</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <Link
                  to={"/modifiers/edit/" + group.id}
                  className="dropdown-item"
                >
                  <span>Edit</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <button onClick={onDelete} className="dropdown-item">
                  <span>Delete</span>
                </button>
              </div>
              <div className="dropdown__innerWrapper">
                <button onClick={onChangeStatus} className="dropdown-item">
                  <span>Set As {status ? "Inactive" : "Active"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>

      {popupShow && (
        <div className="confirmation__wrapper">
          <Modal
            show={popupShow}
            onHide={closePopUpModal}
            animation={false}
            id="confirmation__modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="second-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="confirmation__heading">{"Delete Modifier"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <p className="mb-0">{"Modifier cannot be deleted as it is being used by Menu Items"}
              </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                onClick={closePopUpModal}
                className="cancel-btn red-btn-ghost btn"
              >
                Cancel
              </Button>
              <Button
                onClick={closePopUpModal}
                className="red-btn btn"
                disabled={props.onLoading}
              >
                {"Ok"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

ListingRow.propTypes = {
  modifierGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    modifierType: PropTypes.number,
    isActive: PropTypes.bool,
    modifiedOn: PropTypes.string,
    createdOn: PropTypes.string,
  }),
  refreshList: PropTypes.func,
};

export default ListingRow;
