import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import LoaderInline from "shared/loader/LoaderInline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updateProvider } from "services/api/provider";
import toastify from "shared/toastr";

const ProvidersEdit = ({
  provider,
  kitchenId,
  getListing,
  modalShow,
  closeModal,
  dropdownProvidersList,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    providerId: Yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      providerId: provider?.id,
    });
  }, [provider]);

  const submitForm = async (data) => {
    setisLoading(true);
    await updateProvider({kitchenId, ...data})
      .then((res) => {
        setisLoading(false);
        getListing();
        toastify("success", res.message);
        closeModal();
      })
      .catch((err) => {
        setisLoading(false);
        toastify("error", err.message);
        closeModal();
      });
  };
  return (
    <Modal
      show={modalShow}
      onHide={closeModal}
      centered
      className="credentials__modal"
      id="provider_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{provider ? "Edit" : "Add"} Provider</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="form-row no-gutters">
            <div className="col-md-12 mb-3 pb-3">
              <label htmlFor="providerId">
                Provider Name <span className="mendatory">*</span>
              </label>
              {dropdownProvidersList && dropdownProvidersList.length ? (
                <>
                  <select
                    className={`form-control ${
                      errors?.providerId ? "is-invalid" : ""
                    }`}
                    {...register("providerId")}
                  >
                    <option value={0}>Select Provider Name</option>
                    {dropdownProvidersList &&
                      dropdownProvidersList.map((list) => (
                        <option value={list?.id}>{list?.name}</option>
                      ))}
                  </select>
                  {errors.providerId?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.providerId?.message}
                    </div>
                  )}
                </>
              ) : null}
            </div>

            <div className="col-md-12 action__wrapper d-flex flex-column-reverse d-md-flex justify-content-between">
              <button
                type="button"
                className="cancel-btn red-btn-ghost btn mr-5"
                onClick={closeModal}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="prelogin-links-btn mb-4"
                disabled={isLoading}
              >
                {isLoading ? <LoaderInline /> : provider ? "Save" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ProvidersEdit;
