import React from "react";
import { Link } from "react-router-dom";
import "../ManageResturants/ManageResturantsDetails.scss";
import backArrowIcon from "../../assets/images/back-arrow.svg";

const ManageDeviceDetails = () => {
  return (
    <div className="modifierDetails__wrapper restaurantDetails__wrapper user__details devices__details">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="goBack">
              <Link to="/manage-devices" className=" back-btn-red">
                <span className="icons">
                  <img src={backArrowIcon} alt="back icon" />
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="main_heading">
              <h1>Device Details</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="details__wrapper">
              <div className="details__wrapper__inner">
                <div className="left_content">
                  <div className="row">
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Restaurant Associated</h5>
                        <p>Kentucky Fried Chicken</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Device ID</h5>
                        <p>RT561729YU</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Device Name</h5>
                        <p>Rico’s Tablet</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Device Type</h5>
                        <p>Tablet</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Device Brand Name</h5>
                        <p>Samsung</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Device Connection Type</h5>
                        <p>Wifi</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>City</h5>
                        <p>Los Angeles</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-md-3 pb-md-4">
                      <div className="detailCard">
                        <h5>Device OS Version</h5>
                        <p>Android 11</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="right_content if__active">
                  <span className="custom__badge badge__red table__badge">
                    Active
                  </span>
                  <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-button-drop-left"
                    ></button>
                    <div className="dropdown-menu">
                      <div className="dropdown__innerWrapper">
                        <Link to="#" className="dropdown-item active">
                          <span>Set as Inactive</span>
                        </Link>
                        <Link to="#" className="dropdown-item active">
                          <span>Edit</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDeviceDetails;
