import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Privacy.scss";
import landingLogo__white from "../../assets/images/landing-logo-white.svg";
import landingLogo__black from "../../assets/images/landing-logo-black.svg";
import redExport__icon from "../../assets/images/red_export_icon.svg";
import section1Image from "../../assets/images/how-it-works-1.png";
import sections3Image from "../../assets/images/how-it-works-3.png";
import section4Image from "../../assets/images/how-it-works-4.svg";
import section3Image from "../../assets/images/section-3-img1.png";
import backArrowIcon from "../../assets/images/back-arrow.svg";
import { getUserDataFromToken } from "services/local-storage";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const Privacy = () => {
  const isLoggedIn = getUserDataFromToken();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let x = ["landingpage__header"];
  if (scrolled) {
    x.push("sticky");
  }
  return (
    <>
      <div className="landingPage__wrapper static__pages__wrapper">
        <header className={x.join(" ")}>
          <div className="container-custom">
            <div className="header__content__wrapper">
              <div className="logo">
                <Link to="/home" className="logo__link" role="button">
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid white__logo"
                  />
                  <img
                    src={landingLogo__black}
                    alt="logo"
                    className="img-fluid black__logo"
                  />
                </Link>
              </div>
              <div className="login__action__wrapper">
                {isLoggedIn ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-link signUp__link w-100 h-100 mx-auto"
                    role="button"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-link signIn__link"
                      role="button"
                    >
                      Login
                    </Link>
                    <Link
                      to="/kitchen/register"
                      className="btn btn-link signUp__link"
                      role="button"
                    >
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        <Subheader mobileExtraClass="staticPages_subheader">
          <div className="goBack mb-3">
            <Link to="/home" className="back-btn-red" title="Go to Dashboard">
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Back
            </Link>
          </div>
        </Subheader>
        <main>
          <div className="privacy__wrapper">
          <div className="container">
            <section className="page__title__section">
              <div className="row">
                <div className="col-12 d-none d-md-block">
                  <div className="goBack">
                    <Link to="/home" className=" back-btn-red">
                      <span className="icons">
                        <img src={backArrowIcon} alt="back icon" />
                      </span>
                      Back to Home
                    </Link>
                  </div>
                </div>
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Privacy Policy</h1>
                  </div>
                </div>
              </div>
            </section>
            <div className="terms__content__wrapper">
              <section className="sections intro__section">
                <h3>Introduction</h3>
                <p>
                  Backhouse Brands, and its parent, subsidiary, and affiliated
                  companies (“Company” or “We”) respect your privacy and are
                  committed to protecting it through our compliance with this
                  policy. This policy describes the types of information we may
                  collect from you or that you may provide when you visit the
                  website backhouse.io (our “Website”) and our practices for
                  collecting, using, maintaining, protecting, and disclosing
                  that information.
                </p>
              </section>
              <section className="sections restrictions__section">
                <p>This policy applies to information we collect:</p>
                <ul>
                  <li>On this Website.</li>
                  <li>
                    In email, text, and other electronic messages between you
                    and this Website.
                  </li>
                  <li>
                    Through mobile and desktop applications you download from
                    this Website, which provide dedicated non-browser-based
                    interaction between you and this Website.
                  </li>
                  <li>
                    When you interact with our advertising and applications on
                    third-party websites and services, if those applications or
                    advertising include links to this policy.
                  </li>
                </ul>
                <div className="mt-3">
                  <p>It does not apply to information collected by:</p>
                  <ul>
                    <li>
                      Us offline or through any other means, including on any
                      other website operated by Company or any third party
                      (including our affiliates and subsidiaries); or
                    </li>
                    <li>
                      Any third party (including our affiliates and
                      subsidiaries), including through any application or
                      content (including advertising) that may link to or be
                      accessible from or on the Website.
                    </li>
                  </ul>
                </div>
                <div className="mt-3">
                  <p>
                    Please read this policy carefully to understand our policies
                    and practices regarding your information and how we will
                    treat it. If you do not agree with our policies and
                    practices, your choice is not to use our Website. By
                    accessing or using this Website, you agree to this privacy
                    policy. This policy may change from time to time (see
                    Changes to Our Privacy Policy). Your continued use of this
                    Website after we make changes is deemed to be acceptance of
                    those changes, so please check the policy periodically for
                    updates.
                  </p>
                </div>
              </section>
              <section className="sections pr__section">
                <h3>Children Under the Age of 18</h3>
                <p>
                  Our Website is not intended for children under 18 years of
                  age. No one under age 18 may provide any personal information
                  to or on the Website. We do not knowingly collect personal
                  information from children under 18. If you are under 18, do
                  not use or provide any information on this Website or through
                  any of its features, register on the Website, make any
                  purchases through the Website, use any of the interactive or
                  public comment features of this Website, or provide any
                  information about yourself to us, including your name,
                  address, telephone number, email address, or any screen name
                  or user name you may use. If we learn we have collected or
                  received personal information from a child under 18 without
                  verification of parental consent, we will delete that
                  information. If you believe we might have any information from
                  or about a child under 18, please contact us at{" "}
                  <a href="mailto:support@backhouse.io">
                    support@backhouse.io.
                  </a>
                </p>
              </section>
              <section className="sections pr__section restrictions__section">
                <h3>Information We Collect About You and How We Collect It</h3>
                <p>
                  We collect several types of information from and about users
                  of our Website, including information:
                </p>
                <ul>
                  <li>
                    By which you may be personally identified, such as name,
                    postal address, e-mail address, telephone number, or any
                    other identifier by which you may be contacted online or
                    offline (“personal information”);
                  </li>
                  <li>
                    That is about you but individually does not identify you;
                    and/or
                  </li>
                  <li>
                    About your internet connection, the equipment you use to
                    access our Website, and usage details.
                  </li>
                </ul>
                <div className="mt-3">
                  <p>We collect this information:</p>
                  <ul>
                    <li>Directly from you when you provide it to us.</li>
                    <li>
                      Automatically as you navigate through the site.
                      Information collected automatically may include usage
                      details, IP addresses, and information collected through
                      cookies, web beacons, and other tracking technologies.
                    </li>
                    <li>
                      From third parties, for example, our business partners.
                    </li>
                  </ul>
                </div>
                <div className="mt-4 subsections">
                  <h4>Information You Provide to Us</h4>
                  <div className="mt-0">
                    <p>
                      The information we collect on or through our Website may
                      include:
                    </p>
                    <ul>
                      <li>
                        Information that you provide by filling in forms on our
                        Website. This includes information provided at the time
                        of registering to use our Website, posting material, or
                        requesting further services. We may also ask you for
                        information from time to time.
                      </li>
                      <li>
                        Records and copies of your correspondence (including
                        email addresses), if you contact us.
                      </li>
                      <li>
                        Your responses to surveys that we might ask you to
                        complete for research purposes.
                      </li>
                      <li>
                        Details of transactions you carry out through our
                        Website and of the fulfillment of your orders. You may
                        be required to provide financial information before
                        placing an order through our Website.
                      </li>
                      <li>Your search queries on the Website.</li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>
                      You also may provide information to be published or
                      displayed (hereinafter, “posted”) on public areas of the
                      Website, or transmitted to other users of the Website or
                      third parties (collectively, “User Contributions”). Your
                      User Contributions are posted on and transmitted to others
                      at your own risk. While we make take steps to protect your
                      personal information, please be aware that no security
                      measures are perfect or impenetrable. Additionally, we
                      cannot control the actions of other users of the Website
                      with whom you may choose to share your User Contributions.
                      Therefore, we cannot and do not guarantee that your User
                      Contributions will not be viewed by unauthorized persons.
                    </p>
                  </div>
                </div>
                <div className="mt-4 subsections">
                  <h4>
                    Information We Collect Through Automatic Data Collection
                    Technologies
                  </h4>
                  <div className="mt-0">
                    <p>
                      As you navigate through and interact with our Website, we
                      may use automatic data collection technologies to collect
                      certain information about your equipment, browsing
                      actions, and patterns, including:
                    </p>
                    <ul>
                      <li>
                        Details of your visits to our Website, including traffic
                        data, location data, logs, and other communication data
                        and the resources that you access and use on the
                        Website.
                      </li>
                      <li>
                        Information about your computer and internet connection,
                        including your IP address, operating system, and browser
                        type.
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>
                      We also may use these technologies to collect information
                      about your online activities over time and across
                      third-party websites or other online services (behavioral
                      tracking).
                    </p>
                  </div>
                </div>
                <div className="mt-4 subsections">
                  <div className="mt-0">
                    <p>
                      The information we collect automatically may include
                      personal information, and we may maintain it or associate
                      it with personal information we collect in other ways or
                      receive from third parties. It helps us to improve our
                      Website and to deliver a better and more personalized
                      service, including by enabling us to:
                    </p>
                    <ul>
                      <li>Estimate our audience size and usage patterns.</li>
                      <li>
                        Store information about your preferences, allowing us to
                        customize our Website according to your individual
                        interests.
                      </li>
                      <li>Speed up your searches.</li>
                      <li>Recognize you when you return to our Website.</li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>
                      The technologies we use for this automatic data collection
                      may include:
                    </p>
                    <ul>
                      <li>
                        Cookies (or browser cookies). A cookie is a small file
                        placed on the hard drive of your computer. You may
                        refuse to accept browser cookies by activating the
                        appropriate setting on your browser. However, if you
                        select this setting you may be unable to access certain
                        parts of our Website. Unless you have adjusted your
                        browser setting so that it will refuse cookies, our
                        system will issue cookies when you direct your browser
                        to our Website.
                      </li>
                      <li>
                        Flash Cookies. Certain features of our Website may use
                        local stored objects (or Flash cookies) to collect and
                        store information about your preferences and navigation
                        to, from, and on our Website. Flash cookies are not
                        managed by the same browser settings as are used for
                        browser cookies. For information about managing your
                        privacy and security settings for Flash cookies, see
                        Choices About How We Use and Disclose Your Information.
                      </li>
                      <li>
                        Web Beacons. Pages of our the Website and our e-mails
                        may contain small electronic files known as web beacons
                        (also referred to as clear gifs, pixel tags, and
                        single-pixel gifs) that permit the Company, for example,
                        to count users who have visited those pages or opened an
                        email and for other related website statistics (for
                        example, recording the popularity of certain website
                        content and verifying system and server integrity).
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className="sections pr__section">
                <h3>
                  Third-Party Use of Cookies and Other Tracking Technologies
                </h3>
                <p>
                  Some content or applications, including advertisements, on the
                  Website are served by third-parties, including advertisers, ad
                  networks and servers, content providers, and application
                  providers. These third parties may use cookies, alone or in
                  conjunction with web beacons or other tracking technologies,
                  to collect information about you when you use our website. The
                  information they collect may be associated with your personal
                  information or they may collect information, including
                  personal information, about your online activities over time
                  and across different websites and other online services. They
                  may use this information to provide you with interest-based
                  (behavioral) advertising or other targeted content.
                </p>
                <p>
                  We do not control these third parties’ tracking technologies
                  or how they may be used. If you have any questions about an
                  advertisement or other targeted content, you should contact
                  the responsible provider directly. For information about how
                  you can opt out of receiving targeted advertising from many
                  providers, see Choices About How We Use and Disclose Your
                  Information.
                </p>
              </section>
              <section className="sections pr__section restrictions__section">
                <h3>How We Use Your Information</h3>
                <p>
                  We use information that we collect about you or that you
                  provide to us, including any personal information:
                </p>
                <ul>
                  <li>To present our Website and its contents to you.</li>
                  <li>
                    To provide you with information, products, or services that
                    you request from us.
                  </li>
                  <li>
                    To fulfill any other purpose for which you provide it.
                  </li>
                  <li>To provide you with notices about your account.</li>
                  <li>
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection.
                  </li>
                  <li>
                    To notify you about changes to our Website or any products
                    or services we offer or provide though it.
                  </li>
                  <li>
                    To allow you to participate in interactive features on our
                    Website.
                  </li>
                  <li>
                    In any other way we may describe when you provide the
                    information.
                  </li>
                  <li>For any other purpose with your consent.</li>
                </ul>
                <div className="mt-3">
                  <p>
                    We may also use your information to contact you about our
                    own and third-parties’ goods and services that may be of
                    interest to you. For more information, see Choices About How
                    We Use and Disclose Your Information.
                  </p>
                  <p>
                    We may use the information we have collected from you to
                    enable us to display advertisements to our advertisers’
                    target audiences. Even though we do not disclose your
                    personal information for these purposes without your
                    consent, if you click on or otherwise interact with an
                    advertisement, the advertiser may assume that you meet its
                    target criteria.
                  </p>
                </div>
              </section>
              <section className="sections pr__section restrictions__section">
                <h3>Disclosure of Your Information</h3>
                <p>
                  We may disclose aggregated information about our users, and
                  information that does not identify any individual, without
                  restriction.
                </p>
                <p>
                  We may disclose personal information that we collect or you
                  provide as described in this privacy policy:
                </p>
                <ul>
                  <li>To our subsidiaries and affiliates.</li>
                  <li>
                    To contractors, service providers, and other third parties
                    we use to support our business.
                  </li>
                  <li>
                    To a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of our assets, whether
                    as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which personal information held by us
                    about our Website users is among the assets transferred.
                  </li>
                  <li>
                    To third parties to market their products or services to
                    you.
                  </li>
                  <li>To fulfill the purpose for which you provide it.</li>
                  <li>
                    For any other purpose disclosed by us when you provide the
                    information.
                  </li>
                  <li>With your consent.</li>
                </ul>
                <div className="mt-3">
                  <p>We may also disclose your personal information:</p>
                  <ul>
                    <li>
                      To comply with any court order, law, or legal process,
                      including to respond to any government or regulatory
                      request.
                    </li>
                    <li>
                      To enforce or apply our Terms of Use and other agreements,
                      including for billing and collection purposes.
                    </li>
                    <li>
                      If we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of us, our
                      customers, or others.
                    </li>
                  </ul>
                </div>
              </section>
              <section className="sections pr__section restrictions__section">
                <h3>Choices About How We Use and Disclose Your Information</h3>
                <p>
                  We strive to provide you with choices regarding the personal
                  information you provide to us. We have created mechanisms to
                  provide you with the following control over your information:
                </p>
                <ul>
                  <li>
                    Tracking Technologies and Advertising. You can set your
                    browser to refuse all or some browser cookies, or to alert
                    you when cookies are being sent. To learn how you can manage
                    your Flash cookie settings, visit the Flash player settings
                    page on Adobe’s website. If you disable or refuse cookies,
                    please note that some parts of this site may then be
                    inaccessible or not function properly.
                  </li>
                  <li>
                    Promotional Offers from the Company. If you do not wish to
                    have your email address or other contact information used by
                    the Company to promote our own or third parties’ products or
                    services, you can opt-out by sending us an email stating
                    your request to support@backhouse.io. If we have sent you a
                    promotional email, you may send us a return email asking to
                    be omitted from future email distributions. This opt out
                    does not apply to information provided to the Company as a
                    result of a product purchase, warranty registration, product
                    service experience, or other transactions.
                  </li>
                </ul>
                <div className="mt-3">
                  <p>
                    We do not control third parties’ collection or use of your
                    information to serve interest-based advertising. However
                    these third parties may provide you with ways to choose not
                    to have your information collected or used in this way. You
                    can opt out of receiving targeted ads from members of the
                    Network Advertising Initiative (“NAI”) on the NAI’s website.
                  </p>
                </div>
              </section>
              <section className="sections pr__section">
                <h3>Data Security</h3>
                <p>
                  The safety and security of your information depends on you.
                  Where we have given you (or where you have chosen) a password
                  for access to certain parts of our Website, you are
                  responsible for keeping this password confidential. We ask you
                  not to share your password with anyone. We urge you to be
                  careful about giving out information in public areas of the
                  Website like message boards. The information you share in
                  public areas may be viewed by any user of the Website.
                </p>
                <p>
                  Unfortunately, the transmission of information via the
                  internet is not completely secure. Although we do our best to
                  protect your personal information, we cannot guarantee the
                  security of your personal information transmitted to our
                  Website. Any transmission of personal information is at your
                  own risk. We are not responsible for circumvention of any
                  privacy settings or security measures contained on the
                  Website.
                </p>
              </section>
              <section className="sections pr__section">
                <h3>Changes to Our Privacy Policy</h3>
                <p>
                  It is our policy to post any changes we make to our privacy
                  policy on this page. The date the privacy policy was last
                  revised is identified at the top of the page. You are
                  responsible for periodically visiting our Website and this
                  privacy policy to check for any changes.
                </p>
              </section>
              <section className="sections pr__section">
                <h3>Contact Information</h3>
                <p>
                  To ask questions or comment about this privacy policy and our
                  privacy practices, contact us at:
                </p>
                <div className="privacy__address">
                  <p>Backhouse Brands <br/> 26 32nd Ave S <br/> St Cloud, MN 56301</p>
                  <p><a href="mailto:support@backhouse.io">Email: support@backhouse.io</a></p>
                  <p><a href="tel:(800) 599-5770">Phone: (800) 599-5770</a></p>
                </div>
              </section>
            </div>
          </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Privacy;
