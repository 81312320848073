import React from "react";
import "./Loader.scss";
import PropTypes from "prop-types";

const LoaderBlock = ({ isTextNeeded = true }) => {
  return (
    <div className="loader__block my-5">
      <div className="preloader-wrapper">
        <div className="loader">
          <div className="lds-roller">
            <div></div>
            {isTextNeeded ? <p>Loading...</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

LoaderBlock.propTypes = {
  isTextNeeded: PropTypes.bool,
};

export default LoaderBlock;
