import { createContext, useState } from "react";
import { getUserDataFromToken } from "services/local-storage";

const UserContext = createContext({
  userInfo: getUserDataFromToken(),
  userRole: getUserDataFromToken()?.role,
  setUserInfo: (user) => {},
  removeUserInfo: (user) => {},
});

export function UserContextProvider(props) {
  const [userData, setuserData] = useState(getUserDataFromToken());
  const [userRoleData, setuserRoleData] = useState(
    getUserDataFromToken()?.role
  );

  const userInfoHandler = (user) => {
    setuserData((us) => ({ ...us, ...user }));
    if (user?.role) setuserRoleData(user.role);
  };

  const removeUserInfoHandler = () => {
    setuserData(null);
    setuserRoleData(null);
  };

  const context = {
    userInfo: userData,
    userRole: userRoleData,
    setUserInfo: userInfoHandler,
    removeUserInfo: removeUserInfoHandler,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
