import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./RecipeForm.scss";
import warningIcon from "assets/images/warningIcon.svg";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import { Global } from "constants/global";
import { AssetType } from "constants/assetType";
import uploadFileToBlob from "services/azure-blob";
import Ranger from "UI/Ranger/ProgressBar";

const RecipeDetailsForm = (props) => {
    const recipeVideoSize = Global.twoHunMB;
    const data = props.initialData;
    const isDisabled = props.disabled;
    const minShelfLife = 5;
    const maxShelfLife = 30;
    const minPrepTime = 5;
    const maxPrepTime = 90;
    const [prepTimeValue, setprepTimeValue] = useState(minPrepTime);
    const [shelfLifeValue, setshelfLifeValue] = useState(minShelfLife);
    const [recipeVideo, setrecipeVideo] = useState(null);
    const [isrecipeVideoUploading, setisrecipeVideoUploading] = useState(false);
    
    useEffect(() => {
        return () => props.onChangeTab(getValues());
    }, []);

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .trim()
            .max(120, "Recipe title not more than 120 characters")
            .required("Please enter recipe title"),
        recipeVideoUrl: Yup.string().trim(),
        description: Yup.string()
            .trim()
            .max(500, "Recipe description not more than 500 characters")
            .required("Please enter recipe description"),
      /*   preparationTime: Yup.number()
            .positive()
            .min(minPrepTime)
            .max(maxPrepTime)
            .required("Preparation time is required"),
        shelfLife: Yup.number()
            .positive()
            .min(minShelfLife)
            .max(maxShelfLife)
            .required("Shelf Life is required"), */
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            prepTimeInMinutes: minPrepTime,
            shelfLifeInDays: minShelfLife,
        },
    });

    const {
        register,
        setValue,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isValid },
    } = methods;

    useEffect(() => {
        if (data) {
            reset({
                title: data?.title,
                description: data?.description,
                recipeVideoUrl: data?.recipeVideoUrl || "",
                prepTimeInMinutes: data.prepTimeInMinutes,
                shelfLifeInDays: data.shelfLifeInDays
            });
            setrecipeVideo({ filePath: data?.recipeVideoUrl });
            setprepTimeValue(data.prepTimeInMinutes);
            setshelfLifeValue(data.shelfLifeInDays);
        }
    }, [data]);

    const uploadRecipeVideo = async (file) => {
        setisrecipeVideoUploading(true);
        await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
            (res) => {
                setrecipeVideo(res);
                setisrecipeVideoUploading(false);
                setValue("recipeVideoUrl", res?.fileName, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                });
            },
            () => {
                setrecipeVideo(null);
                setisrecipeVideoUploading(false);
            }
        );
    };

    const onSubmit = (data) => {
        props.onSave(data);
    };

    const onPrepTimeChange = (val) => {
        setValue("prepTimeInMinutes", val, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        setprepTimeValue(val);
    };

    const onShelfLifeChange = (val) => {
        setValue("shelfLifeInDays", val, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        setshelfLifeValue(val);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="createMenu__wrapper uploadAssets__wrapper basic__info__content CreateMenuModalContent__wrapper">
                <div className="uploadAssets-wrapper">
                    <div className="warning__wrapper mb-3 pb-3">
                        {errors && (
                            <div className="warning__box">
                                <p>
                                    <span className="icons">
                                        <img src={warningIcon} alt="" />
                                    </span>
                                    In order to continue to next screen you should fill all
                                    mandatory fields
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="menuTitle">
                                Recipe Title<span className="mendatory">*</span>
                            </label>
                            {isDisabled ? <div className="view_form_input">{data?.title || "NA"}</div>
                                : <input
                                    type="text"
                                    id="menuTitle"
                                    placeholder="Enter Title Name"
                                    className={`form-control ${errors?.title ? "is-invalid" : ""}`}
                                    {...register("title")}
                                    maxLength="120"
                                    disabled={isDisabled}
                                />}
                            {errors?.title?.message && (
                                <div className="invalid-feedback d-block">
                                    {errors?.title?.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                            <label htmlFor="menuDescription">
                                Recipe Description<span className="mendatory">*</span>
                            </label>
                            {isDisabled ? <div className="view_form_input">{data?.description || "NA"}</div>
                                : <textarea
                                    id="menuDescription"
                                    placeholder="e.g. Deep friend wiscosin cheese curds"
                                    rows="3"
                                    className={`form-control ${errors?.description ? "is-invalid" : ""
                                        }`}
                                    {...register("description")}
                                    maxLength="500"
                                    disabled={isDisabled}
                                ></textarea>}
                            {errors?.description?.message && (
                                <div className="invalid-feedback d-block">
                                    {errors?.description?.message}
                                </div>
                            )}
                        </div>

                        <div className="col-12 mb-3 pb-3">
                            <label htmlFor="recipeVideoUrl">Recipe Video</label>
                            <AssetDropArea
                                dimensions="640x360"
                                maxSize={recipeVideoSize}
                                acceptedFormat="video/mp4, video/webm, video/mov"
                                showFormatText="MP4, WEBM, MOV"
                                onFileChange={uploadRecipeVideo}
                                isImageUploading={isrecipeVideoUploading}
                                uploadedImage={recipeVideo?.filePath}
                                uploadedRawImage={recipeVideo?.filePath}
                                disabled={isDisabled}
                                type="video"
                                btnText={"Change Video"}
                            />
                            {errors?.recipeVideoUrl?.message && (
                                <div className="invalid-feedback d-block">
                                    {errors?.recipeVideoUrl?.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                            <div className="form-group">
                                <label htmlFor="prepTimeInMinutes">
                                    Prep Time
                                </label>
                                <Ranger
                                    value={prepTimeValue}
                                    setRadius={onPrepTimeChange}
                                    minDistance={minPrepTime}
                                    maxDistance={maxPrepTime}
                                    label="Min"
                                    disabled={isDisabled}
                                />
                                {errors?.prepTimeInMinutes?.message && (
                                    <div className="invalid-feedback d-block">
                                        {errors?.prepTimeInMinutes?.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-12 mb-3 pb-3">
                            <div className="form-group">
                                <label htmlFor="shelfLifeInDays">
                                    Shelf Life
                                </label>
                                <Ranger
                                    value={shelfLifeValue}
                                    setRadius={onShelfLifeChange}
                                    minDistance={minShelfLife}
                                    maxDistance={maxShelfLife}
                                    label="Days"
                                    disabled={isDisabled}
                                />
                                {errors?.shelfLifeInDays?.message && (
                                    <div className="invalid-feedback d-block">
                                        {errors?.shelfLifeInDays?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal.Footer>
                {props.children}

                <button
                    type="button"
                    onClick={props.onHide}
                    className="cancel-btn red-btn-ghost btn"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="red-btn next-btn btn"
                    disabled={!isValid}
                >
                    Next
                    <span className="icons-right">
                        <img src={rightArrowIcon} alt="arrow icon" />
                    </span>
                </button>
            </Modal.Footer>
        </form>
    );
};

export default RecipeDetailsForm;
