import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import "./ConfirmationPopUp.scss";
import rightArrowIcon from "../../assets/images/white-right-arrow-btn.svg";

const ConfirmationPopUp = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="confirmation__wrapper">
      <Button variant="primary" onClick={handleShow}>
        confirmation modal button
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        id="confirmation__modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="confirmation__heading">
            Review and Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <p className="mb-0">Do you want to submit your Menu for review?</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            onClick={handleClose}
            className="cancel-btn red-btn-ghost btn"
          >
            Cancel
          </Button>
          <Button onClick={handleClose} className="red-btn btn">
            Submit for Review
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationPopUp;
