import React, { useState, useEffect } from "react";

import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// import "../view/ManageResturantsDetails.scss";
import "./DeviceForm.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import LoaderInline from "shared/loader/LoaderInline";
import LoaderFull from "shared/loader/LoaderFull";
import toastify from "shared/toastr";
import { getActiveKitchens } from "services/api/kitchen";
import { addDevice, editDevice, getDevice } from "services/api/device";
import { DeviceTypeDropdown } from "constants/deviceType";
import { DeviceConnectionDropdown } from "constants/deviceConnectionType";
import { SelectDropdownSearch } from "UI/Select-Dropdown/SelectDropdownSearch";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const DeviceForm = (props) => {
  const { deviceId } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);

  const [kitchens, setKitchens] = useState([]);
  const [selectedKitchen, setselectedKitchen] = useState(null);
  const [isKitchensLoading, setisKitchensLoading] = useState(false);

  useEffect(() => {
    if (deviceId) {
      setisEditMode(true);
      getDetail(deviceId);
    }
  }, [deviceId]);

  useEffect(() => {
    getKitchensForDD();
  }, []);

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    kitchenId: Yup.number(),
      //.min(1, () => `Please select Restaurant`),
      //.required("Please select Restaurant"),
    deviceId: Yup.string()
      .trim()
      .max(50, ({ max }) => `Device Id not more than ${max} characters`)
      .required("Please enter device id"),
    deviceName: Yup.string()
      .trim()
      .max(50, ({ max }) => `Device name not more than ${max} characters`)
      .required("Please enter device name"),
    deviceType: Yup.number().required(),
    deviceConnection: Yup.number().required(),
    deviceOs: Yup.string()
      .trim()
      .max(50, ({ max }) => `Device name not more than ${max} characters`)
      .required("Please enter device OS"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const getKitchensForDD = async () => {
    setisKitchensLoading(true);
    await getActiveKitchens()
      .then((res) => {
        let data = res.data;
        if (data && data.length) {
          let kitchens = data.map((res) => ({
            value: res.id,
            label: res.name,
          }));
          setKitchens(kitchens);
        }
        setisKitchensLoading(false);
      })
      .catch(() => setisKitchensLoading(false));
  };

  const getDetail = async (id) => {
    setisLoading(true);

    await getDevice(id)
      .then((res) => {
        let data = res.data;
        reset({
          id: data?.id,
          kitchenId: "" + data?.kitchen?.id,
          deviceId: data.deviceId,
          deviceName: data?.name,
          deviceType: "" + data?.type,
          deviceConnection: "" + data?.connectionType,
          deviceOs: data?.os,
        });
        setselectedKitchen(data?.kitchen?.id);
        setValue("kitchenId", data?.kitchen?.id);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.goBack();
  };

  const onSubmit = async (data) => {
    setisSubmitting(true);

    if (isEditMode) {
      await editDevice(data)
        .then((res) => {
          toastify("success", res.message);
          setisSubmitting(false);
          onBack();
        })
        .catch(() => {
          setisSubmitting(false);
        });
    } else {
      await addDevice(data)
        .then((res) => {
          toastify("success", res.message);
          setisSubmitting(false);
          onBack();
        })
        .catch(() => {
          setisSubmitting(false);
        });
    }
  };

  const onKitchenValueSelected = (val) => {
    setValue("kitchenId", val.value);
    setselectedKitchen(val.value);
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button onClick={onBack} className="back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      <div className="users__wrapper">
        <div className="modifierDetails__wrapper modifierEdit__wrapper">
          {isLoading && <LoaderFull />}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="goBack">
                  <button onClick={onBack} className=" back-btn-red">
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="main_heading">
                  <h1>{isEditMode ? "Edit" : "Add"} Devices</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="details__wrapper editModifier__wrapper">
                  <div className="details__wrapper__inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="basic__info__content">
                        <div className="form-row">
                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="fullname">
                              Restaurant Associated
                              <span className="mendatory">*</span>
                            </label>
                            {kitchens && (
                              <>
                              <SelectDropdownSearch
                                options={kitchens}
                                placeholder="No Restaurant Selected"
                                selectedValue={onKitchenValueSelected}
                                value={selectedKitchen}
                                searchPlaceholder="Search Restaurant"
                                {...register("kitchenId")}
                                className={`form-control ${
                                  errors.kitchenId || !selectedKitchen ? "is-invalid" : ""
                                }`}
                              />
                                {(errors.kitchenId?.message && !selectedKitchen) &&(
                                  <div className="invalid-feedback d-block">
                                    {errors.kitchenId?.message}
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          <div class="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="deviceID">
                              Device ID
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="deviceID"
                              placeholder="e.g. RT561729YU"
                              maxLength="50"
                              {...register("deviceId")}
                              className={`form-control ${
                                errors.deviceId ? "is-invalid" : ""
                              }`}
                            />
                            {errors.deviceId?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.deviceId?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="deviceName">
                              Device Name
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="deviceName"
                              placeholder="e.g. Samsung Tablet S7"
                              maxLength="50"
                              {...register("deviceName")}
                              className={`form-control ${
                                errors.deviceName ? "is-invalid" : ""
                              }`}
                            />
                            {errors.deviceName?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.deviceName?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="mandatory">
                              Device Type
                              <span className="mendatory">*</span>
                            </label>
                            <div class="check__wrapper">
                              {DeviceTypeDropdown &&
                                DeviceTypeDropdown.map((type) => (
                                  <label class="check-container mb-0">
                                    {type.text}
                                    <input
                                      type="radio"
                                      value={type.value}
                                      {...register("deviceType")}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                ))}
                            </div>
                            {errors.deviceType?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.deviceType?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="mandatory">
                              Device Connection Type
                              <span className="mendatory">*</span>
                            </label>
                            <div class="check__wrapper">
                              {DeviceConnectionDropdown &&
                                DeviceConnectionDropdown.map((type, idx) => (
                                  <label key={idx} class="check-container mb-0">
                                    {type.text}
                                    <input
                                      type="radio"
                                      value={type.value}
                                      {...register("deviceConnection")}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                ))}
                            </div>
                            {errors.deviceConnection?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.deviceConnection?.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 mb-3 pb-3 px-0">
                            <label htmlFor="deviceOSversion">
                              Device OS Version
                              <span className="mendatory">*</span>
                            </label>
                            <input
                              type="text"
                              id="deviceOSversion"
                              placeholder="e.g. Android 11"
                              maxLength="50"
                              {...register("deviceOs")}
                              className={`form-control ${
                                errors.deviceOs ? "is-invalid" : ""
                              }`}
                            />
                            {errors.deviceOs?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.deviceOs?.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 px-0">
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={onBack}
                              className="cancel-btn red-btn-ghost btn"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="red-btn next-btn btn"
                              disabled={isSubmitting || !isValid || !selectedKitchen}
                            >
                              {isSubmitting ? <LoaderInline /> : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceForm;
