import { privateApi } from "../utilities/Provider";

const basicUrl = "/";

const getProvidersDropdownList = () => {
  return privateApi.get(basicUrl + "order/deliveryproviders");
};

const getProvidersList = ({ pageSize, pageNum, kitchenId }) => {
  const params = new URLSearchParams();
  if (kitchenId) params.append("id", kitchenId);
  if (pageNum) params.append("pagenum", pageNum);
  if (pageSize) params.append("pagesize", pageSize);
  return privateApi.get(basicUrl + "kitchen/kitchendeliveryproviderlist", { params });
};

const updateProvider = (model) => {
  let body = {
    id: model?.providerId,
    kitchenId: model?.kitchenId,
  };
  return privateApi.put(basicUrl + "kitchen/updatedeliveryprovidertokitchen", body);
};

const deleteProvider = (kitchenId) => {
  return privateApi.delete(basicUrl + "kitchen/deletekitchendeliveryprovider", {
    params: {
      kitchenId: kitchenId,
    },
  });
};

export {
  getProvidersList,
  updateProvider,
  getProvidersDropdownList,
  deleteProvider,
};
