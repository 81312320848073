import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import "./CustomerDetails.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import totalOrders from "assets/images/total_Orders.svg";
import totalRevenue from "assets/images/revenue.svg";
import avgValue from "assets/images/avg_Value.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import LoaderBlock from "shared/loader/LoaderBlock";
import { numberFormat } from "constants/numberFormat";
import ListingRow from "../../order/listing/ListingRow";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import { getCustomerDetails } from "services/api/customer";
import dateFormat from "dateformat";

const CustomerDetails = (props) => {
    const { customerId } = useParams();
    const [isLoading, setisLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [allOrders, setAllOrders] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        if (customerId) {
            if (parseInt(customerId) > 0) {
                getDetail(+customerId);
            }
        }
    }, [customerId]);
    const getDetail = async (id) => {
         setisLoading(true);
         await getCustomerDetails(id)
           .then((res) => {
             setCustomer(res?.data?.customerDetailsForKitchenData[0]);
             setAllOrders(res?.data?.customerOrderHistoryData);
             setTotalRecords(4);
             setisLoading(false);
           })
           .catch(() => {
             setCustomer(null);
             setisLoading(false);
             onBack();
           });
    };
    const onBack = () => {
        props.history.goBack();
    };
    return (
      <>
        <Subheader>
          <div className="goBack mb-3">
            <button onClick={onBack} className="back-btn-red">
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Back
            </button>
          </div>
        </Subheader>

        <div className="modifierDetails__wrapper restaurantDetails__wrapper">
          <div className="container">
            <div className="row mb-4 pb-4">
              <div className="col-12">
                <div className="goBack">
                  <button
                    type="button"
                    onClick={onBack}
                    className=" back-btn-red"
                  >
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </button>
                </div>
              </div>

              <div className="container1">
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Customer Details</h1>
                  </div>
                </div>
                <div className="col-12">
                  <div className="details__wrapper">
                    {isLoading ? (
                      <LoaderBlock />
                    ) : (
                      <div className="details__wrapper__inner">
                          <div className="row justify-content-between w-100">
                            <div className="col-12 col-md-6 w-50">
                              <div className="detailCard">
                                <div className="mb-3 pb-md-4">
                                  <h5>Full Name</h5>
                                  <p className="mb-0">
                                    {customer?.customer || "NA"}
                                  </p>
                                </div>
                                <div className="mb-3 pb-md-4">
                                  <h5>Contact No</h5>
                                  <p className="mb-0">
                                    {customer?.phone || "NA"}
                                  </p>
                                </div>
                                <div className="mb-3 pb-md-4">
                                  <h5>Address Line 1</h5>
                                  <p className="mb-0">
                                    {customer?.addressLine1 || "NA"}
                                  </p>
                                </div>
                                <div className="mb-3 pb-md-4">
                                  <h5>Creation Date</h5>
                                  {customer?.creationDate ? (
                                    <p className="m-0">
                                      {dateFormat(
                                        new Date(customer?.creationDate + "Z"),
                                        "mmm dd, yyyy"
                                      )}
                                    </p>
                                  ) : (
                                    "NA"
                                  )}
                                </div>
                                <div className="mb-3 mb-md-0">
                                  <h5>City</h5>
                                  <p className="mb-0">
                                    {customer?.city || "NA"}
                                  </p>
                                </div>
                                {/*  <div className="mb-3 pb-md-4">
                                  <h5>Country</h5>
                                  <p className="mb-0">
                                    {customer?.phone || "NA"}
                                  </p>
                                </div> */}
                              </div>
                            </div>

                            <div className="col-12 col-md-6 w-50">
                              <div className="detailCard">
                                <div className="mb-3 pb-md-4">
                                  <h5>State</h5>
                                  <p className="mb-0">
                                    {customer?.state || "NA"}
                                  </p>
                                </div>
                                <div className="mb-3 pb-md-4">
                                  <h5>Zip</h5>
                                  <p className="mb-0">
                                    {customer?.zip || "NA"}
                                  </p>
                                </div>
                                <div className="mb-3 mb-md-0">
                                  <h5>Address Line 2</h5>
                                  <p className="mb-0">
                                    {customer?.addressLine2 || "NA"}
                                  </p>
                                </div>
                                {/*  <div className="mb-3 pb-md-4">
                                  <h5>State</h5>
                                  <p className="mb-0">{customer?.state || "NA"}</p>
                                </div> */}
                              {/*   <h5>Account Email</h5>
                                <p className="mb-0">{customer?.email || "NA"}</p> */}
                              </div>
                            </div>
                          </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="container2 statistics_container">
                <div className="col-12">
                  <div className="main_heading">
                    <h1>Statistics</h1>
                  </div>
                </div>
                <div className="col-12">
                  <div className="statistics__card__wrapper">
                    {isLoading ? (
                      <LoaderBlock />
                    ) : (
                      <div className="statistics__card__wrapper__inner">
                        {/* <div className="left_content"> */}
                        <div className="summary__card">
                          <div className="summary__card__data">
                            <div className="summary__card__data__left">
                              <p className="label">
                                Total <br /> Orders
                              </p>
                              <p className="label__data">
                                {customer?.totalOrders || 0}
                              </p>
                            </div>
                            <div className="summary__card__data__right">
                              <div className="img-box">
                                <img src={totalOrders} alt="total orders" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="summary__card">
                          <div className="summary__card__data">
                            <div className="summary__card__data__left">
                              <p className="label">
                                Total <br />
                                Sales
                              </p>
                              <p className="label__data">
                                {numberFormat(customer?.totalSales || 0)}
                              </p>
                            </div>
                            <div className="summary__card__data__right">
                              <div className="img-box">
                                <img src={totalRevenue} alt="total orders" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="summary__card">
                          <div className="summary__card__data">
                            <div className="summary__card__data__left">
                              <p className="label">
                                Average Order <br /> Value
                              </p>
                              <p className="label__data">
                                {numberFormat(customer?.averageSales || 0)}
                              </p>
                            </div>
                            <div className="summary__card__data__right">
                              <div className="img-box">
                                <img src={avgValue} alt="total orders" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="orders__page__wrapper order_history_wrapper">
              <div className="managermodifier__wrapper basic__info__content">
                <div className="container p-0">
                  <div className="col-12">
                    <div className="main_heading">
                      <h1>Order History</h1>
                    </div>
                  </div>
                  {allOrders && totalRecords === 0 && !isLoading ? (
                    <div className="managermodifier__noListing">
                      <div className="managermodifier__noListing__innerwrapper">
                        <div className="content">
                          <div className="img-box">
                            <img
                              className="img-fluid"
                              src={emptyIocn}
                              alt="no list icon"
                              width="185"
                            />
                          </div>
                          <div className="content-desc">
                            <h3>No Orders Found</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="managermodifier__listinPage orderListing__listinPage">
                      <div className="managermodifier__listinPage__wrapper">
                        <table className="table modifierListing__table ordersListing-table">
                          <thead>
                            <tr role="row" className="mat-header-row justify-content-start">
                              <th
                                scope="col"
                                className="mat-header-cell column-1"
                              >
                                Customer
                              </th>
                              <th
                                scope="col"
                                className="mat-header-cell column-2"
                              >
                                Order
                              </th>
                              <th
                                scope="col"
                                className="mat-header-cell column-3"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="mat-header-cell column-4"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="mat-header-cell column-5"
                              >
                                Driver
                              </th>
                              <th
                                scope="col"
                                className="mat-header-cell column-6"
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <tr>
                                <td>
                                  <LoaderBlock />
                                </td>
                              </tr>
                            ) : (
                              allOrders &&
                              allOrders?.map((order, idx) => (
                                <ListingRow
                                  order={order}
                                  key={idx}
                                  updatedStatus={
                                    /*  latestOrderStatus?.orderId === order.id
                                ? latestOrderStatus
                                : */ null
                                  }
                                  screen={"customer"}
                                />
                              ))
                            )}
                          </tbody>
                        </table>
                        {/*  <div className="row">
                    <div className="col-12">
                      <Paginator
                        totalCount={totalRecords}
                        currentPage={pageNum}
                        pageSize={pageSize}
                        pageClick={onPageChange}
                      />
                    </div>
                  </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default CustomerDetails;