import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ChangeStatus from "./ChangeStatus";
import dateFormat from "dateformat";
import brandLogoImage from "assets/images/placeholder-logo.png";

const RecipeGuideListingRow = (props) => {
  const recipeGuide = props.recipeGuide;
  const reloadListing = props.onReloadList;
  const pic = recipeGuide?.imageUrl;
  const brandId = props?.brandId;

  return (
    <tr role="row" className="mat-row">
      <td className="mat-cell column-1" data-column="NAME">
        <Link to={`/recipeguide/${brandId}/${recipeGuide?.id}#view`} className="modifierDeatil__link d-inline-block w-100">
          <div className="customer-data mt-1 mt-lg-0">
            <div className="img-box">
              <img
                src={pic ? pic : brandLogoImage}
                alt="icon"
                className="recipeGuideImgClass img-fluid"
              />
            </div>
            <div className="rgcontent-box">
              <p>{recipeGuide?.title || "NA"}</p>
            </div>
          </div>
        </Link>
      </td>
      <td className="mat-cell column-2" data-column="# OF RECIPES">
        <Link to={`/recipeguide/${brandId}/${recipeGuide?.id}#view`} className="modifierDeatil__link">
          <div>{recipeGuide?.recipesCount || 0}</div>
        </Link>
      </td>
      <td className="mat-cell column-3" data-column="LAST MODIFIED">
        <Link to={`/recipeguide/${brandId}/${recipeGuide?.id}#view`} className="modifierDeatil__link">
          {recipeGuide.modifiedAt ? dateFormat(new Date(recipeGuide.modifiedAt + "Z"), "mmm dd, hh:MM TT")
            : "NA"}
        </Link>
      </td>
      <td className="mat-cell column-5" data-column="ACTION">
        <div className="table__actions ">
          <ChangeStatus
            recipeGuideId={recipeGuide?.id}
            status={props?.isActive}
            onActionComplete={reloadListing}
            brandId={brandId}
          />
        </div>
      </td>
    </tr>
  );
};

RecipeGuideListingRow.propTypes = {
  recipeGuide: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    count: PropTypes.number,
    isActive: PropTypes.bool,
    lastModified: PropTypes.string
  }).isRequired,
  isActive: PropTypes.bool,
  brandId: PropTypes.number,
};

export default RecipeGuideListingRow;
