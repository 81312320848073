import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import { deleteRecipeGuide, changeRecipeGuideStatus } from "services/api/recipeguide";
import DeleteModal from "UI/Modal/DeleteModal";

const ChangeStatus = (props) => {
  const [currentStatus, setcurrentStatus] = useState(props?.status);
  const [isLoading, setisLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const onStatusChange = async () => {
    setisLoading(true);
    await changeRecipeGuideStatus(props?.recipeGuideId, !currentStatus).then((res) => {
      setcurrentStatus(!currentStatus);
      toastify("success", res.message);
      setisLoading(false);
    });
  };

  const openDeleteModal = () => setdeleteModal(true);
  const closeDeleteModal = () => setdeleteModal(false);

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteRecipeGuide(props?.recipeGuideId)
      .then((res) => {
        toastify("success", res.message);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onActionComplete();
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  return (
    <>
      <div className="right_content">
        <span
          className={`custom__badge ${currentStatus ? "badge__red" : "badge__gray"
            }`}
        >
          {isLoading ? <LoaderInline /> : currentStatus ? "Active" : "Inactive"}
        </span>
          <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdown-button-drop-left"
            ></button>
            <div className="dropdown-menu">
              <div className="dropdown__innerWrapper">
                <Link to={`/recipeguide/${props?.brandId}/${props?.recipeGuideId}#view`} className="dropdown-item">
                  <span>View RecipeGuide</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <Link to={`/recipeguide/${props?.brandId}/${props?.recipeGuideId}#edit`} className="dropdown-item">
                  <span>Edit RecipeGuide</span>
                </Link>
              </div>
              <div className="dropdown__innerWrapper">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => onStatusChange()}
                >
                  <span>Set As {!currentStatus ? "Active" : "Inactive"}</span>
                </button>
              </div>
              <div className="dropdown__innerWrapper">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={openDeleteModal}
                >
                  <span>Delete RecipeGuide</span>
                </button>
              </div>
            </div>
          </div>
      </div>

      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          title="Delete Recipe Guide"
          subtitle="Are you sure want to delete this Recipe Guide"
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

ChangeStatus.propTypes = {
  recipeGuideId: PropTypes.number.isRequired,
  status: PropTypes.bool.isRequired,
  onActionComplete: PropTypes.func,
  brandId: PropTypes.any.isRequired,
};

export default ChangeStatus;
