import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import "../Onboarding.scss";
import congrats from "assets/images/green-check-icon.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import LoaderBlock from "shared/loader/LoaderBlock";
import { validateUser } from "services/api/auth";
import { redirectToHome } from "shared/navigation-utils/index";

const KitchenRegisterComplete = (props) => {
  const search = useLocation().search;
  const user = new URLSearchParams(search).get("user");
  const [isValidating, setisValidating] = useState(true);

  useEffect(() => {
    if (user) {
      onValidate();
    } else {
      onBack();
    }
  }, [user]);

  const onValidate = async () => {
    setisValidating(true);
    await validateUser(user)
      .then(() => {
        setisValidating(false);
      })
      .catch(() => {
        setisValidating(false);
        onBack();
      });
  };

  const onBack = () => {
    props.history.push("/login");
  };

  return (
    <>
      {!isValidating ? (
        <div className="message__wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 pb-3">
                <div className="goBack">
                  <button className="back-btn-red" title="back to home" onClick={redirectToHome}>
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back to Home
                  </button>
                </div>
              </div>
            </div>
            <div className="message__wrapper__inner">
              <div className="content">
                <div className="img-box">
                  <img src={congrats} alt="" className="img-fluid" />
                </div>
                <div className="content-box">
                  <h1>Congrats!</h1>
                  <p>You have successfully verified your email address.</p>
                  <p>
                    Please click on “Go to Dashboard” and you will be redirected
                    to your Virtual Brand’s Listings Page
                  </p>
                  <div className="action__wrapper">
                    <a href="/dashboard">
                      <button type="button">Go to Dashboard</button>
                    </a>
                    {/* <Link to="/dashboard">
                      <button type="button">Go to Dashboard</button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoaderBlock />
      )}
    </>
  );
};

export default withRouter(KitchenRegisterComplete);
