export const DeviceConnection = {
  FOUR_G: 0,
  WIFI: 1,
};

export const DeviceConnectionDropdown = [
  {
    value: DeviceConnection.FOUR_G,
    text: "4G",
  },
  {
    value: DeviceConnection.WIFI,
    text: "Wifi",
  },
];
