import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import editIcon from "assets/images/edit.svg";
import { Modal, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  addConnection,
  deleteConnection,
  editConnection
} from "services/api/connection";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import deleteIocn from "assets/images/delete-icon.svg";
import DeleteModal from "UI/Modal/DeleteModal";
import Datetime from "react-datetime";
import moment from "moment";

const MarketPlaceRow = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [market, setMarket] = useState(props?.market);
  const [deleteCredsPopUp, setdeleteCredsPopUp] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [pausedUntil, setPausedUntil] = useState(market?.pausedUntil);
  const kitchenVbAssociationId = props?.kitchenVbAssociationId;
  const services = props?.services;
  const [status, setStatus] = useState(market?.isActive);

  useEffect(() => {
    reset({
      url: market?.url,
      marketPlaceName: market?.marketPlaceName,
      serviceId: market?.platformMasterId,
      marketplaceId: market?.marketPlaceId,
      platformPickupRate: market?.platformPickupRate,
      platformDeliveryRate: market?.platformDeliveryRate,
      status: market?.isActive,
      isActive: market?.isActive,
      pausedUntil: market?.pausedUntil,
      pausedFrom: market?.pausedFrom,
      reason: market?.reason,
    });
    if (market?.id && parseInt(market?.id) > 0) {
         setisEditMode(true);
    }
    else setisEditMode(false);
  }, [market]);

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .nullable()
      .trim()
      .url("Please enter a valid URL")
      .max(500),
    serviceId: Yup.number()
      .test("isSelected", "Please select marketplace", (value) => +value !== 0)
      .required("Please select marketplace name"),
    // marketplaceId: Yup.string()
    //   .max(250, ({ max }) => `Marketplace ID not more than ${max} characters`)
    //   .required("Please enter marketplace id"),
    status: Yup.string(),
    // platformPickupRate: Yup.number()
    //   .typeError("Only digits allowed")
    //   .positive()
    //   .min(0, () => `Platform delivery rates must be greater than or equal to 0`)
    //   .max(100, () => `Platform delivery rates must be less than or equal to 100`),
    //   //.required("Please enter platform pickup rates"),
    // platformDeliveryRate: Yup.number()
    //   .typeError("Only digits allowed")
    //   .positive()
    //   .min(0, () => `Platform delivery rates must be greater than or equal to 0`)
    //   .max(100, () => `Platform delivery rates must be less than or equal to 100`),
      //.required("Please enter platform delivery rates"),
    reason: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const openModal = () => {
    reset({
      url: market?.url,
      username: market?.userName,
      password: market?.password,
      serviceId: market?.platformMasterId,
      marketplaceId: market?.marketPlaceId,
      platformPickupRate: market?.platformPickupRate,
      platformDeliveryRate: market?.platformDeliveryRate,
      status: market?.isActive,
      pausedUntil: market?.pausedUntil,
      pausedFrom: market?.pausedFrom,
      reason: market?.reason
    });
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
    //reset();
  };

  const onSubmit = async (data) => {
    setisLoading(true);
    data["pausedUntil"] = pausedUntil;
       if (isEditMode) {
         await editConnection(+market?.id, data, kitchenVbAssociationId)
           .then((res) => {
             toastify("success", res.message);
             const marketplace = services?.filter(
               (obj) => obj.value === data?.serviceId
             );
             setMarket({
               id: res?.data,
               url: data?.url,
               username: data?.userName,
               password: data?.password,
               marketPlaceName: marketplace[0]?.label,
               marketPlaceId: data?.marketplaceId,
               platformPickupRate: data?.platformPickupRate,
               platformDeliveryRate: data?.platformDeliveryRate,
               isActive: data?.status,
               pausedUntil: data?.pausedUntil,
               pausedFrom: data?.pausedFrom,
               platformMasterId: data?.serviceId,
               reason: data?.reason,
             });
             setisLoading(false);
             setModalShow(false);
           })
           .catch(() => {
             setisLoading(false);
             setModalShow(false);
           });
       } else {
         if (kitchenVbAssociationId) {
           await addConnection(kitchenVbAssociationId, data)
             .then((res) => {
               toastify("success", res.message);
               const marketplace = services?.filter((obj) => obj.value === data?.serviceId);
               setMarket({
                 id: res?.data,
                 url: data?.url,
                 username: data?.userName,
                 password: data?.password,
                 marketPlaceName: marketplace[0]?.label,
                 marketPlaceId: data?.marketplaceId,
                 platformPickupRate: data?.platformPickupRate,
                 platformDeliveryRate: data?.platformDeliveryRate,
                 isActive: data?.status,
                 pausedUntil: data?.pausedUntil,
                 pausedFrom: data?.pausedFrom,
                 platformMasterId: data?.serviceId,
                 reason: data?.reason,
               });
               setisLoading(false);
               setModalShow(false);
             })
             .catch(() => {
               setisLoading(false);
               setModalShow(false);
             });
         }
       }
   };

  const onDelete = async () => {
    setdeleteLoading(true);
    await deleteConnection(market?.id)
      .then((res) => {
        toastify("success", res.message);
        setMarket(null);
        setStatus(true);
        setdeleteLoading(false);
        closeDeleteModal();
        props.onDelete(market?.id);
      })
      .catch(() => {
        setdeleteLoading(false);
      });
  };

  const closeDeleteModal = () => setdeleteCredsPopUp(false);

  /* const onEdit = () => {
    return `/restaurants/${kitchen?.id}/connection/${market?.id}/edit`;
  }; */

   const validStart = (current) => {
     const yesterday = moment().subtract(1, "day");
     const maxEndDate = moment().add(3, "days");
     const range = current.isBefore(maxEndDate);
     return current.isAfter(yesterday) && range;
   };
  
  const statusChange = (evt) => {
    setStatus(evt.target.value);
    setPausedUntil(null);
    setValue("reason", null);
  };

  return (
    <>
      <div className="accordion__data__card">
        <div className="row no-gutters justify-content-between">
          <div className="col-10 col-md-10">
            <div className="row">
              <div className="col-12 col-md-4 pb-3 mb-md-4">
                <div className="data">
                  <label>Url</label>
                  <p>{market?.url || "-"}</p>
                </div>
              </div>
              <div className="col-12 col-md-3 pb-3 mb-md-4">
                <div className="data">
                  <label>Platform</label>
                  <p>{market?.marketPlaceName || "-"}</p>
                </div>
              </div>
              {/* <div className="col-12 col-md-3 pb-3 pb-md-4">
                <div className="data">
                  <label>Marketplace ID</label>
                  <p>{market?.marketPlaceId || "-"}</p>
                </div>
              </div> */}
              <div className="col-12 col-md-2 pb-3 pb-md-4">
                <div className="data">
                  <label>Status</label>
                  <p>
                    {market?.isActive === true || market?.isActive === "true"
                      ? "Active"
                      : "Inactive"}
                  </p>
                </div>
              </div>
              {/* <div className="col-12 col-md-4 pb-3 pb-md-4">
                <div className="data">
                  <label>Platform Pickup Rate %</label>
                  <p>{market?.platformPickupRate || "-"}</p>
                </div>
              </div>
              <div className="col-12 col-md-3 pb-3 pb-md-4">
                <div className="data">
                  <label>Platform Delivery Rate %</label>
                  <p>{market?.platformDeliveryRate || "-"}</p>
                </div>
              </div> */}
              {(market?.isActive === false || market?.isActive === "false") && (
                <>
                  <div className="col-12 col-md-3 pb-3 mb-md-4">
                    <div className="data">
                      <label>Paused From</label>
                      <p>
                        {moment(market?.pausedFrom)?.format(
                          "YYYY-MM-DD HH:mm"
                        ) || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 pb-3 mb-md-4">
                    <div className="data">
                      <label>Paused Until</label>
                      <p>
                        {moment(market?.pausedUntil)?.format(
                          "YYYY-MM-DD HH:mm"
                        ) || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 pb-3 mb-md-4">
                    <div className="data">
                      <label>Reason</label>
                      <p>{market?.reason || "-"}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-2 col-md-1">
            <div className="edit__button__wrapper display__flex">
              <button
                title={isEditMode ? "Edit" : "Add"}
                className="btn edit-btn m-0"
                onClick={openModal}
              >
                <span>
                  <img src={editIcon} alt="edit-icon" className="mr-3" />
                </span>
              </button>
              {isEditMode && (
                <button
                  type="button"
                  className="btn edit-btn m-0"
                  onClick={() => setdeleteCredsPopUp(true)}
                >
                  <span>
                    <img src={deleteIocn} className="icons m-0" alt="remove" />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={closeModal}
          centered
          className="credentials__modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditMode ? "Edit" : "Add"} Ordering Platform
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row no-gutters">
                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="url">URL</label>
                  <input
                    type="text"
                    id="url"
                    placeholder="Enter Platform URL"
                    {...register("url")}
                    maxLength="500"
                    className={`form-control ${errors.url ? "is-invalid" : ""}`}
                  />
                  {errors.url?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.url?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="apiKey">
                    Platform <span className="mendatory">*</span>
                  </label>
                  {services && services?.length && (
                    <>
                      <select
                        {...register("serviceId")}
                        className={`form-control big-select ${
                          errors.serviceId ? "is-invalid" : ""
                        }`}
                      >
                        <option value={0}>No Marketplace Selected</option>
                        {services &&
                          services?.map((res, idx) => (
                            <option key={idx} value={res.value}>
                              {res.label}
                            </option>
                          ))}
                      </select>
                      {errors.serviceId?.message && (
                        <div className="invalid-feedback d-block">
                          {errors.serviceId?.message}
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="marketplaceId">
                    Marketplace ID
                    <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="marketplaceId"
                    placeholder="E.g. 745210"
                    {...register("marketplaceId")}
                    className={`form-control ${
                      errors.marketplaceId ? "is-invalid" : ""
                    }`}
                  />
                  {errors.marketplaceId?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.marketplaceId?.message}
                    </div>
                  )}
                </div> */}
                {/* <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="platformPickupRate">
                    Platform Pickup Rates
                    <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="platformPickupRate"
                    placeholder="E.g. 10"
                    {...register("platformPickupRate")}
                    className={`form-control ${
                      errors.platformPickupRate ? "is-invalid" : ""
                    }`}
                  />
                  <span className="percentage-adornment">%</span>
                  {errors.platformPickupRate?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.platformPickupRate?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="platformDeliveryRate">
                    Platform Delivery Rates
                    <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="platformDeliveryRate"
                    placeholder="E.g. 10"
                    {...register("platformDeliveryRate")}
                    className={`form-control ${
                      errors.platformDeliveryRate ? "is-invalid" : ""
                    }`}
                  />
                  <span className="percentage-adornment">%</span>
                  {errors.platformDeliveryRate?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.platformDeliveryRate?.message}
                    </div>
                  )}
                </div> */}

                <div className="col-md-12 mb-3 pb-3">
                  <label htmlFor="mandatory">Status</label>
                  <select
                    className={`form-control big-select ${
                      errors?.status ? "is-invalid" : ""
                    }`}
                    {...register("status")}
                    onChange={statusChange}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>

                {(status === "false" || !status) && (
                  <>
                    <div className="col-md-12 mb-3 pb-3 px-0">
                      <label htmlFor="to">Paused Until</label>
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY HH:mm",
                        }}
                        isValidDate={validStart}
                        onChange={(date) => setPausedUntil(date)}
                        timeFormat="HH:mm:ss"
                        timeConstraints={{
                          hours: { min: 0, max: 23 },
                        }}
                        value={pausedUntil}
                      />
                      {status === "false" && !pausedUntil && (
                        <div className="invalid-feedback d-block">
                          Please select date time
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 mb-3 pb-3">
                      <label htmlFor="reason"> Reason</label>
                      <textarea
                        id="reason"
                        placeholder="Provide reason"
                        style={{ minHeight: "90px" }}
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        {...register("reason")}
                        maxLength="500"
                        rows="3"
                      ></textarea>
                      {errors?.reason?.message && (
                        <div className="invalid-feedback d-block">
                          {errors?.reason?.message}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="col-md-12 action__wrapper d-flex flex-column-reverse d-md-flex justify-content-between">
                  <button
                    type="button"
                    className="cancel-btn red-btn-ghost btn mr-5"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="prelogin-links-btn mb-4"
                    disabled={isLoading || (status === "false" && !pausedUntil)}
                  >
                    {isLoading ? <LoaderInline /> : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      {deleteCredsPopUp && (
        <DeleteModal
          show={deleteCredsPopUp}
          title="Delete Marketplace"
          subtitle="Are you sure you want to delete Marketplace?"
          onCancel={closeDeleteModal}
          onSubmit={onDelete}
          onLoading={deleteLoading}
        />
      )}
    </>
  );
};

MarketPlaceRow.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.bool,
    marketPlaceId: PropTypes.number,
    marketPlaceName: PropTypes.string,
    password: PropTypes.string,
    url: PropTypes.string,
    userName: PropTypes.string,
  }),
  kitchenVbAssociationId: PropTypes.number
};

export default MarketPlaceRow;
