import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ChangeStatus from "../change-status/ChangeStatus";
import dateFormat from "dateformat";

const ListingRow = (props) => {
  const restaurant = props.restaurant;
  const reloadListing = props.onReloadList;

  return (
    <tr role="row" className="mat-row">
      <td className="mat-cell column-1" data-column="NAME">
        <Link
          to={`/restaurants/${restaurant?.id}`}
          className="modifierDeatil__link"
        >
          {restaurant?.name || "NA"}
        </Link>
      </td>
      <td className="mat-cell column_2" data-column="CONTACT">
        <Link
          to={`/restaurants/${restaurant?.id}`}
          className="modifierDeatil__link"
        >
          <div>{restaurant?.contact || "NA"}</div>
        </Link>
      </td>
      <td className="mat-cell column_3" data-column="NO. OF VB'S">
        <Link
          to={`/restaurants/${restaurant?.id}`}
          className="modifierDeatil__link"
        >
          {restaurant?.kitchenVirtualBrandAssociations &&
            restaurant?.kitchenVirtualBrandAssociations.length}
        </Link>
      </td>
      <td className="mat-cell column-4" data-column="CREATED ON">
        <Link
         to={`/restaurants/${restaurant?.id}`}
          className="modifierDeatil__link d-inline-block w-100"
        >
            <div className="row no-gutters">
              {restaurant?.createdOn ? (
                <div className="col-12">
                  <p className="m-0">
                    {dateFormat(new Date(restaurant?.createdOn + "Z"), "hh:MM TT")}
                  </p>
                  <p className="m-0">
                    {dateFormat(
                      new Date(restaurant?.createdOn + "Z"),
                      "mmm dd, yyyy"
                    )}
                  </p>
                </div>
              ) : (
                "NA"
              )}
            </div>
        </Link>
      </td>
      <td className="mat-cell column_5" data-column="ACTION">
        <div className="table__actions ">
          <ChangeStatus
            restaurant={restaurant}
            restaurantId={restaurant?.id}
            status={restaurant?.status}
            onActionComplete={reloadListing}
          />
        </div>
      </td>
    </tr>
  );
};

ListingRow.propTypes = {
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    contact: PropTypes.string,
    businessAddress: PropTypes.string,
    kitchenOwner: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      fullName: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    createdOn: PropTypes.string,
    modifiedOn: PropTypes.string,
    status: PropTypes.number,
    kitchenVirtualBrandAssociations: PropTypes.arrayOf({
      virtualBrand: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  onReloadList: PropTypes.func,
};

export default ListingRow;
