import React, { useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import warningIcon from "assets/images/warningIcon.svg";
import rightArrowIcon from "assets/images/white-right-arrow-btn.svg";
import AssetDropArea from "UI/Image-Uploader/AssetDropArea";
import { Global } from "constants/global";
import { AssetType } from "constants/assetType";
import uploadFileToBlob from "services/azure-blob";
import Ranger from "UI/Ranger/ProgressBar";
import { deleteVirtualBrandLogoImage, deleteVirtualBrandCoverImage } from "services/api/brand";

const VBBasicInfoForm = (props) => {
    const minDeliveryDistance = 5;
    const maxDeliveryDistance = 50;
    const minPrepTime = 5;
    const maxPrepTime = 90;
    const minTipAmt = 2;
    const data = props.initialData;
    const [radiusValue, setRadiusValue] = useState(minDeliveryDistance);
    const [prepTimeValue, setprepTimeValue] = useState(minPrepTime);
    const isFieldsDisabled = props?.disabled;
    const logoSize = Global.fiveMB;
    const coverSize = Global.fiveMB;
    const [logoImage, setlogoImage] = useState(null);
    const [coverImage, setcoverImage] = useState(null);
    const [rawLogoImage, setRawLogoImage] = useState(null);
    const [rawCoverImage, setRawCoverImage] = useState(null);
    const [isLogoUploading, setisLogoUploading] = useState(false);
    const [isCoverUploading, setisCoverUploading] = useState(false);

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .trim()
        .max(30, "Brand name not more than 30 characters")
        .required("Please enter brand name"),
      tagline: Yup.string()
        .trim()
        .max(120, ({ max }) => `Brand tagline not more than ${max} characters`)
        .required("Please enter brand tagline"),
      description: Yup.string()
        .trim()
        .max(500, "Brand description not more than 500 characters")
        .required("Please enter brand description"),
      deliveryRadius: Yup.number()
        .positive()
        .min(minDeliveryDistance)
        .max(maxDeliveryDistance)
        .required("Delivery Radius is required"),
      preparationTime: Yup.number()
        .positive()
        .min(minPrepTime)
        .max(maxPrepTime)
        .required("Preparation time is required"),
      tipAmount: Yup.number()
        .typeError("Only digits allowed")
        .positive()
        .min(0)
        .max(10000)
        .required("Please enter tip amount")
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            deliveryRadius: minDeliveryDistance,
            preparationTime: minPrepTime,
            tipAmount: minTipAmt
        },
    });

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isValid, isDirty },
    } = methods;

    const onRadiusChange = (val) => {
        setValue("deliveryRadius", val, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        setRadiusValue(val);
    };

    const onPrepTimeChange = (val) => {
        setValue("preparationTime", val, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
        setprepTimeValue(val);
    };

    const uploadLogoImage = async (file) => {
        setisLogoUploading(true);
        await uploadFileToBlob(file, AssetType.LOGOIMAGE).then(
          (res) => {
            setValue("logoURL", res?.filePath || res?.fileName, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
            setlogoImage(res);
            setisLogoUploading(false);
          },
          () => {
            setlogoImage(null);
            setisLogoUploading(false);
          }
        );
    };
    
  const uploadCoverImage = async (file) => {
        setisCoverUploading(true);
        await uploadFileToBlob(file, AssetType.COVERIMAGE).then(
          (res) => {
            setValue("coverURL", res?.filePath || res?.fileName, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
            setcoverImage(res);
            setisCoverUploading(false);
          },
          () => {
            setcoverImage(null);
            setisCoverUploading(false);
          }
        );
      };

  const deleteLogoImage = async () => {
    if (data?.id) {
      setisLogoUploading(true);
      await deleteVirtualBrandLogoImage(data.id).then(
        () => {
          setValue("logoURL", null);
          setlogoImage(null);
          setRawLogoImage(null);
          setisLogoUploading(false);
        },
        () => {
          setlogoImage(null);
          setRawLogoImage(null);
          setisLogoUploading(false);
        }
      );
    }
  };
  
  const deleteCoverImage = async () => {
    if (data?.id) {
      setisCoverUploading(true);
      await deleteVirtualBrandCoverImage(data.id).then(
        () => {
          setValue("coverURL", null);
          setcoverImage(null);
          setRawCoverImage(null);
          setisCoverUploading(false);
        },
        () => {
          setcoverImage(null);
          setRawCoverImage(null);
          setisCoverUploading(false);
        }
      );
    }
  };
 
    useEffect(() => {
        if (data) {
            reset({
                name: data.name,
                tagline: data.tagline,
                description: data.description,
                deliveryRadius: data.deliveryRadius,
                preparationTime: data.preparationTime,
                tipAmount: data.tipAmount || 0,
                logoURL: data.logoURL,
                coverURL: data.coverURL,
                ...data.virtualBrandAvailability
            });
            setRadiusValue(data.deliveryRadius);
            setprepTimeValue(data.preparationTime);
            setlogoImage({ filePath: data.logoURL });
            setcoverImage({ filePath: data.coverURL });
            setRawLogoImage(data.rawLogoURL);
            setRawCoverImage(data.rawCoverURL);
        }
    }, [data]);

    const onSubmit = (data) => {
        props.onSave(data);
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="createMenu__wrapper uploadAssets__wrapper basic__info__content CreateMenuModalContent__wrapper">
          <div className="uploadAssets-wrapper">
            <div className="warning__wrapper mb-3 pb-3">
              {errors && (
                <div className="warning__box">
                  <p>
                    <span className="icons">
                      <img src={warningIcon} alt="" />
                    </span>
                    In order to continue to next screen you should fill all
                    mandatory fields
                  </p>
                </div>
              )}
            </div>
            <div className="row no-gutters">
              <div className="col-md-12 mb-3 pb-3">
                <label htmlFor="brandName">
                  Brand Name<span className="mendatory">*</span>
                </label>
                {isFieldsDisabled ? (
                  <div className="view_form_input">{data?.name || "NA"}</div>
                ) : (
                  <input
                    type="text"
                    id="brandName"
                    placeholder="e.g. Willie"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("name")}
                    maxLength="30"
                    disabled={isFieldsDisabled}
                  />
                )}
                {errors?.title?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.title?.message}
                  </div>
                )}
              </div>

              <div className="col-md-12 mb-3 pb-3">
                <label htmlFor="menuSubtitle">
                  Brand Tagline<span className="mendatory">*</span>
                </label>
                {isFieldsDisabled ? (
                  <div className="view_form_input">{data?.tagline || "NA"}</div>
                ) : (
                  <input
                    type="text"
                    id="brandTagline"
                    placeholder="e.g. tasty food"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("tagline")}
                    maxLength="120"
                    disabled={isFieldsDisabled}
                  />
                )}
                {errors?.tagline?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.tagline?.message}
                  </div>
                )}
              </div>

              <div className="col-12 mb-3 pb-3">
                <label htmlFor="logoURL">Brand Logo Image</label>
                <AssetDropArea
                  extraClass="logo-image-wrapper"
                  dimensions="1200 X 640px"
                  maxSize={logoSize}
                  acceptedFormat="image/jpeg, image/png, image/svg+xml"
                  showFormatText="JPEG, PNG, SVG"
                  onFileChange={uploadLogoImage}
                  isImageUploading={isLogoUploading}
                  uploadedImage={logoImage?.filePath}
                  uploadedRawImage={rawLogoImage}
                  disabled={isFieldsDisabled}
                  btnText={"Change Image"}
                  onDeleteImg={deleteLogoImage}
                />
              </div>

              <div className="col-12 mb-3 pb-3">
                <label htmlFor="coverURL">Brand Cover Image</label>
                <AssetDropArea
                  dimensions="2000 X 1200px"
                  maxSize={coverSize}
                  acceptedFormat="image/jpeg, image/png, image/svg+xml"
                  showFormatText="JPEG, PNG, SVG"
                  onFileChange={uploadCoverImage}
                  isImageUploading={isCoverUploading}
                  uploadedImage={coverImage?.filePath}
                  uploadedRawImage={rawCoverImage}
                  disabled={isFieldsDisabled}
                  btnText={"Change Image"}
                  onDeleteImg={deleteCoverImage}
                />
              </div>

              <div className="col-md-12 mb-3 pb-3">
                <label htmlFor="menuDescription">
                  {" "}
                  Brand Description<span className="mendatory">*</span>
                </label>
                {isFieldsDisabled ? (
                  <div className="view_form_input">
                    {data?.description || "NA"}
                  </div>
                ) : (
                  <textarea
                    id="brandDescription"
                    placeholder="Add Description"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("description")}
                    maxLength="500"
                    rows="3"
                    disabled={isFieldsDisabled}
                  ></textarea>
                )}
                {errors?.description?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.description?.message}
                  </div>
                )}
              </div>

              <div className="col-md-12 mb-3 pb-3">
                <div className="form-group">
                  <label htmlFor="deliveryRadius">
                    Delivery Radius
                    <span className="mendatory">*</span>
                  </label>
                  <Ranger
                    value={radiusValue}
                    setRadius={onRadiusChange}
                    minDistance={minDeliveryDistance}
                    maxDistance={maxDeliveryDistance}
                    label="mi"
                    disabled={isFieldsDisabled}
                  />
                  {errors?.deliveryRadius?.message && (
                    <div className="invalid-feedback d-block">
                      {errors?.deliveryRadius?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3 pb-3">
                <div className="form-group">
                  <label htmlFor="preparationTime">
                    Estimated Preparation Time
                    <span className="mendatory">*</span>
                  </label>
                  <Ranger
                    value={prepTimeValue}
                    setRadius={onPrepTimeChange}
                    minDistance={minPrepTime}
                    maxDistance={maxPrepTime}
                    label="Min"
                    disabled={isFieldsDisabled}
                  />
                  {errors?.preparationTime?.message && (
                    <div className="invalid-feedback d-block">
                      {errors?.preparationTime?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3 pb-3">
                <label htmlFor="tipAmount">
                  Tip Amount
                  <span className="mendatory">*</span>
                </label>
                {isFieldsDisabled ? (
                  <div className="view_form_input">
                    {data?.tipAmount || "NA"}
                  </div>
                ) : (
                  <input
                    id="tipAmount"
                    placeholder="e.g. 9.99"
                    className={`form-control ${
                      errors?.tipAmount ? "is-invalid" : ""
                    }`}
                    {...register("tipAmount")}
                    disabled={isFieldsDisabled}
                  />
                )}
                {errors?.tipAmount?.message && (
                  <div className="invalid-feedback d-block">
                    {errors?.tipAmount?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          {props.children}

          <button
            type="button"
            onClick={props.onHide}
            className="cancel-btn red-btn-ghost btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="red-btn next-btn btn"
            disabled={!isValid}
          >
            Next
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </button>
        </Modal.Footer>
      </form>
    );
};

export default VBBasicInfoForm;
