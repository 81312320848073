import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserAccount } from "services/api/staff";
import LoaderBlock from "shared/loader/LoaderBlock";
import backArrowIcon from "assets/images/back-arrow.svg";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

import "./MyAccount.scss";
import { RoleType } from "constants/roleType";
import UserContext from "store/user-context";

const MyProfile = () => {
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const [User, setUser] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    setisLoading(true);
    await getUserAccount()
      .then((res) => {
        setUser(res.data);
        setisLoading(false);
      })
      .catch(() => setisLoading(false));
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
    <div className="myaccount__wrapper">
      <div className="modifierDetails__wrapper restaurantDetails__wrapper user__details devices__details">
        <div className="container">
          <div className="row">
            {![RoleType.KITCHEN_STAFF].includes(userRole) && (
              <div className="col-12 d-none d-md-block">
                <div className="goBack">
                  <Link
                    to="/dashboard"
                    className="back-btn-red"
                    title="Go to Dashboard"
                  >
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Go to Dashboard
                  </Link>
                </div>
              </div>
            )}
            <div className="col-12">
              <div className="main_heading">
                <h1>My Account</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="details__wrapper">
                {isLoading ? (
                  <LoaderBlock />
                ) : (
                  <>
                    <div className="details__wrapper__inner">
                      <div className="left_content">
                        <div className="row">
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>Full Name</h5>
                              <p>{User?.name || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>User Type</h5>
                              <p>{User?.roleName || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>Contact No</h5>
                              <p>{User?.contact || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>Account Email</h5>
                              <p>{User?.email || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>Address Line 1</h5>
                              <p>{User?.addressOne || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>Address Line 2</h5>
                              <p>{User?.addressTwo || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>City</h5>
                              <p>{User?.city || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>State</h5>
                              <p>{User?.stateName || "NA"}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-md-3 pb-md-4 mb-3 mb-md-0">
                            <div className="detailCard">
                              <h5>Zip</h5>
                              <p>{User?.zip || "NA"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right_content">
                        <div className="btn-group dropleft threeDotsBropdown">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-button-drop-left"
                          ></button>
                          <div className="dropdown-menu">
                            <div className="dropdown__innerWrapper">
                              <Link
                                to="/profile/change-password"
                                className="dropdown-item active"
                              >
                                <span>Change Password</span>
                              </Link>
                              <Link
                                to="/profile/edit"
                                className="dropdown-item"
                              >
                                <span>Edit</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MyProfile;
