export const DeviceType = {
  TABLET: 0,
  PRINTER: 1,
};

export const DeviceTypeDropdown = [
  {
    value: DeviceType.TABLET,
    text: "Tablet",
  },
  {
    value: DeviceType.PRINTER,
    text: "Printer",
  },
];
