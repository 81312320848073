import React, { useEffect, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import "./ManageModifierLandingpage.scss";
import searchIcon from "assets/images/icon-search.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import Paginator from "shared/paginator/Paginator";
import ListingRow from "./ListingRow";
import { Global } from "constants/global";
import LoaderBlock from "shared/loader/LoaderBlock";
import { getKitchens } from "services/api/kitchen";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";

const restParams = new URLSearchParams();

const RestaurantListing = () => {
  const pageSize = Global.pageSize;

  const history = useHistory();
  const { search } = useLocation();

  const page = new URLSearchParams(search).get("page");
  const searchParam = new URLSearchParams(search).get("search");
  const [pageNum, setpageNum] = useState(page || 1);
  const [searchTerm, setsearchTerm] = useState(searchParam || null);
  const [searchValue, setsearchValue] = useState(searchParam || null);

  const [allRestaurnts, setallRestaurnts] = useState(null);
  const [totalRecords, settotalRecords] = useState(0);

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (search) {
      let page = new URLSearchParams(search).get("page");
      let searchVal = new URLSearchParams(search).get("search");
      if (page) {
        setpageNum(+page);
        restParams.set("page", page);
      } else {
        setpageNum(1);
        restParams.delete("page");
      }
      if (searchVal) {
        setsearchTerm(searchVal);
        restParams.set("search", searchVal);
      } else {
        setsearchTerm(null);
        restParams.delete("search");
      }
    } else {
      setpageNum(1);
      restParams.delete("page");
      setsearchTerm(null);
      restParams.delete("search");
    }
  }, [search]);

  useEffect(() => {
    getListing();
  }, [pageNum, searchTerm]);

  const getListing = async () => {
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      searchTerm: searchTerm,
    };
    setisLoading(true);
    await getKitchens(masks)
      .then((res) => {
        let data = res.data;
        setallRestaurnts(data.kitchens);
        settotalRecords(data.pagingParams.totalRecords);
        setisLoading(false);
      })
      .catch(() => {
        setallRestaurnts(null);
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const onPageChange = (page) => {
    if (page) {
      restParams.set("page", page);
    } else {
      restParams.delete("page");
    }
    history.push({ search: restParams.toString() });
  };

  const onSearch = (e) => {
    let search = e.target.value;
    setsearchValue(search);
      if (search) {
        restParams.set("search", search);
      } else {
        restParams.delete("search");
      }
      //setpageNum(1);
      restParams.delete("page");
      history.push({ search: restParams.toString() });
  };

  const onSearchValue = () => {
      if (searchValue) {
        restParams.set("search", searchValue);
      } else {
        restParams.delete("search");
      }
      //setpageNum(1);
      restParams.delete("page");
      history.push({ search: restParams.toString() });
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
      <div className="managermodifier__wrapper basic__info__content">
        <div className="container">
          <div className="managermodifier__header">
            <div className="row justify-content-between">
              <div className="col-12 mb-3 pb-3 d-none d-md-block">
                <div className="goBack">
                  <Link
                    to="/dashboard"
                    className="back-btn-red"
                    title="Go to Dashboard"
                  >
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Go to Dashboard
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <h1>Manage Restaurant</h1>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="search__modifiers manage-restra-search">
                  <div className="search_modifier_group">
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="text"
                          className="custom-file form-control"
                          placeholder="Search Restaurant Name"
                          defaultValue={searchTerm}
                          onKeyUp={onSearch}
                        />
                        <button type="button" className="search-btn" onClick={() => onSearchValue()}>
                          <span className="icons">
                            <img src={searchIcon} alt="plus icon" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {allRestaurnts && totalRecords === 0 ? (
            <div className="managermodifier__noListing">
              <div className="managermodifier__noListing__innerwrapper">
                <div className="content">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={emptyIocn}
                      alt="no list icon"
                      width="185"
                    />
                  </div>
                  <div className="content-desc">
                    <h3>
                      {searchTerm && searchTerm !== ""
                        ? "No restaurant(s) found"
                        : "No restaurants are added"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="managermodifier__listinPage managerestra__listinPage">
              <div className="managermodifier__listinPage__wrapper">
                <table className="table modifierListing__table">
                  <thead>
                    <tr role="row" className="mat-header-row">
                      <th scope="col" className="mat-header-cell column-1">
                        Name
                      </th>
                      <th scope="col" className="mat-header-cell column_2">
                        Contact
                      </th>
                      <th scope="col" className="mat-header-cell column_3">
                        No. of VB's
                      </th>
                      <th scope="col" className="mat-header-cell column-4">
                        Created On
                      </th>
                      <th scope="col" className="mat-header-cell column_5">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td>
                          <LoaderBlock />
                        </td>
                      </tr>
                    ) : (
                      allRestaurnts &&
                      allRestaurnts.map((rstrnt, idx) => (
                        <ListingRow
                          key={idx}
                          restaurant={rstrnt}
                          onReloadList={getListing}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-12">
                    <Paginator
                      totalCount={totalRecords}
                      currentPage={pageNum}
                      pageSize={pageSize}
                      pageClick={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RestaurantListing;
